import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, AbstractControl} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { debounce, finalize, switchMap, map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { CardInfoModalComponent } from '../../../../_shared/components/card-info-modal/card-info-modal.component';
import {
  RequestPaymentModalComponent,
} from '../../../../_shared/components/request-payment-modal/request-payment-modal.component';
import {
  showPaymentCreditorDocumentsModalComponent,
} from '../../../../_shared/components/show-payment-creditor-documents/show-payment-creditor-documents.component';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { CasePaymentPlan } from '../../../../../../../_base-shared/models/Payment/CasePaymentPlan';
import { FinancialOverview } from '../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { PaymentMethod, PaymentMethodType } from '../../../../../../../_base-shared/models/Payment/PaymentMethod';
import { PaymentProcessorType } from '../../../../../../../_base-shared/models/Payment/PaymentProcessor';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { PaymentPlan } from '../../../../../../../_base-shared/models/Product/PaymentPlan';
import { PaymentPlanPhase } from '../../../../../../../_base-shared/models/Product/PaymentPlanPhase';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { EmailPreviewComponent } from '../../../app-document/email-preview/email-preview.component';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { PaymentMethodService } from '../../../../../../../_base-shared/services/payment-method.service';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CasePaymentPlanService } from '../../case-payment-plan.service';
import { CaseService } from '../../case.service';
import { ProductService } from '../../product.service';
import {MiscConfigService} from '../../../config/misc-config/misc-config.service';
import {CaseCreditor, CaseCreditorPivot} from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import {CreditorService} from '../../../creditor/creditor.service';
import {LogaltyTransaction} from '../../../../../../../_base-shared/models/Logalty/LogaltyTransaction';
import { CaseStatusLog } from 'projects/_base-shared/models/Case/CaseStatusLog';
import { CaseDocumentService } from '../../case-document.service';
import { AdminPaymentHandlerComponent } from '../../../payment/admin-payment-handler/admin-payment-handler.component';

@Component({
  selector:    'app-case-payment-editor',
  templateUrl: './case-payment-editor.component.html',
  styleUrls:   ['./case-payment-editor.component.scss'],
})
export class CasePaymentEditorComponent implements OnInit {
  public authUser: User;
  public case: Case;
  public isLoading                            = 0;
  public isSubmittingPurpose                  = false;
  public submittingPaymentPlan: boolean;
  public paymentPlanForm: UntypedFormGroup;
  public paymentPlanResponse: LaravelResourceResponse;
  public submittingPaymentConfiguration: boolean;
  public paymentConfigurationForm: UntypedFormGroup;
  public purposeForm: UntypedFormGroup;
  public paymentConfigResponse: LaravelResourceResponse;
  public financialOverview: FinancialOverview;
  public phaseOnePaid: boolean; // TODO:
  public phaseTwoPaid: boolean; // TODO:
  public taxRate                              = 1.21;
  public aprRate: number;
  public tinRate: number;
  public loanArrangementFee: number;
  public debtReductionFee: number;
  public totalLoaned: number;
  public totalAmountByCreditors: number;
  public totalRepaid = 0;
  public paymentMethods: Array<PaymentMethod> = [];
  public daysInMonth: Array<AppSelectOption>  = [];
  public summaryResponse: LaravelResourceResponse;
  public logaltyTransactions: Array<LogaltyTransaction> = [];
  public caseStatusLog: Array<CaseStatusLog> = [];
  public creditorChangesSavedStatus: Record<string, number> = {
    'saved': 1,
    'unsaved': 0,
    'disabled': -1
  };
  public creditorChangesSaved: number = this.creditorChangesSavedStatus['saved'];
  public sendLinksError             = '';
  public creditors: Array<Creditor> = [];
  public products: Array<Product>   = [];
  public creditorsOption: Array<Creditor> = [];
  public files                  = [];
  public userDocuments                   = [];
  public userDocumentsPartner            = [];
  public creditorDocuments               = [];

  public storageUrl = environment.STORAGE_URL + '/';
  public apiURL    = environment.API_URL;

  // Payment Plan
  public selectedProduct: Product;
  public selectedPaymentPlan: PaymentPlan;
  public phaseOne: PaymentPlanPhase;
  public variousFees: Array<any> = [];
  public variousFeesTable: Array<any> = ['date', 'description', 'amount', 'fullname', 'actions'];
  public variousFeesDescription: Array<AppSelectOption> = [
    {label: 'Various fees', value: 'Various fees'},
    // {label: 'Top Up Loan Amount', value: 'Top Up Loan Amount'},
  ]
  public topUpLoanAmount: number = 0;
  public topUpLoanCount: number = 0;
  public creditorIbans: Array<AppSelectOption> = [];
  // public phaseTwo: PaymentPlanPhase;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private changeRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private caseService: CaseService,
    private caseCreditorService: CaseCreditorService,
    private globalEventsService: MainGlobalEventService,
    private paymentMethodService: PaymentMethodService,
    private productService: ProductService,
    private casePaymentPlanService: CasePaymentPlanService,
    private financialOverviewService: FinancialOverviewService,
    private configService: MiscConfigService,
    private caseDocumentService: CaseDocumentService,
    private creditorService: CreditorService
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.fetchCase(caseId);
    });

    this.financialOverviewService.financialOverview$.subscribe(overview => this.financialOverview = overview);
    this.daysInMonth = [];
    for (let i = 1; i <= 28; i++) {
      this.daysInMonth.push({label: i.toString(10), value: i});
    }
  }

  /**
   *
   * @param caseId Case ID associated with the currently loaded case
   *
   * @returns void
   */
  private fetchCase(caseId: number): void
  {
    this.caseService.get(caseId,
      ['product.payment_plans.payment_plan_phases', 'loan_payment_plan', 'creditors', 'distribution', 'loan', 'payments'],
    )
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.case = result.data;
        this.aprRate = this.case.loan_payment_plan.apr_rate;
        this.tinRate = this.case.loan_payment_plan.tin_rate;
        this.fetchAprRate();
        this.fetchPaymentMethods();
        this.displayDistributionWarningModal();
        this.calculateTotalRepaid();
        this.fetchCreditors();
        this.fetchLogaltyTransactions();
        this.fetchStatusLogs();
        this.fetchVariousFees();
        this.fetchDocuments();
    });
  }

  private reloadCase(caseId: number): void
  {
    this.caseService.get(caseId,
      ['product.payment_plans.payment_plan_phases', 'loan_payment_plan', 'creditors', 'distribution', 'loan', 'payments'],
    )
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.case = result.data;
    });
  }

  public isComponentDirty(): boolean {
    return this.paymentPlanForm.dirty || this.paymentConfigurationForm.dirty;
  }

  public isComponentValid(): boolean {
    return this.paymentPlanForm.valid || this.paymentConfigurationForm.valid;
  }

  public submitComponent() {
    if (this.paymentPlanForm.dirty) {
      this.submitPaymentPlanForm(this.paymentPlanForm);
    }
    if (this.paymentConfigurationForm.dirty) {
      this.submitPaymentConfigurationForm(this.paymentConfigurationForm);
    }

    return true;
  }

  onFileChange(event,i) {
    const creditors = this.purposeForm.get('creditors') as FormArray;
    const files      = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    this.files = filesArray;
    if (this.files.length > 0) {
      this.submitFiles(creditors.controls[i].get('creditor_id').value);
    }
  }

  submitFiles(creditorId) {
    if (this.files.length > 0) {
      //this.uploadSpinner = true;
      const formData     = new FormData();
      const fileType     = '6601';
      const uploadFor    = 'client';
      //  Append files to form data
      this.files.map(file => {
        formData.append(`files[]`, file);
      });
      formData.append(`uploaded_by`, uploadFor);

      formData.append(`document_type_id`, fileType);
      formData.append(`creditor_id`, creditorId);
      this.caseDocumentService.adminUploadFiles(this.case.id, formData)
        .pipe(finalize(() => {
          //this.uploadSpinner = false;
          this.files         = [];
        }))
        .subscribe(
          next => {
            this.userDocuments        = next.data.client_files_by_type;
            this.userDocumentsPartner = next.data.partner_files_by_type;
            this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
          },
          error => {
            this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
          });

    }
  }

  public fetchDocuments(): void {
    this.caseDocumentService.indexCaseDocuments(this.case.id)
      .subscribe(
        next => {
          this.creditorDocuments = next.data.files;
        },
        error => {
          this.toastr.error(this.translate.instant('DOCUMENTS.documents-fetch-error'));
        });
  }

  public hasCreditorDocuments(creditorIndex: number): number {
    let count = 0;
    const creditorId = this.purposeForm.get('creditors').value[creditorIndex].creditor_id;
    this.creditorDocuments.forEach(doc => {
      if (doc.creditor_id === creditorId && doc.document_type_id === 6601) {
        count++;
      }
    });

    return count;
  }

  private subscribeToPaymentPlanChanges(): void {
    this.calculatePaymentPlan();

    this.paymentPlanForm.get('phase_one_duration').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.calculatePaymentPlan('phase_one_duration'));

    this.paymentPlanForm.get('original_debt_amount').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.calculatePaymentPlan('original_debt'));

      this.paymentPlanForm.get('debt_settlement_agreed').valueChanges
        .pipe(debounce(() => interval(500)))
        .subscribe(value => this.calculatePaymentPlan('debt_settlement_agreed'));

      this.paymentPlanForm.get('various_fees').valueChanges
        .pipe(debounce(() => interval(1000)))
        .subscribe(value => this.calculatePaymentPlan('various_fees'));
  }

  public calculatePaymentPlan(key?: string): void {
    const originalDebtAmount = this.paymentPlanForm.get('original_debt_amount').value;
    const debtSettlementAgreed = this.paymentPlanForm.get('debt_settlement_agreed').value;

    if (!originalDebtAmount || !debtSettlementAgreed) {
      return;
    }

    this.paymentPlanForm.markAsPristine();
    this.recalculateLoan();
  }

  private calculateTotalLoan2(): void {
    const interestRate = +this.aprRate;
    const loanArrangementFee = +this.loanArrangementFee;
    const debtSettlementAgreed = +this.paymentPlanForm.get('debt_settlement_agreed');
    const monthlyInterestRate = +(interestRate / 1200).toFixed(10);
    const installments = +this.paymentPlanForm.get('phase_one_duration').value;
    let loanValue = +debtSettlementAgreed + loanArrangementFee;
    loanValue = +loanValue.toFixed(10);
    let monthlyPayment = loanValue * (monthlyInterestRate / (1 - Math.pow((1 + monthlyInterestRate), -installments)));
    monthlyPayment = +monthlyPayment.toFixed(2);
    this.paymentPlanForm.get('phase_one_monthly_fee').patchValue(monthlyPayment);
    this.totalLoaned = +(monthlyPayment * installments).toFixed(10);
  }

  private recalculateLoan(): void {
    // this.aprRate = +this.paymentPlanForm.get('apr_rate').value;
    const interestRate = +this.aprRate;
    const monthlyInterestRate = +(interestRate / 1200).toFixed(10);

    // this.tin_rate = (((1+this.aprRate/100) ** (1/12))-1)*12*100;

    // check if this.creditors has a specific creditor in the array
    let dfixAmount = 0;
    const creditors = this.purposeForm.get('creditors') as FormArray;
    if (creditors.length > 0) {
      creditors.controls.forEach((creditorControl, index) => {
        const creditor = this.case.creditors.find(c => c.id === 3193);
        if (creditor) {
          if (creditor.pivot.type === 'unsecured') {
            dfixAmount = +creditor.pivot.negotiated_amount;
            // // update the owed amount for deudafix in the purpose form with the various fee
            // let updated = 0;
            // if (this.paymentPlanForm.get('various_fees').value) {
            //   updated = +creditor.pivot.negotiated_amount + +this.paymentPlanForm.get('various_fees').value;
            //   // patch the new value to the form for specific creditor with id 3193
            //   // creditors.controls[index].get('negotiated_amount').patchValue(updated);
            // }
          }
        }
      });
    }


    const variousFees = +this.paymentPlanForm.get('various_fees').value;
    const loanValue = +this.paymentPlanForm.get('phase_one_value').value;

    const debtSettlementAgreed = +this.paymentPlanForm.get('debt_settlement_agreed').value;
    // console.log(debtSettlementAgreed);
    // console.log(dfixAmount);
    const newLoanTerm = +this.paymentPlanForm.get('phase_one_duration').value;

    const installments = +this.paymentPlanForm.get('phase_one_duration').value;
    const newLoanAmount = (loanValue + +variousFees);
    let monthlyPayment = +newLoanAmount * (monthlyInterestRate / (1 - Math.pow((1 + monthlyInterestRate), -newLoanTerm)));
    monthlyPayment = +monthlyPayment.toFixed(2);
    this.totalLoaned = +(monthlyPayment * newLoanTerm).toFixed(2);

    this.paymentPlanForm.get('phase_one_monthly_fee').patchValue(monthlyPayment);
    this.paymentPlanForm.get('phase_one_value').patchValue(newLoanAmount.toFixed(2));
    this.paymentPlanForm.get('repayable_amount').patchValue(this.totalLoaned);
    this.paymentPlanForm.get('debt_reduction_fee').patchValue(dfixAmount.toFixed(2));

  }

  private calculateTotalLoan(): void {
    // console.log(this.paymentPlanForm);
    const interestRate = +this.aprRate;
    const monthlyInterestRate = +(interestRate / 1200).toFixed(10);

    const variousFees = +this.paymentPlanForm.get('various_fees').value;
    const originalDebtAmount = +this.paymentPlanForm.get('original_debt_amount').value;
    const debtSettlementAgreed = +this.paymentPlanForm.get('debt_settlement_agreed').value;
    const debtReduction = originalDebtAmount - debtSettlementAgreed;
    const debtReductionFee = (debtReduction * (+this.debtReductionFee / 100));
    const newLoanAmount = (debtSettlementAgreed + (+debtReductionFee * 1.21) + +variousFees);
    const newLoanTerm = +this.paymentPlanForm.get('phase_one_duration').value;
    let monthlyPayment = +newLoanAmount * (monthlyInterestRate / (1 - Math.pow((1 + monthlyInterestRate), -newLoanTerm)));
    monthlyPayment = +monthlyPayment.toFixed(2);
    this.paymentPlanForm.get('phase_one_monthly_fee').patchValue(monthlyPayment);
    this.totalLoaned = +(monthlyPayment * newLoanTerm).toFixed(2);
    this.paymentPlanForm.get('phase_one_value').patchValue(newLoanAmount.toFixed(2));
    this.paymentPlanForm.get('repayable_amount').patchValue(this.totalLoaned);
    this.paymentPlanForm.get('debt_reduction_fee').patchValue(debtReductionFee.toFixed(2));
  }

  public hasError(form: UntypedFormGroup, formControlName: string) {
    return form.get(formControlName)?.touched && this.paymentPlanForm.get(formControlName)?.invalid;
  }

  public sendSepaLink(send_to) {
    this.sendLinksError = '';
    const amount        = this.case.loan_payment_plan.initial_fee;

    if ( ! this.case.id || ! amount) {
      this.sendLinksError = this.translate.instant('CASES.single.save-case-error');

      return this.toastr.error(this.translate.instant('CASES.single.save-case-error'));
    }

    const data = {
      caseId: this.case.id,
      send_to,
    };
    this.caseService.sendSepaLink(data).subscribe(
      () => {
        this.toastr.success(this.translate.instant('CASES.single.send-link-success'));
      },
      err => {
        console.log(err);
        this.toastr.error(this.translate.instant('CASES.single.send-link-error'));
      },
    );
  }

  public requestPaymentDialog(paymentMethodType: PaymentMethodType, paymentProcessorType: PaymentProcessorType) {
    this.sendLinksError = '';
    const amount        = this.case.loan_payment_plan.initial_fee;
    if ( ! this.case.id || ! amount) {
      this.sendLinksError = this.translate.instant('CASES.single.save-case-error');

      return this.toastr.error(this.translate.instant('CASES.single.save-case-error'));
    }

    const dialogRef = this.dialog.open(RequestPaymentModalComponent, {
      width:        '50%',
      minHeight:    200,
      disableClose: true,
      autoFocus:    false,
      data:         {
        caseId:         this.case.id,
        paymentMethodType,
        paymentProcessorType,
        amount,
        disabledAmount: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.success) {
          this.toastr.success(this.translate.instant('CASES.single.request-payment-success'),
            this.translate.instant('SHARED.success'));
        } else {
          this.toastr.error(this.translate.instant('CASES.single.request-payment-error'),
            this.translate.instant('SHARED.error'));
        }
      }
    });
  }

  public sendContract($event, sendTo) {
    $event.preventDefault();
    const data = {
      caseId:  this.case.id,
      send_to: sendTo,
    };
    this.caseService.sendContract(data).subscribe(next => {
        this.toastr.success(this.translate.instant('CASES.single.send-contract-success'));
      }, error => {
        this.toastr.error(this.translate.instant('CASES.single.send-contract-error'));
        this.summaryResponse = error.error;
      },
    );
  }

  public cancelLogaltyTransaction(logaltyTransactionId): void
  {
    this.caseService.cancelLogaltyTransaction(this.case.id, logaltyTransactionId).subscribe(res => {
      this.toastr.success('Request to cancel transaction sent');
    }, error => {
      this.toastr.error('Failed to send cancel transaction request');
    });
  }

  public previewEmail($event, emailType: 'SendInvoiceToCreditorNotification' | 'NewContract', caseId: number,
                      creditorId: number) {
    $event.preventDefault();

    this.caseCreditorService.previewEmail(caseId, creditorId, emailType).subscribe(result => {
        const emailHeader = {
          from:    result.data.from,
          to:      result.data.to,
          cc:      result.data.cc,
          subject: result.data.subject,
        };
        const dialogRef   = this.dialog.open(EmailPreviewComponent, {
          width:  '310mm',
          height: '100vh',
          data:   {
            title:       'Creditor Invoice Preview',
            previewType: 'email',
            emailHeader,
            emailBody:   result.data.body,
            attachments: result.data.attachments,
          },
        });

        dialogRef.afterClosed().subscribe(res => {
          if (res?.action === 'send-email') {
            this.caseCreditorService.sendEmail(caseId, creditorId, emailType).subscribe(
              r => this.toastr.success(
                this.translate.instant('CASES.single.reclama.toast-messages.invoice-sent-success'),
              ),
              errorResponse => {
                this.parseValidationErrors(errorResponse);
                this.translate.instant('CASES.single.reclama.toast-messages.invoice-sent-error');
              },
            );
          }
          if (res?.action === 'save-and-download-document' && res?.params?.type) {
            this.caseCreditorService.saveAndDownloadDocument(caseId, creditorId, res.params.type).subscribe(
              r => {
                if (r.type === 4) {
                  const fileName = res.params?.type + '_' + moment().format('YYYY-MM-DD_HH-mm') + '.pdf';
                  saveAs(r.body, fileName);
                  this.toastr.success('Document downloaded');
                }
              },
              errorResponse => this.parseValidationErrors(errorResponse),
            );
          }
        });
      }, errorResponse => this.parseValidationErrors(errorResponse),
    );
  }


  public addDocumentsToCreditorModal(i) {
    const amount        = this.case.loan_payment_plan.initial_fee;
    const creditors     = this.purposeForm.get('creditors') as FormArray;

    const dialogRef = this.dialog.open(showPaymentCreditorDocumentsModalComponent, {
      width:        '95%',
      height:       '80%',
      disableClose: true,
      autoFocus:    false,
      data:         {
        caseId:         this.case.id,
        creditor:       creditors.controls[i].get('creditor_id').value,
        disabledAmount: true,
      },
    });
  }

  public openCardForm(type: 'charge' | 'verify'): void {
    const amount = type === 'charge' && this.case.debt_payment_plan.initial_fee > 0 ? this.case.debt_payment_plan.initial_fee : 0.01;
    if (this.isComponentDirty()) {
      this.submitComponent();
    }
    const dialogRef = this.dialog.open(AdminPaymentHandlerComponent, {
      width:        '60%',
      minHeight:    200,
      disableClose: false,
      panelClass:   ['custom-mat-dialog', 'scrollable-mat-dialog'],
      autoFocus:    false,
      data:         {
        caseId:          this.case.id,
        clientRole:      'client',
        type:            'moto',
        amount,
        editableAmount:  true,
        paymentPlanType: 'additional_plans',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private fetchPaymentMethods(): void {
    this.isLoading++;
    this.paymentMethodService.index().pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.paymentMethods = result.data,
      error => console.log(error),
    );
  }

  public removeVariousFee(index: number): void {
    this.casePaymentPlanService.deleteVariousFee(this.case.id, index).subscribe(
      response => {
        // remove the row being deleted from the various fees table
        this.variousFees.splice(index, 1);
        this.fetchCase(this.case.id);
        this.toastr.success('Various fee deleted successfully');
      },
      error => {
        this.toastr.error('Failed to delete various fee');
      },
    );
  }

  private fetchVariousFees(): void {
    this.isLoading++;
    this.casePaymentPlanService.getVariousFees(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.variousFees = result.data;
        this.topUpLoanAmount = 0;
        this.variousFees.forEach(fee => {
          if (fee.description === 'Top Up Loan Amount') {
            this.topUpLoanAmount += fee.amount;
            this.topUpLoanCount++;
            this.totalAmountByCreditors += fee.amount;
          }
        });
      },
      error => console.log(error),
    );
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.productService.index({select_all: 1, active: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.products        = result.data;
        this.selectedProduct = this.products.find(product => product.id === this.case.product_id);
        if ( ! this.case.loan_payment_plan) {
          this.case.loan_payment_plan = this.getDefaultPaymentPlan(this.case);
        }

        this.buildPaymentPlanForm(this.case.loan_payment_plan);
        this.buildPaymentConfigurationForm(this.case.loan_payment_plan);
      },
    );
  }

  private fetchAprRate(): void {
    this.isLoading++;

    this.configService.getConfigData()
      .pipe(finalize(() => this.isLoading--))
      .subscribe(response => {
        // this.aprRate = response.data.find(configData => configData.key === 'apr_rate').value;
        this.loanArrangementFee = response.data.find(configData => configData.key === 'loan_arrangement_fee').value;
        this.debtReductionFee = response.data.find(configData => configData.key === 'debt_reduction_fee').value;
        this.fetchProducts();
    });
  }

  private getDefaultPaymentPlan(clientCase: Case): CasePaymentPlan {
    const selectedPaymentPlan = clientCase.product.payment_plans.find(
      plan => plan.is_joint === clientCase.joint_application,
    );

    if (!selectedPaymentPlan) {
      return null;
    }
    const phaseOne            = selectedPaymentPlan.payment_plan_phases.find(phase => phase.phase_order === 1);

    const paymentPlan = new CasePaymentPlan();

    paymentPlan.phase_one_value       = phaseOne.amount;
    paymentPlan.phase_one_monthly_fee = phaseOne.installment_amount;
    paymentPlan.phase_one_duration    = phaseOne.installment_duration;

    return paymentPlan;
  }

  public getPaymentMethodSlug(paymentMethodId: number): string {
    const paymentMethod = this.paymentMethods?.find(pMethod => {
      return Number(pMethod.id) === Number(paymentMethodId);
    });

    return paymentMethod?.slug;
  }

  public contractButtonDisabled(): boolean {
    return ! this.paymentPlanForm.get('phase_one_monthly_fee').value ||
      ! this.paymentConfigurationForm.get('payment_day').value || ! this.validIdCards(this.case) ||
      ! this.case.product_assigned_at || ! this.isCaseLoanApproved() ||
      ! this.doCreditorsAmountMatch() ||
       (this.creditorChangesSaved === this.creditorChangesSavedStatus['unsaved']);
      // || ! this.paymentPlanForm.get('phase_two_monthly_fee').value;
  }

  private parseValidationErrors(errorResponse: any) {
    const error = errorResponse.error;
    if (+error.code === 422 && error.errors) {
      for (const [key, value] of Object.entries(error.errors)) {
        const valueArray = value as any;
        valueArray.forEach(e => this.toastr.error(e));
      }
    }
  }

  public validIdCards(clientCase: Case): boolean {
    return clientCase.joint_application ?
      !! (clientCase.client?.id_card && clientCase.partner?.id_card) :
      !! (clientCase.client?.id_card);
  }

  public savePurposeButtonDisabled(): boolean {
    return !this.doCreditorsAmountMatch();
  }

  public savePaymentPlanButtonDisabled(): boolean {
    // check if the various fees are not empty and greater than zero
    return !this.paymentPlanForm.get('various_fees').value;
  }

  public isIbanCorrect(inputVal: string, i: number): void {
    const creditors = this.purposeForm.get('creditors') as FormArray;
    if (inputVal.trim().replace(/\s/g, '').length >= 15 && inputVal.trim().replace(/\s/g, '').length <= 33 ) {
      this.caseService.getIsCorrectIban(inputVal.trim().replace(/\s/g, ''))
        .subscribe(res => {
            if (res.data.code === "IBAN_API_ERROR"){
              console.log("isIbanCorrect -- IBAN_API_ERROR");
              creditors.controls[i].get('iban').setErrors({ 'invalidIBAN': true })
            }else{
              console.log(res.data.response[0].IsCorrect['0']);
              if (res.data.response[0].IsCorrect['0']==="True") {
                console.log("isIbanCorrect -- API SAYS VALID");
                creditors.controls[i].get('iban').setErrors({ 'invalidIBAN': false })
              }else{
                console.log("isIbanCorrect -- API SAYS INVALID");
                creditors.controls[i].get('iban').setErrors({ 'invalidIBAN': true })
              }
            }
        });
    }else{
      console.log("isIbanCorrect -- API not called");
      creditors.controls[i].get('iban').setErrors({ 'invalidIBAN': true })
    }
  }

  private clearIbanErrors(purposeForm: UntypedFormGroup): void {
    //let errorsCleared = false;
    const creditorsArray = purposeForm.get('creditors') as FormArray;
    creditorsArray.controls.forEach((creditorControl: AbstractControl) => {
      const ibanControl = creditorControl.get('iban');
      if (ibanControl) {
        ibanControl.clearValidators();
        ibanControl.updateValueAndValidity();
        //if (!ibanControl.errors) {
          //errorsCleared = true;
       // }
      }
    });
    //return errorsCleared;
  }


  private buildPaymentPlanForm(casePaymentPlan: CasePaymentPlan): void {
    // console.log('Payment Plan', casePaymentPlan)
    this.paymentPlanForm = this.fb.group({
      phase_one_value:        [casePaymentPlan ? casePaymentPlan.phase_one_value : 0, [Validators.min(50), Validators.required]],
      phase_one_monthly_fee:  [casePaymentPlan ? casePaymentPlan.phase_one_monthly_fee : 0, [Validators.required]],
      phase_one_duration:     [casePaymentPlan ? casePaymentPlan.phase_one_duration : 0, [Validators.min(1), Validators.required]],
      original_debt_amount:   [casePaymentPlan ? casePaymentPlan.original_debt_amount : 0, [Validators.min(1), Validators.required]],
      debt_settlement_agreed: [casePaymentPlan ? casePaymentPlan.debt_settlement_agreed : 0, [Validators.min(0), Validators.required]],
      debt_reduction_fee:     [casePaymentPlan ? casePaymentPlan.debt_reduction_fee : 0, [Validators.min(0), Validators.required]],
      repayable_amount:       [casePaymentPlan ? casePaymentPlan.repayable_amount : 0, [Validators.min(0), Validators.required]],
      various_fees:           [null],
      description: [null],
    });
    this.subscribeToPaymentPlanChanges();
  }

  private buildPaymentConfigurationForm(paymentConfig: CasePaymentPlan) {
    this.paymentConfigurationForm = this.fb.group({
      initial_fee:       [
        (!paymentConfig || paymentConfig.initial_fee === 0) ? null : paymentConfig.initial_fee,
        [Validators.min(1), Validators.max(5000)]],
      payment_method_id: [paymentConfig ? paymentConfig.payment_method_id : null, [Validators.required]],
      iban:              [paymentConfig ? paymentConfig.iban : null],
      payment_day:       [paymentConfig ? paymentConfig.payment_day : null, [Validators.required, Validators.min(1), Validators.max(28)]],
      contract_date:     [paymentConfig ? paymentConfig.contract_date : null, [Validators.required]],
      charge_amount:     [paymentConfig ? paymentConfig.charge_amount : null, [Validators.min(1), Validators.max(5000)]],
    });
  }

  private buildPurposeForm(): void {
    this.purposeForm = this.fb.group({
      purpose: [this.case?.purpose || ''],
      creditors: this.fb.array([]),
    });

    this.addCreditorsToForm(this.case.creditors);

    let totalOwed: number = 0;
    this.case.creditors.forEach(creditor => totalOwed += Number(creditor.pivot.negotiated_amount));
    this.totalAmountByCreditors = totalOwed;

    this.purposeForm.valueChanges.subscribe((value) => {
      if (!this.getFormArray().disabled) {
        this.recalculateTotalAmountOwed(value);
        if (this.creditorChangesSaved === this.creditorChangesSavedStatus['disabled']){
          this.creditorChangesSaved = this.creditorChangesSavedStatus['saved'];
        }else{
          this.creditorChangesSaved = this.creditorChangesSavedStatus['unsaved'];
        }

      }
    });
  }

  public getFormArray(): UntypedFormArray {
    return this.purposeForm.get('creditors') as UntypedFormArray;
  }

  private addCreditorsToForm(caseCreditors: Array<CaseCreditor>): void {
    // console.log(caseCreditors)
    if (caseCreditors?.length > 0) {
      caseCreditors.forEach(caseCreditor => {
        if(caseCreditor.pivot.type === 'unsecured') {
          this.addCreditor(caseCreditor);
          this.creditorChangesSaved === this.creditorChangesSavedStatus['unsaved'];
        }
      });
    }

    let totalOwed: number = 0;
    this.case.creditors.forEach(creditor => totalOwed += Number(creditor.pivot.negotiated_amount));
    this.totalAmountByCreditors = totalOwed;

    this.purposeForm.valueChanges.subscribe((value) => {
      if (!this.getFormArray().disabled) {
        this.recalculateTotalAmountOwed(value);
      }
    });
  }

  public addCreditor(caseCreditor: CaseCreditor = null): void {
    caseCreditor    = caseCreditor ? caseCreditor : this.getDefaultCaseCreditor();
    const formArray =  this.purposeForm.get('creditors') as UntypedFormArray;

    if (!caseCreditor.pivot.iban && caseCreditor.pivot.iban !== '') {
      if(caseCreditor.bank_accounts){
        caseCreditor.pivot.iban = caseCreditor.bank_accounts.find(bank_account => bank_account.default === 1)?.iban ?? '';
      }
    }

    const formGroup = this.fb.group({
      id:                   [caseCreditor.pivot.id],
      case_id:              [caseCreditor.pivot.case_id],
      creditor_id:          [caseCreditor.pivot.creditor_id, [Validators.required]],
      // iban:                 [(typeof caseCreditor.pivot.iban!='undefined' && caseCreditor.pivot.iban) ? caseCreditor.pivot.iban : caseCreditor.iban],
      negotiated_amount:    [caseCreditor.pivot.negotiated_amount, null],
      iban:                 [caseCreditor.pivot.iban, [Validators.required]],
      files: this.fb.array([]),
    });
    // console.log(caseCreditor)
    formArray.push(formGroup);
  }

  public removeCreditor(index: number): void {
    const formArray = this.getFormArray();
    if (formArray.disabled) {
      return;
    }
    formArray.removeAt(index);
  }

  private getDefaultCaseCreditor(): CaseCreditor {
    const caseCreditor           = new CaseCreditor();
    caseCreditor.approved        = 0;
    caseCreditor.pivot           = new CaseCreditorPivot();
    caseCreditor.pivot.ownership = 'applicant';
    caseCreditor.pivot.type      = 'unsecured';

    return caseCreditor;
  }

  public submitPaymentPlanForm(paymentPlanForm: UntypedFormGroup) {
    if (paymentPlanForm.invalid) {
      paymentPlanForm.markAllAsTouched();
      return;
    }
    this.isLoading++;
    this.submittingPaymentPlan = true;
    this.casePaymentPlanService.upsertPaymentPlan(this.case.id, paymentPlanForm.getRawValue())
      .pipe(finalize(() => this.submittingPaymentPlan = false))
      .subscribe(
        () => {
          this.isLoading--;
          this.paymentPlanForm.get('various_fees').setValue('');
          this.fetchCase(this.case.id);
          this.paymentPlanForm.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.payment.payment_plan.result.success'));
        },
        error => {
          this.paymentPlanResponse = error.error;
          this.toastr.error(this.translate.instant('CASES.editor.payment.payment_plan.result.error'),
            error.error.message);
        },
      );
  }

  public submitPaymentConfigurationForm(paymentConfigurationForm: UntypedFormGroup) {
    this.isLoading++;
    if (paymentConfigurationForm.invalid) {
      paymentConfigurationForm.markAllAsTouched();
      return;
    }
    this.submittingPaymentConfiguration = true;
    this.casePaymentPlanService.upsertPaymentConfig(this.case.id, paymentConfigurationForm.getRawValue())
      .pipe(finalize(() => this.submittingPaymentConfiguration = false))
      .subscribe(
        () => {
          this.paymentConfigurationForm.markAsPristine();
          this.reloadCase(this.case.id);
          this.toastr.success(this.translate.instant('CASES.editor.payment.payment_config.result.success'));
        },
        error => {
          this.paymentConfigResponse = error.error;
          this.toastr.error(
            this.translate.instant('CASES.editor.payment.payment_config.result.error'), error.error.message,
          );
        },
      );
  }

  public updateUserIdCard(idCard: string, relation: 'client' | 'partner') {
    this.case[relation].id_card = idCard;
  }

  public updatedClient(newUser: User, relation: 'client' | 'partner') {
    this.case[relation] = newUser;
  }

  calculateTotalRepaid() {
    this.totalRepaid = this.case.payments.reduce((n, {amount}) => n + amount, 0);
  }

  public displayDistributionWarningModal(): void {
    if (this.case.distribution && this.case.distribution.distribution_batch_id) {
      Swal.fire({
        text:               this.translate.instant('DISTRIBUTION.modals.prior-authorization'),
        icon:               'warning',
        showCancelButton:   false,
        confirmButtonText:  'OK',
        confirmButtonColor: '#4267B2',
      });
    } else if (this.case.distribution) {
      Swal.fire({
        text:               this.translate.instant('DISTRIBUTION.modals.notify-of-changes'),
        icon:               'warning',
        showCancelButton:   false,
        confirmButtonText:  'OK',
        confirmButtonColor: '#4267B2',
      });
    }
  }

  public submitPurposeForm(purposeForm: UntypedFormGroup): void {

    this.clearIbanErrors(purposeForm);
    if (purposeForm.invalid) {
      purposeForm.markAllAsTouched();
      return;
    }

    this.isSubmittingPurpose = true;
    this.caseCreditorService.upsert(this.case.id, this.purposeForm.getRawValue())
      .pipe(finalize(() => this.isSubmittingPurpose = false))
      .subscribe(
        () => {
          this.purposeForm.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
        },
        error => {
          console.log(error);
          this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
        },
      );
      this.creditorChangesSaved = this.creditorChangesSavedStatus['saved'];
  }

  private fetchCreditors(): void {
    this.isLoading++;

    this.creditorService.index({all: 0, active: 1, relatedToCase: this.case.id, unsecured: 1})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(response => {
        this.creditorsOption = response.data;
        this.creditorsOption.forEach(creditor => {
          this.creditorIbans[creditor.id] = creditor.bank_accounts.map(bank_account => {
            return {
              label: bank_account.iban + ' | ' + bank_account.description,
              value: bank_account.iban,
            }
          });

        });
        console.log('Creditor IBAN\'s', this.creditorIbans);
        this.buildPurposeForm();
      });
  }

  private fetchLogaltyTransactions(): void {
    this.isLoading++;

    this.caseService.getLogaltyTransactions(this.case.id).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        this.logaltyTransactions = res.data;
      });
  }

  public isCaseLoanApproved(){
    return this.caseStatusLog.some(item => item.status_id === 17);
  }

  public isCaseLoanEmmitted(){
    return this.caseStatusLog.some(item => item.status_id === 22);
  }

  togglePurposeInputs() {
    const formArray = this.getFormArray();

    if (formArray.disabled) {
      this.creditorChangesSaved = this.creditorChangesSavedStatus['disabled'];
      formArray.enable();
    } else {
      if (this.creditorChangesSaved === this.creditorChangesSavedStatus['saved']){
        formArray.disable();
      }
    }
  }

  public doCreditorsAmountMatch(): boolean {
    return Number(this.paymentPlanForm.get('phase_one_value').value) === this.roundTo(this.totalAmountByCreditors, 2);
  }

  public isIbanPresent(): boolean {
    if (!this.case.loan_payment_plan.hasOwnProperty('iban')){
      return false;
    }
    if (this.case.loan_payment_plan.iban === null){
      return false;
    }
    return !(this.case.loan_payment_plan.iban.trim().length === 0);
  }

  /**
   * Recalculates the total amount that is being owed by the borrower.
   *
   * @param value object of creditors.
   */
  private recalculateTotalAmountOwed(value): void {
    let sum: number = 0;
    sum = value.creditors.reduce((sum, creditor) => sum + parseFloat(creditor.negotiated_amount), 0);
    this.totalAmountByCreditors = sum;
  }

  private roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  private fetchStatusLogs(): void {
    this.isLoading++;

    this.caseService.getStatusLogs(this.case.id, {all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      res => {
        this.caseStatusLog = res.data;
        this.disablePurposeFormIfLoanApproved();
      });
  }

  public disablePurposeFormIfLoanApproved(): void {
    if(this.isCaseLoanEmmitted()){
      this.getFormArray().disable();
    }
  }

  public shouldShowLockButton(): boolean {
    if(this.isCaseLoanEmmitted()){
      return true;
    }
    return false;
  }

  public checkValue(event) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
  }

  public checkIfCreditorHasIban(creditorId: number): boolean {
    return Object.keys(this.creditorIbans[creditorId]).length === 0;
  }

}
