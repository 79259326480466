import { Product } from '../Product';
import { PaymentStatus } from '../Status/PaymentStatus';
import { Status } from '../Status/Status';
import { DialerList } from './DialerList';

export class DialerTrigger {
  id?: number;
  name: string;
  statusable_id?: number;
  statusable_type?: 'status' | 'payment_status' | 'call_status';
  dialer_list_id?: number;
  list_id: string;
  priority?: number;
  auto_queue?: 0 | 1 | boolean;
  active?: 0 | 1 | boolean;
  delay?: number;
  delay_unit?: 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month';
  force_dial: boolean;

  created_at: string | Date;
  updated_at: string | Date;

  // Relations
  statusable?: Status | PaymentStatus;
  products?: Array<Product>;

  // Helpers
  product_ids?: Array<number>;
  dialer_list?: DialerList;
}
