import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignaturePad } from 'ngx-signaturepad/signature-pad';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../../admin/case/case.service';
import { ClientService } from '../../client.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector:    'app-sign-asset-and-creditor-list',
  templateUrl: './sign-asset-and-creditor-list.component.html',
  styleUrls:   ['./sign-asset-and-creditor-list.component.scss'],
})
export class SignAssetAndCreditorListComponent implements OnInit, AfterViewInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public isLoadingDocument        = false;
  public isLoadingDocumentPreview = false;
  public caseUuid: string;
  public sign: any;
  public resign: any;
  public type: string;
  public documentId: string;
  public signaturePadOptions      = {
    minWidth:     1,
    maxWidth:     2,
    canvasWidth:  458,
    canvasHeight: 100,
  };
  public currentDate              = new Date();
  public signatureImg             = '';
  public prevSignatureImg         = '';
  public prevSignatureImgPartner  = '';
  public imgPath;
  public case: Case;
  public pdfFileName              = '';
  public loading                  = false;
  public documentHtml: string;
  public showLawyerSignature      = false;
  public companyGdprEmail         = environment.EMAIL_GDPR;
  public companyAddress           = environment.COMPANY_ADDRESS;
  public companyNameLegal         = environment.COMPANY_NAME_LEGAL;
  public companyName              = environment.COMPANY_NAME;

  constructor(
      private elRef: ElementRef,
      private caseService: CaseService,
      private clientService: ClientService,
      private router: Router,
      private route: ActivatedRoute,
      private http: HttpClient,
      private toast: ToastrService,
      public translateService: TranslateService,
  ) {
    this.imgPath = environment.STORAGE_URL + '/';
  }

  ngOnInit(): void {
    this.caseUuid   = this.route.snapshot.queryParamMap.get('uuid');
    this.sign       = this.route.snapshot.queryParamMap.get('sign');
    this.resign     = this.route.snapshot.queryParamMap.get('resign');
    this.type       = this.route.snapshot.queryParamMap.get('type');
    this.documentId = this.route.snapshot.queryParamMap.get('doc');
    console.log(this.sign);
    this.caseService.caseInfo(this.caseUuid)
        .pipe(finalize(() => {
          this.isLoadingDocument = false;
        })).subscribe(next => {
      this.case = next.data;

      if (this.case[this.type][0] && this.case[this.type][0].client_signature[0]) {
        this.prevSignatureImg = this.case[this.type][0].client_signature[0].signature;
      }

      if (this.case[this.type][0] && this.case[this.type][0].partner_signature[0]) {
        this.prevSignatureImgPartner = this.case[this.type][0].partner_signature[0].signature;
      }

      if (this.case[this.type][0] && this.case[this.type][0].pdf_location) {
        const pdfLocation = this.case[this.type][0].pdf_location;
        this.pdfFileName  = pdfLocation.split('/')[3];
      }

      this.clientService.previewAssetCreditorList(this.caseUuid, this.type).pipe(finalize(() => {
        this.isLoadingDocumentPreview = false;
        setTimeout(() => {
          this.scrollToElement('sign');
        }, 500);
      })).subscribe(
          result => {
            this.documentHtml = result.data;
          },
      );
    });

    const viewData = {
      viewed_by: '',
      type:      this.type,
    };

    if (this.sign === 'partner') {
      viewData.viewed_by = 'partner';
    } else {
      viewData.viewed_by = 'client';
    }

    // this.caseService.sendCreditorAssetListViewedTime(this.caseUuid, viewData).subscribe(obj => {
    // });
  }

  ngAfterViewInit() {
    // this.signaturePad.clear();
  }

  drawComplete() {
    this.signatureImg = this.signaturePad.toDataURL();
  }

  public clear() {
    this.signaturePad.clear();
    this.signatureImg = '';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const div = this.elRef.nativeElement.querySelector('.signature-wrap')?.offsetWidth;
    if (div !== 0) {
      this.signaturePad.set('canvasWidth', div);
    }
  }

  drawStart() {
  }

  scrollToElement($element): void {
    const element = document.getElementById($element);
    element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
  }

  saveSignature() {
    this.loading = true;
    const data   = new FormData();

    data.append('signature', this.signatureImg);
    data.append('signed_by', this.sign);
    data.append('type', this.type);
    data.append('app_document_id', this.documentId);
    //
    this.caseService.saveCreditorAssetListSignature(this.case.id, data)
        .pipe(finalize(() => this.loading = false))
        .subscribe(
            next => {
              if (this.sign !== 'partner') {
                this.prevSignatureImg = next.data.signature;
              } else {
                this.prevSignatureImgPartner = next.data.signature_1;
              }
              Swal.fire({
                position:           'top',
                title:              this.translateService.instant('CASES.single.draft.document-signed'),
                icon:               'success',
                confirmButtonText:  'Ok',
                confirmButtonColor: '#886ab5',
              });
            },
            error => this.toast.error('Sign failed. Please try again later.', 'Error'),
        );
  }

}
