import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CallStatus} from '../../../../../../_base-shared/models/Status/CallStatus';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {CallStatusService} from '../call-status.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-call-status-editor',
  templateUrl: './call-status-editor.component.html',
  styleUrls: ['./call-status-editor.component.scss']
})
export class CallStatusEditorComponent implements OnInit {
  public isLoading = 0;
  public form: UntypedFormGroup;
  public editorType: 'create' | 'edit';
  public callStatus: CallStatus;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private callStatusService: CallStatusService,
    private toastr: ToastrService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.editor;
    if (this.editorType === 'edit') {
      this.fetchStatus(+this.route.snapshot.paramMap.get('id'));
    } else {
      this.callStatus = new CallStatus();
      this.buildForm();
    }
  }

  private fetchStatus(callStatusId: number) {
    this.isLoading++;
    this.callStatusService.get(callStatusId).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.callStatus = result.data;
        this.buildForm();
      });
  }

  private buildForm() {
    this.form = this.fb.group({
      name_es: [this.callStatus.name_es, Validators.required],
      name_en: [this.callStatus.name_en, Validators.required]
    });
  }

  public onSubmit() {
    if (this.editorType === 'create') {
      this.callStatusService.store(this.form.value)
        .subscribe(res => {
            this.router.navigateByUrl('/call-status');
            this.toastr.success(
              `${ this.translateService.instant('CONFIG.call-status.call-status') } ${ this.translateService.instant(
                'SHARED.item-added') }`);
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          });
    } else {
      this.callStatusService.update(this.callStatus.id, this.form.value)
        .subscribe(res => {
            this.router.navigateByUrl('/call-status');
            this.toastr.success(
              `${ this.translateService.instant('CONFIG.call-status.call-status') } ${ this.translateService.instant(
                'SHARED.item-edited') }`);
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          },
        );
    }
  }

}
