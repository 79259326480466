import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Product } from '../../../../../_base-shared/models/Product';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentProcessorService extends MainBaseApiService {
  public index(requestData = {}) {
    const params = MainBaseApiService.convertFiltersForRequest(requestData, 'get');
    return this.http.get<LaravelResponse<Array<Product>>>(this.apiUrl + '/payment-processors', {params})
        .pipe(catchError(response => this.handleError(response)));
  }
}
