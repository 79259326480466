import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { PermissionGuard } from '../../../../../_base-shared/guards/permission.guard';
import {CallStatusListComponent} from './call-status-list/call-status-list.component';
import {CallStatusEditorComponent} from './call-status-editor/call-status-editor.component';

const routes: Routes = [
  {
    path: 'call-statuses',
    children: [
      {path: '', canActivate: [PermissionGuard], component: CallStatusListComponent},
      {path: 'create', canActivate: [PermissionGuard], component: CallStatusEditorComponent, data: {editor: 'create'}},
      {path: ':id/edit', canActivate: [PermissionGuard], component: CallStatusEditorComponent, data: {editor: 'edit'}}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class CallStatusRoutingModule {
}
