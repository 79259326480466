import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, first, take } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor, CaseCreditorPivot } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { TownHall } from '../../../../../../../_base-shared/models/Entity/TownHall';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { CreditorService } from '../../../creditor/creditor.service';
import { TownHallService } from '../../../legal-entity/town-hall.service';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { UserService } from '../../../user/user.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-creditor-editor',
  templateUrl: './case-creditor-editor.component.html',
  styleUrls:   ['./case-creditor-editor.component.scss'],
})
export class CaseCreditorEditorComponent implements OnInit {
  public case: Case;
  public isLoading                                      = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public townHalls: Array<TownHall>                     = [];
  public singleOwnershipOptions: Array<AppSelectOption> = [];
  public jointOwnershipOptions: Array<AppSelectOption>  = [];
  public publicOrganizations: Array<AppSelectOption>    = [];
  public publicDebtTypes: Array<AppSelectOption>        = [];
  public securedDebtTypes: Array<AppSelectOption>       = [];
  public unsecuredDebtTypes: Array<AppSelectOption>     = [];
  public typesOfGuarantee: Array<AppSelectOption>       = [];
  public allowMakeAsClaim: boolean;

  public creditors: Array<Creditor>         = [];
  public recoveryCreditors: Array<Creditor> = [];

  public totalDebt           = 0;
  public unsecuredDebt       = 0;
  public totalMonthlyPayment = 0;
  public hasDgsFields: boolean;
  public dgsLegalAdvisor: boolean;
  private authUser: User;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventService: MainGlobalEventService,
    private userService: UserService,
    private caseService: CaseService,
    private creditorService: CreditorService,
    private caseCreditorService: CaseCreditorService,
    private financialOverviewService: FinancialOverviewService,
    private townHallService: TownHallService,
  ) {
  }

  ngOnInit(): void {
    this.buildSelectOptions();
    this.fetchTownHalls(null);
    this.translate.onLangChange.subscribe(() => this.buildSelectOptions());
    this.globalEventService.authUser$.subscribe(user => {
      this.authUser = user;
      this.getAuthUser(user.id);
    });
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['product', 'creditors', 'public_debts'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          if (this.case.product_assigned_at) {
            this.allowMakeAsClaim = this.case.product.group_slug === 'dgs' ? true : false;
          } else {
            this.allowMakeAsClaim = this.dgsLegalAdvisor ? true : false;
          }
          this.hasDgsFields             = ! this.case.product_assigned_at || this.case.product.group_slug === 'dgs';
          this.case.unsecured_creditors = this.case.creditors.filter(creditor => creditor.pivot.type === 'unsecured');
          this.case.secured_creditors   = this.case.creditors.filter(creditor => creditor.pivot.type === 'secured');
          this.case.claim_creditors     = this.case.creditors.filter(creditor => creditor.pivot.type === 'claim');
          this.buildForm(this.case);
        });
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseCreditorService.upsert(this.case.id, form.getRawValue())
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        () => {
          this.form.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
        },
        error => {
          this.serverResponse = error.error;
          this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
        },
      );
  }

  public isComponentDirty(): boolean {
    return this.form.dirty;
  }

  public isComponentValid(): boolean {
    return this.form.valid;
  }

  public submitComponent() {
    return this.submitForm(this.form);
  }

  private updateTotalDebt(updateFinancialOverview = true) {
    this.totalDebt           = 0;
    this.unsecuredDebt       = 0;
    this.totalMonthlyPayment = 0;

    this.getFormArray('public_debts').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });

    this.getFormArray('secured_creditors').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });

    this.getFormArray('unsecured_creditors').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.unsecuredDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });

    this.getFormArray('claim_creditors').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });
    if (updateFinancialOverview) {
      this.financialOverviewService.financialOverview$.pipe(take(1)).subscribe(overview => {
        if (overview) {
          overview.total_debt      = this.totalDebt;
          overview.monthly_payment = this.totalMonthlyPayment;
          overview.unsecured_debt  = this.unsecuredDebt;
          this.financialOverviewService.updateFinancialOverviewLocally(overview);
        }
      });
    }
  }

  private getAuthUser(userId: number) {
    this.isLoading++;
    this.userService.get(userId, {}).pipe(finalize(() => this.isLoading--)).subscribe(user => {
      this.authUser = user.data;
      this.authUser.department_assignments?.forEach(assignment => {
        if (assignment.department_id === 2) {
          this.dgsLegalAdvisor = !! assignment.dgs_group;
        }
      });
    });
  }

  private buildForm(clientCase: Case) {
    this.form = this.fb.group({
      public_debts:        this.fb.array([]),
      unsecured_creditors: this.fb.array([]),
      secured_creditors:   this.fb.array([]),
      claim_creditors:     this.fb.array([]),
    });
    this.addPublicDebts();
    this.addCreditorsToForm(clientCase.unsecured_creditors, 'unsecured_creditors');
    this.addCreditorsToForm(clientCase.secured_creditors, 'secured_creditors');
    this.addCreditorsToForm(clientCase.claim_creditors, 'claim_creditors');

    this.fetchCreditors();
    this.updateTotalDebt();

    this.form.get('public_debts').valueChanges.subscribe(() => this.updateTotalDebt());
    this.form.get('unsecured_creditors').valueChanges.subscribe(() => this.updateTotalDebt());
    this.form.get('secured_creditors').valueChanges.subscribe(() => this.updateTotalDebt());
    this.form.get('claim_creditors').valueChanges.subscribe(() => this.updateTotalDebt());
  }

  private addPublicDebts(): void {
    if (this.case.public_debts?.length > 0) {
      this.case.public_debts.forEach(publicDebt => {
        this.addPublicDebt(publicDebt);
      });
    }
  }

  private addCreditorsToForm(caseCreditors: Array<CaseCreditor>,
                             type: 'unsecured_creditors' | 'secured_creditors' | 'claim_creditors') {
    if (caseCreditors?.length > 0) {
      caseCreditors.forEach(caseCreditor => {
        this.addCreditor(type, caseCreditor);
      });
    }
  }

  public checkCreditors($event, type: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors', index: number) {
    const selectedCreditor    = this.creditors.find(creditor => creditor.id === $event);
    const approvedFormControl = this.getFormArray(type).at(index).get('approved');
    if (approvedFormControl.value !== selectedCreditor.approved) {
      approvedFormControl.patchValue(selectedCreditor.approved);
      approvedFormControl.updateValueAndValidity();
    }
    const lenderTypeFormControl = this.getFormArray(type).at(index).get('lender_type');
    if (lenderTypeFormControl.value !== selectedCreditor.lender_type) {
      lenderTypeFormControl.patchValue(selectedCreditor.lender_type);
      lenderTypeFormControl.updateValueAndValidity();
    }
  }

  public getFormArray(creditorType: 'public_debts' | 'unsecured_creditors' | 'secured_creditors' | 'claim_creditors') {
    return this.form.get(creditorType) as UntypedFormArray;
  }

  public addPublicDebt(publicDebt: CasePublicDebt = null) {
    publicDebt = publicDebt ? publicDebt : this.getDefaultPublicDebt();

    const formArray = this.getFormArray('public_debts');

    const formGroup = this.fb.group({
      id:                     [publicDebt.id],
      town_hall_id:           [
        publicDebt.town_hall_id,
        publicDebt.public_organisation === 'town-hall' ? [Validators.required] : [],
      ],
      ownership:              [
        {value: publicDebt.ownership, disabled: ! this.case.joint_application}, [Validators.required],
      ],
      interested_third_party: [publicDebt.interested_third_party],
      public_organisation:    [publicDebt.public_organisation],
      debt_type:              [publicDebt.debt_type],
      initial_balance:        [publicDebt.initial_balance],
      current_balance:        [publicDebt.current_balance],
      monthly_payments:       [publicDebt.monthly_payments],
      making_payments:        [publicDebt.making_payments],
      reference_number:       [publicDebt.reference_number],
      judicial_claim:         [publicDebt.judicial_claim],
      response_received:      [publicDebt.response_received],
      verified:               [publicDebt.verified],
      city:                   [
        {value: publicDebt.city, disabled: publicDebt.public_organisation !== 'town-hall'},
      ],
      notes:                  [publicDebt.notes],
    });

    formArray.push(formGroup);
  }

  public addCreditor(formArrayName: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors',
                     caseCreditor: CaseCreditor = null) {
    caseCreditor    = caseCreditor ? caseCreditor : this.getDefaultCaseCreditor(formArrayName === 'secured_creditors');
    const formArray = this.getFormArray(formArrayName);
    const formGroup = this.fb.group({
      approved:             [{value: caseCreditor.approved, disabled: true}],
      lender_type:          [caseCreditor.lender_type],
      id:                   [caseCreditor.pivot.id],
      case_id:              [caseCreditor.pivot.case_id],
      creditor_id:          [caseCreditor.pivot.creditor_id, [Validators.required]],
      recovery_creditor_id: [
        caseCreditor.pivot.recovery_creditor_id,
        !! caseCreditor.pivot.recovery_creditor_id ? [Validators.required] : []],
      ownership:            [
        {value: caseCreditor.pivot.ownership, disabled: ! this.case.joint_application},
        [Validators.required],
      ],
      additional_partner:   [caseCreditor.pivot.additional_partner, null],
      debt_type:            [caseCreditor.pivot.debt_type, null],
      type_of_guarantee:    [caseCreditor.pivot.type_of_guarantee, null],
      initial_balance:      [caseCreditor.pivot.initial_balance, null],
      current_balance:      [caseCreditor.pivot.current_balance, null],
      monthly_payments:     [caseCreditor.pivot.monthly_payments, null],
      making_payments:      [caseCreditor.pivot.making_payments, null],
      tae:                  [caseCreditor.pivot.tae, null],
      restructured:         [caseCreditor.pivot.restructured, null],
      reference_number:     [caseCreditor.pivot.reference_number, null],
      judicial_claim:       [caseCreditor.pivot.judicial_claim, null],
      verified:             [caseCreditor.pivot.verified, null],
      notes:                [caseCreditor.pivot.notes, null],
      total_repaid:         [caseCreditor.pivot.total_repaid, null],
      has_recovery:         [ !! caseCreditor.pivot.recovery_creditor_id, null],
    });

    formArray.push(formGroup);
  }

  public removeCreditor(creditorType: 'public_debts' | 'unsecured_creditors' | 'secured_creditors' | 'claim_creditors',
                        index: number) {
    const formArray = this.getFormArray(creditorType);
    formArray.removeAt(index);
  }

  private fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
          this.creditors         = result.data;
          this.recoveryCreditors = result.data.filter(creditor => creditor.can_be_recovery);
          // this.checkCreditors();
        },
        err => console.error(err),
      );
  }

  public publicOrganizationChanged(newSelection: string, index: number) {
    if (newSelection === 'town-hall') {
      this.getFormArray('public_debts').at(index).get('city').enable();
      this.getFormArray('public_debts').at(index).get('town_hall_id').enable();
      this.getFormArray('public_debts').at(index).get('town_hall_id').setValidators([Validators.required]);
    } else {
      this.getFormArray('public_debts').at(index).get('city').patchValue(null);
      this.getFormArray('public_debts').at(index).get('city').disable();
      this.getFormArray('public_debts').at(index).get('town_hall_id').patchValue(null);
      this.getFormArray('public_debts').at(index).get('town_hall_id').disable();
      this.getFormArray('public_debts').at(index).get('town_hall_id').setValidators([]);
    }
    this.getFormArray('public_debts').at(index).get('city').updateValueAndValidity();
    this.getFormArray('public_debts').at(index).get('town_hall_id').updateValueAndValidity();
  }

  public hasRecoveryChanged(arrayName: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors', newValue: any,
                            index: number) {
    if (newValue) {
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').enable();
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').setValidators([Validators.required]);
    } else {
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').patchValue(null);
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').disable();
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').setValidators([]);
    }
    this.getFormArray(arrayName).at(index).get('recovery_creditor_id').updateValueAndValidity();
  }

  private buildSelectOptions() {
    this.singleOwnershipOptions = [
      {value: 'applicant', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.applicant')},
    ];

    this.jointOwnershipOptions = [
      {
        value: 'applicant',
        label: this.translate.instant('CASE_CREDITOR.model.ownership.options.applicant_joint'),
      },
      {value: 'partner', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.partner')},
      {value: 'joint', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.joint')},
    ];

    this.publicOrganizations = [
      {
        value: 'social-security',
        label: this.translate.instant('CASE_CREDITOR.model.public_organization.options.social-security'),
      },
      {value: 'estate', label: this.translate.instant('CASE_CREDITOR.model.public_organization.options.estate')},
      {value: 'town-hall', label: this.translate.instant('CASE_CREDITOR.model.public_organization.options.town-hall')},
    ];

    this.publicDebtTypes = [
      {value: 'tax', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.tax')},
      {value: 'capital-gain', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.capital-gain')},
      {value: 'penalty-fee', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.penalty-fee')},
    ];

    this.securedDebtTypes = [
      {value: 'mortgage', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.mortgage')},
      {value: 'personal-loan', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.personal-loan')},
      {value: 'car-finance', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.car-finance')},
      {
        value: 'domain-reservation',
        label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.domain-reservation'),
      },
      {value: 'endorsement', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.endorsement')},
      {value: 'guarantor', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.guarantor')},
      {value: 'other', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.other')},
    ];

    this.unsecuredDebtTypes = [
      {value: 'loan', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.loan')},
      {value: 'card', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.card')},
      {value: 'tax', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.tax')},
      {value: 'overdraft', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.overdraft')},
    ];

    this.unsecuredDebtTypes.push(
      {value: 'other', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.other')},
    );

    this.typesOfGuarantee = [
      {
        value: 'mortgage',
        label: this.translate.instant('CASE_CREDITOR.model.type_of_guarantee.options.mortgage'),
      },
      {
        value: 'domain_reservation',
        label: this.translate.instant('CASE_CREDITOR.model.type_of_guarantee.options.domain_reservation'),
      },
      {
        value: 'warranty',
        label: this.translate.instant('CASE_CREDITOR.model.type_of_guarantee.options.warranty'),
      },
    ];
  }

  public fetchTownHalls(search: string) {
    this.isLoading++;
    this.townHallService.index({select_all: 1, search}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.townHalls = result.data,
      error => console.error(error),
    );
  }

  private getDefaultPublicDebt(): CasePublicDebt {
    const newPublicDebt     = new CasePublicDebt();
    newPublicDebt.ownership = 'applicant';

    return newPublicDebt;
  }

  private getDefaultCaseCreditor(isSecured: boolean): CaseCreditor {
    const caseCreditor           = new CaseCreditor();
    caseCreditor.approved        = 0;
    caseCreditor.pivot           = new CaseCreditorPivot();
    caseCreditor.pivot.ownership = 'applicant';
    caseCreditor.pivot.type      = isSecured ? 'secured' : 'unsecured';

    return caseCreditor;
  }

  public toggleType(creditorType: 'unsecured' | 'claim', $event, i) {
    if (creditorType === 'unsecured') {
      const creditor   = this.case.unsecured_creditors.find(
        el => el.pivot.creditor_id === $event.getRawValue().creditor_id);
      const creditorId = creditor.pivot.id;
      this.caseService.toggleIsClaimable(this.case.id, creditorId, {type: 'claim'})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.serverResponse = error.error;
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.addToggleCreditor('claim_creditors', $event.getRawValue());
      this.removeCreditor('unsecured_creditors', i);
    } else if (creditorType === 'claim') {
      const creditor = this.case.claim_creditors.find(el => el.pivot.creditor_id === $event.getRawValue().creditor_id);
      this.caseService.toggleIsClaimable(this.case.id, creditor.pivot.id, {type: 'unsecured'})
        .pipe(first())
        .subscribe(
          (data) => {
            this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          },
          error => {
            this.serverResponse = error.error;
            this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
          },
        );
      this.addToggleCreditor('unsecured_creditors', $event.getRawValue());
      this.removeCreditor('claim_creditors', i);
    }
  }

  public addToggleCreditor(formArrayName: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors',
                           caseCreditor: any) {
    const formArray = this.getFormArray(formArrayName);
    const formGroup = this.fb.group({
      approved:             [caseCreditor.approved],
      lender_type:          [caseCreditor.lender_type],
      id:                   [caseCreditor.id],
      case_id:              [caseCreditor.case_id],
      creditor_id:          [caseCreditor.creditor_id, [Validators.required]],
      recovery_creditor_id: [
        caseCreditor.recovery_creditor_id,
        !! caseCreditor.recovery_creditor_id ? [Validators.required] : []],
      ownership:            [
        {value: caseCreditor.ownership, disabled: ! this.case.joint_application},
        [Validators.required],
      ],
      additional_partner:   [caseCreditor.additional_partner, null],
      debt_type:            [caseCreditor.debt_type, null],
      type_of_guarantee:    [caseCreditor.type_of_guarantee, null],
      initial_balance:      [caseCreditor.initial_balance, null],
      current_balance:      [caseCreditor.current_balance, null],
      monthly_payments:     [caseCreditor.monthly_payments, null],
      tae:                  [caseCreditor.tae, null],
      restructured:         [caseCreditor.restructured, null],
      making_payments:      [caseCreditor.making_payments, null],
      reference_number:     [caseCreditor.reference_number, null],
      judicial_claim:       [caseCreditor.judicial_claim, null],
      verified:             [caseCreditor.verified, null],
      notes:                [caseCreditor.notes, null],
      total_repaid:         [caseCreditor.total_repaid, null],
      has_recovery:         [ !! caseCreditor.recovery_creditor_id, null],
      claim_type:           [caseCreditor.claim_type, null],
      initial_claim:        [caseCreditor.initial_claim, null],
      actual_claim:         [caseCreditor.actual_claim, null],
      interest_rate:        [caseCreditor.interest_rate, null],
      invoice_status:       [caseCreditor.invoice_status, null],
    });
    formArray.push(formGroup);
  }

  public getOutcome(formGroup) {
    const initialBalance = formGroup.get('initial_balance').value;
    const totalRepaid    = formGroup.get('total_repaid').value;
    if (initialBalance === null || totalRepaid === null) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.undetermined');
    }
    if (initialBalance === totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.cancel_debt');
    }
    if (initialBalance > totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.pay_difference');
    }
    if (initialBalance < totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.payback');
    }
  }

  public getAmountToCondone(amount: number, currentBalance: number) {
    return amount > 0 ? currentBalance - amount : currentBalance;
  }
}
