import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../../_shared/guards/main-auth-guard.service';
import { PermissionGuard } from '../../../../../_base-shared/guards/permission.guard';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserEditorComponent } from './user-editor/user-editor.component';
import { UserListComponent } from './user-list/user-list.component';

const routes: Routes = [
  {
    path:     'users',
    children: [
      {path:         '',
        canActivate: [MainAuthGuard, PermissionGuard],
        component:   UserListComponent,
        data:        {type: 'admin-user-list'},
      },
      {path: 'create', canActivate: [PermissionGuard], component: UserEditorComponent},
      {path: ':id/edit', canActivate: [PermissionGuard], component: UserEditorComponent},
      {path: ':id', canActivate: [PermissionGuard], component: UserDetailComponent},
    ],
  },
  {path:         'employee-list',
    canActivate: [MainAuthGuard],
    component:   UserListComponent,
    data:        {type: 'admin-employee-list'},
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class UserRoutingModule {
}
