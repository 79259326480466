import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-asset-list',
  templateUrl: './sign-asset-list.component.html',
  styleUrls: ['./sign-asset-list.component.scss']
})
export class SignAssetListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
