<div>
  <div class="row">
    <div class="col-md-6">
      <!-- Payment Plan Form -->
      <div class="card shadow mb-4">
        <div class="card-header"><h3>{{ 'CASES.editor.payment.payment_plan.heading' | translate}}</h3></div>
        <div class="card-body">
          <div class="row">
            <div [hidden]="!isLoading || isLoading <= 0" class="col-12">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
            <div *ngIf="paymentPlanResponse" class="col-12">
              <app-server-response [response]="paymentPlanResponse"></app-server-response>
            </div>
          </div>
          <form *ngIf="paymentPlanForm" [formGroup]="paymentPlanForm">
            <div class="row">
              <!-- Phase One -->
              <div class="col-6">
                <!-- Phase One Total Value -->
                <app-input type="number" formControlName="phase_one_value" [extraLabel]="false" [fullWidth]="true"
                           readonly [label]="('CASES.single.loan-amount' | translate) + ' (inc VAT)'">
                </app-input>
                <!-- New Loan Amount -->
                <app-input type="number" formControlName="repayable_amount" [extraLabel]="false" [fullWidth]="true"
                           readonly [label]="('CASE_CREDITOR.model.outcomes.total_repayable' | translate) + ' (inc VAT)'">
                </app-input>
                <!-- Phase One Monthly Fee -->
                <app-input type="number" formControlName="phase_one_monthly_fee" [extraLabel]="false" readonly
                           [label]="'CASES.single.monthly-payment' | translate" [fullWidth]="true">
                </app-input>
                <!-- Phase One Duration -->
                <app-input type="number" formControlName="phase_one_duration" [extraLabel]="false" readonly
                           [label]="'CASES.single.loan-duration' | translate" [fullWidth]="true">
                </app-input>
              </div>
              <div class="col-6">
                <app-input type="number" formControlName="original_debt_amount" [extraLabel]="false" readonly
                           [label]="'original debt'" [fullWidth]="true">
                </app-input>
                <app-input type="number" formControlName="debt_settlement_agreed" [extraLabel]="false" readonly
                           [label]="'Debt settlement agreed'" [fullWidth]="true">
                </app-input>
                <app-input type="number" formControlName="debt_reduction_fee" [extraLabel]="false" readonly
                           readonly [label]="'Debt reduction fee'" [fullWidth]="true">
                </app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <span>Add additional fees</span>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <app-input type="select" formControlName="description" required
                            label="Description" [selectOptions]="variousFeesDescription"
                            [selectLabel]="'label'" [selectValue]="'value'" [fullWidth]="true">
                </app-input>
              </div>
              <div class="col-6">
                <app-input type="number" formControlName="various_fees" [extraLabel]="false" minError="0" (input)="checkValue($event)"
                           [label]="'CASE_CREDITOR.model.outcomes.various_fees' | translate" [fullWidth]="true">
                </app-input>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <app-spinner-btn *ngIf="paymentPlanForm" type="submit" [loading]="submittingPaymentPlan" [name]="'SHARED.save' | translate"
                             [disabled]="savePaymentPlanButtonDisabled()"
                             (click)="submitPaymentPlanForm(paymentPlanForm)">
            </app-spinner-btn>
          </div>
        </div>
      </div>
      <!-- ID Card Form -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h3>{{ 'CASE_CREDITOR.model.outcomes.various_fees' | translate }}</h3>
        </div>
        <div class="card-body">
          <table mat-table matSort [dataSource]="variousFees" width="100%">
            <tr mat-header-row *matHeaderRowDef="variousFeesTable"></tr>
            <tr mat-row *matRowDef="let row; columns: variousFeesTable;"></tr>
            <!-- Date -->
            <ng-container matColumnDef="date">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.single.date' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.created_at | toDateObject | date:'dd/MM/yy HH:mm' }} </td>
            </ng-container>
            <!-- Description -->
            <ng-container matColumnDef="description">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CONFIG.sms-template.description' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.description }} </td>
            </ng-container>
            <!-- Amount -->
            <ng-container matColumnDef="amount">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.single.amount' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.amount | number: '1.2-2' }}&euro;</td>
            </ng-container>
            <!-- By Who -->
            <ng-container matColumnDef="fullname">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASES.single.done-by' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.fullname }} </td>
            </ng-container>
            <!-- Actions -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.actions' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="warn" class="ml-2" (click)="removeVariousFee(element.id)">
                  <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                </button>
              </td>
            </ng-container>
          </table>
        </div>
      </div>
      <!-- ID Card Form -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h3>{{ 'CASES.editor.payment.id_card.heading' | translate }}</h3>
        </div>
        <div class="card-body">
          <!-- Client ID Card-->
          <app-case-id-card-editor *ngIf="case?.id" [case]="case" [user]="case.client"
                                   [inputLabel]="'Borrower ' + ('USERS.model.id_card' | translate)"
                                   (updatedIdCard)="updateUserIdCard($event, 'client')"
                                   (updatedClient)="updatedClient($event, 'client')">
          </app-case-id-card-editor>
          <!-- Partner ID Card -->
          <app-case-id-card-editor *ngIf="case?.id && case.joint_application" [case]="case" [user]="case.partner"
                                   [inputLabel]="'Guarantor ' + ('USERS.model.id_card' | translate)" class="mt-3"
                                   (updatedIdCard)="updateUserIdCard($event, 'partner')"
                                   (updatedClient)="updatedClient($event, 'partner')">
          </app-case-id-card-editor>
        </div>
      </div>
      <!-- Payment Configuration -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h3>{{ 'CASES.editor.payment.payment_config.heading' | translate }}</h3>
        </div>
        <div class="card-body">
          <form *ngIf="paymentConfigurationForm" [formGroup]="paymentConfigurationForm">
            <!-- Payment Day -->
            <app-input type="select" formControlName="payment_day" [extraLabel]="true"
                       [label]="'CASES.single.payment-day' | translate" [selectOptions]="daysInMonth"
                       [selectLabel]="'label'" [selectValue]="'value'" [fullWidth]="true">
            </app-input>
            <!-- Contract Date -->
            <div class="row">
              <div class="col-6">
                <mat-label>{{"CASES.single.contract-date" | translate}}</mat-label>
              </div>
              <div class="col-6">
                <div class="form-group" style="margin-bottom: 0">
                  <mat-form-field color="primary" appearance="fill" class="block-picker">
                    <mat-label>{{"CASES.single.contract-date" | translate}}</mat-label>
                    <input matInput [matDatepicker]="contractDate" formControlName="contract_date">
                    <mat-datepicker-toggle matSuffix [for]="contractDate"></mat-datepicker-toggle>
                    <mat-datepicker #contractDate color="primary"></mat-datepicker>
                  </mat-form-field>
                  <mat-error *ngIf="hasError(paymentConfigurationForm, 'contract_date')">
                    {{ 'SHARED.field-required' | translate }}
                  </mat-error>
                </div>
              </div>
            </div>
            <!-- Payment Method -->
            <app-input *ngIf="paymentMethods.length" type="select"
                       formControlName="payment_method_id" [extraLabel]="true" [fullWidth]="true"
                       [label]="'CASES.single.payment-type' | translate" [selectOptions]="paymentMethods"
                       [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
            <!-- Initial Card Fee -->
            <div class="row">
              <div class="col-12" style="margin-bottom: 24px">
                <!-- Initial Fee -->
                <app-input type="number" formControlName="initial_fee" [fullWidth]="true"
                           [label]="('CASES.single.initial-fee' | translate)" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <!-- SEPA IBAN -->
            <app-input *ngIf="getPaymentMethodSlug(paymentConfigurationForm.get('payment_method_id').value) === 'sepa'"
                       formControlName="iban" [label]="'CASES.single.iban' | translate" [extraLabel]="true"
                       [fullWidth]="true">
            </app-input>
            <!-- Actions -->
            <div class="row">
              <div class="col-12 mt-3">
                <!-- Send SEPA Link -->
                <div *ngIf="getPaymentMethodSlug(paymentConfigurationForm.get('payment_method_id').value) === 'sepa'"
                     class="dropright d-inline">
                  <button type="button" [title]="'CASES.single.send-sepa-link' | translate"
                          mat-raised-button color="primary" class="btn dropdown-toggle mr-2 mb-2"
                          id="sendSepaLinkDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ 'CASES.single.send-sepa-link' | translate }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div class="dropdown-item" (click)="sendSepaLink('email')" href="#">Email</div>
                    <div class="dropdown-item" (click)="sendSepaLink('sms')" href="#">SMS</div>
                    <div class="dropdown-item" (click)="sendSepaLink('all')" href="#">Email & SMS</div>
                  </div>
                </div>
                <button [matMenuTriggerFor]="requestPaymentMenu" mat-raised-button
                        color="primary" class="mr-2" (click)="$event.preventDefault()"
                        [disabled]="!paymentConfigurationForm.get('initial_fee').value || paymentConfigurationForm.get('initial_fee').invalid">
                  {{ 'CASES.single.send-link' | translate }}
                  <ng-container *ngIf="paymentConfigurationForm.get('initial_fee').value">
                    ({{ paymentConfigurationForm.get('initial_fee').value + "€" }})
                  </ng-container>
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #requestPaymentMenu="matMenu">
                  <button mat-menu-item (click)="requestPaymentDialog('card', 'oppwa')">
                    <span>{{ "CASES.single.payments.actions.request_payment.options.oppwa" | translate }}</span>
                  </button>
                  <!--<button mat-menu-item *ngIf="appEnv === 'local' || appEnv === 'staging' ||-->
                  <!--         (this.authUser.role_id === 5 && this.allowedMymoidIds.includes(this.authUser.id))"-->
                  <!--        (click)="requestPaymentDialog('card', 'mymoid')">-->
                  <!--  <span>{{ "CASES.single.payments.actions.request_payment.options.mymoid" | translate }}</span>-->
                  <!--</button>-->
                </mat-menu>
                <!-- Charge card -->
                <button class="ml-2 mr-2"
                        [disabled]="!paymentConfigurationForm.get('initial_fee').value || paymentConfigurationForm.get('initial_fee').invalid"
                        mat-raised-button color="primary" type="button" (click)="openCardForm('charge')">
                  {{"CASES.single.pay" | translate}}
                  <ng-container *ngIf="paymentConfigurationForm.get('initial_fee').value">
                    ({{ paymentConfigurationForm.get('initial_fee').value + "€" }})
                  </ng-container>
                </button>
                <!-- Tokenize card -->
                <button mat-raised-button color="primary" type="button" (click)="openCardForm('verify')">
                  {{ "CASES.single.verify" | translate }}
                </button>
                <!-- Submit Form -->
                <div class="d-flex justify-content-end">
                  <app-spinner-btn type="submit" [loading]="submittingPaymentConfiguration"
                                   [name]="'SHARED.save' | translate"
                                   (click)="submitPaymentConfigurationForm(paymentConfigurationForm)">
                  </app-spinner-btn>
                </div>
                <div *ngIf="sendLinksError" class="alert alert-warning mt-3">
                  <strong class="text-danger mx-1">{{ sendLinksError }}</strong>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Summary -->
    <div *ngIf="purposeForm && paymentPlanForm" class="col-md-6">
      <div class="card shadow mb-4">
        <div class="card-header"><h3>{{"CASES.single.summary" | translate }}</h3></div>
        <div class="card-body">
          <div class="row">
            <div [hidden]="!isLoading || isLoading <= 0" class="col-12">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
            <div *ngIf="summaryResponse" class="col-12">
              <app-server-response [response]="summaryResponse"></app-server-response>
            </div>
          </div>
          <table class="table">
            <tbody>
            <tr class="{{ phaseOnePaid ? 'row-success' : '' }}">
              <td>{{"CASES.single.loan-amount" | translate}}</td>
              <td>{{ paymentPlanForm.get('phase_one_value').value || 0 | number: '1.2-2'}}€</td>
              <td *ngIf="phaseOnePaid">
                <i *ngIf="phaseOnePaid" class="fal fa-check ml-1 text-success"></i>
              </td>
            </tr>
            <tr>
              <td>{{"CONFIG.misc.apr_rate" | translate}}</td>
              <td>{{aprRate | number: '1.2-2'}}%</td>
            </tr>
            <tr>
              <td>{{"CONFIG.misc.tin_rate" | translate}}</td>
              <td>{{tinRate | number: '1.2-2'}}%</td>
            </tr>
            <tr>
              <td>{{'CASES.single.total-repayable' | translate}}</td>
              <td>{{totalLoaned | number: '1.2-2'}}€</td>
            </tr>
            </tbody>
          </table>
          <div class="form-group pt-3">
            <!-- Preview Contract -->
            <a target="_blank" [routerLink]="['/client', 'sign-contract']"
               [queryParams]="{uuid: case.uuid}" mat-button color="primary" type="button">
              {{"CASES.single.contract-preview" | translate}}
            </a>
            <!-- Send contract button -->
            <div class="dropright d-inline ml-2" *ngIf="paymentPlanForm && paymentConfigurationForm">
              <button [disabled]="contractButtonDisabled()"
                      mat-raised-button color="primary" class="btn dropdown-toggle" type="button"
                      id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{"CASES.single.send-contract" | translate}}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="sendContract($event, 'email')">Email</a>
                <a class="dropdown-item" (click)="sendContract($event, 'sms')">SMS</a>
                <a class="dropdown-item" (click)="sendContract($event, 'all')">Email & SMS</a>
              </div>
            </div>
            <div *ngIf="((paymentPlanForm && paymentConfigurationForm) &&
              !paymentPlanForm.get('phase_one_monthly_fee').value ||
              !paymentConfigurationForm.get('payment_day').value ||
              !validIdCards(case) || !case.product_assigned_at)" class="alert alert-warning mt-3">
              {{ 'CASES.single.please-set' | translate }}
              <strong class="text-danger mx-1" *ngIf="!paymentPlanForm.get('phase_one_monthly_fee').value">
                {{'CASES.single.set-phase-one-fee' | translate}},
              </strong>
              <strong class="text-danger mx-1" *ngIf="!paymentConfigurationForm.get('payment_day').value">
                {{'CASES.single.set-payment-day' | translate}},
              </strong>
              <strong class="text-danger mx-1" *ngIf="!validIdCards(case)">
                {{ 'CASES.single.set-id-card' | translate }},
              </strong>
              <strong class="text-danger mx-1" *ngIf="!case.product_assigned_at">
                {{ 'CASES.single.set-product-outcome' | translate }},
              </strong>
              <strong class="text-danger mx-1">{{'CASES.single.and-save-case' | translate}}</strong>
              {{'CASES.single.to-enable' | translate}}
              <strong class="text-info">{{'CASES.single.send-contract' | translate}}</strong>
              {{'CASES.single.option' | translate}}
            </div>
            <div *ngIf="!isCaseLoanApproved() || !doCreditorsAmountMatch() || !creditorChangesSaved" class="alert alert-warning mt-3">
              {{'CASES.single.send-contract-conditions' | translate}}<br>
              <strong class="text-danger mx-1" *ngIf="!isCaseLoanApproved()">
                -{{'CASES.single.send-contract-condition-3' | translate}}<br>
              </strong>
              <strong class="text-danger mx-1" *ngIf="!doCreditorsAmountMatch()">
                -{{'CASES.single.send-contract-condition-1' | translate}}<br>
              </strong>
              <strong class="text-danger mx-1" *ngIf="!creditorChangesSaved">
                  -<span class="text-center" [translate]="'SHARED.unsaved-changes'"
                  [translateParams]="{section: 'CASES.single.creditors.heading' | translate}"></span>
              </strong>
           </div>
          </div>
        </div>
      </div>
      <div class="card shadow mb-4">
        <div class="card-header"><h3>{{"CASES.single.purpose" | translate }}</h3></div>
        <div class="card-body">
          <form *ngIf="purposeForm" [formGroup]="purposeForm">
            <div class="row">
              <div class="d-flex pb-2 justify-content-between" style="width: 100%">
                <div></div>
                <!-- <mat-radio-group formControlName="purpose" aria-label="Select an option" class="ml-2">
                  <mat-radio-button value="" class="mr-2">{{"CASES.purpose.none" | translate}}</mat-radio-button>
                  <mat-radio-button value="epi_fee" class="mr-2">{{"CASES.purpose.epi_fee" | translate}}</mat-radio-button>
                  <mat-radio-button value="funded_debt_settlement">{{"CASES.purpose.funded_debt_settlement" | translate}}</mat-radio-button>
                </mat-radio-group> -->
                <!-- <button mat-raised-button color="primary" class="mr-2" (click)="submitPurposeForm()" [disabled]="savePurposeButtonDisabled()">
                  {{"SHARED.save" | translate}}
                </button> -->
                <app-spinner-btn type="submit" [loading]="isSubmittingPurpose" [name]="'SHARED.save' | translate"
                                 [disabled]="savePurposeButtonDisabled()"
                                 (click)="submitPurposeForm(purposeForm)">
                </app-spinner-btn>
              </div>
            </div>
            <div *ngIf="purposeForm.get('purpose').value === 'funded_debt_settlement'" class="row">
              <table formArrayName="creditors" class="table">
                <thead>
                  <tr>
                    <th>{{"CASE_CREDITOR.model.relations.creditor" | translate}}</th>
                    <th>{{"CASES.editor.creditors.table.iban" | translate}}</th>
                    <th>{{"PAYMENTS.amount" | translate}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let formGroup of getFormArray().controls let i = index">
                    <tr [formGroupName]="i" style="border-collapse: separate" class="first-row border-top">
                      <td class="d-flex flex-row align-items-center" style="border-top: none !important;">
                        <app-input type="select" formControlName="creditor_id" width="160px"
                                   selectLabel="name" selectValue="id"
                                   [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
                                   [selectOptions]="creditorsOption" [showClear]="false" [searchable]="true">
                        </app-input>
                      </td>
                      <td>
                        <app-input *ngIf="checkIfCreditorHasIban(purposeForm.get('creditors.' + i + '.creditor_id').value)" type="text" formControlName="iban" width="160px" (input)="isIbanCorrect(purposeForm.get('creditors.' + i + '.iban').value, i)"
                                    [label]="'CASES.editor.creditors.table.iban' | translate">
                        </app-input>
                        <!-- Add mat-select -->
                        <app-input *ngIf="!checkIfCreditorHasIban(purposeForm.get('creditors.' + i + '.creditor_id').value)" type="select" formControlName="iban" required [showClear]="false"
                                    [label]="'CASES.editor.creditors.table.iban' | translate" [selectOptions]="creditorIbans[purposeForm.get('creditors.' + i + '.creditor_id').value]"
                                    [selectLabel]="'label'" [selectValue]="'value'" [fullWidth]="true">
                        </app-input>
                      </td>
                      <td>
                        <app-input type="number" formControlName="negotiated_amount" width="160px"
                                   [label]="'PAYMENTS.amount' | translate">
                        </app-input>
                      </td>
                      <td class="d-flex flex-row align-items-center" style="border-top: none !important;">
                        <div *ngIf="creditorChangesSaved === creditorChangesSavedStatus['saved']">
                          <button mat-icon-button color="primary" class="ml-2" (click)="uploader.click()">
                            <mat-icon class="mat-icon" [ngClass]="hasCreditorDocuments(i) ? 'yes_docs' : ''">cloud_upload</mat-icon>
                          </button>
                          <input hidden type="file" #uploader (change)="onFileChange($event,i)"/>

                          <button mat-icon-button color="primary" class="ml-2" (click)="addDocumentsToCreditorModal(i)">
                            <mat-icon class="mat-icon">download_eye</mat-icon>
                          </button>
                        </div>
                        <button mat-icon-button color="warn" class="ml-2"
                                (click)="removeCreditor(i)">
                          <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                <tfoot>
                  <tr *ngIf="topUpLoanCount >= 1">
                    <td></td>
                    <td style="text-align: right;">Top Up Loan Amount{{topUpLoanCount >=2 ? 's' : ''}}:</td>
                    <td>
                      {{topUpLoanAmount | number: '1.2-2'}}€
                    </td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;">Totals:</td>
                    <td>{{totalAmountByCreditors | number: '1.2-2'}}€</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
              <button *ngIf="!getFormArray().disabled" mat-raised-button color="primary" class="ml-2" (click)="addCreditor()">
                + {{ 'CASES.editor.creditors.table.actions.add' | translate }}
              </button>
              <a target="_blank" href="{{ this.apiURL }}/cases/{{ case?.id }}/generate-creditor-list-purpose?uuid={{ case.uuid }}"
                mat-raised-button color="primary" class="ml-2" type="button">
                <!--{{"CASES.single.contract-preview" | translate}}-->
                Generate PDF
              </a>
              <button mat-raised-button color="primary" class="ml-2" [disabled]="creditorChangesSaved < 1" (click)="togglePurposeInputs()" *ngIf="shouldShowLockButton()">
                {{ getFormArray().disabled ? 'Unlock' : 'Lock' }}
              </button>
            </div>
          </form>
          <div *ngIf="!doCreditorsAmountMatch()" class="alert alert-warning mt-3">
            {{ 'CASES.loan-dist-no-match' | translate }}
          </div>
        </div>
      </div>
      <div class="card shadow mb-4" *ngIf="logaltyTransactions.length">
        <div class="card-header"><h3>{{"CASES.editor.logalty.logalty-transactions" | translate}}</h3></div>
        <div class="card-body">
          <div class="row">
            <div [hidden]="!isLoading || isLoading <= 0" class="col-12">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
            <table class="table logalty-transactions" style="border-top: none">
              <thead>
                <tr>
                  <td>#</td>
                  <td>{{"CASES.single.date" | translate}}</td>
                  <td>{{"CASES.details.status" | translate}}</td>
                  <td>{{"SHARED.actions" | translate}}</td>
                </tr>
              </thead>
              <tbody>
              <tr *ngFor="let logaltyTransaction of logaltyTransactions; let i = index">
                <td>{{i + 1}}</td>
                <td>{{logaltyTransaction.created_at | toDateObject | date}}</td>
                <td>{{logaltyTransaction.logalty_result.name}}</td>
                <td>
                  <a mat-raised-button color="primary" class="mr-2" target="_blank" href="{{storageUrl + 'cases/' + logaltyTransaction.case_id + '/documents/' + logaltyTransaction.externalId + '.pdf' }}" [disabled]="!logaltyTransaction.contract || !logaltyTransaction.contract.logalty_certificate_location">{{"CASES.editor.logalty.certificate" | translate}}</a>
                  <a mat-raised-button color="primary" class="mr-2" target="_blank" href="{{storageUrl + 'cases/' + logaltyTransaction.case_id + '/documents/' + logaltyTransaction.externalId + '-stamped.pdf'}}" [disabled]="!logaltyTransaction.stamped_contract_location">{{"CASES.single.document-types.contract" | translate}}</a>
                  <button mat-raised-button color="primary" (click)="cancelLogaltyTransaction(logaltyTransaction.id)" [disabled]="logaltyTransaction.substate_value === 12">{{"SHARED.cancel" | translate}}</button>
                </td>
              </tr>
              </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td>Totals:</td>
                    <td>{{totalAmountByCreditors | number: '1.2-2'}}€</td>
                    <td></td>
                  </tr>
                </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
