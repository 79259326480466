import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'ngx-signaturepad/signature-pad';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { AppDocument } from '../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentSignature } from '../../../../../_base-shared/models/Document/AppDocumentSignature';
import { CaseService } from '../../admin/case/case.service';
import { ClientService } from '../client.service';

@Component({
  selector:    'app-sign-debt-cancellation-request',
  templateUrl: './sign-debt-cancellation-request.component.html',
  styleUrls:   ['./sign-debt-cancellation-request.component.scss'],
})
export class SignDebtCancellationRequestComponent implements OnInit, AfterViewInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public isLoadingDocument        = false;
  public isLoadingDocumentPreview = false;
  public caseUuid: string;
  public case: Case;
  public signBy: any;
  public resign: any;
  public type: string;
  public document: AppDocument;
  public documentSignature: AppDocumentSignature;
  public documentHtml: string;
  public signaturePadOptions      = {
    minWidth:     1,
    maxWidth:     2,
    canvasWidth:  458,
    canvasHeight: 100,
  };
  public signatureImg             = '';
  public prevSignatureImg         = '';
  public imgPath                  = environment.STORAGE_URL + '/';
  public submittingSignature      = false;

  constructor(
    private elRef: ElementRef,
    private caseService: CaseService,
    private clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private toast: ToastrService,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.caseUuid = this.route.snapshot.queryParamMap.get('uuid');
    this.signBy   = this.route.snapshot.queryParamMap.get('sign');
    this.type     = 'debt-cancellation-request';

    const relations = this.signBy === 'partner' ?
      ['debt_cancellation_request_documents.files', 'debt_cancellation_request_documents.partner_signature'] :
      ['debt_cancellation_request_documents.files', 'debt_cancellation_request_documents.client_signature'];

    this.caseService.caseInfo(this.caseUuid, relations).pipe(finalize(() => this.isLoadingDocument = false)).subscribe(
      next => {
        this.case              = next.data;
        this.document          = this.case.debt_cancellation_request_documents?.[0];
        if (this.document) {
          this.documentSignature = this.signBy === 'partner' ?
            this.document.partner_signature[0] :
            this.document.client_signature[0];

          if (this.document && this.documentSignature) {
            this.prevSignatureImg = this.documentSignature.signature;
          }
        }

        this.clientService.previewDebtCancellationRequest(this.caseUuid, this.signBy).pipe(finalize(() => {
          this.isLoadingDocumentPreview = false;
          setTimeout(() => {
            this.scrollToElement('sign');
          }, 500);
        })).subscribe(
          result => {
            this.documentHtml = result.data;
          },
        );
      });

    const viewData = {
      viewed_by: '',
      type:      this.type,
    };

    if (this.signBy === 'partner') {
      viewData.viewed_by = 'partner';
    } else {
      viewData.viewed_by = 'client';
    }

    // TODO: view time
    // this.caseService.sendCreditorAssetListViewedTime(this.caseUuid, viewData).subscribe(obj => {
    // });
  }

  ngAfterViewInit() {
    this.signaturePad.clear();
  }

  drawComplete() {
    this.signatureImg = this.signaturePad.toDataURL();
  }

  public clear() {
    this.signaturePad.clear();
    this.signatureImg = '';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const div = this.elRef.nativeElement.querySelector('.signature-wrap')?.offsetWidth;
    if (div !== 0) {
      this.signaturePad.set('canvasWidth', div);
    }
  }

  drawStart() {
  }

  scrollToElement($element): void {
    const element = document.getElementById($element);
    element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
  }

  saveSignature() {
    this.submittingSignature = true;
    const data               = new FormData();

    data.append('signature', this.signatureImg);
    data.append('signed_by', this.signBy);

    this.clientService.saveDebtCancellationRequestSignature(this.case.id, data)
      .pipe(finalize(() => this.submittingSignature = false))
      .subscribe(
        next => {
          Swal.fire({
            position:           'top',
            title:              this.translateService.instant('CASES.single.draft.document-signed'),
            icon:               'success',
            confirmButtonText:  'Ok',
            confirmButtonColor: '#886ab5',
          });
        },
        error => this.toast.error('Sign failed. Please try again later.', 'Error'),
      );
  }
}
