<!-- BEGIN Page Header -->
<header class="page-header" role="banner">
  <div class="ml-auto d-flex">
    <!-- app user menu -->
    <div>
      <ul class="d-flex m-0" style="height: 100%">
        <li routerLinkActive="active" class="nav-item align-self-center">
          <a href="https://www.epifinance.es/blog/faqs-1" target="_blank" class="nav-link">
            <span class="nav-link-text">
              <mat-icon class="mat-icon">info_outline</mat-icon>
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div>
      <a href="#" data-toggle="dropdown" title="{{ authUser.email }}"
         class="header-icon d-flex align-items-center justify-content-center">
        <img src="assets/img/avatars/avatar-admin.png" class="profile-image rounded-circle" alt="User">
      </a>
      <div class="dropdown-menu dropdown-menu-animated dropdown-lg">
        <div class="dropdown-header bg-trans-gradient d-flex flex-row px-2 px-md-4 py-4 rounded-top">
          <div class="d-flex flex-column flex-md-row align-items-center mt-1 mb-1 color-white">
            <div class="mr-0 mr-md-2">
              <img src="assets/img/avatars/avatar-admin.png" class="rounded-circle profile-image" alt="User">
            </div>
            <div class="info-card-text">
              <div class="fs-lg text-truncate text-truncate-lg">{{ authUser.name }}</div>
              <span class="text-truncate text-truncate-md opacity-80">{{ authUser.email }}</span>
            </div>
          </div>
        </div>

        <div class="dropdown-divider m-0"></div>
        <a *ngIf="authUser.role.is_staff" [routerLink]="['user-settings']" class="dropdown-item"
           data-toggle="modal" data-target=".js-modal-settings">
          <span>Profile Settings</span>
        </a>
        <a *ngIf="!authUser.role.is_staff" [routerLink]="['/profile']" class="dropdown-item"
           data-toggle="modal" data-target=".js-modal-settings">
          <span>Profile Settings</span>
        </a>

        <div class="dropdown-divider m-0"></div>
        <a (click)="logout()" class="dropdown-item" data-toggle="modal" data-target=".js-modal-settings">
          <span>Log Out</span>
        </a>
        <div class="dropdown-item">
          <a class="pr-3" href="#" (click)="changeLanguage($event, 'en')">
            <img src="assets/img/uk.png" alt="UK" style="width: 40px">
          </a>
          <a href="#" (click)="changeLanguage($event, 'es')">
            <img src="assets/img/es.png" alt="ES" style="width: 40px">
          </a>
        </div>
      </div>
    </div>

    <app-notification-bell [notifications]="notifications"></app-notification-bell>

  </div>
</header>
<!-- END Page Header -->
