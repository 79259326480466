import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ClientService } from '../../client.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector:    'app-income-step',
  templateUrl: './income-step.component.html',
  styleUrls:   ['./income-step.component.scss'],
})
export class IncomeStepComponent implements OnInit {
  @Input() caseUuid: string;
  @Input() userUuid: string;
  @Input() isReadOnly: boolean;
  @Input() isDesktop: boolean;
  public form: UntypedFormGroup;
  public isLoading = 0;

  public salaryArrayFilter  = [
    'salary_client',
    'salary_partner',
    'subsidy_benefit',
    'unemployment_benefit',
    'pension_state'];
  public salaryArray        = [];
  public salaryTotal        = 0;
  public pensionArrayFilter = ['pension_private', 'pension_credit', 'pension_other'];
  public pensionArray       = [];
  public pensionTotal       = 0;

  public otherFilter = [
    'other_boarders_or_lodgers',
    'other_non_dependent_contributions',
    'other_student_loans_and_grants',
  ];
  public otherArray  = [];
  public otherTotal  = 0;

  public otherPensionsFilter = ['compensatory_pension', 'alimony'];
  public otherPensionsArray  = [];
  public otherPensionsTotal  = 0;

  public specialIncomesFilter      = ['special_incomes'];
  public specialIncomesArray       = [];
  public specialIncomesTotal       = 0;

  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.isLoading++;
    this.clientService.indexCaseRelation(this.caseUuid, this.userUuid, 'income')
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          result.data.forEach(income => {
            //  Filter wage/salary incomes
            if (this.salaryArrayFilter.indexOf(income.name) !== -1) {
              this.salaryArray.push({name: income.name, value: income.value ? income.value : 0});
              this.salaryTotal += income.value || 0;
            }

            //  Filter special incomes
            if (this.specialIncomesFilter.indexOf(income.name) !== -1) {
              const incomeValueArray = income.value ? income.value : [];
              incomeValueArray.forEach(singleIncome => {
                this.specialIncomesArray.push(
                    {name: singleIncome.label, value: singleIncome.value ? singleIncome.value : 0}
                );
                const incomeValue = +singleIncome.value;
                this.specialIncomesTotal += incomeValue || 0;
              });
            }
            //  Filter pension incomes
            if (this.pensionArrayFilter.indexOf(income.name) !== -1) {
              this.pensionArray.push({name: income.name, value: income.value ? income.value : 0});
              this.pensionTotal += income.value || 0;
            }
            //  Filter other incomes
            if (this.otherFilter.indexOf(income.name) !== -1) {
              this.otherArray.push({name: income.name, value: income.value ? income.value : 0});
              this.otherTotal += income.value || 0;
            }
            //  Filter other pensions incomes
            if (this.otherPensionsFilter.indexOf(income.name) !== -1) {
              this.otherPensionsArray.push({name: income.name, value: income.value ? income.value : 0});
              this.otherPensionsTotal += income.value || 0;
            }
          });

          this.isLoading++;
          this.clientService.indexCaseIncomeValidation(this.caseUuid, this.userUuid)
            .pipe(finalize(() => this.isLoading--))
            .subscribe(incomeResult => {
              this.buildForm(incomeResult.data);
            });
        });
  }

  private buildForm(validatableIncome) {
    const incomes = this.salaryArray.concat(this.pensionArray, this.otherArray, this.otherPensionsArray);
    const group = {};

    incomes.forEach(income => {
      group[income.name] = new UntypedFormControl(validatableIncome ? validatableIncome[income.name] : true);
    });
    // @ts-ignore
    group.other_salaries = new UntypedFormControl(true);
    // @ts-ignore
    group.special_incomes = new UntypedFormControl(true);

    this.form = new UntypedFormGroup(group);
  }

  public getData() {
    const incomes = this.form.value;
    const otherSalaries = incomes.other_salaries;
    delete incomes.other_salaries;

    return {
      income: incomes,
      other_salaries: otherSalaries,
      relation: 'income'
    };
  }

}
