<!--{{ "CASES.single.request-payment" | translate }}-->
<h1 mat-dialog-title>View creditor documents</h1>


<div id="container" style="height: 85%; width: 85%">
    <div id="div1">
        <div mat-dialog-content class="custom-card-wrap">
            <!-- image-gallery.component.html -->
            <div *ngIf="hasMatchingDocuments()">
                <div *ngFor="let file of userDocuments let i = index">
                    <div *ngIf="isMatchingDocument(file)" class="col-md-3">
                        <a target="_blank" href="{{storageUrl + file.location}}"
                           (click)="selectFile($event, (storageUrl + file.location), file.mime_type)">{{file.name}}</a>
                        <div>
                            <button class="ml-2" mat-button color="primary"
                                    (click)="downloadFile($event, file)">{{"CASES.single.download" | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!hasMatchingDocuments()">
                No matching documents available.
            </div>
        </div>
    </div>

    <div id="div2">
        <!--<div *ngIf="fileType === 'object'; else photo" style="height: 100%; width: 100%">
            <div *ngIf="fileUrl" style="height: 100%">
                <div [ngClass]="{'sticky' : isSticky}">
                    <object [data]="fileUrl" type="application/pdf" width="100%" height="100%">
                        <iframe [src]="fileUrl" width="100%" height="100%" style="border: none;">
                            <p>
                                Your browser does not support PDFs.
                                <a [href]="fileUrl">Download the PDF</a>
                            </p>
                        </iframe>
                    </object>
                </div>
            </div>
        </div>-->
        <div *ngIf="fileType === 'object'; else photo" style="height: 100%; width: 100%">
            <div *ngIf="fileUrl" style="height: 100%; width: 100%">
                <div [ngClass]="{'sticky' : isSticky}" style="height: 100%; width: 100%">
                    <object [data]="fileUrl" type="application/pdf" style="height: 100%; width: 100%">
                        <iframe [src]="fileUrl" width="100%" style="height: 100%; border: none;">
                            <p>Your browser does not support PDFs. <a [href]="fileUrl">Download the PDF</a></p>
                        </iframe>
                    </object>
                </div>
            </div>
        </div>
        <ng-template #photo>
            <div>
                <div [ngClass]="{'sticky' : isSticky}">
                    <img [src]="fileUrl" alt="" class="img-fluid" style="max-height: 83vh">
                </div>
            </div>
        </ng-template>
    </div>
</div>

<!--<p>{{"CASES.single.request-payment-note" | translate}}</p>-->
<div mat-dialog-actions class="justify-content-end">
  <div *ngIf="formActive" class="mb-3">
      <!--{{'SHARED.cancel' | translate}}-->
      <button mat-button color="primary" class="mr-3" (click)="onNoClick()">Close</button>
  </div>
</div>