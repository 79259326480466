import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
  HeadsUpDashboardBookPerformanceInterface, HeadsUpDashboardDgsPerformanceInterface,
  HeadsUpDashboardCollectionsInterface,
  HeadsUpDashboardDistributionInterface,
  HeadsUpDashboardDmPerformanceInterface,
  HeadsUpDashboardLsoPerformanceInterface, HeadsUpDashboardRequestFilters,
} from '../../../../../_base-shared/contracts/dashboard.interface';
import {LaravelResourceResponse, LaravelResponse} from '../../../../../_base-shared/contracts/laravel-response.interface';
import {MainBaseApiService} from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends MainBaseApiService {

  public index(data): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${this.apiUrl}/dashboard`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexV2(data?): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${this.apiUrl}/dashboard-v1`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexCustomerCare(data): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${this.apiUrl}/dashboard-customer-care`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getSalesData(): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${this.apiUrl}/leaderboard`)
      .pipe(catchError(response => this.handleError(response)));
  }

  public getVerifierData(data): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${this.apiUrl}/verifierboard`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getCollectionsData(data?: HeadsUpDashboardRequestFilters) {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardCollectionsInterface>>(
      this.apiUrl + '/dashboard/heads-up/collections', {params: data as any}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getDistributionData(data?: HeadsUpDashboardRequestFilters) {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardDistributionInterface>>(
      this.apiUrl + '/dashboard/heads-up/distribution', {params: data as any}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getLsoSummaryData(data?: HeadsUpDashboardRequestFilters) {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardLsoPerformanceInterface>>(
      this.apiUrl + '/dashboard/heads-up/lso-summary', {params: data as any}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getDmSummaryData(data?: HeadsUpDashboardRequestFilters) {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardDmPerformanceInterface>>(
      this.apiUrl + '/dashboard/heads-up/dm-summary', {params: data as any}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getBookPerformanceData(data?: HeadsUpDashboardRequestFilters) {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardBookPerformanceInterface>>(
      this.apiUrl + '/dashboard/heads-up/book-performance', {params: data as any}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getDgsSummaryData(data?: HeadsUpDashboardRequestFilters) {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardDgsPerformanceInterface>>(
      this.apiUrl + '/dashboard/heads-up/dgs-summary', {params: data as any}
    ).pipe(catchError(response => this.handleError(response)));
  }
}
