import { Injectable } from '@angular/core';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { Observable } from 'rxjs';
import { LaravelResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Trigger } from '../../../../../../_base-shared/models/Trigger';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationTriggerService extends MainBaseApiService {

    index(data): Observable<LaravelResponse> {
        return this.http.get<LaravelResponse>(`${this.apiUrl}/notification-triggers`, { params: data })
            .pipe(catchError(response => this.handleError(response)));
    }

    get(triggerId: number): Observable<LaravelResponse> {
        return this.http.get<LaravelResponse>(`${this.apiUrl}/notification-triggers/${triggerId}`)
            .pipe(catchError(response => this.handleError(response)));
    }

    store(data): Observable<LaravelResponse> {
        return this.http.post<LaravelResponse>(`${this.apiUrl}/notification-triggers`, data)
            .pipe(catchError(response => this.handleError(response)));
    }

    update(triggerId, data): Observable<LaravelResponse> {
        return this.http.put<LaravelResponse>(`${this.apiUrl}/notification-triggers/${triggerId}`, data)
            .pipe(catchError(response => this.handleError(response)));
    }

    delete(triggerId: number): Observable<LaravelResponse> {
        return this.http.delete<LaravelResponse>(`${this.apiUrl}/notification-triggers/${triggerId}`)
            .pipe(catchError(response => this.handleError(response)));
    }
}
