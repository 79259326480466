import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../_base-shared/models/User/User';

@Injectable({
  providedIn: 'root',
})
export class MainGlobalEventService {
  // private headerVisibility   = new Subject<boolean>();
  private navStatusSource           = new BehaviorSubject<boolean>(true);
  private authUserSource            = new BehaviorSubject<User>(null);
  private authStatusSource          = new BehaviorSubject<boolean>(null);
  private unapprovedCreditorsSource = new BehaviorSubject<boolean>(null);
  private overdueTasksSource        = new BehaviorSubject<number>(0);
  private clientSelectedCaseSource  = new BehaviorSubject<Case>(null);
  // private globalErrorsSource = new Subject<any>();

  // public headerStatus$ = this.headerVisibility.asObservable();
  public $navStatus           = this.navStatusSource.asObservable();
  public authUser$            = this.authUserSource.asObservable();
  public authStatus$          = this.authStatusSource.asObservable();
  public unapprovedCreditors$ = this.unapprovedCreditorsSource.asObservable();
  public overdueTasks$        = this.overdueTasksSource.asObservable();
  public clientSelectedCase$  = this.clientSelectedCaseSource.asObservable();
  // public globalErrors$ = this.globalErrorsSource.asObservable();

  public authUser: User;
  public unapproved: boolean;
  public previousRoute: string;

  setNavStatus(visibility: boolean) {
    this.navStatusSource.next(visibility);
  }

  setHeaderStatus(visibility: boolean) {
    // this.headerVisibility.next(visibility);
  }

  setAuthUser(user: User) {
    this.authUser = user;
    this.authUserSource.next(user);
    this.authStatusSource.next( !! user);
  }

  setUnapprovedCreditors(count: number) {
    this.unapproved = count > 0;
    this.unapprovedCreditorsSource.next(count > 0);
  }

  setPreviousRoute(route) {
    this.previousRoute = route;
  }

  pushError(errorResponse) {
    // this.globalErrorsSource.next(errorResponse);
  }

  setOverdueTasksCount(value) {
    this.overdueTasksSource.next(value);
  }

  setClientSelectedCase(clientCase: Case) {
    this.clientSelectedCaseSource.next(clientCase);
  }
}
