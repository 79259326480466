import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelPaginatorResponse, LaravelResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { Notary } from '../../../../../../_base-shared/models/Entity/Notary';

@Injectable({
  providedIn: 'root',
})
export class NotariesService extends MainBaseApiService {

  index(data) {
    return this.http.get<LaravelResponse>(this.apiUrl + '/notaries', {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }

  show(id: number) {
    return this.http.get<LaravelResponse<Notary>>(this.apiUrl + '/notaries/' + id)
        .pipe(catchError(response => this.handleError(response)));
  }

  store(data) {
    return this.http.post<LaravelResponse<Notary>>(this.apiUrl + '/notaries', data)
        .pipe(catchError(response => this.handleError(response)));
  }

  saveNotary(caseId, data) {
    return this.http.post<LaravelResponse<Notary>>(this.apiUrl + `/cases/${caseId}/save-notary`, data)
        .pipe(catchError(response => this.handleError(response)));
  }

  update(id: number, data) {
    return this.http.put<LaravelResponse<Notary>>(this.apiUrl + '/notaries/' + id, data)
        .pipe(catchError(response => this.handleError(response)));
  }

  delete(id: number) {
    return this.http.delete<LaravelResponse<Notary>>(this.apiUrl + '/notaries/' + id)
        .pipe(catchError(response => this.handleError(response)));
  }
}
