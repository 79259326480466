<div class="container">
<div class="row">
  <div class="col-12">
    <h2 *ngIf="editorType === 'create'">{{"CREDITORS.add-creditor" | translate}}</h2>
    <h2 *ngIf="editorType !== 'create'">{{"CREDITORS.edit-creditor" | translate}}</h2>
  </div>
</div>
<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<ng-container *ngIf="this.formActive">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <div class="card shadow mb-4">
          <div class="card-body">
            <!-- Name -->
            <app-input type="text" id="name" formControlName="name" [label]="'SHARED.model.name' | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       flexClass="align-items-center" [placeholder]="'SHARED.model.name' | translate">
            </app-input>

            <!-- Active Status -->
            <app-input type="select" id="active" appearance="outline" formControlName="active"
                       [label]="'CREDITORS.status' | translate" [showLabel]="true"
                       [showClear]="false" [selectOptions]="creditorStatuses" [selectLabel]="'label'"
                       [selectValue]="'value'"
                       [fullWidth]="true">
            </app-input>
            <!-- Approved -->
            <app-input type="select" id="approved" appearance="outline" formControlName="approved"
                       [label]="'CREDITORS.approved' | translate" [showLabel]="true"
                       [showClear]="false" [selectOptions]="approvedStatuses" [selectLabel]="'label'"
                       [selectValue]="'value'"
                       [fullWidth]="true">
            </app-input>
            <!-- Address -->
            <app-input type="text" id="address" formControlName="address" [label]="'CREDITORS.address' | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       flexClass="align-items-center" [placeholder]="'CREDITORS.address' | translate">
            </app-input>
            <!-- General Email -->
            <app-input type="text" id="email" formControlName="email"
                       [label]="'CREDITORS.editor.email_general.label' | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       flexClass="align-items-center"
                       [placeholder]="'CREDITORS.editor.email_general.label' | translate">
            </app-input>
            <!-- Payments Email -->
            <app-input type="email" id="email_payments" formControlName="email_payments"
                       [label]="'CREDITORS.editor.email_payments.label' | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       [placeholder]="'CREDITORS.editor.email_payments.label' | translate"
                       flexClass="align-items-center">
            </app-input>
            <!-- Company Name -->
            <app-input type="email" id="company_name" formControlName="company_name"
                       [label]="'CREDITORS.company' | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       [placeholder]="'CREDITORS.company' | translate"
                       flexClass="align-items-center">
            </app-input>
            <!-- Can Be Recovery -->
            <app-input type="checkbox" formControlName="can_be_recovery"
                       [label]="'CREDITORS.can-be-recovery' | translate">
            </app-input>
            <!-- Show Config -->
            <mat-slide-toggle color="primary" formControlName="show_config">
              {{"CREDITORS.enable-schedule-config" | translate}}
            </mat-slide-toggle>
            <div *ngIf="form.get('show_config').value">
              <div class="form-group" formGroupName="schedule_config">
                <div class="pt-4">
                  <mat-label>{{"CREDITORS.type" | translate}}</mat-label>
                  <mat-radio-group formControlName="type" class="d-flex">
                    <mat-radio-button value="daily" class="example-radio-button">
                      {{"CREDITORS.daily" | translate}}
                    </mat-radio-button>
                    <mat-radio-button value="weekly" class="example-radio-button">
                      {{"CREDITORS.weekly" | translate}}
                    </mat-radio-button>
                    <mat-radio-button value="bi-weekly" class="example-radio-button">
                      {{"CREDITORS.bi-weekly" | translate}}
                    </mat-radio-button>
                    <mat-radio-button value="monthly" class="example-radio-button">
                      {{"CREDITORS.monthly" | translate}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div>
                  <label class="pt-3" style="display: block">{{"CREDITORS.select-time" | translate}}</label>
                  <ng-container *ngIf="form.get('schedule_config.type').value === 'weekly' ||
                  form.get('schedule_config.type').value === 'bi-weekly' ">
                    <mat-form-field appearance="standard">
                      <mat-label>{{"CREDITORS.day" | translate}}</mat-label>
                      <mat-select formControlName="day">
                        <mat-option *ngFor="let day of dayOptions" [value]="day">
                          {{ day }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="form.get('schedule_config.type').value === 'monthly'">
                    <mat-form-field appearance="standard">
                      <mat-label>{{"CREDITORS.day" | translate}}</mat-label>
                      <mat-select formControlName="day">
                        <mat-option *ngFor="let day of dayOptionsMonthly" [value]="day">
                          {{ day }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                  <mat-form-field appearance="standard">
                    <mat-label>{{"CREDITORS.hour" | translate}}</mat-label>
                    <mat-select formControlName="hour">
                      <mat-option *ngFor="let hour of hourOptions" [value]="hour">
                        {{hour}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- End Show Config -->
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card shadow mb-4">
          <div class="card-body">
            <!-- Phone 1 -->
            <app-input type="email" id="phone_1" formControlName="phone_1" [label]="'CREDITORS.phone-1' | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       [placeholder]="'CREDITORS.phone-1' | translate"
                       flexClass="align-items-center">
            </app-input>
            <!-- Phone 2 -->
            <app-input type="email" id="phone_2" formControlName="phone_2" [label]="'CREDITORS.phone-2' | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       [placeholder]="'CREDITORS.phone-2' | translate"
                       flexClass="align-items-center">
            </app-input>
            <!-- Contact Person 1 -->
            <app-input type="email" id="contact_person_1" formControlName="contact_person_1"
                       [label]="'CREDITORS.contact-1'  | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       [placeholder]="'CREDITORS.contact-1'  | translate"
                       flexClass="align-items-center">
            </app-input>
            <!-- Contact Person 2 -->
            <app-input type="email" id="contact_person_2" formControlName="contact_person_2"
                       [label]="'CREDITORS.contact-2' | translate"
                       [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                       [placeholder]="'CREDITORS.contact-2' | translate"
                       flexClass="align-items-center">
            </app-input>
            <!--Creditor type-->
            <app-input type="select" id="type" appearance="outline" formControlName="lender_type"
                       [label]="'CREDITORS.creditor-type' | translate" [showLabel]="true"
                       [showClear]="false" [selectOptions]="creditorTypes" [selectLabel]="'label'"
                       [selectValue]="'value'"
                       [fullWidth]="true">
            </app-input>
            <!--IBAN Number-->
            <!-- <app-input type="text" id="iban" formControlName="iban"
                        [label]="'CREDITORS.iban' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.iban' | translate"
                        flexClass="align-items-center">
            </app-input> -->
          </div>
        </div>
        <div>
          <div class="card shadow mb-4">
            <div class="card-body">
              <h4>Bank Accounts</h4>
              <!-- Iterate over bank accounts and display in a table -->
              <div class="table-responsive">
                <table class="table table-bordered" *ngIf="bankAccounts.length > 0">
                  <thead>
                    <tr>
                      <th>{{"CREDITORS.iban" | translate}}</th>
                      <th>{{"CREDITORS.description" | translate}}</th>
                      <th>{{"CREDITORS.default" | translate}}</th>
                      <th>{{"SHARED.actions" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bankAccount of bankAccounts; let i = index">
                      <td>{{bankAccount.iban}}</td>
                      <td>{{bankAccount.description}}</td>
                      <td>
                        <mat-icon *ngIf="bankAccount.default" color="primary">check</mat-icon>
                        <a *ngIf="!bankAccount.default" (click)="setDefaultBankAccount(bankAccount.id)" style="color: #79a303; cursor: pointer;">Make Default</a>
                      </td>
                      <td>
                        <a (click)="destroyBankAccount(bankAccount.id)"><mat-icon color="warn">delete</mat-icon></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-12">
                  <!-- button save -->
                  <button type="submit" mat-raised-button color="primary" style="min-width: 120px" (click)="openBankAccountDialog()">
                    {{ "SHARED.add" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button type="submit" mat-raised-button color="primary" style="min-width: 120px" (click)="submitForm(form)">
          {{ "SHARED.save" | translate }}
        </button>
        <button *ngIf="editorType === 'edit'" type="button" mat-raised-button color="primary" class="ml-2"
                style="min-width: 120px" (click)="deleteAndReassign()">
          {{ "CREDITORS.delete-and-reassign" | translate }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
</div>
