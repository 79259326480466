<div class="container" style="padding-left: 0; padding-right: 0">
  <div class="card">
    <div class="card-body">
      <div class="text-center">
        <img class="img-fluid" src="assets/img/onboarding_success_es.jpg" alt="onboarding-success" style="max-width: 100%">
      </div>
      <mat-spinner diameter="50" *ngIf="isLoadingAgent" class="m-0 m-auto"></mat-spinner>
      <div style="max-width: 100%; padding-left: 15px; padding-right: 15px;">
        <p *ngIf="!isLoadingAgent" style="line-height: 30px">
          Gracias por actualizar tu información, {{ authUser.first_name + ' ' + authUser.last_name}}.
        </p>
        <p *ngIf="!isLoadingAgent" style="line-height: 30px">
          En caso de que necesitemos información adicional, tu gestor personal se pondrá en contacto contigo. Tu felicidad
          es importante para nosotros, así que para cualquier duda o consulta, llama a nuestro departamento de atención al
          cliente al 919-492-222, marcando la extensión 2.
        </p>
      </div>
    </div>
  </div>
</div>
