import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { SystemEventEditorComponent } from './system-event-editor/system-event-editor.component';
import { PermissionGuard } from '../../../../../../_base-shared/guards/permission.guard';

const routes: Routes = [
    {
        path: 'system-event',
        children: [
            {path: '', canActivate: [PermissionGuard], component: SystemEventEditorComponent},
        ],
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class SystemEventRoutingModule {
}
