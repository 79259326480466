import { DistributionBatchStatus } from '../Distribution/DistributionBatch';

export class CaseListFilter {
  search?: string;
  start_date?: string | Date;
  end_date?: string | Date;
  all_active?: boolean | 0 | 1;
  status_date_type?: 'sign_up' | 'change_date';
  amount_paid?: number; // eg. more than X$ paid
  debt_level?: number;
  outstanding_balance?: number;
  last_action?: number; // eg. more than X days since last_action_at
  has_property?: boolean | 0 | 1;
  has_pending_docs?: boolean | 0 | 1;
  has_public_debt?: boolean | 0 | 1;
  region?: string;
  city?: string;
  products?: Array<number>;
  affiliates?: Array<number>;
  creditors?: Array<number>;
  statuses?: Array<number>;
  payment_statuses?: Array<number>;
  call_statuses?: Array<number>;
  select_all?: boolean | 0 | 1; // paginated or return all
  only_count?: boolean | 0 | 1;
  only_related?: boolean | 0 | 1;
  // Case Roles
  user_department_assignments?: Array<number>;
  verifier?: Array<number>;
  'legal-advisor'?: Array<number>;
  'case-manager'?: Array<number>;
  'customer-contact'?: Array<number>;
  'creditor-negotiator'?: Array<number>;
  'notary-manager'?: Array<number>;
  lawyer?: Array<number>;
  'draft-manager'?: Array<number>;
  'collections-agent'?: Array<number>;
  // Case Entities
  notaries?: Array<number>;
  administrators?: Array<number>;
  courts?: Array<number>;
  solicitors?: Array<number>;
  notary_appointed?: boolean | 0 | 1;
  // Extra
  days_no_contact?: number;
  days_no_contact_channels?: Array<string>;
  client_allow_marketing?: boolean | 0 | 1;
  has_distribution?: boolean | 0 | 1;
  case_distribution_status?: 'in_distribution' | 'not_in_distribution_and_nonviable' | 'nonviable';
  flag_distribution_nonviable?: boolean | 0 | 1;
  distribution_batch_statuses?: Array<DistributionBatchStatus>;
  distribution_batch_ids?: Array<number> | null;
  team_ids?: Array<number> | null;

  // Sort
  order?: 'asc' | 'desc';
  sort_by?: 'id' | 'ref_number' | 'first_name' | 'last_name' | 'joint_application' | 'created_at' | 'last_action_at' | 'status' |
      'payment_status' | 'affiliate' | 'product' | 'debt_amount' | 'pending_files_count' | 'call_status';

  // Paginate
  per_page?: number;
  page?: number;

  // Extra
  cases?: Array<number>; // DEPRECATED, use `case_ids` instead
  case_ids?: Array<number>;
  terms?: Array<number>;
  expected_count?: number;
  activity_status?: string;
  page_type?: string;
}
