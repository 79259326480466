import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '../../client.service';
import { SignaturePad } from 'ngx-signaturepad/signature-pad';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector:    'app-signature-step',
  templateUrl: './signature-step.component.html',
  styleUrls:   ['./signature-step.component.scss'],
})
export class SignatureStepComponent implements OnInit, AfterViewInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  @Input() caseUuid: string;
  @Input() userUuid: string;

  public signatureImg        = '';
  public signaturePadOptions = {
    minWidth:     1,
    maxWidth:     2,
    canvasWidth:  '100%',
    canvasHeight: 100,
  };

  public isLoading = 0;

  constructor(
      private clientService: ClientService,
      private elRef: ElementRef,
      private router: Router,
      private toast: ToastrService,
  ) {
  }

  ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    const div = this.elRef.nativeElement.querySelector('.signature-wrap')?.offsetWidth;
    if (div !== 0) {
      this.signaturePad.set('canvasWidth', div);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const div = this.elRef.nativeElement.querySelector('.signature-wrap')?.offsetWidth;
    if (div !== 0) {
      this.signaturePad.set('canvasWidth', div);
    }
  }

  drawComplete() {
    this.signatureImg = this.signaturePad.toDataURL();
  }

  public clear() {
    this.signaturePad.clear();
    this.signatureImg = '';
  }

  public submitSignature() {
    this.isLoading++;
    const data = {
      signature: this.signatureImg,
    };
    this.clientService.signValidation(this.caseUuid, this.userUuid, data).pipe(finalize(() => this.isLoading--))
        .subscribe(next => {
              this.router.navigate(['client', 'success'], {queryParams: {caseUuid: this.caseUuid}});
            },
        );
  }
}
