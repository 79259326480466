import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from '../../client.service';
import { finalize } from 'rxjs/operators';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Creditor } from '../../../../../../_base-shared/models/Entity/Creditor';
import { CasePublicDebt } from '../../../../../../_base-shared/models/Case/CasePublicDebt';
import { CaseAssetBankAccount } from '../../../../../../_base-shared/models/Case/CaseAssetBankAccount';
import { CaseAssetVehicle } from '../../../../../../_base-shared/models/Case/CaseAssetVehicle';
import { CaseAssetProperty } from '../../../../../../_base-shared/models/Case/CaseAssetProperty';
import { CaseAssetOther } from '../../../../../../_base-shared/models/Case/CaseAssetOther';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-assets-step',
  templateUrl: './assets-step.component.html',
  styleUrls: ['./assets-step.component.scss']
})
export class AssetsStepComponent implements OnInit {
  @Input() caseUuid: string;
  @Input() userUuid: string;
  @Input() isReadOnly: boolean;
  @Input() isDesktop: boolean;
  public form: UntypedFormGroup;
  public isLoading = 0;
  public assets;
  public bankAccounts: Array<CaseAssetBankAccount> = [];
  public vehicles: Array<CaseAssetVehicle> = [];
  public properties: Array<CaseAssetProperty> = [];
  public other: Array<CaseAssetOther> = [];

  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.isLoading++;
    this.clientService.indexCaseRelation(this.caseUuid, this.userUuid, 'assets')
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        // @ts-ignore
        this.bankAccounts = result.data.bank_accounts || [];
        // @ts-ignore
        this.vehicles = result.data.vehicles || [];
        // @ts-ignore
        this.properties = result.data.properties || [];
        // @ts-ignore
        this.other = result.data.other || [];

        this.isLoading++;
        this.clientService.indexCaseRelationValidations(this.caseUuid, this.userUuid, 'asset')
          .pipe(finalize(() => this.isLoading--))
          .subscribe(assetResult => {
            this.buildForm(assetResult.data);
          });
      });
  }

  private buildForm(validatableAssets) {
    // @ts-ignore
    const assets = this.bankAccounts.concat(this.vehicles, this.properties, this.other);
    const group = {};

    assets.map(asset => {
      const validatableAsset = validatableAssets.find(validatableItem => asset.id === validatableItem.validatable_id);
      const validatableValue = validatableAsset !== undefined ? validatableAsset.correct : true;
      group[asset.id] = new UntypedFormControl(validatableValue);
    });

    // @ts-ignore
    group.other_assets = new UntypedFormControl(true);
    this.form = new UntypedFormGroup(group);
  }

  public getData() {
    const assets = this.form.value;
    const otherAssets = assets.other_assets;
    delete assets.other_assets;

    return {
      assets,
      other_assets: otherAssets,
      relation: 'assets'
    };
  }

}
