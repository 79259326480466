import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import {
  LaravelPaginatorResponse,
  LaravelResourceResponse,
  LaravelResponse,
} from '../../../../../_base-shared/contracts/laravel-response.interface';
import { CallHistory } from '../../../../../_base-shared/models/Notification/CallHistory';
import { DatabaseNotification } from '../../../../../_base-shared/models/Notification/DatabaseNotification';
import { EmailHistory } from '../../../../../_base-shared/models/Notification/EmailHistory';
import { NotificationChannel, NotificationChannelSlug } from '../../../../../_base-shared/models/Notification/NotificationChannel';
import { SmsHistory } from '../../../../../_base-shared/models/Notification/SmsHistory';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends MainBaseApiService {

  public index(params = {}) {
    return this.http.get<LaravelResponse<Array<DatabaseNotification>> | LaravelPaginatorResponse<Array<DatabaseNotification>>>(
        this.apiUrl + '/notifications', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public show(notificationUuid: string) {
    return this.http.get<LaravelResponse<DatabaseNotification>>(this.apiUrl + '/notifications/' + notificationUuid)
        .pipe(catchError(response => this.handleError(response)));
  }

  public store(notification) {
    return this.http.post<LaravelResponse<DatabaseNotification>>(this.apiUrl + '/notifications', notification)
        .pipe(catchError(response => this.handleError(response)));
  }

  public update(notificationUuid: string, notification) {
    return this.http.patch<LaravelResponse<DatabaseNotification>>(
        this.apiUrl + '/notifications/' + notificationUuid, notification,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public delete(notificationUuid: string) {
    return this.http.delete<LaravelResponse<DatabaseNotification>>(this.apiUrl + '/notifications/' + notificationUuid)
        .pipe(catchError(response => this.handleError(response)));
  }

  public indexChannels() {
    return this.http.get<LaravelResponse<Array<NotificationChannel>>>(this.apiUrl + '/notification-channels')
        .pipe(catchError(response => this.handleError(response)));
  }

  public markAsRead(params: { notification_ids?: Array<string> } = {}) {
    return this.http.patch<LaravelResponse<Array<DatabaseNotification>>>(
        this.apiUrl + '/notifications/mark-as-read', params,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexNotifications(caseId: number, channelSlug: NotificationChannelSlug, recipientType: string) {
    const data   = {case_id: caseId, channel_slug: channelSlug, recipient_type: recipientType};
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResponse>(this.apiUrl + '/cases/' + caseId + '/notifications', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public indexCaseNotifications(caseId: number, params) {
    return this.http.get<LaravelResourceResponse<Array<EmailHistory | SmsHistory>>>(
        this.apiUrl + '/cases/' + caseId + '/notification-history', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCallLogs(caseId: number) {
    return this.http.get<LaravelResourceResponse<Array<CallHistory>>>(
        this.apiUrl + '/cases/' + caseId + '/call-logs',
    ).pipe(catchError(response => this.handleError(response)));
  }
}
