import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { BaseChartDirective, Label, MultiDataSet } from 'ng2-charts';
import { interval, Subscription } from 'rxjs';
import { DashboardService } from '../dashboard.service';

@Component({
  selector:    'app-sales-leaderboard',
  templateUrl: './sales-leaderboard.component.html',
  styleUrls:   ['./sales-leaderboard.component.scss'],
})
export class SalesLeaderboardComponent implements OnInit, OnDestroy {
  @Input() withKey: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('salesSOrt') salesSOrt: MatSort;
  @ViewChild('lsoSort') lsoSort: MatSort;
  @ViewChild('dmSort') dmSort: MatSort;
  @ViewChild('bdmSort') bdmSort: MatSort;
  @ViewChild('dgsSort') dgsSort: MatSort;
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  public dataSource: MatTableDataSource<any>;
  public dataSourceDmLso: MatTableDataSource<any>;
  public dataSourceDGS: MatTableDataSource<any>;
  public dataSourceSales: MatTableDataSource<any>;
  public showPage                              = false;
  public totalNumberOfSalesLSO;
  public totalNumberOfSalesDM;
  public totalNumberOfSalesBDM;
  public totalNumberOfSalesDGS;
  public quotaDM;
  public quotaLSO;
  public monthlyQuota;
  public monthlyQuotaDM;
  public monthlyQuotaBDM;
  public monthlyQuotaDGS;
  public showTable                             = 'lso';
  public tableData                             = [];  //  Used for formatting response from API
  public newFormat                             = [];  //  Used for formatting response from API
  public displayedColumns: string[]            = [
    'name',
    'type',
    'today',
    'this_week',
    'this_month',
    'total_fees',
    'quota'];
  public displayedColumnsLso: string[]         = [
    'name',
    'type',
    'today_lso',
    'this_week_lso',
    'this_month_lso',
    'total_fees_lso',
    'quota'];
  public displayedColumnsDm: string[]          = [
    'name',
    'type',
    'today_dm',
    'this_week_dm',
    'this_month_dm',
    'total_fees_dm',
    'quota'];
  public displayedColumnsBDm: string[]         = [
    'name',
    'type',
    'today_bdm',
    'this_week_bdm',
    'this_month_bdm',
    'total_fees_bdm',
    'quota'];
  public displayedColumnsDGs: string[]         = [
    'name',
    'type',
    'today_dgs',
    'this_week_dgs',
    'this_month_dgs',
    'total_fees_dgs',
    'quota'];
  public displayedColumnsSales: string[]       = ['agent_name', 'number_of_sales'];
  public subscription: Subscription;
  public doughnutChartLabels: Label[]          = ['Target', 'No. of sales', 'Sales goal'];
  public doughnutChartLabelsDM: Label[]        = ['Target', 'No. of sales', 'Sales goal'];
  public doughnutChartLabelsDGS: Label[]       = ['Target', 'No. of sales', 'Sales goal'];
  public doughnutChartData: MultiDataSet       = [
    [0, 0, 0],
    [0, 0, 0],
  ];
  public doughnutChartDataDM: MultiDataSet     = [
    [0, 0, 0],
    [0, 0, 0],
  ];
  public doughnutChartDataDGS: MultiDataSet    = [
    [0, 0, 0],
    [0, 0, 0],
  ];
  public doughnutChartType: ChartType          = 'doughnut';
  public doughnutChartOptions: ChartOptions    = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          //  Remove '0' from labels
          return value === 0 ? null : value;
        },
      },
    },
  };
  public doughnutChartOptionsDM: ChartOptions  = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          //  Remove '0' from labels
          return value === 0 ? null : value;
        },
      },
    },
  };
  public doughnutChartOptionsDGS: ChartOptions = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          //  Remove '0' from labels
          return value === 0 ? null : value;
        },
      },
    },
  };
  public doughnutChartPlugins                  = [pluginDataLabels];

  public doughnutChartColors    = [
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
  ];
  public doughnutChartColorsDM  = [
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
  ];
  public doughnutChartColorsBDM = [
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
  ];
  public doughnutChartColorsDGS = [
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
  ];
  public daysInMonth            = moment().daysInMonth();

  constructor(private dashboardService: DashboardService,
              private route: ActivatedRoute,
              public router: Router) {
  }

  ngOnInit(): void {
    if (this.withKey) { //  Key is used when accessing 'sales-leaderboard' page
      const key = this.route.snapshot.queryParamMap?.get('key');
      if (key === '1234567890') { //  Check if key is valid
        this.showPage = true; //  Flag to check if to render page
        this.getSalesData();
        const source      = interval(900000);  //  Get new data every 15 minutes
        this.subscription = source.subscribe(val => this.getSalesData());
      } else {
        this.router.navigateByUrl('/'); //  Redirect to homepage
      }
    } else {  //  If this component is used in 'dashboard' page
      this.showPage = true; //  Flag to check if to render page
      this.getSalesData();
      const source      = interval(900000);  //  Get new data every 15 minutes
      this.subscription = source.subscribe(val => this.getSalesData());
    }
  }

  ngOnDestroy() {
    //  Unsubscribe from interval();
    this.subscription.unsubscribe();
  }

  getSalesData() {
    this.dashboardService.getSalesData()
      .subscribe(next => {
        console.log(next);
        this.totalNumberOfSalesLSO = next.data.agents_this_month_sum_lso;
        this.totalNumberOfSalesDM  = next.data.agents_this_month_sum_dm;
        this.totalNumberOfSalesBDM = next.data.agents_this_month_sum_bdm;
        this.totalNumberOfSalesDGS = next.data.agents_this_month_sum_dgs_dm;
        this.monthlyQuota          = next.data.config.full_target.value;
        this.monthlyQuotaDM        = next.data.config.full_target_dm.value;
        this.monthlyQuotaBDM       = next.data.config.full_target_bdm.value;
        this.monthlyQuotaDGS       = next.data.config.full_target_dgs.value;
        //  500 sales data
        this.dataSourceSales       = new MatTableDataSource(next.data.number_of_sales);
        this.dataSourceSales.sort  = this.salesSOrt;
        //  Sales leaderboard data
        // this.quotaDM = next.data.config.sales_target_dm.value;
        // this.quotaLSO = next.data.config.sales_target.value;
        this.formatDataForTable(next.data);
        //  Update chart with new values
        this.updateChartData();
      });
  }

  public getTotal(field): number {
    let total = 0;
    if (this.dataSource) {
      switch (field) {
        case 'today':
          this.dataSource.data.forEach(data => {
            // Used to count only data from 1st row (since we have 2 data objects for each agent)
            //  This way we ignore data from 2nd row
            if (data.type === 'DM') {
              total += +(data.today || 0);
            }
          });
          break;
        case 'this_week':
          this.dataSource.data.forEach(data => {
            if (data.type === 'DM') {
              total += +(data.this_week || 0);
            }
          });
          break;
        case 'this_month':
          this.dataSource.data.forEach(data => {
            if (data.type === 'DM') {
              total += +(data.this_month || 0);
            }
          });
          break;
        case 'total_fees':
          this.dataSource.data.forEach(data => {
            if (data.type === 'DM') {
              total += +(data.total_fees || 0);
            }
          });
          break;
        case '500':
          this.dataSourceSales.data.forEach(data => {
            total += data.number_of_sales || 0;
          });
          return total;
        default:
          break;
      }
    }

    return total;
  }

  public getTotalDmLso(field): number {
    let total = 0;
    if (this.dataSourceDmLso) {
      switch (field) {
        case 'today':
          this.dataSourceDmLso.data.forEach(data => {
            // Used to count only data from 1st row (since we have 2 data objects for each agent)
            //  This way we ignore data from 2nd row
            if (this.showTable === 'lso') {
              total += +(data.today_lso || 0);
            }
            if (this.showTable === 'dm') {
              total += +(data.today_dm || 0);
            }
            if (this.showTable === 'bdm') {
              total += +(data.today_bdm || 0);
            }
            if (this.showTable === 'dgs') {
              total += +(data.today_dgs || 0);
            }
          });
          break;
        case 'this_week':
          this.dataSourceDmLso.data.forEach(data => {
            if (this.showTable === 'lso') {
              total += +(data.this_week_lso || 0);
            }
            if (this.showTable === 'dm') {
              total += +(data.this_week_dm || 0);
            }
            if (this.showTable === 'bdm') {
              total += +(data.this_week_bdm || 0);
            }
            if (this.showTable === 'dgs') {
              total += +(data.this_week_dgs || 0);
            }
          });
          break;
        case 'this_month':
          this.dataSourceDmLso.data.forEach(data => {
            if (this.showTable === 'lso') {
              total += +(data.this_month_lso || 0);
            }
            if (this.showTable === 'dm') {
              total += +(data.this_month_dm || 0);
            }
            if (this.showTable === 'bdm') {
              total += +(data.this_month_bdm || 0);
            }
            if (this.showTable === 'dgs') {
              total += +(data.this_month_dgs || 0);
            }
          });
          break;
        case 'total_fees':
          this.dataSourceDmLso.data.forEach(data => {
            if (this.showTable === 'lso') {
              total += +(data.total_fees_lso || 0);
            }
            if (this.showTable === 'dm') {
              total += +(data.total_fees_dm || 0);
            }
            if (this.showTable === 'bdm') {
              total += +(data.total_fees_bdm || 0);
            }
            if (this.showTable === 'dgs') {
              total += +(data.total_fees_dgs || 0);
            }
          });
          break;
        default:
          break;
      }
    }

    return total;
  }

  populateDataArray(agent, timeSpan) {
    //  Check if agent exists in table data
    const agentExists = this.tableData.find(d => d.agent_id === agent.agent_id);
    if (agentExists) {  //  If agent exists add
      const agentIndex                              = this.tableData.findIndex(
        x => x.agent_id === agentExists.agent_id);
      this.tableData[agentIndex][timeSpan]          = timeSpan === 'total_fees' ? agent.amount_paid : agent.case_count;
      this.tableData[agentIndex][timeSpan + '_dm']  = timeSpan === 'total_fees' ?
        agent.amount_paid_dm :
        agent.case_count_dm;
      this.tableData[agentIndex][timeSpan + '_bdm'] = timeSpan === 'total_fees' ?
        agent.amount_paid_bdm :
        agent.case_count_bdm;
      this.tableData[agentIndex][timeSpan + '_lso'] = timeSpan === 'total_fees' ?
        agent.amount_paid_lso :
        agent.case_count_lso;
      this.tableData[agentIndex][timeSpan + '_dgs'] = timeSpan === 'total_fees' ?
        agent.amount_paid_dgs :
        agent.case_count_dgs;
    } else {
      this.tableData.push({
        agent_id:            agent.agent_id,
        name:                agent.agent_name,
        [timeSpan]:          timeSpan === 'total_fees' ? agent.amount_paid : agent.case_count,
        [timeSpan + '_dm']:  timeSpan === 'total_fees' ? agent.amount_paid_dm : agent.case_count_dm,
        [timeSpan + '_bdm']: timeSpan === 'total_fees' ? agent.amount_paid_bdm : agent.case_count_bdm,
        [timeSpan + '_lso']: timeSpan === 'total_fees' ? agent.amount_paid_lso : agent.case_count_lso,
        [timeSpan + '_dgs']: timeSpan === 'total_fees' ? agent.amount_paid_dgs : agent.case_count_dgs,
        // quota: agent.target_dm,
        quotaDM:  agent.target_dm,
        quotaBDM: agent.target_bdm,
        quotaLSO: agent.target_lso,
        quotaDGS: agent.target_dgs,
      });
    }
  }

  formatDataForTable(data) {
    data.agents_today.forEach(agentToday => {
      this.populateDataArray(agentToday, 'today');
    });

    data.agents_this_week.forEach(agentWeek => {
      this.populateDataArray(agentWeek, 'this_week');
    });

    data.agents_this_month.forEach(agentMonth => {
      this.populateDataArray(agentMonth, 'this_month');
    });

    data.amounts.forEach(agentMonth => {
      this.populateDataArray(agentMonth, 'total_fees');
    });

    this.newFormat = [];
    this.tableData.forEach(obj => {
      //  Create 2 objects from 1
      //  Used for creating 2 table rows for one agent
      const bdm = {...obj};
      bdm.type  = 'BDM';
      const dm  = {...obj};
      dm.type   = 'DM';
      const lso = {...obj};
      lso.type  = 'LSO';
      const dgs = {...obj};
      dgs.type  = 'DGS';
      this.newFormat.push(lso);
      this.newFormat.push(dm);
      this.newFormat.push(bdm);
      this.newFormat.push(dgs);
    });
    this.dataSource           = new MatTableDataSource(this.newFormat);
    this.dataSource.sort      = this.sort;
    this.dataSourceDmLso      = new MatTableDataSource(this.tableData);
    this.dataSourceDGS        = new MatTableDataSource(this.tableData);
    this.dataSourceDmLso.sort = this.lsoSort;
    this.dataSourceDGS.sort   = this.dgsSort;
    // this.dataSourceDmLso.sort = this.dmSort;
  }

  getPercent(element) {
    if (element.type === 'LSO') {
      if (isFinite(element.this_month_lso / +element.quotaLSO)) {
        return ((element.this_month_lso / +element.quotaLSO) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
    if (element.type === 'DM') {
      if (isFinite(element.this_month_dm / +element.quotaDM)) {
        return ((element.this_month_dm / +element.quotaDM) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
    if (element.type === 'BDM') {
      if (isFinite(element.this_month_bdm / +element.quotaBDM)) {
        return ((element.this_month_bdm / +element.quotaBDM) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
    if (element.type === 'DGS') {
      if (isFinite(element.this_month_dgs / +element.quotaDGS)) {
        return ((element.this_month_dgs / +element.quotaDGS) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
  }

  getPercentDmLso(element) {
    if (this.showTable === 'lso') {
      if (isFinite(element.this_month_lso / +element.quotaLSO)) {
        return ((element.this_month_lso / +element.quotaLSO) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
    if (this.showTable === 'dm') {
      if (isFinite(element.this_month_dm / +element.quotaDM)) {
        return ((element.this_month_dm / +element.quotaDM) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
    if (this.showTable === 'bdm') {
      if (isFinite(element.this_month_bdm / +element.quotaBDM)) {
        return ((element.this_month_bdm / +element.quotaBDM) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
    if (this.showTable === 'dgs') {
      if (isFinite(element.this_month_dgs / +element.quotaDGS)) {
        return ((element.this_month_dgs / +element.quotaDGS) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
  }

  updateChartData() {
    const currentDayInMonth      = moment().date();
    const monthlyTarget          = this.monthlyQuota;
    const monthlyTargetDM        = this.monthlyQuotaDM;
    const monthlyTargetBDM       = this.monthlyQuotaBDM;
    const monthlyTargetDGS       = this.monthlyQuotaDGS;
    const percentOfTotal         = Math.round(currentDayInMonth / this.daysInMonth * 100);
    const dayTarget              = (monthlyTarget / 100) * percentOfTotal;
    const dayTargetDM            = (monthlyTargetDM / 100) * percentOfTotal;
    const dayTargetBDM           = (monthlyTargetBDM / 100) * percentOfTotal;
    const dayTargetDGS           = (monthlyTargetDGS / 100) * percentOfTotal;
    const actualSales            = this.totalNumberOfSalesLSO;
    const actualSalesDM          = this.totalNumberOfSalesDM;
    const actualSalesBDM         = this.totalNumberOfSalesBDM;
    const actualSalesDGS         = this.totalNumberOfSalesDGS;
    //  Prepare LSO chart data
    this.doughnutChartData[0][0] = +dayTarget.toFixed(); //  update day target
    this.doughnutChartData[0][2] = +(monthlyTarget - dayTarget).toFixed(); //  update sales goal
    this.doughnutChartData[1][1] = +actualSales.toFixed(); //  update actual sales
    //  Prepare DM chart data
    this.doughnutChartDataDM[0][0] = +dayTargetDM.toFixed(); //  update day target
    this.doughnutChartDataDM[0][2] = +(monthlyTargetDM - dayTargetDM).toFixed(); //  update sales goal
    this.doughnutChartDataDM[1][1] = +actualSalesDM.toFixed(); //  update actual sales
    // DGS
    this.doughnutChartDataDGS[0][0] = +dayTargetDGS.toFixed(); //  update day target
    this.doughnutChartDataDGS[0][2] = +(monthlyTargetDGS - dayTargetDGS).toFixed(); //  update sales goal
    this.doughnutChartDataDGS[1][1] = +actualSalesDGS.toFixed(); //  update actual sales
    // If sales are greater than monthly quota
    if (+(monthlyTarget - actualSales).toFixed() < 0) {
      this.doughnutChartLabels = ['Target', 'Over Target', 'No. of sales', 'Sales goal'];
      this.doughnutChartColors = [
        {
          backgroundColor: [
            'rgba(4, 55, 61, 1)',
            'rgba(121, 163, 3, 1)',
            'rgba(121, 163, 3, 0.7)',
            'rgba(0, 0, 0, 0.3)'],
        },
        {
          backgroundColor: [
            'rgba(4, 55, 61, 1)',
            'rgba(121, 163, 3, 1)',
            'rgba(121, 163, 3, 0.7)',
            'rgba(0, 0, 0, 0.3)'],
        },
      ];

      this.doughnutChartOptions = {
        tooltips: {
          custom: (label) => {
            if (label.dataPoints && label.dataPoints[0]?.datasetIndex === 1 && label.dataPoints[0]?.index === 2) {
              return label.opacity = 0;
            } else {
              return label;
            }
          },
        },
        plugins:  {
          datalabels: {
            color:     'white',
            formatter: (value, ctx) => {
              if (ctx.dataset.label === 'No. of sales' && ctx.dataIndex === 2) {
                return null;
              } else {
                return value === 0 ? null : value;
              }
            },
          },
        },
      };

      this.doughnutChartData[1][3] = 0; //  update sales goal
      this.doughnutChartData[1][2] = +Math.abs((monthlyTarget - (actualSales - monthlyTarget))).toFixed(); //  update actual sales
      this.doughnutChartData[1][1] = Math.abs(+(monthlyTarget - actualSales).toFixed()); //  update over target
    } else {
      this.doughnutChartData[1][2] = +(monthlyTarget - actualSales).toFixed(); //  update sales goal
    }

    if (+(monthlyTargetDM - actualSalesDM).toFixed() < 0) {
      this.doughnutChartLabelsDM = ['Target', 'Over Target', 'No. of sales', 'Sales goal'];
      this.doughnutChartColorsDM = [
        {
          backgroundColor: [
            'rgba(4, 55, 61, 1)',
            'rgba(121, 163, 3, 1)',
            'rgba(121, 163, 3, 0.7)',
            'rgba(0, 0, 0, 0.3)'],
        },
        {
          backgroundColor: [
            'rgba(4, 55, 61, 1)',
            'rgba(121, 163, 3, 1)',
            'rgba(121, 163, 3, 0.7)',
            'rgba(0, 0, 0, 0.3)'],
        },
      ];

      this.doughnutChartOptionsDM = {
        tooltips: {
          custom: (label) => {
            if (label.dataPoints && label.dataPoints[0]?.datasetIndex === 1 && label.dataPoints[0]?.index === 2) {
              return label.opacity = 0;
            } else {
              return label;
            }
          },
        },
        plugins:  {
          datalabels: {
            color:     'white',
            formatter: (value, ctx) => {
              if (ctx.dataset.label === 'No. of sales' && ctx.dataIndex === 2) {
                return null;
              } else {
                return value === 0 ? null : value;
              }
            },
          },
        },
      };

      this.doughnutChartDataDM[1][3] = 0; //  update sales goal
      this.doughnutChartDataDM[1][2] = +Math.abs((monthlyTargetDM - (actualSalesDM - monthlyTargetDM))).toFixed(); //  update actual sales
      this.doughnutChartDataDM[1][1] = Math.abs(+(monthlyTargetDM - actualSalesDM).toFixed()); //  update over target
    } else {
      this.doughnutChartDataDM[1][2] = +(monthlyTargetDM - actualSalesDM).toFixed(); //  update sales goal
    }

    if (+(monthlyTargetDGS - actualSalesDGS).toFixed() < 0) {
      this.doughnutChartLabelsDGS = ['Target', 'Over Target', 'No. of sales', 'Sales goal'];
      this.doughnutChartColorsDGS = [
        {
          backgroundColor: [
            'rgba(4, 55, 61, 1)',
            'rgba(121, 163, 3, 1)',
            'rgba(121, 163, 3, 0.7)',
            'rgba(0, 0, 0, 0.3)'],
        },
        {
          backgroundColor: [
            'rgba(4, 55, 61, 1)',
            'rgba(121, 163, 3, 1)',
            'rgba(121, 163, 3, 0.7)',
            'rgba(0, 0, 0, 0.3)'],
        },
      ];

      this.doughnutChartOptionsDGS = {
        tooltips: {
          custom: (label) => {
            if (label.dataPoints && label.dataPoints[0]?.datasetIndex === 1 && label.dataPoints[0]?.index === 2) {
              return label.opacity = 0;
            } else {
              return label;
            }
          },
        },
        plugins:  {
          datalabels: {
            color:     'white',
            formatter: (value, ctx) => {
              if (ctx.dataset.label === 'No. of sales' && ctx.dataIndex === 2) {
                return null;
              } else {
                return value === 0 ? null : value;
              }
            },
          },
        },
      };

      this.doughnutChartDataDGS[1][3] = 0; //  update sales goal
      this.doughnutChartDataDGS[1][2] = +Math.abs((monthlyTargetDGS - (actualSalesDGS - monthlyTargetDGS))).toFixed();
      //  update actual sales
      this.doughnutChartDataDGS[1][1] = Math.abs(+(monthlyTargetDGS - actualSalesDGS).toFixed()); //  update over target
    } else {
      this.doughnutChartDataDGS[1][2] = +(monthlyTargetDGS - actualSalesDGS).toFixed(); //  update sales goal
    }

    // END If sales are greater than monthly quota

    //  Update LSO chart
    this.charts.first.chart.update();
    // @ts-ignore
    this.charts._results[1].chart.update();
    //  Update DM chart
    this.charts.last.chart.update();
  }

  onRadioChange($event) {
    this.showTable = $event.value;
    //  Change sort order for `dm` and `lso` `bdm` because we use same dataSource for all
    if ($event.value === 'lso') {
      this.dataSourceDmLso.sort = this.lsoSort;
    }
    if ($event.value === 'dm') {
      this.dataSourceDmLso.sort = this.dmSort;
    }
    if ($event.value === 'bdm') {
      this.dataSourceDmLso.sort = this.bdmSort;
    }
    if ($event.value === 'dgs') {
      this.dataSourceDmLso.sort = this.dgsSort;
    }
  }

}
