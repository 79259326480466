<ng-container>
  <div class="container case-relation-validation " id="case-relation" [ngClass]="{'client-case-component':readonly}">
    <div class="mobile-nav-stepper d-none" style="min-height: 50px">
      <div *ngIf="selectedIndex === 0" style="padding-left: 25px; font-weight: 700; color: #272F66;"
           class="d-flex align-items-center">
        <img class="pen-img" src="/assets/img/pencil.png" alt="" style="width: 30px; margin-right: 15px">
        {{'CLIENT-PORTAL.validator-form.details' | translate}} (1/7)
      </div>
      <div *ngIf="selectedIndex === 1" style="padding-left: 25px; font-weight: 700; color: #272F66;"
           class="d-flex align-items-center">
        <img class="pen-img" src="/assets/img/pencil.png" alt="" style="width: 30px; margin-right: 15px">
        {{'CLIENT-PORTAL.validator-form.creditors' | translate}} (2/7)
      </div>
      <div *ngIf="selectedIndex === 2" style="padding-left: 25px; font-weight: 700; color: #272F66;"
           class="d-flex align-items-center">
        <img class="pen-img" src="/assets/img/pencil.png" alt="" style="width: 30px; margin-right: 15px">
        {{'CLIENT-PORTAL.validator-form.assets' | translate}} (3/7)
      </div>
      <div *ngIf="selectedIndex === 3" style="padding-left: 25px; font-weight: 700; color: #272F66;"
           class="d-flex align-items-center">
        <img class="pen-img" src="/assets/img/pencil.png" alt="" style="width: 30px; margin-right: 15px">
        {{'CLIENT-PORTAL.validator-form.income' | translate}} (4/7)
      </div>
      <div *ngIf="selectedIndex === 4" style="padding-left: 25px; font-weight: 700; color: #272F66;"
           class="d-flex align-items-center">
        <img class="pen-img" src="/assets/img/pencil.png" alt="" style="width: 30px; margin-right: 15px">
        {{'CLIENT-PORTAL.validator-form.expenses' | translate}} (5/7)
      </div>
      <div *ngIf="selectedIndex === 5" style="padding-left: 25px; font-weight: 700; color: #272F66;"
           class="d-flex align-items-center">
        <img class="pen-img" src="/assets/img/pencil.png" alt="" style="width: 30px; margin-right: 15px">
        {{'CLIENT-PORTAL.validator-form.payment' | translate}} (6/7)
      </div>
      <div *ngIf="selectedIndex === 6" style="padding-left: 25px; font-weight: 700; color: #272F66;"
           class="d-flex align-items-center">
        <img class="pen-img" src="/assets/img/pencil.png" alt="" style="width: 30px; margin-right: 15px">
        Last step
      </div>
    </div>
    <h5 *ngIf="readonly" class="ml-3 your-case-heading">Tu caso</h5>
    <p class="ml-3 mt-2" *ngIf="selectedIndex === 0">{{case?.ref_number}}</p>
    <div class="onboarding-info mx-3" *ngIf="false && selectedIndex === 0 && !readonly">
      <h3><b>{{'CLIENT-PORTAL.validator-form.verify_heading' | translate}}</b></h3>
      <span>{{'CLIENT-PORTAL.validator-form.verify_details_one' | translate}}</span>
      <br>
      <br>
      <span>{{'CLIENT-PORTAL.validator-form.verify_details_two' | translate}}</span>
    </div>
    <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="setSelectedIndex($event)"
                            disableRipple="true">
      <mat-step [stepControl]="validationForm.get('user')">
        <ng-template matStepLabel>{{'CLIENT-PORTAL.validator-form.steps.details_step' | translate}}</ng-template>
        <div class="card">
          <div class="card-body" style="margin-bottom: 41px;">
            <app-details-step *ngIf="primaryClient" [isReadOnly]="readonly" [primaryClient]="primaryClient"
                              [secondaryClient]="secondaryClient"
                              [userUuid]="userUuid" [caseUuid]="caseUuid">
            </app-details-step>

            <div *ngIf="caseRelationValidation && !readonly" class="d-flex flex-column">
              <div style="min-width: 125px" class="d-flex justify-content-center align-items-center" [ngStyle]="{'margin-bottom': !readonly ? '2rem': ''}">
                <button *ngIf="!isSendingCorrect" class="btn correct-btn w-100"
                        (click)="validateStep('user', true)">
                  {{ "CLIENT-PORTAL.validator-form.save-and-continue" | translate }}
                </button>
              <!--  <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
              </div>
            </div>
            <div *ngIf="readonly" style="min-width: 125px;margin-bottom: 2rem;" class="d-flex flex-column">
              <button class="btn correct-btn correct-btn-sm"
                      (click)="validateStep('user', true)">
                {{ "CLIENT-PORTAL.validator-form.next" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="validationForm.get('creditors')">
        <ng-template matStepLabel>{{'CLIENT-PORTAL.validator-form.steps.creditors_step' | translate}}</ng-template>
        <div class="card">
          <div class="card-body" style="margin-bottom: 41px;">
            <app-creditors-step [jointApplication]="case?.joint_application" [userUuid]="userUuid"
                                [caseUuid]="caseUuid" [isReadOnly]="readonly"></app-creditors-step>
            <div *ngIf="caseRelationValidation  && !readonly" class="d-flex flex-column">
              <div style="min-width: 125px" class="d-flex justify-content-center align-items-center">
                <button *ngIf="!isSendingNotCorrect" class="mb-2 btn incorrect-btn w-100"
                        (click)="goBack()">
                  {{ "CLIENT-PORTAL.validator-form.back" | translate }}
                </button>
                <mat-spinner *ngIf="isSendingNotCorrect" diameter="25" color="primary"></mat-spinner>
              </div>
              <div style="min-width: 125px" class="d-flex justify-content-center align-items-center" [ngStyle]="{'margin-bottom': !readonly ? '2rem': ''}">
                <button *ngIf="!isSendingCorrect" class="btn correct-btn w-100"
                        (click)="validateStep('creditors', true)">
                  {{ "CLIENT-PORTAL.validator-form.save-and-continue" | translate }}
                </button>
               <!-- <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
              </div>
            </div>
            <div *ngIf="readonly" style="min-width: 125px;margin-bottom: 2rem;" class="d-flex flex-column" >
              <button class="incorrect-btn mr-2 mb-2"
                      (click)="goBack()">{{'CLIENT-PORTAL.validator-form.back' | translate}}
              </button>
              <button class="btn correct-btn"
                      (click)="validateStep('user', true)">
                {{ "CLIENT-PORTAL.validator-form.next" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="validationForm.get('assets')">
        <ng-template matStepLabel>{{'CLIENT-PORTAL.validator-form.steps.assets_step' | translate}}</ng-template>
        <div class="card">
          <div class="card-body" style="margin-bottom: 41px;">
            <app-assets-step [userUuid]="userUuid" [caseUuid]="caseUuid" [isReadOnly]="readonly"></app-assets-step>
            <div *ngIf="caseRelationValidation  && !readonly" class="d-flex flex-column">
              <div style="min-width: 125px" class="d-flex justify-content-center align-items-center">
                <button class="mb-2 btn incorrect-btn w-100"
                        (click)="goBack()">
                  {{ "CLIENT-PORTAL.validator-form.back" | translate }}
                </button>
                <!--<mat-spinner *ngIf="isSendingNotCorrect" diameter="25" color="primary"></mat-spinner>-->
              </div>
              <div style="min-width: 125px" class="d-flex justify-content-center align-items-center" [ngStyle]="{'margin-bottom': !readonly ? '2rem': ''}">
                <button *ngIf="!isSendingCorrect" class="btn correct-btn w-100"
                        (click)="validateStep('assets', true)">
                  {{ "CLIENT-PORTAL.validator-form.save-and-continue" | translate }}
                </button>
              <!--  <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
              </div>
            </div>
            <div *ngIf="readonly" style="min-width: 125px;margin-bottom: 2rem;" class="d-flex flex-column">
              <button class="incorrect-btn mr-2 mb-2"
                      (click)="goBack()"> {{ "CLIENT-PORTAL.validator-form.back" | translate }}
              </button>
              <button class="btn correct-btn"
                      (click)="validateStep('user', true)">
                {{ "CLIENT-PORTAL.validator-form.next" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="validationForm.get('income')">
        <ng-template matStepLabel>{{'CLIENT-PORTAL.validator-form.steps.income_step' | translate}}</ng-template>
        <div class="card">
          <div class="card-body" style="margin-bottom: 41px;">
            <app-income-step [userUuid]="userUuid" [caseUuid]="caseUuid" [isReadOnly]="readonly"></app-income-step>
            <div *ngIf="caseRelationValidation  && !readonly" class="d-flex flex-column">
              <div style="min-width: 125px" class="d-flex justify-content-center align-items-center">
                <button class="mb-2 btn incorrect-btn w-100"
                        (click)="goBack()">
                  {{ "SHARED.back" | translate }}
                </button>
               <!-- <mat-spinner *ngIf="isSendingNotCorrect" diameter="25" color="primary"></mat-spinner>-->
              </div>
              <div style="min-width: 125px;margin-bottom: 2rem;" class="d-flex justify-content-center align-items-center" [ngStyle]="{'margin-bottom': !readonly ? '2rem': ''}">
                <button *ngIf="!isSendingCorrect" class="btn correct-btn w-100 "
                        (click)="validateStep('income', true)">
                  {{ "CLIENT-PORTAL.validator-form.save-and-continue" | translate }}
                </button>
              <!--  <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
              </div>
            </div>
            <div *ngIf="readonly" style="min-width: 125px;margin-bottom: 2rem;" class="d-flex flex-column">
              <button class="incorrect-btn mr-2 mb-2"
                      (click)="goBack()"> {{ "CLIENT-PORTAL.validator-form.back" | translate }}
              </button>
              <button class="btn correct-btn"
                      (click)="validateStep('user', true)">
                {{ "CLIENT-PORTAL.validator-form.next" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="validationForm.get('expense')">
        <ng-template matStepLabel>{{'CLIENT-PORTAL.validator-form.steps.expanses_step' | translate}}</ng-template>
        <div class="card">
          <div class="card-body" style="margin-bottom: 41px;">
            <app-expense-step [userUuid]="userUuid" [caseUuid]="caseUuid" [isReadOnly]="readonly"></app-expense-step>
            <div *ngIf="caseRelationValidation  && !readonly" class="d-flex flex-column">
              <div style="min-width: 125px" class="d-flex justify-content-center align-items-center">
                <button class="mb-2 btn incorrect-btn w-100"
                        (click)="goBack()">
                  {{ "CLIENT-PORTAL.validator-form.back" | translate }}
                </button>
              <!--  <mat-spinner *ngIf="isSendingNotCorrect" diameter="25" color="primary"></mat-spinner>-->
              </div>
              <div style="min-width: 125px;" class="d-flex align-items-center" [ngStyle]="{'margin-bottom': !readonly ? '2rem': ''}">
                <button *ngIf="!isSendingCorrect" class="btn correct-btn w-100"
                        (click)="validateStep('expense', true)">
                  {{'CLIENT-PORTAL.validator-form.submit-verification' | translate}}
                </button>
               <!-- <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
              </div>
            </div>
            <div *ngIf="readonly" style="min-width: 125px;margin-bottom: 2rem;" class="d-flex flex-column">
              <button class="incorrect-btn mr-2 mb-2"
                      (click)="goBack()"> {{ "CLIENT-PORTAL.validator-form.back" | translate }}
              </button>
              <button class="correct-btn mr-2" routerLink="/">Incio
              </button>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</ng-container>
