<div class="div justify-content-center details-step-container client-typography-v2" *ngIf="form">
  <div class="salary" *ngIf="salaryArray.length">
    <h2 class="pr-3 m-0">{{"CASES.single.wage-salary" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let salary of salaryArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="salary.value > 0">
            <span class="income-value">
              <p class="my-0">{{'AEP-SIGNATURE.' + salary.name | translate}}</p>
              {{salary.value | number: '1.2-2'}}€
            </span>
            <span class="d-flex align-items-end" *ngIf="!isReadOnly">
              <div class="slide-toggle-wrapper mr-2">
                <mat-slide-toggle formControlName="{{salary.name}}" disableRipple>
                </mat-slide-toggle>
              </div>
            </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="pension " *ngIf="pensionTotal && pensionArray.length">
    <h2 class="pr-3 m-0">{{"CASES.single.pensions" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let pension of pensionArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="pension.value > 0">
            <span class="income-value">
              <p class="my-0">{{'AEP-SIGNATURE.' + pension.name | translate}}</p>
              {{pension.value | number: '1.2-2'}}€
            </span>
            <span class="d-flex align-items-end" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper mr-2">
              <mat-slide-toggle formControlName="{{pension.name}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="other-pension" *ngIf="otherPensionsTotal && otherPensionsArray.length">
    <h2 class="pr-3 m-0">{{"CASES.single.benefits" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let pension of otherPensionsArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="pension.value > 0">
            <span class="income-value">
              <p class="m-0">{{'AEP-SIGNATURE.' + pension.name | translate}}</p>
              {{pension.value | number: '1.2-2'}}€
            </span>
            <span class="d-flex align-items-end" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper mr-2">
              <mat-slide-toggle formControlName="{{pension.name}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="other-pension" *ngIf="otherTotal && otherArray.length">
    <h2 class="pr-3 m-0">{{"CASES.single.other-income" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let other of otherArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="other.value > 0">
              <span class="income-value">
                <p class="m-0">{{'AEP-SIGNATURE.' + other.name | translate}}</p>
                {{other.value | number: '1.2-2'}}€
              </span>
            <span class="d-flex align-items-end" *ngIf="!isReadOnly">
                <div class="slide-toggle-wrapper mr-2">
                  <mat-slide-toggle formControlName="{{other.name}}" disableRipple>
                  </mat-slide-toggle>
                </div>
              </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="pt-3 pb-3" *ngIf="!isLoading && !salaryTotal && !pensionTotal && !otherTotal &&!otherPensionsTotal">
    <p class="text-center">No tienes ingresos</p>
  </div>

  <div class="other-salaries" *ngIf="!isReadOnly">
    <table class="table table-borderless table-striped client-table"
           [formGroup]="form" *ngIf="form && !isLoading">
      <tbody>
      <tr>
        <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
            *ngIf="specialIncomesTotal">
              <span class="income-value">
                <p class="m-0">{{"CLIENT-PORTAL.validator-form.special_incomes" | translate}}</p>
                {{specialIncomesTotal | number: '1.2-2'}}€
              </span>
          <span class="d-flex align-items-end" *ngIf="!isReadOnly">
                <div class="slide-toggle-wrapper mr-2">
                  <mat-slide-toggle formControlName="special_incomes" disableRipple>
                  </mat-slide-toggle>
                </div>
              </span>
        </td>
      </tr>
      <tr>
        <td class="d-flex flex-row justify-content-between mr-1">
          <p class="m-0">{{"CLIENT-PORTAL.validator-form.other-salaries" | translate}}?</p>
          <mat-slide-toggle class="ml-2 mr-24" formControlName="other_salaries" disableRipple>
          </mat-slide-toggle>
          <!-- <mat-radio-group formControlName="other_salaries" aria-label="Select an option">
             <mat-radio-button [value]="true" class="mr-2">
               {{ "SHARED.yes" | translate }}
             </mat-radio-button>
             <mat-radio-button [value]="false" class="no-radio-btn">
               {{ "SHARED.no" | translate }}
             </mat-radio-button>
           </mat-radio-group>-->
        </td>
      </tr>
      <tr>
        <td class="d-flex flex-row justify-content-between mr-1">
          <p class="m-0">{{"CLIENT-PORTAL.validator-form.special_incomes" | translate}}?</p>
          <mat-slide-toggle class="ml-2 mr-24" formControlName="special_incomes" disableRipple>
          </mat-slide-toggle>
      </tr>
      </tbody>
    </table>
  </div>

</div>
