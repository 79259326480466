import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaseRelationValidationComponent } from './case-relation-validation/case-relation-validation.component';
import { SuccessPageComponent } from './case-relation-validation/success-page/success-page.component';
import { VerifyDetailsComponent } from './case-relation-validation/verify-details/verify-details.component';
import { ContractsSignComponent } from './contracts/contracts-sign/contracts-sign.component';
import { ClientDashboardComponent } from './dashboard/client-dashboard.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import {
  SignAssetAndCreditorListComponent,
} from './documents/sign-asset-and-creditor-list/sign-asset-and-creditor-list.component';
import { ESignatureComponent } from './e-signature/e-signature.component';
import { NotaryDownloadComponent } from './notary-download/notary-download.component';
import {
  SignDebtCancellationRequestComponent,
} from './sign-debt-cancellation-request/sign-debt-cancellation-request.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { ClientProfileSettingsComponent } from './user/client-profile-settings/client-profile-settings.component';

const routes: Routes = [
  {
    path:     'client',
    children: [
      // {path: 'login', component: ClientLoginComponent},
      // {path: 'sign-up', component: ClientSignUpComponent},
      // {path: 'forgot-password', component: ClientForgotPasswordComponent},
      // {path: 'new-password', component: ClientNewPasswordComponent},
      {path: 'dashboard', component: ClientDashboardComponent},
      {path: 'success', component: SuccessPageComponent},
      {path: 'verify', component: VerifyDetailsComponent},
      {
        path:      'cases/:caseUuid/users/:userUuid/relation-validation',
        component: CaseRelationValidationComponent,
        data:      {readonly: false},
      },
      {path: 'upload-files', component: UploadFilesComponent},
      {path: 'sign-contract', component: ContractsSignComponent},
      {path: 'sign-creditor-asset-list', component: SignAssetAndCreditorListComponent},
      {path: 'sign-debt-cancellation-request', component: SignDebtCancellationRequestComponent},
      {path: 'cases/:caseUuid/profile', component: ClientProfileSettingsComponent},
      {path:       'cases/:caseUuid/users/:userUuid/your-case',
        component: CaseRelationValidationComponent,
        data:      {readonly: true},
      },
      {path: 'cases/:caseUuid/users/:userUuid/document-upload', component: DocumentUploadComponent},
      {path: 'e-sign', component: ESignatureComponent},
      // {path: 'aep-signature', component: AepSignatureComponent},
      {path: 'unsubscribe', component: UnsubscribeComponent},
      {path: 'notary-download', component: NotaryDownloadComponent}, // TODO: move to public?
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class ClientRoutingModule {
}


