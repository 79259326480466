import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment/moment';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DistributionBatch } from '../../../../../../_base-shared/models/Distribution/DistributionBatch';
import {
  AppointCourtModalComponent,
} from '../../case/case-detail/case-draft/appoint-court-modal/appoint-court-modal.component';
import { DistributionBatchService } from '../distribution-batch.service';
import { DistributionService } from '../distribution.service';

@Component({
  selector:    'app-distribution-report-modal',
  templateUrl: './distribution-report-modal.component.html',
  styleUrls:   ['./distribution-report-modal.component.scss'],
})
export class DistributionReportModalComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public batches: MatTableDataSource<DistributionBatch>;
  public noBatches      = false;
  public requestFilters = {
    search:                 '',
    distribution_batch_ids: [],
    report_group:           '',
    page:                   1,
    per_page:               10,
    statuses:               [],
    select_all:             true,
  };

  public isLoading                       = 0;
  public isSubmitting                    = false;
  public searchFocus                     = false;
  public displayedColumns: Array<string> = ['select', 'name', 'status'];
  public selection                       = new SelectionModel<any>(true, []);
  public searchControl: UntypedFormControl      = new UntypedFormControl();

  private totalResults: number;
  private totalPages: number;
  public paginatorConfig = {
    pageIndex: 0,
    pageSize:  6,
    length:    1,
  };

  public reportType: 'scheduled_fee' | 'payments_received' | 'claims' | 'funding_claims' | 'funding_schedule_fee';
  public reportGroup: 'funding' | 'performance';
  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private translate: TranslateService,
              private distributionBatchService: DistributionBatchService,
              private distributionService: DistributionService,
              public dialogRef: MatDialogRef<AppointCourtModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.reportType  = this.data.reportType;
    this.reportGroup = this.data.reportGroup;
    this.fetchBatches();
    this.buildForm(this.reportType);

    this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(keyword => {
      this.requestFilters.search = keyword;
      this.fetchBatches();
    });
  }

  public toggleRow(event: MatCheckboxChange, row) {
    this.noBatches = false;
    this.selection.toggle(row);
  }

  public submitSelectedBatch() {
    if (this.selection.selected.length === 0) {
      this.noBatches = true;
      return;
    }

    this.selection.selected.forEach(selectedBatch => this.requestFilters.distribution_batch_ids.push(selectedBatch.id));

    const formSubmitValue = {};
    if (this.form) {
      Object.keys(this.form.value).forEach(formField => {
        formSubmitValue[formField] = this.form.value[formField] instanceof Date ?
            this.form.value[formField].toISOString() :
            this.form.value[formField];
      });
    }

    const requestFilters        = {...this.requestFilters, ...formSubmitValue};
    requestFilters.report_group = this.reportGroup;
    requestFilters.statuses     = [];
    requestFilters.per_page     = null;
    requestFilters.page         = null;

    this.isSubmitting = true;
    this.distributionService.export(this.reportType, requestFilters).pipe(finalize(() => this.isSubmitting = false))
        .subscribe(result => {
          this.toastr.success(this.translate.instant('DISTRIBUTION.report.export_success'));
          this.closeModal(true);
        }, error => {
          this.toastr.error(this.translate.instant('DISTRIBUTION.report.export_failed'));
          this.closeModal(true);
        });
  }

  private fetchBatches() {
    this.selection.clear();

    this.isLoading++;

    this.requestFilters.statuses = this.reportGroup === 'funding' ? ['pending', 'submitted'] : ['funded'];

    this.distributionBatchService.index(this.requestFilters)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.selection.clear();
          this.batches                = new MatTableDataSource<DistributionBatch>(result.data);
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        });
  }

  public closeModal(dismissed = true, data = null) {
    this.dialogRef.close({dismissed, data});
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.requestFilters.page     = this.paginatorConfig.pageIndex + 1;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchBatches();
  }

  private buildForm(reportType: 'scheduled_fee' | 'payments_received' | 'claims' | 'funding_claims' | 'funding_schedule_fee') {
    if (reportType === 'payments_received') {
      this.form = this.fb.group({
        payment_from: [null],
        payment_to:   [null],
      });
      return;
    }
    this.form = null;
  }

  public dateChanged($event: MatDatepickerInputEvent<unknown>, formControlName, endDay = false) {
    if (endDay) {
      const date = moment($event.value).endOf('day');
      this.form.get(formControlName).patchValue(date.toDate());
    }
  }

  public clearFormControl($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }
}
