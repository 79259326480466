<h1 class="pr-3">{{outcome?.name}}</h1>
<div *ngIf="outcome?.name === 'LSO' || outcome?.name === 'LSO2'" class="outcome">
  <p class="pt-2">Cumple con los requisitos de la ley de segunda oportunidad.</p>
  <p><b>Esto te permitirá:</b></p>
  <ul>
    <li>Cancelar el 100% de sus deudas</li>
    <li>Congele todos los saldos e intereses mientras negociamos esto</li>
    <li>Detenga todo contacto con los acreedores tan pronto como se formalice el proceso</li>
  </ul>
  <p>Podemos enviarle nuestra hoja de información y el contrato estándar para explicar el proceso.</p>
  <p>Puede pagar € 250 más IVA hoy para comenzar el proceso y una vez que firme nuestro mandato nos comunicaremos
    con los acreedores para informarles que dejen de contactarlo.</p>
  <p>Luego, puede detener los pagos de sus préstamos y tarjetas de crédito para pagar mejor nuestras tarifas y
    acelerar el proceso de exoneración y cancelación de todas las deudas.</p>
  <p>Noté que tiene crédito disponible en sus tarjetas actuales, algunos clientes lo han usado para pagar tarifas,
    ya que estos costos también calificarán para la cancelación como parte del proceso.
  </p>
</div>
<div *ngIf="outcome?.name === 'DM' || outcome?.name === 'BDM'" class="outcome">
  <p class="pt-2">Según la información proporcionada, la ley de segunda oportunidad no es adecuada para su
    situación.</p>
  <p><b>Existe un plan alternativo de gestión de la deuda que:</b></p>
  <ul>
    <li>Asegure cualquier activo que posea</li>
    <li>Detenga todo contacto de acreedores con usted</li>
    <li>Congelar saldos y pagos de intereses</li>
    <li>Acuerde un monto mensual más bajo para todas las deudas.</li>
  </ul>
  <p>Este plan es una solución simple con costos más bajos.</p>
  <p>Si acepta, podemos enviarle un mandato para indicarnos que contratemos a sus acreedores sin cargo.</p>
  <p>uestro objetivo será detener el contacto, congelar los intereses y extender el período del préstamo para
    reducir sus pagos mensuales.</p>
</div>
