<div class="div justify-content-center details-step-container client-typography-v2">
  <div class="essential" *ngIf="essentialTotal">
    <h2 class="pr-3 m-0">{{"CASES.single.essential-expenditure" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let salary of essentialArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="salary.value > 0">
              <span class="expense-value">
              <p class="m-0">{{'AEP-SIGNATURE.' + salary.name | translate}}</p>
                {{salary.value | number: '1.2-2'}}€
            </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
                <div class="slide-toggle-wrapper">
                  <mat-slide-toggle formControlName="{{salary.name}}" disableRipple>
                  </mat-slide-toggle>
                </div>
              </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="living pt-3" *ngIf="livingExpensesTotal">
    <h2 class="pr-3 m-0">{{"CASES.single.living-expenses" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between" >
        <ng-container *ngFor="let living of livingExpensesArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="living.value > 0">
              <span class="expense-value">
              <p class="m-0">{{'AEP-SIGNATURE.' + living.name | translate}}</p>
                {{living.value | number: '1.2-2'}}€
            </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
                <div class="slide-toggle-wrapper">
                  <mat-slide-toggle formControlName="{{living.name}}" disableRipple>
                  </mat-slide-toggle>
                </div>
              </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="phone pt-3" *ngIf="phoneExpensesTotal">
    <h2 class="pr-3 m-0">{{"CASES.single.phone" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let phone of phoneExpensesArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="phone.value > 0">
              <span class="expense-value">
              <p class="m-0">{{'AEP-SIGNATURE.' + phone.name | translate}}</p>
                {{phone.value | number: '1.2-2'}}€
            </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
                <div class="slide-toggle-wrapper">
                  <mat-slide-toggle formControlName="{{phone.name}}" disableRipple>
                  </mat-slide-toggle>
                </div>
              </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="travel pt-3" *ngIf="travelExpensesTotal">
    <h2 class="pr-3 m-0">{{"CASES.single.travel" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let travel of travelExpensesArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="travel.value > 0">
              <span class="expense-value">
              <p class="m-0">{{'AEP-SIGNATURE.' + travel.name | translate}}</p>
                {{travel.value | number: '1.2-2'}}€
            </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
              <div class="slide-toggle-wrapper">
                <mat-slide-toggle formControlName="{{travel.name}}" disableRipple>
                </mat-slide-toggle>
              </div>
            </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="house pt-3" *ngIf="housekeepingTotal">
    <h2 class="pr-3 m-0">{{"CASES.single.housekeeping" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let house of housekeepingArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="house.value > 0">
              <span class="expense-value">
              <p class="m-0">{{'AEP-SIGNATURE.' + house.name | translate}}</p>
                {{house.value | number: '1.2-2'}}€
            </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
                <div class="slide-toggle-wrapper">
                  <mat-slide-toggle formControlName="{{house.name}}" disableRipple>
                  </mat-slide-toggle>
                </div>
              </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="other pt-3" *ngIf="otherTotal">
    <h2 class="pr-3 m-0">{{"CASES.single.other-expenditures" | translate | titlecase }}</h2>
    <table class="table table-borderless table-striped client-table" [formGroup]="form" *ngIf="!isLoading">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let other of otherArray">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="other.value > 0">
            <ng-container *ngIf="other.value">
              <span class="expense-value">
              <p class="m-0">{{'AEP-SIGNATURE.' + other.name | translate}}</p>
                {{other.value | number: '1.2-2'}}€
            </span>
              <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
              <div class="slide-toggle-wrapper">
                <mat-slide-toggle formControlName="{{other.name}}" disableRipple>
                </mat-slide-toggle>
              </div>
            </span>
            </ng-container>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="other-salaries pt-3" *ngIf="!isReadOnly">
    <table class="table table-borderless table-striped client-table" [formGroup]="form"
           *ngIf="form && !isLoading">
      <tbody>
      <tr>
        <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
            *ngIf="specialExpensesTotal">
              <span class="income-value">
                <p class="m-0">{{"CLIENT-PORTAL.validator-form.special_expenses" | translate}}</p>
                {{specialExpensesTotal | number: '1.2-2'}}€
              </span>
          <span class="d-flex align-items-end" *ngIf="!isReadOnly">
                <div class="slide-toggle-wrapper mr-2">
                  <mat-slide-toggle formControlName="special_expenses" disableRipple>
                  </mat-slide-toggle>
                </div>
              </span>
        </td>
      </tr>
      <tr>
        <td class="d-flex flex-row justify-content-between mr-1">
          <p class="m-0">{{"CLIENT-PORTAL.validator-form.have-other-expenses" | translate}}</p>
          <mat-slide-toggle class="ml-2 mr-24" formControlName="other_expenses" disableRipple>
          </mat-slide-toggle>
          <!--<mat-radio-group formControlName="other_expenses" aria-label="Select an option">
            <mat-radio-button [value]="true" class="mr-2">
              {{ "SHARED.yes" | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="false" class="no-radio-btn">
              {{ "SHARED.no" | translate }}
            </mat-radio-button>
          </mat-radio-group>-->
        </td>
      </tr>
      <tr>
        <td class="d-flex flex-row justify-content-between mr-1">
          <p class="m-0">{{"CLIENT-PORTAL.validator-form.special_expenses" | translate}}</p>
          <mat-slide-toggle class="ml-2 mr-24" formControlName="special_expenses" disableRipple>
          </mat-slide-toggle>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
