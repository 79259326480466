<ng-container>
  <div class="onboarding-stepper-desktop">
    <div class="onboarding-stepper-container">
      <div class="row h-100 w-100">
        <!--Onboarding content-->
        <div class="col-8 onboarding-details h-100">
          <mat-vertical-stepper #stepper [@.disabled]="true" class="h-100">
            <!--Step 1-->
            <mat-step label="Step 1" [stepControl]="validationForm.get('user')">
              <div>
                <div>
                  <span class="text-muted">Tu caso</span>
                  <h2>{{'CLIENT-PORTAL.validator-form.details' | translate}}</h2>
                  <p>{{case?.ref_number}}</p>
                  <app-details-step *ngIf="primaryClient" [isReadOnly]="readonly" [primaryClient]="primaryClient"
                                    [secondaryClient]="secondaryClient"
                                    [userUuid]="userUuid" [caseUuid]="caseUuid">
                  </app-details-step>

                  <div *ngIf="caseRelationValidation" class="d-flex flex-row">
                    <button class="save-btn-one mr-2" (click)="goBack()" *ngIf="stepper.selectedIndex > 0">Back
                    </button>
                    <div style="min-width: 125px" class="d-flex">
                      <button *ngIf="!isSendingCorrect" class="btn client-button-correct"
                              (click)="validateStep('user', true)">
                        {{ "CLIENT-PORTAL.validator-form.next" | translate }}
                      </button>
                      <!--  <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
            <!--Step 2-->
            <mat-step label="Step 2" [stepControl]="validationForm.get('creditors')">
              <div>
                <div>
                  <span class="text-muted">Tu caso</span>
                  <h2>{{'CLIENT-PORTAL.validator-form.creditors' | translate}}</h2>
                  <app-creditors-step [jointApplication]="case?.joint_application" [userUuid]="userUuid"
                                      [isDesktop]="desktopVersion"
                                      [caseUuid]="caseUuid" [isReadOnly]="readonly"></app-creditors-step>
                  <div *ngIf="caseRelationValidation" class="d-flex flex-row">
                    <button class="save-btn-one mr-2" (click)="goBack()" *ngIf="stepper.selectedIndex > 0">
                      {{'CLIENT-PORTAL.validator-form.back' | translate}}
                    </button>
                    <div class="d-flex">
                      <button *ngIf="!isSendingCorrect" class="btn client-button-correct"
                              (click)="validateStep('creditors', true)">
                        {{ "CLIENT-PORTAL.validator-form.next" | translate }}
                      </button>
                      <!--   <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
            <!--Step 3-->
            <mat-step label="Step 3" [stepControl]="validationForm.get('assets')">
              <div>
                <div>
                  <span class="text-muted">Tu caso</span>
                  <h2>{{'CLIENT-PORTAL.validator-form.assets' | translate}}</h2>
                  <app-assets-step [userUuid]="userUuid" [caseUuid]="caseUuid" [isDesktop]="desktopVersion"
                                   [isReadOnly]="readonly"></app-assets-step>
                  <div *ngIf="caseRelationValidation" class="d-flex flex-row">
                    <button class="save-btn-one mr-2" (click)="goBack()" *ngIf="stepper.selectedIndex > 0">
                      {{'CLIENT-PORTAL.validator-form.back' | translate}}
                    </button>
                    <div style="min-width: 125px" class="d-flex">
                      <button *ngIf="!isSendingCorrect" class="btn client-button-correct"
                              (click)="validateStep('assets', true)">
                        {{ "CLIENT-PORTAL.validator-form.next" | translate }}
                      </button>
                      <!--<mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
            <!--Step 4-->
            <mat-step label="Step 4" [stepControl]="validationForm.get('income')">
              <div>
                <div>
                  <span class="text-muted">Tu caso</span>
                  <h2>{{'CLIENT-PORTAL.validator-form.income' | translate}}</h2>
                  <app-income-step [userUuid]="userUuid" [caseUuid]="caseUuid" [isDesktop]="desktopVersion"
                                   [isReadOnly]="readonly"></app-income-step>
                  <div *ngIf="caseRelationValidation" class="d-flex flex-row">
                    <button class="save-btn-one mr-2" (click)="goBack()" *ngIf="stepper.selectedIndex > 0">
                      {{'CLIENT-PORTAL.validator-form.back' | translate}}
                    </button>
                    <div style="min-width: 125px" class="d-flex">
                      <button *ngIf="!isSendingCorrect" class="btn client-button-correct"
                              (click)="validateStep('income', true)">
                        {{ "CLIENT-PORTAL.validator-form.next" | translate }}
                      </button>
                      <!-- <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
            <!--Step 5-->
            <mat-step label="Step 5" [stepControl]="validationForm.get('expense')">
              <div>
                <div>
                  <span class="text-muted">Tu caso</span>
                  <h2>{{'CLIENT-PORTAL.validator-form.expense' | translate}}</h2>
                  <app-expense-step [userUuid]="userUuid" [caseUuid]="caseUuid" [isDesktop]="desktopVersion"
                                    [isReadOnly]="readonly"></app-expense-step>
                  <div *ngIf="caseRelationValidation" class="d-flex flex-row">
                    <button class="save-btn-one mr-2" routerLink="/" *ngIf="stepper.selectedIndex > 0">Incio
                    </button>
                    <div style="min-width: 125px" class="d-flex" *ngIf="!readonly">
                      <button *ngIf="!isSendingCorrect" class="btn client-button-submit"
                              (click)="validateStep('expense', true)">
                        {{'CLIENT-PORTAL.validator-form.submit-verification' | translate}}
                      </button>
                      <!--  <mat-spinner *ngIf="isSendingCorrect" diameter="25" color="primary"></mat-spinner>-->
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
            <!--Step 6-->
            <mat-step label="Step 5" [stepControl]="validationForm.get('expense')" *ngIf="!readonly">
              <div>
                <div>
                  <span class="text-muted">Tu caso</span>
                  <h2>{{'CASES.details.verify' | translate}}</h2>
                  <app-verify-details></app-verify-details>
                </div>
              </div>
            </mat-step>
          </mat-vertical-stepper>
        </div>

        <!--Controls-->
        <div class="col-4 onboarding-controls-container h-100">
          <div class="onboarding-controls">
            <div>
              <ul>
                <!--Details-->
                <li [ngClass]="{'active': stepper.selectedIndex == 0}"
                    (click)="stepHeaderChanged(stepper.selectedIndex)"
                    *ngIf="isDetailsTouched === false">
                  <div class="mat-header-control">
                    <mat-icon [ngStyle]="{'display': stepper.selectedIndex == 0 ? 'block' : 'none'}">create</mat-icon>
                    <span [ngStyle]="{'display': stepper.selectedIndex == 0 ? 'none' : 'block'}">1</span>
                  </div>
                  <a (click)="move(0)">{{'CLIENT-PORTAL.validator-form.steps.details_step' | translate}}</a>
                </li>
                <li *ngIf="isDetailsTouched === true"
                    (click)="stepHeaderChanged(stepper.selectedIndex)">
                  <div class="mat-header-control-edit" *ngIf="stepper.selectedIndex === 0">
                    <mat-icon>create</mat-icon>
                  </div>
                  <div class="mat-header-control-done" *ngIf="stepper.selectedIndex !== 0">
                    <mat-icon>check</mat-icon>
                  </div>
                  <a (click)="move(0)">{{'CLIENT-PORTAL.validator-form.steps.details_step' | translate}}</a>
                </li>
                <!--Creditors-->
                <li [ngClass]="{'active': stepper.selectedIndex == 1}"
                    (click)="stepHeaderChanged(stepper.selectedIndex)" *ngIf="isCreditorsTouched === false">
                  <div class="mat-header-control">
                    <mat-icon [ngStyle]="{'display': stepper.selectedIndex == 1 ? 'block' : 'none'}">create</mat-icon>
                    <span [ngStyle]="{'display': stepper.selectedIndex == 1 ? 'none' : 'block'}">2</span>
                  </div>
                  <a (click)="move(1)">
                    {{'CLIENT-PORTAL.validator-form.steps.creditors_step' | translate}}</a>
                </li>
                <li *ngIf="isCreditorsTouched === true"
                    (click)="stepHeaderChanged(stepper.selectedIndex)">
                  <div class="mat-header-control-edit" *ngIf="stepper.selectedIndex === 1">
                    <mat-icon>create</mat-icon>
                  </div>
                  <div class="mat-header-control-done" *ngIf="stepper.selectedIndex !== 1">
                    <mat-icon>check</mat-icon>
                  </div>
                  <a (click)="move(1)">{{'CLIENT-PORTAL.validator-form.steps.creditors_step' | translate}}</a>
                </li>
                <!--Assets-->
                <li [ngClass]="{'active': stepper.selectedIndex == 2}" *ngIf="isAssetsTouched === false"
                    (click)="stepHeaderChanged(stepper.selectedIndex)">
                  <div class="mat-header-control">
                    <mat-icon [ngStyle]="{'display': stepper.selectedIndex == 2 ? 'block' : 'none'}">create</mat-icon>
                    <span [ngStyle]="{'display': stepper.selectedIndex == 2 ? 'none' : 'block'}">3</span>
                  </div>
                  <a (click)="move(2)">
                    {{'CLIENT-PORTAL.validator-form.steps.assets_step' | translate}}</a>
                </li>
                <li *ngIf="isAssetsTouched === true"
                    (click)="stepHeaderChanged(stepper.selectedIndex)">
                  <div class="mat-header-control-edit" *ngIf="stepper.selectedIndex === 2">
                    <mat-icon>create</mat-icon>
                  </div>
                  <div class="mat-header-control-done" *ngIf="stepper.selectedIndex !== 2">
                    <mat-icon>check</mat-icon>
                  </div>
                  <a (click)="move(2)">{{'CLIENT-PORTAL.validator-form.steps.assets_step' | translate}}</a>
                </li>
                <!--Income-->
                <li class="mytest" [ngClass]="{'active': stepper.selectedIndex == 3}" *ngIf="isIncomeTouched === false"
                    (click)="stepHeaderChanged(stepper.selectedIndex)">
                  <div class="mat-header-control">
                    <mat-icon [ngStyle]="{'display': stepper.selectedIndex == 3 ? 'block' : 'none'}">create</mat-icon>
                    <span [ngStyle]="{'display': stepper.selectedIndex == 3 ? 'none' : 'block'}">4</span>
                  </div>
                  <a (click)="move(3)">
                    {{'CLIENT-PORTAL.validator-form.steps.income_step' | translate}}</a>
                </li>
                <li *ngIf="isIncomeTouched === true"
                    (click)="stepHeaderChanged(stepper.selectedIndex)">
                  <div class="mat-header-control-edit" *ngIf="stepper.selectedIndex === 3">
                    <mat-icon>create</mat-icon>
                  </div>
                  <div class="mat-header-control-done" *ngIf="stepper.selectedIndex !== 3">
                    <mat-icon>check</mat-icon>
                  </div>
                  <a (click)="move(3)">{{'CLIENT-PORTAL.validator-form.steps.income_step' | translate}}</a>
                </li>
                <!--Expanses-->
                <li class="mytest" [ngClass]="{'active': stepper.selectedIndex == 4}" *ngIf="isExpenseTouched === false"
                    (click)="stepHeaderChanged(stepper.selectedIndex)">
                  <div class="mat-header-control">
                    <mat-icon [ngStyle]="{'display': stepper.selectedIndex == 4 ? 'block' : 'none'}">create</mat-icon>
                    <span [ngStyle]="{'display': stepper.selectedIndex == 4 ? 'none' : 'block'}">5</span>
                  </div>
                  <a (click)="move(4)">
                    {{'CLIENT-PORTAL.validator-form.steps.expanses_step' | translate}}</a>
                </li>
                <li *ngIf="isExpenseTouched === true"
                    (click)="stepHeaderChanged(stepper.selectedIndex)">
                  <div class="mat-header-control-edit" *ngIf="stepper.selectedIndex === 4">
                    <mat-icon>create</mat-icon>
                  </div>
                  <div class="mat-header-control-done" *ngIf="stepper.selectedIndex !== 4">
                    <mat-icon>check</mat-icon>
                  </div>
                  <a (click)="move(4)">{{'CLIENT-PORTAL.validator-form.steps.expanses_step' | translate}}</a>
                </li>
              </ul>
            </div>
            <div class="onboarding-controls-buttons">
              <button class="save-btn-one mr-2" (click)="goBack()"
                      *ngIf="stepper.selectedIndex > 0"> {{'CLIENT-PORTAL.validator-form.back' | translate}}
              </button>
              <button class="save-btn-two" (click)="findCurrentStepAndValidate(stepper.selectedIndex)">
                {{ ((stepper.selectedIndex === 4 && readonly) || (stepper.selectedIndex === 5 && !readonly) ?
                "SHARED.home" :
                "CLIENT-PORTAL.validator-form.next") | translate }}</button>
            </div>
          </div>
          <div class="onboarding-info">
            <h3><b>{{'CLIENT-PORTAL.validator-form.verify_heading' | translate}}</b></h3>
            <span>{{'CLIENT-PORTAL.validator-form.verify_details_one' | translate}}</span>
            <br>
            <br>
            <span >{{'CLIENT-PORTAL.validator-form.verify_details_two' | translate}}
              <a href="https://wa.me/34960136099"
                 style="color: #2764ae; cursor: pointer">{{'CLIENT-PORTAL.validator-form.live_chat' | translate}},</a>
              {{'CLIENT-PORTAL.validator-form.verify_details_three' | translate}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
