import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from '../../client.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-outcome-step',
  templateUrl: './outcome-step.component.html',
  styleUrls: ['./outcome-step.component.scss']
})
export class OutcomeStepComponent implements OnInit {
  @Input() caseUuid: string;
  @Input() userUuid: string;
  public isLoading = 0;
  public outcome;


  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.isLoading++;
    this.clientService.indexCaseRelation(this.caseUuid, this.userUuid, 'product')
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.outcome = result.data;
      });
  }

}
