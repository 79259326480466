import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { NotificationFromEvent } from '../../../../../../../_base-shared/models/Notification/NotificationFromEvent';
import { Status } from '../../../../../../../_base-shared/models/Status/Status';
import { Trigger } from '../../../../../../../_base-shared/models/Trigger';
import { UserService } from '../../../user/user.service';
import { StatusService } from '../../../status/status.service';
import { NotificationEventService } from '../notification-event.service';
import { NotificationTriggerService } from '../notification-trigger.service';

@Component({
  selector:    'app-notification-configurator',
  templateUrl: './notification-configurator.component.html',
  styleUrls:   ['./notification-configurator.component.scss'],
})
export class NotificationConfiguratorComponent implements OnInit {
  public form: UntypedFormGroup;
  public editorType: string;
  public trigger: Trigger;

  public notifications: NotificationFromEvent[];
  public emailNotifications: NotificationFromEvent[];
  public smsNotifications: NotificationFromEvent[];
  public statuses: Status[];
  public statusSelectOptions: Array<AppSelectOption>       = [];
  public notificationToSelectOptions: Array<AppSelectOption>       = [];
  public roleSelectOptions: Array<AppSelectOption>       = [];
  public emailSelectOptions: Array<AppSelectOption>       = [];

  public roles: Array<any>;
  public isLoading        = 0;
  public formActive       = false;

  constructor(
      private translateService: TranslateService,
      private formBuilder: UntypedFormBuilder,
      private notificationService: NotificationEventService,
      private notificationTriggerService: NotificationTriggerService,
      private statusService: StatusService,
      private userService: UserService,
      private tostr: ToastrService,
      private router: Router,
      private route: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.editorType;

    this.fetchStatuses();
    this.fetchRoles();
    this.fetchNotifications();
    this.statusSelectOptions = this.buildStatusSelectOptions();
    this.roleSelectOptions = this.buildRoleSelectOptions();
    this.notificationToSelectOptions = this.buildNotificaationSelectOption();
    this.emailSelectOptions = this.buildEmailSelectOptions();

    if (this.editorType === 'create') {
      this.trigger = new Trigger();
      this.buildForm();
    } else {
      this.route.paramMap.subscribe(params => {
        this.fetchNotificationTrigger(+params.get('id'));
      });
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      on:           [this.trigger.on || 'enter', [Validators.required]],
      type:         [this.trigger.type || 'email', [Validators.required]],
      status:       [this.trigger.status, [Validators.required]],
      notification: [this.trigger.notification, [Validators.required]],
      days:         [this.trigger.days || null, [Validators.required]],
      receivers:    this.formBuilder.array([]),
    });
    this.addReceiversToForm();
    this.formActive = true;
  }

  addReceiversToForm() {
    const receiversArray = this.form.get('receivers') as UntypedFormArray;
    if (this.trigger.receivers?.length > 0) {
      this.trigger.receivers.map(receiver => {
        receiversArray.push(
            this.formBuilder.group({
              notification_to: [receiver.notification_to || 'staff_in', [Validators.required]],
              role:            [receiver.role, [Validators.required]],
            }),
        );
      });
    } else {
      receiversArray.push(
          this.formBuilder.group({
            notification_to: ['staff_in', [Validators.required]],
            role:            ['', [Validators.required]],
          }),
      );
    }
  }

  addNewReceiver() {
    const receiversArray = this.form.get('receivers') as UntypedFormArray;
    receiversArray.push(
        this.formBuilder.group({
          notification_to: ['staff_in', [Validators.required]],
          role:            ['', [Validators.required]],
        }),
    );
  }

  removeReceiver(index) {
    const receiversArray = this.form.get('receivers') as UntypedFormArray;
    receiversArray.removeAt(index);
  }

  fetchStatuses() {
    this.isLoading++;
    this.statusService.index()
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            res => this.statuses = res.data.data.map(status => ({
              id:       status.id,
              name:     status.name,
              itemName: status.name,
            })),
            err => console.error(err),
        );
  }

  fetchRoles() {
    this.isLoading++;
    this.userService.indexRoles()
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            res => this.roles = res.data.map(role => ({
              id:       role.id,
              name:     role.name,
              itemName: role.name,
            })),
            err => console.error(err),
        );
  }

  public getFormArray() {
    return this.form.get('receivers') as UntypedFormArray;
  }

  fetchNotifications() {
    this.isLoading++;
    this.notificationService.index()
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            res => {
              this.notifications = res.data.data.map(notification => ({
                id:       notification.id,
                name:     notification.title,
                itemName: notification.title,
              }));
            },
            err => console.error(err),
        );
  }

  fetchNotificationTrigger(triggerId) {
    this.isLoading++;
    this.notificationTriggerService.get(triggerId)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            res => {
              const resData             = res.data;
              this.trigger              = res.data;
              this.trigger.notification = this.notifications.filter(e => e.id === resData.notification_message_id);
              this.trigger.status       = this.statuses.filter(e => e.id === resData.status_id);
              this.trigger.role         = this.roles.filter(e => e.id === resData.role_id);

              this.buildForm();
            },
            err => console.error(err),
        );
  }

  onSubmit() {
    const formData = this.form.value;

    const data = {
      on:                      formData.on,
      notification_message_id: formData.notification[0].id,
      role_id:                 formData.role[0].id,
      status_id:               formData.status,
    };

    if (this.editorType === 'create') {
      this.notificationTriggerService.store(data)
          .subscribe(
              res => {
                this.tostr.success(this.translateService.instant('CONFIG.notifications.triggers-added'));
                this.router.navigateByUrl('/notification/trigger');
              },
              err => {
                this.tostr.error(this.translateService.instant('SHARED.went-wrong'));
              },
          );
    } else {
      this.notificationTriggerService.update(this.trigger.id, data)
          .subscribe(
              res => {
                this.tostr.success(this.translateService.instant('CONFIG.notifications.triggers-added'));
                this.router.navigateByUrl('/notification/trigger');
              },
              err => {
                this.tostr.error(this.translateService.instant('SHARED.went-wrong'));
              },
          );
    }
  }

  private buildStatusSelectOptions() {
    return [
      {label: this.translateService.instant('CONFIG.notifications.enters'), value: 'enter'},
      {label: this.translateService.instant('CONFIG.notifications.leaves'), value: 'leave'},
      {label: this.translateService.instant('CONFIG.notifications.stays'), value: 'stays'},
    ];
  }

  private buildRoleSelectOptions() {
    return [
      {label: 'Assigned Agent', value: 'staff_in'},
      {label: 'Verified by', value: 'staff_in'},
      {label: 'Watcher', value: 'staff_in'},
    ];
  }

  private buildNotificaationSelectOption() {
    return [
      {label: 'Staff in stage', value: 'staff_in'},
      {label: 'Borrower/Guarantor', value: 'applicant'},
      {label: 'Staff which', value: 'staff_which'},
    ];
  }

  private buildEmailSelectOptions() {
    return [
      {label: 'SMS', value: 'sms'},
      {label: 'Email', value: 'email'},
    ];
  }
}
