import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../../../../../../_base-shared/guards/permission.guard';
import { DripCampaignEditorComponent } from './drip-campaign-editor/drip-campaign-editor.component';
import { DripCampaignListComponent } from './drip-campaign-list/drip-campaign-list.component';

const routes: Routes = [
  {
    path:     'drip-campaigns',
    children: [
      {path: '', canActivate: [PermissionGuard], component: DripCampaignListComponent},
      {path: 'create', canActivate: [PermissionGuard], component: DripCampaignEditorComponent, data: {type: 'create'}},
      {path: ':id/edit', canActivate: [PermissionGuard], component: DripCampaignEditorComponent, data: {type: 'edit'}},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class DripCampaignRoutingModule {
}
