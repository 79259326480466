import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ScriptLoaderService } from '../../../../../../../../_base-shared/services/script-loader.service';
import {
  BasePaymentHandlerComponent
} from '../../../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component';
import { ClientService } from '../../../../client.service';
import { environment } from '../../../../../../environments/environment';
import { PaymentCard } from '../../../../../../../../_base-shared/models/Payment/PaymentCard';


@Component({
  selector:    'app-public-payment-handler',
  templateUrl: '../../../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component.html',
  styleUrls:   [
    '../../../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component.scss',
    './public-payment-handler.component.scss'
  ]
})
export class PublicPaymentHandlerComponent extends BasePaymentHandlerComponent implements OnInit {
  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected fb: UntypedFormBuilder,
              protected dialog: MatDialog,
              protected breakpointObserver: BreakpointObserver,
              protected translate: TranslateService,
              protected toastr: ToastrService,
              protected scriptLoader: ScriptLoaderService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              protected clientAppService: ClientService
  ) {
    super(route, router, fb, dialog, breakpointObserver, translate, toastr, scriptLoader, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.currentLanguage = 'es';
    this.translate.use(this.currentLanguage);
    this.authUser               = null;
    this.targetClient           = this.clientRole === 'partner' && this.case.partner ? this.case.partner : this.case.client;
    this.editableAmount         = false;
    this.redirectNewTab         = false;
    this.autoRedirect           = true;
    this.redirectSuccess        = '/client/link-payments/outcome/success';
    this.redirectError          = '/client/link-payments/outcome/error';
    this.redirectCancelled      = '/client/link-payments/outcome/error';
    this.dueDate                = this.installment ? new Date(this.installment.term_date) : null;
    const redirectSuccessFull   = environment.APP_URL + this.redirectSuccess;
    const redirectErrorFull     = environment.APP_URL + this.redirectError;
    const redirectCancelledFull = environment.APP_URL + this.redirectCancelled;
    // tslint:disable-next-line:max-line-length
    this.buildForm(this.case.client, this.amountToCharge, this.amountToCharge, false, redirectSuccessFull, redirectErrorFull, redirectCancelledFull);
  }

  protected submitFormToApi(userUuid: string, caseUuid: string, formValue): void {
    super.makePayment(this.clientAppService.makePayment(userUuid, caseUuid, formValue));
  }

  public makeDefault(paymentCard: PaymentCard): void {
    super.baseMakeDefault(paymentCard, this.clientAppService.setDefaultPaymentCard(this.case.uuid, paymentCard.uuid));
  }

  public deleteCard(paymentCard: PaymentCard): void {
    super.baseDeleteCard(paymentCard, this.clientAppService.deletePaymentCardPublic(this.case.uuid, paymentCard.uuid));
  }
}
