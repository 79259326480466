<div *ngIf="!showPage" class="text-center" style="padding: 50px">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div  *ngIf="showPage">
  <div class="row">
    <div class="col-md-8">
      <h2>{{"DASHBOARD.sales-leaderboard" | translate}}</h2>
      <div class="d-inline-block pb-2">
        <mat-radio-group aria-label="Select an option" (change)="onRadioChange($event)">
          <mat-radio-button checked value="lso">LSO</mat-radio-button>
          <mat-radio-button value="dm">DM</mat-radio-button>
          <mat-radio-button value="dgs">DGS</mat-radio-button>
          <!--<mat-radio-button value="combined">{{ "CASES.single.todo" | translate }}</mat-radio-button>-->
        </mat-radio-group>
      </div>
      <div class="mat-elevation-z8 mb-5" [hidden]="showTable !== 'combined'">
        <table style="width: 100%" mat-table matSort [dataSource]="dataSource" matSortActive="this_month" matSortDirection="desc">
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
            <td mat-cell *matCellDef="let element;" [attr.rowspan]="3" [style.display]="element.type !== 'DM' && element.type !== 'BDM' ? '' : 'none'">
              {{ element.name }}
            </td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.type }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="today">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.today" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.type === 'LSO'">{{element.today_lso || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'DM'">{{element.today_dm || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'BDM'">{{element.today_bdm || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'DGS'">{{element.today_dgs || 0}}</ng-container>
            </td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotal('today')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_week">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-week" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.type === 'LSO'">{{element.this_week_lso || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'DM'">{{element.this_week_dm || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'BDM'">{{element.this_week_bdm || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'DGS'">{{element.this_week_dgs || 0}}</ng-container>
            </td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotal('this_week')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_month">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-month" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.type === 'LSO'">{{element.this_month_lso || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'DM'">{{element.this_month_dm || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'BDM'">{{element.this_month_bdm || 0}}</ng-container>
              <ng-container *ngIf="element.type === 'DGS'">{{element.this_month_dgs || 0}}</ng-container>
            </td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotal('this_month')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="total_fees">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.type === 'LSO'">{{element.total_fees_lso || 0 | number: '1.2-2'}}€</ng-container>
              <ng-container *ngIf="element.type === 'DM'">{{element.total_fees_dm || 0 | number: '1.2-2'}}€</ng-container>
              <ng-container *ngIf="element.type === 'BDM'">{{element.total_fees_bdm || 0 | number: '1.2-2'}}€</ng-container>
              <ng-container *ngIf="element.type === 'DGS'">{{element.total_fees_dgs || 0 | number: '1.2-2'}}€</ng-container>
            </td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotal('total_fees') | number: '1.2-2'}}€</b></td>
          </ng-container>

          <ng-container matColumnDef="quota">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.quota" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.type === 'LSO'">
                <small class="font-weight-lighter">{{element.this_month_lso}} / {{element.quotaLSO}}</small>
              </ng-container>
              <ng-container *ngIf="element.type === 'DM'">
                <small class="font-weight-lighter">{{element.this_month_dm}} / {{element.quotaDM}}</small>
              </ng-container>
              <ng-container *ngIf="element.type === 'BDM'">
                <small class="font-weight-lighter">{{element.this_month_bdm}} / {{element.quotaBDM}}</small>
              </ng-container>
              <ng-container *ngIf="element.type === 'DGS'">
                <small class="font-weight-lighter">{{element.this_month_dgs}} / {{element.quotaDGS}}</small>
              </ng-container>
              <div class="progress" style="max-width: 120px">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercent(element)  + '%'}"
                     [attr.aria-valuenow]="getPercent(element)"
                     [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercent(element) }}%
                </div>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z8" [hidden]="showTable !== 'lso'">
        <table style="width: 100%" mat-table matSort #lsoSort="matSort" [dataSource]="dataSourceDmLso" matSortActive="this_month_lso" matSortDirection="desc">
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element.name }}
            </td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ showTable.toUpperCase() }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="today_lso">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.today" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.today_lso || 0 }}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('today')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_week_lso">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-week" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_week_lso || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('this_week')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_month_lso">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-month" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_month_lso || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('this_month')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="total_fees_lso">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.total_fees_lso || 0 | number: '1.2-2' }}€</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('total_fees') | number: '1.2-2'}}€</b></td>
          </ng-container>

          <ng-container matColumnDef="quota">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.quota" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <small class="font-weight-lighter">
                {{ element.this_month_lso }} / {{ element.quotaLSO }}
              </small>
              <div class="progress" style="max-width: 120px">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercentDmLso(element)  + '%'}"
                     [attr.aria-valuenow]="getPercentDmLso(element)"
                     [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercentDmLso(element) }}%
                </div>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsLso"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsLso;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsLso"></tr>
        </table>
      </div>

      <div class="mat-elevation-z8" [hidden]="showTable !== 'dm'">
        <table style="width: 100%" mat-table matSort #dmSort="matSort" [dataSource]="dataSourceDmLso" matSortActive="this_month_dm" matSortDirection="desc">
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element.name }}
            </td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ showTable.toUpperCase() }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="today_dm">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.today" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.today_dm || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('today')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_week_dm">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-week" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_week_dm || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('this_week')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_month_dm">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-month" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_month_dm || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('this_month')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="total_fees_dm">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.total_fees_dm || 0 | number: '1.2-2' }}€</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('total_fees') | number: '1.2-2'}}€</b></td>
          </ng-container>

          <ng-container matColumnDef="quota">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.quota" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <small class="font-weight-lighter">
                {{element.this_month_dm}} / {{element.quotaDM}}
              </small>
              <div class="progress" style="max-width: 120px">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercentDmLso(element)  + '%'}"
                     [attr.aria-valuenow]="getPercentDmLso(element)"
                     [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercentDmLso(element) }}%
                </div>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsDm"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDm;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsDm"></tr>
        </table>
      </div>

      <div class="mat-elevation-z8" [hidden]="showTable !== 'bdm'">
        <table style="width: 100%" mat-table matSort #bdmSort="matSort" [dataSource]="dataSourceDmLso" matSortActive="this_month_bdm" matSortDirection="desc">
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element.name }}
            </td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ showTable.toUpperCase() }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="today_bdm">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.today" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.today_bdm || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('today')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_week_bdm">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-week" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_week_bdm || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('this_week')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_month_bdm">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-month" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_month_bdm || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('this_month')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="total_fees_bdm">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.total_fees_bdm || 0 | number: '1.2-2' }}€</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('total_fees') | number: '1.2-2'}}€</b></td>
          </ng-container>

          <ng-container matColumnDef="quota">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.quota" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <small class="font-weight-lighter">
                {{element.this_month_bdm}} / {{element.quotaBDM}}
              </small>
              <div class="progress" style="max-width: 120px">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercentDmLso(element)  + '%'}"
                     [attr.aria-valuenow]="getPercentDmLso(element)"
                     [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercentDmLso(element) }}%
                </div>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsBDm"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsBDm;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsBDm"></tr>
        </table>
      </div>
      <!--DGS-->
      <div class="mat-elevation-z8" [hidden]="showTable !== 'dgs'">
        <table style="width: 100%" mat-table matSort #dgsSort="matSort" [dataSource]="dataSourceDGS" matSortActive="this_month_dgs" matSortDirection="desc">
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element.name }}
            </td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ showTable.toUpperCase() }}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="today_dgs">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.today" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.today_dgs || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('today')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_week_dgs">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-week" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_week_dgs || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('this_week')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="this_month_dgs">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-month" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_month_dgs || 0}}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('this_month')}}</b></td>
          </ng-container>

          <ng-container matColumnDef="total_fees_dgs">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.total_fees_dgs || 0 | number: '1.2-2' }}€</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('total_fees') | number: '1.2-2'}}€</b></td>
          </ng-container>

          <ng-container matColumnDef="quota">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.quota" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <small class="font-weight-lighter">
                {{element.this_month_dgs}} / {{element.quotaDGS}}
              </small>
              <div class="progress" style="max-width: 120px">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercentDmLso(element)  + '%'}"
                     [attr.aria-valuenow]="getPercentDmLso(element)"
                     [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercentDmLso(element) }}%
                </div>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsDGs"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDGs;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsDGs"></tr>
        </table>
      </div>
    </div>
    <div class="col-md-4">
      <h2>{{"DASHBOARD.500-sales" | translate}}</h2>
      <div class="mat-elevation-z8 mb-5" style="margin-top: 49px">
        <table style="width: 100%" mat-table #salesSOrt="matSort" matSort [dataSource]="dataSourceSales">
          <ng-container matColumnDef="agent_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.agent_name }}</td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>

          <ng-container matColumnDef="number_of_sales">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.number-of-cases" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.number_of_sales }}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotal('500')}}</b></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsSales"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsSales;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsSales"></tr>
        </table>
      </div>
      <!--CHARTS-->
      <div style="display: block; position: relative">
        <h2 class="text-center m-0">{{"DASHBOARD.lso-progress" | translate}}</h2>
        <canvas baseChart
                [data]="doughnutChartData"
                [options]="doughnutChartOptions"
                [labels]="doughnutChartLabels"
                [colors]="doughnutChartColors"
                [plugins]="doughnutChartPlugins"
                [chartType]="doughnutChartType">
        </canvas>
        <div class="report"><b>{{this.totalNumberOfSalesLSO}}/{{this.monthlyQuota}}</b></div>
      </div>
      <div style="display: block; position: relative">
        <h2 class="text-center m-0 pt-3">{{"DASHBOARD.dm-progress" | translate}}</h2>
        <canvas baseChart
                [data]="doughnutChartDataDM"
                [options]="doughnutChartOptionsDM"
                [labels]="doughnutChartLabelsDM"
                [colors]="doughnutChartColorsDM"
                [plugins]="doughnutChartPlugins"
                [chartType]="doughnutChartType">
        </canvas>
        <div class="report"><b>{{this.totalNumberOfSalesDM}}/{{this.monthlyQuotaDM}}</b></div>
      </div>
      <div style="display: block; position: relative">
        <h2 class="text-center m-0 pt-3">DGS progress chart</h2>
        <canvas baseChart
                [data]="doughnutChartDataDGS"
                [options]="doughnutChartOptionsDGS"
                [labels]="doughnutChartLabelsDGS"
                [colors]="doughnutChartColorsDGS"
                [plugins]="doughnutChartPlugins"
                [chartType]="doughnutChartType">
        </canvas>
        <div class="report"><b>{{this.totalNumberOfSalesDGS}}/{{this.monthlyQuotaDGS}}</b></div>
      </div>
    </div>
  </div>
</div>
