import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { UserExternalService } from '../../../../../../../_base-shared/models/User/UserExternalService';
import { UserServiceIntegration } from '../../../../../../../_base-shared/models/User/UserServiceIntegration';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { UserService } from '../../../../admin/user/user.service';
import { ClientService } from '../../../client.service';

@Component({
  selector:    'app-client-profile-desktop',
  templateUrl: './client-profile-desktop.component.html',
  styleUrls:   ['./client-profile-desktop.component.scss'],
})
export class ClientProfileDesktopComponent implements OnInit {
  public authUser: User;
  public isLoading                                              = 0;
  public isLoadingIntegrations                                  = 0;
  public externalServices: Array<UserExternalService>           = [];
  public userServiceIntegrations: Array<UserServiceIntegration> = [];
  public submitting                                             = false;
  public form: UntypedFormGroup;
  public passwordForm: UntypedFormGroup;
  public formActive                                             = false;
  public formSubmitted: boolean;
  public serverResponse: LaravelResponse;
  public caseUuid: string;
  public caseRef: string;

  constructor(private globalEventsService: MainGlobalEventService,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private userService: UserService,
              private clientService: ClientService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.getUser();
  }

  public getUser() {
    this.caseUuid = this.route.snapshot.paramMap.get('caseUuid');
    this.globalEventsService.authUser$.subscribe(next => {
      this.authUser = next;
      if (this.authUser.role.is_staff) {
        this.fetchExternalServices();
      }
      this.fetchCase(this.authUser, this.caseUuid);
      this.buildForm(this.authUser);
      this.buildPasswordForm(this.authUser);
    });
  }

  private buildForm(user: User) {
    this.form = this.fb.group({
        first_name:         [user.first_name, [Validators.required]],
        last_name:          [user.last_name, [Validators.required]],
        phone_country_code: [{disabled: true, value: user.phone_country_code}, [Validators.required]],
        phone:              [
          user.phone,
          [
            Validators.pattern('(6|7|9)([0-9])\\w+'),
            Validators.minLength(9),
            Validators.maxLength(9),
          ],
        ],
        id_card:            [user.id_card, [Validators.required]],
      },
    );

    this.formActive = true;
  }

  private buildPasswordForm(user: User) {
    this.passwordForm = this.fb.group({
        current_password:      [''],
        password:              ['', [Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')]],
        password_confirmation: ['', [Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')]],
      },
      {validator: this.confirmedValidator('password', 'password_confirmation')});

    this.formActive = true;
  }

  public submit(form: UntypedFormGroup) {
    this.serverResponse = null;
    this.formSubmitted  = true;
    if (form.invalid) {
      return;
    }
    this.submitting   = true;
    const requestData = {phone_country_code: this.form.get('phone_country_code').value, ...form.value};

    const observable = this.authUser.role.is_staff ?
      this.userService.update(this.authUser.id, requestData) :
      this.clientService.updateUser(this.authUser.uuid, requestData);
    observable.pipe(finalize(() => this.submitting = false)).subscribe(
      res => {
        this.globalEventsService.setAuthUser(res.data);
        this.toastr.success('Successfully updated your details!');
      },
      error => {
        this.serverResponse = error.error;
        this.toastr.error(error.error.message);
      },
    );
  }

  public changePassword(form: UntypedFormGroup) {
    this.serverResponse = null;
    this.formSubmitted  = true;
    if (form.invalid) {
      return;
    }
    this.submitting   = true;
    const requestData = {phone_country_code: this.form.get('phone_country_code').value, ...form.value};
    this.userService.update(this.authUser.id, requestData).pipe(finalize(() => this.submitting = false)).subscribe(
      res => {
        this.globalEventsService.setAuthUser(res.data);
        this.toastr.success('Password successfully changed!');
      },
      error => {
        this.serverResponse = error.error;
        this.toastr.error(error.error.message);
      },
    );
  }

  private fetchExternalServices() {
    this.isLoadingIntegrations++;
    this.userService.externalServices().pipe(finalize(() => this.isLoadingIntegrations--))
      .subscribe(result => this.fetchIntegrations(this.authUser.id, result.data));
  }

  private fetchIntegrations(userId: number, externalServices: Array<UserExternalService>) {
    this.isLoadingIntegrations++;
    this.userService.indexIntegrations(userId).pipe(finalize(() => this.isLoadingIntegrations--)).subscribe(result => {
      this.userServiceIntegrations = result.data;
      this.externalServices        = externalServices.map(externalService => {
        const integratedService = this.userServiceIntegrations.find(
          iService => iService.service_id === externalService.id,
        );
        return {...externalService, integrated_id: integratedService ? integratedService.id : 0};
      });
    });
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control         = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({confirmedValidator: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  private fetchCase(client: User, caseUuid: string) {
    this.isLoading++;
    this.clientService.showUserCase(client.uuid, caseUuid).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.caseRef = result.data.ref_number;
      this.globalEventsService.setClientSelectedCase(result.data);
    });
  }
}
