import { Case } from './Case/Case';
import { PaymentPlan } from './Product/PaymentPlan';

export type ProductSlug =
  'dm'
  | 'bdm'
  | 'lso'
  | 'lso2'
  | 'lso-te'
  | 'lso-pp'
  | 'lso-lq'
  | 'dgs-dm'
  | 'dgs-mixed'
  | 'dgs-claim';

export class Product {
  id?: number;

  slug?: ProductSlug;
  group_slug?: 'lso' | 'dm' | 'dgs';
  active?: boolean | 0 | 1;
  name_en?: string;
  name_es?: string;
  description_en?: string;
  description_es?: string;

  created_at: string | Date;
  updated_at: string | Date;

  // Accessors
  name?: string;
  description?: string;

  // Relations
  payment_plans?: Array<PaymentPlan>;
  cases?: Array<Case>;
}
