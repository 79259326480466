import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { User } from '../../../../../../_base-shared/models/User/User';
import { UserExternalService } from '../../../../../../_base-shared/models/User/UserExternalService';
import { UserServiceIntegration } from '../../../../../../_base-shared/models/User/UserServiceIntegration';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { UserService } from '../user.service';

@Component({
  selector:    'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls:   ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  public authUser: User;
  public isLoading                                              = 0;
  public isLoadingIntegrations                                  = 0;
  public externalServices: Array<UserExternalService>           = [];
  public userServiceIntegrations: Array<UserServiceIntegration> = [];
  public submitting                                             = false;
  public form: UntypedFormGroup;
  public formActive                                             = false;
  public formSubmitted: boolean;
  public serverResponse: LaravelResponse;
  public testResponse: string;

  constructor(private globalEventsService: MainGlobalEventService,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.getUser();
  }

  public getUser() {
    this.globalEventsService.authUser$.subscribe(next => {
      this.authUser = next;
      if (this.authUser.role.is_staff) {
        this.fetchExternalServices();
      }
      this.buildForm(this.authUser);
    });
  }

  private buildForm(user: User) {
    this.form = this.fb.group({
      first_name:            [user.first_name, [Validators.required]],
      last_name:             [user.last_name, [Validators.required]],
      phone_country_code:    [{disabled: true, value: user.phone_country_code}, [Validators.required]],
      phone:                 [
        user.phone,
        [
          Validators.pattern('(6|7|9)([0-9])\\w+'),
          Validators.minLength(9),
          Validators.maxLength(9),
        ],
      ],
      email:                 [user.email, [Validators.required, Validators.email]],
      current_password:      [''],
      password:              [''],
      password_confirmation: [''],
    });

    this.formActive = true;
  }

  public submit(form: UntypedFormGroup) {
    this.serverResponse = null;
    this.formSubmitted  = true;
    if (form.invalid) {
      return;
    }
    this.submitting   = true;
    const requestData = {phone_country_code: this.form.get('phone_country_code').value, ...form.value};
    this.userService.update(this.authUser.id, requestData).pipe(finalize(() => this.submitting = false)).subscribe(
        res => this.globalEventsService.setAuthUser(res.data),
        error => this.serverResponse = error.error,
    );
  }

  private fetchExternalServices() {
    this.isLoadingIntegrations++;
    this.userService.externalServices().pipe(finalize(() => this.isLoadingIntegrations--))
        .subscribe(result => this.fetchIntegrations(this.authUser.id, result.data));
  }

  private fetchIntegrations(userId: number, externalServices: Array<UserExternalService>) {
    this.isLoadingIntegrations++;
    this.userService.indexIntegrations(userId).pipe(finalize(() => this.isLoadingIntegrations--)).subscribe(result => {
      this.userServiceIntegrations = result.data;
      this.externalServices        = externalServices.map(externalService => {
        const integratedService = this.userServiceIntegrations.find(
            iService => iService.service_id === externalService.id,
        );
        return {...externalService, integrated_id: integratedService ? integratedService.id : 0};
      });
    });
  }

  public authorize(service: UserExternalService) {
    this.isLoadingIntegrations++;
    return this.userService.requestAuthorization(this.authUser.id, service.id)
        .pipe(finalize(() => this.isLoadingIntegrations--))
        .subscribe(
            result => {
              console.log('result is');
              console.log(result.data.redirect_url);
              if (service.type === 'gmail') {
                return window.location.href = result.data.redirect_url;
              }
            },
            error => {
              this.serverResponse = error.error;
              this.toastr.error(error.code + ' Could not request service authorization');
            },
        );
  }

  public unauthorize(service: UserExternalService, integratedId: number) {
    this.isLoadingIntegrations++;
    this.userService.removeIntegration(this.authUser.id, integratedId)
        .pipe(finalize(() => this.isLoadingIntegrations--))
        .subscribe(
            result => {
              service.integrated_id = 0;
              this.toastr.success('Disconnected ' + service.name);
            },
            error => this.serverResponse = error.error,
        );
  }

  public testIntegration(service: UserExternalService, integratedId: number) {
    this.userService.testIntegration(this.authUser.id, integratedId)
        .subscribe(
            result => {
              this.toastr.success('Tested ' + service.name);
              this.testResponse = result.data;
            },
            error => this.serverResponse = error.error,
        );
  }
}
