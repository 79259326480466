<div class="d-inline-block pb-2">
  <mat-radio-group [formControl]="typeForm" aria-label="Select an option" (change)="onRadioChangeType($event)">
    <mat-radio-button value="lso">LSO</mat-radio-button>
    <mat-radio-button value="dm">DM</mat-radio-button>
    <mat-radio-button value="dgs">DGS</mat-radio-button>
    <!--<mat-radio-button value="combined">{{ "CASES.single.todo" | translate }}</mat-radio-button>-->
  </mat-radio-group>
</div>

<div class="row">
  <div class="col-lg-8">
    <div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'combined'">
      <table style="width: 100%" mat-table matSort [dataSource]="dataSource" matSortActive="impago_percent" matSortDirection="desc">

        <ng-container matColumnDef="name">
          <th style="width: 200px" mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
          <td mat-cell *matCellDef="let element" [attr.rowspan]="3" [style.display]="element.type !== 'DM' && element.type !== 'BDM' ? '' : 'none'">
            {{ element.name || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th style="width: 80px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="case_count">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.type === 'LSO'">{{element.case_count_lso}}</ng-container>
            <ng-container *ngIf="element.type === 'DM'">{{element.case_count_dm}}</ng-container>
            <ng-container *ngIf="element.type === 'BDM'">{{element.case_count_bdm}}</ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef><b>{{totals.caseCount}}</b></td>
        </ng-container>

        <ng-container matColumnDef="impago_percent">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Impago %</th>
          <td mat-cell *matCellDef="let element" [class.red-color]=" element.type === 'LSO' ? element.impago_percent_lso > 12.50 :
             element.type === 'DM' ? element.impago_percent_dm > 12.50 : element.impago_percent_bdm > 12.50">
            <ng-container *ngIf="element.type === 'LSO'">{{element.impago_percent_lso | number:'1.2-2'}}%</ng-container>
            <ng-container *ngIf="element.type === 'DM'">{{element.impago_percent_dm | number:'1.2-2'}}%</ng-container>
            <ng-container *ngIf="element.type === 'BDM'">{{element.impago_percent_bdm | number:'1.2-2'}}%</ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="caso_congelado">
          <th class="custom-center" mat-header-cell  mat-sort-header *matHeaderCellDef>Caso Congelado Converted</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.type === 'LSO'">{{element.caso_congelado_lso}}</ng-container>
            <ng-container *ngIf="element.type === 'DM'">{{element.caso_congelado_dm}}</ng-container>
            <ng-container *ngIf="element.type === 'BDM'">{{element.caso_congelado_bdm}}</ng-container>
          </td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef><b>{{totals.casoCongelado}}</b></td>
        </ng-container>

        <ng-container matColumnDef="documentacion_completa">
          <th class="custom-center" mat-header-cell mat-sort-header *matHeaderCellDef>Documentacion Completa</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.type === 'LSO'">{{element.documentacion_completa_lso}}</ng-container>
            <ng-container *ngIf="element.type === 'DM'">{{element.documentacion_completa_dm}}</ng-container>
            <ng-container *ngIf="element.type === 'BDM'">{{element.documentacion_completa_bdm}}</ng-container>
          </td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef><b>{{totals.documentacionCompleta}}</b></td>
        </ng-container>

        <ng-container matColumnDef="quota_caso_congelado">
          <th style="width: 130px" mat-header-cell mat-sort-header *matHeaderCellDef>Caso Congelado {{"DASHBOARD.quota" | translate}}</th>
          <td style="text-align: right" mat-cell [attr.rowspan]="3" *matCellDef="let element" [style.display]="element.type !== 'DM' && element.type !== 'BDM' ? '' : 'none'">
            <small class="font-weight-lighter pr-3">
              {{ element.caso_congelado }} / {{ element.target_caso_congelado || 0}}
            </small>
            <div class="progress" style="max-width: 120px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercentDmLso(element, 'congelado')  + '%'}"
                   [attr.aria-valuenow]="getPercentDmLso(element, 'congelado')"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercentDmLso(element, 'congelado') }}%
              </div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="quota_documentacion_completa">
          <th style="width: 130px" mat-header-cell mat-sort-header *matHeaderCellDef>Documentacion Completa {{"DASHBOARD.quota" | translate}}</th>
          <td style="text-align: right" mat-cell *matCellDef="let element" [attr.rowspan]="3" [style.display]="element.type !== 'DM' && element.type !== 'BDM' ? '' : 'none'">
            <small class="font-weight-lighter pr-3">
              {{ element.documentacion_completa }} / {{ element.target_documentacion_completa || 0 }}
            </small>
            <div class="progress" style="max-width: 120px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercentDmLso(element, 'doc')  + '%'}"
                   [attr.aria-valuenow]="getPercentDmLso(element, 'doc')"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercentDmLso(element, 'doc') }}%
              </div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr style="pointer-events: auto" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>

      <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h3>
      </div>
    </div>

    <div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'lso'">
      <table style="width: 100%" mat-table matSort #lsoSort="matSort" [dataSource]="dataSourceDmLso" matSortActive="impago_percent_lso" matSortDirection="desc">

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ typeForm.value.toUpperCase() }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="case_count_lso">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.case_count_lso }}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{totals.caseCountLso}}</b></td>
        </ng-container>

        <ng-container matColumnDef="impago_percent_lso">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Impago %</th>
          <td mat-cell *matCellDef="let element" [class.red-color]="element.impago_percent_lso > 12.50">{{ element.impago_percent_lso | number:'1.2-2'}}%</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="caso_congelado_lso">
          <th class="custom-center" mat-header-cell  mat-sort-header *matHeaderCellDef>Caso Congelado Converted</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">{{ element.caso_congelado_lso }}</td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef><b>{{totals.casoCongeladoLso}}</b></td>
        </ng-container>

        <ng-container matColumnDef="documentacion_completa_lso">
          <th class="custom-center" mat-header-cell  mat-sort-header *matHeaderCellDef>Documentacion Completa</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">{{ element.documentacion_completa_lso }}</td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef><b>{{totals.documentacionCompletaLso}}</b></td>
        </ng-container>

        <ng-container matColumnDef="quota_documentacion_completa">
          <th style="width: 130px" mat-header-cell mat-sort-header *matHeaderCellDef>Documentacion Completa {{"DASHBOARD.quota" | translate}}</th>
          <td style="text-align: right" mat-cell *matCellDef="let element">
            <small class="font-weight-lighter pr-3">
              {{ element.documentacion_completa }} / {{ element.target_documentacion_completa || 0 }}
            </small>
            <div class="progress" style="max-width: 120px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercentDmLso(element, 'doc')  + '%'}"
                   [attr.aria-valuenow]="getPercentDmLso(element, 'doc')"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercentDmLso(element, 'doc') }}%
              </div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr style="pointer-events: auto" mat-header-row *matHeaderRowDef="displayedColumnsLso"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsLso;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumnsLso"></tr>
      </table>

      <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="isLoading < 1 && dataSourceDmLso && dataSourceDmLso.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h3>
      </div>
    </div>

    <div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'dm'">
      <table style="width: 100%" mat-table matSort #dmSort="matSort" [dataSource]="dataSourceDmLso" matSortActive="impago_percent_dm" matSortDirection="desc">

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.name || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ typeForm.value.toUpperCase() }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="case_count_dm">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.case_count_dm }}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{totals.caseCountDm}}</b></td>
        </ng-container>

        <ng-container matColumnDef="impago_percent_dm">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Impago %</th>
          <td mat-cell *matCellDef="let element" [class.red-color]="element.impago_percent_dm > 12.50">{{ element.impago_percent_dm | number:'1.2-2'}}%</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="caso_congelado_dm">
          <th class="custom-center" mat-header-cell  mat-sort-header *matHeaderCellDef>Caso Congelado Converted</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">{{ element.caso_congelado_dm }}</td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef><b>{{totals.casoCongeladoDm}}</b></td>
        </ng-container>

        <ng-container matColumnDef="documentacion_completa_dm">
          <th class="custom-center" mat-header-cell  mat-sort-header *matHeaderCellDef>Documentacion Completa</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">{{ element.documentacion_completa_dm }}</td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef>{{ totals.documentacionCompletaDm }}</td>
        </ng-container>

        <tr style="pointer-events: auto" mat-header-row *matHeaderRowDef="displayedColumnsDm"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDm;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumnsDm"></tr>
      </table>

      <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="isLoading < 1 && dataSourceDmLso && dataSourceDmLso.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h3>
      </div>
    </div>

    <div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'bdm'">
      <table style="width: 100%" mat-table matSort #bdmSort="matSort" [dataSource]="dataSourceDmLso" matSortActive="impago_percent_bdm" matSortDirection="desc">

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.name || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ typeForm.value.toUpperCase() }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="case_count_bdm">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.case_count_bdm }}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{totals.caseCountBdm}}</b></td>
        </ng-container>

        <ng-container matColumnDef="impago_percent_bdm">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Impago %</th>
          <td mat-cell *matCellDef="let element" [class.red-color]="element.impago_percent_bdm > 12.50">
            {{ element.impago_percent_bdm | number:'1.2-2'}}%
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="caso_congelado_bdm">
          <th class="custom-center" mat-header-cell mat-sort-header *matHeaderCellDef>Caso Congelado Converted</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">{{ element.caso_congelado_bdm }}</td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef><b>{{totals.casoCongeladoBdm}}</b></td>
        </ng-container>

        <ng-container matColumnDef="documentacion_completa_bdm">
          <th class="custom-center" mat-header-cell  mat-sort-header *matHeaderCellDef>Documentacion Completa</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">{{ element.documentacion_completa_bdm }}</td>
          <td style="text-align: center; padding-right: 0" mat-footer-cell *matFooterCellDef><b>{{ totals.documentacionCompletaBdm }}</b></td>
        </ng-container>

        <tr style="pointer-events: auto" mat-header-row *matHeaderRowDef="displayedColumnsBdm"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsBdm;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumnsBdm"></tr>
      </table>

      <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="isLoading < 1 && dataSourceDmLso && dataSourceDmLso.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h3>
      </div>
    </div>
    <!--DGS-->
    <div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'dgs'">
      <table style="width: 100%" mat-table matSort #dgsSort="matSort" [dataSource]="dataSourceDgs" matSortActive="impago_percent_dgs" matSortDirection="desc">

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.name || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ typeForm.value.toUpperCase() }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="case_count_dgs">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.case_count_bdm }}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{totals.caseCountBdm}}</b></td>
        </ng-container>

        <ng-container matColumnDef="impago_percent_dgs">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Impago %</th>
          <td mat-cell *matCellDef="let element" [class.red-color]="element.impago_percent_bdm > 12.50">
            {{ element.impago_percent_bdm | number:'1.2-2'}}%
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="caso_congelado_dgs">
          <th class="custom-center" mat-header-cell mat-sort-header *matHeaderCellDef>Caso Congelado Converted</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">{{ element.caso_congelado_bdm }}</td>
          <td style="text-align: center" mat-footer-cell *matFooterCellDef><b>{{totals.casoCongeladoBdm}}</b></td>
        </ng-container>

        <ng-container matColumnDef="documentacion_completa_dgs">
          <th class="custom-center" mat-header-cell  mat-sort-header *matHeaderCellDef>Documentacion Completa</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">{{ element.documentacion_completa_bdm }}</td>
          <td style="text-align: center; padding-right: 0" mat-footer-cell *matFooterCellDef><b>{{ totals.documentacionCompletaBdm }}</b></td>
        </ng-container>

        <tr style="pointer-events: auto" mat-header-row *matHeaderRowDef="displayedColumnsDgs"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDgs;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumnsDgs"></tr>
      </table>

      <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="isLoading < 1 && dataSourceDgs && dataSourceDgs.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h3>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div style="display: block; position: relative" *ngIf="!isLoading">
      <h2 class="text-center m-0">{{"DASHBOARD.caso-congelado-progress" | translate}}</h2>
      <canvas baseChart
              [data]="doughnutChartData"
              [options]="doughnutChartOptions"
              [labels]="doughnutChartLabels"
              [colors]="doughnutChartColors"
              [plugins]="doughnutChartPlugins"
              [chartType]="doughnutChartType">
      </canvas>
      <div class="report top"><b>{{this.totalNumberOfCasoCongelado}}/{{this.monthlyQuotaCasoCongelado}}</b></div>
    </div>
    <div style="display: block; position: relative" *ngIf="!isLoading">
      <h2 class="text-center m-0 pt-3">{{"DASHBOARD.documentacion-completa-progress" | translate}}</h2>
      <canvas baseChart
              [data]="doughnutChartDataDM"
              [options]="doughnutChartOptionsDM"
              [labels]="doughnutChartLabelsDM"
              [colors]="doughnutChartColorsDM"
              [plugins]="doughnutChartPlugins"
              [chartType]="doughnutChartType">
      </canvas>
      <div class="report bottom"><b>{{this.totalNumberOfDocumentacionCompleta}}/{{this.monthlyQuotaDocCompleta}}</b></div>
    </div>
  </div>
</div>


