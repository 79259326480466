<ng-container>
  <div class="password-desktop-container">
    <div class="row d-flex flex-row justify-content-center align-items-center h-100 ">
      <div class="col-6 client-desktop-details w-100">
        <div class="w-100 h-100">
          <mat-tab-group class="w-100 h-100" disableRipple>
            <!--Your details tab-->
            <mat-tab [label]="'USERS.password' | translate">
              <div>
                <div class="container client-typography">
                  <form [formGroup]="passwordForm" (ngSubmit)="changePassword(passwordForm)" class="form">
                    <div class="row">
                      <div class="col-12">
                        <!-- Change Password Form-->
                        <div class="mb-2">
                          <span class="text-muted">{{'SHARED.profile' | translate}}</span>
                          <p>{{caseRef}}</p>
                          <div><h2>Tu contraseña</h2></div>
                          <div *ngIf="isLoading" class="row">
                            <div class="col-12 pt-5 text-center">
                              <mat-spinner class="m-0 m-auto"></mat-spinner>
                            </div>
                          </div>
                          <div [hidden]="isLoading">
                            <!-- Current Password -->
                            <app-input type="password" formControlName="current_password" [clientTypography]="true"
                                       [label]="'USER.settings.form-password.current_password' | translate"
                                       [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                                       [requiredStar]="true" [showClear]="false">
                            </app-input>
                            <div
                              *ngIf="passwordForm.get('current_password').errors && (passwordForm.get('current_password').touched || formSubmitted)">
                              <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                                 [fieldName]="'current_password'">
                              </app-server-errors>
                            </div>
                            <!-- New Password -->
                            <app-input type="password" formControlName="password" [clientTypography]="true"
                                       [label]="'USER.settings.form-password.password' | translate"
                                       [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                                       [requiredStar]="true" [showClear]="false">
                            </app-input>
                            <div
                              *ngIf="passwordForm.get('password').errors && (passwordForm.get('password').touched || formSubmitted)">
                              <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                                 [fieldName]="'password'">
                              </app-server-errors>
                            </div>
                            <!-- New Password Confirmation -->
                            <app-input type="password" formControlName="password_confirmation" [clientTypography]="true"
                                       [label]="'USER.settings.form-password.password_confirmation' | translate"
                                       [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                                       [requiredStar]="true" [showClear]="false">
                            </app-input>
                            <mat-error *ngIf="passwordForm.get('password_confirmation').hasError('confirmedValidator')">
                              {{'SHARED.passwords_must_match' | translate}}
                            </mat-error>
                            <div
                              *ngIf="passwordForm.get('password_confirmation').errors && (passwordForm.get('password_confirmation').touched || formSubmitted)">
                              <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                                 [fieldName]="'password_confirmation'">
                              </app-server-errors>
                            </div>
                          </div>
                        </div>
                        <!-- Submit -->
                        <div class="d-flex mb-4">
                          <button class="client-desktop-btn" type="submit"
                                  [disabled]="!passwordForm.valid">{{'SHARED.update_password' | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </mat-tab>
            <!--Your details tab-->
            <mat-tab [label]="'CLIENT.client-dashboard.your-details' | translate">
              <div class="container client-typography">
                <form [formGroup]="form" (ngSubmit)="submit(form)" class="form">
                  <div class="row">
                    <div class="col-12">
                      <!-- User Details Form -->
                      <div>
                        <span class="text-muted">{{'SHARED.profile' | translate}}</span>
                        <p>{{caseRef}}</p>
                        <div><h2>{{'CLIENT.client-dashboard.your-details' | translate}}</h2></div>
                        <div *ngIf="isLoading" class="row">
                          <div class="col-12 pt-5 text-center">
                            <mat-spinner class="m-0 m-auto"></mat-spinner>
                          </div>
                        </div>
                        <div [hidden]="isLoading">
                          <div class="d-flex flex-row w-100">
                            <div class="w-50 mr-3"><!-- First Name -->
                              <app-input type="text" formControlName="first_name" [clientTypography]="true"
                                         [label]="'USERS.model.first_name' | translate" [readonly]="true"
                                         [showLabel]="true" [extraLabel]="false" appearance="standard"
                                         [fullWidth]="true"
                                         [requiredStar]="true" [showClear]="false">
                              </app-input>
                            </div>
                            <div class="w-50">
                              <!-- Last Name -->
                              <app-input type="text" formControlName="last_name" [clientTypography]="true"
                                         [label]="'USER.settings.form-details.last_name' | translate" [readonly]="true"
                                         [showLabel]="true" [extraLabel]="false" appearance="standard"
                                         [fullWidth]="true"
                                         [requiredStar]="true" [showClear]="false">
                              </app-input>
                            </div>
                          </div>
                          <div class="d-flex flex-row w-100">
                            <div class="w-50 mr-3">
                              <!-- Id card -->
                              <app-input type="text" formControlName="id_card"
                                         [label]="'SHARED.dni_number' | translate" [clientTypography]="true"
                                         [readonly]="true"
                                         [showLabel]="true" [extraLabel]="false" appearance="standard"
                                         [fullWidth]="true"
                                         [requiredStar]="true" [showClear]="false">
                              </app-input>
                            </div>
                            <div class="w-50">
                              <!-- Phone -->
                              <app-input type="number" formControlName="phone"
                                         [label]="'USERS.editor.phone' | translate" [clientTypography]="true"
                                         [readonly]="true"
                                         [showLabel]="true" [extraLabel]="false" appearance="standard"
                                         [fullWidth]="true"
                                         [requiredStar]="true" [showClear]="false">
                              </app-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-muted">{{'CLIENT.client-dashboard.contact-team' | translate}}</p>
                    </div>
                    <!-- Submit -->
                   <!-- <div class="d-flex mb-4 ml-2">
                      <button class="client-desktop-btn" type="submit"
                              [disabled]="!form.valid">Update
                      </button>
                    </div>-->
                  </div>
                </form>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</ng-container>
