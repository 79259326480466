import { Affiliate } from '../Affiliate';
import { Case } from '../Case/Case';
import { UserCaseRelationValidation } from '../Case/UserCaseRelationValidation';
import { Department } from '../Department/Department';
import { DepartmentAssignment } from '../Department/DepartmentAssignment';
import { DistributionProvider } from '../Distribution/DistributionProvider';
import { Address } from '../Location/Address';
import { DatabaseNotification } from '../Notification/DatabaseNotification';
import { DripCampaign } from '../Notification/DripCampaign';
import { DripNotification } from '../Notification/DripNotification';
import { EmailHistory } from '../Notification/EmailHistory';
import { SmsHistory } from '../Notification/SmsHistory';
import { BankAccount } from '../Payment/BankAccount';
import { PaymentCard } from '../Payment/PaymentCard';
import { Role } from '../Role';
import { ScheduledTaskNotification } from '../Task/ScheduledTaskNotification';
import { Task } from '../Task/Task';
import { TaskTemplate } from '../Task/TaskTemplate';
import { TemplateNotification } from '../Task/TemplateNotification';
import { UserSaleTarget } from './UserSaleTarget';
import { UserServiceIntegration } from './UserServiceIntegration';
import {Team} from './Team';

export class User {
  id: number;
  uuid: string;
  role_id: number;

  name: string; // Note: NOT A REAL FIELD, Full Name Accessor- Deprecated
  full_name: string; // Laravel Accessor
  first_name: string;
  last_name?: string;
  phone_country_code: string;
  phone: string;
  phone_verified_at?: Date | string;
  email: string;
  email_verified_at?: Date | string;
  locale: 'es' | 'en';
  timezone: 'UTC' | 'Europe/Madrid';
  home_phone?: string;
  work_phone?: string;
  password: string;
  temporary_password: boolean;
  remember_token?: string;
  id_card?: string;
  date_of_birth?: Date | string;
  signature_verified_at?: Date | string;
  nationality?: string;
  // Agent Specific
  diary_url?: string;
  customer_contact_diary_url: string;
  // Client Specific
  employment?: string;
  economic_regime?: string;
  marital_status?: string;
  married_at?: Date | string;
  marriage_city?: string;
  home_owner?: string;
  number_of_dependent?: number;
  dialer_id?: string;
  relation_to_applicant?: string;
  debt_aware?: 0 | 1 | boolean;
  vulnerability?: string;
  time_to_contact?: string;
  allow_email?: 0 | 1 | boolean;
  allow_sms?: 0 | 1 | boolean;
  allow_call?: 0 | 1 | boolean;
  allow_post?: 0 | 1 | boolean;
  allow_marketing?: 0 | 1 | boolean;
  connex_username: string;
  active: number;
  paired_lawyer_id: number;
  paired_lawyer: User;
  criminal_record: boolean;
  spanish_resident: boolean;
  lso_in_five_years: boolean;
  derivation_of_responsibility: boolean;
  more_than_one_creditor: boolean;
  public_sanctions: boolean;

  created_at?: Date | string;
  updated_at?: Date | string;
  deleted_at?: Date | string;

  // Relations
  address?: Address;
  sale_target?: UserSaleTarget;
  notifications?: Array<DatabaseNotification>;
  role?: Role;
  departments?: Array<Department>;
  department_assignments?: Array<DepartmentAssignment>;
  payment_cards?: Array<PaymentCard>;
  bank_accounts?: Array<BankAccount>;
  default_payment_card?: PaymentCard;
  client_cases?: Array<Case>;
  partner_cases?: Array<Case>;
  drip_campaigns?: Array<DripCampaign>;
  drip_notifications_sender?: Array<DripNotification>;
  template_sender?: Array<TemplateNotification>;
  authored_email_histories?: Array<EmailHistory>;
  authored_sms_histories?: Array<SmsHistory>;
  authored_task_templates?: Array<TaskTemplate>;
  task_templates?: Array<TaskTemplate>;
  authored_tasks?: Array<Task>;
  scheduled_task_notifications?: Array<ScheduledTaskNotification>;
  task_template_assignments?: Array<TaskTemplate>;
  task_assignments?: Array<Task>;
  template_notification_assignments?: Array<TemplateNotification>;
  service_integrations?: Array<UserServiceIntegration>;
  relation_validation?: UserCaseRelationValidation;
  affiliate?: Affiliate;
  affiliates?: Array<Affiliate>;
  distribution_providers?: Array<DistributionProvider>;
  teams?: Array<Team>;
}
