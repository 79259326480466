import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../case.service';
import Swal from 'sweetalert2';

@Component({
  selector:    'app-case-payment',
  templateUrl: './case-payment.component.html',
  styles:      [],
})
export class CasePaymentComponent implements OnInit, OnDestroy {
  public componentType: 'distribution' | 'admin' = 'admin';
  public case: Case;
  public isLoading                               = 0;
  public serverResponse: LaravelResponse;

  public openDialog$: EventEmitter<any>       = new EventEmitter<any>();
  public fetchInstalments$: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions: Array<Subscription> = [];

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      this.route.queryParams.subscribe(result => {
        const stateParams    = new URLSearchParams(result);
        const paymentOutcome = stateParams.get('payment_outcome');
        if (paymentOutcome === 'success' || paymentOutcome === 'error' || paymentOutcome === 'cancelled') {
          const paymentSuccess = paymentOutcome === 'success';
          let title: string;
          let message: string;
          if (paymentOutcome === 'success') {
            title   = this.translate.instant('SHARED-COMPONENTS.payment_outcome.success.heading');
            message = this.translate.instant('SHARED-COMPONENTS.payment_outcome.success.message');
          } else if (paymentOutcome === 'cancelled') {
            title   = this.translate.instant('SHARED-COMPONENTS.payment_outcome.cancelled.heading');
            message = this.translate.instant('SHARED-COMPONENTS.payment_outcome.cancelled.message');
          } else {
            title   = this.translate.instant('SHARED-COMPONENTS.payment_outcome.error.heading');
            message = this.translate.instant('SHARED-COMPONENTS.payment_outcome.error.message');
          }
          Swal.fire({
            title,
            text:               message,
            icon:               paymentSuccess ? 'success' : 'error',
            showCancelButton:   false,
            showConfirmButton:  true,
            cancelButtonText:   this.translate.instant('SHARED.cancel'),
            confirmButtonColor: '#886ab5',
          }).then(res => {
            if (res.isConfirmed) {
              this.router.navigate([], {queryParams: {payment_outcome: null}, queryParamsHandling: 'merge'});
            }
          });

        }
      });

      const caseId = +params.get('id');
      this.isLoading++;
      this.subscriptions.push(
        this.caseService.get(caseId, ['client', 'loan_payment_plan', 'terms'])
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            result => this.case = result.data,
            error => this.serverResponse = error.error,
          ),
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public paymentRecorded() {
    this.caseService.get(this.case.id).subscribe(res => {
      // this.case = res.data;
    });
    this.fetchInstalments$.emit();
  }
}
