import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LaravelResourceResponse, LaravelResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Creditor } from '../../../../../_base-shared/models/Entity/Creditor';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CreditorService extends MainBaseApiService {
  storeBankAccount(creditorId: number, data: any): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/creditors/${ creditorId }/bank-accounts`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  destroyBankAccount(creditorId: number, bankAccountId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/creditors/${ creditorId }/bank-accounts/${ bankAccountId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  setAccountToDefault(creditorId: number, bankAccountId: number): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(`${ this.apiUrl }/creditors/${ creditorId }/bank-accounts/${ bankAccountId }`, {})
      .pipe(catchError(response => this.handleError(response)));
  }

  public index(data) {
    return this.http.get<LaravelResourceResponse<Array<Creditor>>>(`${ this.apiUrl }/creditors`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  getCreditorCaseCount(creditorId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/creditors/${ creditorId }/case-count`)
      .pipe(catchError(response => this.handleError(response)));
  }

  get(creditorId: number): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/creditors/${ creditorId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  store(newCreditor: Creditor): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/creditors`, newCreditor)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(creditorId: number, creditor: Creditor): Observable<LaravelResponse> {
    return this.http.put<LaravelResponse>(`${ this.apiUrl }/creditors/${ creditorId }`, creditor)
      .pipe(
        catchError(response => this.handleError(response)),
        tap(response => {
          this.globalEvents.setUnapprovedCreditors(response.data.unapproved_creditors);
        }));
  }

  delete(creditorId: number): Observable<LaravelResponse> {
    return this.http.delete<LaravelResponse>(`${ this.apiUrl }/creditors/${ creditorId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  deleteAndReassign(deleteId: number, reassignId: number, notes: string): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/creditors/reassign`,
      {reassign: reassignId, delete: deleteId, notes})
      .pipe(catchError(response => this.handleError(response)));
  }

  showReport(creditorId: number, data): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/creditors/${ creditorId }/show-report`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  showReportAll(): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/creditors/show-full-report`)
      .pipe(catchError(response => this.handleError(response)));
  }

  downloadReport(creditorId: number) {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/creditors/${ creditorId }/download-report`)
      .pipe(catchError(response => this.handleError(response)));
  }

  verifySendMandates(caseCreditorId, data = null) {
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/verify-send-mandate`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendMandatesRecoveryAgent(caseCreditorId, data = null) {
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/verify-send-mandate-recovery-agent`,
      data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendProposalAndAcoord(caseCreditorId, data = null) {
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/send-proposal-and-accord`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendRightOfAccess(caseId: number, data: { selected_ids: Array<number> }) {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-right-of-access', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendRightOfAccessPpi(caseId: number, data: { selected_ids: Array<number> }) {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-ppi', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendPpiClaim(caseId: number, data: { selected_ids: Array<number> }) {
    return this.http.post(this.apiUrl + '/case-creditors/send-ppi-claim', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAzcarateLoanLetter(caseId: number, data: { selected_ids: Array<number> }) {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-azcarate-loan', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAzcarateRevolvingLetter(caseId: number, data: { selected_ids: Array<number> }) {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-azcarate-revolving', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAepEmails(caseId, data = null) {
    return this.http.post(`${ this.apiUrl }/cases/${ caseId }/creditors/send-aep`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCaseCreditor(caseCreditorId, data) {
    return this.http.post(`${ this.apiUrl }/update-case-creditor/${ caseCreditorId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCasePublicDebt(caseId, debtId, data) {
    return this.http.patch(`${ this.apiUrl }/cases/${ caseId }/public-debts/${ debtId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendLastChanceEmail(caseCreditorId, data = null) {
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/send-last-chance`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendDeleteNumberEmail(caseCreditorId, data) {
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/send-creditor-delete-phone`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAntiHarassmentEmail(caseCreditorId, data = null) {
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/send-azkarate-acoso`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendLoanCancellationEmail(caseCreditorId, data = null) {
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/send-azkarate-cancelar`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAzcarateEmail(caseCreditorId) {
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/send-azcarate`, null)
      .pipe(catchError(response => this.handleError(response)));
  }

  public saveLoanCreditorsData(caseId: number, creditorId: number, data: any): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId,
      data)
      .pipe(catchError(response => this.handleError(response)));
  }

}
