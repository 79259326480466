<div class="collapse navbar-collapse client-nav-desktop" id="navbarNavDropdown"
     style="position: absolute;" [ngStyle]="{'right': authUser?.role?.slug === 'affiliate' ? '197px' : '100px'}">
  <!-- CLIENT NAVIGATION -->
  <ul *ngIf="desktopNav && authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'user'"
      class="navbar-nav mr-auto">
    <div style="width: 38rem;" class="d-flex flex-row justify-content-around">
      <div class="d-flex flex-row align-items-center justify-content-center">
        <mat-icon style="color:#242E61">home</mat-icon>
        <span class="client-nav-label" routerLink="/" routerLinkActive="route-active"
              [routerLinkActiveOptions]="{ exact: true }">Inicio</span>
      </div>
      <div *ngIf="selectedCase" class="d-flex flex-row align-items-center justify-content-center">
        <mat-icon style="color:#242E61">card_travel</mat-icon>
        <span class="client-nav-label"
              [routerLink]="['/client', 'cases', selectedCase.uuid, 'users', selectedCase.client.uuid, 'your-case']"
              routerLinkActive="route-active">Tu caso</span>
      </div>
      <div *ngIf="selectedCase" class="d-flex flex-row align-items-center justify-content-center">
        <mat-icon style="color:#242E61">description</mat-icon>
        <span class="client-nav-label"
              [routerLink]="['/client', 'cases', selectedCase.uuid,'users',selectedCase.user_id,'document-upload']"
              routerLinkActive="route-active">Docs</span>
      </div>
      <div *ngIf="selectedCase" class="d-flex flex-row align-items-center justify-content-center">
        <mat-icon style="color:#242E61">payment</mat-icon>
        <span class="client-nav-label" [routerLink]="['/client', 'cases', selectedCase.uuid, 'payments']"
              routerLinkActive="route-active">Pagos</span>
      </div>
      <div *ngIf="selectedCase" class="d-flex flex-row align-items-center justify-content-center">
        <mat-icon style="color:#242E61">person</mat-icon>
        <span class="client-nav-label" [routerLink]="['/client', 'cases', selectedCase.uuid, 'profile']"
              routerLinkActive="route-active">Perfil</span>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center" (click)="logOut()">
        <mat-icon style="color:#242E61">logout</mat-icon>
        <span class="client-nav-label"
              routerLinkActive="route-active">Salir</span>
      </div>
    </div>
  </ul>
  <!-- Affiliate NAVIGATION -->
  <ul *ngIf="authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'affiliate'" class="navbar-nav mr-auto">
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="" title="Dashboard" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.dashboard" | translate }}</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a [routerLink]="['affiliate', 'cases']" title="Affiliate Cases" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.cases" | translate }}</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a [routerLink]="['affiliate', 'leads', 'create']" title="Create lead" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.create-lead" | translate }}</span>
      </a>
    </li>
  </ul>
  <!-- Distribution Provider NAVIGATION -->
  <ul *ngIf="authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'distribution-provider'"
      class="navbar-nav mr-auto">
    <!--<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">-->
    <!--  <a routerLink="" title="Dashboard" class="nav-link">-->
    <!--    <span class="nav-link-text">{{ "NAVBAR.dashboard" | translate }}</span>-->
    <!--  </a>-->
    <!--</li>-->
    <!-- Distribution -->
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="['/distribution-provider', 'batches']" title="Distribution" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.distribution" | translate }}</span>
      </a>
    </li>
  </ul>
</div>
<!--Mobile nav-->
<mat-toolbar *ngIf="!desktopNav && authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'user'"
             class="mobile-nav nav-d-block">
  <div class="d-flex flex-column align-items-center justify-content-center" routerLink="/">
    <mat-icon>home</mat-icon>
    <a routerLinkActive="route-active" (click)="clientChangeNav()"
       [routerLinkActiveOptions]="{ exact: true }">Inicio</a>
  </div>
  <div *ngIf="selectedCase" class="d-flex flex-column align-items-center justify-content-center"
       [routerLink]="['/client', 'cases', selectedCase.uuid, 'users', selectedCase.client.uuid, 'your-case']">
    <mat-icon>card_travel</mat-icon>
    <a
      routerLinkActive="route-active">Tu caso</a>
  </div>
  <div *ngIf="selectedCase" class="d-flex flex-column align-items-center justify-content-center"
       [routerLink]="['/client', 'cases', selectedCase.uuid,'users',selectedCase.user_id,'document-upload']">
    <mat-icon>description</mat-icon>
    <a
      routerLinkActive="route-active">Docs</a>
  </div>
  <div *ngIf="selectedCase" class="d-flex flex-column align-items-center justify-content-center"
       [routerLink]="['/client', 'cases', selectedCase.uuid, 'payments']">
    <mat-icon>payment</mat-icon>
    <a routerLinkActive="route-active">Pagos</a>
  </div>
  <div *ngIf="selectedCase" class="d-flex flex-column align-items-center justify-content-center"
       [routerLink]="['/client', 'cases', selectedCase.uuid, 'profile']">
    <mat-icon>person</mat-icon>
    <a routerLinkActive="route-active">Perfil</a>
  </div>
  <div class="d-flex flex-column align-items-center justify-content-center" (click)="logOut()">
    <mat-icon>logout</mat-icon>
    <a routerLinkActive="route-active">Salir</a>
  </div>
</mat-toolbar>
