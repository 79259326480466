import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse, LaravelResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Role } from '../../../../../_base-shared/models/Role';
import { User } from '../../../../../_base-shared/models/User/User';
import { UserExternalService } from '../../../../../_base-shared/models/User/UserExternalService';
import { UserServiceIntegration } from '../../../../../_base-shared/models/User/UserServiceIntegration';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends MainBaseApiService {

  public index(requestData, relations: Array<string> = []) {
    requestData.with    = relations;
    const params = MainBaseApiService.convertFiltersForRequest(requestData, 'get');
    return this.http.get<LaravelResourceResponse<Array<User>>>(this.apiUrl + '/users', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public get(userId, requestData?, relations: Array<string> = []) {
    requestData.with    = relations;
    const params = MainBaseApiService.convertFiltersForRequest(requestData, 'get');
    return this.http.get<LaravelResourceResponse<User>>(this.apiUrl + '/users/' + userId, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public store(data) {
    return this.http.post<LaravelResourceResponse<User>>(this.apiUrl + '/users', data)
        .pipe(catchError(response => this.handleError(response)));
  }

  public update(userId, data) {
    return this.http.patch<LaravelResourceResponse<User>>(this.apiUrl + '/users/' + userId, data)
        .pipe(catchError(response => this.handleError(response)));
  }

  public delete(userId) {
    return this.http.delete<LaravelResourceResponse<User>>(this.apiUrl + '/users/' + userId)
        .pipe(catchError(response => this.handleError(response)));
  }

  public indexRoles() {
    return this.http.get<LaravelResourceResponse<Array<Role>>>(this.apiUrl + '/roles')
        .pipe(catchError(response => this.handleError(response)));
  }

  public getTasks(userId): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(this.apiUrl + '/users/' + userId + '/tasks')
        .pipe(catchError(response => this.handleError(response)));
  }

  public externalServices() {
    return this.http.get<LaravelResponse<Array<UserExternalService>>>(this.apiUrl + '/external-services')
        .pipe(catchError(response => this.handleError(response)));
  }

  public indexIntegrations(userId: number) {
    return this.http.get<LaravelResponse<Array<UserServiceIntegration>>>(
        this.apiUrl + '/users/' + userId + '/integrations')
        .pipe(catchError(response => this.handleError(response)));
  }

  public removeIntegration(userId: number, integrationId: number) {
    return this.http.delete<LaravelResponse>(this.apiUrl + '/users/' + userId + '/integrations/' + integrationId)
        .pipe(catchError(response => this.handleError(response)));
  }

  public testIntegration(userId: number, integrationId: number) {
    return this.http.post<LaravelResponse>(
        this.apiUrl + '/users/' + userId + '/integrations/' + integrationId + '/test', {})
        .pipe(catchError(response => this.handleError(response)));
  }

  public requestAuthorization(userId: number, serviceId: number) {
    return this.http.post<LaravelResponse>(this.apiUrl + '/users/' + userId + '/authorization/request',
        {external_service_id: serviceId})
        .pipe(catchError(response => this.handleError(response)));
  }

  public confirmAuthorization(userId: number, serviceId, code: string) {
    return this.http.post<LaravelResponse>(this.apiUrl + '/users/' + userId + '/authorization/confirm',
        {external_service_id: serviceId, authorization_code: code})
        .pipe(catchError(response => this.handleError(response)));
  }

  public validateField(field: string, value: string, userId: number | null = null) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/users/validate?field=' + field,
        {field_value: value, existing_model_id: userId})
        .pipe(catchError(response => this.handleError(response)));
  }

  public updateIdCard(userId: number, idCard: string) {
    return this.http.patch<LaravelResponse>(this.apiUrl + '/users/' + userId + '/update-field', {id_card: idCard})
        .pipe(catchError(response => this.handleError(response)));
  }

  public changePassword(userId: number, caseId: number, password: string) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/users/' + userId + '/change-password', {password, case_id: caseId})
      .pipe(catchError(response => this.handleError(response)));
  }

}
