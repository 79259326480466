import { NgModule } from '@angular/core';
import { SharedClientModule } from '../_shared/shared-client.module';
import { PublicClientRoutingModule } from './public-client-routing.module';
import {
  PublicPaymentHandlerWrapperComponent
} from './payment/public-payment-handler-wrapper/public-payment-handler-wrapper.component';
import {
  PublicPaymentHandlerComponent
} from './payment/public-payment-handler-wrapper/payment-handler/public-payment-handler.component';
import { ClientPaymentOutcomeComponent } from './payment/client-payment-outcome/client-payment-outcome.component';
import { ClientPaymentFooterComponent } from './client-payment-footer/client-payment-footer.component';


@NgModule({
  imports:      [
    SharedClientModule,
    PublicClientRoutingModule,
  ],
  declarations: [
    PublicPaymentHandlerWrapperComponent,
    PublicPaymentHandlerComponent,
    ClientPaymentOutcomeComponent,
    ClientPaymentFooterComponent
  ],
})
export class PublicClientModule {
}
