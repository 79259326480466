import { Component, OnInit } from '@angular/core';

@Component({
  selector:    'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles:      ['.mat-radio-button ~ .mat-radio-button { margin-left: 16px;}'],
})
export class DashboardComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

}
