import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-next-step-modal',
  templateUrl: './next-step-modal.component.html',
  styleUrls: ['./next-step-modal.component.scss']
})
export class NextStepModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NextStepModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }



}
