<div class="payment-wrap client-typography">
  <mat-card [ngStyle]="{'min-height': isDesktop ? '240px' : ''}">
    <div style="justify-content: left">
      <p class="text-muted d-flex flex-row align-items-center">
        <mat-icon  aria-hidden="false" fontIcon="work"
                   style="margin-right: 9px">person
        </mat-icon>
        {{'CLIENT.client-dashboard.your-details' | translate}}
      </p>
    </div>
    <mat-card-content>
      <div class="row mt-3">
        <div class="col">
          <h4> {{'CLIENT.client-dashboard.your-details' | translate}}</h4>
          <div class="d-flex flex-row">
            <div class="d-flex flex-column mr-4">
              <p class="text-muted">Nombre</p>
              <h3>{{user.first_name | titlecase}}</h3>
            </div>
            <div class="d-flex flex-column mr-4">
              <p class="text-muted">Apellido</p>
              <h3>{{user.last_name | titlecase}}</h3>
            </div>
            <div class="d-flex flex-column">
              <p class="text-muted">{{'CLIENT.client-dashboard.phone' | translate}}</p>
              <h3>{{user.phone}}</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- Temp action -->
      <mat-card-actions>
        <button  class="ml-2 onboarding-payment-btn" style="margin-top: 8px;"
                 [routerLink]="['/client', 'cases', case.uuid, 'profile']">
          {{'CLIENT.client-dashboard.edit-details' | translate}}
        </button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</div>
