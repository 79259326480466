import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResponse } from '../contracts/laravel-response.interface';
import { PaymentStatus } from '../models/Status/PaymentStatus';
import { MainBaseApiService } from '../../main/src/app/_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentStatusService extends MainBaseApiService {

  index(data = {}) {
    return this.http.get<LaravelResponse<Array<PaymentStatus>>>(`${ this.apiUrl }/payment-statuses`, {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }
}
