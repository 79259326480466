<div class="container">
  <form *ngIf="formReady" class="row" [formGroup]="form" (ngSubmit)="submit()">
    <div class="col-12">
      <h1>{{"CONFIG.misc.header" | translate}}</h1>
    </div>
    <div class="col-12">
      <div class="card shadow">
        <div class="card-body pt-5">
          <div class="container">
            <div class="row pb-5">
              <!-- Loan arrangement fee -->
              <div class="col-9">
                <div>
                  <app-input type="number" formControlName="loan_arrangement_fee" [fullWidth]="true" [extraLabel]="true"
                            [label]="'CONFIG.misc.loan_arrangement_fee' | translate">
                  </app-input>
                </div>
                <!-- Late payment penalty -->
                <div>
                  <app-input type="number" formControlName="late_payment_penalty" [fullWidth]="true" [extraLabel]="true"
                            [label]="'CONFIG.misc.late_payment_penalty' | translate">
                  </app-input>
                </div>
                <div>
                  <app-input type="number" formControlName="debt_reduction_fee" [fullWidth]="true" [extraLabel]="true"
                            [label]="'CONFIG.misc.debt_reduction_fee' | translate">
                  </app-input>
                </div>
    <!--            &lt;!&ndash; LSO &ndash;&gt;-->
    <!--            <div class="col-6">-->
    <!--              <app-input type="number" formControlName="full_target" [fullWidth]="true"-->
    <!--                         [label]="'CONFIG.misc.full-target' | translate">-->
    <!--              </app-input>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; LSO2 &ndash;&gt;-->
    <!--            <div class="col-6">-->
    <!--              <app-input type="number" formControlName="full_target_lso2" [fullWidth]="true"-->
    <!--                         [label]="'CONFIG.misc.monthly_lso2' | translate">-->
    <!--              </app-input>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; DM &ndash;&gt;-->
    <!--            <div class="col-6">-->
    <!--              <app-input type="number" formControlName="full_target_dm" [fullWidth]="true"-->
    <!--                         [label]="'CONFIG.misc.full-target-dm' | translate">-->
    <!--              </app-input>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; BDM &ndash;&gt;-->
    <!--            <div class="col-6">-->
    <!--              <app-input type="number" formControlName="full_target_bdm" [fullWidth]="true"-->
    <!--                         [label]="'CONFIG.misc.full-target-bdm' | translate">-->
    <!--              </app-input>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; Frozen Cases &ndash;&gt;-->
    <!--            <div class="col-6">-->
    <!--              <app-input type="number" formControlName="full_target_caso_congelado" [fullWidth]="true"-->
    <!--                         [label]="'CONFIG.misc.full-target-caso-congelado' | translate">-->
    <!--              </app-input>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; Documentation Complete &ndash;&gt;-->
    <!--            <div class="col-6">-->
    <!--              <app-input type="number" formControlName="full_target_documentacion_completa" [fullWidth]="true"-->
    <!--                         [label]="'CONFIG.misc.full-target-documentacion-completa' | translate">-->
    <!--              </app-input>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; DM &ndash;&gt;-->
    <!--            <div class="col-6">-->
    <!--              <app-input type="number" formControlName="full_target_dgs" [fullWidth]="true"-->
    <!--                         [label]="'CONFIG.misc.full-target-dgs' | translate">-->
    <!--              </app-input>-->
    <!--            </div>-->
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-8">
                <div class="d-flex">
                  <app-spinner-btn [loading]="isSubmitting" [name]="'SHARED.save' | translate"></app-spinner-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- view for the various fee percentages -->
  <div class="row mt-3">
    <div class="col-12 mt-3">
      <h1>Fee Percentages</h1>
    </div>
    <div class="col-12">
      <div class="card shadow">
        <div class="card-body pt-5">
          <div class="container">
            <div class="row pb-5">
              <div class="col-9">
                <table mat-table matSort [dataSource]="feePercentages" width="100%">
                  <tr mat-header-row *matHeaderRowDef="feePercentageTableColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: feePercentageTableColumns;"></tr>
                  <!-- Date -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }} </td>
                  </ng-container>
                  <!-- Description -->
                  <ng-container matColumnDef="percentage">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Percentage</th>
                    <td mat-cell *matCellDef="let element">{{ element.percentage }} </td>
                  </ng-container>
                  <!-- Amount -->
                  <ng-container matColumnDef="bywho">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
                    <td mat-cell *matCellDef="let element">{{ element.user.first_name }} {{ element.user.last_name }}</td>
                  </ng-container>
                  <!-- By Who -->
                  <ng-container matColumnDef="date">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let element">{{ element.created_at | toDateObject | date:'dd/MM/YYYY HH:mm' }} </td>
                  </ng-container>
                  <!-- Actions -->
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.actions' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                      <button mat-icon-button color="warn" class="ml-2" (click)="editFeePercentage(element.id, $event)">
                        <mat-icon class="mat-icon">edit_note</mat-icon>
                      </button>
                      <button mat-icon-button color="warn" class="ml-2" (click)="removeFeePercentage(element.id, $event)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                </table>
              </div>
              <form *ngIf="percentageFormReady" class="col-3" [formGroup]="percentageForm" (ngSubmit)="submitPercentageForm()">
                <div>
                  <!-- Form to create new fee percentage -->
                  <div class="row">
                    <div class="col-12">
                      <h3>Percentage Form</h3>
                    </div>
                    <div class="col-12">
                      <app-input type="text" formControlName="name" [fullWidth]="true"
                                  label="Description">
                      </app-input>
                    </div>
                    <div class="col-12">
                      <app-input type="number" formControlName="percentage" [fullWidth]="true"
                                  label="Percentage">
                      </app-input>
                    </div>
                    <div class="col-12">
                      <app-input type="checkbox" formControlName="is_fds" (checked)="isFds" [fullWidth]="true" label="Is this percentage FDS?"></app-input>
                    </div>
                    <div class="col-12">
                      <div class="d-flex">
                        <app-spinner-btn [loading]="isSubmittingFee" [name]="'SHARED.save' | translate" [disabled]="disableButton()"></app-spinner-btn>
                        <button mat-stroked-button color="primary" type="button" class="ml-2" (click)="percentageForm.reset()">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
                <app-input type="number" formControlName="id" hidden></app-input>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
