<form *ngIf="form" [formGroup]="form" #ngForm>
  <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'">
    <div class="col-8">
      <!-- Start Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="startDate" formControlName="start_date"
               (dateChange)="dateChanged($event, 'end_date', false)">
        <button *ngIf="form.get('start_date') && form.get('start_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('start_date').disabled" (click)="clearFormControl($event, 'start_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- End Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="endDate" formControlName="end_date"
               (dateChange)="dateChanged($event, 'end_date', true)">
        <button *ngIf="form.get('end_date') && form.get('end_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('end_date').disabled" (click)="clearFormControl($event, 'end_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- From/To Shortcut modifiers -->
      <div class="d-inline-block pl-5">
        <mat-radio-group [formControl]="dateRadioControl" aria-label="Select an option"
                         (change)="dateModifierChange($event)">
          <mat-radio-button value="day">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="isoWeek">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="month">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
        <mat-slide-toggle formControlName="only_related" color="primary" class="ml-5"
                          (ngModelChange)="onlyRelatedChange($event)">
          {{ 'CASES.list.filters.only_related.label' | translate }}
        </mat-slide-toggle>
      </div>
    </div>
    <!-- Search -->
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'">
    <!-- Status Date Type -->
    <div class="pl-3">
      <mat-label style="padding-right: 10px; display: block">
        {{ 'CASES.list.filters.status_date_type.label' | translate }}
      </mat-label>
      <mat-radio-group formControlName="status_date_type" aria-label="Select an option">
        <mat-radio-button value="sign_up">
          {{ "CASES.list.filters.status_date_type.options.sign_up" | translate }}
        </mat-radio-button>
        <mat-radio-button value="change_date"
                          [matTooltip]="'CASES.list.filters.status_date_type.option-labels.sign_up' | translate">
          {{ "CASES.list.filters.status_date_type.options.change_date" | translate }}
        </mat-radio-button>
        <mat-radio-button value="was_in_status"
                          [matTooltip]="'CASES.list.filters.status_date_type.option-labels.was_in_status' | translate">
          {{ "CASES.list.filters.status_date_type.options.was_in_status" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- Activity Status -->
    <div class="pl-5">
      <mat-label style="padding-right: 10px; display: block"
                 [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
        {{ 'CASES.list.filters.activity_status.label' | translate }}
      </mat-label>
      <mat-radio-group formControlName="activity_status" aria-label="Select an option">
        <mat-radio-button value="active">
          {{ "CASES.list.filters.activity_status.options.active" | translate }}
        </mat-radio-button>
        <mat-radio-button value="inactive">
          {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
        </mat-radio-button>
        <mat-radio-button value="all">
          {{ "CASES.list.filters.activity_status.options.all" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="dropleft">
      <button style="min-width: 120px" mat-button color="primary" class="btn dropdown-toggle ml-4" type="button"
              id="statusShortcutFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Select All
      </button>
      <div class="dropdown-menu" aria-labelledby="statusShortcutFilter">
        <a class="dropdown-item" (click)="selectStatuses(true)">
          {{ "CASES.list.filters.status_preset.active" | translate }}
        </a>
        <a class="dropdown-item" (click)="selectStatuses(false)">
          {{ "CASES.list.filters.status_preset.inactive" | translate }}
        </a>
      </div>
    </div>
  </div>
  <!-- Only show on `legal` tab -->
  <div class="row" *ngIf="type === 'legal'">
    <div class="col-8">
      <div class="">
        <mat-label style="padding-right: 10px; display: block"
                   [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
          {{ 'CASES.list.filters.activity_status.label' | translate }}
        </mat-label>
        <mat-radio-group formControlName="activity_status" aria-label="Select an option">
          <mat-radio-button value="active">
            {{ "CASES.list.filters.activity_status.options.active" | translate }}
          </mat-radio-button>
          <mat-radio-button value="inactive">
            {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
          </mat-radio-button>
          <mat-radio-button value="all">
            {{ "CASES.list.filters.activity_status.options.all" | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <!--      <mat-slide-toggle formControlName="all_active" color="primary" class="ml-5">-->
        <!--        {{"PAYMENTS.show-all-active" | translate}}-->
        <!--      </mat-slide-toggle>-->
      </div>
    </div>
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <!-- Advanced Filters -->
  <div class="filters pb-5 pt-3">
    <div class="advanced-filters">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex align-items-center justify-content-between" style="width: 100%">
                {{ "PAYMENTS.advanced-filters" | translate }}
                <!--<button mat-stroked-button color="primary" (click)="clearFilters()">-->
                <!--  {{"SHARED.reset-filters" | translate}}-->
                <!--</button>-->
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'">
<!--            &lt;!&ndash; Products &ndash;&gt;-->
           <div class="col-2 col-lg">
             <app-dropdown [data]="products"
                           [isMultiple]="true" [label]="'CASES.single.product-type' | translate"
                           [matOptionValue]="'id'" (clearSelect)="clearMultiSelect($event, 'products')"
                           [parentFormGroup]="form" [parentFormControlName]="'products'">
             </app-dropdown>
           </div>
            <!-- Affiliates -->
            <div class="col-2 col-lg">
              <app-dropdown [data]="affiliates" [isMultiple]="true"
                            [label]="'CASES.affiliates' | translate"
                            (clearSelect)="clearMultiSelect($event, 'affiliates')"
                            [parentFormGroup]="form" [parentFormControlName]="'affiliates'">
              </app-dropdown>
            </div>
<!--            &lt;!&ndash; Creditors &ndash;&gt;-->
<!--            <div class="col-6 col-lg">-->
<!--              <app-dropdown [data]="creditors" [isMultiple]="true"-->
<!--                            [label]="'CASES.single.creditors.heading' | translate"-->
<!--                            (clearSelect)="clearMultiSelect($event, 'creditors')"-->
<!--                            [parentFormGroup]="form" [parentFormControlName]="'creditors'">-->
<!--              </app-dropdown>-->
<!--            </div>-->
<!--            &lt;!&ndash; Last Action At &ndash;&gt;-->
<!--            <div class="col-6 col-lg">-->
<!--              <mat-form-field appearance="standard" class="input-full-width">-->
<!--                <mat-label>Last Action</mat-label>-->
<!--                <input matInput formControlName="last_action" type="number">-->
<!--                <div *ngIf="form.get('last_action').errors && (form.get('last_action').touched || ngForm.submitted)">-->
<!--                  <mat-error [hidden]="!form.get('last_action').hasError('required')">-->
<!--                    {{ "SHARED.field-required" | translate }}-->
<!--                  </mat-error>-->
<!--                </div>-->
<!--              </mat-form-field>-->
<!--            </div>-->
<!--            &lt;!&ndash; Has distribution &ndash;&gt;-->
<!--            <div *ngIf="userIsAMami()" class="col-6 col-lg">-->
<!--              <app-input type="select" formControlName="case_distribution_status" appearance="standard"-->
<!--                         [fullWidth]="true"-->
<!--                         [label]="'CASES.list.filters.has_distribution.label' | translate" [showClear]="true"-->
<!--                         [selectOptions]="hasDistributionOptions" [selectLabel]="'label'" [selectValue]="'value'">-->
<!--              </app-input>-->
<!--            </div>-->
<!--            &lt;!&ndash; Batch ID &ndash;&gt;-->
<!--            <div *ngIf="userIsAMami()" class="col-6 col-lg">-->
<!--              <app-input type="select" formControlName="distribution_batch_ids" appearance="standard" multiple-->
<!--                         fullWidth="false"-->
<!--                         [label]="'DISTRIBUTION.batch.selector.distribution_batch_id' | translate"-->
<!--                         [selectOptions]="distributionBatches" [selectLabel]="'name'" [selectValue]="'id'">-->
<!--              </app-input>-->
<!--            </div>-->
<!--            &lt;!&ndash; Teams &ndash;&gt;-->
<!--            <div class="col-6 col-lg">-->
<!--              <app-input type="select" formControlName="team_ids" appearance="standard" multiple-->
<!--                         fullWidth="false"-->
<!--                         [label]="'TEAM.model_name.plural' | translate"-->
<!--                         [selectOptions]="teams" [selectLabel]="'name'" [selectValue]="'id'">-->
<!--              </app-input>-->
<!--            </div>-->
          </div>
          <div class="row">
<!--            &lt;!&ndash; Amount Paid &ndash;&gt;-->
<!--            <div class="col-6 col-lg" *ngIf="type === 'case' || type === 'customer_contact'">-->
<!--              <app-input type="number" formControlName="amount_paid" [label]="'Amount Paid'" appearance="standard"-->
<!--                         [fullWidth]="true">-->
<!--              </app-input>-->
<!--            </div>-->
<!--            &lt;!&ndash; Debt Level &ndash;&gt;-->
<!--            <div class="col-6 col-lg" *ngIf="type === 'case'">-->
<!--              <app-input type="number" formControlName="debt_level" [label]="'Debt level'" appearance="standard"-->
<!--                         [fullWidth]="true">-->
<!--              </app-input>-->
<!--            </div>-->
<!--            &lt;!&ndash; Outstanding Balance&ndash;&gt;-->
<!--            <div class="col-6 col-lg" *ngIf="type === 'case'">-->
<!--              <app-input type="number" formControlName="outstanding_balance" [label]="'Outstanding Balance'"-->
<!--                         appearance="standard" [fullWidth]="true">-->
<!--              </app-input>-->
<!--            </div>-->
            <!-- Status Categories -->
            <div class="col-6 col-lg">
              <app-dropdown
                      [data]="statusCategories" [matOptionValue]="'id'" [isMultiple]="true" [selectAll]="true"
                      (selectChange)="statusCategoryChanged($event)"
                      (clearSelect)="clearStatusControl($event, statusCategoryControl,'category')"
                      [label]="'CASES.single.general.status_editor.status_category' | translate"
                      [parentFormControl]="statusCategoryControl">
              </app-dropdown>
            </div>
            <!-- Statuses -->
            <div class="col-6 col-lg">
              <app-dropdown
                      [data]="filteredStatusCategories" [parentFormControl]="statusControl" [matOptionValue]="'id'"
                      [isMultiple]="true" [selectAll]="true" (selectChange)="statusChanged($event)"
                      (clearSelect)="clearStatusControl($event, statusControl,'status')"
                      [label]="'Status'" [optionGroup]="true" [optionGroupLabel]="'name'">
              </app-dropdown>
            </div>
            <!-- Payment Statuses -->
            <div class="col-6 col-lg">
              <app-dropdown [data]="paymentStatuses" [isMultiple]="true"
                            [label]="'CASES.single.general.status_editor.payment_status' | translate"
                            (clearSelect)="clearMultiSelect($event, 'payment_statuses')"
                            [parentFormGroup]="form" [parentFormControlName]="'payment_statuses'">
              </app-dropdown>
            </div>
<!--            &lt;!&ndash; Call Statuses &ndash;&gt;-->
<!--            <div class="col-6 col-lg">-->
<!--              <app-dropdown [data]="callStatuses" [isMultiple]="true"-->
<!--                            [label]="'CONFIG.call-status.header' | translate"-->
<!--                            (clearSelect)="clearMultiSelect($event, 'call_statuses')"-->
<!--                            [parentFormGroup]="form" [parentFormControlName]="'call_statuses'">-->
<!--              </app-dropdown>-->
<!--            </div>-->

            <div class="col-6  col-lg" *ngIf="type === 'customer_contact'">
              <app-input type="number" formControlName="days_no_contact"
                         [label]="'CASES.list.filters.days_no_contact.label' | translate"
                         [appearance]="'standard'" [extraLabel]="false" [fullWidth]="true">
              </app-input>
            </div>
            <div class="col-6  col-lg" *ngIf="type === 'customer_contact'">
              <app-dropdown [data]="notificationChannels" [isMultiple]="true"
                            [label]="'CASES.list.filters.days_no_contact_channels.label' | translate"
                            [matOptionLabel]="'label'"
                            [matOptionValue]="'value'"
                            (clearSelect)="clearMultiSelect($event, 'days_no_contact_channels')"
                            [parentFormGroup]="form" [parentFormControlName]="'days_no_contact_channels'">
              </app-dropdown>
            </div>
<!--            &lt;!&ndash; User Department Assignments &ndash;&gt;-->
<!--            <div class="col-6 col-lg" *ngIf="type === 'case' || type === 'customer_contact'">-->
<!--              <app-input type="select" *ngIf="form" formControlName="user_department_assignments" appearance="standard"-->
<!--                         [label]="'CASES.list.filters.user_case_roles.label' | translate" multiple [fullWidth]="true"-->
<!--                         [selectOptions]="departmentCategories" [selectLabel]="'name'" [selectValue]="'id'"-->
<!--                         [optGroupProperty]="'departments'">-->
<!--              </app-input>-->
<!--            </div>-->
          </div>
          <!-- Department Users -->
          <div class="row">
            <ng-container *ngFor="let departmentCategory of departmentCategories">
              <ng-container *ngFor="let department of departmentCategory.departments">
                <div class="col-6 col-md-4 col-lg-3 col-xl-3">
                  <app-input type="select" *ngIf="form" [formControlName]="department.type" appearance="standard"
                             [label]="department.name" multiple [fullWidth]="true" searchable="true"
                             [selectOptions]="department.users" [selectLabel]="'name'" [selectValue]="'id'">
                  </app-input>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <!-- Case Entities -->
          <div class="row" *ngIf="type === 'legal'">
            <!-- Notaries -->
            <div class="col-6 col-lg">
              <app-dropdown [data]="notaries" [isMultiple]="true"
                            [label]="'LEGAL.notary' | translate"
                            (clearSelect)="clearMultiSelect($event, 'notaries')"
                            [parentFormGroup]="form" [parentFormControlName]="'notaries'">
              </app-dropdown>
            </div>
            <!-- Administrators -->
            <div class="col-6 col-lg">
              <app-dropdown [data]="administrators" [isMultiple]="true"
                            [label]="'LEGAL.administrator' | translate"
                            (clearSelect)="clearMultiSelect($event, 'administrator')"
                            [parentFormGroup]="form" [parentFormControlName]="'administrators'">
              </app-dropdown>
            </div>
            <!-- Courts -->
            <div class="col-6 col-lg">
              <app-dropdown [data]="courts" [isMultiple]="true"
                            [label]="'LEGAL.court' | translate"
                            (clearSelect)="clearMultiSelect($event, 'courts')"
                            [parentFormGroup]="form" [parentFormControlName]="'courts'">
              </app-dropdown>
            </div>
            <!-- Solicitors -->
            <div class="col-6 col-lg">
              <app-dropdown [data]="solicitors" [isMultiple]="true"
                            [label]="'LEGAL.solicitor' | translate"
                            (clearSelect)="clearMultiSelect($event, 'solicitors')"
                            [parentFormGroup]="form" [parentFormControlName]="'solicitors'">
              </app-dropdown>
            </div>
          </div>
          <div class="row" *ngIf="type === 'legal'">
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{'ADDRESS.city' | translate}}</mat-label>
                <input type="text" matInput formControlName="city">
              </mat-form-field>
            </div>
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{ 'ADDRESS.region' | translate }}</mat-label>
                <input type="text" matInput formControlName="region">
              </mat-form-field>
            </div>
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{'CASES.list.filters.notary_appointed.label' | translate}}</mat-label>
                <mat-select formControlName="notary_appointed">
                  <mat-option [value]="1">{{'SHARED.yes' | translate}}</mat-option>
                  <mat-option [value]="0">{{'SHARED.no' | translate}}</mat-option>
                </mat-select>
                <button *ngIf="form.get('notary_appointed') &&
                  (form.get('notary_appointed').value === 0 || form.get('notary_appointed').value === 1)"
                        mat-button matSuffix mat-icon-button
                        [disabled]="form.get('notary_appointed').disabled" aria-label="Clear"
                        (click)="clearFormControl($event, 'notary_appointed')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
<!--          <div class="row" *ngIf="type === 'case'">-->
<!--            <div class="col-6 col-md-4 col-lg-3 col-xl-3">-->
<!--              <mat-slide-toggle formControlName="has_property" color="primary" class="ml-5">-->
<!--                {{ 'CASES.list.filters.has_property.label' | translate }}-->
<!--              </mat-slide-toggle>-->
<!--            </div>-->
<!--            <div class="col-6 col-md-4 col-lg-3 col-xl-3">-->
<!--              <mat-slide-toggle formControlName="has_pending_docs" color="primary" class="ml-5">-->
<!--                {{ 'CASES.list.filters.has_pending_docs.label' | translate }}-->
<!--              </mat-slide-toggle>-->
<!--            </div>-->
<!--            <div class="col-6 col-md-4 col-lg-3 col-xl-3">-->
<!--              <mat-slide-toggle formControlName="has_public_debt" color="primary" class="ml-5">-->
<!--                {{ 'CASES.list.filters.has_public_debt.label' | translate }}-->
<!--              </mat-slide-toggle>-->
<!--            </div>-->
<!--          </div>-->
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</form>
