import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'ngx-signaturepad/signature-pad';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../admin/case/case.service';

@Component({
  selector:    'app-e-signature',
  templateUrl: './e-signature.component.html',
  styleUrls:   ['./e-signature.component.scss'],
})
export class ESignatureComponent implements OnInit, AfterViewInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public case: Case;
  public date                                               = new Date();
  public dmData;
  public proposal;
  public sign                                               = 'client';
  public caseType;
  public uuId;
  public imgPath;
  public loading                                            = false;
  public signatureImg                                       = '';
  public prevSignatureImg                                   = '';
  public prevSignatureImgPartner                            = '';
  public vehiclesArray                                      = [];
  public propertiesArray                                    = [];
  public bankAccArray                                       = [];
  public otherArray                                         = [];
  public allCreditors: Array<{ name: string, pivot?: any }> = [];

  public subsidy_benefit              = 0;
  public pension                      = 0;
  public mortgage                     = 0;
  public utilities                    = 0;
  public vehicles                     = 0;
  public telephone                    = 0;
  public totalExpenses                = 0;
  public totalAssets                  = 0;
  public totalPublicDebtValue         = 0;
  public totalCreditorValue           = 0;
  public totalUnsecuredCreditorsValue = 0;
  public totalWithoutStepOffer        = 0;
  public totalWithStepOffer           = 0;
  public globalCreditWithoutStepOffer = 0;
  public globalCreditWithStepOffer    = 0;
  public otherIncome                  = 0;
  public otherExpenses                = 0;
  public alimentacion                 = 0;

  public companyName      = environment.COMPANY_NAME;
  public companyNameLegal = environment.COMPANY_NAME_LEGAL;

  public signaturePadOptions = {
    minWidth:     1,
    maxWidth:     2,
    canvasWidth:  458,
    canvasHeight: 100,
  };

  // TODO: load client/partner relations
  constructor(private route: ActivatedRoute,
              private elRef: ElementRef,
              private translate: TranslateService,
              private toast: ToastrService,
              private caseService: CaseService) {
    this.imgPath = environment.STORAGE_URL + '/';
  }

  ngOnInit(): void {
    this.sign    = this.route.snapshot.queryParamMap.get('sign');
    this.uuId    = this.route.snapshot.queryParamMap.get('uuid');

    this.caseService.caseInfo(this.uuId)
      .pipe(finalize(() => {
        setTimeout(() => {
          this.scrollToElement('signature');
        }, 500);
      }))
      .subscribe(next => {
        this.case                    = next.data;
        this.proposal                = next.data.proposal?.proposal;
        this.prevSignatureImg        = next.data.propuesta_modelo?.signature;
        this.prevSignatureImgPartner = next.data.propuesta_modelo?.signature_partner;
        //  Add `name` prop for public debts
        //  Used when sorting
        this.case.public_debts.map(debt => {
          debt.name = 'Deuda publica';
        });
        this.case.unsecured_creditors.forEach(caseCreditor => {
          this.allCreditors.push({name: caseCreditor.name, pivot: caseCreditor.pivot});
        });
        this.case.secured_creditors.forEach(caseCreditor => {
          this.allCreditors.push({name: caseCreditor.name, pivot: caseCreditor.pivot});
        });
        this.case.public_debts.forEach(caseCreditor => {
          this.allCreditors.push({name: 'Deuda publica', pivot: null});
        });
        //  Sort `allCreditors` and `unsecuredCreditors` alphabetically
        this.allCreditors       = this.allCreditors.slice().sort(this.compare);
        this.case.unsecured_creditors = this.case.unsecured_creditors.slice().sort(this.compare);

        //  ALL CALCULATIONS HERE ARE MIRROR FROM BACKEND
        //  IF BACKEND CALCULATIONS CHANGE THIS NEEDS TO BE CHANGED TOO
        //  THIS IS ONLY FOR LSO CASE
        this.sumValues();
        this.calculateTotal();

        //  Get DM proposal data
        this.caseService.getProposalData(this.uuId).subscribe(data => {
          this.dmData = data.data;
        });
      });

    const viewData = {
      viewed_by: '',
    };

    if (this.sign === 'partner') {
      viewData.viewed_by = 'partner';
    } else {
      viewData.viewed_by = 'client';
    }

    this.caseService.sendViewedTimeProposal(this.uuId, viewData)
      .subscribe(obj => {
      });
  }

  ngAfterViewInit() {
    this.signaturePad.clear();
  }

  compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  sumValues() {
    this.subsidy_benefit = (this.case.income?.subsidy_benefit || 0) + (this.case.income?.unemployment_benefit || 0);
    this.pension         = (this.case.income?.pension_credit || 0) + (this.case.income?.pension_other || 0) +
      (this.case.income?.pension_private || 0) + (this.case.income?.pension_state || 0);
    this.otherIncome     = (this.case.income?.other_boarders_or_lodgers || 0) +
      (this.case.income?.other_non_dependent_contributions || 0) +
      (this.case.income?.other_student_loans_and_grants || 0) +
      (this.case.income?.alimony || 0) + (this.case.income?.compensatory_pension || 0);
    this.mortgage        = (this.case.expense?.essential_mortgage || 0) + (this.case.expense?.essential_rent || 0);
    this.utilities       = (this.case.expense?.essential_gas || 0) + (this.case.expense?.essential_electricity || 0) +
      (this.case.expense?.essential_water || 0);
    this.vehicles        = (this.case.expense?.travel_car_insurance || 0) +
      (this.case.expense?.travel_vehicle_tax || 0) +
      (this.case.expense?.travel_fuel || 0) + (this.case.expense?.essential_hire_purchase || 0) +
      (this.case.expense?.itv || 0);
    this.telephone       = (this.case.expense?.phone_home || 0) + (this.case.expense?.phone_mobile || 0);
    this.otherExpenses   = (this.case.expense?.other_hobbies_leisure_sport || 0) +
      (this.case.expense?.other_other || 0) +
      (this.case.expense?.medications || 0) + (this.case.expense?.medical_expenses || 0) +
      (this.case.expense?.pet_expenses || 0) +
      (this.case.expense?.cosmetics_and_beauty || 0) + (this.case.expense?.legal_fees || 0) +
      (this.case.expense?.essential_local_tax || 0) +
      (this.case.expense?.life_insurance || 0) + (this.case.expense?.pension_plan || 0) +
      (this.case.expense?.ibi || 0) +
      (this.case.expense?.home_insurance || 0) + (this.case.expense?.essential_other || 0);
    this.alimentacion    = (this.case.expense?.housekeeping_food_and_groceries || 0) +
      (this.case.expense?.housekeeping_cleaning || 0) +
      (this.case.expense?.housekeeping_newspapers || 0) + (this.case.expense?.housekeeping_alcohol || 0) +
      (this.case.expense?.housekeeping_clothing_footwear || 0);

    this.case.expense?.special_expenses.map(exp => {
      this.totalExpenses += +exp.value;
    });
    this.totalExpenses += +this.proposal?.total_expenses;
    // filter vehicles
    this.vehiclesArray   = this.case?.assets.filter(asset => {
      if (asset.type === 'vehicles') { // Sum total
        this.totalAssets += asset.value || 0;
      }
      return asset.type === 'vehicles';
    });
    // filter properties
    this.propertiesArray = this.case.assets.filter(asset => {
      if (asset.type === 'properties') {  // Sum total
        this.totalAssets += asset.value || 0;
      }
      return asset.type === 'properties';
    });
    // filter bank accounts
    this.bankAccArray    = this.case.assets.filter(asset => {
      if (asset.type === 'bank_accounts') { // Sum total
        this.totalAssets += (asset.balance || 0) - (asset.exposed || 0);
      }
      return asset.type === 'bank_accounts';
    });
    // filter bank other
    this.otherArray      = this.case.assets.filter(asset => {
      if (asset.type === 'other') { // Sum total
        this.totalAssets += asset.estimated_value || 0;
      }
      return asset.type === 'other';
    });
    this.case.public_debts.map(debt => this.totalPublicDebtValue += debt.current_balance);
    this.case.secured_creditors.concat(this.case.unsecured_creditors).map(creditor => {
      if (creditor.pivot.debt_type !== 'mortgage') {
        this.totalCreditorValue += creditor.pivot.current_balance;
      }
    });
    this.case.unsecured_creditors.map(cred => {
      this.totalUnsecuredCreditorsValue += cred.pivot.current_balance;
    });
  }

  scrollToElement($element): void {
    const element = document.getElementById($element);
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const div = this.elRef.nativeElement.querySelector('.signature-wrap')?.offsetWidth;
    if (div !== 0) {
      this.signaturePad.set('canvasWidth', div);
    }
  }

  drawComplete() {
    this.signatureImg = this.signaturePad.toDataURL();
  }

  clear() {
    this.signaturePad.clear();
    this.signatureImg = '';
  }

  calculateTotal() {
    this.case.unsecured_creditors.forEach(creditor => {
      this.totalWithoutStepOffer += (creditor.pivot.current_balance / this.totalUnsecuredCreditorsValue) *
        this.proposal?.offer_to_creditors;

      this.totalWithStepOffer += ((creditor.pivot.current_balance / this.totalUnsecuredCreditorsValue) *
        this.proposal?.step_offer);

      this.globalCreditWithoutStepOffer += (((this.proposal?.term || 0)) *
        ((creditor.pivot.current_balance / this.totalUnsecuredCreditorsValue) * this.proposal?.offer_to_creditors));

      this.globalCreditWithStepOffer += (((this.proposal?.term || 0) - (this.proposal?.step_period || 0)) *
        ((creditor.pivot.current_balance / this.totalUnsecuredCreditorsValue) * this.proposal?.offer_to_creditors) +
        ((this.proposal?.step_period || 0) * (this.proposal?.step_period && this.proposal?.step_offer ?
          ((creditor.pivot.current_balance / this.totalUnsecuredCreditorsValue) * this.proposal?.step_offer) : 0)));
    });
  }

  saveSignature() {
    this.loading = true;
    const data   = new FormData();
    if (this.sign === 'client') {
      data.append('signature', this.signatureImg);
    }
    if (this.sign === 'partner') {
      data.append('signature_1', this.signatureImg);
    }

    this.caseService.signProposal(this.case.id, data)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        next => {
          this.prevSignatureImg        = next.data.signature;
          this.prevSignatureImgPartner = next.data.signature_partner;

          this.toast.success('Contract signed successfully.', 'Success');

          window.location.href = 'https://www.deudafix.es/doc_success.php';
        },
        error => {
          this.toast.error('Sign failed. Please try again later.', 'Error');
        });
  }

}
