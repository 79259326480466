import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import {debounce, finalize} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { FinancialOverview } from '../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { CaseService } from '../../case.service';
import { ProductService } from '../../product.service';
import {MiscConfigService} from '../../../config/misc-config/misc-config.service';
import {CasePaymentPlan} from '../../../../../../../_base-shared/models/Payment/CasePaymentPlan';
import {interval} from 'rxjs';
import {CasePaymentPlanService} from '../../case-payment-plan.service';
import { AppSelectOption } from 'projects/_base-shared/contracts/common.interface';

@Component({
  selector:    'app-case-outcome',
  templateUrl: './case-outcome.component.html',
  styleUrls:   ['./case-outcome.component.scss'],
})
export class CaseOutcomeComponent implements OnInit {
  public case: Case;
  public isLoading                           = 0;
  public isSubmitting: boolean;
  public submittingPaymentPlan: boolean;
  public selectedProductControl: UntypedFormControl = new UntypedFormControl(null);
  public financialOverview: FinancialOverview;
  public expectedTerm: number;
  public unsecuredCreditorsCount: number;
  public caseCreditors: Array<CaseCreditor>;
  public claimCreditorsCount: number;
  public creditorAmountBalance: number;
  public aprRate: number;
  public tinRate: number;
  public loanArrangementFee: number;
  public debtReductionFee: number;
  public totalLoaned: number;
  public serverResponse: LaravelResourceResponse;
  public form: UntypedFormGroup;
  public percentageSaved: number = 0.00;
  public isFds: boolean;

  public currentLocale: 'en' | 'es' | string;
  public products: Array<Product>               = [];
  public potentialAzcarate: Array<CaseCreditor> = [];

  public aprRateOptions: Array<any> = []

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private caseService: CaseService,
    private productService: ProductService,
    private financialOverviewService: FinancialOverviewService,
    private configService: MiscConfigService,
    private casePaymentPlanService: CasePaymentPlanService,
  ) {
  }

  ngOnInit(): void {
    this.fetchFeePercentages();
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['creditors', 'public_debts', 'product.payment_plans.payment_plan_phases', 'loan_payment_plan'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          this.getCreditorStats(this.case);
          this.fetchAprRate();
        });
    });

    this.financialOverviewService.financialOverview$.subscribe(
      overview => {
        this.financialOverview = overview;
        if (overview) {
          this.expectedTerm = overview.unsecured_debt / (overview.income - overview.expenses);
          if (!isFinite(this.expectedTerm) || Number.isNaN(this.expectedTerm)) {
            this.expectedTerm = 0;
          }
        } else {
          this.expectedTerm = 0;
        }
      },
    );

    this.currentLocale = this.cookieService.get('lang') || 'en';
    this.translate.onLangChange.subscribe(next => this.currentLocale = next.lang);
  }

  public submitPaymentPlanForm(paymentPlanForm: UntypedFormGroup): void {
    if (paymentPlanForm.invalid) {
      paymentPlanForm.markAllAsTouched();
      return;
    }

    const aprRateOption = this.aprRateOptions.find(option => option.value === this.form.get('apr_rate').value);
    console.log(aprRateOption.isFds)
    if (aprRateOption && aprRateOption.isFds) {
      paymentPlanForm.addControl('is_fds', new UntypedFormControl(true));
    } else {
      paymentPlanForm.addControl('is_fds', new UntypedFormControl(false));
    }

    this.submittingPaymentPlan = true;
    this.casePaymentPlanService.upsertPaymentPlan(this.case.id, paymentPlanForm.getRawValue())
      .pipe(finalize(() => this.submittingPaymentPlan = false))
      .subscribe(
        () => {
          this.form.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.payment.payment_plan.result.success'));
        },
        error => {
          this.form = error.error;
          this.toastr.error(this.translate.instant('CASES.editor.payment.payment_plan.result.error'),
            error.error.message);
        },
      );
  }

  private fetchAprRate(): void {
    this.isLoading++;

    this.configService.getConfigData()
      .pipe(finalize(() => this.isLoading--))
      .subscribe(response => {
        this.aprRate = response.data.find(configData => configData.key === 'apr_rate').value;
        this.loanArrangementFee = response.data.find(configData => configData.key === 'loan_arrangement_fee').value;
        this.debtReductionFee = response.data.find(configData => configData.key === 'debt_reduction_fee').value;
        this.fetchProducts();
      });
  }

  private subscribeToPaymentPlanChanges(): void {
    if(this.case.loan_payment_plan.apr_rate) {
      this.aprRate = this.case.loan_payment_plan.apr_rate;
      this.form.get('apr_rate').patchValue(this.aprRate);
    }

    this.calculatePaymentPlan();

    this.form.get('phase_one_duration').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.calculatePaymentPlan('phase_one_duration'));

    this.form.get('original_debt_amount').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.calculatePaymentPlan('original_debt'));

    this.form.get('debt_settlement_agreed').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.calculatePaymentPlan('debt_settlement_agreed'));

    this.form.get('apr_rate').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.calculateTotalLoan());
    }

  public calculatePaymentPlan(key?: string): void {
    const originalDebtAmount = this.form.get('original_debt_amount').value;
    const debtSettlementAgreed = this.form.get('debt_settlement_agreed').value;

    if (!originalDebtAmount || !debtSettlementAgreed) {
      return;
    }

    this.form.markAsPristine();
    this.calculateTotalLoan();
  }

  private calculateTotalLoan(): void {
    this.aprRate = +this.form.get('apr_rate').value;
    this.tinRate = this.calculateTinRate(this.aprRate);
    const interestRate = +this.aprRate;
    const monthlyInterestRate = +(interestRate / 1200).toFixed(10);

    const originalDebtAmount = +this.form.get('original_debt_amount').value ?? 0.00;
    const debtSettlementAgreed = +this.form.get('debt_settlement_agreed').value ?? 0.00;
    const debtReduction = originalDebtAmount - debtSettlementAgreed;
    const debtReductionFee = (debtReduction * (+this.debtReductionFee / 100));
    const newLoanAmount = debtSettlementAgreed + (+debtReductionFee * 1.21);
    const newLoanTerm = +this.form.get('phase_one_duration').value;

    this.percentageSaved = ((debtReduction/originalDebtAmount)*100);

    let monthlyPayment = +newLoanAmount * (monthlyInterestRate / (1 - Math.pow((1 + monthlyInterestRate), -newLoanTerm)));
    monthlyPayment = +monthlyPayment.toFixed(2);
    this.form.get('phase_one_monthly_fee').patchValue(monthlyPayment);
    this.totalLoaned = +(monthlyPayment * newLoanTerm).toFixed(10);
    this.form.get('phase_one_value').patchValue(newLoanAmount.toFixed(2));
    this.form.get('debt_reduction_fee').patchValue(debtReductionFee.toFixed(2));
    this.form.get('total_reduction').patchValue(debtReduction.toFixed(2));
    this.form.get('new_loan_amount').patchValue(newLoanAmount.toFixed(2));
    this.form.get('repayable_amount').patchValue(this.totalLoaned);
    this.form.get('tin_rate').patchValue(this.tinRate.toFixed(2));
  }
// this.case.unsecured_creditors
  private buildForm(casePaymentPlan: CasePaymentPlan): void {
    const debtAmount = this.caseCreditors.reduce((a, b) => a + (+b.pivot.debt_amount || 0), 0);
    const negotiatedAmount = this.caseCreditors.reduce((a, b) => a + (+b.pivot.negotiated_amount || 0), 0);
    const apr_rate_form: number = casePaymentPlan.apr_rate;
    this.form = this.fb.group({
      original_debt_amount: [casePaymentPlan && casePaymentPlan.original_debt_amount ?
        casePaymentPlan.original_debt_amount : debtAmount, [Validators.min(1), Validators.required]],
      debt_settlement_agreed: [casePaymentPlan && casePaymentPlan.debt_settlement_agreed ?
        casePaymentPlan.debt_settlement_agreed : negotiatedAmount,
        [Validators.min(0), Validators.required]],
      total_reduction:       [null, Validators.required],
      debt_reduction_fee:    [casePaymentPlan ? casePaymentPlan.debt_reduction_fee : 0, [Validators.min(0), Validators.required]],
      new_loan_amount:       [null, Validators.required],
      phase_one_duration:    [(casePaymentPlan.phase_one_duration) === 0 ? 36 : casePaymentPlan.phase_one_duration, [Validators.min(1), Validators.required]],
      phase_one_value:       [casePaymentPlan ? casePaymentPlan.phase_one_value : 0, [Validators.required]],
      phase_one_monthly_fee: [casePaymentPlan ? casePaymentPlan.phase_one_monthly_fee : 0, [Validators.required]],
      repayable_amount:      [casePaymentPlan ? casePaymentPlan.repayable_amount : 0, [Validators.required]],
      apr_rate:              [casePaymentPlan ? Number(casePaymentPlan.apr_rate) : 13.91, [Validators.required]],
      tin_rate:              [null],
    });
    this.tinRate = this.calculateTinRate(this.aprRate);
    this.form.get('tin_rate').patchValue(this.tinRate.toFixed(2));
    if(isNaN(+Number(this.case.loan_payment_plan.apr_rate))) {
      this.form.get('apr_rate').patchValue(13.91)
    }
    this.subscribeToPaymentPlanChanges();
  }
  private getCreditorStats(clientCase: Case) {
    let unsecuredCount = 0;
    let claimCount     = 0;
    let amount         = 0;
    const unsecuredCreditors = [];
    clientCase.creditors.forEach(caseCreditor => {
      if (caseCreditor.pivot.type === 'unsecured') {
        unsecuredCreditors.push(caseCreditor);
        unsecuredCount++;
      }
      if (caseCreditor.pivot.type === 'claim') {
        claimCount++;
      }
      if (caseCreditor.pivot.total_repaid > caseCreditor.pivot.initial_balance) {
        this.potentialAzcarate.push(caseCreditor);
      }
      if (caseCreditor.pivot.total_repaid >= 0 && caseCreditor.pivot.initial_balance >= 0) {
        amount += caseCreditor.pivot.initial_balance - caseCreditor.pivot.total_repaid;
      }
    });
    this.unsecuredCreditorsCount = unsecuredCount;
    this.claimCreditorsCount     = claimCount;
    this.creditorAmountBalance   = amount;
    this.caseCreditors = unsecuredCreditors;
  }

  public selectOutcome(product: Product) {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CASES.editor.outcome.request_confirmation'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translate.instant('SHARED.no'),
      confirmButtonText:  this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.selectedProductControl.patchValue(product.id);
        this.isSubmitting = true;
        this.productService.updateCaseProduct(this.case.id, {product_id: this.selectedProductControl.value})
          .pipe(finalize(() => this.isSubmitting = false))
          .subscribe(
            result => {
              this.toastr.success(this.translate.instant('CASES.editor.outcome.result.success'));
              this.case.product_id          = result.data.id;
              this.case.product_assigned_at = result.data.product_assigned_at;
            },
            error => {
              this.serverResponse = error.error;
              this.toastr.error(this.translate.instant('CASES.editor.outcome.result.error'), error.error.message);
              this.selectedProductControl.patchValue(this.case.product_id);
            },
          );
      }
    });
  }

  private getDefaultPaymentPlan(clientCase: Case): CasePaymentPlan {
    const selectedPaymentPlan = clientCase.product.payment_plans.find(
      plan => plan.is_joint === clientCase.joint_application,
    );

    if (!selectedPaymentPlan) {
      return null;
    }
    const phaseOne = selectedPaymentPlan.payment_plan_phases.find(phase => phase.phase_order === 1);

    const paymentPlan = new CasePaymentPlan();

    paymentPlan.phase_one_value = phaseOne.amount;
    paymentPlan.phase_one_monthly_fee = phaseOne.installment_amount;
    paymentPlan.phase_one_duration = phaseOne.installment_duration;

    return paymentPlan;
  }
  private fetchProducts(): void {
    this.isLoading++;
    this.productService.index({select_all: 1, active: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.products = result.data;
        this.selectedProductControl.patchValue(this.products.find(product => product.id === this.case.product_id).id);
        if ( ! this.case.loan_payment_plan) {
          this.case.loan_payment_plan = this.getDefaultPaymentPlan(this.case);
        }

        this.buildForm(this.case.loan_payment_plan);
      },
    );
  }

  public isProductSelectable(product: Product): boolean {
    return this.areProductRequirementsFulfilled(this.case, product) &&
      (this.selectedProductControl.value !== product.id || !this.case.product_assigned_at);
  }

  private areProductRequirementsFulfilled(clientCase: Case, product: Product): boolean {
    let productRequirementsFulfilled = false;
    if (product.group_slug === 'lso') {
      if (product.slug === 'lso-pp') {
        productRequirementsFulfilled = this.isProductRequirementFulfilled(clientCase, product, 1) &&
          this.isProductRequirementFulfilled(clientCase, product, 2);
        if (!productRequirementsFulfilled) {
          return productRequirementsFulfilled;
        }
      }
      if (clientCase.require_lso_qualifiers &&
        (clientCase.client.criminal_record === true || clientCase.client.spanish_resident === false ||
          clientCase.client.lso_in_five_years === true || clientCase.client.more_than_one_creditor === false)) {
        productRequirementsFulfilled = false;
      } else {
        productRequirementsFulfilled = true;
      }
    } else {
      productRequirementsFulfilled = true;
    }

    return productRequirementsFulfilled;
  }

  public isProductRequirementFulfilled(clientCase: Case, product: Product, requirement: number): boolean {
    let result             = false;
    const disposableIncome = this.financialOverview.income - this.financialOverview.expenses;
    if (product.slug === 'lso-pp') {
      if (requirement === 1) {
        result = disposableIncome >= this.financialOverview.seizable_amount;
      }

      if (requirement === 2) {
        return this.areAssetsPayable() || this.isUnsecuredDebtPayable();
      }
    }

    return result;
  }

  public areAssetsPayable() {
    return (this.financialOverview.amortisation_with_disposable_income + this.financialOverview.liquidable_assets) >
      this.financialOverview.assets;
  }

  public isUnsecuredDebtPayable() {
    return (this.financialOverview.amortisation_with_disposable_income + this.financialOverview.liquidable_assets) >
      this.financialOverview.unsecured_debt;
  }

  public calculateTinRate(aprRate: number): number {
    return (((1+aprRate/100) ** (1/12))-1)*12*100;
  }

  private fetchFeePercentages(): void {
    this.isLoading++;
    this.configService.getVariousFeePercentages().pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.aprRateOptions = result.data.map(option => {
          return {label: option.name, value: option.percentage, isFds: option.is_fds};
        });
      },
      error => console.log(error),
    );
  }
}
