import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignaturePad } from 'ngx-signaturepad/signature-pad';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../../admin/case/case.service';
import { ClientService } from '../../client.service';

@Component({
  selector:    'app-contracts-sign',
  templateUrl: './contracts-sign.component.html',
  styleUrls:   ['./contracts-sign.component.scss'],
})
export class ContractsSignComponent implements OnInit, AfterViewInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public caseUuid: any;
  public clientRole: 'client' | 'partner';
  public resign: any;
  public signaturePadOptions     = {
    minWidth:     1,
    maxWidth:     2,
    canvasWidth:  458,
    canvasHeight: 100,
  };
  public currentDate             = new Date();
  public signatureImg            = '';
  public prevSignatureImg        = '';
  public prevSignatureImgPartner = '';
  public imgPath;
  public case: Case;
  public pdfFileName             = '';
  public loading                 = false;
  public isLoadingContractPreview: boolean;
  public isLoadingMandatePreview: boolean;
  public isLoadingRightOfAccessPreview: boolean;
  public isLoadingContract       = true;
  public contractHtml: string;
  public mandateHtml: string;
  public rightOfAccessHtml: string;
  public companyGdprEmail        = environment.EMAIL_GDPR;
  public companyAddress          = environment.COMPANY_ADDRESS;
  public companyNameLegal        = environment.COMPANY_NAME_LEGAL;
  public companyName             = environment.COMPANY_NAME;

  constructor(private elRef: ElementRef,
              private caseService: CaseService,
              private clientService: ClientService,
              private router: Router,
              private route: ActivatedRoute,
              private http: HttpClient,
              private toast: ToastrService) {
    this.imgPath = environment.STORAGE_URL + '/';
  }

  ngOnInit(): void {
    this.caseUuid   = this.route.snapshot.queryParamMap.get('uuid');
    this.clientRole = this.route.snapshot.queryParamMap.get('sign') === 'client' ? 'client' : 'partner';
    this.resign     = this.route.snapshot.queryParamMap.get('resign');

    this.caseService.caseInfo(this.caseUuid)
      .pipe(finalize(() => {
        this.isLoadingContract = false;
      }))
      .subscribe(next => {
        this.case = next.data;

        if (this.case.contracts[0]?.signature) {
          this.prevSignatureImg = this.case.contracts[0].signature;
        }
        if (this.case.contracts[0]?.signature_1) {
          this.prevSignatureImgPartner = this.case.contracts[0].signature_1;
        }
        if (this.case.contracts[0]?.pdf_location) {
          const pdfLocation = this.case.contracts[0]?.pdf_location;
          this.pdfFileName  = pdfLocation.split('/')[3];
        }
        this.clientService.previewContract(this.caseUuid).pipe(finalize(() => {
          this.isLoadingContractPreview = false;
          setTimeout(() => {
            const signatureId = this.case.product.group_slug === 'dm' ?
              'signature-dm' :
              'signature-lso-group';
            this.scrollToElement(signatureId);
          }, 500);
        })).subscribe(
          result => {
            this.contractHtml = result.data;
          },
        );
        this.clientService.previewMandate(this.caseUuid, this.clientRole)
          .pipe(finalize(() => this.isLoadingMandatePreview = false))
          .subscribe(result => this.mandateHtml = result.data);
        this.clientService.previewRightOfAccess(this.caseUuid, this.clientRole)
          .pipe(finalize(() => this.isLoadingRightOfAccessPreview = false))
          .subscribe(result => this.rightOfAccessHtml = result.data);
      });

    const viewData = {
      viewed_by: '',
    };

    if (this.clientRole === 'partner') {
      viewData.viewed_by = 'partner';
    } else {
      viewData.viewed_by = 'client';
    }

    this.caseService.sendViewedTime(this.caseUuid, viewData).subscribe(obj => {
    });

  }

  ngAfterViewInit() {
    this.signaturePad.clear();
  }

  drawComplete() {
    this.signatureImg = this.signaturePad.toDataURL();
  }

  drawStart() {
  }

  public clear() {
    this.signaturePad.clear();
    this.signatureImg = '';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const div = this.elRef.nativeElement.querySelector('.signature-wrap')?.offsetWidth;
    if (div !== 0) {
      this.signaturePad.set('canvasWidth', div);
    }
  }

  scrollToElement($element): void {
    const element = document.getElementById($element);
    element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
  }

  saveSignature() {
    this.loading = true;
    const data   = new FormData();
    if (this.clientRole !== 'partner') {
      data.append('signature', this.signatureImg);
    } else {
      data.append('signature_1', this.signatureImg);
    }

    this.caseService.saveSignature(this.case.id, data).pipe(finalize(() => this.loading = false)).subscribe(
      next => {
        if (this.clientRole !== 'partner') {
          this.prevSignatureImg = next.data.signature;
        } else {
          this.prevSignatureImgPartner = next.data.signature_1;
        }
        this.toast.success('Contract signed successfully.', 'Success');

        window.location.href = 'https://www.deudafix.es/front/dist/contrato_firmado.php';
      },
      error => this.toast.error('Sign failed. Please try again later.', 'Error'),
    );
  }

}
