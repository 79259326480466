<div #signature id="signature">
  <div *ngIf="isLoadingDocumentPreview" class="d-flex justify-content-center align-items-center">
    <mat-spinner diameter="30" color="primary"></mat-spinner>
  </div>

  <app-html-shadow-dom [hidden]="isLoadingDocumentPreview" [unsafeHtml]="documentHtml"></app-html-shadow-dom>

  <div class="signature pt-6 with-logo" id="signature-LSO2"
       style="width: 210mm; margin: 0 auto; padding: 25mm">
    <div class="row">
      <div class="col-md-6">
        <ng-container *ngIf="showLawyerSignature">
          <p>Firmas</p>
          <img style="width:80%" class="img-fluid" src="/assets/img/ceo_sign2.png" alt="signature">
          <div class="pt-5">
            <p>D. Luis Miguel Díaz Simón</p>
            <p>Fdo. {{ companyNameLegal }}</p>
          </div>
        </ng-container>
      </div>
      <div class="col-md-6" id="signature-lso-group">
        <p *ngIf="!case?.joint_application"><b>Cliente:<span style="color:red">*</span></b></p>
        <p *ngIf="case?.joint_application && sign === 'client'"><b>Cliente 1:<span style="color:red">*</span></b></p>
        <div *ngIf="sign === 'client'">
          <div class="signature-area" data-toggle="modal" data-target="#sign">
            <p class="m-0" *ngIf="!prevSignatureImg && !loading || (resign && !loading)">FIRMAR AQUI</p>
            <div *ngIf="prevSignatureImg && !loading && !resign">
              <img class="img-fluid" src="{{imgPath + prevSignatureImg}}" alt="">
            </div>
            <div *ngIf="loading">
              <mat-spinner class=""></mat-spinner>
            </div>
          </div>
          <p class="m-0">{{ case?.client.first_name}} {{ case?.client.last_name}}</p>
          <p class="m-0">DNI: {{ case?.client?.id_card}}</p>
        </div>
        <div *ngIf="sign === 'partner'">
          <div *ngIf="case?.joint_application">
            <p class="pt-3"><b>Cliente 2:<span style="color:red">*</span></b></p>
            <div class="signature-area" data-toggle="modal" data-target="#sign">
              <p class="m-0" *ngIf="!prevSignatureImgPartner && !loading || (resign && !loading)">FIRMAR AQUI</p>
              <div *ngIf="prevSignatureImgPartner && !loading && !resign">
                <img class="img-fluid" src="{{imgPath + prevSignatureImgPartner}}" alt="">
              </div>
              <div *ngIf="loading">
                <mat-spinner class=""></mat-spinner>
              </div>
            </div>
            <p class="m-0">{{ case?.partner?.first_name}} {{ case?.partner?.last_name}}</p>
            <p class="m-0">DNI: {{ case?.partner?.id_card}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Signature modal -->
  <div class="modal fade" id="sign" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Sign contract</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div (window:resize)="onResize()" class="signature-wrap">
            <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()">
            </signature-pad>
          </div>
        </div>
        <div class="modal-footer">
          <button mat-raised-button color="primary" type="button" class="mr-auto" (click)="clear()">
            Clear signature
          </button>
          <button mat-stroked-button color="primary" class="mr-3" type="button" data-dismiss="modal">Close</button>
          <button mat-raised-button color="primary" type="button" data-dismiss="modal" (click)="saveSignature()">
            Save signature
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
