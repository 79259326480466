import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { User } from '../../../../../_base-shared/models/User/User';
import { CaseDocumentService } from '../../admin/case/case-document.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from '../../admin/case/case.service';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { ClientService } from '../client.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector   : 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls  : ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {

  public requiredFilesArray = [];
  public creditorsRequiredFilesArray = [];
  public bankRequiredFilesArray = [];
  public storageUrl         = environment.STORAGE_URL + '/';
  public labels: {}         = {};
  public uuId: any;
  public files              = {};
  public case;
  public user: User;
  public userFirstName      = '';
  public uploadedFiles      = [];
  public uploadedFilesPartner      = [];
  public creditorFiles      = [];
  public creditorFilesPartner      = [];
  public creditorFilesFiltered      = [];
  public personalFilesFiltered      = [];
  public bankFiles      = [];
  public bankFilesPartner      = [];
  public bankFilesFiltered      = [];
  public publicDebtFiles      = [];
  public publicDebtFilesPartner      = [];
  public isLoadingUploaded = 0;
  public loading            = 0;
  public lang = 'es';
  public uploader = 'client';
  public resendId;
  public allFiles   = new BehaviorSubject<any>([]);
  public allFiles$   = this.allFiles.asObservable();
  public dniNotApproved = [];

  constructor(private fb: UntypedFormBuilder,
              private caseService: CaseService,
              private cookieService: CookieService,
              private documentService: CaseDocumentService,
              private route: ActivatedRoute,
              private globalEventsService: MainGlobalEventService,
              private clientService: ClientService,
              private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.lang = this.cookieService.get('lang');
    //  Get params
    this.uuId = this.route.snapshot.queryParamMap.get('uuid');
    //  Check if there is resend request
    if (this.route.snapshot.queryParamMap.get('resend_id')) {
      this.resendId = this.route.snapshot.queryParamMap.get('resend_id');
    }
    if ( this.route.snapshot.queryParamMap.get('uploaded_by')) {
      this.uploader = this.route.snapshot.queryParamMap.get('uploaded_by');
    }
    //  If user gets here by logging in
    if (!this.uuId) {
      //  Get logged user so we can get case with user id
      this.getUser();
    } else {
      //  If user gets here by uuid
      this.getRequiredFiles(this.uuId);
      this.getDocumentsWithUuid();
    }

    this.allFiles$.subscribe(next => {
      this.setUploadedDocuments(next);
    });
  }

  getDocumentsWithUuid() {
    this.isLoadingUploaded++;
    this.caseService.caseInfo(this.uuId)
      .pipe(finalize(() => this.isLoadingUploaded--))
      .subscribe(
        res => {
          this.case = res.data;
          if (this.uploader === 'partner') {
            this.userFirstName = this.case.partner?.first_name;
            this.requiredFilesArray = this.case.partner_file_requests;
          }
          this.creditorsRequiredFiles(this.case);
          this.bankRequiredFiles(this.case);
          this.setUploadedDocuments(res);
        },
        err => {
          console.log(err);
        },
      );
  }

  generateCssClasses(requiredFiles, uploadedFiles) {
    //  Check if there are uploaded files for each file requested
    requiredFiles.map(file => {
      const files = file.entity ? uploadedFiles[file.entity.name] :  uploadedFiles[file.name];
      if (files) {
        let status = '';
        files.map(document => {
          if (status !== 'pending' && status !== 'declined') {
            status = document.status;
          }
        });
        if (status === 'pending') {
          return file.hasClass = 'pending-document';
        } else if (status === 'accepted') {
          return file.hasClass = 'accepted-document';
        } else if (status === 'declined') {
          return file.hasClass = 'declined-document';
        } else {
          return '';
        }
      }
    });
  }

  setUploadedDocuments(res) {
    //  Get all files fro client and partner
    this.uploadedFiles = res.data?.client_files_by_type;
    this.uploadedFilesPartner = res.data?.partner_files_by_type;
    this.creditorFiles = res.data?.files_by_creditor;
    this.creditorFilesPartner = res.data?.partner_files_by_creditor;
    this.publicDebtFiles = res.data?.files_by_public_debt;
    this.publicDebtFilesPartner = res.data?.partner_files_by_public_debt;
    this.bankFiles = res.data?.client_files_by_bank;
    this.bankFilesPartner = res.data?.partner_files_by_bank;
    //  Check who is uploading files and set what files to show
    if (this.uploader === 'client') {
      //  Add classes to personal documents used for CSS styling
      this.generateCssClasses(this.requiredFilesArray, this.uploadedFiles);
      //  Add classes to creditor documents used for CSS styling
      this.generateCssClasses(this.creditorsRequiredFilesArray, this.creditorFiles);
      //  Add classes to bank documents used for CSS styling
      this.generateCssClasses(this.bankRequiredFilesArray, this.bankFiles);

      //  Generate one object from personal files
      this.personalFilesFiltered = {...this.uploadedFiles};
      if (Object.keys(this.personalFilesFiltered).length === 0) {
        this.personalFilesFiltered = [];
      }

      //  Generate one object from creditor files and public debt files
      this.creditorFilesFiltered = {...this.creditorFiles, ...this.publicDebtFiles};
      if (Object.keys(this.creditorFilesFiltered).length === 0) {
        this.creditorFilesFiltered = [];
      }

      //  Generate one object from bank files
      this.bankFilesFiltered = {...this.bankFiles};
      if (Object.keys(this.bankFilesFiltered).length === 0) {
        this.bankFilesFiltered = [];
      }
    } else {
      //  Add classes to personal documents used for CSS styling
      this.generateCssClasses(this.requiredFilesArray, this.uploadedFilesPartner);
      //  Add classes to creditor documents used for CSS styling
      this.generateCssClasses(this.creditorsRequiredFilesArray, this.creditorFilesPartner);
      //  Add classes to bank documents used for CSS styling
      this.generateCssClasses(this.bankRequiredFilesArray, this.bankFilesPartner);

      //  Generate one object from personal files
      this.personalFilesFiltered = {...this.uploadedFilesPartner};
      if (Object.keys(this.personalFilesFiltered).length === 0) {
        this.personalFilesFiltered = [];
      }

      //  Generate one object from creditor files and public debt files
      this.creditorFilesFiltered = {...this.creditorFilesPartner, ...this.publicDebtFilesPartner};
      if (Object.keys(this.creditorFilesFiltered).length === 0) {
        this.creditorFilesFiltered = [];
      }

      //  Generate one object from bank files
      this.bankFilesFiltered = {...this.bankFilesPartner};
      if (Object.keys(this.bankFilesFiltered).length === 0) {
        this.bankFilesFiltered = [];
      }
    }

    //  Check if dni is approved
    // @ts-ignore
    if (this.personalFilesFiltered.dni?.length) {
      // @ts-ignore
      this.dniNotApproved = this.personalFilesFiltered.dni?.filter(file => file.status !== 'accepted');
    }
  }

  creditorsRequiredFiles(data) {
    // const allApprovedCreditors = data.creditors.filter(creditor => creditor.pivot?.verified);
    // const allPublicDebts = data.public_debts.filter(debt => debt.verified);
    const allApprovedCreditors = data.creditors;
    const allPublicDebts = data.public_debts;
    //  Format and add name property
    allPublicDebts.map(obj => {
      if (obj.public_organisation) {
        obj.name = obj.public_organisation.replace('-', ' ').toUpperCase();
      }
    });

    this.creditorsRequiredFilesArray = allApprovedCreditors.concat(allPublicDebts);
  }

  bankRequiredFiles(data) {
    this.bankRequiredFilesArray = data.assets.filter(asset => asset.type === 'bank_accounts');

    //  Filter out accounts that are in the same bank (remove duplicate)
    this.bankRequiredFilesArray = this.bankRequiredFilesArray.filter((bank, index, arr) =>
      index === arr.findIndex(t => (t.entity.id === bank.entity.id)));
  }

  getUser() {
    this.globalEventsService.authUser$
      .subscribe(user => {
        this.user = user;
        this.getUserCase(user.id);
      });
  }

  getUserCase(id) {
    this.loading++;
    this.caseService.index({user_id: id})
      .pipe(
        finalize(() => this.loading--)
      )
      .subscribe(
        res => {
          this.case = res.data[0];
          //  Check if logged user is client or partner
          if (this.case.client.email === this.user.email) {
            this.uploader = 'client';
          } else {
            this.uploader = 'partner';
          }
          this.uuId = res.data[0].uuid;
          //  Get files already uploaded by client
          this.getUploadedFiles(res.data[0].id);
          //  Get requested files
          this.getRequiredFiles(this.uuId);
        },
        err => {
          console.log(err);
        },
      );
  }

  getUploadedFiles(caseId) {
    this.loading++;
    this.clientService.getClientDocuments(caseId)
      .pipe(
        finalize(() => this.loading--)
      )
      .subscribe(
        res => {
          this.setUploadedDocuments(res);
        },
        err => {
          console.log(err);
        }
      );
  }

  getRequiredFiles(uuId) {
    //  Get files needed for uploading and build form with those inputs
    this.documentService.getRequiredFiles(uuId)
      .subscribe(files => {
        if (this.uploader === 'client') {
          this.userFirstName = files.data.case_user?.name.split(' ')[0];
          this.requiredFilesArray = files.data.requested_files;
        }
      });
  }

  getDocumentStatus(documents) {
    let status = '';
    documents.map(document => {
      if (status !== 'pending' && status !== 'declined') {
        status = document.status;
      }
    });
    if (status === 'pending') {
      return 'pending-document';
    } else if (status === 'accepted') {
      return 'accepted-document';
    } else {
      return 'declined-document';
    }
  }

  formatFileType(name) {
    if (name === 'social-security' || name === 'estate' || name === 'town-hall') {
      return this.translate.instant('CASE_CREDITOR.model.public_organization.options.' + name);
    }

    return name;
  }

}
