import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../../_shared/shared.module';
import { DocumentComponent } from '../document/document.component';
import { AppDocumentModule } from '../app-document/app-document.module';
import { TaskModule } from '../task/task.module';
import { UserModule } from '../user/user.module';
import { CaseBulkActionModalComponent } from './case-bulk-action-modal/case-bulk-action-modal.component';
import { CaseDashboardComponent } from './case-dashboard/case-dashboard.component';
import { AdditionalInputComponent } from './case-detail/case-creditor-list/additional-input/additional-input.component';
import { CaseCreditorListComponent } from './case-detail/case-creditor-list/case-creditor-list.component';
import {
  CaseDepartmentUserEditorComponent,
} from './case-detail/case-detail-general/case-department-user-editor/case-department-user-editor.component';
import { CaseGeneralDetailComponent } from './case-detail/case-detail-general/case-general-detail.component';
import {
  CaseQuickClientEditorComponent,
} from './case-detail/case-detail-general/case-quick-client-editor/case-quick-client-editor.component';
import {
  CaseAepListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-aep-list/case-aep-list.component';
import {
  CaseContractListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-contract-list/case-contract-list.component';
import {
  CaseCreditorNoteListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-creditor-note-list/case-creditor-note-list.component';
import {
  CaseNotesComponent,
} from './case-detail/case-detail-general/case-relation-list/case-notes/case-notes.component';
import {
  CaseNotificationListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-notification-list/case-notification-list.component';
import {
  CasePaymentStatusLogComponent,
} from './case-detail/case-detail-general/case-relation-list/case-payment-status-log/case-payment-status-log.component';
import {
  CaseProposalListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-proposal-list/case-proposal-list.component';
import {
  CaseRelationListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-relation-list.component';
import {
  CaseStatusLogComponent,
} from './case-detail/case-detail-general/case-relation-list/case-status-log/case-status-log.component';
import {
  CaseTaskListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-task-list/case-task-list.component';
import {
  CaseStatusEditorComponent,
} from './case-detail/case-detail-general/case-status-editor/case-status-editor.component';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import { CaseDocumentListNewComponent } from './case-detail/case-document-list-new/case-document-list-new.component';
import {
  DocumentRequestListComponent,
} from './case-detail/case-document-list-new/document-request-list/document-request-list.component';
import {
  RequestedDocumentUploaderComponent,
} from './case-detail/case-document-list-new/requested-document-uploader/requested-document-uploader.component';
import { AllBankFilesComponent } from './case-detail/case-document-list/all-bank-files/all-bank-files.component';
import { AllCourtFilesComponent } from './case-detail/case-document-list/all-court-files/all-court-files.component';
import {
  AllCreditorFilesComponent,
} from './case-detail/case-document-list/all-creditor-files/all-creditor-files.component';
import { AllFilesComponent } from './case-detail/case-document-list/all-files/all-files.component';
import { AllLegalFilesComponent } from './case-detail/case-document-list/all-legal-files/all-legal-files.component';
import { CaseDocumentListComponent } from './case-detail/case-document-list/case-document-list.component';
import { RequestComponent } from './case-detail/case-document-list/request/request.component';
import { RequiredComponent } from './case-detail/case-document-list/required/required.component';
import { StatisticsComponent } from './case-detail/case-document-list/statistics/statistics.component';
import { AppointCourtModalComponent } from './case-detail/case-draft/appoint-court-modal/appoint-court-modal.component';
import {
  AppointedCourtEditorComponent,
} from './case-detail/case-draft/appointed-court-editor/appointed-court-editor.component';
import { CaseAepCreatorComponent } from './case-detail/case-draft/case-aep-creator/case-aep-creator.component';
import { CaseDraftComponent } from './case-detail/case-draft/case-draft.component';
import { DemandGeneratorComponent } from './case-detail/case-draft/demand-generator/demand-generator.component';
import {
  AdministratorModalComponent
} from './case-detail/case-draft/administrator-modal/administrator-modal.component';
import { LsoGeneratorComponent } from './case-detail/case-draft/lso-generator/lso-generator.component';
import { NotaryModalComponent } from './case-detail/case-draft/notary-modal/notary-modal.component';
import { ProposalModalComponent } from './case-detail/case-draft/proposal-modal/proposal-modal.component';
import { SolicitorModalComponent } from './case-detail/case-draft/solicitor-modal/solicitor-modal.component';
import {
  CaseInstallmentAmountEditorComponent,
} from './case-detail/case-payment/case-installment-amount-editor/case-installment-amount-editor.component';
import {
  CaseInstallmentListComponent,
} from './case-detail/case-payment/case-installment-list/case-installment-list.component';
import {
  RecordPaymentModalComponent,
} from './case-detail/case-payment/case-installment-list/record-payment-modal/record-payment-modal.component';
import { CasePaymentListComponent } from './case-detail/case-payment/case-payment-list/case-payment-list.component';
import {
  EditHistoryModalComponent,
} from './case-detail/case-payment/case-payment-list/edit-history-modal/edit-history-modal.component';
import {
  CasePaymentPlanGeneratorComponent,
} from './case-detail/case-payment/case-payment-plan-generator/case-payment-plan-generator.component';
import {
  CasePaymentRequestListComponent,
} from './case-detail/case-payment/case-payment-request-list/case-payment-request-list.component';
import { CasePaymentComponent } from './case-detail/case-payment/case-payment.component';
import {
  CaseTransactionListComponent,
} from './case-detail/case-payment/case-transaction-list/case-transaction-list.component';
import {
  RebuildPaymentPlanComponent,
} from './case-detail/case-payment/rebuild-payment-plan/rebuild-payment-plan.component';
import { QuickNoteComponent } from './case-detail/quick-note/quick-note.component';
import { CaseEditorPrefillComponent } from './case-editor-prefill/case-editor-prefill.component';
import { CaseAssetEditorComponent } from './case-editor/case-asset-editor/case-asset-editor.component';
import { CaseCreditorEditorComponent } from './case-editor/case-creditor-editor/case-creditor-editor.component';
import { CaseEditorComponent } from './case-editor/case-editor.component';
import { CaseExpenseEditorComponent } from './case-editor/case-expense-editor/case-expense-editor.component';
import { CaseFinanceOverviewComponent } from './case-editor/case-finance-summary/case-finance-overview.component';
import { CaseGeneralEditorComponent } from './case-editor/case-general-editor/case-general-editor.component';
import { CaseIncomeEditorComponent } from './case-editor/case-income-editor/case-income-editor.component';
import { CaseOutcomeComponent } from './case-editor/case-outcome/case-outcome.component';
import {
  CaseIdCardEditorComponent,
} from './case-editor/case-payment-editor/case-id-card-editor/case-id-card-editor.component';
import {
  CasePaymentEditorComponent as CasePaymentEditorComponent,
} from './case-editor/case-payment-editor/case-payment-editor.component';
import { CaseUserMergeComponent } from './case-editor/case-payment-editor/case-user-merge/case-user-merge.component';
import { CaseListFiltersComponent } from './case-list/case-list-filters/case-list-filters.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CaseRoutingModule } from './case-routing.module';
import {
  CaseUserPasswordModalComponent
} from './case-detail/case-detail-general/case-user-password-modal/case-user-password-modal.component';
import {
  CaseCallStatusLogComponent
} from './case-detail/case-detail-general/case-relation-list/case-call-status-log/case-call-status-log.component';
import {
  CaseUserQualifyingDetailsComponent
} from './case-detail/case-detail-general/case-user-qualifying-details/case-user-qualifying-details.component';
import { FloatingNoteComponent } from './case-detail/floating-note/floating-note.component';

@NgModule({
  declarations: [
    CaseListComponent,
    CaseEditorComponent,
    CaseGeneralEditorComponent,
    CaseCreditorEditorComponent,
    CaseAssetEditorComponent,
    CaseIncomeEditorComponent,
    CaseExpenseEditorComponent,
    CaseOutcomeComponent,
    CasePaymentEditorComponent,
    CaseDetailComponent,
    CaseGeneralDetailComponent,
    CaseDocumentListComponent,
    StatisticsComponent,
    RequestComponent,
    AllFilesComponent,
    RequiredComponent,
    CasePaymentListComponent,
    RecordPaymentModalComponent,
    CaseNotesComponent,
    CaseInstallmentListComponent,
    EditHistoryModalComponent,
    CaseCreditorNoteListComponent,
    CaseStatusLogComponent,
    CasePaymentStatusLogComponent,
    CaseFinanceOverviewComponent,
    AllCreditorFilesComponent,
    AllLegalFilesComponent,
    CaseTransactionListComponent,
    CaseCreditorListComponent,
    AdditionalInputComponent,
    CaseDraftComponent,
    ProposalModalComponent,
    CaseContractListComponent,
    RebuildPaymentPlanComponent,
    CaseTaskListComponent,
    CaseEditorPrefillComponent,
    CaseProposalListComponent,
    CaseListFiltersComponent,
    QuickNoteComponent,
    AdministratorModalComponent,
    CaseNotificationListComponent,
    CaseDashboardComponent,
    AllBankFilesComponent,
    CaseAepCreatorComponent,
    NotaryModalComponent,
    CaseAepListComponent,
    AllCourtFilesComponent,
    CasePaymentRequestListComponent,
    DocumentRequestListComponent,
    CaseDepartmentUserEditorComponent,
    CaseIdCardEditorComponent,
    CaseRelationListComponent,
    CaseStatusEditorComponent,
    CaseQuickClientEditorComponent,
    CaseDocumentListNewComponent,
    RequestedDocumentUploaderComponent,
    CasePaymentComponent,
    AppointCourtModalComponent,
    AppointedCourtEditorComponent,
    CasePaymentPlanGeneratorComponent,
    DemandGeneratorComponent,
    CaseBulkActionModalComponent,
    CaseUserMergeComponent,
    SolicitorModalComponent,
    CaseInstallmentAmountEditorComponent,
    LsoGeneratorComponent,
    CaseUserPasswordModalComponent,
    CaseCallStatusLogComponent,
    CaseUserQualifyingDetailsComponent,
    FloatingNoteComponent,
    DocumentComponent
  ],
  imports:      [
    SharedModule,
    MatMenuModule,
    TaskModule,
    ImageCropperModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    UserModule,
    AppDocumentModule,
    DragDropModule,
    CaseRoutingModule,
    MatAutocompleteModule,
  ],
  exports:      [
    AllFilesComponent,
    CaseInstallmentListComponent,
    CasePaymentListComponent,
    CaseTransactionListComponent,
    CasePaymentRequestListComponent,
  ],
})
export class CaseModule {
}
