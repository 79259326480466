import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Affiliate } from '../../../../../../_base-shared/models/Affiliate';
import { AffiliateProduct } from '../../../../../../_base-shared/models/AffiliateProductPivot';
import { Product } from '../../../../../../_base-shared/models/Product';
import { Status } from '../../../../../../_base-shared/models/Status/Status';
import { User } from '../../../../../../_base-shared/models/User/User';
import { ProductService } from '../../case/product.service';
import { StatusService } from '../../status/status.service';
import { UserService } from '../../user/user.service';
import { AffiliateService } from '../affiliate.service';

@Component({
  selector:    'app-affiliate-editor',
  templateUrl: './affiliate-editor.component.html',
  styleUrls:   ['./affiliate-editor.component.scss'],
})
export class AffiliateEditorComponent implements OnInit, OnDestroy {
  public editorType;
  public form: UntypedFormGroup;
  public statuses: Array<Status>                    = [];
  public products: Array<Product>                   = [];
  public affiliateProducts: Array<AffiliateProduct> = [];
  public affiliateUsers: Array<User>;

  public isLoading                           = 0;
  public affiliate: Affiliate                = null;
  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private toastr: ToastrService,
              private affiliateService: AffiliateService,
              private statusService: StatusService,
              private translate: TranslateService,
              private productService: ProductService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;

      if (this.editorType === 'create') {
        this.affiliate                                   = new Affiliate();
        this.affiliate.active                            = true;
        this.affiliate.allow_without_debt                = true;
        this.affiliate.allow_under_5000                  = false;
        this.affiliate.do_not_allow_overwrite            = false;
        this.affiliate.allow_duplicates                  = false;
        this.affiliate.is_df_affiliate                   = false;
        this.affiliate.allow_overwrite_df_affiliates     = false;
        this.affiliate.allow_overwrite_non_df_affiliates = false;
        this.fetchProducts();
      }

      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          this.fetchAffiliate(+params.get('id'));
        });
      }
    });
    this.fetchAffiliateUsers();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  fetchAffiliate(affiliateId: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.get(affiliateId)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.affiliate = result.data;
          this.fetchAffiliateProducts(affiliateId);
        }),
    );
  }

  private fetchStatuses() {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.index({all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.statuses = result.data;
          this.buildForm();
        },
      ),
    );
  }

  private fetchProducts() {
    this.isLoading++;
    this.subscriptions.push(
      this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.products = result.data;
          this.fetchStatuses();
        },
      ),
    );
  }

  private fetchAffiliateProducts(affiliateId: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.getProducts(affiliateId).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.affiliateProducts = result.data;
          this.fetchProducts();
        },
      ),
    );
  }

  buildForm() {

    this.form = this.formBuilder.group({
      overload_status_id:                  [this.affiliate.overload_status_id, []],
      overwrite_cliente_regreso_status_id: [this.affiliate.overwrite_cliente_regreso_status_id, []],
      track_id:                            [this.affiliate.track_id, []],
      name:                                [this.affiliate.name, []],
      active:                              [this.affiliate.active, []],
      allow_without_debt:                  [this.affiliate.allow_without_debt, []],
      allow_under_5000:                    [this.affiliate.allow_under_5000, []],
      allow_duplicates:                    [this.affiliate.allow_duplicates, []],
      do_not_allow_overwrite:              [this.affiliate.do_not_allow_overwrite, []],
      is_df_affiliate:                     [this.affiliate.is_df_affiliate, []],
      allow_overwrite_df_affiliates:       [this.affiliate.allow_overwrite_df_affiliates, []],
      allow_overwrite_non_df_affiliates:   [this.affiliate.allow_overwrite_non_df_affiliates, []],
      is_packager:                         [this.affiliate.is_packager, []],
      postback_url:                        [this.affiliate.postback_url, []],
      user:                                this.formBuilder.group({
        first_name:                 [this.affiliate.user?.first_name, []],
        last_name:                  [this.affiliate.user?.last_name, []],
        email:                      [this.affiliate.user?.email, []],
        send_affiliate_login_email: [this.affiliate.user?.send_affiliate_login_email, []],
      }),
      lead_tracking:                       this.formBuilder.array([]),
      user_ids:                     [this.affiliate.users?.map(user => user.id), []],
    });

    this.addProducts();
  }

  addProducts(): void {
    this.products.map(product => {
      let affiliateProduct = this.affiliateProducts.find(affiliateP => affiliateP.pivot.product_id === product.id);
      if (typeof affiliateProduct !== 'undefined') {
        this.addProduct(affiliateProduct);
      } else {
        affiliateProduct         = new AffiliateProduct();
        affiliateProduct.name_es = product.name_es;
        affiliateProduct.id      = product.id;
        this.addProduct(affiliateProduct);
      }
    });
  }

  addProduct(product: AffiliateProduct) {
    const formArray = this.form.get('lead_tracking') as UntypedFormArray;

    const formGroup = this.formBuilder.group({
      product_id:    [product.id],
      name:          [product.name_es],
      cost:          [product?.pivot?.cost || null],
      status_id:     [product?.pivot?.status_id || null],
      tracking_type: [product?.pivot?.tracking_type || null],
    });
    formArray.push(formGroup);
  }

  public getFromArray() {
    return this.form.get('lead_tracking') as UntypedFormArray;
  }

  onSubmit() {
    const formData = this.form.value;

    if (formData.overload_status_id === 'null') {
      formData.overload_status_id = null;
    }

    if (formData.postback_url) {
      formData.postback_url = encodeURI(formData.postback_url);
    }

    if (this.editorType === 'create') {
      this.affiliateService.store(formData).subscribe(
        res => this.handleSuccessResponse(res),
        error => this.handleErrorResponse(error),
      );
    }

    if (this.editorType === 'edit') {
      this.affiliateService.update(this.affiliate.id, formData).subscribe(
        res => this.handleSuccessResponse(res),
        error => this.handleErrorResponse(error),
      );
    }
  }

  handleErrorResponse(error) {
    console.log(error);

    if (this.editorType === 'create') {
      this.toastr.error(
        this.translate.instant('AFFILIATES.add-new-error'),
        this.translate.instant('SHARED.error'),
      );
    }

    if (this.editorType === 'edit') {
      this.toastr.error(
        this.translate.instant('AFFILIATES.edit-aff-error'),
        this.translate.instant('SHARED.error'),
      );
    }
  }

  handleSuccessResponse(response) {
    if (response.code === 200) {
      this.router.navigateByUrl('/affiliates');

      if (this.editorType === 'create') {
        this.toastr.success(
          this.translate.instant('AFFILIATES.add-new-success'),
          this.translate.instant('SHARED.success'),
        );
      }

      if (this.editorType === 'edit') {
        this.toastr.success(
          this.translate.instant('AFFILIATES.edit-aff-success'),
          this.translate.instant('SHARED.success'),
        );
      }
    }
  }

  addVariable($event) {
    const content = this.form.get('postback_url').value || '';
    this.form.get('postback_url').setValue(content + $event.target.innerText + ' ');
  }

  private fetchAffiliateUsers() {
    this.isLoading++;
    this.userService.index({select_all: 1, role_slugs: ['affiliate']})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result =>  this.affiliateUsers = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }
}
