// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
import { EnvironmentInterface } from './EnvironmentInterface';

export const environment: EnvironmentInterface = {
  APP_NAME:              'Episys',
  APP_ENV:               'production',
  production:            true,
  devMode:               false,
  API_DOMAIN:            'api.epifinance.es',
  API_SECURE_PROTOCOL:   true,
  API_URL:               'https://api.epifinance.es',
  STORAGE_URL:           'https://api.epifinance.es/storage',
  APP_URL:               'https://app.epifinance.es',
  GENERAL_SITE_URL:      'https://epifinance.es',
  CLIENT_APP_URL:        'https://clientes.epifinance.es',
  REFRESH_NOTIFICATIONS: true,
  DISTRIBUTION_USER_IDS: [1],

  COMPANY_NAME:        'Epifinance',
  COMPANY_NAME_LEGAL:  'EPI Legal Finance S.L',
  COMPANY_ID_CIF:      'B88315890',
  COMPANY_ADDRESS:     'Av. de Brasil, 6, 1º 28020 Madrid',
  COMPANY_BANK_NAME:   'Caixabank',
  COMPANY_BANK_IBAN:   'ES06 2100 4910 3613 0024 6367',
  COMPANY_PHONE_LEGAL: '910 053 393',
  VAT_PERCENTAGE:      21,

  EMAIL_INFO:                 'info@novomoney.com',
  EMAIL_LEGAL:                'legal@novomoney.com',
  EMAIL_ADMINISTRATION:       'administracion@novomoney.com',
  EMAIL_CANCELLATIONS:        'cancelaciones@novomoney.com',
  EMAIL_CONTRACT_CONTACT:     'contract@novomoney.com',
  EMAIL_SUPPORTING_DOCUMENTS: 'justificantes@novomoney.com',
  EMAIL_GDPR:                 'dataprotectionofficer@novomoney.com',

  GOOGLE_ANALYTICS_CODE:    '',
  GOOGLE_ANALYTICS_ENABLED: false,
};
