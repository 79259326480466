<div class="documents-wrap client-typography" [ngClass]="{'mat-card-fixed-width mt-lg': desktopVersion}">
  <mat-card [ngStyle]="{'height': desktopVersion ? '240px' : ''}">
    <div style="justify-content: left">
      <p class="text-muted d-flex flex-row align-items-center">
        <mat-icon aria-hidden="false" fontIcon="work" style="margin-right: 9px">insert_drive_file</mat-icon>
        {{'CLIENT.client-dashboard.documents' | translate}}</p>
    </div>
    <mat-card-content>
      <div class="row mt-3">
        <div class="col">
          <h4>{{'CLIENT.client-dashboard.documents_outstanding' | translate}}</h4>
          <div class="mt-2"
               [ngStyle]="{'display': desktopVersion ? 'block' : 'none' }
          ">
          <span class="text-muted"
                style="font-family: 'Poppins';font-size: 12px;">{{'CLIENT.client-dashboard.uploaded' | translate}}</span>
          <br>
          <span style="font-family: 'Inter';font-size: 16px;">{{uploadedDocs}}/{{requestedDocs}}</span>
        </div>
      </div>
</div>
<!-- Temp action -->
<mat-card-actions>
  <button class="ml-2 onboarding-documents-btn" [ngStyle]="{'margin-top': desktopVersion ? '28px' : ''}"
          [routerLink]="['/client', 'cases', case.uuid, 'users', user.uuid, 'document-upload']">
    {{'CLIENT.client-dashboard.upload_documents' | translate}}
  </button>
</mat-card-actions>
</mat-card-content>
</mat-card>
</div>
