<div class="text-right">
  <!--<div class="dropleft">
    <button style="min-width: 120px" [hidden]="requestingDocuments"
            mat-raised-button color="primary" class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{"SHARED.save" | translate}}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" (click)="requestDocuments($event,'email')" href="#">Email</a>
      <a class="dropdown-item" (click)="requestDocuments($event,'sms')" href="#">SMS</a>
      <a class="dropdown-item" (click)="requestDocuments($event, 'all')" href="#">Email & SMS</a>
      <a class="dropdown-item" (click)="requestDocuments($event, 'none')"
         href="#">{{"CASES.single.none" | translate}}</a>
    </div>
  </div>-->
  <!--  <button mat-raised-button color="primary" style="min-width: 120px" *ngIf="!requestingDocuments"-->
  <!--          (click)="requestDocuments()">{{"SHARED.save" | translate}}</button>-->
  <div class="spinner-container p-2">
    <mat-spinner *ngIf="requestingDocuments" diameter="30"></mat-spinner>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <div>
      <h3 class="text-center text-primary pb-5" style="text-decoration: underline"><b>DOCUMENTACIÓN INICIAL</b></h3>
      <h4 class="m-0 text-primary"><b>DOCUMENTACIÓN PERSONAL</b></h4>
      <mat-selection-list>
        <mat-list-option *ngFor="let type of initialDocuments.personal" (click)="addOrRemoveDocument(type)"
                         [selected]="shouldBeChecked(type)">
          {{type.label}}
        </mat-list-option>
      </mat-selection-list>
    </div>

   <div class="pt-4">
     <h4 class="m-0 text-primary"><b>DOCUMENTACIÓN INGRESOS, GASTOS Y BIENES</b></h4>
     <mat-selection-list>
       <mat-list-option *ngFor="let type of initialDocuments.income" (click)="addOrRemoveDocument(type)"
                        [selected]="shouldBeChecked(type)">
         {{type.label}}
       </mat-list-option>
     </mat-selection-list>
   </div>

   <div class="pt-4">
     <h4 class="m-0 text-primary"><b>DOCUMENTACIÓN FINANCIERA</b></h4>
     <mat-selection-list>
       <mat-list-option *ngFor="let type of initialDocuments.financial" (click)="addOrRemoveDocument(type)"
                        [selected]="shouldBeChecked(type)">
         {{"CASES.single.document-types." + type.name | translate}}
       </mat-list-option>
     </mat-selection-list>
   </div>

  </div>
 <div class="col-lg-6">
   <div>
     <h3 class="text-center text-primary pb-5" style="text-decoration: underline">
       <b>DOCUMENTACIÓN A REMITIR EN LOS DOS MESES ANTERIORES A ACUDIR A NOTARÍA</b>
     </h3>
     <h4 class="m-0 text-primary"><b>DOCUMENTACIÓN PERSONAL</b></h4>
     <mat-selection-list>
       <mat-list-option *ngFor="let type of expiryDocuments.personal" (click)="addOrRemoveDocument(type)"
                        [selected]="shouldBeChecked(type)">
         {{"CASES.single.document-types." + type.name | translate}}
       </mat-list-option>
     </mat-selection-list>
   </div>

   <div class="pt-4">
     <h4 class="m-0 text-primary"><b>DOCUMENTACIÓN INGRESOS, GASTOS Y BIENES</b></h4>
     <mat-selection-list>
       <mat-list-option *ngFor="let type of expiryDocuments.income" (click)="addOrRemoveDocument(type)"
                        [selected]="shouldBeChecked(type)">
         {{"CASES.single.document-types." + type.name | translate}}
       </mat-list-option>
     </mat-selection-list>
   </div>

   <div class="pt-4">
     <h4 class="m-0 text-primary"><b>DOCUMENTACIÓN FINANCIERA</b></h4>
     <mat-selection-list>
       <mat-list-option *ngFor="let type of expiryDocuments.financial" (click)="addOrRemoveDocument(type)"
                        [selected]="shouldBeChecked(type)">
         {{"CASES.single.document-types." + type.name | translate}}
       </mat-list-option>
     </mat-selection-list>
   </div>

   <div class="pt-4">
     <div class="d-flex justify-content-between align-items-center">
       <h4 class="m-0 text-primary"><b>CUSTOM DOCUMENTS</b></h4>
       <div class="d-flex">
         <input type="text" class="form-control" [formControl]="customDocName">
         <button style="width: 100%" mat-button (click)="addCustomDocument($event)">+ Add Document</button>
       </div>
     </div>
     <mat-selection-list>
       <div class="d-flex align-items-center" *ngFor="let type of expiryDocuments.custom">
         <mat-list-option (click)="addOrRemoveDocument(type)" [selected]="shouldBeChecked(type)">
           {{type.name}}
         </mat-list-option>
         <button mat-icon-button color="warn" *ngIf="type.type === 'new-custom'">
           <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">remove_circle
           </mat-icon>
         </button>
       </div>
     </mat-selection-list>
   </div>
 </div>
</div>


