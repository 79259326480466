<div class="row">
  <div class="col-12">
    <h2>{{ "AFFILIATES.affiliates" | translate }}</h2>
  </div>
  <div class="col-md-8" *ngIf="allowedUsers.indexOf(authUser.id) !== -1">
    <button mat-raised-button color="primary" routerLink="create" type="button">
      + {{ "AFFILIATES.add-new" | translate }}</button>
  </div>
  <div class="col-md-4 pt-md-0 pt-5 pb-3">
    <div class="input-group bg-white shadow-inset-2" [class.has-length]="searchFocus">
      <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-right-0">
              <i class="fal fa-search"></i>
          </span>
      </div>
      <input [formControl]="search" type="text"
             class="form-control border-left-0 bg-transparent pl-0"
             (focus)="toggleFocus(true)"
             (blur)="toggleFocus(false)"
             (change)="onChange()"
             [placeholder]="'SHARED.search' | translate ">
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="track_id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'AFFILIATES.track-id' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.track_id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "AFFILIATES.name" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "AFFILIATES.status" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.active" class="badge badge-success">{{ "AFFILIATES.active" | translate }}</span>
        <span *ngIf="!element.active" class="badge badge-danger">{{ "AFFILIATES.inactive" | translate }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="created-at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "AFFILIATES.created-at" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.created_at | toDateObject | date }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex">
          <a class="pr-3" [routerLink]="['/affiliates', element.id, 'cases']">
            {{ 'CASES.model_name.plural' | translate }}
          </a>
          <a class="pr-3" routerLink="{{ element.id }}/edit">{{ "SHARED.edit" | translate }}</a>
          <a class="pr-3" (click)="openDeleteDialog(element.id, $event)">
            {{ "SHARED.delete" | translate }}
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h3 class="text-center">{{ "AFFILIATES.no-affiliates" | translate }}</h3>
  </div>

  <mat-paginator
          [length]="paginatorConfig.length"
          [pageSize]="paginatorConfig.pageSize"
          [pageSizeOptions]="[5, 10, 20]"
          (page)="paginatorChange($event)"
          showFirstLastButtons
  ></mat-paginator>
</div>
