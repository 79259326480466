import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root'
})
export class MiscConfigService extends MainBaseApiService {

  index(data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/configs`, data)
        .pipe(catchError(response => this.handleError(response)));
  }

  getConfigData(): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/configs`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getVariousFeePercentages(): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/fee-percentages`)
      .pipe(catchError(response => this.handleError(response)));
  }

  savePercentage(data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/fee-percentages`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  deleteFeePercentage(id): Observable<LaravelResponse> {
    return this.http.delete<LaravelResponse>(`${ this.apiUrl }/fee-percentages/${ id }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getFeePercentage(id): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/fee-percentages/${ id }`)
      .pipe(catchError(response => this.handleError(response)));
  }
}
