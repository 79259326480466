<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center">
    <h2>{{ 'DISTRIBUTION.report.select_modal.title' | translate }}</h2>
  </div>
  <div class="d-flex justify-content-end">
    <app-input type="text" [formControl]="searchControl" [appearance]="'standard'"
               [label]="'SHARED.search' | translate" [extraLabel]="false" prefixType="icon" prefix="search">
    </app-input>
  </div>
  <div class="mat-elevation-z8">
    <mat-error *ngIf="noBatches" class="pl-2">
      {{ 'DISTRIBUTION.report.select_modal.not-selected' | translate }}
    </mat-error>

    <table mat-table [dataSource]="batches" style="width: 100%">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'active': selection.isSelected(row)}">
      </tr>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" [checked]="selection.isSelected(row)"
                        (change)="toggleRow($event, row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.name' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
      </ng-container>
      <!-- Address Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'DISTRIBUTION.batch.model.status' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
      </ng-container>
    </table>

    <form *ngIf="reportType === 'payments_received'" [formGroup]="form">
      <div class="row pl-2 pr-2">
        <!-- Start Date -->
        <mat-form-field color="primary" appearance="standard" class="col-6">
          <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
          <input matInput [matDatepicker]="startDate" formControlName="payment_from">
          <button *ngIf="form.get('payment_from') && form.get('payment_from').value" mat-button matSuffix mat-icon-button
                  [disabled]="form.get('payment_from').disabled" (click)="clearFormControl($event, 'payment_from')"
                  aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button>
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate color="primary"></mat-datepicker>
        </mat-form-field>
        <!-- End Date -->
        <mat-form-field color="primary" appearance="standard" class="col-6">
          <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
          <input matInput [matDatepicker]="endDate" formControlName="payment_to"
                 (dateChange)="dateChanged($event, 'payment_to', true)">
          <button *ngIf="form.get('payment_to') && form.get('payment_to').value" mat-button matSuffix mat-icon-button
                  [disabled]="form.get('payment_to').disabled" (click)="clearFormControl($event, 'payment_to')"
                  aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button>
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate color="primary"></mat-datepicker>
        </mat-form-field>
      </div>
    </form>

    <!-- No Data -->
    <div *ngIf="isLoading < 1 && batches && batches.data.length < 1" style="padding: 50px">
      <h3 class="text-center">{{ 'DISTRIBUTION.report.no_batches' | translate }}</h3>
    </div>
    <div *ngIf="isLoading" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                   [pageSizeOptions]="[5, 10, 20, 50]" [pageIndex]="paginatorConfig.pageIndex"
                   (page)="paginatorChange($event)">
    </mat-paginator>
  </div>
  <div class="text-right mt-5 pb-5 d-flex justify-content-end align-items-center">
    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeModal()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
    <app-spinner-btn type="button" color="primary" [loading]="isSubmitting" (click)="submitSelectedBatch()">
      {{ 'SHARED.select' | translate }}
    </app-spinner-btn>
    <mat-spinner *ngIf="isSubmitting" diameter="20" color="accent"></mat-spinner>
  </div>
</div>
