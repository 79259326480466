import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { UserCaseRelationValidation } from '../../../../../../_base-shared/models/Case/UserCaseRelationValidation';
import { User } from '../../../../../../_base-shared/models/User/User';
import {UserCaseDataValidation} from '../../../../../../_base-shared/models/Case/UserCaseDataValidation';

@Component({
  selector:    'app-onboarding-preview',
  templateUrl: './onboarding-preview.component.html',
  styles:      [
    `
        h4 {
            font-weight: 400;
            color: #355270;
            font-size: 24px;
            line-height: 24px;
        }

        .onboarding-preview-btn {
            display: flex;
            font-family: Inter;
            flex-direction: column;
            align-items: center;
            padding: 10px 15px;
            gap: 8px;
            width: 174px;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #2764AE;
            border-radius: 4px;
            color: #2764AE;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }

        .mt-lg {
            margin-top: 16px;
        }
    `
  ],
})
export class OnboardingPreviewComponent implements OnInit {
  @Input() caseRelationValidation: UserCaseRelationValidation;
  @Input() case: Case;
  @Input() user: User;
  @Input() caseDataValidation: UserCaseDataValidation;
  @Output() caseFinished = new EventEmitter<boolean>(false);
  public total_fields_count: number;
  public completed_fields_count: number;
  public percentageComplete: number;
  public user_data: number;
  public other_creditors: number;
  public other_assets: number;
  public other_salaries: number;
  public other_expenses: number;
  public isCaseFinished: boolean;
  public desktopVersion: boolean;
  constructor(private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
    this.calculatePercentage(this.caseDataValidation);
  }

  private calculatePercentage(caseDataValidation: UserCaseDataValidation) {
    const fields = ['other_creditors', 'other_assets', 'other_salaries', 'other_expenses'];
    let completed_fields_count = 0;

    if (caseDataValidation)  {
      this.user_data = 100.0;
      completed_fields_count++;
    } else {
      this.user_data = 0.0;
    }

    fields.forEach(field => {
      if (!caseDataValidation) {
        this[field] = 0.0;
        return;
      }

      if (caseDataValidation[field] !== null) {
        completed_fields_count++;
        this[field] = 100.0;
      } else {
        this[field] = 0.0;
      }
    });
    this.completed_fields_count = completed_fields_count;
    this.isCaseFinished = this.completed_fields_count === 5 ? true : false;
    this.caseFinished.next(this.isCaseFinished);
    this.total_fields_count          = 5;
    this.percentageComplete          = parseFloat(((this.completed_fields_count / this.total_fields_count) * 100).toFixed(2));
  }
}
