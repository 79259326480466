import { Component, Input, OnInit } from '@angular/core';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../_base-shared/models/User/User';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss']
})
export class ProfilePreviewComponent implements OnInit {
  @Input() case: Case;
  @Input() user: User;
  @Input() isDesktop;
  constructor() { }

  ngOnInit(): void {
  }

}
