<h1 mat-dialog-title>{{ "CASES.single.request-payment" | translate }}</h1>
<p>{{"CASES.single.request-payment-note" | translate}}</p>
<div mat-dialog-content class="custom-card-wrap">
  <form *ngIf="formActive" [formGroup]="form">
    <div class="row">
      <div class="col-lg-6">
        <app-input type="number" formControlName="amount" [label]="'PAYMENTS.amount' | translate" [suffix]="'€'"
                   [fullWidth]="true">
        </app-input>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <div *ngIf="formActive" class="mb-3">
    <button mat-button color="primary" class="mr-3" (click)="onNoClick()">{{'SHARED.cancel' | translate}}</button>
    <div class="dropleft d-inline-block">
      <button style="min-width: 120px" mat-raised-button color="primary" class="btn dropdown-toggle" type="button"
              id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{"SHARED.send" | translate}}
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" (click)="sendRequest($event, ['email'])" href="#">Email</a>
        <a class="dropdown-item" (click)="sendRequest($event, ['sms'])" href="#">SMS</a>
        <a class="dropdown-item" (click)="sendRequest($event, ['email', 'sms'])" href="#">Email & SMS</a>
      </div>
    </div>
  </div>
</div>
