import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../../_shared/guards/main-auth-guard.service';
import { PermissionGuard } from '../../../../../_base-shared/guards/permission.guard';
import { DistributionBatchListComponent } from './distribution-batch-list/distribution-batch-list.component';
import {
  DistributionBatchDetailComponent,
} from './distribution-batch/distribution-batch-detail/distribution-batch-detail.component';
import {
  DistributionBatchEditorComponent,
} from './distribution-batch/distribution-batch-editor/distribution-batch-editor.component';
import { DistributionCaseDetailComponent } from './distribution-case-detail/distribution-case-detail.component';
import {
  DistributionCasePaymentComponent,
} from './distribution-case-detail/distribution-case-payment/distribution-case-payment.component';
import { DistributionListComponent } from './distribution-list/distribution-list.component';
import {
  DistributionProviderEditorComponent,
} from './distribution-provider/distribution-provider-editor/distribution-provider-editor.component';
import {
  DistributionProviderListComponent,
} from './distribution-provider/distribution-provider-list/distribution-provider-list.component';
import { DistributionReportListComponent } from './distribution-report-list/distribution-report-list.component';
import { DistributionComponent } from './distribution.component';

const routes: Routes = [
  {
    path:             'distribution',
    component:        DistributionComponent,
    canActivateChild: [MainAuthGuard],
    children:         [
      {path: '', redirectTo: 'cases', pathMatch: 'full'},
      {
        path:     'providers',
        children: [
          {path: '', component: DistributionProviderListComponent},
          {path: 'create', component: DistributionProviderEditorComponent, data: {editorType: 'create'}},
          {path: ':id/edit', component: DistributionProviderEditorComponent, data: {editorType: 'edit'}},
        ],
      },
      {
        path: 'cases', children: [
          {path: '', component: DistributionListComponent},
          {
            path:        ':uuid',
            component:   DistributionCaseDetailComponent,
            canActivate: [MainAuthGuard, PermissionGuard],
            children:    [
              {path: 'payments', component: DistributionCasePaymentComponent},
            ],
          },
        ],
      },
      {
        path:     'batches',
        children: [
          {path: '', component: DistributionBatchListComponent},
          {path: 'create', component: DistributionBatchEditorComponent, data: {editorType: 'create'}},
          {
            path: ':id/cases', children: [
              {path: '', component: DistributionBatchDetailComponent},
              {
                path:        ':uuid',
                component:   DistributionCaseDetailComponent,
                canActivate: [MainAuthGuard, PermissionGuard],
                children:    [
                  {path: 'payments', component: DistributionCasePaymentComponent},
                ],
              },
            ],
          },
          {path: ':id/edit', component: DistributionBatchEditorComponent, data: {editorType: 'edit'}},
        ],
      },
      {path: 'reports', component: DistributionReportListComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class DistributionRoutingModule {
}
