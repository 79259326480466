<div class="payment-desktop-container client-typography">
  <div class="h-100 d-flex flex-column align-items-center">
    <div class="row payment-desktop " [ngStyle]="{'width': desktopVersion ? '80%': '100%'}">
      <div *ngIf="errorMessage" class="col-12 pop-up-desktop pt-2" [@fadeAnimation]
           [ngStyle]="{'background-color': isSuccessful ? 'rgba(71, 157, 132, 0.1)' : '#F8E5E5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">
        <p style="color:#B60000;cursor: pointer"
           class="text-center d-flex flex-row justify-content-center align-items-center"
           (click)="dismissError()">
          <mat-icon class="me-2">cancel</mat-icon>
          {{ errorMessage }}
        </p>
      </div>
      <div class="col-12 col-lg-8 payment-action py-5">
        <div class="container-fluid client-typography"
             style="padding-right: 0 !important; padding-left: 0; max-width: 100%">
          <div class="payment-heading text-center">
            <!--            <span class="text-muted">{{ 'SHARED-COMPONENTS.payment_handler_wrapper.heading' | translate }}</span>-->
            <h2 *ngIf="!case && idCardForm">
              {{ 'SHARED-COMPONENTS.payment_handler_wrapper.id_card_form.locate_case' | translate }}
            </h2>
            <h2 *ngIf="case && idCardForm">
              {{ 'SHARED-COMPONENTS.payment_handler_wrapper.id_card_form.get_details' | translate }}
            </h2>
          </div>
        </div>
        <div class="container client-typography-input" style="padding: 0">
          <div class="mt-3 w-100">
            <div class="w-100">
              <mat-spinner *ngIf="resolvingIdCardForm" class="m-0 m-auto"></mat-spinner>
              <div *ngIf="idCardForm" [hidden]="resolvingIdCardForm" class="mx-auto w-50">
                <form [formGroup]="idCardForm" (ngSubmit)="submitIdCardForm(idCardForm)">
                  <!-- ID Card -->
                  <app-input type="text" formControlName="id_card"
                             [label]="'SHARED-COMPONENTS.payment_handler_wrapper.id_card_form.id_card' | translate"
                             [fullWidth]="true" appearance="fill" [extraLabel]="false" [requiredStar]="true">
                  </app-input>
                  <!-- Submit -->
                  <div class="text-right d-flex">
                    <app-spinner-btn type="submit" [loading]="resolvingIdCardForm > 0"
                                     [name]="'SHARED.submit' | translate">
                    </app-spinner-btn>
                  </div>
                </form>
              </div>
              <div *ngIf="paymentFormActive">
                <!-- Card Form -->
                <div class="mt-3 w-100">
                  <div class="w-100">
                    <div class="row">
                      <div class="col-12">
                        <app-public-payment-handler [case]="case" [amountToCharge]="chargeAmount"
                                                    [editableAmount]="false" [paymentCards]="paymentCards"
                                                    [paymentRequest]="paymentRequest" [paymentPlanType]="paymentPlanType"
                                                    [installment]="installment" [clientRole]="clientRole"
                                                    [autoRedirect]="true" [redirectNewTab]="false">
                        </app-public-payment-handler>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 payment-info px-5 py-5">
        <!--Pay safe text container-->
        <div
          class="col-icons pay-safe-text d-flex flex-column justify-content-center align-items-start">
          <p>
            <i class="fas fa-lock me-2"></i>
            {{ 'SHARED-COMPONENTS.payment_handler_wrapper.about_payments.pay_safe' | translate }}.
          </p>
          <p>
            <i class="fas fa-shield-alt me-2"></i>
            {{ 'SHARED-COMPONENTS.payment_handler_wrapper.about_payments.safe_info' | translate }}.
          </p>
          <p>
            <i class="fab fa-cc-visa me-2"></i>
            {{ 'SHARED-COMPONENTS.payment_handler_wrapper.about_payments.we_accept' | translate }}
          </p>
          <div class="d-flex justify-content-around">
            <img src="assets/img/visaIcon.png" alt="" style="height: 36px">
            <img class="ms-2 ms-2" src="assets/img/masterCardIcon.png" alt="" style="height: 36px">
            <!-- <img class="ml-2 ms-2" src="assets/img/bizum.png" alt="" style="height: 40px"> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
