import { SelectionModel } from '@angular/cdk/collections';
import {Component, ElementRef, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { AdditionalInputComponent } from './additional-input/additional-input.component';
import {
  ChangeCreditorStatusComponent,
} from '../../../../_shared/components/change-creditor-status/change-creditor-status.component';
import { VerifySignatureComponent } from '../../../../_shared/components/verify-signature/verify-signature.component';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { EmailPreviewComponent } from '../../../app-document/email-preview/email-preview.component';
import { CreditorService } from '../../../creditor/creditor.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseDocumentService } from '../../case-document.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-creditor-list',
  templateUrl: './case-creditor-list.component.html',
  styles:      [
    `
      .mat-icon-size {
        font-size : 25px;
        width     : 25px;
        height    : 25px;
      }

      .mat-form-field {
        width : 100%;
      }
    `],
})
export class CaseCreditorListComponent implements OnInit {
  public case: Case;
  public form: UntypedFormGroup;
  public loanCreditorsForm: UntypedFormGroup;
  public securedCreditors: MatTableDataSource<CaseCreditor>;
  public unsecuredCreditors: MatTableDataSource<CaseCreditor>;
  public claimCreditors: MatTableDataSource<CaseCreditor>;
  public publicDebts: MatTableDataSource<CasePublicDebt>;
  public loanCreditors: MatTableDataSource<CaseCreditor>;
  public originalLoanCreditors: Array<CaseCreditor>;
  public displayedColumns: string[]   = [];
  public displayedColumnsPD: string[] = [];
  public displayedColumnsLoanCreditors: string[] = [];
  public isLoading                    = 0;
  public isFetching                   = 0;
  public isFetchingPublicDebt         = 0;
  public isLoadingIds: string[]       = []; //  User to determinate in which row to show spinner
  public isPublicLoading              = 0;
  public isPublicLoadingId: string[]  = [];
  public isEditing                       = false;
  public currentRow: number;

  public totalDebtAmount: number = 0;
  public totalNegotiatedAmount: number = 0;
  public totalReductionAmount: number = 0;
  public percentReduction: number = 0;

  public loadRelations = ['contracts', 'client_files', 'partner_files', 'client', 'partner'];
  selectionSecured     = new SelectionModel<CaseCreditor>(true, []);
  selectionUnsecured   = new SelectionModel<CaseCreditor>(true, []);
  selectionClaim       = new SelectionModel<CaseCreditor>(true, []);

  selectionLoanCreditors  = new SelectionModel<CaseCreditor>(true, []);

  selectionPublicDebts = new SelectionModel<CasePublicDebt>(true, []);
  //  Enable/Disabled flags
  public isSigned      = false;

  public missingAepFiles               = true;
  private propuestoModelo: any;
  public publicDebtsVerifyDisabled     = true;
  public publicDebtsResendDisabled     = true;
  public securedVerifyDisabled         = true;
  public securedResendDisabled         = true;
  public securedRecoveryDisabled       = true;
  public securedSendAepDisabled        = true;
  public securedResendAepDisabled      = true;
  public securedProposalAccordDisabled = true;

  public unsecuredSendAepDisabled        = true;
  public unsecuredResendAepDisabled      = true;
  public unsecuredVerifyDisabled         = true;
  public unsecuredResendDisabled         = true;
  public unsecuredRecoveryDisabled       = true;
  public unsecuredProposalAccordDisabled = true;
  public claimSendAepDisabled            = true;
  public claimResendAepDisabled          = true;
  public claimVerifyDisabled             = true;
  public claimResendDisabled             = true;
  public claimRecoveryDisabled           = true;
  public claimProposalAccordDisabled     = true;
  public isSubmitting                    = false;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private caseService: CaseService,
    private creditorService: CreditorService,
    private caseCreditorService: CaseCreditorService,
    private caseDocumentService: CaseDocumentService,
    private dialog: MatDialog,
    private toast: ToastrService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private el: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.isLoading++;
        this.caseDocumentService.indexCaseDocuments(caseId)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(res => {
            this.case = res.data;
            this.fetchData();
            this.buildDisplayColumns();
            this.buildForm();
            this.buildLoanCreditorsForm();
            this.caseDocumentService.index(caseId, ['legal_documents'])
              .pipe(finalize(() => this.isLoading--))
              // tslint:disable-next-line:no-shadowed-variable
              .subscribe(res => {
                this.case.legal_documents = res.data.legal_documents;
                this.missingAepFiles      = !! (this.case.legal_documents.filter(
                    file => file.document_type === 'aep').length === 0 &&
                  (this.case.legal_documents.filter(
                      file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
                    this.case.legal_documents.filter(
                      file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0));
              });
            this.isLoading++;
            this.caseDocumentService.showPropuestoModelo(caseId)
              .pipe(finalize(() => this.isLoading--))
              // tslint:disable-next-line:no-shadowed-variable
              .subscribe(res => {
                this.propuestoModelo = res.data;
                if (this.propuestoModelo) {
                  this.isSigned = this.case.joint_application ?
                    !! (this.propuestoModelo.signature && this.propuestoModelo.signature_partner) :
                    !! this.propuestoModelo.signature;
                } else {
                  this.isSigned = false;
                }
              });
            this.selectionSecured.changed.subscribe(next => {
              this.checkIfDisabled(next, 'secured');
            });
            this.selectionUnsecured.changed.subscribe(next => {
              this.checkIfDisabled(next, 'unsecured');
            });
            this.selectionPublicDebts.changed.subscribe(next => {
              this.checkIfDisabled(next, 'public_debts');
            });

            this.form.valueChanges.subscribe(value => {
              const filters = this.getFilters();
              this.fetchData(filters);
            });
          });

      });
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(selection, data) {
    const numSelected = selection.selected.length;
    const numRows     = data.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selection, data) {
    if (this.isAllSelected(selection, data)) {
      selection.clear();
      return;
    }

    selection.select(...data.data);
  }

  public toggleLoanEditMode(index: number, element): void {
    this.currentRow = index;
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      this.loanCreditorsForm.get('debt_amount').patchValue(element.pivot.debt_amount);
      this.loanCreditorsForm.get('negotiated_amount').patchValue(element.pivot.negotiated_amount);
      this.el.nativeElement.querySelector('td').classList.add('.p-r-10');
    }

    if (!this.isEditing) {
      this.saveLoanCreditorData(element, this.loanCreditorsForm.getRawValue());
      this.loanCreditorsForm.get('debt_amount').patchValue(null);
      this.loanCreditorsForm.get('negotiated_amount').patchValue(null);
    }
  }

  private buildDisplayColumns() {
    this.displayedColumns = [
      'select',
      'name',
      'ownership',
      'debt_type',
      'initial_balance',
      'current_balance',
      'monthly_payments',
      'reference_number',
      'response_received',
      'additional_partner',
      'making_payments',
      'azcarate',
      'actions',
      'loader',
    ];

    this.displayedColumnsPD = [
      'select', 'name', 'ownership', 'debt_type', 'initial_balance', 'current_balance', 'monthly_payments',
      'reference_number', 'response_received', 'additional_partner', 'making_payments', 'actions', 'loader',
    ];

    this.displayedColumnsLoanCreditors = [
      'select', 'name', 'ownership', 'debt_amount', 'negotiated_amount', 'total_reduction', 'percent_reduction',
      'actions', 'loader'
    ];
  }

  buildForm() {
    this.form = this.fb.group({
      ownership:         new UntypedFormControl(''),
      verified:          new UntypedFormControl(''),
      response_received: new UntypedFormControl(''),
    });
  }
  buildLoanCreditorsForm(): void {
    this.loanCreditorsForm = this.fb.group({
      debt_amount: new UntypedFormControl(''),
      negotiated_amount: new UntypedFormControl('')
    });
  }

  getFilters() {
    const {ownership, verified, response_received} = this.form.value;
    const data                                     = {};
    if (ownership) {
      data['ownership[]'] = ownership;
    }
    if (verified) {
      data['verified[]'] = verified;
    }
    if (response_received) {
      data['response_received[]'] = response_received;
    }
    return data;
  }

  calculateFooterTotals() {
    this.totalDebtAmount = this.loanCreditors.data.reduce((sum, creditor) => sum + Number(creditor.pivot.debt_amount), 0);
    this.totalNegotiatedAmount = this.loanCreditors.data.reduce((sum, creditor) => sum + Number(creditor.pivot.negotiated_amount), 0);
    this.totalReductionAmount = (this.totalDebtAmount - this.totalNegotiatedAmount);
    this.percentReduction = (+(+(this.totalDebtAmount - this.totalNegotiatedAmount) / this.totalDebtAmount) * 100);
  }

  private fetchData(data = {}) {
    data['exclude_deudafix'] = true;
    this.fetchCreditors(data);
    this.fetchPublicDebts(data);
  }

  private fetchCreditors(data) {
    this.isFetching++;
    this.securedCreditors   = new MatTableDataSource<CaseCreditor>([]);
    this.unsecuredCreditors = new MatTableDataSource<CaseCreditor>([]);
    this.claimCreditors     = new MatTableDataSource<CaseCreditor>([]);
    this.loanCreditors      = new MatTableDataSource<CaseCreditor>([]);
    this.caseCreditorService.indexCaseCreditors(this.case.id, data)
      .pipe(finalize(() => this.isFetching--))
      .subscribe(value => {
        this.securedCreditors   = new MatTableDataSource<Creditor>(value.data.secured);
        this.unsecuredCreditors = new MatTableDataSource<Creditor>(value.data.unsecured);
        this.claimCreditors     = new MatTableDataSource<Creditor>(value.data.claim);

        const loanCreditors = value.data.unsecured ? value.data.unsecured.map(loanCreditor => {
          loanCreditor.pivot.debt_amount = loanCreditor.pivot.debt_amount ?
            loanCreditor.pivot.debt_amount : loanCreditor.pivot.initial_balance;
          return loanCreditor;
        }) : [];
        this.originalLoanCreditors = loanCreditors;
        this.loanCreditors = new MatTableDataSource<Creditor>(loanCreditors);
        this.calculateFooterTotals();
      });
  }

  private fetchPublicDebts(data) {
    this.isFetchingPublicDebt++;
    this.publicDebts = new MatTableDataSource<CasePublicDebt>([]);
    this.caseCreditorService.indexCasePublicDebts(this.case.id, data)
      .pipe(finalize(() => this.isFetchingPublicDebt--))
      .subscribe(value => {
        this.publicDebts = new MatTableDataSource<CasePublicDebt>(value.data);
      });
  }

  public clearFilters() {
    this.form.setValue({
      ownership:         '',
      verified:          '',
      response_received: '',
    });
    this.fetchData({});
  }

  public clearMultiSelect($event, name) {
    $event.stopPropagation();
    this.form.patchValue({[name]: []});
  }

  public saveLoanCreditorData(element, formValue): void {
    this.creditorService.saveLoanCreditorsData(this.case.id, element.pivot.id, formValue)
      .subscribe(res => {
        const updatedCreditor = this.originalLoanCreditors.find(creditor => creditor.pivot.id === element.pivot.id);
        const updatedCreditorIndex = this.originalLoanCreditors.findIndex(creditor => creditor.pivot.id === element.pivot.id);

        updatedCreditor.pivot.debt_amount = formValue.debt_amount;
        updatedCreditor.pivot.negotiated_amount = formValue.negotiated_amount;
        /*updatedCreditor.pivot.total_reduction = formValue.total_reduction;
        updatedCreditor.pivot.percent_reduction = formValue.percent_reduction;*/
        this.originalLoanCreditors[updatedCreditorIndex] = updatedCreditor;
        this.loanCreditors = new MatTableDataSource<Creditor>(this.originalLoanCreditors);

        this.calculateFooterTotals();

        this.toast.success(this.translate.instant('SHARED.success'));
      }, err => {
        this.toast.error(this.translate.instant('SHARED.error'));
      });
  }

  verifyCreditor($event, caseCreditorId, creditorType) {
    $event.preventDefault();

    if ( !! this.case.client.signature_verified_at) {
      if (creditorType === 'public_debts') {
        this.additionalInput(caseCreditorId, creditorType);
      } else {
        this.verifySendMandates(caseCreditorId, creditorType);
      }
    } else {
      this.verifySignature(caseCreditorId, creditorType);
    }
  }

  public sendAep($event, caseCreditorId, creditorType) {
    $event.preventDefault();
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.sendAepEmails(this.case.id, {selected_ids: ids})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.send-aep-success'));
          ids.map(id => {
            //  Mark that AEP is sent for all creditors with same name
            if (creditorType === 'secured_creditors') {
              //  Find creditor
              // @ts-ignore
              const item = this.securedCreditors.data.find(i => i.pivot.id === id);
              this.securedCreditors.data.map(cred => { // Find all other creditor creditors with same id
                if (cred.id === item.id) {
                  // @ts-ignore
                  cred.pivot.aep_sent = 1;
                }
              });
            } else if (creditorType === 'unsecured_creditors') {
              //  Find creditor
              // @ts-ignore
              const item = this.unsecuredCreditors.data.find(i => i.pivot.id === id);
              this.unsecuredCreditors.data.map(cred => {  // Find all other creditor creditors with same id
                if (cred.id === item.id) {
                  // @ts-ignore
                  cred.pivot.aep_sent = 1;
                }
              });
            } else {
              //  Find creditor
              // @ts-ignore
              const item = this.claimCreditors.data.find(i => i.pivot.id === id);
              this.claimCreditors.data.map(cred => {  // Find all other creditor creditors with same id
                if (cred.id === item.id) {
                  // @ts-ignore
                  cred.pivot.aep_sent = 1;
                }
              });
            }
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('CASES.details.send-aep-error'));
          }
        });

  }

  public sendProposalAndAccord($event, caseCreditorId) {
    $event.preventDefault();
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;

    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.sendProposalAndAcoord(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.send-proposal-accord-success'));
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('CASES.details.send-proposal-accord-error'));
          }
        });
  }

  public sendRightOfAccess(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendRightOfAccess(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access')}));
        },
        error => {
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access')}));
          }
        });
  }

  public sendRightOfAccessPpi(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendRightOfAccessPpi(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access_ppi')}));
        },
        error => {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access_ppi')}));
        });
  }

  public sendPpiClaim(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendPpiClaim(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.ppi_claim')}));
        },
        error => {
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.ppi_claim')}));
          }
        });
  }

  public sendAzcarateLoanLetter(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendAzcarateLoanLetter(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_loan_letter')}));
        },
        error => {
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_loan_letter')}));
          }
        });
  }

  public sendAzcarateRevolvingLetter(selectedCaseCreditors) {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.pivot.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.creditorService.sendAzcarateRevolvingLetter(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_revolving_letter')}));
        },
        error => {
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_revolving_letter')}));
          }
        });
  }

  public markAsVerified(id, type) {
    if (type === 'secured_creditors') {
      // @ts-ignore
      const item          = this.securedCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.verified = 1;
    }
    if (type === 'unsecured_creditors') {
      // @ts-ignore
      const item          = this.unsecuredCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.verified = 1;
    }
    if (type === 'claim_creditors') {
      // @ts-ignore
      const item          = this.claimCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.verified = 1;
    }
    if (type === 'public_debts') {
      // @ts-ignore
      const item    = this.publicDebts.data.find(i => i.id === id);
      // @ts-ignore
      item.verified = 1;
    }
  }

  public updateStatus(id, type, value) {
    if (type === 'secured_creditors') {
      // @ts-ignore
      const item                   = this.securedCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.response_received = value;
    }
    if (type === 'unsecured_creditors') {
      // @ts-ignore
      const item                   = this.unsecuredCreditors.data.find(i => i.pivot.id === id);
      // @ts-ignore
      item.pivot.response_received = value;
    }
    if (type === 'public_debts') {
      // @ts-ignore
      const item             = this.publicDebts.data.find(i => i.id === id);
      // @ts-ignore
      item.response_received = value;
    }
  }

  verifySendMandates(caseCreditorId, creditorType) {
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.verifySendMandates(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          // @ts-ignore
          value.data.messages.map(message => {
            this.toast.success(message.message);
          });

          ids.map(id => {
            this.markAsVerified(id, creditorType);
            this.updateStatus(id, creditorType, 'mandate_sent');
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('CASES.details.resend-mandates-error'));
          }
        });
  }

  sendMandatesRecoveryAgent(caseCreditorId, creditorType) {
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.pivot.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.sendMandatesRecoveryAgent(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          // @ts-ignore
          value.data.messages.map(message => {
            this.toast.success(message.message);
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error?.data?.message) {
            this.toast.error(error.error.data.message);
          } else {
            this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
          }
        });
  }

  verifySignature(caseCreditorId, creditorType) {
    const dialogRef = this.dialog.open(VerifySignatureComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case:           this.case,
        verifyCreditor: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.message) {
          if (creditorType === 'public_debts') {
            this.additionalInput(caseCreditorId, creditorType);
          } else {
            this.verifySendMandates(caseCreditorId, creditorType);
          }
          // this.updateSignatureVerification.emit(1);
        }
      }
    });
  }

  additionalInput(debtIds, creditorType) {
    const ids              = debtIds.selected ? debtIds.selected.map(cred => cred.id) : debtIds;
    this.isPublicLoading   = 1;
    this.isPublicLoadingId = ids;

    const dialogRef = this.dialog.open(AdditionalInputComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case: this.case,
        debtIds,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.message) {
          ids.map(id => {
            this.markAsVerified(id, creditorType);
          });
          this.isPublicLoading   = 0;
          this.isPublicLoadingId = [];
          if (debtIds.selected) {
            debtIds.clear(); //  Remove selections
          }
        }
      } else {
        this.isPublicLoading   = 0;
        this.isPublicLoadingId = [];
        if (debtIds.selected) {
          debtIds.clear(); //  Remove selections
        }
      }
    });
  }

  sendDeleteNumber($event: MouseEvent, ids: any[]) {
    $event.preventDefault();

    this.isLoading++;
    this.isLoadingIds = ids;

    const dialogRef = this.dialog.open(AdditionalInputComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        type:       'number',
        case:       this.case,
        creditorId: ids[0],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading    = 0;
      this.isLoadingIds = [];
    });
  }

  sendAntiHarassment($event: MouseEvent, ids: any[]) {
    $event.preventDefault();
    this.isLoading++;
    this.isLoadingIds = ids;

    this.creditorService.sendAntiHarassmentEmail(ids[0])
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  sendLoanCancellationEmail($event: MouseEvent, ids: any[]) {
    $event.preventDefault();
    this.isLoading++;
    this.isLoadingIds = ids;

    this.creditorService.sendLoanCancellationEmail(ids[0])
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  public getRightOfAccess(clientRole: 'client' | 'partner', creditorId: number, $event) {
    $event.preventDefault();
    this.caseDocumentService.generateRightOfAccessLetter(clientRole, creditorId)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'right_of_access_' + moment().format('YYYY-MM-DD_HH-mm') + 'pdf';
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded right of access letter');
        }
      }, err => {
        this.toastr.error('Failed to generate Demanda document');
      });
  }

  changeStatus($event: MouseEvent, selection, type: string) {
    $event.preventDefault();
    let ids;
    if (type === 'public_debts') {
      ids = selection.selected ? selection.selected.map(cred => cred.id) : selection;
    } else {
      ids = selection.selected ? selection.selected.map(cred => cred.pivot.id) : selection;
    }

    const dialogRef = this.dialog.open(ChangeCreditorStatusComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case: this.case,
        ids,
        type,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (selection.selected) {
        selection.clear(); //  Remove selections
      }
      if (result) {
        ids.map(id => {
          this.updateStatus(id, type, result);
        });
      }
    });
  }

  checkIfDisabled(selection, type: string) {
    if (type === 'public_debts') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.publicDebtsVerifyDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.verified === 1).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.publicDebtsResendDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.verified !== 1).length);
    }
    if (type === 'secured') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.securedVerifyDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified === 1).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.securedResendDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified !== 1).length);

      //  Used to Disable/Enable `send mandate recovery` action
      this.securedRecoveryDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.recovery_creditor_id).length);

      //  Used to Disable/Enable `send aep` action
      //  Check if there is selection and if can send aep email
      this.securedSendAepDisabled = !! (selection.source.selected.length === 0 || // No selection
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent === 1).length);

      //  Used to Disable/Enable `resend aep` action
      //  Check if there is selection and if can send aep email
      this.securedResendAepDisabled = !! (selection.source.selected.length === 0 || // No selection
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent !== 1).length);

      //  Used to Disable/Enable `send proposal and accord` action
      this.securedProposalAccordDisabled = !! (selection.source.selected.length === 0 || ! this.isSigned ||
        selection.source.selected.filter(cred => ! cred.pivot.proposal_location).length);
    }
    if (type === 'unsecured') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.unsecuredVerifyDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified === 1).length);

      //  Used to Disable/Enable `send mandate recovery` action
      this.unsecuredRecoveryDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.recovery_creditor_id).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.unsecuredResendDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified !== 1).length);

      //  Used to Disable/Enable `send aep` action
      //  Check if there is selection and if can send aep email
      this.unsecuredSendAepDisabled = !! (selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent === 1).length);

      //  Used to Disable/Enable `resend aep` action
      //  Check if there is selection and if can send aep email
      this.unsecuredResendAepDisabled = !! (selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent !== 1).length);

      //  Used to Disable/Enable `send proposal and accord` action
      this.unsecuredProposalAccordDisabled = !! (selection.source.selected.length === 0 || ! this.isSigned ||
        selection.source.selected.filter(cred => ! cred.pivot.proposal_location).length);
    }

    if (type === 'claim') {
      //  Used to Disable/Enable `verify and send mandate` action
      //  Check if there is selection and if selected items are verified
      //  If there are verified debt selected we disable action
      this.claimVerifyDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified === 1).length);

      //  Used to Disable/Enable `send mandate recovery` action
      this.claimRecoveryDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.recovery_creditor_id).length);

      //  Used to Disable/Enable `resend mandate` action
      //  Check if there is selection and if selected items are not verified
      this.claimResendDisabled = !! (selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.pivot.verified !== 1).length);

      //  Used to Disable/Enable `send aep` action
      //  Check if there is selection and if can send aep email
      this.claimSendAepDisabled = !! (selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent === 1).length);

      //  Used to Disable/Enable `resend aep` action
      //  Check if there is selection and if can send aep email
      this.claimResendAepDisabled = !! (selection.source.selected.length === 0 ||
        (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 && // No `aep` files
          // No `aep-reg` files uploaded for client
          (this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
            // No `aep-reg` files uploaded for partner
            this.case.legal_documents.filter(
              file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
        selection.source.selected.filter(cred => cred.pivot.aep_sent !== 1).length);

      //  Used to Disable/Enable `send proposal and accord` action
      this.claimProposalAccordDisabled = !! (selection.source.selected.length === 0 || ! this.isSigned ||
        selection.source.selected.filter(cred => ! cred.pivot.proposal_location).length);
    }
  }

  sendLastChance($event: MouseEvent, selection) {
    $event.preventDefault();
    const ids = selection.selected ? selection.selected.map(cred => cred.pivot.id) : selection;
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CASES.details.send-last-chance-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.send'),
      confirmButtonColor: '#886AB5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.isLoadingIds = ids;

        this.creditorService.sendLastChanceEmail(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
          .pipe(finalize(() => {
            this.isLoading--;
            this.isLoadingIds = [];
          }))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.email-sent'));
              if (selection.selected) {
                selection.clear(); //  Remove selections
              }
            }, error => {
              this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
            });
      }
    });
  }

  sendEmail($event: MouseEvent, id) {
    $event.preventDefault();

    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CASES.details.send-last-chance-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.send'),
      confirmButtonColor: '#886AB5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.isLoadingIds = id;

        this.creditorService.sendAzcarateEmail(id)
          .pipe(finalize(() => {
            this.isLoading--;
            this.isLoadingIds = null;
          }))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.email-sent'));
            }, error => {
              this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
            });
      }
    });
  }

  public previewEmail(emailType: 'SettlementDemand' | 'ExtrajudicialNotice', caseId: number, creditorId: number) {
    this.caseCreditorService.previewEmail(caseId, creditorId, emailType).subscribe(result => {
        const emailHeader = {
          from:    result.data.from,
          to:      result.data.to,
          cc:      result.data.cc,
          subject: result.data.subject,
        };

        const dialogRef = this.dialog.open(EmailPreviewComponent, {
          width:  '310mm',
          height: '100vh',
          data:   {
            title:       'Creditor Document Request Preview',
            previewType: 'email',
            emailHeader,
            emailBody:   result.data.body,
            attachments: result.data.attachments,
          },
        });

        dialogRef.afterClosed().subscribe(res => {
          if (res && res.action === 'send-email') {
            this.caseCreditorService.sendEmail(caseId, creditorId, emailType).subscribe(
              r => this.toast.success('Sent ' + emailType),
              errorResponse => this.parseValidationErrors(errorResponse),
            );
          }
          if (res && res.action === 'save-and-download-document' && res.params?.type) {
            this.caseCreditorService.saveAndDownloadDocument(caseId, creditorId, res.params.type).subscribe(
              r => {
                if (r.type === 4) {
                  const fileName = res.params?.type + '_' + moment().format('YYYY-MM-DD_HH-mm') + '.pdf';
                  saveAs(r.body, fileName);
                  this.toast.success('Document downloaded');
                }
              },
              errorResponse => this.parseValidationErrors(errorResponse),
            );
          }
        });
      },
      errorResponse => this.parseValidationErrors(errorResponse));
  }

  private parseValidationErrors(errorResponse: any) {
    const error = errorResponse.error;
    if (+error.code === 422 && error.errors) {
      for (const [key, value] of Object.entries(error.errors)) {
        const valueArray = value as any;
        valueArray.forEach(e => this.toast.error(e));
      }
    }
  }

  public returnLenderType(lenderType: 'only_claims' | 'standard' | 'high_interest' | 'not_claimable') {
    const types = {
      only_claims:   '#FFABAA',
      standard:      '',
      high_interest: '#FFFF63',
      not_claimable: '',
    };
    return types[lenderType];
  }
}
