import { NgModule } from '@angular/core';
import { SharedClientModule } from '../_shared/shared-client.module';
import { ClientPaymentComponent } from './client-payment/client-payment.component';
import { PaymentsFooterComponent } from './client-payment/payments-footer/payments-footer.component';
import { TrustboxComponent } from './client-payment/trustbox/trustbox.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { SepaPaymentComponent } from './sepa-payment/sepa-payment.component';
import { AuthPaymentComponent } from './auth-payment/auth-payment.component';
import { ClientPaymentHandlerComponent } from './client-payment-handler/client-payment-handler.component';

@NgModule({
  imports:      [
    SharedClientModule,
    PaymentRoutingModule,
  ],
  declarations: [
    ClientPaymentComponent,
    SepaPaymentComponent,
    TrustboxComponent,
    AuthPaymentComponent,
    ClientPaymentHandlerComponent,
    PaymentsFooterComponent
  ],
})
export class PaymentModule {
}
