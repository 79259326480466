import { Case } from './Case';

export type CaseIncomeType = 'salary' | 'pension' | 'benefits' | 'other' | 'special_incomes';

export class CaseIncome {
  id?: number;
  case_id: number;
  // Salary
  salary_client: number;
  salary_partner: number;
  subsidy_benefit: number;
  unemployment_benefit: number;
  pension_state: number;
  // Benefits
  compensatory_pension: number;
  alimony: number;
  // Pension
  pension_private: number;
  pension_credit: number;
  pension_other: number;
  // Other
  other_boarders_or_lodgers: number;
  other_non_dependent_contributions: number;
  other_student_loans_and_grants: number;

  special_incomes: Array<{ label: string, value: number }>;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  case?: Case;
}
