import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { DripCampaignModule } from './drip-campaign/drip-campaign.module';
import { EmailModule } from './email/email.module';
import { NotificationModule } from './notification/notification.module';
import { SmsModule } from './sms/sms.module';
import { StatusModule } from '../status/status.module';
import { SystemEventModule } from './system-event/system-event.module';
import { MiscConfigModule } from './misc-config/misc-config.module';
import {CallStatusModule} from '../call-status/call-status.module';

@NgModule({
  imports: [
    SharedModule,
    SystemEventModule,
    SmsModule,
    EmailModule,
    NotificationModule,
    StatusModule,
    DripCampaignModule,
    MiscConfigModule,
    CallStatusModule
  ],
})
export class ConfigModule {
}
