import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { ScriptLoaderService } from '../../../../../_base-shared/services/script-loader.service';
import { ClientService } from '../../client/client.service';

@Component({
  selector:    'app-nav',
  templateUrl: './nav.component.html',
  styleUrls:   ['./nav.component.scss'],
})
export class NavComponent implements OnInit{
  @Input() notifications;
  @Input() selectedCase: Case;
  public authUser: User;
  public navV2                        = false;
  public desktopVersion: boolean;
  public isLiveChatLoading: boolean;
  public showLiveChatButton           = false;
  public showNav                      = true;
  public showPaymentsNav              = false;
  public isMobileNav          = false;

  constructor(public globalEventsService: MainGlobalEventService,
              public clientService: ClientService,
              private breakpointObserver: BreakpointObserver,
              @Inject(DOCUMENT) private document: Document,
              private scriptLoaderService: ScriptLoaderService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.globalEventsService.$navStatus.subscribe(result => this.showNav = result);
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
      this.isMobileNav = result.matches;
    });
    if (this.router.url.includes('client/payment')) {
      this.showPaymentsNav = true;
      return;
    }
    this.clientService.navSubject$.subscribe(isNavV2 => this.navV2 = isNavV2);
    this.showLiveChatButton = true;
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.showLiveChatButton = event.url === '/' ? true : false;
    });
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.isLiveChatLoading = true;
  }

  public changeNav(): void {
    this.clientService.setNavSubject(false);
    this.clientService.navSubject$.subscribe(data => {
      if ( ! data) {
        this.navV2 = data;
      }
    });
  }

  public openMobileNav(): void {
    this.isMobileNav = !this.isMobileNav;
  }
}
