import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { ClientService } from '../../../client.service';
import {
  PaymentOutcomeComponent
} from '../../../../../../../_base-shared/components/payment/payment-outcome/payment-outcome.component';

@Component({
  selector:    'app-client-payment-outcome',
  templateUrl: '../../../../../../../_base-shared/components/payment/payment-outcome/payment-outcome.component.html',
  styles:      []
})
export class ClientPaymentOutcomeComponent extends PaymentOutcomeComponent {
  constructor(protected route: ActivatedRoute,
              protected translate: TranslateService,
              protected clientAppService: ClientService) {
    super(route, translate);
  }

  protected checkPaymentStatus(checkoutId: string, resourcePath: string): void {
    const requestData = {
      transactionable_type:  'oppwa_order',
      transaction_oppwa_uid: checkoutId,
      resource_path:         resourcePath,
    };
    this.isLoading++;
    this.clientAppService.checkTransactionStatus(requestData)
      .pipe(finalize(() => {
        this.isResolved = true;
        this.isLoading--;
      }))
      .subscribe(
        result => {
          console.log(result.data);
          this.isSuccessful = result.data.success;
          this.message      = this.isSuccessful ?
            this.translate.instant('SHARED-COMPONENTS.payment_outcome.success.message') :
            this.translate.instant('SHARED-COMPONENTS.payment_outcome.error.message');
        }
      );
  }
}
