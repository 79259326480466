<!--Desktop version-->
<ng-container  *ngIf="desktopVersion">
  <div class="payment-desktop-container client-typography">
    <div class="d-flex flex-row justify-content-center align-items-center h-100 ">
      <div class="row payment-desktop">

        <div *ngIf="isSuccessful" class="pop-up-desktop pt-2" [@fadeAnimation]
             [ngStyle]="{'background-color': isSuccessful ? 'rgb(232 241 238)' : '#f8e5e5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">
          <p style="color:#479D84" class="d-flex flex-row justify-content-center align-items-center">
            <mat-icon class="mr-2">check</mat-icon>
            {{paymentResponse}}</p>
          <p>{{'CLIENT.auth-payment.success_msg' | translate}}.</p>
          <p style="color:#B60000;cursor: pointer" (click)="dismissPopUp()" class="d-flex flex-row justify-content-center align-items-center">
            <mat-icon>cancel</mat-icon>
            {{'CLIENT.auth-payment.dismiss' | translate}}
          </p>
        </div>
        <div class="col-md-12 pop-up-desktop pt-2" [@fadeAnimation] *ngIf="isError"
             [ngStyle]="{'background-color': isSuccessful ? 'rgba(71, 157, 132, 0.1)' : '#f8e5e5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">
          <p style="color:#B60000" class="text-center">Payment failed.</p>
          <p style="color:#B60000;cursor: pointer" class="text-center d-flex flex-row justify-content-center align-items-center"
             (click)="dismissPopUp()">
            <mat-icon class="mr-2">cancel</mat-icon>
            {{'CLIENT.auth-payment.dismiss' | translate}}
          </p>
        </div>

        <div class="col-6 payment-action px-5 py-5">
          <div class="container-fluid client-typography" style="padding-right: 0px !important;padding-left: 0px;">
            <div class="payment-heading">
              <span class="text-muted">Pagos</span>
              <h2>{{'CLIENT.auth-payment.make_a_payment' | translate}}</h2>
            </div>
          </div>
          <div class="container" style="padding: 0px;">
            <!-- Card Form -->
            <div class="mt-3 w-100">
              <div class="w-100">
                <form [formGroup]="form" *ngIf="form" class="w-100">
                  <!--Card id hidden-->
                  <app-input type="number" formControlName="card_id" appearance="standard" [hidden]="true"
                             [clientTypography]="true" [fullWidth]="true">
                  </app-input>
                  <!-- Amount -->
                  <app-input type="number" formControlName="amount" appearance="standard" [requiredStar]="true"
                             [clientTypography]="true" [showClear]="false"
                             [label]="'PAYMENTS.amount' | translate" [fullWidth]="true" [extraLabel]="false">
                  </app-input>
                  <!-- Card number -->
                  <app-input type="text" formControlName="card_number" appearance="standard" [requiredStar]="true"
                             [clientTypography]="true" [inputMode]="'numeric'"
                             [label]="'CLIENT.auth-payment.card_number' | translate" [fullWidth]="true"
                             [extraLabel]="false"
                             (click)="findCard($event.target.value)">
                  </app-input>
                  <p style="margin-bottom: 0px;font-size: 17px;">{{'CLIENT.auth-payment.expiry_date' | translate}}</p>
                  <div class="card card-dropdown" *ngIf="paymentCardsFiltered && paymentCardsFiltered.length >0">
                    <div *ngFor="let card of paymentCardsFiltered"
                         class="d-flex flex-row mt-3 justify-content-between align-items-center">
                      <div (click)="chooseExistingCard(card)">
                        <img *ngIf="card.card_brand === 'Visa'" class="brand-img" style="width: 50px;"
                             src="assets/img/visaIcon.png" alt="">
                        <img *ngIf="card.card_brand === 'Master'" class="brand-img" style="width: 50px;"
                             src="assets/img/masterCardIcon.png" alt="">
                        XXXX-XXXX-XXXX-{{card.card_last_four}}
                      </div>
                      <span style="color: #242E61;float:right">Remove</span>
                    </div>
                  </div>
                  <!--Expires in-->
                  <div class="d-flex flex-row w-100" *ngIf="!isExistingCard">
                    <div class="w-50 mr-1">
                      <app-input type="number" formControlName="expiry_month" appearance="standard"
                                 [requiredStar]="true"
                                 [label]="'CLIENT.auth-payment.exp_month'| translate" class="mr-2" [fullWidth]="true"
                                 [extraLabel]="false"
                                 [clientTypography]="true">
                      </app-input>
                    </div>
                    <div class="w-50">
                      <app-input *ngIf="!isExistingCard" type="number" formControlName="expiry_year"
                                 appearance="standard"
                                 [requiredStar]="true" [clientTypography]="true"
                                 [label]="'CLIENT.auth-payment.exp_year'| translate" [fullWidth]="true"
                                 [extraLabel]="false">
                      </app-input>
                    </div>
                  </div>
                  <!-- CVV -->
                  <app-input *ngIf="!isExistingCard" type="number" formControlName="cvv" appearance="standard"
                             [requiredStar]="true" [clientTypography]="true"
                             label="CVV" [fullWidth]="true" [extraLabel]="false">
                  </app-input>
                  <!--Save card-->
                  <app-input *ngIf="!isExistingCard" type="checkbox" class="text-small" formControlName="save_card"
                             [label]="'CLIENT.auth-payment.save_details' | translate" [extraLabel]="false"
                             [clientTypography]="true">
                  </app-input>
                </form>
                <div *ngIf="executingPayment">
                  <div class="text-center" *ngIf="!paymentResponse">
                    <h2>{{'CARD-INFO.processing' | translate}}</h2>
                    <mat-spinner style="margin: auto"></mat-spinner>
                  </div>
                </div>
             <!--   <div>
                  <div *ngIf="paymentResponse && isSuccessful">
                    <h1 class="text-center">{{paymentResponse}}</h1>
                    <p class="text-center">
                      <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary" ligature="home">
                        check_circle
                      </mat-icon>
                    </p>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
          <!-- Submit -->
          <div>
            <button *ngIf="submitType === 'existing_card'"
                    class="w-100 payment-btn" type="button" (click)="submitExistingCardPayment(form)">Pagar
            </button>
            <button *ngIf="submitType === 'new_card'" type="button" class="payment-btn"
                    (click)="submitNewCardPayment(form)">Pagar
            </button>
          </div>
        </div>
        <div class="col-6 payment-info px-5 py-5">
          <div class="heading d-flex flex-row w-100">
            <div class="d-flex flex-column mr-5 mb-2">
              <span class="text-muted">{{'CLIENT.auth-payment.amount_due' | translate}}</span>
              <h2 class="payment-info-value">&euro;{{amountToCharge}}</h2>
            </div>
            <div class="d-flex flex-column">
              <span class="text-muted">{{'CLIENT.auth-payment.date_due' | translate}}</span>
              <h2 class="payment-info-value">{{dueDate | date:'d/M/y'}}</h2>
            </div>
          </div>
          <!--Pay safe text container-->
          <div
            class="col-icons pay-safe-text d-flex flex-column justify-content-center align-items-start">
            <p style="color: #479D84;"><i class="fas fa-lock mr-2"></i>Pago seguro.</p>
            <p style="color: #479D84;"><i class="fas fa-shield-alt mr-2"></i>Su información es segura.</p>
            <p style="color: #479D84;"><i class="fab fa-cc-visa mr-2"></i>Aceptamos Visa/Mastercard</p>
            <img src="../../../../../assets/img/masterCardAndVisa.png" alt="" style="width: 22%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--Mobile version-->
<ng-container *ngIf="!desktopVersion">
  <div class="container-fluid client-typography" style="padding-right: 0px !important;padding-left: 0px;">
    <div *ngIf="isSuccessful" class="pop-up" [@fadeAnimation]
         [ngStyle]="{'background-color': isSuccessful ? 'rgb(232 241 238)' : '#f8e5e5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">
      <p style="color:#479D84" class="d-flex flex-row justify-content-center align-items-center">
        <mat-icon>check</mat-icon>
        {{paymentResponse}}</p>
      <p>{{'CLIENT.auth-payment.success_msg' | translate}}.</p>
      <p style="color:#B60000; cursor:pointer;" class="d-flex flex-row justify-content-center align-items-center" (click)="dismissPopUp()">
        <mat-icon>cancel</mat-icon>
        {{'CLIENT.auth-payment.dismiss' | translate}}
      </p>
    </div>
    <div class="pop-up" [@fadeAnimation] *ngIf="isError"
         [ngStyle]="{'background-color': isSuccessful ? 'rgba(71, 157, 132, 0.1)' : '#f8e5e5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">
      <p style="color:#B60000">{{'CLIENT.auth-payment.payment_failed' | translate}}.</p>
      <p style="color:#B60000; cursor:pointer;" class="d-flex flex-row justify-content-center align-items-center"
         (click)="dismissPopUp()">
        <mat-icon>cancel</mat-icon>
        {{'CLIENT.auth-payment.dismiss' | translate}}
      </p>
    </div>

    <div class="payment-heading mx-2">
      <h2>{{'CLIENT.auth-payment.make_a_payment' | translate}}</h2>
    </div>
  </div>
  <div class="container">
    <!-- Card Form -->
    <div class="row mt-3">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <form [formGroup]="form" *ngIf="form">
          <!--Card id hidden-->
          <app-input type="number" formControlName="card_id" appearance="standard" [hidden]="true"
                     [clientTypography]="true">
          </app-input>
          <!-- Amount -->
          <app-input type="number" formControlName="amount" appearance="standard" [requiredStar]="true"
                     [clientTypography]="true" [showClear]="false"
                     [label]="'PAYMENTS.amount' | translate" [fullWidth]="true" [extraLabel]="false">
          </app-input>
          <!-- Card number -->
          <app-input type="text" [inputMode]="'numeric'" formControlName="card_number" appearance="standard"
                     [requiredStar]="true"
                     [clientTypography]="true"
                     [label]="'CLIENT.auth-payment.card_number' | translate" [fullWidth]="true" [extraLabel]="false"
                     (click)="findCard($event.target.value)">
          </app-input>
          <div class="card"
               style="margin-top: -19px !important;max-height: 150px;overflow-y: scroll;padding-left: 13px;padding-right: 13px; width: 100%"
               *ngIf="paymentCardsFiltered && paymentCardsFiltered.length >0">
            <div *ngFor="let card of paymentCardsFiltered"
                 class="d-flex flex-row mt-3 justify-content-between align-items-center">
              <div (click)="chooseExistingCard(card)">
                <img *ngIf="card.card_brand === 'Visa'" class="brand-img" style="width: 50px;"
                     src="../../../../assets/img/visaIcon.png" alt="">
                <img *ngIf="card.card_brand === 'Master'" class="brand-img" style="width: 50px;"
                     src="../../../../assets/img/masterCardIcon.png" alt="">
                XXXX-XXXX-XXXX-{{card.card_last_four}}
              </div>
              <span style="color: #242E61;float:right">Remove</span>
            </div>
          </div>
          <p style="margin-bottom: 0px;font-size: 17px">{{'CLIENT.auth-payment.expiry_date' | translate}}</p>
          <!--Expires in-->
          <div class="d-flex flex-row" *ngIf="!isExistingCard" style="width: 100%">
            <div style="width: 50%">
              <app-input type="number" formControlName="expiry_month" appearance="standard" [requiredStar]="true"
                         [label]="'CLIENT.auth-payment.exp_month' | translate" class="mr-2" [fullWidth]="true"
                         [extraLabel]="false"
                         [clientTypography]="true">
              </app-input>
            </div>
            <div style="width: 50%">
              <app-input *ngIf="!isExistingCard" type="number" formControlName="expiry_year" appearance="standard"
                         [requiredStar]="true" [clientTypography]="true"
                         [label]="'CLIENT.auth-payment.exp_year' | translate" [fullWidth]="true" [extraLabel]="false">
              </app-input>
            </div>
          </div>
          <!-- CVV -->
          <app-input *ngIf="!isExistingCard" type="number" formControlName="cvv" appearance="standard"
                     [requiredStar]="true" [clientTypography]="true"
                     label="CVV" [fullWidth]="true" [extraLabel]="false">
          </app-input>
          <!--Save card-->
          <app-input *ngIf="!isExistingCard" type="checkbox" class="text-small" formControlName="save_card"
                     [label]="'CLIENT.auth-payment.save_details' | translate" [extraLabel]="false"
                     [clientTypography]="true">
          </app-input>
        </form>
        <div *ngIf="executingPayment">
          <div class="text-center" *ngIf="!paymentResponse">
            <h2>{{'CARD-INFO.processing' | translate}}</h2>
            <mat-spinner style="margin: auto"></mat-spinner>
          </div>
        </div>
       <!-- <div>
          <div *ngIf="paymentResponse && isSuccessful">
            <h1 class="text-center">{{paymentResponse}}</h1>
            <p class="text-center">
              <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary" ligature="home">
                check_circle
              </mat-icon>
            </p>
          </div>
        </div>-->
      </div>
    </div>
  </div>


  <!-- Submit -->
  <div class="text-right d-flex mx-2" style="margin-bottom: 6rem;margin-top: 1rem;">
    <button class="correct-btn w-100" *ngIf="submitType === 'existing_card'"
            type="button" (click)="submitExistingCardPayment(form)">Pagar
    </button>
    <button *ngIf="submitType === 'new_card'" type="button"
            class="correct-btn w-100" (click)="submitNewCardPayment(form)">Pagar
    </button>
  </div>
</ng-container>
