import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ClientService } from '../../client.service';

@Component({
  selector:    'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls:   ['./success-page.component.scss'],
})
export class SuccessPageComponent implements OnInit {
  public authUser: User;
  public caseManager: User;
  private caseUuid: string;
  public isLoadingAgent = 0;

  constructor(private route: ActivatedRoute,
              private globalEventsService: MainGlobalEventService,
              private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.caseUuid = this.route.snapshot.queryParamMap.get('caseUuid');
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (this.authUser) {
        this.isLoadingAgent++;
        this.clientService.getCaseDepartmentUser(this.caseUuid, 'case-manager')
            .pipe(finalize(() => this.isLoadingAgent--)).subscribe(
            result => this.caseManager = result.data,
        );
      }
    });
  }

}
