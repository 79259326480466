<div *ngIf="isLoading || serverResponse" class="card mb-3">
  <div class="card-body">
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
  </div>
</div>

<div class="row outcome-wrap mb-5">
  <div class="offset-4"></div>
  <div class="col-sm-4">
    <div class="card shadow">
      <div class="card-header text-center selected">
        <h2 class="m-0">Loan Calculator</h2>
      </div>
      <div class="card-body">
        <form *ngIf="form" [formGroup]="form">
          <div class="outcome-input d-flex justify-content-between">
            <p>{{ 'CONFIG.misc.apr_rate' | translate }}</p>
            <app-input type="select" formControlName="apr_rate" [showClear]="false"
              [label]="'CONFIG.misc.apr_rate' | translate" [selectOptions]="aprRateOptions"
              [selectLabel]="'label'" [selectValue]="'value'">
            </app-input>
            <app-input type="number" formControlName="tin_rate" hidden></app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.outcomes.original_debt_amount' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="original_debt_amount" [showClear]="false"
                       [label]="'CASE_CREDITOR.model.outcomes.original_debt_amount' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.outcomes.debt_settlement_amount' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="debt_settlement_agreed" [showClear]="false"
                       [label]="'CASE_CREDITOR.model.outcomes.debt_settlement_amount' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.outcomes.new_loan_term' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="phase_one_duration" [showClear]="false"
                       [label]="'CASE_CREDITOR.model.outcomes.new_loan_term' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.percent_reduction' | translate}}</p>
            <h4 [ngClass]="percentageSaved >= 70 ? 'text-success' : 'text-danger'">{{percentageSaved.toFixed(2)}}</h4>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.outcomes.total_reduction' | translate}}</p>
            {{form.get("total_reduction").value}}
            <app-input type="number" formControlName="total_reduction" hidden></app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.outcomes.debt_reduction_fee' | translate}}</p>
            {{form.get("debt_reduction_fee").value}}
            <app-input type="number" formControlName="debt_reduction_fee" hidden></app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.outcomes.new_loan_amount' | translate}}</p>
            {{form.get("new_loan_amount").value}}
            <app-input type="number" formControlName="new_loan_amount" hidden></app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.outcomes.total_repayable' | translate}}</p>
            {{form.get("repayable_amount").value}}
            <app-input type="number" formControlName="repayable_amount" hidden></app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASE_CREDITOR.model.outcomes.monthly_repayments' | translate}}</p>
            {{form.get("phase_one_monthly_fee").value}}
            <app-input type="number" formControlName="phase_one_monthly_fee" hidden></app-input>
          </div>
          <div class="outcome-input d-flex justify-content-start">
            <app-spinner-btn type="submit" [loading]="submittingPaymentPlan" [name]="'SHARED.save' | translate"
                             [disabled]="form?.invalid"
                             (click)="submitPaymentPlanForm(form)">
            </app-spinner-btn>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
