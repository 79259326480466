<h1 mat-dialog-title>Actualizar datos</h1>
<div mat-dialog-content>
    <mat-form-field appearance="outline" color="primary" class="w-100 client-input">
      <mat-label>{{"USER.settings.form-details.first_name" | translate}}</mat-label>
      <input #firstName matInput type="text" [formControl]="form">
    </mat-form-field>

  <div class="pb-3 pt-3" style="border-top: 1px solid #CDCDCD;">
    <button class="btn save-btn" (click)="submit()">Salvar cambios</button>
  </div>
  <div>
    <button class="btn cancel-btn" (click)="closeModal()">Anular</button>
  </div>


</div>
