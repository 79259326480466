<h1>{{"CLIENT-PORTAL.validator-form.signature.heading" | translate}}</h1>
<div class="row justify-content-center">
  <div class="col-md-12 col-lg-8 col-xl-6">
    <div (window:resize)="onResize()" class="signature-wrap">
      <signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
    </div>
  </div>
</div>
<!--<div class="text-right pt-5">-->
<!--  <button  mat-button mat-stroked-button color="primary"-->
<!--           class="client-button-empty"-->
<!--           (click)="clear()">-->
<!--    {{"CLIENT-PORTAL.validator-form.signature.clear-signature" | translate}}-->
<!--  </button>-->

<!--  <button [disabled]="signatureImg === ''" mat-button mat-raised-button color="primary"-->
<!--          class="ml-2 client-button"-->
<!--          (click)="submitSignature()">-->
<!--    {{"SHARED.submit" | translate}}-->
<!--  </button>-->
<!--</div>-->

<div class="d-flex justify-content-center pt-5">
  <div style="min-width: 125px" class="d-flex justify-content-center align-items-center">
    <button class="mr-2 btn client-button-incorrect w-100"
            (click)="clear()">
      {{"CLIENT-PORTAL.validator-form.signature.clear-signature" | translate}}
    </button>
  </div>
  <div style="min-width: 125px" class="d-flex justify-content-center align-items-center">
    <button [disabled]="signatureImg === ''" *ngIf="!isLoading" class="ml-2 btn client-button-correct w-100"
            (click)="submitSignature()">
      {{ "CLIENT-PORTAL.validator-form.signature.submit" | translate }}
    </button>
    <mat-spinner *ngIf="isLoading" diameter="25" color="primary"></mat-spinner>
  </div>
</div>
