import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelPaginatorResponse, LaravelResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { TaskTemplate } from '../../../../../_base-shared/models/Task/TaskTemplate';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TaskTemplateService extends MainBaseApiService {

  index(params = {}) {
    return this.http.get<LaravelResponse<Array<TaskTemplate>>>(this.apiUrl + '/task-templates', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  store(taskTemplate: TaskTemplate, params = {}) {
    return this.http.post<LaravelResponse<TaskTemplate>>(this.apiUrl + '/task-templates', taskTemplate, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  show(id: number, params = {}) {
    return this.http.get<LaravelResponse<TaskTemplate>>(this.apiUrl + '/task-templates/' + id, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  update(id: number, taskTemplate: TaskTemplate, params = {}) {
    return this.http.patch<LaravelResponse<TaskTemplate>>(
        this.apiUrl + '/task-templates/' + id, taskTemplate, {params},
    )
        .pipe(catchError(response => this.handleError(response)));
  }

  destroy(id: number, params = {}) {
    return this.http.delete<LaravelResponse>(this.apiUrl + '/task-templates/' + id, {params})
        .pipe(catchError(response => this.handleError(response)));
  }
}
