import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppAlert } from '../../../../../../_base-shared/contracts/app-alert.interface';
import { LaravelResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { QuickNoteComponent } from '../case-detail/quick-note/quick-note.component';
import { CaseService } from '../case.service';

@Component({
  selector:    'app-case-editor',
  templateUrl: './case-editor.component.html',
  styles:      [
    `
        .mat-icon {
            font-size: 20px;
        }
    `],
})
export class CaseEditorComponent implements OnInit, OnDestroy {
  public caseId: number;
  public case: Case;
  public tabs: Array<{ label: string, path: Array<string | number> | string }>;
  public isLoading                    = 0;
  public serverResponse: LaravelResponse;
  public localAlerts: Array<AppAlert> = [];
  public quickNotesDialogOpened       = false;
  public editorType: 'edit' | 'create';
  public hasUnapproved                = false;

  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          this.caseId = +params.get('id');
          this.buildTabs(this.editorType, this.caseId);
          this.fetchCase(this.caseId);
        });
      } else {
        this.buildTabs(this.editorType);
      }
    });
  }

  ngOnDestroy(): void {
    console.log('destroying case editor');
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'q') { //  Open dialog on 'ctrl' + 'q'
      if (!this.quickNotesDialogOpened) { //  Check if dialog is not already opened
        const dialog = this.dialog.open(QuickNoteComponent, {
          width: '50%',
          data:  {case: this.case},
        });
        dialog.afterOpened().subscribe(result => {
          this.quickNotesDialogOpened = true;
        });
        dialog.afterClosed().subscribe(result => {
          this.quickNotesDialogOpened = false;
        });
      }
    }
  }

  private fetchCase(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.get(caseId).pipe(finalize(() => this.isLoading--)).subscribe(
            result => this.case = result.data,
            err => this.serverResponse = err.error,
        ),
    );
  }

  // TODO:
  changeUnapprovedStatus(event) {
    this.hasUnapproved = event;
  }

  private buildTabs(editorType: 'edit' | 'create', caseId: number = null): void {
    this.tabs = [];
    if (editorType === 'create') {
      this.tabs = [
        {label: this.translate.instant('CASES.editor.general.heading'), path: ['/cases', 'create', 'general']},
      ];
    }
    if (editorType === 'edit') {
      this.tabs = [
        {
          label: this.translate.instant('CASES.editor.general.heading'),
          path:  ['/cases', caseId, 'edit', 'general'],
        },
        {
           label: this.translate.instant('CASES.editor.creditors.heading'),
           path:  ['/cases', caseId, 'edit', 'creditors'],
        },
        {
          label: this.translate.instant('CASES.editor.assets.heading'),
          path:  ['/cases', caseId, 'edit', 'assets'],
        },
        {
          label: this.translate.instant('CASES.editor.incomes.heading'),
          path:  ['/cases', caseId, 'edit', 'incomes'],
        },
        {
          label: this.translate.instant('CASES.editor.expenses.heading'),
          path:  ['/cases', caseId, 'edit', 'expenses'],
        },
        {
          label: this.translate.instant('CASES.editor.outcome.heading'),
          path:  ['/cases', caseId, 'edit', 'outcome'],
        },
        {
          label: this.translate.instant('CASES.editor.payment.heading'),
          path:  ['/cases', caseId, 'edit', 'payment'],
        },
      ];
    }
  }
}
