import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-headline-reports',
  templateUrl: './headline-reports.component.html',
  styleUrls: ['./headline-reports.component.scss']
})
export class HeadlineReportsComponent implements OnInit {
  public clientStatuses;
  public totalUnpaidFee = 0;
  public cancellationPercent = 0;
  public defaultPercent = 0;
  public clientsGestion = 0;
  public clientsRedaccion = 0;
  public clientsAep = 0;
  public clientsJudicial = 0;
  public frozenClients = 0;
  public buscarNotaria = 0;
  public notariaCitado = 0;
  public planDePagos = 0;
  public demandaPresentada = 0;
  public feesThisMonth = 0;
  public documentacionCompleta = 0;
  public notariaCitadoLastMonth = 0;
  public clientsExonerated = 0;
  public extraJudicial = 0;
  public clientsPrestamoActivo = 0;
  public loansOptions = [];

  constructor(private reportService: ReportService) { }

  ngOnInit(): void {
    this.reportService.headlineReports().subscribe(value => {
      this.totalUnpaidFee = value.data.total_unpaid_fees;
      this.cancellationPercent = value.data.cancel_percent;
      this.defaultPercent = value.data.default_percent;
      this.clientStatuses = value.data.cases_per_status;
      this.documentacionCompleta = value.data.documentacion_completa;
      this.notariaCitadoLastMonth = value.data.notaria_citado;
      this.clientsExonerated = value.data.exonerated;
      this.extraJudicial = value.data.extra_judicial;
      this.clientsJudicial = value.data.judicial;
      this.frozenClients = value.data.pago_aplazado;
      this.loansOptions = value.data.loans;

      value.data.cases_per_super_status.map(status => {
        if (status.id === 2) {
          this.clientsGestion = status.case_count;
        }
        if (status.id === 3) {
          this.clientsRedaccion = status.case_count;
        }
        if (status.id === 4) {
          this.clientsAep = status.case_count;
        }
        if (status.id === 5) {
          // this.clientsJudicial = status.case_count;
        }
      });
      value.data.cases_per_status.map(status => {
        if (status.id === 32) {
          this.buscarNotaria = status.case_count;
        }
        if (status.id === 36) {
          this.notariaCitado = status.case_count;
        }
        if (status.id === 46) {
          this.planDePagos = status.case_count;
        }
        if (status.id === 49) {
          this.demandaPresentada = status.case_count;
        }
        if (status.id === 15) {
          this.clientsPrestamoActivo = status.case_count;
        }
      });
    });
  }

}
