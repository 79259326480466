import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment/moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Product } from '../../../../../../../../_base-shared/models/Product';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';
import { ProductService } from '../../../product.service';

@Component({
  selector:    'app-demand-generator',
  templateUrl: './demand-generator.component.html',
  styles:      [],
})
export class DemandGeneratorComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  public isLoading                           = 0;
  public productControl                      = new UntypedFormControl(null);
  public form: UntypedFormGroup;
  public isSubmitting: boolean;
  public serverResponse: LaravelResourceResponse;
  public products: Array<Product>;
  public selectedProduct: Product;
  public productOptionOptions: Array<AppSelectOption>;
  private subscriptions: Array<Subscription> = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { case: Case },
    @Optional() public dialogRef: MatDialogRef<DemandGeneratorComponent>,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
    private caseService: CaseService,
    private caseDocumentService: CaseDocumentService,
    private productService: ProductService,
  ) {
  }

  get nonLiquidableAssets() {
    return this.form.get(['liquidation_of_assets', 'non_liquidable_assets']) as UntypedFormArray;
  }

  get oneOwnedAssets() {
    return this.form.get(['contest_without_mass', 'one_owner_of_assets', 'assets']) as UntypedFormArray;
  }

  get twoOwnedAssets() {
    return this.form.get(['contest_without_mass', 'two_owner_of_assets', 'assets']) as UntypedFormArray;
  }

  get threeOwnedAssets() {
    return this.form.get(['contest_without_mass', 'three_owner_of_assets', 'assets']) as UntypedFormArray;
  }

  ngOnInit(): void {
    this.fetchProducts();
    if (this.data && this.data.case) {
      this.case = this.data.case;
      if (this.case.product) {
        this.updateSelectedProduct(this.case.product);
        this.buildProductOptionList(this.selectedProduct);
        this.buildForm(this.productOptionOptions[0].value.toString());
      } else {
        this.fetchCaseProduct(this.case);
      }
    } else {
      this.route.parent.paramMap.subscribe(params => {
        this.fetchCase(+params.get('id'));
      });
    }
    this.translate.onLangChange.subscribe(() => this.buildProductOptionList(this.selectedProduct));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseDocumentService.generateDocument(this.case.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'demanda_' + this.selectedProduct.slug + '_' + moment().format('YYYY-MM-DD_HH-mm') +
            '.' + (form.get('type').value === 'pdf' ? 'pdf' : 'doc');
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded demanda document');
        }
      }, err => {
        this.toastr.error('Failed to generate Demanda document');
      });
  }

  public rebuildFormBasedOnProduct(productId: number) {
    if (!this.products || !this.products.length) {
      return;
    }
    this.selectedProduct = this.products.find(product => product.id === productId);
    this.buildProductOptionList(this.selectedProduct);
    this.form?.get('document_type_slug').patchValue(this.productOptionOptions[0].value, {onlySelf: false});
    this.form?.get('document_type_slug').updateValueAndValidity();

    // this.form            = null;
    // this.isLoading++;
    // setTimeout(() => {
    //   this.buildProductOptionList(this.selectedProduct);
    //   this.buildForm(this.selectedProduct);
    //   this.isLoading--;
    // }, 500);
  }

  public changeFormForDocumentType(documentTypeSlug: string) {
    this.form = null;
    this.isLoading++;
    setTimeout(() => {
      this.buildForm(documentTypeSlug);
      this.isLoading--;
    }, 500);
  }

  public addNonLiquidableAsset() {
    this.nonLiquidableAssets.push(this.fb.group({name: [null, [Validators.required]]}));
  }

  public addOneOwnedAsset() {
    this.oneOwnedAssets.push(this.fb.group({description: [null, [Validators.required]]}));
  }

  public addTwoOwnedAsset() {
    this.twoOwnedAssets.push(this.fb.group({
      description: [null, [Validators.required]],
      burden:      [null, [Validators.required]],
    }));
  }

  public addThreeOwnedAsset() {
    this.threeOwnedAssets.push(this.fb.group({
      description: [null, [Validators.required]],
    }));
  }

  private buildForm(documentTypeSlug: string) {
    const formGroupData: { [key: string]: any } = {
      document_type_slug:     [documentTypeSlug, [Validators.required]],
      city:                   [null, [Validators.required]],
      procurator:             [null, [Validators.required]],
      attorney_accredited:    [null, [Validators.required]],
      personal_circumstances: this.fb.group({
        profession: [null, [Validators.required]],
      }),
      state_of_insolvency:    this.fb.group({
        overdue_obligations: this.fb.group({
          ongoing_proceedings: [null, [Validators.required]],
        }),
      }),
      signature_date:         [null, [Validators.required]],
      lawyer:                 [null, [Validators.required]],
      type:                   [null],
    };

    if (documentTypeSlug === 'demand-lso-te-with-assets') {
      formGroupData.contest_without_mass = this.fb.group({
        one_owner_of_assets:   this.fb.group({
          include: [false],
          assets:  this.fb.array([
            this.fb.group({
              description: [null],
            }),
          ]),
        }),
        two_owner_of_assets:   this.fb.group({
          include: [false],
          assets:  this.fb.array([
            this.fb.group({
                description: [null],
                burden:      [null],
              },
            ),
          ]),
        }),
        three_owner_of_assets: this.fb.group({
          include: [false],
          value:   [null],
          assets:  this.fb.array([
            this.fb.group({
              description: [null],
            }),
          ]),
        }),
      });
    }

    if (documentTypeSlug === 'demand-lso-lq' || documentTypeSlug === 'demand-lso-pp') {
      formGroupData.liquidation_of_assets = this.fb.group({
        non_liquidable_assets: this.fb.array([
          this.fb.group({name: [null, [Validators.required]]}),
        ]),
      });
    }

    this.form = this.fb.group(formGroupData);
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.products = result.data,
    );
  }

  private fetchCaseProduct(clientCase: Case) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(clientCase.id, ['product']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.updateSelectedProduct(result.data.product);
          this.buildProductOptionList(this.selectedProduct);
          this.buildForm(this.productOptionOptions[0].value.toString());
        }),
    );
  }

  private fetchCase(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId, ['product']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          this.updateSelectedProduct(this.case.product);
          this.buildProductOptionList(this.selectedProduct);
          this.buildForm(this.productOptionOptions[0].value.toString());
        }),
    );
  }

  private buildProductOptionList(selectedProduct: Product) {
    if (selectedProduct.slug === 'lso-te') {
      this.productOptionOptions = [
        {
          label: this.translate.instant('CASES.single.draft.demand_generator.demand_lso_te_no_assets'),
          value: 'demand-lso-te-no-assets',
        },
        {
          label: this.translate.instant('CASES.single.draft.demand_generator.demand_lso_te_with_assets'),
          value: 'demand-lso-te-with-assets',
        },
      ];
    } else {
      this.productOptionOptions = [
        {label: 'Demand ' + selectedProduct.name, value: 'demand-' + selectedProduct.slug},
      ];
    }
  }

  private updateSelectedProduct(product: Product) {
    this.selectedProduct = product;
    this.productControl.patchValue(product.id, {onlySelf: true});
  }

  public chooseDocsType(type: 'doc' | 'pdf', submitedForm: UntypedFormGroup) {
    this.form.get('type').patchValue(type);
    this.form.get('type').updateValueAndValidity();
    this.submitForm(submitedForm);
  }
}
