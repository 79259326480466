<div class="footer mt-5 col-12 row d-flex flex-column justify-content-center align-items-start">
  <div class="col-lg-7 col-11 d-flex justify-content-center mt-5" #colSeven>
    <div class="d-flex flex-column" style="width: 654px">
      <div class="logo-wrapper d-flex align-items-start mb-3 mt-3">
        <a href=" https://devuelve.es/">
          <img src="assets/img/logos/novosys_white_transparent.png" alt="Logo"
               style="width: 168px;height: 27px;">
        </a>
      </div>
    </div>
  </div>
</div>
<div class="col-12 linksDivWrapper pb-4" [ngStyle]="{'margin-left': styleObj + 'px'}">
  <!--<p class="poppins-14">Devuelve es una marca comercial de Simply Legal SL, empresa registrada en la Calle Diego de-->
  <!--  León, 47, Madrid, CP 28006, con CIF B56253362.</p>-->
  <div class="d-flex flex-column flex-lg-row col-6 justify-content-between links">
    <a class="poppins-14" href="https://novomoney.es/Condiciones/">Condiciones generales</a>
    <a class="poppins-14" href="https://novomoney.es/Privacidad/"> Política de privacidad</a>
    <a class="poppins-14" href="https://novomoney.es/Cookies/"> Política de cookies</a>
    <a class="poppins-14" href=" https://novomoney.es/Aviso-legal/"> Aviso Legal</a>
  </div>
</div>
