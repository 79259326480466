<div *ngIf="case" class="card shadow card-height detail-documents mb-4">
  <div class="card-header py-4">
    <div class="d-flex">
      <h1>{{ "CASES.single.documents.heading" | translate }}</h1>
    </div>
    <div class="d-flex get-files-btns">
      <a mat-raised-button color="primary" [disabled]="!contractPdfLocation" target="_blank"
         href="{{storageUrl + contractPdfLocation}}"
         [title]="!contractPdfLocation ?
          ('CASES.single.get-contract-button-disabled' | translate) :
           ('DOCUMENTS.get-contract' | translate)">
        {{ "DOCUMENTS.get-contract" | translate }}
      </a>
      <a mat-raised-button color="primary" class="ml-3" [disabled]="!redactedContractPdfLocation" target="_blank"
         href="{{storageUrl + redactedContractPdfLocation}}"
      >
        {{ "DOCUMENTS.get-redacted-contract" | translate }}
      </a>
      <!--Get mandate button for single case-->
<!--      <a mat-raised-button color="primary" class="ml-3" *ngIf="!case.joint_application"-->
<!--         [disabled]="!mandatePdfLocation" target="_blank" href="{{storageUrl + mandatePdfLocation}}"-->
<!--         [title]="!mandatePdfLocation ?-->
<!--          ('CASES.single.mandates-button-disabled' | translate) :-->
<!--           ('CASES.single.get-mandates' | translate)">-->
<!--        {{ "CASES.single.get-mandates" | translate }}-->
<!--      </a>-->
      <!--   Get mandate button for joint case   -->
<!--      <a mat-raised-button color="primary" class="ml-3" *ngIf="case.joint_application"-->
<!--         [disabled]="!mandatePdfLocation" target="_blank" href="{{storageUrl + mandatePdfLocation}}"-->
<!--         [title]="!mandatePdfLocation ?-->
<!--          ('CASES.single.mandates-button-disabled' | translate) :-->
<!--           ('CASES.single.get-client-1-mandates' | translate)">-->
<!--        {{ "CASES.single.get-client-1-mandates" | translate }}-->
<!--      </a>-->
<!--      <a mat-raised-button color="primary" class="ml-3" *ngIf="case.joint_application"-->
<!--         [disabled]="!mandatePdfLocationPartner" target="_blank" href="{{storageUrl + mandatePdfLocationPartner}}"-->
<!--         [title]="!mandatePdfLocationPartner ?-->
<!--          ('CASES.single.mandates-button-disabled' | translate) :-->
<!--           ('CASES.single.get-client-2-mandates' | translate)">-->
<!--        {{ "CASES.single.get-client-2-mandates" | translate }}-->
<!--      </a>-->
<!--      <ng-container>-->
<!--        <button [matMenuTriggerFor]="clientRoleMenu" mat-raised-button color="primary" class="ml-2">-->
<!--          {{'CASES.single.send_auto_de_concurso' | translate}}-->
<!--          <mat-icon>arrow_drop_down</mat-icon>-->
<!--        </button>-->
<!--        <mat-menu #clientRoleMenu="matMenu">-->
<!--          <button mat-menu-item (click)="sendAutoDeConscursoDoc('client')" [disabled]="!autoDeConcursoUploaded">-->
<!--            <span>{{'CASES.single.send_to_client' | translate}} 1</span>-->
<!--          </button>-->
<!--          <button mat-menu-item (click)="sendAutoDeConscursoDoc('partner')" [disabled]="!case.joint_application || !autoDeConcursoUploaded">-->
<!--            <span>{{'CASES.single.send_to_client' | translate}} 2</span>-->
<!--          </button>-->
<!--        </mat-menu>-->
<!--      </ng-container>-->
      <div *ngIf="authUser.role_id === 5 || authUser.role_id === 1 || authUser.role_id === 2"
           class="d-flex justify-content-center flex-wrap" style="min-width: 245px; margin-left: auto">
        <div
          *ngIf="authUser.id === 27 || authUser.id === 36 || authUser.id === 3 || authUser.id === 6 || authUser.id === 967 || authUser.id === 235517
                  || authUser.id === 582 || authUser.id === 513023 || authUser.id === 34 || authUser.id === 26 || authUser.role_id === 2"
          class="dropleft d-flex">
          <button [disabled]="!contractPdfLocation" style="min-width: 120px"
                  mat-raised-button color="primary" class="btn dropdown-toggle" type="button"
                  [title]="!contractPdfLocation ? ('DOCUMENTS.resign-contract-disabled' | translate) : ('DOCUMENTS.resign-contract' | translate)"
                  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{"DOCUMENTS.resign-contract" | translate}}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="resignContract($event, 'email', 'client')" href="#"> Borrower Email</a>
            <a class="dropdown-item" (click)="resignContract($event, 'sms', 'client')" href="#"> Borrower SMS</a>
            <a class="dropdown-item" (click)="resignContract($event, 'all', 'client')" href="#"> Borrower Email & SMS</a>
            <a *ngIf="case.joint_application"
               class="dropdown-item" (click)="resignContract($event, 'email', 'partner')" href="#"> Guarantor Email</a>
            <a *ngIf="case.joint_application"
               class="dropdown-item" (click)="resignContract($event, 'sms', 'partner')" href="#"> Guarantor SMS</a>
            <a *ngIf="case.joint_application"
               class="dropdown-item" (click)="resignContract($event, 'all', 'partner')" href="#"> Guarantor Email &
              SMS</a>
          </div>
        </div>
        <button
          *ngIf="authUser.id === 1 || authUser.id === 22 || authUser.id === 27 || authUser.id === 36 || authUser.id === 2497"
          [disabled]="!contractPdfLocation || regenerateSpinner" class="ml-3"
          mat-raised-button color="primary" (click)="regenerateContract()"
          [title]="!contractPdfLocation ? ('DOCUMENTS.regenerate-contract-disabled' | translate) : ('DOCUMENTS.regenerate-contract' | translate)">
          {{"CASES.single.regenerate-contract" | translate}}
        </button>
        <mat-spinner *ngIf="regenerateSpinner" diameter="30" class="m-0 m-auto"></mat-spinner>
        <button *ngIf="case.client" mat-raised-button color="primary" class="ml-3" (click)="verifySignature($event)">
          {{!case.client.signature_verified_at ?
          ("CASES.details.signature-verify" | translate) :
          ("CASES.details.view-signature" | translate)}}
        </button>
      </div>
      <button mat-raised-button color="primary" class="ml-3" (click)="sendSignedContract()">
        {{'CASES.single.send-signed-contract' | translate}}
      </button>
    </div>

<!--    <button *ngIf="advicePackVisible" [matMenuTriggerFor]="advicePack" mat-raised-button color="primary" class="ml-2 mt-2">-->
<!--      {{'CASES.single.resend_advice_pack' | translate}}-->
<!--      <mat-icon>arrow_drop_down</mat-icon>-->
<!--    </button>-->
<!--    <mat-menu #advicePack="matMenu">-->
<!--      <button mat-menu-item (click)="resendAdvicePackEmail(true)">-->
<!--        <span>{{'CASES.single.generate' | translate}}</span>-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="resendAdvicePackEmail(false)">-->
<!--        <span>{{'CASES.single.dont_generate' | translate}}</span>-->
<!--      </button>-->
<!--    </mat-menu>-->
<!--    <button [matMenuTriggerFor]="debtCancellationRequestMenu" mat-raised-button color="primary" class="ml-2 mt-2">-->
<!--      {{ 'CASES.single.documents.debt_cancellation_request.action' | translate }}-->
<!--      <mat-icon>arrow_drop_down</mat-icon>-->
<!--    </button>-->
<!--    <mat-menu #debtCancellationRequestMenu="matMenu">-->
<!--      <button mat-menu-item [routerLink]="['/client/sign-debt-cancellation-request']"-->
<!--              [queryParams]="{uuid: case.uuid, type: 'debt-cancellation-request', sign: 'client'}">-->
<!--        {{ 'CASES.single.documents.debt_cancellation_request.preview' | translate }}-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="requestDebtCancellationRequestSignature()">-->
<!--        {{ 'CASES.single.documents.debt_cancellation_request.request_signature.action' | translate }}-->
<!--      </button>-->
<!--      <button mat-menu-item  (click)="sendEmailToExperian()" [disabled]="!areExperianAttachmentsReady">-->
<!--        {{ 'CASES.single.documents.debt_cancellation_request.send_email_to_experian' | translate }}-->
<!--      </button>-->
<!--    </mat-menu>-->
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitFiles()" class="form">
          <div>
            <div class="row pb-4">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="uploadFile1">{{ "DOCUMENTS.upload-documents" | translate }}</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input multiple type="file" class="custom-file-input" [lang]="lang" id="uploadFile1"
                             formControlName="files" (change)="onFileChange($event)">
                      <label class="custom-file-label" for="uploadFile1">
                        {{ 'DOCUMENTS.choose-file' | translate}}
                      </label>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center" *ngFor="let file of files">
                    <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                    <button mat-icon-button color="warn" (click)="removeFile($event, file.index)">
                      <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
                        remove_circle
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="case.joint_application"
                   class="col-md-2 col-lg-2 inline-form-button d-flex justify-content-between">
                <div class="form-group upload-for" style="margin-right: 0">
                  <label for="uploadFor">{{"CASES.single.upload-for" | translate}}</label>
                  <select id="uploadFor" class="form-control" formControlName="uploadFor"
                          (ngModelChange)="changeUploadFor($event)">
                    <option value="client">{{"CASES.single.client" | translate}}</option>
                    <option value="partner">{{"CASES.single.partner" | translate}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 d-flex">
                <div class="form-group">
                  <label for="status">{{ "DOCUMENTS.select-category" | translate }}</label>
                  <select class="form-control" id="status" formControlName="fileType">
                    <option value="sepa">{{"CASES.single.sepa" | translate}}</option>
                    <option value="contract">{{"CASES.single.document-types.contract" | translate}}</option>
                    <option value="mandate">{{"CASES.single.document-types.mandates" | translate}}</option>
                    <option *ngFor="let type of documentType"
                            [value]="type.id">
                      {{ type.custom ? type.name : ("CASES.single.document-types." + type.name | translate)}}
                    </option>
                  </select>
                </div>
              </div>
              <div style="padding-top: 25px">
                <app-spinner-btn [loading]="uploadSpinner" [name]="'SHARED.submit' | translate"></app-spinner-btn>
              </div>
            </div>
          </div>
        </form>
        <mat-accordion multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "CASES.single.required-documents" | translate }} {{ "CASES.single.client" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-required [case]="case" (closeAccordion)="closeRequestAccordion($event)"
                          [selectedTypes]="case.file_requests" [clientType]="'client'">
            </app-required>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="case.joint_application">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "CASES.single.required-documents" | translate }} {{ "CASES.single.partner" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-required [case]="case" (closeAccordion)="closeRequestAccordion($event)"
                          [selectedTypes]="case.partner_file_requests" [clientType]="'partner'">
            </app-required>
          </mat-expansion-panel>
<!--          &lt;!&ndash;   Creditor documents for client   &ndash;&gt;-->
<!--          <mat-expansion-panel>-->
<!--            <mat-expansion-panel-header>-->
<!--              <mat-panel-title>-->
<!--                {{ "CASES.single.creditor-documents" | translate }} {{ "CASES.single.client" | translate }}-->
<!--              </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <form *ngIf="creditorForm" [formGroup]="creditorForm" (ngSubmit)="submitCreditorFiles('client')"-->
<!--                  class="form">-->
<!--              <div>-->
<!--                <div class="row pb-4">-->
<!--                  <div class="col-lg-4">-->
<!--                    <div class="form-group">-->
<!--                      <label for="creditor-docs-client">{{ "DOCUMENTS.upload-documents" | translate }}</label>-->
<!--                      <div class="input-group">-->
<!--                        <div class="custom-file">-->
<!--                          <input multiple type="file" class="custom-file-input" [lang]="lang" id="creditor-docs-client"-->
<!--                                 formControlName="files" (change)="creditorDocsChange($event, 'client')">-->
<!--                          <label class="custom-file-label" for="uploadFile1">-->
<!--                            {{ 'DOCUMENTS.choose-file' | translate}}-->
<!--                          </label>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="d-flex justify-content-between align-items-center"-->
<!--                           *ngFor="let file of creditorDocsClient">-->
<!--                        <p class="m-0 uploaded-file-name">{{ file.name }}</p>-->
<!--                        <button mat-icon-button color="warn" (click)="removeFile($event, file.index, 'client')">-->
<!--                          <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">-->
<!--                            remove_circle-->
<!--                          </mat-icon>-->
<!--                        </button>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-lg-3 d-flex">-->
<!--                    <div class="form-group">-->
<!--                      <label for="creditor-select">{{ "REPORTS.select-creditor" | translate }}</label>-->
<!--                      <select class="form-control" id="creditor-select" formControlName="fileType">-->
<!--                        <option value="">{{ "REPORTS.select-creditor" | translate }}</option>-->
<!--                        <option *ngFor="let creditor of allCreditors"-->
<!--                                [value]="creditor.pivot ? 'creditor-' + creditor.id : 'publicdebt-' + creditor.id">-->
<!--                          {{ creditor.name }}-->
<!--                        </option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">-->
<!--                    <div style="padding-top: 25px">-->
<!--                      <app-spinner-btn [loading]="uploadSpinnerClient" [name]="'SHARED.submit' | translate">-->
<!--                      </app-spinner-btn>-->
<!--                    </div>-->
<!--                    <div class="ml-2" style="padding-top: 25px">-->
<!--                      <button mat-raised-button color="{{creditorDocumentsVisible ? 'warn' : 'primary'}}"-->
<!--                              (click)="updateDocumentVisibility(!creditorDocumentsVisible, 'case_creditor')">-->
<!--                        {{creditorDocumentsVisible ? 'Hide from client' : 'Show to client'}}-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--            <app-all-creditor-files [case]="case" [filesByCreditor]="files_by_creditor" [updateFiles]="updateFiles"-->
<!--                                    [filesByPublicDebt]="files_by_public_debt" [allCreditors]="allCreditors"-->
<!--                                    [type]="'client'" (documentInfoChange$)="documentInfoChange($event)"-->
<!--                                    (selectedFileEvent)="setFileUrl($event)">-->
<!--            </app-all-creditor-files>-->
<!--          </mat-expansion-panel>-->
<!--          &lt;!&ndash;   Creditor documents for client 2  &ndash;&gt;-->
<!--          <mat-expansion-panel *ngIf="case.joint_application">-->
<!--            <mat-expansion-panel-header>-->
<!--              <mat-panel-title>-->
<!--                {{ "CASES.single.creditor-documents" | translate }} {{ "CASES.single.partner" | translate }}-->
<!--              </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <form *ngIf="creditorFormPartner" [formGroup]="creditorFormPartner"-->
<!--                  (ngSubmit)="submitCreditorFiles('partner')" class="form">-->
<!--              <div>-->
<!--                <div class="row pb-4">-->
<!--                  <div class="col-lg-4">-->
<!--                    <div class="form-group">-->
<!--                      <label for="creditor-docs-partner">{{ "DOCUMENTS.upload-documents" | translate }}</label>-->
<!--                      <div class="input-group">-->
<!--                        <div class="custom-file">-->
<!--                          <input multiple type="file" class="custom-file-input" [lang]="lang" id="creditor-docs-partner"-->
<!--                                 formControlName="files" (change)="creditorDocsChange($event, 'partner')">-->
<!--                          <label class="custom-file-label" for="uploadFile1">-->
<!--                            {{ 'DOCUMENTS.choose-file' | translate}}-->
<!--                          </label>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="d-flex justify-content-between align-items-center"-->
<!--                           *ngFor="let file of creditorDocsPartner">-->
<!--                        <p class="m-0 uploaded-file-name">{{ file.name }}</p>-->
<!--                        <button mat-icon-button color="warn" (click)="removeFile($event, file.index, 'partner')">-->
<!--                          <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">-->
<!--                            remove_circle-->
<!--                          </mat-icon>-->
<!--                        </button>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-lg-3 d-flex">-->
<!--                    <div class="form-group">-->
<!--                      <label for="creditor-select-partner">{{ "REPORTS.select-creditor" | translate }}</label>-->
<!--                      <select class="form-control" id="creditor-select-partner" formControlName="fileType">-->
<!--                        <option value="">{{ "REPORTS.select-creditor" | translate }}</option>-->
<!--                        <option *ngFor="let creditor of allCreditors"-->
<!--                                [value]="creditor.pivot ? 'creditor-' + creditor.id : 'publicdebt-' + creditor.id">-->
<!--                          {{ creditor.name }}-->
<!--                        </option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">-->
<!--                    <div style="padding-top: 25px">-->
<!--                      <app-spinner-btn [loading]="uploadSpinnerPartner" [name]="'SHARED.submit' | translate">-->
<!--                      </app-spinner-btn>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--            <app-all-creditor-files [case]="case" [partnerFilesByCreditor]="partner_files_by_creditor"-->
<!--                                    [updateFiles]="updateFiles"-->
<!--                                    [partnerFilesByPublicDebt]="partner_files_by_public_debt"-->
<!--                                    [allCreditors]="allCreditors" (documentInfoChange$)="documentInfoChange($event)"-->
<!--                                    [type]="'partner'" (selectedFileEvent)="setFileUrl($event)">-->
<!--            </app-all-creditor-files>-->
<!--          </mat-expansion-panel>-->
<!--          &lt;!&ndash;   Legal documents for client  &ndash;&gt;-->
<!--          <mat-expansion-panel>-->
<!--            <mat-expansion-panel-header>-->
<!--              <mat-panel-title>-->
<!--                {{ "CASES.single.legal-documents" | translate }} {{ "CASES.single.client" | translate }}-->
<!--              </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <form *ngIf="legalForm" [formGroup]="legalForm" (ngSubmit)="submitLegalFiles('client')" class="form">-->
<!--              <div class="row pb-4">-->
<!--                <div class="col-lg-4">-->
<!--                  <div class="form-group">-->
<!--                    <label for="legal-docs-client">{{ "DOCUMENTS.upload-documents" | translate }}</label>-->
<!--                    <div class="input-group">-->
<!--                      <div class="custom-file">-->
<!--                        <input multiple type="file" class="custom-file-input" [lang]="lang" id="legal-docs-client"-->
<!--                               formControlName="files" (change)="legalDocsChange($event, 'client')">-->
<!--                        <label class="custom-file-label" for="uploadFile1">-->
<!--                          {{ 'DOCUMENTS.choose-file' | translate}}-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="d-flex justify-content-between align-items-center" *ngFor="let file of legalDocsClient">-->
<!--                      <p class="m-0 uploaded-file-name">{{ file.name }}</p>-->
<!--                      <button mat-icon-button color="warn" (click)="removeLegalFile($event, file.index, 'client')">-->
<!--                        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">-->
<!--                          remove_circle-->
<!--                        </mat-icon>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 d-flex">-->
<!--                  <div class="form-group">-->
<!--                    <label for="legal-select">{{ "DOCUMENTS.select-category" | translate }}</label>-->
<!--                    <select class="form-control" id="legal-select" formControlName="fileType">-->
<!--                      <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>-->
<!--                      <option *ngFor="let legalType of legalDocumentType" [value]="legalType.name">-->
<!--                        {{ legalType.label }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">-->
<!--                  <div style="padding-top: 25px">-->
<!--                    <app-spinner-btn [loading]="uploadSpinnerClientLegal" [name]="'SHARED.submit' | translate">-->
<!--                    </app-spinner-btn>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--            <form *ngIf="legalFormCustom" [formGroup]="legalFormCustom" (ngSubmit)="submitLegalFilesCustom('client')"-->
<!--                  class="form">-->
<!--              <div class="row pb-4">-->
<!--                <div class="col-lg-4">-->
<!--                  <div class="form-group">-->
<!--                    <label for="legal-docs-client-custom">{{ "DOCUMENTS.upload-documents-custom" | translate }}</label>-->
<!--                    <div class="input-group">-->
<!--                      <div class="custom-file">-->
<!--                        <input multiple type="file" class="custom-file-input" [lang]="lang"-->
<!--                               id="legal-docs-client-custom" formControlName="files"-->
<!--                               (change)="legalDocsChangeCustom($event, 'client')">-->
<!--                        <label class="custom-file-label" for="uploadFile1">-->
<!--                          {{ 'DOCUMENTS.choose-file' | translate}}-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="d-flex justify-content-between align-items-center"-->
<!--                         *ngFor="let file of legalDocsClientCustom">-->
<!--                      <p class="m-0 uploaded-file-name">{{ file.name }}</p>-->
<!--                      <button mat-icon-button color="warn"-->
<!--                              (click)="removeLegalFileCustom($event, file.index, 'client')">-->
<!--                        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">-->
<!--                          remove_circle-->
<!--                        </mat-icon>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 d-flex">-->
<!--                  <div class="form-group" style="width: 100%;">-->
<!--                    <label for="legal-select-custom">{{ "DOCUMENTS.enter-custom-name" | translate }}</label>-->
<!--                    <input type="text" class="form-control" id="legal-select-custom" formControlName="fileType"/>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">-->
<!--                  <div style="padding-top: 25px">-->
<!--                    <app-spinner-btn [loading]="uploadSpinnerClientLegalCustom" [name]="'SHARED.submit' | translate">-->
<!--                    </app-spinner-btn>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--            <app-all-legal-files [case]="case" [filesByLegal]="files_by_legal" [updateFiles]="updateFiles"-->
<!--                                 [type]="'client'" (selectedFileEvent)="setFileUrl($event)">-->
<!--            </app-all-legal-files>-->
<!--          </mat-expansion-panel>-->
<!--          <mat-expansion-panel *ngIf="case.joint_application">-->
<!--            <mat-expansion-panel-header>-->
<!--              <mat-panel-title>-->
<!--                {{ "CASES.single.legal-documents" | translate }} {{ "CASES.single.partner" | translate }}-->
<!--              </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <form *ngIf="legalFormPartner" [formGroup]="legalFormPartner" (ngSubmit)="submitLegalFiles('partner')"-->
<!--                  class="form">-->
<!--              <div class="row pb-4">-->
<!--                <div class="col-lg-4">-->
<!--                  <div class="form-group">-->
<!--                    <label for="legal-docs-partner">{{ "DOCUMENTS.upload-documents" | translate }}</label>-->
<!--                    <div class="input-group">-->
<!--                      <div class="custom-file">-->
<!--                        <input multiple type="file" class="custom-file-input" [lang]="lang" id="legal-docs-partner"-->
<!--                               formControlName="files" (change)="legalDocsChange($event, 'partner')">-->
<!--                        <label class="custom-file-label" for="uploadFile1">-->
<!--                          {{ 'DOCUMENTS.choose-file' | translate}}-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="d-flex justify-content-between align-items-center"-->
<!--                         *ngFor="let file of legalDocsPartner">-->
<!--                      <p class="m-0 uploaded-file-name">{{ file.name }}</p>-->
<!--                      <button mat-icon-button color="warn" (click)="removeLegalFile($event, file.index, 'partner')">-->
<!--                        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">-->
<!--                          remove_circle-->
<!--                        </mat-icon>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 d-flex">-->
<!--                  <div class="form-group">-->
<!--                    <label for="legal-select-partner">{{ "DOCUMENTS.select-category" | translate }}</label>-->
<!--                    <select class="form-control" id="legal-select-partner" formControlName="fileType">-->
<!--                      <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>-->
<!--                      <option *ngFor="let legalType of legalDocumentType"-->
<!--                              [value]="legalType.name">-->
<!--                        {{ legalType.label }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">-->
<!--                  <div style="padding-top: 25px">-->
<!--                    <app-spinner-btn [loading]="uploadSpinnerPartnerLegal"-->
<!--                                     [name]="'SHARED.submit' | translate"></app-spinner-btn>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--            <form *ngIf="legalFormCustomPartner" [formGroup]="legalFormCustomPartner"-->
<!--                  (ngSubmit)="submitLegalFilesCustom('partner')" class="form">-->
<!--              <div class="row pb-4">-->
<!--                <div class="col-lg-4">-->
<!--                  <div class="form-group">-->
<!--                    <label for="legal-docs-partner-custom">{{ "DOCUMENTS.upload-documents-custom" | translate }}</label>-->
<!--                    <div class="input-group">-->
<!--                      <div class="custom-file">-->
<!--                        <input multiple type="file" class="custom-file-input" [lang]="lang"-->
<!--                               id="legal-docs-partner-custom"-->
<!--                               formControlName="files" (change)="legalDocsChangeCustom($event, 'partner')">-->
<!--                        <label class="custom-file-label" for="uploadFile1">-->
<!--                          {{ 'DOCUMENTS.choose-file' | translate}}-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="d-flex justify-content-between align-items-center"-->
<!--                         *ngFor="let file of legalDocsPartnerCustom">-->
<!--                      <p class="m-0 uploaded-file-name">{{ file.name }}</p>-->
<!--                      <button mat-icon-button color="warn"-->
<!--                              (click)="removeLegalFileCustom($event, file.index, 'partner')">-->
<!--                        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">-->
<!--                          remove_circle-->
<!--                        </mat-icon>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 d-flex">-->
<!--                  <div class="form-group" style="width: 100%;">-->
<!--                    <label for="legal-select-custom-partner">{{ "DOCUMENTS.enter-custom-name" | translate }}</label>-->
<!--                    <input type="text" class="form-control" id="legal-select-custom-partner"-->
<!--                           formControlName="fileType"/>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">-->
<!--                  <div style="padding-top: 25px">-->
<!--                    <app-spinner-btn [loading]="uploadSpinnerPartnerLegalCustom" [name]="'SHARED.submit' | translate">-->
<!--                    </app-spinner-btn>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--            <app-all-legal-files [case]="case" [filesByLegal]="partner_files_by_legal" [updateFiles]="updateFiles"-->
<!--                                 [type]="'partner'" (selectedFileEvent)="setFileUrl($event)">-->
<!--            </app-all-legal-files>-->
<!--          </mat-expansion-panel>-->

<!--          <mat-expansion-panel>-->
<!--            <mat-expansion-panel-header>-->
<!--              <mat-panel-title>-->
<!--                {{ "CASES.single.court-documents" | translate }} {{ "CASES.single.client" | translate }}-->
<!--              </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <form *ngIf="courtForm" [formGroup]="courtForm" (ngSubmit)="submitCourtFiles('client')" class="form">-->
<!--              <div class="row pb-4">-->
<!--                <div class="col-lg-4">-->
<!--                  <div class="form-group">-->
<!--                    <label for="court-docs-client">{{ "DOCUMENTS.upload-documents" | translate }}</label>-->
<!--                    <div class="input-group">-->
<!--                      <div class="custom-file">-->
<!--                        <input multiple type="file" class="custom-file-input" [lang]="lang" id="court-docs-client"-->
<!--                               formControlName="files" (change)="courtDocsChange($event, 'client')">-->
<!--                        <label class="custom-file-label" for="uploadFile1">-->
<!--                          {{ 'DOCUMENTS.choose-file' | translate}}-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="d-flex justify-content-between align-items-center" *ngFor="let file of courtDocsClient">-->
<!--                      <p class="m-0 uploaded-file-name">{{ file.name }}</p>-->
<!--                      <button mat-icon-button color="warn" (click)="removeCourtFile($event, file.index, 'client')">-->
<!--                        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">-->
<!--                          remove_circle-->
<!--                        </mat-icon>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 d-flex">-->
<!--                  <div class="form-group" style="width: 100%;">-->
<!--                    <label for="legal-select">{{ "DOCUMENTS.select-category" | translate }}</label>-->
<!--                    <select class="form-control" id="court-select" formControlName="fileType">-->
<!--                      <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>-->
<!--                      <option *ngFor="let courtType of courtDocumentType"-->
<!--                              [value]="courtType.name">-->
<!--                        {{ courtType.label }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">-->
<!--                  <div style="padding-top: 25px">-->
<!--                    <app-spinner-btn [loading]="uploadSpinnerClientCourt"-->
<!--                                     [name]="'SHARED.submit' | translate"></app-spinner-btn>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--            <app-all-court-files [case]="case" [filesByCourt]="files_by_court" [updateFiles]="updateFiles"-->
<!--                                 [type]="'client'" (selectedFileEvent)="setFileUrl($event)">-->
<!--            </app-all-court-files>-->
<!--          </mat-expansion-panel>-->
<!--          &lt;!&ndash;   Legal documents for partner  &ndash;&gt;-->
<!--          <mat-expansion-panel *ngIf="case.joint_application">-->
<!--            <mat-expansion-panel-header>-->
<!--              <mat-panel-title>-->
<!--                {{ "CASES.single.court-documents" | translate }} {{ "CASES.single.partner" | translate }}-->
<!--              </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <form *ngIf="courtFormPartner" [formGroup]="courtFormPartner" (ngSubmit)="submitCourtFiles('partner')"-->
<!--                  class="form">-->
<!--              <div class="row pb-4">-->
<!--                <div class="col-lg-4">-->
<!--                  <div class="form-group">-->
<!--                    <label for="court-docs-partner">{{ "DOCUMENTS.upload-documents" | translate }}</label>-->
<!--                    <div class="input-group">-->
<!--                      <div class="custom-file">-->
<!--                        <input multiple type="file" class="custom-file-input" [lang]="lang" id="court-docs-partner"-->
<!--                               formControlName="files" (change)="courtDocsChange($event, 'partner')">-->
<!--                        <label class="custom-file-label" for="uploadFile1">-->
<!--                          {{ 'DOCUMENTS.choose-file' | translate}}-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="d-flex justify-content-between align-items-center"-->
<!--                         *ngFor="let file of courtDocsPartner">-->
<!--                      <p class="m-0 uploaded-file-name">{{ file.name }}</p>-->
<!--                      <button mat-icon-button color="warn" (click)="removeCourtFile($event, file.index, 'partner')">-->
<!--                        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">-->
<!--                          remove_circle-->
<!--                        </mat-icon>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 d-flex">-->
<!--                  <div class="form-group" style="width: 100%;">-->
<!--                    <label for="court-select-partner">{{ "DOCUMENTS.select-category" | translate }}</label>-->
<!--                    <select class="form-control" id="court-select-partner" formControlName="fileType">-->
<!--                      <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>-->
<!--                      <option *ngFor="let courtType of courtDocumentType"-->
<!--                              [value]="courtType.name">-->
<!--                        {{ courtType.label }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">-->
<!--                  <div style="padding-top: 25px">-->
<!--                    <app-spinner-btn [loading]="uploadSpinnerPartnerCourt"-->
<!--                                     [name]="'SHARED.submit' | translate"></app-spinner-btn>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--            <app-all-court-files [case]="case" [filesByCourt]="partner_files_by_court" [updateFiles]="updateFiles"-->
<!--                                 [type]="'partner'" (selectedFileEvent)="setFileUrl($event)">-->
<!--            </app-all-court-files>-->
<!--          </mat-expansion-panel>-->

          <!--  all bank files    -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "CASES.single.bank-documents" | translate }} {{ "CASES.single.client" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form *ngIf="bankFormClient" [formGroup]="bankFormClient" (ngSubmit)="submitBankFiles('client')"
                  class="form">
              <div class="row pb-4">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="bank-docs-client">{{ "DOCUMENTS.upload-documents" | translate }}</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input multiple type="file" class="custom-file-input" [lang]="lang" id="bank-docs-client"
                               formControlName="files" (change)="bankDocsChange($event, 'client')">
                        <label class="custom-file-label" for="uploadFile1">
                          {{ 'DOCUMENTS.choose-file' | translate}}
                        </label>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" *ngFor="let file of bankDocsClient">
                      <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                      <button mat-icon-button color="warn" (click)="removeBankFile($event, file.index, 'client')">
                        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
                          remove_circle
                        </mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 d-flex">
                  <div class="form-group">
                    <label for="bank-select-type">{{ "DOCUMENTS.select-category" | translate }}</label>
                    <select class="form-control" id="bank-select-type" formControlName="fileType">
                      <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>
                      <option *ngFor="let bank of allBanks"
                              [value]="'bank-' + bank.entity.id">
                        {{ bank.entity.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">
                  <div style="padding-top: 25px">
                    <app-spinner-btn [loading]="uploadSpinnerClientBank"
                                     [name]="'SHARED.submit' | translate"></app-spinner-btn>
                  </div>
                  <!--<div class="ml-2" style="padding-top: 25px">
                    <button mat-raised-button color="{{bankDocumentsVisible ? 'warn' : 'primary'}}"
                            (click)="updateDocumentVisibility(!bankDocumentsVisible, 'bank_account')">
                      {{bankDocumentsVisible ? 'Hide from client' : 'Show to client'}}
                    </button>
                  </div>-->
                </div>
              </div>
            </form>
            <app-all-bank-files [case]="case" [filesByBank]="files_by_bank" [updateFiles]="updateFiles"
                                [type]="'client'" (selectedFileEvent)="setFileUrl($event)">
            </app-all-bank-files>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="case.joint_application">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "CASES.single.bank-documents" | translate }} {{ "CASES.single.partner" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form *ngIf="bankFormPartner" [formGroup]="bankFormPartner" (ngSubmit)="submitBankFiles('partner')"
                  class="form">
              <div class="row pb-4">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="bank-docs-partner">{{ "DOCUMENTS.upload-documents" | translate }}</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input multiple type="file" class="custom-file-input" [lang]="lang" id="bank-docs-partner"
                               formControlName="files" (change)="bankDocsChange($event, 'partner')">
                        <label class="custom-file-label" for="uploadFile1">
                          {{ 'DOCUMENTS.choose-file' | translate}}
                        </label>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" *ngFor="let file of bankDocsPartner">
                      <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                      <button mat-icon-button color="warn" (click)="removeBankFile($event, file.index, 'partner')">
                        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
                          remove_circle
                        </mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 d-flex">
                  <div class="form-group">
                    <label for="bank-select-type-partner">{{ "DOCUMENTS.select-category" | translate }}</label>
                    <select class="form-control" id="bank-select-type-partner" formControlName="fileType">
                      <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>
                      <option *ngFor="let bank of allBanks"
                              [value]="'bank-' + bank.entity.id">
                        {{ bank.entity.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">
                  <div style="padding-top: 25px">
                    <app-spinner-btn [loading]="uploadSpinnerPartnerBank" [name]="'SHARED.submit' | translate">
                    </app-spinner-btn>
                  </div>
                </div>
              </div>
            </form>
            <app-all-bank-files [case]="case" [partnerFilesByBank]="partner_files_by_bank" [updateFiles]="updateFiles"
                                [type]="'partner'" (selectedFileEvent)="setFileUrl($event)">
            </app-all-bank-files>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "CASES.single.documents-list" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-all-files [case]="case"
                           (documentInfoChange$)="documentInfoChange($event)"
                           (documentStatusChange$)="documentStatusChange($event)"
                           (documentResendRequest$)="resendDocumentRequest($event)"
                           (documentDeleteRequest$)="deleteFile($event)"
                           (documentsMerged$)="documentsMerged($event)"
                           [userDocuments]="userDocuments"
                           [allCreditors]="allCreditors"
                           [type]="'client'"
                           (selectedFileEvent)="setFileUrl($event)">
            </app-all-files>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="case.joint_application">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "CASES.single.documents-list-partner" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-all-files [case]="case"
                           (documentInfoChange$)="documentInfoChange($event)"
                           (documentStatusChange$)="documentStatusChange($event)"
                           (documentResendRequest$)="resendDocumentRequest($event)"
                           (documentDeleteRequest$)="deleteFile($event)" (selectedFileEvent)="setFileUrl($event)"
                           (documentsMerged$)="documentsMerged($event)"
                           [userDocuments]="userDocumentsPartner"
                           [allCreditors]="allCreditors"
                           [type]="'partner'">
            </app-all-files>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div *ngIf="fileType === 'object'; else photo" class="col-6" style="height: auto">
        <div *ngIf="fileUrl" style="height: 100%">
          <div [ngClass]="{'height-50' : true, 'sticky' : isSticky}">
            <object [data]="fileUrl" type="application/pdf" width="100%" height="100%">
              <iframe [src]="fileUrl" width="100%" height="100%" style="border: none;">
                <p>
                  Your browser does not support PDFs.
                  <a [href]="fileUrl">Download the PDF</a>
                </p>
              </iframe>
            </object>
          </div>
        </div>
      </div>
      <ng-template #photo>
        <div class="col-md-6">
          <div [ngClass]="{'height-50' : true, 'sticky' : isSticky}">
            <img [src]="fileUrl" alt="" class="img-fluid" style="max-height: 83vh">
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
