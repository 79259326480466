import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PaymentService } from '../../../admin/payment/payment.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { UploadService } from '../../../../../../_base-shared/services/upload.service';

@Component({
  selector: 'app-change-date-modal',
  templateUrl: './change-date-modal.component.html',
  styleUrls: ['./change-date-modal.component.scss']
})
export class ChangeDateModalComponent implements OnInit {
  public form  = new UntypedFormGroup({
    newDate:      new UntypedFormControl('', [Validators.required]),
    newDateRadio: new UntypedFormControl('this_payment'),
    note: new UntypedFormControl('', Validators.required),
    reason: new UntypedFormControl(''),
  });
  public isLoading =  false;
  public showReason = false;
  public showDeferOption = false;
  public quillModules        = {
    imageUploader: {
      upload: (file) => this.uploadFile(file)
    },
  };

  constructor(
    private uploadService: UploadService,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ChangeDateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.form.patchValue({newDate: new Date(this.data.term.term_date)});
    this.showDeferOption = this.data.term.amount_paid === 0;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public changeDate() {

    if (this.form.valid) {
      //  Set loading flag
      this.isLoading = true;
      //  Prepare data for sending
      const data = {
        date: new Date(this.form.value.newDate).toDateString(),
        type: this.form.value.newDateRadio,
        note: this.form.value.note,
        reason: this.form.value.reason
      };

      this.paymentService.changeDate(this.data.term.id, data)
        .pipe(finalize(() => { this.isLoading = false; }))
        .subscribe(
          res => {
            this.toastr.success('Payment date changed successfully', 'Change date success.');
            this.dialogRef.close(res);  //  Close dialog modal
          }, error => {
            this.toastr.error('Error changing date. Please try again later.', 'Change date error.');
          });
    } else {
      //  If form is not valid
      //  trigger 'touched' event on all fields
      this.form.markAllAsTouched();
    }
  }

  dateRadioChange(value) {
    this.showReason = value === 'defer_payment';

    if (value !== 'defer_payment') {
      this.form.value.reason = null;
    }
  }

}
