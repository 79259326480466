<div class="row" [formGroup]="parentForm">
  <div class="col-6">
    <app-dropdown [data]="statusCategories" [isMultiple]="true"
                  [matOptionValue]="'id'"
                  [selectAll]="true"
                  [label]="'CASES.single.general.status_editor.status_category' | translate"
                  (clearSelect)="clearStatusControl($event, statusCategoryControl,'category')"
                  (selectChange)="statusCategoryChanged($event)"
                  [parentFormControl]="statusCategoryControl">
    </app-dropdown>
  </div>
  <div class="col-6">
    <app-dropdown [data]="filteredStatusCategories" [isMultiple]="true"
                  [parentFormControl]="statusControl"
                  [matOptionValue]="'id'"
                  [selectAll]="true"
                  [optionGroup]="true"
                  [optionGroupLabel]="'name'"
                  [label]="'CASES.single.general.status_editor.status' | translate"
                  (clearSelect)="clearStatusControl($event, statusControl,'status')"
                  (selectChange)="statusChanged($event)">
    </app-dropdown>
  </div>
</div>
