import { Injectable } from '@angular/core';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { Observable } from 'rxjs';
import { LaravelResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SmsService extends MainBaseApiService {

  index(data): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${this.apiUrl}/sms-templates`, {params: data})
            .pipe(catchError(response => this.handleError(response)));
  }

  get(smsId: number): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${this.apiUrl}/sms-templates/${smsId}`)
            .pipe(catchError(response => this.handleError(response)));
  }

  store(data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${this.apiUrl}/sms-templates`, data)
            .pipe(catchError(response => this.handleError(response)));
  }

  update(data): Observable<LaravelResponse> {
    return this.http.patch<LaravelResponse>(`${this.apiUrl}/sms-templates/${data.smsId}`, data.sms)
            .pipe(catchError(response => this.handleError(response)));
  }

  delete(smsId: number): Observable<LaravelResponse> {
    return this.http.delete<LaravelResponse>(`${this.apiUrl}/sms-templates/${smsId}`)
            .pipe(catchError(response => this.handleError(response)));
  }
}
