import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { UserService } from '../../../admin/user/user.service';

@Component({
  selector: 'app-client-profile-settings',
  templateUrl: './client-profile-settings.component.html',
  styleUrls: ['./client-profile-settings.component.scss']
})
export class ClientProfileSettingsComponent implements OnInit {
  public desktopVersion: boolean;

  constructor(private globalEventsService: MainGlobalEventService,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private userService: UserService,
              private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
  }

}
