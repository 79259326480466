import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../../../../../../_base-shared/guards/permission.guard';
import { NotificationConfiguratorComponent } from './notification-configurator/notification-configurator.component';
import { NotificationEditorComponent } from './notification-editor/notification-editor.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationTriggersListComponent } from './notification-triggers-list/notification-triggers-list.component';

const routes: Routes = [
  {
    path:     'notification',
    children: [
      {path: '', canActivate: [PermissionGuard], component: NotificationListComponent},
      {path:         'create',
        canActivate: [PermissionGuard],
        component:   NotificationEditorComponent,
        data:        {editorType: 'create'},
      },
      {path:         ':id/edit',
        canActivate: [PermissionGuard],
        component:   NotificationEditorComponent,
        data:        {editorType: 'edit'},
      },
      {path: 'trigger', canActivate: [PermissionGuard], component: NotificationTriggersListComponent},
      {path:         'trigger/configure',
        canActivate: [PermissionGuard],
        component:   NotificationConfiguratorComponent,
        data:        {editorType: 'create'},
      },
      {path:         'trigger/:id/edit',
        canActivate: [PermissionGuard],
        component:   NotificationConfiguratorComponent,
        data:        {editorType: 'edit'},
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class NotificationRoutingModule {
}
