import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { UserCaseRelation, UserCaseRelationValidation } from '../../../../../../_base-shared/models/Case/UserCaseRelationValidation';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ClientService } from '../../client.service';
import { AssetsStepComponent } from '../assets-step/assets-step.component';
import { CreditorsStepComponent } from '../creditors-step/creditors-step.component';
import { DetailsStepComponent } from '../details-step/details-step.component';
import { ExpenseStepComponent } from '../expense-step/expense-step.component';
import { IncomeStepComponent } from '../income-step/income-step.component';


@Component({
  selector:    'app-case-relation-validation-desktop',
  templateUrl: './case-relation-validation-desktop.component.html',
  styleUrls:   ['./case-relation-validation-desktop.component.scss'],
  providers:   [
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false},
    }],
})
export class CaseRelationValidationDesktopComponent implements OnInit, AfterViewChecked {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(DetailsStepComponent) user: DetailsStepComponent;
  @ViewChild(CreditorsStepComponent) creditors: CreditorsStepComponent;
  @ViewChild(AssetsStepComponent) assets: AssetsStepComponent;
  @ViewChild(IncomeStepComponent) income: IncomeStepComponent;
  @ViewChild(ExpenseStepComponent) expense: ExpenseStepComponent;

  public authUser: User;
  public caseUuid: string;
  public userUuid: string;
  public isLinear                     = true;
  public isLoading                    = 0;
  public isSendingNotCorrect          = 0;
  public isSendingCorrect             = 0;
  public selectedIndex                = 0;
  public case: Case;
  public caseRelationValidation: UserCaseRelationValidation;
  public primaryClient: User;
  public secondaryClient: User;
  public validationForm: UntypedFormGroup;
  public isStepValidated              = [
    {completed: true},
    {completed: false},
    {completed: false},
    {completed: false},
    {completed: false}];
  public readonly: boolean;
  public desktopVersion: boolean;
  public isDetailsTouched             = true;
  public isCreditorsTouched           = false;
  public isAssetsTouched              = false;
  public isIncomeTouched              = false;
  public isExpenseTouched             = false;
  public touched                      = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private globalEventsService: MainGlobalEventService,
    private clientService: ClientService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });


    this.route.data.subscribe(data => {
      this.readonly = data.readonly;
      if (this.readonly) {
        this.clientService.setNavSubject(false);
      } else {
        this.clientService.setNavSubject(true);
      }
    });
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.caseUuid       = this.route.snapshot.paramMap.get('caseUuid');
    this.userUuid       = this.route.snapshot.paramMap.get('userUuid');
    //  This form is used to validate material stepper
    this.validationForm = this.fb.group({
      user:      ['', Validators.required],
      creditors: ['', Validators.required],
      assets:    ['', Validators.required],
      income:    ['', Validators.required],
      expense:   ['', Validators.required],
    });
    this.fetchCase(this.caseUuid);
  }

  move(index: number) {
    if (index === 0) {
      this.stepper.selectedIndex = index;
      return;
    }
    this.isStepValidated[index].completed = true;
    if (this.isStepValidated[index - 1].completed === true && index > 0) {
      this.stepper.next();
    }
    if (this.isStepValidated.every(obj => obj.completed)) {
      this.stepper.selectedIndex = index;
    }
  }

  updateStepValidation(relation) {
    this.validationForm.patchValue({
      user:      relation.user,
      creditors: relation.creditors,
      assets:    relation.assets,
      income:    relation.income,
      expense:   relation.expense,
    });
    //  Set selected step
    // this.stepper.selectedIndex = 3;

  }

  private fetchCase(caseUuid: string) {
    this.isLoading++;
    this.clientService.showCase(caseUuid).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.case = result.data;
      this.globalEventsService.setClientSelectedCase(this.case);
      this.caseRelationValidation = this.case.relation_validations.find(
        relationValidation => relationValidation.user_id === this.authUser.id,
      );
      this.updateStepValidation(this.caseRelationValidation);

      if (this.case.user_id === this.authUser.id) {
        this.primaryClient   = this.case.client;
        this.secondaryClient = this.case.partner;
      }
      if (this.case.partner_user_id === this.authUser.id) {
        this.primaryClient   = this.case.partner;
        this.secondaryClient = this.case.client;
      }
    });
  }

  public validateStep(relation: UserCaseRelation, value: boolean) {
    //  Add two spinners for corrent and not correct
    if (value) {
      this.isSendingCorrect++;
    } else {
      this.isSendingNotCorrect++;
    }
    const data = this[relation].getData();
    window.scroll(0, 0);
    this.stepHeaderChanged(this.stepper.selectedIndex);
    this.submitStep(relation, data).pipe(finalize(() => {
      if (value) {
        this.isSendingCorrect--;
      } else {
        this.isSendingNotCorrect--;
      }
    }))
      .subscribe(
        result => {
          this.caseRelationValidation     = result.data;
          this.stepper.selected.completed = true;
          //  Update validation form allowing user to go on another step
          this.updateStepValidation(this.caseRelationValidation);
          if (value === false) {
            this.router.navigate(['/']);
          } else {
            if (relation === 'expense') {
              this.stepper.next();
              // tslint:disable-next-line:no-unused-expression
            } else {
              if (relation === 'assets' && this.desktopVersion === false) {
                const container = document.querySelector('.mat-horizontal-stepper-header-container');
                container.scrollTo(270, 0);
              }
              this.stepper.next();
            }
          }
        },
      );
  }

  private submitStep(relation: UserCaseRelation, data: object) {
    if (relation === 'user') {
      return this.clientService.updateCaseDataValidation(this.caseUuid, this.userUuid, data);
    }

    return this.clientService.updateCaseRelationValidation(this.caseUuid, this.userUuid, data);
  }

  public setSelectedIndex($event) {
    if ($event.selectedIndex === 2) {
      const container = document.querySelector('.mat-horizontal-stepper-header-container');
      container.scrollTo(270, 0);
    }
    this.selectedIndex = $event.selectedIndex;
    const element      = document.getElementById('case-relation');

    if (window.innerWidth < 768) { // add scroll to top only on mobile
      setTimeout(() => { // wait for stepper animation to finish
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
      }, 500);
    }
  }

  public goBack() {
    this.stepper.previous();
  }

  public stepHeaderChanged(index: number) {
    const allSteps   = ['Details', 'Creditors', 'Assets', 'Income', 'Expense'];
    const relation   = allSteps[index];
    const nameOfProp = 'is' + relation + 'Touched';
    this[nameOfProp] = true;
  }

  public findCurrentStepAndValidate(step: number) {
    if (step === 5 && !this.readonly) {
      this.router.navigate(['/']);
      return;
    }
    if ((step === 4 && this.readonly)) {
      this.validateStep('user', false);
    }
    const allSteps = ['user', 'creditors', 'assets', 'income', 'expense'];
    const relation = allSteps[step] as UserCaseRelation;
    this.validateStep(relation, true);
  }

  ngAfterViewChecked() {
  }
}
