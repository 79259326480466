<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h3>{{ "CASES.single.general.status_editor.heading" | translate }}</h3>
      </div>
      <div *ngIf="!isEditing" class="col-5 text-right">
        <button mat-icon-button color="primary" (click)="switchEdit($event)" class=" p-0">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body d-flex flex-column">
    <div *ngIf="isLoading" class="pt-4 pl-4">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>

    <app-server-response [response]="serverResponse"></app-server-response>
    <form [formGroup]="form" [hidden]="isLoading" class="disabled-form" (ngSubmit)="submitForm(form)">
      <!-- Super status -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input *ngIf="statusCategories?.length" type="select" [formControl]="statusCategoryControl"
                   appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                   [label]="'CASES.single.general.status_editor.status_category' | translate"
                   [selectOptions]="statusCategories" [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                   (ngModelChange)="updateSelectedStatusCategories($event)"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Sub status -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input *ngIf="filteredStatusCategories?.length" type="select" [formControlName]="statusFormControlName"
                   appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                   [label]="'CASES.single.general.status_editor.status' | translate"
                   [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
                   [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Payment status -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="payment_status_id" appearance="standard" [searchable]="true"
                   [label]="'CASES.single.general.status_editor.payment_status' | translate"
                   [selectOptions]="paymentStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Call status -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="call_status_id" appearance="standard" [searchable]="true"
                   [label]="'CASES.single.general.status_editor.call_status' | translate"
                   [selectOptions]="callStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Product ID -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="product_id" appearance="standard"
                   [label]="'CASES.single.general.status_editor.product' | translate"
                   [selectOptions]="products" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Total fees paid -->
      <div class="row align-items-baseline">
        <mat-label class="col-6">
          {{ "CASES.single.general.status_editor.total_fees_paid" | translate }}
        </mat-label>
        <div class="col-6">
          <p class="text-right" style="padding-top: 7px;">{{ amountPaid | number: '1.2-2' }} €</p>
        </div>
      </div>
      <!-- Total fees paid -->
      <div class="row align-items-baseline">
        <mat-label class="col-6">
          {{ "CASES.single.general.status_editor.total_fees_unpaid" | translate }}
        </mat-label>
        <div class="col-6">
          <p class="text-right" style="padding-top: 7px;">{{ amountLeftToPay | number: '1.2-2' }} €</p>
        </div>
      </div>
      <!-- Months Active -->
      <div class="row align-items-baseline">
        <mat-label class="col-6">
          {{ "CASES.single.general.status_editor.months_active" | translate }}
        </mat-label>
        <div class="col-6">
          <p class="text-right" style="padding-top: 7px;">{{ monthsActive }}</p>
        </div>
      </div>
      <!-- Next Payment Date -->
      <div class="row align-items-center" style="min-height: 40px">
        <mat-label class="col-6">
          {{ "CASES.single.general.status_editor.next_payment_date" | translate }}
        </mat-label>
        <div class="col-6 text-right">
          <p *ngIf="nextPaymentDate">{{nextPaymentDate}}</p>
          <p *ngIf="!nextPaymentDate">/</p>
        </div>
      </div>
      <!-- Next Payment Amount -->
      <div class="row align-items-center" style="min-height: 40px">
        <mat-label class="col-6">
          {{ "CASES.single.general.status_editor.next_payment_amount" | translate }}
        </mat-label>
        <div class="col-6 text-right">
          <p *ngIf="nextPaymentAmount">{{nextPaymentAmount}}</p>
          <p *ngIf="!nextPaymentAmount">/</p>
        </div>
      </div>
      <!--   IBAN   -->
      <div class="row align-items-center" style="min-height: 40px">
        <div class="col-12">
          <app-input type="text" formControlName="iban" [label]="'CASES.single.iban' | translate"
                     [showLabel]="false" [extraLabel]="true" appearance="standard" [fullWidth]="true"
                     flexClass="align-items-end">
          </app-input>
        </div>
      </div>
      <div *ngIf="isEditing" class="mt-auto">
        <div class="row pt-3">
          <div class="col-12 text-right">
            <button mat-button color="primary" class="mr-3" (click)="switchEdit($event)">
              {{ 'SHARED.cancel' | translate }}
            </button>
            <app-spinner-btn type="submit" [loading]="isSubmitting" color="primary">
              {{ 'SHARED.save' | translate }}
            </app-spinner-btn>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
