<div #signature id="signature">
  <div *ngIf="isLoadingDocumentPreview" class="d-flex justify-content-center align-items-center">
    <mat-spinner diameter="30" color="primary"></mat-spinner>
  </div>

  <app-html-shadow-dom [hidden]="isLoadingDocumentPreview" [unsafeHtml]="documentHtml"></app-html-shadow-dom>

  <div class="signature pt-6 with-logo" id="signature-LSO2"
       style="width: 210mm; margin: 0 auto; padding: 25mm">
    <div class="row">
      <div class="col-md-6" id="signature-lso-group">
        <p><b>Cliente:<span style="color:red">*</span></b></p>
        <div>
          <div class="signature-area" data-toggle="modal" data-target="#sign">
            <p class="m-0" *ngIf="!submittingSignature || (!submittingSignature)">FIRMAR AQUI</p>
            <div *ngIf="prevSignatureImg && !submittingSignature">
              <img class="img-fluid" src="{{imgPath + prevSignatureImg}}" alt="">
            </div>
            <div *ngIf="submittingSignature">
              <mat-spinner class=""></mat-spinner>
            </div>
          </div>
          <p class="m-0">{{ case?.client.first_name}} {{ case?.client.last_name}}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Signature modal -->
  <div class="modal fade" id="sign" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Sign contract</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div (window:resize)="onResize()" class="signature-wrap">
            <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()">
            </signature-pad>
          </div>
        </div>
        <div class="modal-footer">
          <button mat-raised-button color="primary" type="button" class="mr-auto" (click)="clear()">
            Clear signature
          </button>
          <button mat-stroked-button color="primary" class="mr-3" type="button" data-dismiss="modal">Close</button>
          <button mat-raised-button color="primary" type="button" data-dismiss="modal" (click)="saveSignature()">
            Save signature
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
