import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { StatusService } from '../../../admin/status/status.service';
import { Status } from '../../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../../_base-shared/models/Status/StatusCategory';

@Component({
  selector:    'app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls:   ['./status-filter.component.scss'],
})
export class StatusFilterComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;

  public statusCategoryControl: UntypedFormControl = new UntypedFormControl([]);
  public statusControl: UntypedFormControl         = new UntypedFormControl([]);

  public allStatuses: Array<Status>                      = [];
  public statusCategories: Array<StatusCategory>         = [];
  public filteredStatusCategories: Array<StatusCategory> = [];

  constructor(private statusService: StatusService,
              public translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.fetchStatuses();
  }

  public clearControls() {
    this.statusCategoryControl.patchValue([]);
    this.statusControl.patchValue([]);
  }

  private fetchStatuses(): void {
    this.statusService.indexCategoriesWithStatuses().subscribe(
        result => {
          this.statusCategories         = result.data;
          this.filteredStatusCategories = result.data;
          this.statusCategories.forEach(category => {
            this.allStatuses.push(category);
            category.statuses.forEach(status => this.allStatuses.push(status));
          });
          this.setStatusControls(this.parentForm.get(this.controlName).value);
        },
    );
  }

  private setStatusControls(selectedIds = []) {
    const selectedCategoryIds      = [];
    const selectedStatusIds        = [];
    const filteredStatusCategories = [];

    selectedIds.forEach(selectedId => {
      const category = this.statusCategories.find(findCategory => findCategory.id === selectedId);
      if (category) {
        filteredStatusCategories.push(category);
        selectedCategoryIds.push(category.id);
      }
      const status = this.allStatuses.find(findStatus => findStatus.id === selectedId);
      if (status && status.status_id) {
        selectedStatusIds.push(status.id);
      }
    });
    this.filteredStatusCategories = filteredStatusCategories.length ?
        filteredStatusCategories :
        this.filteredStatusCategories;
    this.statusCategoryControl.patchValue(selectedCategoryIds);
    this.statusControl.patchValue(selectedStatusIds);
  }

  public statusCategoryChanged($event: MatSelectChange) {
    this.updateSelectedStatusCategories($event.value);
  }

  public statusChanged($event: MatSelectChange) {
    this.updateSelectedStatuses($event.value);
  }

  private updateSelectedStatusCategories(selectedStatusCategoryIds: Array<number>) {
    const selectedStatuses = this.statusControl.value ? this.statusControl.value : [];

    let filteredStatusCategories = [];
    const toSelectStatuses       = [];

    if (selectedStatusCategoryIds.length) {
      selectedStatusCategoryIds.forEach(selectedCategoryId => {
        const statusCategory            = this.statusCategories.find(category => category.id === selectedCategoryId);
        let categoryHasStatusesSelected = false;
        filteredStatusCategories.push(statusCategory);

        statusCategory.statuses.forEach(status => {
          if (selectedStatuses.find(findStatusId => findStatusId === status.id)) {
            categoryHasStatusesSelected = true;
            toSelectStatuses.push(status.id);
          }
        });
        if (!categoryHasStatusesSelected) {
          toSelectStatuses.push(selectedCategoryId);
        }
      });
    } else {
      filteredStatusCategories = this.statusCategories;
    }

    this.filteredStatusCategories = filteredStatusCategories;

    this.parentForm.get(this.controlName).patchValue(toSelectStatuses);
  }

  private updateSelectedStatuses(selectedStatusIds: Array<number>) {
    const selectedStatusCategories = this.statusCategoryControl.value ? this.statusCategoryControl.value : [];
    const toSelectStatuses         = [...selectedStatusCategories];

    selectedStatusIds.forEach(statusId => {
      toSelectStatuses.push(statusId);
      const status        = this.allStatuses.find(findStatus => findStatus.id === statusId);
      const categoryIndex = toSelectStatuses.findIndex(findStatusId => findStatusId === status.status_id);
      if (categoryIndex >= 0) {
        toSelectStatuses.splice(categoryIndex, 1);
      }
    });

    this.parentForm.get(this.controlName).patchValue(toSelectStatuses);
  }

  public clearStatusControl($event, control: UntypedFormControl, type: 'category' | 'status') {
    $event.stopPropagation();
    if (type === 'category') {
      this.updateSelectedStatusCategories([]);
    } else {
      this.updateSelectedStatuses([]);
    }

    control.patchValue([]);
  }

}
