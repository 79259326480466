<div class="d-flex justify-content-between align-items-center">
  <h2>{{"TASK.list.title" | translate}}</h2>
</div>

<div *ngIf="formReady" [formGroup]="form" class="pb-5">
  <div class="row">
    <!-- Start Date -->
    <app-input type="mat-datepicker" formControlName="start_due_date" appearance="standard"
               label='{{ "PAYMENTS.start-date" | translate }}'>
    </app-input>
    <!-- End Date -->
    <app-input type="mat-datepicker" formControlName="end_due_date" appearance="standard"
               label='{{ "PAYMENTS.end-date" | translate }}'>
    </app-input>
    <!-- Due Date Statuses -->
    <app-input type="select" formControlName="due_date_statuses" appearance="standard" class="ml-2"
               label="Due date" [selectOptions]="dueDateOptions" [selectLabel]="'label'" [selectValue]="'value'"
                [showClear]="true" (ngModelChange)="onDueDateSelection($event)">
    </app-input>
    <!-- Is completed -->
    <app-input type="select" formControlName="is_completed" appearance="standard" class="ml-2"
               label='{{ "TASK.list.filters.completed" | translate }}' [selectOptions]="completedOptions"
               [selectLabel]="'label'" [selectValue]="'value'"
               [showClear]="true">
    </app-input>
    <!-- Only Related -->
    <app-input type="switch" formControlName="only_related" appearance="standard" class="pt-5 ml-2"
               flexClass="align-items-center"
               label='{{ "TASK.list.filters.my-tasks" | translate }}' (ngModelChange)="onlyRelatedChange($event)"
               *ngIf="authUser.role.is_staff && authUser.role.slug !== 'agent'">
    </app-input>
  </div>
  <div class="row">
    <!-- Assigned Users -->
    <app-input *ngIf="authUser.role_id === 5" type="select" formControlName="assigned_users" appearance="standard"
               [searchable]="true" class="ml-2"
               label="Users"
               [selectOptions]="users" [selectLabel]="['first_name', 'last_name']" [selectValue]="'id'"
               [showClear]="true" multiple="true">
    </app-input>
    <!-- Teams -->
    <app-input *ngIf="authUser.role.is_staff && authUser.role.slug !== 'agent'" type="select" formControlName="team_ids"
               appearance="standard" [searchable]="true" class="ml-2"
               label="Teams" [selectOptions]="teams" [selectLabel]="['name']" [selectValue]="'id'" [showClear]="true"
               multiple="true">
    </app-input>
    <!-- Status Categories -->
    <app-input *ngIf="statusCategories?.length" type="select" [formControl]="statusCategoryControl" class="ml-2"
               appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
               [label]="'CASES.single.general.status_editor.status_category' | translate"
               [selectOptions]="statusCategories" [selectLabel]="'name'" [selectValue]="'id'"
               (ngModelChange)="updateSelectedStatusCategories($event)" [showClear]="false">
    </app-input>
    <!-- Statuses -->
    <app-input *ngIf="filteredStatusCategories?.length" type="select" [formControlName]="statusFormControlName"
               class="ml-2" appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
               [label]="'CASES.single.general.status_editor.status' | translate"
               [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
               [selectLabel]="'name'" [selectValue]="'id'" [showClear]="false">
    </app-input>
    <!-- Payment Statuses -->
    <app-input type="select" formControlName="payment_status_ids" appearance="standard" [searchable]="true" class="ml-2"
               [label]="'CASES.single.general.status_editor.payment_status' | translate" multiple="true"
               [selectOptions]="paymentStatuses" [selectLabel]="['name']" [selectValue]="'id'" [showClear]="true"
    >
    </app-input>
  </div>
  <div class="text-right">
    <button mat-raised-button color="primary" (click)="applyFilters()">
      {{ 'SHARED.apply_filters' | translate }}
    </button>
  </div>
</div>

<div *ngIf="filtersReady && taskListFilter && pageSelected && (totalPages > 1)" class="row mb-3">
  <div class="col-12">
    <div class="global-select-all">
      <p class="m-0" *ngIf="!taskListFilter.select_all">
        Selected <b>{{ selection.selected.length }}</b> tasks on this page.
        <button mat-button color="primary" (click)="globalSelectAll($event)">
          Select all {{ totalResults | number }} tasks
        </button>
      </p>
      <p class="m-0" *ngIf="taskListFilter.select_all">
        All <b>{{ totalResults | number }}</b> tasks selected.
        <button mat-button color="primary" (click)="globalClearSelectAll($event)">Clear selection</button>
      </p>
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource" style="width: 100%">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openTask(row)"></tr>

    <!-- Selection -->
    <ng-container matColumnDef="select">
      <!-- Select All On Page -->
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                      [checked]="selection.hasValue() && allRowsSelected()"
                      [indeterminate]="selection.hasValue() && !allRowsSelected()">
        </mat-checkbox>
      </th>
      <!-- Select Row -->
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                      (change)="toggleRow($event, row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Case Ref Number -->
    <ng-container matColumnDef="ref_number">
      <th mat-header-cell *matHeaderCellDef>Ref.</th>
      <td mat-cell *matCellDef="let element"> {{element.case?.ref_number}} </td>
    </ng-container>

    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.name' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Case Status -->
    <ng-container matColumnDef="case_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'TASK.detail.case_status' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element?.case?.status.name}} </td>
    </ng-container>

    <!-- Payment Status -->
    <ng-container matColumnDef="payment_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'TASK.detail.payment_status' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element?.case?.payment_status?.name}} </td>
    </ng-container>

    <!-- Assigned Users -->
    <ng-container matColumnDef="assigned_users">
      <th mat-header-cell *matHeaderCellDef>{{ 'TASK.detail.assigned_users' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngFor="let user of element.assigned_users; let i = index">
          <span class="badge badge-primary">{{ user.name }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Assigned Departments -->
    <ng-container matColumnDef="assigned_departments">
      <th mat-header-cell *matHeaderCellDef>{{"TASK.detail.assigned_departments" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngFor="let department of element.assigned_departments; let i = index">
          <span class="badge badge-info">{{ department.name }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Teams -->
    <ng-container matColumnDef="teams">
      <th mat-header-cell *matHeaderCellDef>{{"TEAM.model_name.plural" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngFor="let team of element.teams">
          <span class="badge badge-info">{{team}}</span>
        </div>
      </td>
    </ng-container>

    <!-- Due Date -->
    <ng-container matColumnDef="due_date">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"TASK.detail.due-date.due-date" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <p class="m-0">{{ element.due_date | toDateObject | date:'dd/MM/yy' }}</p>
        <p class="m-0">{{ element.due_date | toDateObject | date:'HH:mm' }}</p>
      </td>
    </ng-container>

    <!-- Created At -->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"TASK.detail.created_at" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <p class="m-0">{{ element.created_at | toDateObject | date:'dd/MM/yy' }}</p>
        <p class="m-0">{{ element.created_at | toDateObject | date:'HH:mm' }}</p>
      </td>
    </ng-container>

    <!-- Completed At -->
    <ng-container matColumnDef="completed_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"TASK.detail.status" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.completed_at">
          <p class="m-0">{{ element.completed_at | toDateObject | date:'dd/MM/yy' }}</p>
          <p class="m-0">{{ element.completed_at | toDateObject | date:'HH:mm' }}</p>
          <p class="m-0" *ngIf="element.completed_by">
            By {{ element.completed_by.name }}
          </p>
        </div>
        <span *ngIf="!element.completed_at">{{ ("TASK.detail.completed_at.options.false" | translate) }}</span>
      </td>
    </ng-container>
  </table>

  <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h3 class="text-center">{{ "TASK.list.table.no-data" | translate }}</h3>
  </div>

  <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                 [pageSizeOptions]="[5, 10, 20, 50]" (page)="paginatorChange($event)">
  </mat-paginator>
</div>

<div class="mt-2 pb-3 pl-1" *ngIf="authUser.role_id === 5 || authUser.role_id === 7">
  <button mat-raised-button color="primary" class="mr-3"
          [disabled]="selection.selected.length === 0"
          (click)="exportRecords(taskListFilter)">
    {{ "CASES.list.export.button-name" | translate }}
  </button>
</div>
