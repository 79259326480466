import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { User } from '../../../../../../_base-shared/models/User/User';
import { ClientService } from '../../client.service';
import { EditModalComponent } from './edit-modal/edit-modal.component';
import {UserCaseDataValidation} from '../../../../../../_base-shared/models/Case/UserCaseDataValidation';

@Component({
  selector:    'app-details-step',
  templateUrl: './details-step.component.html',
  styleUrls:   ['./details-step.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class DetailsStepComponent implements OnInit {
  @ViewChild('firstName') firstName: ElementRef;
  @ViewChild('lastName') lastName: ElementRef;
  @Input() primaryClient: User;
  @Input() secondaryClient: User;
  @Input() caseUuid: string;
  @Input() userUuid: string;
  @Input() isReadOnly: boolean;
  public form: UntypedFormGroup;
  public partnerForm: UntypedFormGroup;
  public isLoading = 0;
  public editMode: boolean;
  public caseDataValidation?: UserCaseDataValidation;
  public checked = true;

  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.clientService.indexCaseDataValidation(this.caseUuid, this.userUuid).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
          this.caseDataValidation = result.data;
          this.buildClientForm(this.primaryClient);
      });
    // TODO: component should show details for primary client, and only first_name, last_name and dni for secondary client
    // primary client can click an `edit` button to enable the form, only first_name and last_name should be editable atm
  }

  private buildClientForm(primaryClient: User) {
    // Note: only first_name and last_name should be editable atm
    this.form = this.fb.group({
      first_name:          [{
        value: this.caseDataValidation ? this.caseDataValidation.first_name : primaryClient.first_name,
        disabled: false
      }, Validators.required],
      last_name:           [{
        value: this.caseDataValidation ? this.caseDataValidation.last_name : primaryClient.last_name,
        disabled: false
      }, Validators.required],
      email:               [{
        value: this.caseDataValidation ? this.caseDataValidation.email : primaryClient.email,
        disabled: false}, Validators.required],
      address:             [this.caseDataValidation ? this.caseDataValidation.address : true],
      id_card:             [this.caseDataValidation ? this.caseDataValidation.id_card : true],
      phone:               [this.caseDataValidation ? this.caseDataValidation.phone : true],
      employment:          [this.caseDataValidation ? this.caseDataValidation.employment : true],
      economic_regime:     [this.caseDataValidation ? this.caseDataValidation.economic_regime : true],
      marital_status:      [this.caseDataValidation ? this.caseDataValidation.marital_status : true],
    });
  }

  private buildPartnerForm(secondaryClient: User) {
    this.partnerForm = this.fb.group({
      partner_first_name:         [{value: secondaryClient.first_name, disabled: false}],
      partner_last_name:          [{value: secondaryClient.last_name, disabled: false}],
      partner_id_card:            [true],
      partner_employment:         [true],
      partner_economic_regime:    [true],
      partner_marital_status:     [true],
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.isLoading++;
    this.clientService.updateUser(this.primaryClient.uuid, form.value).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.toastr.success('Updated');
          this.primaryClient = result.data;
          this.toggleEditMode();
        },
    );
  }

  public toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.form.get('first_name').enable();
      this.form.get('last_name').enable();
      this.firstName.nativeElement.focus();
    } else {
      this.form.get('first_name').disable();
      this.form.get('last_name').disable();
    }
  }

  public getData() {
    return {
      client: this.form.value
    };
  }
}
