<div class="d-flex justify-content-center ml-3">
  <a data-toggle="dropdown" [attr.data-count]="unreadCount"
     class="clickable nav-link notification d-flex align-items-center justify-content-center"
     [class.notify]="notify" [class.show-count]="showCount"></a>
  <div class="dropdown-menu dropdown-menu-animated dropdown-lg" (click)="$event.stopPropagation()">
    <div class="dropdown-header d-flex flex-row px-2 px-md-4 py-4 rounded-top justify-content-between">
      <div class="info-card-text">
        <div class="fs-lg text-truncate text-truncate-lg" style="color: #000000">Notifications</div>
      </div>
      <div class="mr-0 mr-md-2" *ngIf="notifications.length">
        <a href="#" (click)="markAllAsRead($event)">Mark all as read</a>
      </div>
    </div>

    <div class="dropdown-divider m-0"></div>
    <div *ngFor="let notification of notifications"
         class="dropdown-item d-flex justify-content-between align-items-center"
         (click)="openModal($event, notification)">
      <div>
        <p class="m-0">{{notification.data.subject}}</p>
      </div>
      <a class="clickable" (click)="markAsRead($event, notification)">Mark as read</a>
    </div>
    <!--  No notifications  -->
    <div *ngIf="!notifications.length">
      <p class="m-0 text-center p-2">No unread notifications</p>
    </div>

    <div class="dropdown-divider m-0"></div>
    <div class="text-center p-2">
      <a routerLink="/case-dashboard/notifications">See all notifications</a>
    </div>
    <audio #sound>
      <source src="/assets/img/swiftly-610.mp3" type="audio/mpeg"/>
    </audio>

  </div>
</div>
