<div class="onboarding-wrap client-typography" [ngClass]="{'mat-card-fixed-width': desktopVersion}">
  <mat-card>
    <div style="justify-content: left">
      <p class="text-muted d-flex flex-row align-items-center">
        <mat-icon aria-hidden="false" fontIcon="work" style="margin-right: 9px">card_travel</mat-icon>
        {{'CLIENT.client-dashboard.your_case' | translate}}
      </p>
    </div>
    <mat-card-content>
      <div class="row mt-3">
        <div class="col">
          <h4>{{ (!isCaseFinished ? 'CLIENT.client-dashboard.onboarding_preview.subheading'  : 'CLIENT.client-dashboard.your_case') | translate}}</h4>
          <p [ngStyle]="{'display': !desktopVersion ? 'block' : 'none'}">{{completed_fields_count}}
            /{{total_fields_count}} {{'CLIENT.client-dashboard.onboarding_preview.steps_completed' | translate}}</p>
          <div [ngStyle]="{'max-width': !desktopVersion ? '352px' : '','margin': !desktopVersion ? 'auto' : ''}">
            <div *ngIf="!caseRelationValidation.completed_at" class="d-flex flex-row">
              <mat-progress-bar class="m-auto" [mode]="'determinate'" style="width: 19%;"
                                [value]="user_data"></mat-progress-bar>
              <mat-progress-bar class="m-auto" [mode]="'determinate'" style="width: 19%;"
                                [value]="other_creditors"></mat-progress-bar>
              <mat-progress-bar class="m-auto" [mode]="'determinate'" style="width: 19%;"
                                [value]="other_assets"></mat-progress-bar>
              <mat-progress-bar class="m-auto" [mode]="'determinate'" style="width: 19%;"
                                [value]="other_salaries"></mat-progress-bar>
              <mat-progress-bar class="m-auto" [mode]="'determinate'" style="width: 19%;"
                                [value]="other_expenses"></mat-progress-bar>
            </div>
          </div>
          <div class="mt-2" [ngStyle]="{'display': desktopVersion ? 'block' : 'none'}">
            <span class="text-muted" style="font-family: 'Poppins';font-size: 12px;">{{'CLIENT.client-dashboard.steps_completed' | translate}}</span>
            <br>
            <span style="font-family: 'Inter';font-size: 16px;">{{completed_fields_count}}/{{total_fields_count}}</span>
          </div>
        </div>
      </div>
      <!-- Temp action -->
      <mat-card-actions style="margin-top: 1rem">
        <ng-container>
          <p *ngIf="caseRelationValidation.completed_at" class="m-0">Completado</p>
          <mat-icon *ngIf="caseRelationValidation.completed_at" class="text-success mat-icon">check</mat-icon>
        </ng-container>
        <button *ngIf="!isCaseFinished" class="ml-2 onboarding-preview-btn" style="height: auto"
                [routerLink]="['/client', 'cases', case.uuid, 'users', user.uuid, 'relation-validation']">
          {{ 'CLIENT.client-dashboard.onboarding_preview.actions.verify' | translate }}
        </button>
        <button *ngIf="isCaseFinished" class="ml-2 onboarding-preview-btn" style="margin-top: 1px"
                [routerLink]="['/client', 'cases', case.uuid, 'users', user.uuid, 'your-case']">
          {{ 'CLIENT.client-dashboard.your_case' | translate }}
        </button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</div>
