import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelPaginatorResponse, LaravelResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DripCampaign } from '../../../../../../_base-shared/models/Notification/DripCampaign';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DripCampaignService extends MainBaseApiService {

  index(params = {}) {
    return this.http.get<LaravelPaginatorResponse<Array<DripCampaign>>>(this.apiUrl + '/drip-campaigns', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  store(dripCampaign: FormData, params = {}) {
    return this.http.post<LaravelResponse<DripCampaign>>(this.apiUrl + '/drip-campaigns', dripCampaign, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  show(id: number, params = {}) {
    return this.http.get<LaravelResponse<DripCampaign>>(this.apiUrl + '/drip-campaigns/' + id, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  update(id: number, dripCampaign: FormData) {
    const params = {_method: 'patch'};
    return this.http.post<LaravelResponse<DripCampaign>>(
        this.apiUrl + '/drip-campaigns/' + id, dripCampaign, {params},
    )
        .pipe(catchError(response => this.handleError(response)));
  }

  destroy(id: number, params = {}) {
    return this.http.delete<LaravelResponse>(this.apiUrl + '/drip-campaigns/' + id, {params})
        .pipe(catchError(response => this.handleError(response)));
  }
}
