import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CasePaymentPlan } from '../../../../../../_base-shared/models/Payment/CasePaymentPlan';
import { PaymentMethod } from '../../../../../../_base-shared/models/Payment/PaymentMethod';
import { PaymentMethodService } from '../../../../../../_base-shared/services/payment-method.service';
import { ClientService } from '../../client.service';

@Component({
  selector:    'app-payment-step',
  templateUrl: './payment-step.component.html',
  styleUrls:   ['./payment-step.component.scss'],
})
export class PaymentStepComponent implements OnInit {
  @Input() caseUuid: string;
  @Input() userUuid: string;
  public isLoading = 0;
  public taxRate   = 1.21;
  public debtPaymentPlan: CasePaymentPlan;
  public paymentMethods: Array<PaymentMethod>;
  public casePaymentMethod: PaymentMethod;

  constructor(
      private clientService: ClientService,
      private paymentMethodService: PaymentMethodService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading++;
    this.clientService.indexCaseRelation(this.caseUuid, this.userUuid, 'payment_plans.type')
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          result.data.forEach(paymentPlan => {
            if (paymentPlan.type.slug === 'debt_plan') {
              this.debtPaymentPlan = paymentPlan;
            }
          })
          this.fetchPaymentMethods();
        });
  }

  private fetchPaymentMethods(): void {
    this.paymentMethodService.index().subscribe(
        result => {
          this.paymentMethods    = result.data;
          this.casePaymentMethod = this.findCasePaymentMethod(this.debtPaymentPlan.payment_method_id);
        },
        error => console.error(error),
    );
  }

  private findCasePaymentMethod(paymentMethodId: number): PaymentMethod {
    const selectedPaymentMethod = this.paymentMethods?.find(paymentMethod => {
      return paymentMethod.id === +paymentMethodId;
    });

    return selectedPaymentMethod;
  }

}
