import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreditorListComponent } from './creditor-list/creditor-list.component';
import { CreditorEditorComponent } from './creditor-editor/creditor-editor.component';
import { PermissionGuard } from '../../../../../_base-shared/guards/permission.guard';

const routes: Routes = [
  {
    path: 'creditor',
    children: [
      {path: '', canActivate: [PermissionGuard], component: CreditorListComponent, data: {allowManager: true}},
      {path: 'create', canActivate: [PermissionGuard], component: CreditorEditorComponent, data: {editorType: 'create', allowManager: true}},
      {path: ':id/edit', canActivate: [PermissionGuard], component: CreditorEditorComponent, data: {editorType: 'edit', allowManager: true}},
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class CreditorRoutingModule {
}
