<div class="div justify-content-center details-step-container client-typography">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
    <table class="table table-borderless">
      <tbody>
      <!--Name-->
      <tr>
        <td>
          <app-input style="width: 100%" type="text" formControlName="first_name" appearance="standard"
                     [requiredStar]="true" label="Nombre" [clientTypography]="true"
                     [fullWidth]="true" [showClear]="false" *ngIf="!isReadOnly"></app-input>
          <!--Readonly-->
          <div *ngIf="isReadOnly" class="mb-3">
          <p class="text-muted input-xs" >Nombre<span style="color: red">*</span></p>
          <div class="d-flex justify-content-between flex-row" >
          <span>
            <input matInput type="text" formControlName="id_card" hidden>
            <p class="your-case-typography" style="margin-bottom: 0px !important;font-size: 17px;"> {{ form.get('first_name').value }}</p>
          </span>
          </div>
          </div>
        </td>
      </tr>
      <!--Last name-->
      <tr>
        <td class="responsive-td">
          <app-input style="width: 100%" type="text" formControlName="last_name" appearance="standard"
                     [requiredStar]="true" label="Apellido" [clientTypography]="true"
                     [fullWidth]="true" [showClear]="false" *ngIf="!isReadOnly"></app-input>
          <!--Readonly-->
          <div *ngIf="isReadOnly" class="mb-3">
            <p class="text-muted input-xs" >Apellido<span style="color: red">*</span></p>
            <div class="d-flex justify-content-between flex-row" >
          <span>
            <input matInput type="text" formControlName="id_card" hidden>
            <p class="your-case-typography" style="margin-bottom: 0px !important;font-size: 17px;">{{ form.get('last_name').value }}</p>
          </span>
            </div>
          </div>
        </td>
      </tr>
      <!--Email-->
      <tr>
        <td>
          <app-input style="width: 100%" type="text" formControlName="email" appearance="standard"
                     [requiredStar]="true" label="Email" [clientTypography]="true"
                     [fullWidth]="true" [showClear]="false" *ngIf="!isReadOnly"></app-input>
          <!--Readonly-->
          <div *ngIf="isReadOnly" class="mb-3">
            <p class="text-muted input-xs" >Email<span style="color: red">*</span></p>
            <div class="d-flex justify-content-between flex-row" >
          <span>
            <input matInput type="text" formControlName="id_card" hidden>
            <p class="your-case-typography"  style="margin-bottom: 0px !important;font-size: 17px;">{{ form.get('email').value }}</p>
          </span>
            </div>
          </div>
        </td>
      </tr>
      <!--Direction-->
      <tr>
        <td class="d-flex justify-content-between flex-column mb-3">
          <p class="text-muted input-xs">Dirección<span style="color: red">*</span></p>
          <div class="d-flex justify-content-between flex-row " [ngClass]="{'input-div': !isReadOnly}">
            <p [ngClass]="{'text-muted': !isReadOnly, 'your-case-typography': isReadOnly}" style="margin-bottom: 0px !important;">{{primaryClient?.address?.address_1}}{{primaryClient?.address?.address_2 ?
              ', ' + primaryClient?.address?.address_2 :
              ''}}{{primaryClient?.address?.postcode ?
              ', ' + primaryClient?.address?.postcode :
              ''}}{{primaryClient?.address?.municipality ?
              ', ' + primaryClient?.address?.municipality :
              ''}}{{primaryClient?.address?.city ?
              ', ' + primaryClient?.address?.city :
              ''}}{{primaryClient?.address?.region ? ', ' + primaryClient?.address?.region : ''}}</p>
            <div class="slide-toggle-wrapper mr-2 mb-6-lg" *ngIf="!isReadOnly">
              <mat-slide-toggle formControlName="address" disableRipple>
              </mat-slide-toggle>
            </div>
          </div>
        </td>
      </tr>
      <!--DNI-->
      <tr>
        <td class="d-flex justify-content-between flex-column mb-3">
          <p class="text-muted input-xs">DNI/NIE<span style="color: red">*</span></p>
          <div class="d-flex justify-content-between flex-row"  [ngClass]="{'input-div': !isReadOnly}">
          <span>
            <input matInput type="text" formControlName="id_card" hidden>
            <p  style="margin-bottom: 0px !important;font-size: 17px;" class="text-muted your-case-typography" [ngClass]="{'text-muted': !isReadOnly, 'your-case-typography': isReadOnly}">{{ primaryClient.id_card }}</p>
          </span>
            <div class="slide-toggle-wrapper mr-2 mb-6-lg" *ngIf="!isReadOnly">
              <mat-slide-toggle formControlName="id_card" disableRipple>
              </mat-slide-toggle>
            </div>
          </div>
        </td>
      </tr>
      <!--Teléfono móvil-->
      <tr>
        <td class="d-flex justify-content-between flex-column mb-3">
          <p class="text-muted input-xs">Teléfono móvil<span style="color: red">*</span>
          </p>
          <div class="d-flex justify-content-between flex-row" [ngClass]="{'input-div': !isReadOnly}">
          <span>
            <input matInput type="text" formControlName="phone" hidden>
            <p [ngClass]="{'text-muted': !isReadOnly, 'your-case-typography': isReadOnly}" style="margin-bottom: 0px !important;font-size: 17px;">{{ primaryClient.phone }}</p>
          </span>
            <span>
            <div class="slide-toggle-wrapper mr-2 mb-6-lg" *ngIf="!isReadOnly">
              <mat-slide-toggle formControlName="phone" disableRipple style="margin-bottom: 6px;">
              </mat-slide-toggle>
            </div>
          </span>
          </div>
        </td>
      </tr>
      <!--Employment-->
      <tr *ngIf="primaryClient.employment">
        <td class="d-flex justify-content-between flex-column mb-3">
          <p class="text-muted input-xs"> {{ 'USERS.model.employment.label' | translate }}<span style="color: red">*</span>
          </p>
          <div class="d-flex justify-content-between flex-row" [ngClass]="{'input-div': !isReadOnly}">
          <p [ngClass]="{'text-muted': !isReadOnly, 'your-case-typography': isReadOnly}" *ngIf="primaryClient.employment" style="margin-bottom: 0px !important;font-size: 17px;">
            {{ 'USERS.model.employment.options.' + primaryClient.employment | translate }}
          </p>
          <span>
            <div class="slide-toggle-wrapper mr-2 mb-6-lg" *ngIf="!isReadOnly">
              <mat-slide-toggle formControlName="employment" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </div>
        </td>
      </tr>
      <!--Economic regime-->
      <tr *ngIf="primaryClient.economic_regime">
        <td class="d-flex justify-content-between flex-column mb-3">
          <p class="text-muted input-xs"> {{ 'USERS.model.economic_regime.label' | translate }}<span style="color: red">*</span>
          </p>
          <div class="d-flex justify-content-between flex-row" [ngClass]="{'input-div': !isReadOnly}">
            <p [ngClass]="{'text-muted': !isReadOnly, 'your-case-typography': isReadOnly}" *ngIf="primaryClient.economic_regime" style="margin-bottom: 0px !important;font-size: 17px;">
              {{ 'USERS.model.economic_regime.options.' + primaryClient.economic_regime | translate }}
            </p>
          <span>
            <div class="slide-toggle-wrapper mr-2 mb-6-lg" *ngIf="!isReadOnly">
              <mat-slide-toggle formControlName="economic_regime" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </div>
        </td>
      </tr>
      <!--Marital status-->
      <tr *ngIf="primaryClient.marital_status">
        <td class="d-flex justify-content-between flex-column mb-3">
          <p class="text-muted input-xs"> {{ 'USERS.model.marital_status.label' | translate }}<span style="color: red">*</span>
          </p>
          <div class="d-flex justify-content-between flex-row" [ngClass]="{'input-div': !isReadOnly}">
            <p [ngClass]="{'text-muted': !isReadOnly, 'your-case-typography': isReadOnly}" *ngIf="primaryClient.marital_status" style="margin-bottom: 0px !important;font-size: 17px;">
              {{ 'USERS.model.marital_status.options.' + primaryClient.marital_status | translate }}
            </p>
          <span>
            <div class="slide-toggle-wrapper mr-2 mb-6-lg" *ngIf="!isReadOnly">
              <mat-slide-toggle formControlName="marital_status" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </form>
</div>

