<div class="div justify-content-center details-step-container client-typography-v2">
  <div class="bank-accounts" *ngIf="bankAccounts">
    <h2>{{"CLIENT-PORTAL.validator-form.bank-accounts" | translate}}</h2>
    <table class="table table-borderless table-striped client-table" *ngIf="bankAccounts.length && !isLoading"
           [formGroup]="form">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let bank of bankAccounts">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="bank.balance">
          <span class="assets-value">
            <p class="m-0">{{bank.entity?.name}}</p>
            {{bank.balance | number: '1.2-2'}}€
          </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper">
              <mat-slide-toggle formControlName="{{bank.id}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
    <div *ngIf="!bankAccounts.length && !isLoading">
      <p class="text-center">No tienes cuentas bancarias</p>
    </div>
  </div>

  <div class="vehicles pt-3" *ngIf="vehicles.length">
    <h2>{{"CLIENT-PORTAL.validator-form.vehicles" | translate}}</h2>
    <table class="table table-borderless table-striped client-table" *ngIf="vehicles.length && !isLoading"
           [formGroup]="form">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let vehicle of vehicles; let i = index">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="vehicle.value">
          <span class="assets-value">
            <p class="my-0">{{"CLIENT-PORTAL.validator-form.vehicle" | translate}} {{i + 1 }}</p>
            {{vehicle.value | number: '1.2-2'}}€
          </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper">
              <mat-slide-toggle formControlName="{{vehicle.id}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
    <div *ngIf="!vehicles.length && !isLoading">
      <p class="text-center">No tienes vehículos</p>
    </div>
  </div>

  <div class="properties pt-3" *ngIf="properties.length">
    <h2>{{"CLIENT-PORTAL.validator-form.property" | translate}}</h2>
    <table class="table table-borderless table-striped client-table" *ngIf="properties.length && !isLoading"
           [formGroup]="form">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let property of properties; let i = index">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="property.value">
          <span class="assets-value">
            <p class="my-0">{{"CLIENT-PORTAL.validator-form.property" | translate}} {{i + 1}}</p>
            {{property.value | number: '1.2-2'}}€
          </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper">
              <mat-slide-toggle formControlName="{{property.id}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
    <div *ngIf="!properties.length && !isLoading">
      <p class="text-center">No tienes propiedades</p>
    </div>
  </div>

  <div class="other pt-3" *ngIf="other.length">
    <h2>{{"CLIENT-PORTAL.validator-form.other" | translate}}</h2>
    <table class="table table-borderless table-striped client-table" *ngIf="other.length && !isLoading"
           [formGroup]="form">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let oth of other; let i = index">
        <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}" *ngIf="oth.estimated_value">
          <span class="assets-value">
            <p class="my-0">{{oth.other_assets_type}}</p>
            {{oth.estimated_value | number: '1.2-2'}}€
          </span>
          <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper">
              <mat-slide-toggle formControlName="{{oth.id}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
        </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
    <div *ngIf="!other.length && !isLoading">
      <p class="text-center">No tienes otros activos</p>
    </div>
  </div>

  <div class="other-assets pt-3" *ngIf="!isReadOnly">
    <table class="table table-borderless table-striped client-table" [formGroup]="form"
           *ngIf="form && !isLoading">
      <tbody>
      <tr>
        <td class="d-flex flex-row justify-content-between mr-1">
          <p class="m-0">{{"CLIENT-PORTAL.validator-form.other-assets" | translate}}</p>
          <mat-slide-toggle class="ml-2 mr-24" formControlName="other_assets" disableRipple>
          </mat-slide-toggle>
          <!--  <mat-radio-group formControlName="other_assets" aria-label="Select an option">
              <mat-radio-button [value]="true" class="mr-2">
                {{ "SHARED.yes" | translate }}
              </mat-radio-button>
              <mat-radio-button [value]="false" class="no-radio-btn">
                {{ "SHARED.no" | translate }}
              </mat-radio-button>
            </mat-radio-group>-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
