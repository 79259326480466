<div *ngIf="case?.product.group_slug === 'lso'">
  <div class="container e-sign-page">
    <div class="text-right">
      <img class="img-fluid logo" src="assets/img/logos/deuda-logo.png" alt="Logo">
    </div>

    <div class="page-title pt-5">
      <h1>Propuesta de Acuerdo Extrajudicial de Pagos</h1>
    </div>

    <p class="page-text">Nombre del cliente: {{case?.client.first_name}} {{case?.client.last_name}}
      {{case?.joint_application ? ' y ' + case?.partner?.first_name + ' ' + case?.partner?.last_name : ''}}</p>
    <p class="page-text">
      DNI: {{case?.client?.id_card }} {{case?.joint_application ? 'y ' + case?.partner?.id_card + ' respectivamente.' : ''}}</p>
    <p class="page-text">Dirección: {{case?.client?.address?.address_1}}, {{case?.client?.address?.address_2}}</p>
    <p class="page-text">Referencia: {{case?.ref_number}}</p>

    <div class="page-title pt-5">
      <h1>1. Objeto</h1>
    </div>
    <p class="page-text">Que al amparo de los artículos 666 y concordantes de la Ley Concursal,
      {{case?.client.first_name}} {{case?.client?.last_name}} {{case?.joint_application ?
          'y ' + case?.partner?.first_name + ' ' + case?.partner?.last_name :
          ''}}
      ,
      encontrándose en situación
      de mediación concursal, realiza{{case?.joint_application ? 'n' : ''}} la siguiente Propuesta Extrajudicial de
      Pagos a
      sus acreedores, para que
      voluntaria
      o
      legalmente queden sometidos al mismo.</p>

    <div class="page-title pt-5">
      <h1>2. Designación de Mediador Concursal</h1>
    </div>
    <p class="page-text">Siendo designado como Mediador Concursal, {{proposal?.administrator_name}} con dirección a efectos
      de
      notificaciones en {{proposal?.administrator_address}}</p>

    <div class="page-title pt-5">
      <h1>3. Circunstancias Personales, Económicas y Laborales</h1>
    </div>
    <p class="page-text">
      {{case?.client.first_name}} {{case?.client?.last_name}} {{case?.joint_application ?
        'y ' + case?.partner?.first_name + ' ' + case?.partner?.last_name :
        ''}}
      con estado civil {{ 'USERS.model.marital_status.options.' + case?.client?.marital_status | translate}} y con el domicilio
      en {{case?.client?.address?.address_1}}, {{case?.client?.address?.address_2}}
    </p>

    <div class="page-title pt-5">
      <h1>3.1 Evaluación de ingresos</h1>
    </div>
    <p class="page-text pb-2">
      El estado laboral de {{case?.client.first_name}} {{case?.client?.last_name}}
      {{case?.joint_application ? 'y ' + case?.partner?.first_name + ' ' + case?.partner?.last_name : ''}} unos ingresos
      regulares;
    </p>

    <div style="border-top: 3px solid #77838f; border-bottom: 3px solid #77838f; padding: 10px 5px">
      <div *ngIf="case?.income?.salary_client" class="d-flex justify-content-between">
        <p style="color: #000000" class="page-text m-0">
          Salario {{case?.client.first_name}} {{case?.client?.last_name}}</p>
        <p style="color: #000000" class="page-text m-0">{{case?.income?.salary_client | number: '1.2-2'}}€</p>
      </div>
      <div *ngIf="case?.joint_application" class="d-flex justify-content-between">
        <p style="color: #000000" class="page-text m-0">
          Salario {{case?.partner?.first_name}} {{case?.partner?.last_name}}</p>
        <p style="color: #000000" class="page-text m-0">{{case?.income?.salary_partner | number: '1.2-2'}}€</p>
      </div>
      <div *ngIf="subsidy_benefit" class="d-flex justify-content-between">
        <p style="color: #000000" class="page-text m-0">Subsidios sociales</p>
        <p style="color: #000000" class="page-text m-0">{{subsidy_benefit | number: '1.2-2'}}€</p>
      </div>
      <div *ngIf="pension" class="d-flex justify-content-between">
        <p style="color: #000000" class="page-text m-0">Pensión </p>
        <p style="color: #000000" class="page-text m-0">{{pension | number: '1.2-2'}}€</p>
      </div>
      <div *ngIf="proposal?.total_income" class="d-flex justify-content-between">
        <p style="color: #000000" class="page-text m-0">Total ingresos</p>
        <p style="color: #000000" class="page-text m-0">{{proposal?.total_income | number: '1.2-2'}}€</p>
      </div>
    </div>

    <div class="page-title pt-5 pb-2" style="border-bottom: 3px solid #77838f;">
      <h1>3.2. Evaluación de gastos mensuales</h1>
    </div>
    <div *ngIf="mortgage" class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Vivienda (alquiler/hipoteca)</p>
      <p style="color: #000000" class="page-text m-0">{{mortgage | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="utilities" class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Suministros</p>
      <p style="color: #000000" class="page-text m-0">{{utilities | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="vehicles" class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Vehículo (gasolina, seguro, impuesto)</p>
      <p style="color: #000000" class="page-text m-0">{{vehicles | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="case?.expense?.travel_public_transport" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Transporte</p>
      <p style="color: #000000" class="page-text m-0">{{case?.expense.travel_public_transport | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="case?.expense?.housekeeping_food_and_groceries" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Alimentación</p>
      <p style="color: #000000"
         class="page-text m-0">{{case?.expense.housekeeping_food_and_groceries | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="telephone" class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Teléfono e internet</p>
      <p style="color: #000000" class="page-text m-0">{{telephone | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="proposal?.legal_fees" class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Honorarios Letrados</p>
      <p style="color: #000000" class="page-text m-0">{{proposal?.legal_fees | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="case?.expense?.essential_local_tax" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Impuesto local</p>
      <p style="color: #000000" class="page-text m-0">{{case?.expense?.essential_local_tax | number: '1.2-2'}}€</p>
    </div>
    <div *ngFor="let expense of case?.expense?.special_expenses" style="border-bottom: 1px dotted #77838f">
      <div class="d-flex justify-content-between">
        <p style="color: #000000" class="page-text m-0">{{expense.label}}</p>
        <p style="color: #000000" class="page-text m-0">{{expense.value | number: '1.2-2'}}€</p>
      </div>
    </div>
    <div class="d-flex justify-content-between" style="background-color: #d5d5d5">
      <p class="m-0" style="color: #000000">Total Gastos: </p>
      <p class="m-0" style="color: #000000">{{totalExpenses | number: '1.2-2'}}€</p>
    </div>

    <div class="page-title pt-5 pb-2">
      <h1>3.3. Capacidad de pago del deudor. Cuota Mensual</h1>
    </div>

    <p class="page-text m-0 pb-3">Conforme a lo expuesto, {{case?.joint_application ? 'los deudores' : 'el deudor'}}
      tiene{{case?.joint_application ? 'n' : ''}} una capacidad de pago de
      {{proposal?.offer_to_creditors | number: '1.2-2'}}€.</p>
    <div class="d-flex justify-content-between pt-2" style="border-top: 3px solid #77838f;">
      <p class="mb-1" style="color: #000000">Total Ingresos:</p>
      <p class="mb-1" style="color: #000000">{{proposal?.total_income | number: '1.2-2'}}€</p>
    </div>
    <div class="d-flex justify-content-between">
      <p class="mb-1" style="color: #000000">Total Gastos:</p>
      <p class="mb-1" style="color: #000000">{{totalExpenses | number: '1.2-2'}}€</p>
    </div>
    <div class="d-flex justify-content-between" style="background-color: #d5d5d5">
      <p class="m-0" style="color: #000000">Propuesta de Pagos:</p>
      <p class="m-0" style="color: #000000">{{proposal?.offer_to_creditors | number: '1.2-2'}}€</p>
    </div>
  </div>

  <div class="container e-sign-page mt-6">
    <div class="text-right">
      <img class="img-fluid logo" src="assets/img/logos/deuda-logo.png" alt="Logo">

    </div>

    <div class="page-title pt-5">
      <h1>4. Masa activa; Bienes</h1>
    </div>
    <p class="page-text">La siguiente tabla pone de manifiesto de forma detallada sus bienes:</p>
    <div style="border-top: 3px solid #77838f;" class="pt-4">
      <div class="d-flex justify-content-between">
        <p class="page-text mb-1" style="color: #000000">Bienes</p>
        <p class="page-text mb-1" style="color: #000000">Importe</p>
      </div>
      <div *ngFor="let vehicle of vehiclesArray; let i = index">
        <div class="d-flex justify-content-between">
          <p class="page-text m-0" style="color: #000000">Vehículo {{i + 1}} - {{vehicle.make}} {{vehicle.model}} </p>
          <p class="page-text m-0" style="color: #000000">{{vehicle.value | number: '1.2-2'}}€</p>
        </div>
      </div>
      <div *ngFor="let property of propertiesArray; let i = index">
        <div class="d-flex justify-content-between">
          <p class="page-text m-0" style="color: #000000">Finca - {{property.address_1}}</p>
          <p class="page-text m-0" style="color: #000000">{{property.value | number: '1.2-2'}}€</p>
        </div>
      </div>
      <div *ngFor="let acc of bankAccArray; let i = index">
        <div class="d-flex justify-content-between">
          <p class="page-text m-0" style="color: #000000">Cuenta bancaria {{i + 1}} - {{acc.entity.name}}</p>
          <p class="page-text m-0"
             style="color: #000000">{{((acc.balance || 0) - (acc.exposed || 0)) | number: '1.2-2'}}€</p>
        </div>
      </div>
      <div *ngFor="let oth of otherArray; let i = index">
        <div class="d-flex justify-content-between">
          <p class="page-text m-0" style="color: #000000">Otro {{i + 1}}</p>
          <p class="page-text m-0" style="color: #000000">{{oth.estimated_value | number: '1.2-2'}}€</p>
        </div>
      </div>
      <div class="d-flex justify-content-between" style="background-color: #d5d5d5">
        <p class="page-text mb-1" style="color: #000000">Valor total</p>
        <p class="page-text mb-1" style="color: #000000">{{totalAssets | number: '1.2-2'}}€</p>
      </div>
    </div>

    <div class="page-title pt-5">
      <h1>5. Masa pasiva; Acreedores</h1>
    </div>
    <p class="page-text">La siguiente tabla pone de manifiesto de forma detallada las deudas:</p>
    <div style="border-top: 3px solid #77838f;" class="pt-4">
      <div class="d-flex">
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Acreedores</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Importe</p>
        </div>
        <div class="flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">% de la deuda global</p>
        </div>
      </div>

      <div class="d-flex" *ngFor="let creditor of this.allCreditors">
        <div class="mr-1 flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="page-text mb-1 pt-1" style="color: #000000">{{creditor.name}}</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 1px dotted #77838f" *ngIf="creditor.pivot">
          <p class="page-text mb-1 pt-1" style="color: #000000">{{creditor.pivot.current_balance | number: '1.2-2'}}
            €</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 1px dotted #77838f" *ngIf="!creditor.pivot">
          <p class="page-text mb-1 pt-1" style="color: #000000">{{creditor.current_balance | number: '1.2-2'}}
            €</p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f"
             *ngIf="!creditor.pivot || creditor.pivot.debt_type === 'mortgage'">
          <p class="page-text mb-1 pt-1" style="color: #000000">0.00</p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f"
             *ngIf="creditor.pivot && creditor.pivot.debt_type !== 'mortgage'">
          <p class="page-text mb-1 pt-1" style="color: #000000">
            {{((creditor.pivot.current_balance / totalCreditorValue) * 100) | number: '1.2-2'}}%
          </p>
        </div>
      </div>

      <div class="d-flex">
        <div class="mr-1 flex-1" style="background-color: #d5d5d5">
          <p class="mb-0" style="color: #000000">Total Deuda</p>
        </div>
        <div class="mr-1 flex-1" style="background-color: #d5d5d5">
          <p class="m-0" style="color: #000000">{{(totalPublicDebtValue + totalCreditorValue) | number: '1.2-2'}}€</p>
        </div>
        <div class="flex-1" style="background-color: #d5d5d5">
          <p class="m-0" style="color: #000000">100,00%</p>
        </div>
      </div>
    </div>

    <div class="page-title pt-5">
      <h1>6. Plan de Viabilidad. Plan de Pagos Propuesto</h1>
    </div>
    <p class="page-text">La presente Propuesta consiste en el pago de todos los créditos ordinarios. Se exceptúan
      aquellos
      créditos asistidos por garantía real. Así mismo, los créditos de Derecho Público
      no
      están afectados por el presente convenio</p>

    <div *ngIf="proposal?.step_period && proposal?.step_offer">
      <p style="color: #000000"><b>Capacidad de pago del deudor</b></p>

      <p class="page-text">Conforme a lo expuesto en el apartado 3.3, nuestro cliente dispone de una máxima capacidad de
        pago con respecto a sus deudas actual
        de {{proposal?.offer_to_creditors | number: '1.2-2'}} euros, y desde el
        mes {{proposal?.term - proposal?.step_period}},
        de {{proposal?.step_offer | number: '1.2-2'}} euros. Es por ello que el deudor
        ofrece el 100% de su renta disponible a los acreedores, y el presente plan se divide en dos periodos:
      </p>

      <p class="page-text">
        - {{proposal?.term - proposal?.step_period}} primeras cuotas: {{proposal?.offer_to_creditors | number: '1.2-2'}}
        €
      </p>
      <p class="page-text">
        - {{proposal?.step_period}} restantes cuota: {{proposal?.step_offer | number: '1.2-2'}}€
      </p>
    </div>

    <div *ngIf="!proposal?.step_period && !proposal?.step_offer">
      <p class="page-text">
        - {{ proposal?.term - proposal?.step_period }} cuotas: {{proposal?.offer_to_creditors | number: '1.2-2'}}€
      </p>
    </div>

    <div style="border-bottom: 3px solid #77838f;" class="pt-4">
      <p style="color: #000000"><b>Plan de Pagos</b></p>
      <div class="d-flex">
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Acreedores</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">{{(proposal?.term || 0) - (proposal?.step_period || 0)}} primeros</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f"
             *ngIf="proposal?.step_period && proposal?.step_offer">
          <p class="mb-1" style="color: #000000">{{proposal?.step_period}} restantes mensualidades</p>
        </div>
        <div class="flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">% de la deuda global</p>
        </div>
        <div class="flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Abono global al acreedor</p>
        </div>
        <div class="flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">% deuda recuperada acreedor</p>
        </div>
      </div>

      <div class="d-flex" *ngFor="let creditor of case.unsecured_creditors">
        <div class="mr-1 flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">{{creditor.name}}</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">
            {{ ((creditor.pivot.current_balance / totalUnsecuredCreditorsValue) *
              proposal?.offer_to_creditors) | number: '1.2-2' }}
            €
          </p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f"
             *ngIf="proposal?.step_period && proposal?.step_offer">
          <p class="mb-1 pt-1" style="color: #000000">
            {{ ((creditor.pivot.current_balance / totalUnsecuredCreditorsValue) *
              proposal?.step_offer) | number: '1.2-2' }}
            €
          </p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">
            {{ ((creditor?.pivot?.current_balance / this.totalUnsecuredCreditorsValue) * 100) | number: '1.2-2' }}%
          </p>
        </div>
        <!--    Percent of global debt when there is NO step period    -->
        <div class="flex-1" style="border-bottom: 1px dotted #77838f"
             *ngIf="!proposal?.step_period && !proposal?.step_offer">
          <p class="mb-1 pt-1" style="color: #000000">
            {{ (((proposal?.term || 0)) *
              ((creditor.pivot.current_balance / totalUnsecuredCreditorsValue) *
                  proposal?.offer_to_creditors)) | number: '1.2-2' }}
            €
          </p>
        </div>
        <!--    Percent of global debt when there IS step period    -->
        <div class="flex-1" style="border-bottom: 1px dotted #77838f"
             *ngIf="proposal?.step_period && proposal?.step_offer">
          <p class="mb-1 pt-1" style="color: #000000">
            {{ (((proposal?.term || 0) - (proposal?.step_period || 0)) *
              ((creditor.pivot.current_balance / totalUnsecuredCreditorsValue) * proposal?.offer_to_creditors) +
              ((proposal?.step_period || 0) * (proposal?.step_period && proposal?.step_offer ?
                  ((creditor.pivot.current_balance / totalUnsecuredCreditorsValue) * proposal?.step_offer) :
                  0))) | number: '1.2-2' }}
            €
          </p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">
            {{ proposal?.dividend | number: '1.2-2' }}%
          </p>
        </div>
      </div>

      <div class="d-flex">
        <div class="mr-1 flex-1" style="background-color: #d5d5d5">
          <p class="mb-0" style="color: #000000">Total</p>
        </div>
        <div class="mr-1 flex-1" style="background-color: #d5d5d5">
          <p class="m-0" style="color: #000000">{{totalWithoutStepOffer | number: '1.2-2'}}€</p>
        </div>
        <div class="flex-1" style="background-color: #d5d5d5" *ngIf="proposal?.step_period && proposal?.step_offer">
          <p class="m-0" style="color: #000000">{{totalWithStepOffer | number: '1.2-2'}}€</p>
        </div>
        <div class="flex-1" style="background-color: #d5d5d5" *ngIf="proposal?.step_period && proposal?.step_offer">
          <p class="m-0" style="color: #000000">100.00%</p>
        </div>
        <div class="flex-1" style="background-color: #d5d5d5" *ngIf="!proposal?.step_period && !proposal?.step_offer">
          <p class="m-0" style="color: #000000">{{globalCreditWithoutStepOffer | number: '1.2-2'}}€</p>
        </div>
        <div class="flex-1" style="background-color: #d5d5d5" *ngIf="proposal?.step_period && proposal?.step_offer">
          <p class="m-0" style="color: #000000">{{globalCreditWithStepOffer | number: '1.2-2'}}€</p>
        </div>
        <div class="flex-1" style="background-color: #d5d5d5">
          <p class="m-0" style="color: #000000">{{ proposal?.dividend | number: '1.2-2' }}%</p>
        </div>
      </div>
    </div>

    <div class="page-title pt-5">
      <h1>7. CONTROL DE CUMPLIMIENTO. INCUMPLIMIENTO DEL ACUERDO
        EXTRAJUDICIAL DE PAGOS.</h1>
    </div>
    <p class="page-text">{{case?.joint_application ? 'Los deudores' : 'El deudor'}}
      asumirá{{case?.joint_application ? 'n' : ''}}
      el compromiso de informar por escrito al mediador concursal, con periodicidad
      anual a contar desde la
      elevación a público del presente Plan de Pagos, los pagos atendidos y el cumplimiento o no del Acuerdo
      Extrajudicial
      de Pagos</p>
    <p class="page-text">1. Las fórmulas de pago establecidas en la presente Propuesta anticipada de Convenio no
      comportarán el devengo, ni
      exigibilidad de interés alguno.</p>
    <p class="page-text">2. Los pagos que se efectuaran conforme al expuesto en la presente Propuesta, dando
      cumplimiento a la misma e iniciarán en el mes siguiente al que el acuerdo sea elevado a público conforme
      a lo dispuesto en el art. 678 TRLC.</p>
    <p class="page-text">3. La eventual nulidad o ineficacia de alguna disposición del presente Plan de Pagos no
      afectará
      a las restantes disposiciones.</p>
    <p class="page-text">4. No se establecen medidas prohibitivas o limitativas de las facultades de administración y
      disposición del deudor.</p>
    <p class="page-text">Con la declaración del cumplimiento de este Plan de pagos todos los acreedores se considerarán
      pagados en sus créditos, quedando plenamente satisfechos y no teniendo nada más que pedir ni reclamar al
      deudor.</p>

    <div class="row">
      <div class="col-md-6">
        <div class="signature" id="signature" style="padding-top: 100px">
          <div *ngIf="sign === 'client'">
            <div class="signature-area" data-toggle="modal" data-target="#sign">
              <p class="m-0" *ngIf="!prevSignatureImg && !loading">FIRMAR AQUI</p>
              <div *ngIf="prevSignatureImg && !loading">
                <img class="img-fluid" src="{{imgPath + prevSignatureImg}}" alt="">
              </div>
              <div *ngIf="loading">
                <mat-spinner class=""></mat-spinner>
              </div>
            </div>
            <p class="m-0">{{ case?.client.first_name}} {{ case?.client?.last_name}}</p>
            <p class="m-0">DNI: {{ case?.client?.id_card}}</p>
          </div>
          <div *ngIf="sign === 'partner'">
            <div class="signature-area" style="cursor: default">
              <div *ngIf="prevSignatureImg && !loading">
                <img class="img-fluid" src="{{imgPath + prevSignatureImg}}" alt="">
              </div>
            </div>
            <p class="m-0">{{ case?.client.first_name}} {{ case?.client?.last_name}}</p>
            <p class="m-0">DNI: {{ case?.client?.id_card}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="signature" id="signature" style="padding-top: 100px" *ngIf="case?.joint_application">
          <div *ngIf="sign === 'partner'">
            <div class="signature-area" data-toggle="modal" data-target="#sign">
              <p class="m-0" *ngIf="!prevSignatureImgPartner && !loading">FIRMAR AQUI</p>
              <div *ngIf="prevSignatureImgPartner && !loading">
                <img class="img-fluid" src="{{imgPath + prevSignatureImgPartner}}" alt="">
              </div>
              <div *ngIf="loading">
                <mat-spinner class=""></mat-spinner>
              </div>
            </div>
            <p class="m-0">{{ case?.partner?.first_name}} {{ case?.partner?.last_name}}</p>
            <p class="m-0">DNI: {{ case?.partner?.id_card}}</p>
          </div>
          <div *ngIf="sign === 'client'">
            <div class="signature-area" style="cursor: default">
              <div *ngIf="prevSignatureImgPartner && !loading">
                <img class="img-fluid" src="{{imgPath + prevSignatureImgPartner}}" alt="">
              </div>
            </div>
            <p class="m-0">{{ case?.partner?.first_name}} {{ case?.partner?.last_name}}</p>
            <p class="m-0">DNI: {{ case?.partner?.id_card}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="case?.product.group_slug === 'dm' || case?.product.group_slug === 'dgs'">
  <div class="container e-sign-page">
    <div class="text-right">
      <img class="img-fluid logo" src="assets/img/logos/deuda-logo.png" alt="Logo">

    </div>

    <div class="page-title pt-5">
      <h1>Propuesta de reestructuración de deuda</h1>
    </div>

    <p class="page-text">Nombre del cliente: Don / Doña {{case?.client.first_name}} {{case?.client?.last_name}}</p>
    <p class="page-text">Dirección: {{case?.client?.address?.address_1}}{{case?.client?.address?.address_2 ?
        ', ' + case?.client?.address.address_2 :
        ''}}
      {{case?.client?.address?.postcode ? ', ' + case.client?.address?.postcode : ''}}{{case?.client?.address?.municipality ? ', ' + case.client?.address?.municipality : ''}}
      {{case?.client?.address?.city ? ', ' + case.client?.address?.city : ''}}{{case?.client?.address?.region ? ', ' + case.client?.address?.region : ''}}{{case?.client?.address?.county ?
          ', ' + case.client?.address?.county :
          ''}}
      {{case?.client?.address?.country ? ', ' + case.client?.address?.country : ''}}</p>
    <p class="page-text">DNI: {{case?.client?.id_card}}</p>

    <p *ngIf="case?.joint_application" class="page-text">Nombre del cliente
      2: {{case?.partner?.first_name}} {{case?.partner?.last_name}}</p>
    <p *ngIf="case?.joint_application" class="page-text">
      Dirección: {{case?.client?.address?.address_1}}{{case?.client?.address?.address_2 ?
        ', ' + case?.client?.address.address_2 :
        ''}}
      {{case?.client?.address?.postcode ? ', ' + case.client?.address?.postcode : ''}}{{case?.client?.address?.municipality ? ', ' + case.client?.address?.municipality : ''}}
      {{case?.client?.address?.city ? ', ' + case.client?.address?.city : ''}}{{case?.client?.address?.region ? ', ' + case.client?.address?.region : ''}}{{case?.client?.address?.county ?
        ', ' + case.client?.address?.county :
        ''}}
      {{case?.client?.address?.country ? ', ' + case.client?.address?.country : ''}}</p>
    <p *ngIf="case?.joint_application" class="page-text">DNI: {{case?.partner?.id_card}}</p>
    <p class="page-text">Referencia: {{case?.ref_number}}</p>
    <p class="page-text">
      Fecha: {{date | date:'dd.MM.yyyy.'}}</p>
    <p class="page-text">Referencia: {{case?.ref_number}}</p>

    <p class="page-text pt-2">Estimados acreedores,</p>

    <p class="page-text pb-1">
      Nuestro cliente en común ha facultado a {{ companyNameLegal }} mediante el Mandato que remitimos
      adjunto, a mediar e intentar alcanzar un acuerdo con sus acreedores; expresamente el documento recoge la facultad
      de:
    </p>
    <p class="page-text pb-1">
      “Llevar a cabo la negociación individual con cada uno de sus acreedores y con respecto a la deuda contraída con
      cada uno de ellos, supervisando y llegando a acuerdos que permitan la disminución y/o paralización de sus
      intereses, revisión de cláusulas abusivas en caso de que las hubiera.”
    </p>
    <p class="page-text pb-1">
      Para redactar este Acuerdo se han evaluado de forma exhaustiva las siguientes circunstancias del cliente:
    </p>
    <ul>
      <li>Análisis de sus gastos esenciales;</li>
      <li>Evaluación íntegra de sus ingresos;</li>
      <li>Crédito pendiente que mantiene con todos los acreedores;</li>
      <li>Análisis y evolución de las circunstancias financieras del cliente a corto –medio plazo;</li>
      <li>Posibilidad de saldar los créditos pendientes si el cliente opta por acogerse a la Ley de Segunda
        Oportunidad.
      </li>
    </ul>
    <p class="page-text pb-1">
      Todo lo anterior ha sido evaluado utilizando pruebas documentales, por lo tanto, consideramos que la siguiente
      propuesta es un reflejo veraz y preciso de las circunstancias financieras actuales y futuras del cliente tras
      haber sido asistido y asesorado por {{ companyName }}.
    </p>
    <p class="page-text pb-1">
      El presente Acuerdo se remite a todas las partes involucradas para que puedan valorarlo con el objetivo de
      alcanzar una reestructuración del total de la deuda de nuestro cliente común con todos sus acreedores.
    </p>
    <p class="page-text pb-1">
      Es necesaria la aceptación formal de todos los acreedores, por lo tanto, hemos fijado un plazo máximo para su
      aceptación de 30 días. Durante el plazo indicado, les rogamos detengan cualquier procedimiento de cobro hasta
      alcanzar un acuerdo entre las partes.
    </p>
    <p class="page-text pb-3">
      Quedamos a la espera de su pronta respuesta, considerando su aceptación como una forma de garantizar el resultado
      positivo para todas las partes involucradas.
    </p>
    <p class="page-text pb-1">
      Atentamente,
    </p>
    <p class="page-text pb-1">
      <img style="width:150px" class="img-fluid" src="assets/img/ceo_sign2.png"
           alt="signature">
    </p>
    <p class="page-text pb-1 m-0">
      Luis Miguel Díaz Simón
    </p>
    <p class="page-text pb-1 m-0">
      Director Legal
    </p>
  </div>

  <div class="container e-sign-page mt-5">
    <div class="text-right">
      <img class="img-fluid logo" src="assets/img/logos/deuda-logo.png" alt="Logo">

    </div>

    <div class="page-title pt-5">
      <h1>Circunstancias personales</h1>
    </div>

    <p class="page-text pb-1">
      D/Dª. {{ case?.client.first_name}} {{ case?.client.last_name}} tiene domicilio en
      {{case?.client?.address?.address_1}}{{case?.client?.address?.address_2 ?
        ', ' + case?.client?.address.address_2 :
        ''}}
      {{case?.client?.address?.postcode ?
        ', ' + case?.client?.address?.postcode :
        ''}}{{case?.client?.address?.municipality ? ', ' + case?.client?.address?.municipality : ''}}
      {{case?.client?.address?.city ? ', ' + case?.client?.address?.city : ''}}{{case?.client?.address?.region ?
        ', ' + case?.client?.address?.region :
        ''}}{{case?.client?.address?.county ?
        ', ' + case?.client?.address?.county :
        ''}}
      {{case?.client?.address?.country ? ', ' + case?.client?.address?.country : ''}}
    </p>
    <p *ngIf="case?.joint_application" class="page-text pb-1">
      D/Dª. {{case?.partner?.first_name}} {{case?.partner?.last_name}} tiene domicilio en
      {{case?.partner?.address?.address_1}}{{case?.partner?.address?.address_2 ?
        ', ' + case?.partner?.address?.address_2 :
        ''}}
      {{case?.partner?.address?.postcode ?
        ', ' + case?.partner?.address?.postcode :
        ''}}{{case?.partner?.address?.municipality ? ', ' + case?.partner?.address?.municipality : ''}}
      {{case?.partner?.address?.city ? ', ' + case?.partner?.address?.city : ''}}{{case?.partner?.address?.region ?
        ', ' + case?.partner?.address?.region :
        ''}}{{case?.partner?.address?.county ?
        ', ' + case?.partner?.address?.county :
        ''}}
      {{case?.partner?.address?.country ? ', ' + case?.partner?.address?.country : ''}}
    </p>

    <div class="page-title pt-5">
      <h1>Evaluación de ingresos</h1>
    </div>

    <p *ngIf="!case?.joint_application" class="page-text pb-1">
      El estado laboral del {{ case?.client.first_name}} {{case?.client?.last_name}} es {{"CASES.e-sign." +
    case?.client?.employment | translate | lowercase}} con unos ingresos de:
    </p>

    <p *ngIf="case?.joint_application" class="page-text pb-1">
      El estado laboral del {{case?.client.first_name}} {{case?.client.last_name}} es {{"CASES.e-sign." +
    case?.client?.employment | translate | lowercase}}. Así mismo, el estado laboral
      de {{case?.partner?.first_name}} {{case?.partner?.last_name}} es {{"CASES.e-sign." +
    case?.partner?.employment | translate | lowercase}}. Los ingresos
      percibidos entre ambos son:
    </p>

    <div class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">{{!case?.joint_application ? 'Salario' : 'Salario ambos'}}</p>
      <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_ingresos?.salario | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="dmData?.evaluacion_de_ingresos?.otros" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Otros</p>
      <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_ingresos?.otros | number: '1.2-2'}}€</p>
    </div>
    <div class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Total ingresos</p>
      <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_ingresos?.total_income | number: '1.2-2'}}
        €</p>
    </div>

    <div class="page-title mt-4">
      <h1 style="border-bottom: 3px solid #77838f; border-top: 3px solid #77838f; padding: 20px 0">
        Evaluación de gastos mensuales
      </h1>
    </div>

    <div *ngIf="dmData?.evaluacion_de_gastos?.vivienda_habitual" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Vivienda habitual (alquiler/hipoteca)</p>
      <p style="color: #000000"
         class="page-text m-0">{{dmData?.evaluacion_de_gastos?.vivienda_habitual | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="dmData?.evaluacion_de_gastos?.suministros" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Suministros</p>
      <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_gastos?.suministros | number: '1.2-2'}}
        €</p>
    </div>
    <div *ngIf="dmData?.evaluacion_de_gastos?.transporte" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Transporte</p>
      <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_gastos?.transporte | number: '1.2-2'}}
        €</p>
    </div>
    <div *ngIf="dmData?.evaluacion_de_gastos?.alimentacion" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Alimentación</p>
      <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_gastos?.alimentacion | number: '1.2-2'}}
        €</p>
    </div>
    <div *ngIf="dmData?.evaluacion_de_gastos?.telephone" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Teléfono e internet</p>
      <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_gastos?.telephone | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="dmData?.evaluacion_de_gastos?.gastos_escolares" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Gastos escolares</p>
      <p style="color: #000000"
         class="page-text m-0">{{dmData?.evaluacion_de_gastos?.gastos_escolares | number: '1.2-2'}}€</p>
    </div>
    <div *ngIf="dmData?.evaluacion_de_gastos?.special_expenses.length">
      <div *ngFor="let expense of dmData?.evaluacion_de_gastos?.special_expenses"
           style="border-bottom: 1px dotted #77838f">
        <div class="d-flex justify-content-between">
          <p style="color: #000000" class="page-text m-0">{{expense.label}}</p>
          <p style="color: #000000" class="page-text m-0">{{expense.value | number: '1.2-2'}}€</p>
        </div>
      </div>
    </div>
    <div *ngIf="dmData?.evaluacion_de_gastos?.otros" class="d-flex justify-content-between"
         style="border-bottom: 1px dotted #77838f">
      <p style="color: #000000" class="page-text m-0">Otros</p>
      <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_gastos?.otros | number: '1.2-2'}}€</p>
    </div>
    <div class="d-flex justify-content-between" style="background-color: #d5d5d5">
      <p class="m-0" style="color: #000000">Total Gastos:</p>
      <p class="m-0" style="color: #000000">{{dmData?.evaluacion_de_gastos?.total_expenses | number: '1.2-2'}}€</p>
    </div>

    <div class="page-title mt-5">
      <h1 style="border-bottom: 3px solid #77838f;">
        Máxima capacidad de pago para el abono mensual de los créditos
      </h1>
    </div>

    <div *ngIf="dmData?.evaluacion_de_gastos?.maxima_capacidad.has_step_payment">
      <div class="row">
        <div class="col-6">
          <h3 style="color: #000000"><b>PRIMER TRAMO</b></h3>
          <div class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
            <p style="color: #000000" class="page-text m-0">Cuota mensual</p>
            <p style="color: #000000"
               class="page-text m-0">{{dmData?.evaluacion_de_gastos?.maxima_capacidad.step_offer | number: '1.2-2'}}
              €</p>
          </div>
          <div class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
            <p style="color: #000000" class="page-text m-0">Plazo sugerido</p>
            <p style="color: #000000"
               class="page-text m-0">{{dmData?.evaluacion_de_gastos?.maxima_capacidad.step_period}} meses</p>
          </div>
        </div>
        <div class="col-6">
          <h3 style="color: #000000"><b>SEGUNDO TRAMO</b></h3>
          <div class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
            <p style="color: #000000" class="page-text m-0">Cuota mensual</p>
            <p style="color: #000000"
               class="page-text m-0">{{dmData?.evaluacion_de_gastos?.maxima_capacidad.offer_to_creditors | number: '1.2-2'}}
              €</p>
          </div>
          <div class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
            <p style="color: #000000" class="page-text m-0">Plazo sugerido</p>
            <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_gastos?.maxima_capacidad.term}}
              meses</p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!dmData?.evaluacion_de_gastos?.maxima_capacidad.has_step_payment">
      <div class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
        <p style="color: #000000" class="page-text m-0">Cuota mensual</p>
        <p style="color: #000000"
           class="page-text m-0">{{dmData?.evaluacion_de_gastos?.maxima_capacidad.offer_to_creditors | number: '1.2-2'}}
          €</p>
      </div>
      <div class="d-flex justify-content-between" style="border-bottom: 1px dotted #77838f">
        <p style="color: #000000" class="page-text m-0">Plazo sugerido</p>
        <p style="color: #000000" class="page-text m-0">{{dmData?.evaluacion_de_gastos?.maxima_capacidad.term}}
          meses</p>
      </div>
    </div>

    <div class="page-title mt-4">
      <h1 style="border-top: 3px solid #77838f; padding: 20px 0">
        Declaración de acreedores
      </h1>
    </div>
    <div>
      <div class="d-flex">
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Acreedores</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Importe</p>
        </div>
        <div class="flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">% de la deuda global</p>
        </div>
      </div>

      <div class="d-flex" *ngFor="let creditor of this.dmData?.first_table">
        <div class="mr-1 flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="page-text mb-1 pt-1" style="color: #000000">{{creditor.creditor}}</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="page-text mb-1 pt-1" style="color: #000000">{{creditor.importe | number: '1.2-2'}}
            €</p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="page-text mb-1 pt-1" style="color: #000000">
            {{ creditor.percent_de_la_deuda_global | number: '1.2-2'}}%
          </p>
        </div>
      </div>

      <div class="d-flex">
        <div class="mr-1 flex-1" style="background-color: #d5d5d5">
          <p class="mb-0" style="color: #000000">Total Deuda</p>
        </div>
        <div class="mr-1 flex-1" style="background-color: #d5d5d5">
          <p class="m-0" style="color: #000000">{{dmData?.first_table_total | number: '1.2-2'}}€</p>
        </div>
        <div class="flex-1" style="background-color: #d5d5d5">
          <p class="m-0" style="color: #000000">100,00%</p>
        </div>
      </div>
    </div>

    <div class="page-title mt-4">
      <h1 style="border-top: 3px solid #77838f; padding: 20px 0">
        Pago mensual propuesto a los acreedores
      </h1>
    </div>
    <div style="border-bottom: 3px solid #77838f;" class="pt-4">
      <div class="d-flex">
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Acreedores</p>
        </div>
        <div *ngIf="!dmData?.evaluacion_de_gastos?.maxima_capacidad.has_step_payment" class="mr-1 flex-1"
             style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Pago Mensual {{dmData?.evaluacion_de_gastos?.maxima_capacidad.term}}
            meses</p>
        </div>
        <div *ngIf="dmData?.evaluacion_de_gastos?.maxima_capacidad.has_step_payment" class="mr-1 flex-1"
             style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Pago Mensual
            primeros {{dmData?.evaluacion_de_gastos?.maxima_capacidad.step_period}} meses</p>
        </div>
        <div *ngIf="dmData?.evaluacion_de_gastos?.maxima_capacidad.has_step_payment" class="mr-1 flex-1"
             style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Pago Mensual {{dmData?.evaluacion_de_gastos?.maxima_capacidad.term}}
            meses restantes</p>
        </div>
        <div *ngIf="dmData?.second_table[0].ultima_quota > 0" class="mr-1 flex-1"
             style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Última cuota</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">% de la deuda global</p>
        </div>
        <div class="mr-1 flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">Abono global al acreedor</p>
        </div>
        <div class="flex-1" style="border-bottom: 2px solid #77838f">
          <p class="mb-1" style="color: #000000">% deuda recuperada acreedor</p>
        </div>
      </div>

      <div class="d-flex" *ngFor="let creditor of this.dmData?.second_table">
        <div class="mr-1 flex-1" style="border-bottom: 1px dotted #77838f;">
          <p class="mb-1 pt-1" style="color: #000000">{{creditor.name}}</p>
        </div>
        <div *ngIf="!dmData?.evaluacion_de_gastos?.maxima_capacidad.has_step_payment" class="mr-1 flex-1"
             style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">{{ creditor.value | number: '1.2-2' }}€</p>
        </div>
        <div *ngIf="dmData?.evaluacion_de_gastos?.maxima_capacidad.has_step_payment" class="mr-1 flex-1"
             style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">{{ creditor.step_value | number: '1.2-2' }}€</p>
        </div>
        <div *ngIf="dmData?.evaluacion_de_gastos?.maxima_capacidad.has_step_payment" class="mr-1 flex-1"
             style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">{{ creditor.value | number: '1.2-2' }}€</p>
        </div>
        <div *ngIf="creditor.ultima_quota > 0" class="flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">
            {{ creditor.ultima_quota > 0 ? (creditor.ultima_quota | number: '1.2-2') : '' }}€
          </p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">{{ creditor.percentage | number: '1.2-2' }}%</p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">{{ creditor.abono_global | number: '1.2-2' }}€</p>
        </div>
        <div class="flex-1" style="border-bottom: 1px dotted #77838f">
          <p class="mb-1 pt-1" style="color: #000000">{{ creditor.percent_deuda_recuperada | number: '1.2-2' }}%</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container e-sign-page mt-5">
    <div class="text-right">
      <img class="img-fluid logo" src="assets/img/logos/deuda-logo.png" alt="Logo">

    </div>

    <div class="page-title pt-5">
      <h1>Intervención de {{ companyName }} en la supervisión del cumplimiento del presente Acuerdo</h1>
    </div>

    <p class="page-text pb-1">
      Una vez aceptado el presente Acuerdo, {{ companyName }} realizará las siguientes gestiones:<br>
      1. Supervisar el cumplimiento del presente acuerdo conforme a las condiciones pactadas.<br>
      2. Mediar en la formalización del acuerdo entre el cliente y los acreedores.<br>
      3. Comunicar los cambios que pudieran producirse con relación a las circunstancias financieras del cliente.<br>
      4. En caso de que las circunstancias financieras del cliente mejoren, mediar para intentar disminuir la duración
      del presente acuerdo con el objetivo de satisfacer las cantidades pendientes en el menor tiempo posible.<br>
      5. Proporcionar todos los medios necesarios para que exista una comunicación fluida entre todas las partes.<br>
    </p>

    <div class="page-title pt-5">
      <h1>Conclusiones del estudio financiero</h1>
    </div>

    <p class="page-text pb-1">
      La finalidad de este Acuerdo es la devolución del capital pendiente en su totalidad, en el menor plazo
      posible.<br>
      Tras haber valorado todas las opciones, consideramos que el presente acuerdo es la solución más adecuada tanto
      para nuestro cliente, como para todos los acreedores, teniendo en cuenta la situación financiera actual del
      cliente.<br>
      En caso de que el Acuerdo no se formalice en las condiciones expuestas, la única opción viable para nuestro
      cliente será iniciar el mecanismo de reducción de carga financiera, RD 1/2015, conocido popularmente como Ley de
      Segunda Oportunidad, con la consiguiente solicitud de exoneración de la totalidad de sus deudas al cumplir los
      requisitos exigibles para someterse a este procedimiento, dado que el plan de pagos propuesto en la fase
      extrajudicial de este procedimiento tendrá las mismas características que el presente. Entendemos que esta última
      opción es la más desfavorable para todos los acreedores, pues las cantidades pendientes no podrían ser
      recuperadas.<br>
      Esperamos que los detalles relativos a las circunstancias personales y financieras del cliente expuestos en el
      presente, les permitan tomar una decisión positiva para ambas partes, pudiéndose formalizar con la aceptación de
      éste.<br>
      Les rogamos nos faciliten su respuesta en el plazo máximo de 30 días desde la comunicación del presente acuerdo,
      confirmando, en su caso, la aceptación del mismo vía email o escrita, indicando adicionalmente los datos de pago
      en los que requieren que se realice el abono de las cuotas pactadas.<br>
      Quedamos a la espera de su pronta respuesta.<br>
    </p>

    <div class="row">
      <div class="col-6">
        <p class="page-text pb-1">
          Atentamente,
        </p>
        <p class="page-text pb-1">
          <img style="width:150px" class="img-fluid" src="assets/img/ceo_sign2.png"
               alt="signature">
        </p>
        <p class="page-text pb-1 m-0">
          Luis Miguel Díaz Simón
        </p>
        <p class="page-text pb-1 m-0">
          Director Legal
        </p>
      </div>
      <div class="col-6">
      </div>
    </div>
  </div>
</div>

<div class="accord mt-5" *ngIf="case?.product.group_slug === 'dm' || case?.product.group_slug === 'dgs'">
  <div class="container e-sign-page">
    <h2 class="text-center"><b>ACUERDO DE REESTRUCTURACIÓN DE DEUDA</b></h2>

    <p class="page-text">En {{ case?.client?.address?.city ? case.client?.address?.city : '' }}, de {{ date | date:'dd.MM.yyyy.' }}</p>

    <p class="page-text" *ngIf="!case?.joint_application">De una parte,
      D/Dª. {{ case?.client.first_name}} {{ case?.client?.last_name}}
      , mayor de edad, con DNI {{case?.client?.id_card}}, y domicilio a efecto de notificaciones
      en {{case?.client?.address?.address_1}} {{case?.client?.address?.address_2 ?
          ', ' + case?.client?.address.address_2 :
          ''}}{{case?.client?.address?.postcode ? ', ' + case.client?.address?.postcode : ''}}{{case?.client?.address?.municipality ?
          ', ' + case.client?.address?.municipality :
          ''}}{{case?.client?.address?.city ? ', ' + case.client?.address?.city : ''}}{{case?.client?.address?.region ? ', ' + case.client?.address?.region : ''}} {{case?.client?.address?.county ?
          ', ' + case.client?.address?.county :
          ''}}{{case?.client?.address?.country ? ', ' + case.client?.address?.country : ''}}
    </p>
    <p class="page-text" *ngIf="case?.joint_application">De una parte,
      D/Dª. {{ case?.client.first_name}} {{ case?.client?.last_name}},
      mayor de edad, con DNI {{ case?.client?.id_card}} y D/Dª. {{case?.partner?.first_name}} {{case?.partner?.last_name}}, mayor de edad, con
      DNI {{case?.partner?.id_card}}, con domicilio a efecto de notificaciones
      en {{case?.client?.address?.address_1}} {{case?.client?.address?.address_2 ?
          ', ' + case?.client?.address.address_2 :
          ''}} {{case?.client?.address?.postcode ? ', ' + case.client?.address?.postcode : ''}} {{case?.client?.address?.municipality ?
          ', ' + case.client?.address?.municipality :
          ''}} {{case?.client?.address?.city ? ', ' + case.client?.address?.city : ''}} {{case?.client?.address?.region ? ', ' + case.client?.address?.region : ''}} {{case?.client?.address?.county ?
          ', ' + case.client?.address?.county :
          ''}} {{case?.client?.address?.country ? ', ' + case.client?.address?.country : ''}}
    </p>
    <p class="page-text">Y de otra, {{'\{\{ACREEDOR NOMBRE\}\}'}}, en calidad de Acreedor</p>
    <p class="page-text">En adelante el Acreedor y el Deudor, se denominarán individualmente cada una de ellas “Parte”
      y, conjuntamente, las “Partes”</p>

    <h2 class="text-center pt-3 pb-3"><b>EXPONEN</b></h2>
    <p class="page-text"><b>Primero.- </b>Que el Deudor, en su propio nombre y representación, declara y reconoce
      adeudar al Acreedor la cantidad de
      {{'\{\{ABONO GLOBAL AL ACREEDOR\}\}'}} (en adelante la “Deuda”)</p>
    <p class="page-text"><b>Segundo.- </b>Que ambas partes reconocen que las circunstancias financieras del deudor han
      cambiado, no pudiendo hacer frente
      al abono de las cantidades pendientes según las condiciones estipuladas en los contratos referenciados en el punto
      primero del
      presente acuerdo.
      En virtud de todo lo expuesto, las partes: </p>

    <h2 class="text-center pt-3 pb-3"><b>ACUERDAN</b></h2>
    <p class="page-text"><b>Primero.- </b>Que ambas partes acuerdan que el Deudor abonará al Acreedor la Deuda en los
      plazos, fechas, forma y condiciones
      establecidas según las siguientes:</p>
    <ul>
      <li>Cuota mensual: {{'\{\{CUOTA MENSUAL\}\}'}}</li>
      <li>Plazo de amortización: {{proposal?.term}} meses</li>
      <li>Importe total a devolver: {{'\{\{ABONO GLOBAL ACREEDOR\}\}'}} euros</li>
      <li>Forma de pago: Transferencia / Ingreso a cuenta</li>
      <li>Cuenta de pago: {{proposal?.cuenta_de_pago}}</li>
      <li>Referencia del pago: {{proposal?.referencia_del_pago}}</li>
    </ul>
    <p class="page-text"><b>Segundo.- </b>Que el Acuerdo se entenderá aceptado con la comunicación por parte del
      Acreedor de su aceptación, a través de
      cualquier vía de comunicación dirigida a {{ companyName }}, siendo preferible su realización vía email al
      remitente del
      presente.</p>
    <p class="page-text"><b>Tercero.- </b>Que el Deudor se compromete a realizar el abono de la primera mensualidad en
      los diez (10) días siguientes a la
      notificación de la aceptación del presente acuerdo por todos los acreedores, procediendo el abono de las sucesivas
      mensualidades los días 5 de cada mes o, en caso de recaer en festivo, en el siguiente día hábil.</p>
    <p class="page-text">En caso de incumplimiento de pago por parte del Deudor, el Acreedor notificará al Deudor y
      otorgará un plazo de cinco (5) días
      para la subsanación del pago. A estos efectos, el Deudor se comprometerá a remitir vía email el justificante del
      ingreso realizado
      al Acreedor en un plazo de tres (3) días desde el abono de la mensualidad correspondiente.</p>
    <p class="page-text"><b>Cuarto.- </b>Que el Deudor se reserva el derecho de efectuar amortizaciones anticipadas de
      la deuda a fin de acortar la duración
      del plan de pagos, o bien efectuando pagos superiores a la cuota acordada o bien negociando con el acreedor una
      cancelación
      parcial a cambio de la concesión de una quita por parte de éste.</p>
    <p class="page-text"><b>Quinto.- </b>Que el Deudor se obliga a comunicar al Acreedor cualquier variación en sus
      circunstancias personales que le impidan
      cumplir el acuerdo, así como a iniciar una nueva negociación en base a su nueva situación.</p>
    <p class="page-text"><b>Sexto.- </b>Que tras la aceptación del presente acuerdo seguirá la gestión por parte del
      acreedor de la baja de la inscripción del
      Deudor en los ficheros de morosidad y otras bases de datos similares o de igual finalidad, así como de la
      solicitud de archivo y
      homologación del presente acuerdo, en caso de existir algún procedimiento judicial pendiente reclamando la deuda
      objeto de
      este acuerdo. Dicha gestión deberá ser realizada en el plazo máximo de treinta (30) días desde el pago de la
      primera
      mensualidad por parte del Deudor.</p>
    <p class="page-text"><b>Séptimo.- </b>Que este acuerdo podrá ser formalizado en escritura pública en cualquier
      momento a petición de cualquiera de las
      partes, siendo a cargo de cada una, a partes iguales, los gastos inherentes.</p>
    <p class="page-text">La parte interesada convocará con al menos siete (7) días de antelación a la otra parte,
      indicándole fecha, hora y notaría en la
      cual, esta última se compromete a comparecer. Dicha notaría deberá ubicarse, en todo caso, en la ciudad que
      corresponda al
      domicilio del Deudor.</p>
    <p class="page-text"><b>Octavo.- </b>Que las partes establecen como domicilio de notificaciones bajo el Acuerdo, los
      siguientes:</p>
    <p class="page-text">ACREEDOR</p>
    <p class="page-text">Domicilio: {{'\{\{ACREEDOR DETALLES\}\}'}}</p>
    <p class="page-text">Email: {{'\{\{ACREEDOR EMAIL\}\}'}}</p>
    <p class="page-text">***************************</p>
    <p class="page-text">DEUDOR</p>
    <p class="page-text">Domicilio: {{ case?.client?.address?.address_1 }} {{ case?.client?.address?.address_2 ?
        ', ' + case?.client?.address.address_2 :
        ''}} {{case?.client?.address?.postcode ? ', ' + case.client?.address?.postcode : ''}} {{case?.client?.address?.municipality ?
        ', ' + case.client?.address?.municipality :
        ''}} {{case?.client?.address?.city ? ', ' + case.client?.address?.city : ''}} {{case?.client?.address?.region ? ', ' + case.client?.address?.region : ''}} {{case?.client?.address?.county ?
        ', ' + case.client?.address?.county :
        ''}} {{case?.client?.address?.country ? ', ' + case.client?.address?.country : ''}}</p>
    <p class="page-text">Email: {{ case?.client?.email}}</p>
    <p class="page-text pb-5"><b>* Este acuerdo será replicado de forma individual, acorde a las condiciones señaladas
      en la propuesta, para cada uno de los acreedores comunicados por el cliente.</b></p>

    <div class="row pt-5">
      <div class="col-6">
        <p class="page-text pb-1">
          Atentamente,
        </p>
        <p class="page-text pb-1">
          <img style="width:150px" class="img-fluid" src="assets/img/ceo_sign2.png"
               alt="signature">
        </p>
        <p class="page-text pb-1 m-0">
          Luis Miguel Díaz Simón
        </p>
        <p class="page-text pb-1 m-0">
          Director Legal
        </p>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-md-12">
            <div class="signature" id="signature">
              <div *ngIf="sign === 'client'">
                <div class="signature-area" data-toggle="modal" data-target="#sign">
                  <p class="m-0" *ngIf="!prevSignatureImg && !loading">FIRMAR AQUI</p>
                  <div *ngIf="prevSignatureImg && !loading">
                    <img class="img-fluid" src="{{imgPath + prevSignatureImg}}" alt="">
                  </div>
                  <div *ngIf="loading">
                    <mat-spinner class=""></mat-spinner>
                  </div>
                </div>
                <p class="m-0">{{ case?.client.first_name}} {{ case?.client?.last_name}}</p>
                <p class="m-0">DNI: {{ case?.client?.id_card}}</p>
              </div>
              <div *ngIf="sign === 'partner'">
                <div class="signature-area" style="cursor: default">
                  <div *ngIf="prevSignatureImg">
                    <img class="img-fluid" src="{{imgPath + prevSignatureImg}}" alt="">
                  </div>
                </div>
                <p class="m-0">{{ case?.client.first_name}} {{ case?.client?.last_name}}</p>
                <p class="m-0">DNI: {{ case?.client?.id_card}}</p>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="signature" id="signature" *ngIf="case?.joint_application">
              <div *ngIf="sign === 'partner'">
                <div class="signature-area" data-toggle="modal" data-target="#sign">
                  <p class="m-0" *ngIf="!prevSignatureImgPartner && !loading">FIRMAR AQUI</p>
                  <div *ngIf="prevSignatureImgPartner && !loading">
                    <img class="img-fluid" src="{{imgPath + prevSignatureImgPartner}}" alt="">
                  </div>
                  <div *ngIf="loading">
                    <mat-spinner class=""></mat-spinner>
                  </div>
                </div>
                <p class="m-0">{{ case?.partner?.first_name}} {{ case?.partner?.last_name}}</p>
                <p class="m-0">DNI: {{ case?.partner?.id_card}}</p>
              </div>
              <div *ngIf="sign === 'client'">
                <div class="signature-area" style="cursor: default">
                  <div *ngIf="prevSignatureImgPartner">
                    <img class="img-fluid" src="{{imgPath + prevSignatureImgPartner}}" alt="">
                  </div>
                </div>
                <p class="m-0">{{ case?.partner?.first_name}} {{ case?.partner?.last_name}}</p>
                <p class="m-0">DNI: {{ case?.partner?.id_card}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="log mt-5">
  <div class="container" style="background: #fff; padding: 0 50px 100px 50px">
    <h2 class="text-center p-3">Firma electrónica</h2>
    <div style="border-bottom: 1px solid #6666; border-top: 1px solid #6666; margin-top: 30px">
      <h2 class="m-0 py-3">Documento firmado. Código de verificación enviado por {{ companyName }}</h2>
    </div>
    <div style="border-bottom: 1px solid #6666; padding: 20px 0">
      <div class="row">
        <div class="col-md-4">
          <p style="margin: 0; padding: 10px 0"><b>TITULO</b></p>
        </div>
        <div class="col-md-8">
          <p style="margin: 0; padding: 10px 0">
            <span *ngIf="case?.product.group_slug !== 'dgs'">CONTRATO SOBRE EL DERECHO DE ACCESO</span>
            <span *ngIf="case?.product.group_slug === 'dgs'">Propuesta de reestructuración</span>
          </p>
        </div>
      </div>
      <!--      <div *ngIf="pdfFileName" class="row">-->
      <!--        <div class="col-md-4">-->
      <!--          <p style="margin: 0; padding: 10px 0"><b>NOMBRE DEL ARCHIVO <br>CERTIFICADO DIGITAL</b></p>-->
      <!--        </div>-->
      <!--        <div class="col-md-8">-->
      <!--          <p style="margin: 0; padding: 10px 0">{{pdfFileName}}</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="row">
        <div class="col-md-4">
          <p style="margin: 0; padding: 10px 0"><b>ESTADO</b></p>
        </div>
        <div class="col-md-8">
          <p style="margin: 0; padding: 10px 0" class="green-circle">Completado Correctamente</p>
        </div>
      </div>
    </div>


    <div>
      <h2 style="margin: 0; padding: 30px 0">Histórico del proceso</h2>
      <div class="row" style="padding-left: 100px">
        <div class="col-lg-4">
          <div class="img-wrap-sent" style="padding-right: 100px; display: inline-block; text-align: center">
            <img src="assets/img/send_black.png" alt="sent">
          </div>
          <div style="display: inline-block; position: relative; bottom: 10px">
            <p style="margin: 0"><b>{{ case?.propuesta_modelo?.created_at | toDateObject | date : 'dd/MM/yyyy' }}</b>
            </p>
            <p style="margin: 0">
              <b>{{ case?.propuesta_modelo?.created_at | toDateObject | date : 'H:mm:ss': 'UTC' }}</b>
            </p>
          </div>
        </div>
        <div class="col-lg-8">
          <p style="margin: 0">
            Envio de firma electronica para {{ case?.client.first_name}} {{ case?.client?.last_name}}
            ({{ case?.client?.email}})
            desde {{ companyName }}
            <span> case@deudafix.es</span>
          </p>
        </div>
      </div>

      <div class="row" style="padding: 30px 0 0 100px">
        <div class="col-lg-4">
          <div class="img-wrap-viewed" style="padding-right: 100px; display: inline-block; text-align: center">
            <img src="assets/img/visibility.png" alt="sent">
          </div>
          <div style="display: inline-block; position: relative; bottom: 10px">
            <p style="margin: 0">
              <b>{{ case?.propuesta_modelo?.viewed_by_client | toDateObject | date : 'dd/MM/yyyy' }}</b></p>
            <p style="margin: 0">
              <b>{{ case?.propuesta_modelo?.viewed_by_client | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
          </div>
        </div>
        <div class="col-lg-8">
          <p style="margin: 0">
            <b>Verificado por: </b>{{ case?.client.first_name}} {{ case?.client?.last_name}} ({{ case?.client?.email}})
          </p>
          <p style="margin: 0"><b>Firmante ID: {{case?.propuesta_modelo?.client_ip}}</b></p>
        </div>
      </div>

      <div *ngIf="case?.joint_application">
        <div class="row" style="padding: 30px 0 0 100px">
          <div class="col-lg-4">
            <div class="img-wrap-viewed" style="padding-right: 100px; display: inline-block; text-align: center">
              <img src="assets/img/visibility.png" alt="sent">
            </div>
            <div style="display: inline-block; position: relative; bottom: 10px">
              <p style="margin: 0">
                <b>{{ case?.propuesta_modelo?.viewed_by_partner | toDateObject | date : 'dd/MM/yyyy' }}</b></p>
              <p style="margin: 0">
                <b>{{ case?.propuesta_modelo?.viewed_by_partner | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
            </div>
          </div>
          <div class="col-lg-8">
            <p style="margin: 0">
              <b>Verificado por: </b>{{ case?.partner?.first_name}} {{ case?.partner?.last_name}}
              ({{ case?.partner?.email}})
            </p>
            <p style="margin: 0"><b>Firmante ID: {{case?.propuesta_modelo?.partner_ip}}</b></p>
          </div>
        </div>
      </div>

      <div class="row" style="padding: 30px 0 0 100px">
        <div class="col-lg-4">
          <div class="img-wrap-completed" style="padding-right: 100px; display: inline-block; text-align: center">
            <img src="assets/img/check_circle.png" alt="sent">
          </div>
          <div style="display: inline-block; position: relative; bottom: 10px">
            <p style="margin: 0">
              <b>{{ case?.propuesta_modelo?.signed_by_client | toDateObject | date : 'dd/MM/yyyy' }}</b></p>
            <p style="margin: 0">
              <b>{{ case?.propuesta_modelo?.signed_by_client | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
          </div>
        </div>
        <div class="col-lg-8">
          <p style="margin: 0">El documento ha sido correctamente firmado
            por: {{ case?.client.first_name}} {{ case?.client?.last_name}}.</p>
        </div>
      </div>

      <div *ngIf="case?.joint_application">
        <div class="row" style="padding: 30px 0 0 100px">
          <div class="col-lg-4">
            <div class="img-wrap-completed" style="padding-right: 100px; display: inline-block; text-align: center">
              <img src="assets/img/check_circle.png" alt="sent">
            </div>
            <div style="display: inline-block; position: relative; bottom: 10px">
              <p style="margin: 0">
                <b>{{ case?.propuesta_modelo?.signed_by_partner | toDateObject | date : 'dd/MM/yyyy' }}</b></p>
              <p style="margin: 0">
                <b>{{ case?.propuesta_modelo?.signed_by_partner | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
            </div>
          </div>
          <div class="col-lg-8">
            <p style="margin: 0">El documento ha sido correctamente firmado
              por: {{ case?.partner?.first_name}} {{ case?.partner?.last_name}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="sign" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Sign</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div (window:resize)="onResize()" class="signature-wrap">
          <signature-pad [options]="signaturePadOptions"
                         (onEndEvent)="drawComplete()"></signature-pad>
        </div>
      </div>
      <div class="modal-footer">
        <!--          <div>-->
        <button mat-raised-button color="primary" type="button" class="mr-auto" (click)="clear()">Clear
          signature
        </button>
        <!--          </div>-->
        <button mat-stroked-button color="primary" class="mr-3" type="button" data-dismiss="modal">Close</button>
        <button mat-raised-button color="primary" type="button" data-dismiss="modal" (click)="saveSignature()">Save
          signature
        </button>
      </div>
    </div>
  </div>
</div>
