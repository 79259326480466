import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector   : 'app-rebuild-payment-plan',
  templateUrl: './rebuild-payment-plan.component.html',
  styles  :    [],
  animations : [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.3s ease-out',
              style({height: '*', opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            animate('0.3s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class RebuildPaymentPlanComponent implements OnInit {
  public optionsForm: UntypedFormGroup;
  public phaseOneAmount = 0;
  public phaseOneAmountLeft = 0;
  public phaseTwoAmount = 0;
  public phaseTwoAmountLeft = 0;
  public validationNotAllocated = '';


  constructor(private fb: UntypedFormBuilder,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<RebuildPaymentPlanComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.calculatePhaseAmount();

    this.optionsForm = this.fb.group({
      options: ['from_beginning', [Validators.required]],
      phase_1: this.fb.array([]),
      phase_2: this.fb.array([])
    });

    this.optionsForm.controls.phase_1.valueChanges.subscribe(value => {
      this.updateAmountLeft('1', value);
    });
    this.optionsForm.controls.phase_2.valueChanges.subscribe(value => {
      this.updateAmountLeft('2', value);
    });
  }

  updateAmountLeft(phase: string, value) {
    let newValue = 0;

    if (phase === '1') {
      const formArray = this.optionsForm.get('phase_1') as UntypedFormArray;
      formArray.value.map(val => {
        newValue += val.amount;
      });
      this.phaseOneAmountLeft = this.phaseOneAmount - newValue;
    }
    if (phase === '2') {
      const formArray = this.optionsForm.get('phase_2') as UntypedFormArray;
      formArray.value.map(val => {
        newValue += val.amount;
      });
      this.phaseTwoAmountLeft = this.phaseTwoAmount - newValue;
    }
  }

  calculatePhaseAmount() {
    if (this.data.terms.length) {
      this.data.terms.map(term => {
        if (term.type === 'phase_one') {
          if (term.amount > term.amount_paid) {
            this.phaseOneAmount += term.amount - term.amount_paid;
          }
        }
        if (term.type === 'phase_two') {
          if (term.amount > term.amount_paid) {
            this.phaseTwoAmount += term.amount - term.amount_paid;
          }
        }
      });
    } else {
      this.phaseOneAmount = this.data.case.loan_payment_plan.phase_one_value || 0;
      this.phaseTwoAmount = this.data.case.loan_payment_plan.phase_two_value || 0;
    }

    //  Set initial values for '...AmountLeft' variables
    //  '...AmountLeft' variables are used to set value of each added input field
    this.phaseOneAmountLeft = this.phaseOneAmount;
    this.phaseTwoAmountLeft = this.phaseTwoAmount;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  removeInstallment(phase: string, index: number, $event) {
    $event.preventDefault();
    const formArray = this.getFormArray(phase);
    formArray.removeAt(index);
  }

  public getFormArray(phase: string) {
    return this.optionsForm.get(phase) as UntypedFormArray;
  }

  public addInstallment(phase: string, $event) {
    $event.preventDefault();

    if (phase === '1') {
      const formArray = this.optionsForm.get('phase_1') as UntypedFormArray;
      formArray.push(
        this.fb.group({
          amount: [this.phaseOneAmountLeft, null]
        })
      );

      // this.updateAmountLeft('1');
    }
    if (phase === '2') {
      const formArray = this.optionsForm.get('phase_2') as UntypedFormArray;
      formArray.push(
        this.fb.group({
          amount: [this.phaseTwoAmountLeft, null]
        })
      );
    }
  }

  submit() {
    const {options} = this.optionsForm.value;
    this.validationNotAllocated = '';
    // this.data.updateData();
    if (this.optionsForm.valid) {
      if (options === 'from_beginning') {
        this.data.deleteAndRebuild();
        this.dialogRef.close();
      }
      if (options === 'only_unpaid') {
        this.data.rebuild();
        this.dialogRef.close();
      }
      if (options === 'manual') {
        // this.data.rebuild();
        if (this.phaseOneAmountLeft === 0 && this.phaseTwoAmountLeft === 0) {
          //  Api call
          const data = {
            case_id: this.data.case.id,
            ...this.optionsForm.value
          };
          this.data.rebuildManual(data);
          this.dialogRef.close();
        } else {
          this.validationNotAllocated = this.translate.instant('CASES.single.validation-not-allocated');
        }
      }
    }
    else {
      this.optionsForm.markAllAsTouched();
    }
  }
}
