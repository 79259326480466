<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>

<div *ngIf="!isLoading">
  <h2 *ngIf="editorType == 'create'">{{"AFFILIATES.add-new" | translate}}</h2>
  <h2 *ngIf="editorType == 'edit'">{{"AFFILIATES.edit-aff" | translate}}</h2>
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="form" (submit)="onSubmit()">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label for="track_id" class="pl-2">
                  {{"AFFILIATES.track-id" | translate}} {{"AFFILIATES.track-id-exp" | translate}}
                </label>
                <input id="track_id" type="text" readonly formControlName="track_id" class="form-control ">
              </div>
              <div class="col-md-6">
                <label for="name" class="pl-2">{{"AFFILIATES.name" | translate}}</label>
                <input id="name" type="text" formControlName="name" class="form-control">
              </div>
            </div>

            <div class="row pt-5">
              <div class="col-md-6 pt-md-0 pt-5">
                <label for="activity" class="pl-2">
                  {{"AFFILIATES.activity" | translate}}
                </label>
                <select id="activity" formControlName="active" class="form-control">
                  <option [ngValue]="true">{{"AFFILIATES.active" | translate}}</option>
                  <option [ngValue]="false">{{"AFFILIATES.inactive" | translate}}</option>
                </select>
              </div>

              <div class="col-md-6 pt-md-0 pt-5">
                <app-input type="checkbox" formControlName="allow_under_5000" appearance="standard"
                           [label]="'AFFILIATES.allow_under_5000' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="allow_without_debt" appearance="standard"
                           [label]="'AFFILIATES.allow_without_debt' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="allow_duplicates" appearance="standard"
                           [label]="'AFFILIATES.allow_duplicates' | translate">
                </app-input>
                <!--<app-input type="checkbox" formControlName="do_not_allow_overwrite" appearance="standard"-->
                <!--           [label]="'AFFILIATES.do_not_allow_overwrite' | translate">-->
                <!--</app-input>-->
                <app-input type="checkbox" formControlName="is_df_affiliate" appearance="standard"
                           [label]="'AFFILIATES.is_df_affiliate' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="allow_overwrite_df_affiliates" appearance="standard"
                           [label]="'AFFILIATES.allow_overwrite_df_affiliates' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="allow_overwrite_non_df_affiliates" appearance="standard"
                           [label]="'AFFILIATES.allow_overwrite_non_df_affiliates' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="is_packager" appearance="standard"
                           [label]="'AFFILIATES.is_packager' | translate">
                </app-input>
              </div>
            </div>

            <!--Overload intitial case status-->
            <div class="row pt-5">
              <div class="col-md-6 pt-md-0 pt-5">
                <label for="overload_status_id" class="pl-2">
                  {{ "AFFILIATES.editor.overload_status_id.label" | translate }}
                </label>
                <select id="overload_status_id" formControlName="overload_status_id" class="form-control">
                  <option [value]="null">
                    {{ "AFFILIATES.editor.overload_status_id.default_selected" | translate }}
                  </option>
                  <option *ngFor="let status of statuses" [value]="status.id">{{ status.name }}</option>
                </select>
              </div>
            </div>

            <!--Overwrite client regreso status-->
            <div class="row pt-5">
              <div class="col-md-6 pt-md-0 pt-5">
                <label for="overwrite_cliente_regreso_status_id" class="pl-2">
                  {{ "AFFILIATES.editor.overload_regreso_status.label" | translate }}
                </label>
                <select id="overwrite_cliente_regreso_status_id" formControlName="overwrite_cliente_regreso_status_id"
                        class="form-control">
                  <option [value]="null">
                    {{ "AFFILIATES.editor.overload_regreso_status.default_selected" | translate }}
                  </option>
                  <option *ngFor="let status of statuses" [value]="status.id">{{ status.name }}</option>
                </select>
              </div>
            </div>

            <div class="row pt-5">
              <div class="col-md-6 pt-md-0 pt-5">
                <div class="form-group">
                  <label>{{ "AFFILIATES.editor.postback-url" | translate }}</label>
                  <textarea formControlName="postback_url" class="form-control" rows="3"></textarea>
                </div>
              </div>
            </div>


            <div class="row pt-5">
              <div class="col-md-6">
                <div class="row" formGroupName="user">
                  <div class="col-md-6">
                    <label for="first_name">First name</label>
                    <input type="text" id="first_name" formControlName="first_name" class="form-control">
                  </div>
                  <div class="col-md-6">
                    <label for="email">Email</label>
                    <input type="text" id="email" formControlName="email" class="form-control">
                  </div>
                  <app-input type="checkbox" class="col-12" formControlName="send_affiliate_login_email"
                             appearance="standard" [label]="'Send login email'">
                  </app-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 pt-md-0 pt-5">
                <app-input type="select" formControlName="user_ids" appearance="outline" multiple
                           [label]="'CONFIG.misc.users' | translate" showLabel="true" fullWidth="true" searchable="true"
                           [selectOptions]="affiliateUsers" [selectLabel]="['first_name', 'last_name']" [selectValue]="'id'">
                </app-input>
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-12">
                <button *ngIf="editorType === 'create'" mat-raised-button color="primary" type="submit">
                  {{"AFFILIATES.create-aff" | translate}}
                </button>
                <button *ngIf="editorType === 'edit'" mat-raised-button color="primary" type="submit">
                  {{"AFFILIATES.edit-aff" | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <div class="card shadow">
        <div class="card-header">{{"CONFIG.sms-template.variables" | translate}}</div>
        <div class="card-body">
          <h3 class="text-center" (click)="addVariable($event)"><code>%%SUB_AFF%%</code></h3>
          <h3 class="text-center" (click)="addVariable($event)"><code>%%SS_AFF%%</code></h3>
        </div>
      </div>

      <div class="card shadow mt-5">
        <div class="card-header">{{"AFFILIATES.lead_tracking" | translate}}</div>
        <div class="card-body">
          <form *ngIf="form" [formGroup]="form">
            <table *ngIf="getFromArray().controls.length" formArrayName="lead_tracking">
              <thead>
              <tr>
                <th>{{ "AFFILIATES.editor.product_type" | translate }}</th>
                <th>{{ "AFFILIATES.editor.tracking-type.label" | translate }}</th>
                <th>{{ "AFFILIATES.editor.cost" | translate }}</th>
                <th>{{ "AFFILIATES.editor.conversion_status.label" | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let formGroup of getFromArray().controls let i = index">
                <tr [formGroupName]="i">
                  <td>
                    <input type="text" formControlName="name" class="form-control" disabled/>
                  </td>
                  <td>
                    <select formControlName="tracking_type" class="form-control">
                      <option [value]="null">
                        {{ "AFFILIATES.editor.tracking-type.default_selected" | translate }}
                      </option>
                      <option value="cpa">CPA</option>
                      <option value="cpl">CPL</option>
                    </select>
                  </td>
                  <td>
                    <input type="number" formControlName="cost" class="form-control"/>
                  </td>
                  <td>
                    <select *ngIf="form.value?.lead_tracking[i]?.tracking_type === 'cpa'" formControlName="status_id"
                            class="form-control">
                      <option [value]="null">
                        {{ "AFFILIATES.editor.conversion_status.default_selected" | translate }}
                      </option>
                      <option *ngFor="let status of statuses" [value]="status.id">{{ status.name }}</option>
                    </select>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
