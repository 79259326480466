import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';
import {finalize} from 'rxjs/operators';
import {
  HeadsUpDashboardBookPerformanceInterface, HeadsUpDashboardDgsPerformanceInterface,
  HeadsUpDashboardCollectionsInterface,
  HeadsUpDashboardDistributionInterface,
  HeadsUpDashboardDmPerformanceInterface,
  HeadsUpDashboardLsoPerformanceInterface,
} from '../../../../../../_base-shared/contracts/dashboard.interface';
import {DashboardService} from '../dashboard.service';

@Component({
  selector: 'app-heads-up-dash',
  templateUrl: './heads-up-dash.component.html',
  styles: [
    '.datepicker-container { display: flex; flex-direction: row; align-items: baseline; }',
  ],
})
export class HeadsUpDashComponent implements OnInit {
  public form: UntypedFormGroup;
  public dateRadioControl: UntypedFormControl = new UntypedFormControl();
  public collectionsSource: HeadsUpDashboardCollectionsInterface;
  public distributionSource: HeadsUpDashboardDistributionInterface;
  public lsoPerformanceSource: HeadsUpDashboardLsoPerformanceInterface;
  public dmPerformanceSource: HeadsUpDashboardDmPerformanceInterface;
  public dgsPerformanceSource: HeadsUpDashboardDgsPerformanceInterface;
  public bookPerformanceSource: HeadsUpDashboardBookPerformanceInterface;
  public isLoadingCollections = 0;
  public isLoadingDistribution = 0;
  public isLoadingLsoPerformance = 0;
  public isLoadingDmPerformance = 0;
  public isLoadingDgsPerformance = 0;
  public isLoadingBookPerformance = 0;

  constructor(private fb: UntypedFormBuilder,
              private dashboardService: DashboardService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchCollectionsData(this.form);
    this.fetchDistributionData(this.form);
    this.fetchLsoPerformanceData(this.form);
    this.fetchDmPerformanceData(this.form);
    this.fetchDgsPerformanceData(this.form);
    this.fetchBookPerformanceData(this.form);
  }

  private buildForm() {
    this.form = this.fb.group({
      start_date: [null],
      end_date: [null],
    });
    const initialRange = 'month';
    this.dateRadioControl.patchValue(initialRange);
    this.setInitialDate(initialRange);
  }

  private fetchCollectionsData(form: UntypedFormGroup) {
    this.isLoadingCollections++;
    this.dashboardService.getCollectionsData(form.value)
      .pipe(finalize(() => this.isLoadingCollections--))
      .subscribe(result => {
        this.collectionsSource = result.data;
      });
  }

  private fetchDistributionData(form: UntypedFormGroup) {
    this.isLoadingDistribution++;
    this.dashboardService.getDistributionData(form.value)
      .pipe(finalize(() => this.isLoadingDistribution--))
      .subscribe(result => this.distributionSource = result.data);
  }

  private fetchLsoPerformanceData(form: UntypedFormGroup) {
    this.isLoadingLsoPerformance++;
    this.dashboardService.getLsoSummaryData(form.value)
      .pipe(finalize(() => this.isLoadingLsoPerformance--))
      .subscribe(result => this.lsoPerformanceSource = result.data);
  }

  private fetchDmPerformanceData(form: UntypedFormGroup) {
    this.isLoadingDmPerformance++;
    this.dashboardService.getDmSummaryData(form.value)
      .pipe(finalize(() => this.isLoadingDmPerformance--))
      .subscribe(result => this.dmPerformanceSource = result.data);
  }

  private fetchDgsPerformanceData(form: UntypedFormGroup) {
    this.isLoadingDgsPerformance++;
    this.dashboardService.getDgsSummaryData(form.value)
      .pipe(finalize(() => this.isLoadingDgsPerformance--))
      .subscribe(result => {
        this.dgsPerformanceSource = result.data;
      });
  }


  private fetchBookPerformanceData(form: UntypedFormGroup) {
    this.isLoadingBookPerformance++;
    this.dashboardService.getBookPerformanceData(form.value)
      .pipe(finalize(() => this.isLoadingBookPerformance--))
      .subscribe(result => {
        console.log(result.data);
        this.bookPerformanceSource = result.data;
      });
  }

  public dateModifierChange($event) {
    this.form.get('start_date').setValue(moment().startOf($event.value).toDate().toISOString());
    this.form.get('end_date').setValue(moment().endOf($event.value).toDate().toISOString());
    this.form.get('start_date').updateValueAndValidity();
    this.form.get('end_date').updateValueAndValidity();
  }

  public setInitialDate(value) {
    this.form.get('start_date').setValue(moment().startOf(value).toDate().toISOString());
    this.form.get('end_date').setValue(moment().endOf(value).toDate().toISOString());
    this.form.get('start_date').updateValueAndValidity();
    this.form.get('end_date').updateValueAndValidity();
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    this.fetchCollectionsData(form.value);
    this.fetchDistributionData(form.value);
    this.fetchLsoPerformanceData(form.value);
    this.fetchDmPerformanceData(form.value);
    this.fetchDgsPerformanceData(form.value);
    this.fetchBookPerformanceData(form.value);
  }
}
