import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../main/src/environments/environment';
import { ScriptLoaderService } from './script-loader.service';

declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private googleAnalyticsKey     = environment.GOOGLE_ANALYTICS_CODE;
  private googleAnalyticsEnabled = environment.GOOGLE_ANALYTICS_ENABLED;

  constructor(private router: Router,
              private scriptLoaderService: ScriptLoaderService) {
  }

  public event(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }

  public init() {
    if (!this.googleAnalyticsEnabled) {
      return;
    }

    const googleAnalyticsScript = {
      name:  'GoogleAnalytics',
      type:  'text/javascript',
      src:   'https://www.googletagmanager.com/gtag/js?id=' + environment.GOOGLE_ANALYTICS_CODE,
      async: true,
      defer: true,
    };

    this.scriptLoaderService.loadScript(googleAnalyticsScript).subscribe(res => {
      this.listenForRouteChanges();
      try {
        const script2     = document.createElement('script');
        script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + this.googleAnalyticsKey + `');
      `;
        document.head.appendChild(script2);
        console.log('Google analytics appended successfully');
      } catch (ex) {
        console.error('Error appending google analytics');
        console.error(ex);
      }
    });
  }

  private listenForRouteChanges() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.googleAnalyticsKey, {
          page_path: event.urlAfterRedirects,
        });
        console.log('Sending Google Analytics hit for route', event.urlAfterRedirects);
        console.log('Property ID', this.googleAnalyticsKey);
      }
    });
  }
}
