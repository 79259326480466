import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  LaravelResourceResponse,
  LaravelResponse
} from '../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService extends MainBaseApiService {

  index(data): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/affiliates`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  get(id: number): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/affiliates/${ id }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  store(data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/affiliates`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(affiliateId: number, data): Observable<LaravelResponse> {
    return this.http.put<LaravelResponse>(`${ this.apiUrl }/affiliates/${ affiliateId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  delete(affiliateId: number): Observable<LaravelResponse> {
    return this.http.delete<LaravelResponse>(`${ this.apiUrl }/affiliates/${ affiliateId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getProducts(affiliateId: number): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/affiliates/${ affiliateId }/products`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getExport(filters): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/affiliate/cases/export`, {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }
}
