import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthPaymentComponent } from './auth-payment/auth-payment.component';
import { ClientPaymentComponent } from './client-payment/client-payment.component';
import { SepaPaymentComponent } from './sepa-payment/sepa-payment.component';
import { ClientPaymentHandlerComponent } from './client-payment-handler/client-payment-handler.component';

const routes: Routes = [
  {
    path: 'client', children: [
      {
        path:     'cases',
        children: [
          {path: ':caseUuid/payments', component: AuthPaymentComponent},
        ],
      },
      {
        path: 'payment', children: [
          {path: '', component: ClientPaymentComponent},
          {path: 'outcome', component: ClientPaymentHandlerComponent},
        ],
      },
      {
        path: 'payments', children: [
          {path: 'payment-handler', component: ClientPaymentHandlerComponent},
          {
            path: 'outcome', children: [
              {path: 'success', component: ClientPaymentHandlerComponent, data: {result: 'success'}},
              {path: 'error', component: ClientPaymentHandlerComponent, data: {result: 'error'}},
            ]
          },
        ],
      },
      {
        path: 'sepa', children: [
          {path: '', component: SepaPaymentComponent},
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class PaymentRoutingModule {
}
