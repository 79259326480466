export class AffiliateCaseListFilter {
  affiliate_id?: number;
  affiliate_ids?: Array<number>;
  search?: string;
  start_date?: string | Date;
  end_date?: string | Date;
  conversion_status?: string;
  status_date_type?: 'sign_up' | 'conversion_date';
  statuses?: Array<number>;
  expected_count: number;
  select_all: boolean;

  per_page?: number;
  page?: number;

  order?: 'asc' | 'desc';
  sort_by?: string;
}
