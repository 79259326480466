<div class="row px-1" *ngIf="financialOverview">
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ 'CASES.financial_overview.table.unsecured_debt' | translate }}:
        <span class="text-primary"> {{ financialOverview.unsecured_debt | number: '1.2-2'}}€</span>
      </h4>
    </div>
  </div>
    <div class="col card mb-4 ml-1" style="padding: 0" *ngIf="financialOverview.claim_debt">
      <div class="card-body text-center">
        <h4 class="m-0" style="text-decoration: underline">
          {{ 'CASES.financial_overview.table.claim_debt' | translate }}:
          <span class="text-primary"> {{ financialOverview.claim_debt | number: '1.2-2'}}€</span>
        </h4>
      </div>
    </div>
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ 'CASES.financial_overview.table.monthly_payment' | translate }}:
        <span class="text-primary"> {{ financialOverview.monthly_payment | number: '1.2-2' }}€</span>
      </h4>
    </div>
  </div>
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ 'CASES.financial_overview.table.assets' | translate }}:
        <span class="text-primary"> {{ financialOverview.assets | number: '1.2-2' }}€</span>
      </h4>
    </div>
  </div>
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ 'CASES.financial_overview.table.income' | translate }}:
        <span class="text-primary"> {{ financialOverview.income | number: '1.2-2' }}€</span>
      </h4>
    </div>
  </div>
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ 'CASES.financial_overview.table.expenses' | translate }}:
        <span class="text-primary">{{ financialOverview.expenses | number: '1.2-2' }}€</span>
      </h4>
    </div>
  </div>
  <div class="col card mb-4 ml-1" style="padding: 0">
    <div class="card-body text-center">
      <h4 class="m-0" style="text-decoration: underline">
        {{ 'CASES.financial_overview.table.disposable_income' | translate }}:
        <span class="text-primary"> {{ (financialOverview.income - financialOverview.expenses) | number: '1.2-2'}}€
        </span>
      </h4>
    </div>
  </div>
</div>
