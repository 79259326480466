import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../../../../../_base-shared/guards/permission.guard';
import { ChangelogListComponent } from './changelog-list/changelog-list.component';

const routes: Routes = [
  {
    path:     'changelog',
    children: [
      {path: '', canActivate: [PermissionGuard], component: ChangelogListComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class ChangelogRoutingModule {
}
