import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../_base-shared/models/User/User';
import { CaseDocumentService } from '../../../admin/case/case-document.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-documents-preview',
  templateUrl: './documents-preview.component.html',
  styleUrls: ['./documents-preview.component.scss']
})
export class DocumentsPreviewComponent implements OnInit {
  @Input() case: Case;
  @Input() user: User;
  public isLoading = 0;
  public desktopVersion: boolean;
  public uploadedDocs: number;
  public requestedDocs: number;

  constructor(private breakpointObserver: BreakpointObserver,
              private documentService: CaseDocumentService) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge, Breakpoints.Medium]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
    this.getDocumentsCount();
  }

  private getDocumentsCount() {
    this.isLoading++;
    this.documentService.documentsCount(this.case.uuid, this.user.uuid).pipe(finalize(() => this.isLoading--)).subscribe(
      res => {
        this.uploadedDocs  = res.data.uploaded_documents;
        this.requestedDocs = res.data.app_document_requests_count + res.data.bank_accounts_count +
          res.data.creditors_count + res.data.document_file_requests_count + res.data.public_debts_count;
      },
      err => console.error(err),
    );
  }
}
