import { NgModule } from '@angular/core';
import { SharedPublicModule } from './_shared/shared-public.module';
import { PublicRoutingModule } from './public-routing.module';
import { PublicUnsubscribeComponent } from './public-unsubscribe/public-unsubscribe.component';
import { PaymentsComponent } from './payments/payments.component';

@NgModule({
  imports:      [
    SharedPublicModule,
    PublicRoutingModule,
  ],
  declarations: [
    PublicUnsubscribeComponent,
    PaymentsComponent,
  ],

})
export class PublicModule {
}
