<ng-container *ngIf="formActive">
  <div class="container client-typography">
    <form [formGroup]="form" (ngSubmit)="submit(form)" class="form">
      <div class="row">
        <div class="col-12">
          <!-- User Details Form -->
          <div class="mb-5">
            <div><h2>{{'SHARED.profile' | translate}}</h2></div>
            <p>{{caseRef}}</p>
            <div *ngIf="isLoading" class="row">
              <div class="col-12 pt-5 text-center">
                <mat-spinner class="m-0 m-auto"></mat-spinner>
              </div>
            </div>
            <div [hidden]="isLoading">
              <!-- First Name -->
              <app-input type="text" formControlName="first_name" [clientTypography]="true"
                         [label]="'CLIENT-PORTAL.validator-form.name' | translate" [readonly]="true"
                         [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                         [requiredStar]="true" [showClear]="false">
              </app-input>
              <!-- Last Name -->
              <app-input type="text" formControlName="last_name" [clientTypography]="true"
                         [label]="'USER.settings.form-details.last_name' | translate" [readonly]="true"
                         [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                         [requiredStar]="true" [showClear]="false">
              </app-input>
              <!-- Id card -->
              <app-input type="text" formControlName="id_card"
                         [label]="'SHARED.dni_number' | translate" [clientTypography]="true" [readonly]="true"
                         [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                         [requiredStar]="true" [showClear]="false">
              </app-input>
              <!-- Phone -->
              <app-input type="number" formControlName="phone"
                         [label]="'USERS.editor.phone' | translate" [clientTypography]="true" [readonly]="true"
                         [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                         [requiredStar]="true" [showClear]="false">
              </app-input>
            </div>
          </div>
          <!-- Change Password Form-->
          <div class="mb-2">
            <div><h2>{{ "USER.settings.form-password.title" | translate }}</h2></div>
            <div *ngIf="isLoading" class="row">
              <div class="col-12 pt-5 text-center">
                <mat-spinner class="m-0 m-auto"></mat-spinner>
              </div>
            </div>
            <div [hidden]="isLoading">
              <!-- Current Password -->
              <app-input type="password" formControlName="current_password" [clientTypography]="true"
                         [label]="'USER.settings.form-password.current_password' | translate"
                         [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                         [requiredStar]="true" [showClear]="false">
              </app-input>
              <div
                *ngIf="form.get('current_password').errors && (form.get('current_password').touched || formSubmitted)">
                <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                   [fieldName]="'current_password'">
                </app-server-errors>
              </div>
              <!-- New Password -->
              <app-input type="password" formControlName="password" [clientTypography]="true"
                         [label]="'USER.settings.form-password.password' | translate"
                         [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                         [requiredStar]="true" [showClear]="false">
              </app-input>
              <div *ngIf="form.get('password').errors && (form.get('password').touched || formSubmitted)">
                <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                   [fieldName]="'password'">
                </app-server-errors>
              </div>
              <!-- New Password Confirmation -->
              <app-input type="password" formControlName="password_confirmation" [clientTypography]="true"
                         [label]="'USER.settings.form-password.password_confirmation' | translate"
                         [showLabel]="true" [extraLabel]="false" appearance="standard" [fullWidth]="true"
                         [requiredStar]="true" [showClear]="false">
              </app-input>
              <mat-error *ngIf="form.get('password_confirmation').hasError('confirmedValidator')">
                {{'SHARED.passwords_must_match' | translate}}
              </mat-error>
              <div
                *ngIf="form.get('password_confirmation').errors && (form.get('password_confirmation').touched || formSubmitted)">
                <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                   [fieldName]="'password_confirmation'">
                </app-server-errors>
              </div>
            </div>
          </div>
          <!-- Submit -->
          <div class="d-flex" style="margin-bottom: 11.5rem !important;">
            <button class="spinner-btn" type="submit"
                    [disabled]="!form.valid">{{'SHARED.update_password' | translate}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>

