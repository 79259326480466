import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as cardValidation from 'creditcards';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CaseService } from '../../../admin/case/case.service';
import { PaymentService } from '../../../admin/payment/payment.service';
import { PaymentProcessorType } from '../../../../../../_base-shared/models/Payment/PaymentProcessor';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../services/main-global-event.service';
import { CustomValidators, expiryDateInPast } from '../../../../../../_base-shared/validators/custom.validators';

@Component({
  selector:    'app-card-info-modal',
  templateUrl: './card-info-modal.component.html',
  styleUrls:   ['./card-info-modal.component.scss'],
  animations:  [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.3s ease-out',
              style({height: '*', opacity: 1})),
          ],
        ),
        transition(
          ':leave',
          [
            animate('0.3s ease-in',
              style({height: 0, opacity: 0})),
          ],
        ),
      ],
    ),
  ],
})
export class CardInfoModalComponent implements OnInit {
  public authUser: User;
  public form: UntypedFormGroup;
  public executingPayment = false;
  public paymentResponse;
  public isSuccessful;
  public fetchingCards    = true;
  public formActive       = false;
  public isDevMode        = environment.devMode;
  public userCards        = [];
  public appEnv           = environment.APP_ENV;
  public tabIndex         = 0;

  public iFrameSrcSecured: SafeResourceUrl;
  public isRequestingPayment = 0;
  public isRequestSuccess: boolean;
  private appUrl: string;

  constructor(public translate: TranslateService,
              public globalEventsService: MainGlobalEventService,
              private sanitizer: DomSanitizer,
              private fb: UntypedFormBuilder,
              private caseService: CaseService,
              private toastr: ToastrService,
              private paymentService: PaymentService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<CardInfoModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.appUrl = environment.APP_URL;
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    if (this.data.type === 'Manual charge') {
      this.fetchCreditCards();
    } else {
      this.buildCardForm();
    }
  }

  tabChange($event) {
    this.tabIndex = $event;
  }

  isAdmin() {
    return this.authUser.role_id === 1 || this.authUser.role_id === 5;
  }

  private fetchCreditCards() {
    const requestData  = {force_default_processor: this.data.forceDefaultProcessor};
    this.fetchingCards = true;
    this.paymentService.getCreditCards(this.data.caseId, requestData).pipe(finalize(() => this.fetchingCards = false))
      .subscribe(result => {
        this.userCards = result.data.map(card => {
          const expDateObj         = moment(`${ card.card_exp_month }${ card.card_exp_year }`, 'MMYYYY')
            .startOf('month')
            .format();
          card.expires_at          = moment(expDateObj).endOf('month').format();
          const expirationDateDiff = moment(card.expires_at).diff(moment(), 'months', true);

          if (expirationDateDiff <= 4) {
            card.expire = 'soon';
          }

          if (expirationDateDiff < 0) {
            card.expire = 'expired';
          }

          card.card_brand = card.card_brand ? card.card_brand : cardValidation.card.type(card.card_bin, true);

          return card;
        });

        this.buildCardForm();
      });
  }

  removeCard($event, cardId) {
    $event.preventDefault();
    $event.stopPropagation();
    //  Check if removed card was default
    if (this.form.value.default_id === cardId) {
      this.form.get('default').enable();  //  Enable checkbox field
    }

    this.paymentService.removeCreditCard(this.data.caseId, cardId)
      .subscribe(next => {
        this.fetchCreditCards();
      });
  }

  setDefaultCard($event) {
    if ($event.checked) {
      if (this.form.value.chosen_card) {
        this.paymentService.setDefaultCard(this.form.value.chosen_card)
          .subscribe(
            next => {
              this.fetchCreditCards();  //  Re-fetch credit cards
              this.form.get('default').disable(); //  Disable checkbox
              this.toastr.success(this.translateService.instant('CARD-INFO.default-card-set-success'));
            },
            error => {
              this.toastr.error(this.translateService.instant('CARD-INFO.default-card-set-error'));
            },
          );
      }
    }
  }

  buildCardForm() {
    const maxPayableAmount = this.data.maxPayableAmount ? Math.min(this.data.maxPayableAmount, 5000) : 5000;
    this.form              = this.fb.group({
      case_id:      [null, null],
      amount:       [
        this.data.amount,
        [Validators.required, Validators.min(0.01), Validators.max(maxPayableAmount)],
      ],
      uuid:         [null, null],
      default_id:   [this.findDefaultCard(), null],
      default:      [{value: this.findDefaultCard(), disabled: !!this.findDefaultCard()}, null],
      type:         [this.data.type, null],
      chosen_card:  [this.findDefaultCard(), null],
      card_number:  [this.isDevMode ? '4200000000000000' : null, [Validators.required, CustomValidators.cardValidator]],
      cvv:          [this.isDevMode ? '123' : null, [Validators.required, CustomValidators.cvcValidator]],
      holder:       [this.isDevMode ? 'John Doe' : null, [Validators.required]],
      expiry_month: [this.isDevMode ? '10' : null, [Validators.required, CustomValidators.expiryMonthValidator]],
      expiry_year:  [this.isDevMode ? '22' : null, [Validators.required, CustomValidators.expiryYearValidator]],
      brand:        ['VISA', [Validators.required]],
    }, {validators: expiryDateInPast});

    this.formActive = true;
  }

  findDefaultCard() {
    const defaultCard = this.userCards.find(card => card.default);
    return defaultCard ? defaultCard.id : null;
  }

  onCardSelectChange($event) {
    const selectedCard = this.userCards.find(card => card.id === $event.value);
    //  Update values and enable/disable checkbox
    //  Default cards have checkbox disabled
    //  To prevent removing default flag from all cards
    if (selectedCard && selectedCard.default) {
      this.form.patchValue({default: 1});
      this.form.get('default').disable();
    } else {
      this.form.patchValue({default: 0});
      this.form.get('default').enable();
    }
  }

  formatCardNumber($event) {
    // const cardTypes = cardValidation.withTypes(['Visa', 'Mastercard']);
    const value       = $event.target.value;
    const parseValue  = cardValidation.card.parse(value);
    const formatValue = cardValidation.card.format(parseValue);
    this.form.get('card_number').setValue(formatValue);
  }

  onNoClick(reFetch): void {
    this.dialogRef.close(reFetch);
  }

  onSubmit(): void {
    if (this.form.value.chosen_card) {
      const data = {
        card_id:                 this.form.value.chosen_card,
        amount:                  this.form.value.amount,
        default:                 this.form.value.default,
        force_default_processor: this.data.forceDefaultProcessor
      };
      this.executeExistingCardPayment(this.data.caseId, data);
    } else if (this.form.valid) {
      const {card_number, expiry_month, expiry_year} = this.form.value;

      const formData                   = this.form.value;
      formData.expiry_year             = cardValidation.expiration.year.parse(expiry_year, true);
      formData.expiry_month            = expiry_month.length === 1 ? `0${ expiry_month }` : expiry_month;
      formData.card_number             = cardValidation.card.parse(card_number);
      formData.case_id                 = this.data.caseId;
      formData.default                 = this.form.value.default;
      formData.force_default_processor = this.data.forceDefaultProcessor;

      if (cardValidation.card.type(formData.card_number) === 'Visa') {
        formData.brand = 'VISA';
      }
      if (cardValidation.card.type(formData.card_number) === 'Mastercard') {
        formData.brand = 'MASTER';
      }

      this.executeNewCardPayment(this.data.caseId, formData);
    } else {
      this.form.markAllAsTouched();
    }
  }

  executeNewCardPayment(caseId, formData) {
    this.executingPayment = true;
    this.caseService.chargeNewCard(caseId, formData)
      .pipe(finalize(() => this.executingPayment = false))
      .subscribe(
        res => {
          this.isSuccessful    = true;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-success');
        },
        err => {
          this.isSuccessful    = false;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-failed');
        },
      );
  }

  executeExistingCardPayment(caseId: number, formData) {
    this.executingPayment = true;
    this.caseService.chargeExistingCard(caseId, formData)
      .pipe(finalize(() => this.executingPayment = false))
      .subscribe(
        res => {
          this.isSuccessful    = true;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-success');
        },
        err => {
          this.isSuccessful    = false;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-failed');
        },
      );
  }

  requestPayment(paymentProcessorType: PaymentProcessorType) {
    const data = {
      case_id:                this.data.caseId,
      payment_processor_type: paymentProcessorType,
      amount:                 this.form.value.amount,
      term_id:                null,
      set_default:            this.form.value.default,
      is_test:                paymentProcessorType === 'mymoid_moto' ? 1 : 0, // TODO: temp; hardcoded
    };

    this.isRequestingPayment++;
    this.paymentService.prepareTransaction(data).pipe(finalize(() => this.isRequestingPayment--))
      .subscribe(next => {
        this.isRequestSuccess = true;
        if (paymentProcessorType === 'mymoid_moto') {
          const baseUrl   = next.data.base_url;
          const urlOk     = encodeURI(this.appUrl + '/authorization-callbacks/mymoid-moto/iframe/success');
          const urlKo     = encodeURI(this.appUrl + '/authorization-callbacks/mymoid-moto/iframe/error');
          const shortCode = next.data.transactionable.short_code;
          const iFrameSrc = baseUrl + '/?urlok=' + urlOk + '&urlko=' + urlKo + '&p=' + shortCode;
          console.log(iFrameSrc);
          this.iFrameSrcSecured = this.sanitizer.bypassSecurityTrustResourceUrl(iFrameSrc);

          //  Scroll element into view
          //  Needs timeout until iframe is generated
          setTimeout(() => {
            const element = document.getElementById('payment-iframe');
            element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
          }, 1000);
        }

        if (paymentProcessorType === 'oppwa') {
          // TODO:
        }
      });
  }

}
