import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {StatusListComponent} from './status-list/status-list.component';
import {StatusEditorComponent} from './status-editor/status-editor.component';
import { PermissionGuard } from '../../../../../_base-shared/guards/permission.guard';

const routes: Routes = [
    {
        path: 'status',
        children: [
            {path: '', canActivate: [PermissionGuard], component: StatusListComponent},
            {path: 'create', canActivate: [PermissionGuard], component: StatusEditorComponent, data: {editor: 'create'}},
            {path: ':id/edit', canActivate: [PermissionGuard], component: StatusEditorComponent, data: {editor: 'edit'}},
        ],
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class StatusRoutingModule {
}
