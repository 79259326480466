import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-client-payment-footer',
  templateUrl: './client-payment-footer.component.html',
  styleUrls: ['./client-payment-footer.component.scss']
})
export class ClientPaymentFooterComponent implements OnInit, AfterViewInit{
  @ViewChild('colSeven') colSeven: ElementRef;
  @Input() divWidthOnResize: number;
  public styleObj;
  public resizeObservable: Observable<Event>;
  public resizeSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.resizeObservable   = fromEvent(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe(evt => {
      const divWidth = this.colSeven.nativeElement.offsetWidth;
      this.styleObj  = ((divWidth - 654) / 2) + 24;
    });
  }

  ngAfterViewInit(): void {
    this.styleObj  = ((this.colSeven.nativeElement.offsetWidth - 654) / 2) + 20;
    this.cdr.detectChanges();
  }
}
