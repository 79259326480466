<div>
    <h4>Bank Accounts</h4>
    <!--IBAN Number-->
    <form [formGroup]="bankAccountForm">
        <div class="row">
            <div class="col-12">
            <h5>Add new IBAN</h5>
            </div>
            <app-input type="text" id="iban" formControlName="iban" class="col-3"
                        [label]="'CREDITORS.iban' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.iban' | translate"
                        flexClass="align-items-center">
            </app-input>
            <!-- Description -->
            <app-input type="text" id="description" formControlName="description" class="col-5"
                    [label]="'CREDITORS.description' | translate"
                    [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                    [placeholder]="'CREDITORS.description' | translate"
                    flexClass="align-items-center">
            </app-input>
            <!-- Default checkbox -->
            <app-input type="checkbox" formControlName="default" class="col-2"
                    [label]="'CREDITORS.default' | translate">
            </app-input>
        </div>
        </form>
        <!-- Iterate over bank accounts and display in a table -->
        <div class="table-responsive">
        
        <div class="col-12">
            <!-- button save -->
            <button type="submit" mat-raised-button color="primary" style="min-width: 120px" (click)="saveBankAccount()">
            {{ "SHARED.save" | translate }}
            </button>
        </div>
    </div>
</div>