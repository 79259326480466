import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Creditor } from '../../../../../../_base-shared/models/Entity/Creditor';
import { ClientService } from '../../client.service';
import { CasePublicDebt } from '../../../../../../_base-shared/models/Case/CasePublicDebt';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector:    'app-creditors-step',
  templateUrl: './creditors-step.component.html',
  styleUrls: ['./creditors-step.component.scss'],
})
export class CreditorsStepComponent implements OnInit {
  @Input() caseUuid: string;
  @Input() userUuid: string;
  @Input() jointApplication: boolean;
  @Input() isReadOnly: boolean;
  @Input() isDesktop: boolean;
  public creditorForm: UntypedFormGroup;
  public debtForm: UntypedFormGroup;
  public isLoading = 0;
  public securedCreditors: Array<Creditor> = [];
  public unsecuredCreditors: Array<Creditor> = [];
  public publicDebts: Array<CasePublicDebt> = [];

  constructor(private clientService: ClientService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.isLoading++;
    this.clientService.indexCaseRelation(this.caseUuid, this.userUuid, 'creditors')
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        // @ts-ignore
        this.securedCreditors = result.data.secured || [];
        // @ts-ignore
        this.unsecuredCreditors = result.data.unsecured || [];
        // @ts-ignore
        this.publicDebts = result.data.public_debts || [];

        this.isLoading++;
        this.clientService.indexCaseRelationValidations(this.caseUuid, this.userUuid, 'creditor')
          .pipe(finalize(() => this.isLoading--))
          .subscribe(creditorsResult => {
            const creditors = creditorsResult.data.filter(creditor => creditor.validatable_type === 'creditor');
            const debts = creditorsResult.data.filter(debt => debt.validatable_type === 'public_debt');

            this.buildCreditorForm(creditors);
            this.buildDebtForm(debts);
          });
      });
  }

  private buildCreditorForm(validatableCreditors) {
    const creditors = this.securedCreditors.concat(this.unsecuredCreditors);
    const group = {};
    creditors.map(creditor => {
      // @ts-ignore
      const validatableCreditor = validatableCreditors.find(validatableItem => creditor.pivot.id === validatableItem.validatable_id);
      const validatableValue = validatableCreditor !== undefined ? validatableCreditor.correct : true;
      // @ts-ignore
      group[creditor.pivot.id] = new UntypedFormControl(validatableValue);
    });
    // @ts-ignore
    group.other_creditors = new UntypedFormControl(true);
    this.creditorForm = new UntypedFormGroup(group);
  }

  private buildDebtForm(validatableDebts) {
    const group = {};
    this.publicDebts.map(debt => {
      const validatableDebt = validatableDebts.find(validatableItem => debt.id === validatableItem.validatable_id);
      const validatableValue = validatableDebt !== undefined ? validatableDebt.correct : true;
      group[debt.id] = new UntypedFormControl(validatableValue);
    });

    this.debtForm = new UntypedFormGroup(group);
  }

  public getData() {
    const creditors = this.creditorForm.value;
    const debts = this.debtForm.value;
    const otherCreditors = creditors.other_creditors;
    delete creditors.other_creditors;

    return {
      creditors,
      other_creditors: otherCreditors,
      debts,
      relation: 'creditors'
    };
  }

}
