<div #signature>
  <!-- DGS Group and LSO Group (without `lso` product) Preview -->
  <div *ngIf="case?.product?.group_slug === 'dgs' || case?.product?.group_slug === 'ppi' || case?.product?.slug === 'lso2' || case?.product?.slug === 'lso-te' || case?.product?.slug === 'lso-pp' ||
   case?.product?.slug === 'lso-lq'">
    <div *ngIf="isLoadingContractPreview" class="d-flex justify-content-center align-items-center">
      <mat-spinner diameter="30" color="primary"></mat-spinner>
    </div>
    <app-html-shadow-dom [hidden]="isLoadingContractPreview" [unsafeHtml]="contractHtml"></app-html-shadow-dom>
    <div *ngIf="isLoadingMandatePreview" class="d-flex justify-content-center align-items-center">
      <mat-spinner diameter="30" color="primary"></mat-spinner>
    </div>
    <app-html-shadow-dom [hidden]="isLoadingMandatePreview" [unsafeHtml]="mandateHtml"></app-html-shadow-dom>
    <app-html-shadow-dom *ngIf="case?.product?.group_slug === 'dgs'" [hidden]="isLoadingRightOfAccessPreview"
                         [unsafeHtml]="rightOfAccessHtml"></app-html-shadow-dom>
    <div class="signature pt-6 with-logo" id="signature-LSO2"
         style="width: 210mm; margin: 0 auto; padding: 25mm">
      <div class="row">
        <div class="col-md-6">
          <p>Firmas</p>
          <img style="width:80%" class="img-fluid" src="/assets/img/ceo_sign2.png" alt="signature">
          <div class="pt-5">
            <p>D. Luis Miguel Díaz Simón</p>
            <p>Fdo. {{ companyNameLegal }}</p>
          </div>
        </div>
        <div class="col-md-6" id="signature-lso-group">
          <p *ngIf="!case?.joint_application"><b>Cliente:<span style="color:red">*</span></b></p>
          <p *ngIf="case?.joint_application"><b>Cliente 1:<span style="color:red">*</span></b></p>
          <div *ngIf="clientRole === 'client'">
            <div class="signature-area" data-toggle="modal" data-target="#sign">
              <p class="m-0" *ngIf="!prevSignatureImg && !loading || (resign && !loading)">FIRMAR AQUI</p>
              <div *ngIf="prevSignatureImg && !loading && !resign">
                <img class="img-fluid" src="{{imgPath + prevSignatureImg}}" alt="">
              </div>
              <div *ngIf="loading">
                <mat-spinner class=""></mat-spinner>
              </div>
            </div>
            <p class="m-0">{{ case?.client.first_name}} {{ case?.client.last_name}}</p>
            <p class="m-0">DNI: {{ case?.client?.id_card}}</p>
          </div>
          <div *ngIf="clientRole === 'client'">
            <div *ngIf="case?.joint_application">
              <p class="pt-3"><b>Cliente 2:<span style="color:red">*</span></b></p>
              <div class="signature-area" style="cursor: default">
                <div *ngIf="prevSignatureImgPartner">
                  <img class="img-fluid" src="{{imgPath + prevSignatureImgPartner}}" alt="">
                </div>
              </div>
              <p class="m-0">{{ case?.partner?.first_name}} {{ case?.partner.last_name}}</p>
              <p class="m-0">DNI: {{ case?.partner?.id_card}}</p>
            </div>
          </div>

          <div *ngIf="clientRole === 'partner'">
            <div class="signature-area" style="cursor: default">
              <div *ngIf="prevSignatureImg">
                <img class="img-fluid" src="{{imgPath + prevSignatureImg}}" alt="">
              </div>
            </div>
            <p class="m-0">{{ case?.client.first_name}} {{ case?.client.last_name}}</p>
            <p class="m-0">DNI: {{ case?.client?.id_card}}</p>
          </div>
          <div *ngIf="clientRole === 'partner'">
            <div *ngIf="case?.joint_application">
              <p class="pt-3"><b>Cliente 2:<span style="color:red">*</span></b></p>
              <div class="signature-area" data-toggle="modal" data-target="#sign">
                <p class="m-0" *ngIf="!prevSignatureImgPartner && !loading || (resign && !loading)">FIRMAR AQUI</p>
                <div *ngIf="prevSignatureImgPartner && !loading && !resign">
                  <img class="img-fluid" src="{{imgPath + prevSignatureImgPartner}}" alt="">
                </div>
                <div *ngIf="loading">
                  <mat-spinner class=""></mat-spinner>
                </div>
              </div>
              <p class="m-0">{{ case?.partner?.first_name}} {{ case?.partner?.last_name}}</p>
              <p class="m-0">DNI: {{ case?.partner?.id_card}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Signatures -->
<div class="log mt-5" *ngIf="!isLoadingContract">
  <div class="container" style="background: #fff; padding: 0 50px 100px 50px">
    <h2 class="text-center p-3">Firma Electrónica</h2>
    <div style="border-bottom: 1px solid #6666; border-top: 1px solid #6666; margin-top: 30px">
      <h2 class="m-0 py-3">Documento firmado. Código de verificación enviado por {{ companyName }}</h2>
    </div>
    <div style="border-bottom: 1px solid #6666; padding: 20px 0">
      <div class="row">
        <div class="col-md-4">
          <p style="margin: 0; padding: 10px 0"><b>TITULO</b></p>
        </div>
        <div class="col-md-8">
          <p style="margin: 0; padding: 10px 0">Hoja de encargo</p>
        </div>
      </div>
      <div *ngIf="pdfFileName" class="row">
        <div class="col-md-4">
          <p style="margin: 0; padding: 10px 0"><b>NOMBRE DEL ARCHIVO <br>CERTIFICADO DIGITAL</b></p>
        </div>
        <div class="col-md-8">
          <p style="margin: 0; padding: 10px 0">{{pdfFileName}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <p style="margin: 0; padding: 10px 0"><b>ESTADO</b></p>
        </div>
        <div class="col-md-8">
          <p style="margin: 0; padding: 10px 0" class="green-circle">Completado Correctamente</p>
        </div>
      </div>
    </div>
    <!-- Timestamps -->
    <div>
      <h2 style="margin: 0; padding: 30px 0">Histórico del proceso</h2>
      <div class="row contract-timestamps-holder" style="padding-left: 100px">
        <div class="col-lg-4">
          <div class="img-wrap-sent" style="padding-right: 100px; display: inline-block;">
            <img src="assets/img/send_black.png" alt="sent">
          </div>
          <div style="display: inline-block; position: relative; bottom: 10px; margin-top: 20px">
            <p style="margin: 0"><b>{{ case?.contracts[0]?.created_at | toDateObject | date : 'dd/MM/yyyy' }}</b>
            </p>
            <p style="margin: 0">
              <b>{{ case?.contracts[0]?.created_at | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
          </div>
        </div>
        <div class="col-lg-8">
          <p style="margin: 0">
            Envio de firma electronica para {{ case?.client.first_name}} {{ case?.client?.last_name}}
            ({{ case?.client?.email}}) desde {{ companyName }} contract@deudafix.com
          </p>
        </div>
      </div>
      <div class="row contract-timestamps-holder" style="padding: 30px 0 0 100px">
        <div class="col-lg-4">
          <div class="img-wrap-viewed" style="padding-right: 100px; display: inline-block;">
            <img src="assets/img/visibility.png" alt="sent">
          </div>
          <div style="display: inline-block; position: relative; bottom: 10px; margin-top: 20px">
            <p style="margin: 0">
              <b>{{ case?.contracts[0]?.viewed_by_client | toDateObject | date : 'dd/MM/yyyy' }}</b></p>
            <p style="margin: 0">
              <b>{{ case?.contracts[0]?.viewed_by_client | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
          </div>
        </div>
        <div class="col-lg-8">
          <p style="margin: 0">
            <b>Verificado por: </b>{{ case?.client.first_name}} {{ case?.client?.last_name}} ({{ case?.client?.email}})
          </p>
          <p style="margin: 0"><b>Firmante ID: {{case?.contracts[0]?.client_ip}}</b></p>
        </div>
      </div>
      <div *ngIf="case?.joint_application">
        <div class="row contract-timestamps-holder" style="padding: 30px 0 0 100px">
          <div class="col-lg-4">
            <div class="img-wrap-viewed" style="padding-right: 100px; display: inline-block;">
              <img src="assets/img/visibility.png" alt="sent">
            </div>
            <div style="display: inline-block; position: relative; bottom: 10px; margin-top: 20px">
              <p style="margin: 0">
                <b>{{ case?.contracts[0]?.viewed_by_partner | toDateObject | date : 'dd/MM/yyyy' }}</b></p>
              <p style="margin: 0">
                <b>{{ case?.contracts[0]?.viewed_by_partner | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
            </div>
          </div>
          <div class="col-lg-8">
            <p style="margin: 0">
              <b>Verificado por: </b>{{ case?.partner?.first_name}} {{ case?.partner?.last_name}}
              ({{ case?.partner?.email}})
            </p>
            <p style="margin: 0"><b>Firmante ID: {{case?.contracts[0]?.partner_ip}}</b></p>
          </div>
        </div>
      </div>
      <div class="row contract-timestamps-holder" style="padding: 30px 0 0 100px">
        <div class="col-lg-4">
          <div class="img-wrap-completed" style="padding-right: 100px; display: inline-block;">
            <img src="assets/img/check_circle.png" alt="sent">
          </div>
          <div style="display: inline-block; position: relative; bottom: 10px; margin-top: 20px">
            <p style="margin: 0">
              <b>{{ case?.contracts[0]?.signed_by_client | toDateObject | date : 'dd/MM/yyyy' }}</b></p>
            <p style="margin: 0">
              <b>{{ case?.contracts[0]?.signed_by_client | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
          </div>
        </div>
        <div class="col-lg-8">
          <p style="margin: 0">El documento ha sido correctamente
            por: {{ case?.client.first_name}} {{ case?.client?.last_name}}.</p>
          <p *ngIf="case?.contracts[0]?.signed_client_ip"><b>Firmante ID: {{case?.contracts[0]?.signed_client_ip}}</b>
          </p>
        </div>
      </div>
      <div *ngIf="case?.joint_application">
        <div class="row contract-timestamps-holder" style="padding: 30px 0 0 100px">
          <div class="col-lg-4">
            <div class="img-wrap-completed" style="padding-right: 100px; display: inline-block;">
              <img src="assets/img/check_circle.png" alt="sent">
            </div>
            <div style="display: inline-block; position: relative; bottom: 10px; margin-top: 20px">
              <p style="margin: 0">
                <b>{{ case?.contracts[0]?.signed_by_partner | toDateObject | date : 'dd/MM/yyyy' }}</b></p>
              <p style="margin: 0">
                <b>{{ case?.contracts[0]?.signed_by_partner | toDateObject | date : 'H:mm:ss': 'UTC' }}</b></p>
            </div>
          </div>
          <div class="col-lg-8">
            <p style="margin: 0">El documento ha sido correctamente
              por: {{ case?.partner?.first_name}} {{ case?.partner?.last_name}}</p>
            <p *ngIf="case?.contracts[0]?.signed_partner_ip"><b>Firmante
              ID: {{case?.contracts[0]?.signed_partner_ip}}</b></p>
          </div>
        </div>
      </div>
    </div>
    <!--  End Timestamps -->
  </div>
</div>

<!-- Signature modal -->
<div class="modal fade" id="sign" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Sign contract</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div (window:resize)="onResize()" class="signature-wrap">
          <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()">
          </signature-pad>
        </div>
      </div>
      <div class="modal-footer">
        <button mat-raised-button color="primary" type="button" class="mr-auto" (click)="clear()">
          Clear signature
        </button>
        <button mat-stroked-button color="primary" class="mr-3" type="button" data-dismiss="modal">Close</button>
        <button mat-raised-button color="primary" type="button" data-dismiss="modal" (click)="saveSignature()">
          Save signature
        </button>
      </div>
    </div>
  </div>
</div>
