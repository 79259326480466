import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import {
  CaseInstallmentListComponent,
} from '../../../case/case-detail/case-payment/case-installment-list/case-installment-list.component';
import {
  CasePaymentListComponent,
} from '../../../case/case-detail/case-payment/case-payment-list/case-payment-list.component';
import {
  CasePaymentRequestListComponent,
} from '../../../case/case-detail/case-payment/case-payment-request-list/case-payment-request-list.component';
import {
  CaseTransactionListComponent,
} from '../../../case/case-detail/case-payment/case-transaction-list/case-transaction-list.component';
import { DistributionService } from '../../distribution.service';

@Component({
  selector:    'app-distribution-case-payment',
  templateUrl: './distribution-case-payment.component.html',
  styles:      [],
})
export class DistributionCasePaymentComponent implements OnInit, OnDestroy {
  @ViewChild(CaseInstallmentListComponent) caseInstallmentListComponent: CaseInstallmentListComponent;
  @ViewChild(CasePaymentListComponent) casePaymentListComponent: CasePaymentListComponent;
  @ViewChild(CaseTransactionListComponent) caseTransactionListComponent: CaseTransactionListComponent;
  @ViewChild(CasePaymentRequestListComponent) casePaymentRequestListComponent: CasePaymentRequestListComponent;
  public componentType: 'distribution' | 'admin';
  public authUser: User;
  public case: Case;
  public isLoading                            = 0;
  public serverResponse: LaravelResponse;
  public fetchInstalments$: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions: Array<Subscription> = [];

  constructor(
    private route: ActivatedRoute,
    private globalEventsService: MainGlobalEventService,
    private distributionService: DistributionService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser      = user;
      this.componentType = this.authUser.role.slug === 'distribution-provider' ? 'distribution' : 'admin';
    });
    this.route.parent.paramMap.subscribe(params => {
      const caseUuid = params.get('uuid');
      this.isLoading++;
      this.subscriptions.push(
        this.distributionService.showCase(caseUuid, ['client', 'terms', 'loan_payment_plan'])
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            result => this.case = result.data,
            error => this.serverResponse = error.error,
          ),
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public updateComponentType(componentType: 'distribution' | 'admin') {
    this.componentType = componentType;
    this.caseInstallmentListComponent.updateComponentType(componentType);
    this.casePaymentListComponent.updateComponentType(componentType);
    this.caseTransactionListComponent.updateComponentType(componentType);
    this.casePaymentRequestListComponent.updateComponentType(componentType);
  }

}
