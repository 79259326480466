import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { CaseDocumentService } from '../../admin/case/case-document.service';
import { CaseService } from '../../admin/case/case.service';
import { ClientService } from '../client.service';

@Component({
  selector:    'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls:   ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  @Input() client: User;
  @Input() case: Case;
  @ViewChild('videoPlayer', {static: false}) videoplayer: ElementRef;
  public userUuid: string;
  public caseUuid: string;
  public uploader: string;
  public storageUrl                           = environment.STORAGE_URL + '/';
  public requestedFilesArray                  = [];
  public completedFilesArray                  = [];
  public creditorsRequiredFilesArray          = [];
  public bankRequiredFilesArray               = [];
  public uploadedFiles                        = [];
  public creditorFiles                        = [];
  public bankFiles                            = [];
  public publicDebtFiles                      = [];
  public loading                              = 0;
  public showDniFront                         = true;
  public showDniBack                          = true;
  public bannerImage: string;
  public creditorDocsVisible: boolean | 0 | 1 = false;
  public bankDocsVisible: boolean | 0 | 1     = false;
  public isVideoPlaying                       = false;
  public uploadedDocs: number;
  public requestedDocs: number;

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private clientService: ClientService,
              private documentService: CaseDocumentService,
              private caseService: CaseService,
              private globalEventsService: MainGlobalEventService,
              private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.userUuid = this.route.snapshot.params.userUuid;
    this.caseUuid = this.route.snapshot.params.caseUuid;
    this.setBannerImage();
    this.globalEventsService.authUser$.subscribe(user => {
      this.client = user;
      this.getDocumentsWithUuid();
    });
  }

  public checkIfVideoIsPlaying() {
    Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
      get() {
        return !! (this.currentTime > 0 && ! this.paused && ! this.ended && this.readyState > 2);
      },
    });
  }

  getDocumentsWithUuid() {
    this.loading++;
    this.caseService.caseInfo(this.caseUuid)
      .pipe(finalize(() => this.loading--))
      .subscribe(res => {
        this.case = res.data;
        this.getDocumentsCount();
        this.globalEventsService.setClientSelectedCase(this.case);
        this.uploader = this.client.id === this.case.user_id ? 'client' : 'partner';

        this.requestedFilesArray = this.uploader === 'client' ? res.data.file_requests : res.data.partner_file_requests;
        this.requestedFilesArray.forEach((file) => {
          if (file.pivot.completed === 1) {
            this.completedFilesArray.push(file);
          }
        });
        this.requestedFilesArray = this.requestedFilesArray.filter(x => x.pivot.completed !== 1);
        this.creditorsRequiredFiles(this.case);
        this.bankRequiredFiles(this.case);
        this.setUploadedDocuments(res);
        this.setClientDocumentsVisibility();
      });
  }

  creditorsRequiredFiles(data) {
    const allApprovedCreditors = data.creditors;
    const allPublicDebts       = data.public_debts;
    //  Format and add name property
    allPublicDebts.map(obj => {
      obj.name = obj.public_organisation.replace('-', ' ').toUpperCase();
    });

    this.creditorsRequiredFilesArray = allApprovedCreditors.concat(allPublicDebts);
    this.creditorsRequiredFilesArray = [
      ...new Map(this.creditorsRequiredFilesArray.map(file => [file.name, file])).values()];
  }

  bankRequiredFiles(data) {
    this.bankRequiredFilesArray = data.assets.filter(asset => asset.type === 'bank_accounts');
    //  Filter out accounts that are in the same bank (remove duplicate)
    this.bankRequiredFilesArray = this.bankRequiredFilesArray.filter((bank, index, arr) =>
      index === arr.findIndex(t => (t.entity.id === bank.entity.id)));
    // this.bankRequiredFilesArray = [...new Map(this.bankRequiredFilesArray.map(file => [file.entity.name, file])).values()];
  }

  formatFileType(name) {
    if (name === 'social-security' || name === 'estate' || name === 'town-hall') {
      return this.translate.instant('CASE_CREDITOR.model.public_organization.options.' + name);
    }

    return name;
  }

  public onFileInput(event, file, type, side) {
    if (file.name === 'dni') {
      if (side === 'front') {
        this.showDniFront = false;
      } else {
        this.showDniBack = false;
      }
    }

    const formData = new FormData();
    formData.append('uploaded_by', this.uploader);
    formData.append('client_side_upload', '1');

    if (type === 'banks') {
      formData.append(`bank-${ file.entity.id }[]`, event.target.files.item(0));
    } else if (type === 'creditors') {
      if (file.debt_type) {
        formData.append(`publicdebt-${ file.id }[]`, event.target.files.item(0));
      } else {
        formData.append(`creditor-${ file.id }[]`, event.target.files.item(0));
      }
    } else {
      formData.append(`${ file.name }[]`, event.target.files.item(0));
    }

    this.documentService.uploadFiles(this.caseUuid, formData)
      .pipe(finalize(() => {
        this.confirmCompleteTask();
        this.loading--;
      }))
      .subscribe(res => {
        this.setUploadedDocuments(res);
      });
  }

  setUploadedDocuments(res) {
//  Get all files for client and partner
    this.uploadedFiles   = this.uploader === 'client' ?
      res.data?.client_files_by_type :
      res.data?.partner_files_by_type;
    this.creditorFiles   = this.uploader === 'client' ?
      res.data?.files_by_creditor :
      res.data?.partner_files_by_creditor;
    this.publicDebtFiles = this.uploader === 'client' ?
      res.data?.files_by_public_debt :
      res.data?.partner_files_by_public_debt;
    this.bankFiles       = this.uploader === 'client' ?
      res.data?.client_files_by_bank :
      res.data?.partner_files_by_bank;

    //  Add classes to personal documents used for CSS styling
    this.generateCssClasses(this.requestedFilesArray, this.uploadedFiles);
    //  Add classes to personal documents used for CSS styling
    this.generateCssClasses(this.completedFilesArray, this.uploadedFiles);
    //  Add classes to creditor documents used for CSS styling
    this.generateCssClasses(this.creditorsRequiredFilesArray, this.creditorFiles);
    //  Add classes to bank documents used for CSS styling
    this.generateCssClasses(this.bankRequiredFilesArray, this.bankFiles);
  }

  onRemove(event, file) {
    this.documentService.removeUploadedFile(this.caseUuid, file.id)
      .subscribe(res => {
        this.showDniFront = true;
        this.showDniBack  = true;
        this.setUploadedDocuments(res);
      });
  }

  generateCssClasses(requiredFiles, uploadedFiles) {
    //  Check if there are uploaded files for each file requested
    requiredFiles.map(file => {
      const files = file.entity ? uploadedFiles[file.entity.name] : uploadedFiles[file.name];
      if (files) {
        let status = '';
        files.map(document => {
          if (status !== 'pending' && status !== 'declined') {
            status = document.status;
          }
        });
        if (status === 'pending') {
          return file.documentClass = 'pending-document';
        } else if (status === 'accepted') {
          return file.documentClass = 'accepted-document';
        } else if (status === 'declined') {
          return file.documentClass = 'declined-document';
        } else {
          return '';
        }
      }
    });
  }

  setBannerImage() {
    const bannerImages = [
      'https://www.deudafix.es/portal-img/img1.jpeg',
      'https://www.deudafix.es/portal-img/img2.jpeg',
      'https://www.deudafix.es/portal-img/img3.jpeg',
    ];

    this.bannerImage = bannerImages[Math.floor(Math.random() * bannerImages.length)];
  }

  setClientDocumentsVisibility() {
    if (this.case.creditors.length) {
      this.creditorDocsVisible = this.case.creditors[0].pivot.client_documents_visible;
    }

    if (this.case.assets.length) {
      const bankAssets = this.case.assets.filter(asset => asset.type === 'bank_accounts');

      if (bankAssets.length) {
        this.bankDocsVisible = bankAssets[0].client_documents_visible;
      }
    }
  }

  public playVideo() {
    this.isVideoPlaying = true;
    const myVideo: any  = document.getElementById('videoOne');
    myVideo.play();
  }

  public play(isPlayimg) {
    if (isPlayimg === '1') {
      this.isVideoPlaying = true;
    }
    if (isPlayimg === '0') {
      this.isVideoPlaying = false;
    }
  }

  public confirmCompleteTask() {
    Swal.fire({
      title:             this.translate.instant('CLIENT.documents.document_uploaded'),
      icon:              'success',
      customClass:       {
        icon: 'icon-blue'
      },
      showCancelButton:  true,
      cancelButtonText:  this.translate.instant('CLIENT.documents.close'),
      cancelButtonColor: '#0A6EBD',
      showConfirmButton: false,
      showCloseButton:   false,
    });
  }

  private getDocumentsCount() {
    if ( ! this.case || ! this.client) {
      this.uploadedDocs  = 0;
      this.requestedDocs = 0;
      return;
    }

    this.documentService.documentsCount(this.case?.uuid, this.client?.uuid)
      .subscribe(
        res => {
          this.uploadedDocs  = res.data.uploaded_documents;
          this.requestedDocs = res.data.app_document_requests_count + res.data.bank_accounts_count +
            res.data.creditors_count + res.data.document_file_requests_count + res.data.public_debts_count;
        },
        err => console.error(err)
      );
  }
}
