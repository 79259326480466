<div class="div justify-content-center details-step-container client-typography-v2">
  <div class="secured" *ngIf="securedCreditors.length">
    <h2>{{"CLIENT-PORTAL.validator-form.secured-creditors" | translate}}</h2>
    <table class="table table-borderless table-striped client-table" *ngIf="securedCreditors.length && !isLoading"
           [formGroup]="creditorForm">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let secured of securedCreditors">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
               *ngIf="secured.pivot.current_balance">
          <span class="creditor-value">
            <p class="m-0" [ngClass]="{'your-case-typography': isReadOnly}">{{secured.name}}</p>
            {{secured.pivot.current_balance | number: '1.2-2'}}€
          </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper">
              <mat-slide-toggle formControlName="{{secured.pivot.id}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
    <div *ngIf="!securedCreditors.length && !isLoading">
      <p class="text-center">No tienes acreedores garantizados</p>
    </div>
  </div>

  <div class="unsecured pt-3" *ngIf="unsecuredCreditors.length">
    <h2>{{"CLIENT-PORTAL.validator-form.unsecured-creditors" | translate}}</h2>
    <table class="table table-borderless table-striped client-table" *ngIf="unsecuredCreditors.length && !isLoading"
           [formGroup]="creditorForm">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let unsecured of unsecuredCreditors">
          <td class="d-flex justify-content-between mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="unsecured.pivot.current_balance">
          <span class="creditor-value">
            <p class="my-0">{{unsecured.name}}</p>
            {{unsecured.pivot.current_balance | number: '1.2-2'}}€
          </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper">
              <mat-slide-toggle formControlName="{{unsecured.pivot.id}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
    <div *ngIf="!unsecuredCreditors.length && !isLoading">
      <p class="text-center">No tienes acreedores sin garantia</p>
    </div>
  </div>

  <div class="public-debts pt-3" *ngIf="publicDebts.length">
    <h2>{{"CLIENT-PORTAL.validator-form.public-debts" | translate}}</h2>
    <table class="table table-borderless table-striped client-table" *ngIf="publicDebts.length && !isLoading"
           [formGroup]="debtForm">
      <tbody>
      <tr class="d-flex flex-row flex-wrap justify-content-between">
        <ng-container *ngFor="let debt of publicDebts">
          <td class="d-flex justify-content-between  mr-1" [ngStyle]="{'width': isDesktop ? '49%' : '100%'}"
              *ngIf="debt.current_balance">
          <span class="creditor-value">
            <p class="my-0">{{'CASE_CREDITOR.model.public_organization.options.' +
            debt.public_organisation  | translate}}</p>
            {{debt.current_balance | number: '1.2-2'}}€
          </span>
            <span class="d-flex align-items-end mr-2" *ngIf="!isReadOnly">
            <div class="slide-toggle-wrapper">
              <mat-slide-toggle formControlName="{{debt.id}}" disableRipple>
              </mat-slide-toggle>
            </div>
          </span>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>
    <div *ngIf="!publicDebts.length && !isLoading">
      <p class="text-center">No tienes deudas públicas</p>
    </div>
  </div>

  <div class="other-creditors pt-3" *ngIf="!isReadOnly">
    <table class="table table-borderless table-striped client-table" [formGroup]="creditorForm"
           *ngIf="creditorForm && !isLoading">
      <tbody>
      <tr>
        <td class="d-flex flex-row justify-content-between mr-1">
          <p class="m-0">{{"CLIENT-PORTAL.validator-form.other-creditors" | translate}}?</p>
          <mat-slide-toggle class="ml-2 mr-24" formControlName="other_creditors" disableRipple>
          </mat-slide-toggle>
          <!-- <mat-radio-group formControlName="other_creditors" aria-label="Select an option" class="radio-btn">
             <mat-radio-button color="primary" [value]="true" class="mr-2 ">
               {{ "SHARED.yes" | translate }}
             </mat-radio-button>
             <mat-radio-button [value]="false" class="no-radio-btn">
               {{ "SHARED.no" | translate }}
             </mat-radio-button>
           </mat-radio-group>-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
