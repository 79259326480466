<div class="row" [ngClass]="{'d-none-sm': !desktopVersion}">
  <h2>{{'CLIENT.client-dashboard.welcome' | translate}}, {{authUser.first_name}}</h2>
</div>

<div *ngIf="authUser && !case" [hidden]="isLoadingOnboarding" class="row">
  <div *ngFor="let clientCase of clientCases" style="width: 100%" class="mt-3 text-center">
    <button mat-raised-button color="primary"
             (click)="selectCase(authUser.uuid, clientCase.uuid)">
      {{ clientCase.ref_number }}
    </button>
  </div>
</div>
<div *ngIf="authUser && case && !isLoadingOnboarding" class="row mx-2"  [ngClass]="{'p-large-lg': desktopVersion}">
  <!--Onboarding preview-->
  <div class="col-md-4">
    <div class="mb-3">
      <mat-spinner diameter="50" *ngIf="isLoadingOnboarding" class="m-0 m-auto"></mat-spinner>
      <app-onboarding-preview [hidden]="isLoadingOnboarding" (caseFinished)="setCaseStatus($event)"
                              [caseRelationValidation]="caseRelationValidation" [case]="case" [user]="authUser" [caseDataValidation]="caseDataValidation">
      </app-onboarding-preview>
    </div>

    <!--Documents preview-->
    <div class="mb-3">
      <mat-spinner diameter="50" *ngIf="isLoadingOnboarding" class="m-0 m-auto"></mat-spinner>
      <app-documents-preview [hidden]="isLoadingOnboarding" [case]="case" [user]="authUser">
      </app-documents-preview>
    </div>
  </div>

  <div class="col-md-4">
    <!--Payment preview-->
    <div class="mb-3" *ngIf="!isPaymentPlanPaid">
      <mat-spinner diameter="50" *ngIf="isLoadingOnboarding" class="m-0 m-auto"></mat-spinner>
      <app-payment-preview (paymentPlanPaid)="hidePaymentPlan($event)" [hidden]="isLoadingOnboarding" [case]="case" [user]="authUser">
      </app-payment-preview>
    </div>

    <!--Details preview-->
    <div class="mb-3">
      <mat-spinner diameter="50" *ngIf="isLoadingOnboarding" class="m-0 m-auto"></mat-spinner>
      <app-profile-preview [case]="case" [user]="authUser" [isDesktop]="desktopVersion"></app-profile-preview>
    </div>
  </div>

  <div class="col-md-4 d-md-flex">
    <div class="banner-holder">
      <img class="img-fluid" [src]="bannerImage" alt="">
    </div>
  </div>
</div>
