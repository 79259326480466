<div class="container">
  <h1 class="pb-5">{{ "REPORTS.headline-reports" | translate }}</h1>
  <div class="row px-1">
    <div class="col-md-4" style="padding: 0">
      <div class="card mb-4 h-100">
        <div class="card-body text-center">
          <h4>{{ "REPORTS.total-unpaid-fees" | translate }}</h4>
          <div>
            <span class="text-primary" style="font-size: 25px">{{totalUnpaidFee | number: '1.2-2'}}€</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4" style="padding: 0">
      <div class="card mb-4 h-100">
        <div class="card-body text-center">
  <!--        <h4>{{ "REPORTS.total-fees-this-month" | translate }}</h4>-->
  <!--        <div>-->
  <!--          <span class="text-primary" style="font-size: 25px">{{feesThisMonth | number: '1.2-2'}}€</span>-->
  <!--        </div>-->
          <h4>{{ "REPORTS.cancellation-percent" | translate }}</h4>
          <div>
            <span class="text-primary" style="font-size: 25px">{{cancellationPercent}}%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4" style="padding: 0">
      <div class="card mb-4 h-100">
        <div class="card-body text-center">
          <h4>{{ "REPORTS.default-percent" | translate }}</h4>
          <div>
            <span class="text-primary" style="font-size: 25px">{{defaultPercent}}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row px-1 pt-5">
    <div class="col-md-3" style="padding: 0">
      <div class="card mb-4">
        <div class="card-body text-center">
          <h4>Clients with Active Loans</h4>
          <div>
            <span class="text-primary" style="font-size: 25px">{{clientsPrestamoActivo}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3" style="padding: 0">
      <div class="card mb-4">
        <div class="card-body text-center">
          <h4>Clients in Extra Judicial</h4>
          <div>
            <span class="text-primary" style="font-size: 25px">{{extraJudicial}}</span>
          </div>
        </div>
      </div>
    </div>


    <div class="col-md-3" style="padding: 0">
      <div class="card mb-4">
        <div class="card-body text-center">
          <h4>{{ "REPORTS.clients-judiscial" | translate }}</h4>
          <div>
            <span class="text-primary" style="font-size: 25px">{{clientsJudicial}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3" style="padding: 0">
      <div class="card mb-4">
        <div class="card-body text-center">
          <h4>{{ "REPORTS.clients-frozen" | translate }}</h4>
          <div>
            <span class="text-primary" style="font-size: 25px">{{frozenClients}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3" style="padding: 0" *ngFor="let item of loansOptions">
      <div class="card mb-4">
        <div class="card-body text-center">
          <h4>{{item.label}}</h4>
          <div>
            <span class="text-primary" style="font-size: 25px">{{item.value | number: '1.2-2' }}€</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
