import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DashboardService } from '../dashboard.service';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { BaseChartDirective, Label, MultiDataSet } from 'ng2-charts';
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-mi-dashboard-customer',
  templateUrl: './mi-dashboard-customer.component.html',
  styleUrls: ['./mi-dashboard-customer.component.scss']
})
export class MiDashboardCustomerComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('lsoSort') lsoSort: MatSort;
  @ViewChild('dmSort') dmSort: MatSort;
  @ViewChild('bdmSort') bdmSort: MatSort;
  @ViewChild('dgsSort') dgsSort: MatSort;
  @ViewChildren (BaseChartDirective) charts: QueryList<BaseChartDirective>;
  public isLoading                  = 0;
  public form: UntypedFormGroup;
  public typeForm                   = new UntypedFormControl('lso');
  public status_ids                 = [];
  public dataSource: MatTableDataSource<any>;
  public dataSourceDgs: MatTableDataSource<any>;
  public dataSourceDmLso: MatTableDataSource<any>;
  public displayedColumns: string[] = ['name', 'type', 'case_count', 'impago_percent',
   'caso_congelado', 'documentacion_completa', 'quota_caso_congelado', 'quota_documentacion_completa'
  ];
  public displayedColumnsLso: string[] = ['name', 'type', 'case_count_lso', 'impago_percent_lso',
    'caso_congelado_lso', 'documentacion_completa_lso', 'quota_documentacion_completa'
  ];
  public displayedColumnsDm: string[] = ['name', 'type', 'case_count_dm', 'impago_percent_dm',
    'caso_congelado_dm', 'documentacion_completa_dm'
  ];
  public displayedColumnsBdm: string[] = ['name', 'type', 'case_count_bdm', 'impago_percent_bdm',
    'caso_congelado_bdm', 'documentacion_completa_bdm'
  ];
  public displayedColumnsDgs: string[] = ['name', 'type', 'case_count_dgs', 'impago_percent_dgs',
    'caso_congelado_dgs', 'documentacion_completa_dgs'
  ];
  public sortBy                     = 'id';
  public sortOrder                  = 'desc';

  //  Totals
  public totals = {
    caseCount: 0,
    caseCountLso: 0,
    caseCountDm: 0,
    caseCountBdm: 0,
    caseCountDgs: 0,
    impago: 0,
    impagoLso: 0,
    impagoDm: 0,
    impagoDgs: 0,
    casoCancelado: 0,
    casoCanceladoLso: 0,
    casoCanceladoDm: 0,
    casoCanceladoDgs: 0,
    casoCongelado: 0,
    casoCongeladoLso: 0,
    casoCongeladoDm: 0,
    casoCongeladoBdm: 0,
    casoCongeladoDgs: 0,
    documentacionCompleta: 0,
    documentacionCompletaLso: 0,
    documentacionCompletaDm: 0,
    documentacionCompletaBdm: 0,
    documentacionCompletaDgs: 0,
  };

  //  Graph
  public totalNumberOfCasoCongelado = 0;
  public totalNumberOfDocumentacionCompleta = 0;
  public monthlyQuotaCasoCongelado;
  public monthlyQuotaDocCompleta;
  public doughnutChartLabels: Label[] = ['Target', 'Caso Congelado Conversions', 'Sales goal'];
  public doughnutChartLabelsDM: Label[] = ['Target', 'No of Documentacion Completa', 'Sales goal'];
  public doughnutChartData: MultiDataSet = [
    [0, 0, 0],
    [0, 0, 0],
  ];
  public doughnutChartDataDM: MultiDataSet = [
    [0, 0, 0],
    [0, 0, 0],
  ];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions: ChartOptions = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (value, ctx) => {
          //  Remove '0' from labels
          return value === 0 ? null : value;
        }
      },
    }
  };
  public doughnutChartOptionsDM: ChartOptions = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (value, ctx) => {
          //  Remove '0' from labels
          return value === 0 ? null : value;
        }
      },
    }
  };
  public doughnutChartPlugins = [pluginDataLabels];
  public doughnutChartColors = [
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
  ];
  public doughnutChartColorsDM = [
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
  ];
  public daysInMonth = moment().daysInMonth();

  constructor(private fb: UntypedFormBuilder,
              private dashboardService: DashboardService
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.getData();

    this.form.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
      )
      .subscribe(res => {
        this.getData();
      });
  }

  buildForm() {
    this.form = this.fb.group({
      from     : [new Date()],
      to       : [new Date()],
      dateRadio: ['today'],
    });
  }

  onRadioChangeType($event) {
    this.typeForm.setValue($event.value);
    if ($event.value === 'lso') {
      this.dataSourceDmLso.sort = this.lsoSort;
    }
    if ($event.value === 'dm') {
      this.dataSourceDmLso.sort = this.dmSort;
    }
    if ($event.value === 'bdm') {
      this.dataSourceDmLso.sort = this.bdmSort;
    }
    if ($event.value === 'dgs') {
      this.dataSourceDgs.sort = this.dgsSort;
    }
  }

  getData() {
    this.isLoading++;
    const dateRange = {
      start_date: this.form.value.from ? this.form.value.from.toDateString() : '',
      end_date  : this.form.value.to ? this.form.value.to.toDateString() : '',
    };

    this.dataSource           = new MatTableDataSource([]);
    this.dataSourceDmLso      = new MatTableDataSource([]);
    this.dataSourceDgs        = new MatTableDataSource([]);
    this.dashboardService.indexCustomerCare(dateRange)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          const newFormat = [];
          res.data.data.map(obj => {
            //  Create 2 objects from 1
            //  Used for creating 2 table rows for one agent
            const dm  = {...obj};
            dm.type   = 'DM';
            const bdm  = {...obj};
            bdm.type   = 'BDM';
            const lso = {...obj};
            lso.type  = 'LSO';
            const dgs = {...obj};
            dgs.type  = 'DGS';

            newFormat.push(lso);
            newFormat.push(dm);
            newFormat.push(bdm);
            newFormat.push(dgs);
          });
          this.dataSource           = new MatTableDataSource(newFormat);
          this.dataSource.sort      = this.sort;

          this.dataSourceDmLso      = new MatTableDataSource(res.data.data);
          this.dataSourceDmLso.sort = this.lsoSort;
          this.dataSourceDgs      = new MatTableDataSource(newFormat);
          this.dataSourceDgs.sort = this.dgsSort;

          this.getTotalNumbers(res.data.data);
          //  Update chart with new values
          this.updateChartData(res.data);
        }, err => console.log(err)
      );
  }

  getTotalNumbers(data) {
    //  Reset totals
    this.totals = {
      caseCount               : 0,
      caseCountLso            : 0,
      caseCountDm             : 0,
      caseCountBdm            : 0,
      caseCountDgs            : 0,
      impago                  : 0,
      impagoLso               : 0,
      impagoDm                : 0,
      impagoDgs               : 0,
      casoCancelado           : 0,
      casoCanceladoLso        : 0,
      casoCanceladoDm         : 0,
      casoCanceladoDgs        : 0,
      casoCongelado           : 0,
      casoCongeladoLso        : 0,
      casoCongeladoDm         : 0,
      casoCongeladoBdm        : 0,
      casoCongeladoDgs        : 0,
      documentacionCompleta   : 0,
      documentacionCompletaLso: 0,
      documentacionCompletaDm : 0,
      documentacionCompletaBdm : 0,
      documentacionCompletaDgs : 0,
    };
    data.map(obj => {
      this.totals.caseCount += obj.case_count;
      this.totals.caseCountLso += obj.case_count_lso;
      this.totals.caseCountDm += obj.case_count_dm;
      this.totals.caseCountDgs += obj.case_count_dgs;
      this.totals.caseCountBdm += obj.case_count_bdm;
      this.totals.impago += obj.impago;
      this.totals.impagoLso += obj.impago_lso;
      this.totals.impagoDm += obj.impago_dgs;
      this.totals.impagoDgs += obj.impago_dgs;
      this.totals.casoCancelado += obj.caso_cancelado;
      this.totals.casoCanceladoLso += obj.caso_cancelado_lso;
      this.totals.casoCanceladoDm += obj.caso_cancelado_dm;
      this.totals.casoCanceladoDgs += obj.caso_cancelado_dgs;
      this.totals.casoCongelado += obj.caso_congelado;
      this.totals.casoCongeladoLso += obj.caso_congelado_lso;
      this.totals.casoCongeladoDm += obj.caso_congelado_dm;
      this.totals.casoCongeladoBdm += obj.caso_congelado_bdm;
      this.totals.casoCongeladoDgs += obj.caso_congelado_dgs;
      this.totals.documentacionCompleta += obj.documentacion_completa;
      this.totals.documentacionCompletaLso += obj.documentacion_completa_lso;
      this.totals.documentacionCompletaDm += obj.documentacion_completa_dm;
      this.totals.documentacionCompletaBdm += obj.documentacion_completa_bdm;
      this.totals.documentacionCompletaDgs += obj.documentacion_completa_dgs;
    });

  }

  updateChartData(values) {
    const {data, config} = values;
    data.map(agent => {
      this.totalNumberOfCasoCongelado += agent.caso_congelado;
      this.totalNumberOfDocumentacionCompleta += agent.documentacion_completa;
    });
    this.monthlyQuotaCasoCongelado = config.full_target_caso_congelado.value;
    this.monthlyQuotaDocCompleta = config.full_target_documentacion_completa.value;
    const currentDayInMonth = moment().date();
    const monthlyTargetCasoCongelado = config.full_target_caso_congelado.value;
    const monthlyTargetDocCompleta = config.full_target_documentacion_completa.value;
    const percentOfTotal = Math.round(currentDayInMonth / this.daysInMonth * 100);
    const dayTarget = (monthlyTargetCasoCongelado / 100) * percentOfTotal;
    const dayTargetDM = (monthlyTargetDocCompleta / 100) * percentOfTotal;
    const actualSales = this.totalNumberOfCasoCongelado;
    const actualSalesDM = this.totalNumberOfDocumentacionCompleta;
    //  Prepare LSO chart data
    this.doughnutChartData[0][0] = +dayTarget.toFixed(); //  update day target
    this.doughnutChartData[0][2] = +(monthlyTargetCasoCongelado - dayTarget).toFixed(); //  update sales goal
    this.doughnutChartData[1][1] = +actualSales.toFixed(); //  update actual sales
    //  Prepare DM chart data
    this.doughnutChartDataDM[0][0] = +dayTargetDM.toFixed(); //  update day target
    this.doughnutChartDataDM[0][2] = +(monthlyTargetDocCompleta - dayTargetDM).toFixed(); //  update sales goal
    this.doughnutChartDataDM[1][1] = +actualSalesDM.toFixed(); //  update actual sales

    // If sales are greater than monthly quota
    if (+(monthlyTargetCasoCongelado - actualSales).toFixed() < 0) {
      this.doughnutChartLabels = ['Target', 'Over Target', 'No. of sales', 'Sales goal'];
      this.doughnutChartColors = [
        {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
        {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
      ];

      this.doughnutChartOptions = {
        tooltips: {
          custom: (label) => {
            if (label.dataPoints && label.dataPoints[0]?.datasetIndex === 1 && label.dataPoints[0]?.index === 2) {
              return label.opacity = 0;
            }
            else {
              return label;
            }
          }
        },
        plugins : {
          datalabels: {
            color    : 'white',
            formatter: (value, ctx) => {
              if (ctx.dataset.label === 'No. of sales' && ctx.dataIndex === 2) {
                return null;
              }
              else {
                return value === 0 ? null : value;
              }
            }
          },
        }
      };

      this.doughnutChartData[1][3] = 0; //  update sales goal
      this.doughnutChartData[1][2] = +Math.abs((monthlyTargetCasoCongelado - (actualSales - monthlyTargetCasoCongelado))).toFixed();
      //  update actual sales
      this.doughnutChartData[1][1] = Math.abs(+(monthlyTargetCasoCongelado - actualSales).toFixed()); //  update over target
    } else {
      this.doughnutChartData[1][2] = +(monthlyTargetCasoCongelado - actualSales).toFixed(); //  update sales goal
    }

    if (+(monthlyTargetDocCompleta - actualSalesDM).toFixed() < 0) {
      this.doughnutChartLabelsDM = ['Target', 'Over Target', 'No. of sales', 'Sales goal'];
      this.doughnutChartColorsDM = [
        {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
        {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
      ];

      this.doughnutChartOptionsDM = {
        tooltips: {
          custom: (label) => {
            if (label.dataPoints && label.dataPoints[0]?.datasetIndex === 1 && label.dataPoints[0]?.index === 2) {
              return label.opacity = 0;
            } else {
              return label;
            }
          }
        },
        plugins: {
          datalabels: {
            color: 'white',
            formatter: (value, ctx) => {
              if (ctx.dataset.label === 'No. of sales' && ctx.dataIndex === 2) {
                return null;
              } else {
                return value === 0 ? null : value;
              }
            }
          },
        }
      };

      this.doughnutChartDataDM[1][3] = 0; //  update sales goal
      this.doughnutChartDataDM[1][2] = +Math.abs((monthlyTargetDocCompleta - (actualSalesDM - monthlyTargetDocCompleta))).toFixed();
      //  update actual sales
      this.doughnutChartDataDM[1][1] = Math.abs(+(monthlyTargetDocCompleta - actualSalesDM).toFixed()); //  update over target
    } else {
      this.doughnutChartDataDM[1][2] = +(monthlyTargetDocCompleta - actualSalesDM).toFixed(); //  update sales goal
    }
    // END If sales are greater than monthly quota

    //  Update LSO chart
    this.charts.first.chart.update();
    //  Update DM chart
    this.charts.last.chart.update();
  }

  getPercentDmLso(element, type) {
    if (type === 'congelado') {
      if (isFinite(element.caso_congelado / +element.target_caso_congelado)) {
        return ((element.caso_congelado / +element.target_caso_congelado) * 100).toFixed(2);
      } else {
        return 0;
      }
    } else  {
      if (isFinite(element.documentacion_completa / +element.target_documentacion_completa)) {
        return ((element.documentacion_completa / +element.target_documentacion_completa) * 100).toFixed(2);
      } else {
        return 0;
      }
    }
  }

}
