import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CreditorService} from '../../creditor.service';
import {finalize} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Creditor} from '../../../../../../../_base-shared/models/Entity/Creditor';

@Component({
  selector: 'app-creditor-reassign',
  templateUrl: './creditor-add-bankaccount.component.html'
})

export class CreditorBankAccountComponent implements OnInit {
    public isLoading = 0;
    public bankAccountForm: UntypedFormGroup;
  
    constructor(
      private creditorService: CreditorService,
      private formBuilder: UntypedFormBuilder,
      private toastr: ToastrService,
      public dialogRef: MatDialogRef<CreditorBankAccountComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
  
    ngOnInit(): void {
      this.buildForm();
    }

    buildForm() {
        this.bankAccountForm = this.formBuilder.group({
            iban       : [null, [Validators.required]],
            description: [null, [Validators.required]],
            default    : [null, []],
        })
    }

    saveBankAccount() {
        if (!this.bankAccountForm.value.default) {
            this.bankAccountForm.value.default = false;
        }

        this.creditorService.storeBankAccount(this.data.creditor.id, this.bankAccountForm.value)
            .pipe(finalize(() => this.isLoading--))
            .subscribe(() => {
                this.toastr.success('Bank account added successfully');
                this.dialogRef.close(true);
            }, (error) => {
                this.toastr.error(error.error.data.message);
            }
        );
    }
}