import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { DepartmentCategory } from '../../../../../../_base-shared/models/Department/DepartmentCategory';
import { NotificationChannel } from '../../../../../../_base-shared/models/Notification/NotificationChannel';
import { Task } from '../../../../../../_base-shared/models/Task/Task';
import { TaskTemplate } from '../../../../../../_base-shared/models/Task/TaskTemplate';
import { TemplateNotification } from '../../../../../../_base-shared/models/Task/TemplateNotification';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { PaymentStatusService } from '../../../../../../_base-shared/services/payment-status.service';
import { UploadService } from '../../../../../../_base-shared/services/upload.service';
import { CaseService } from '../../case/case.service';
import { StatusService } from '../../status/status.service';
import { DepartmentService } from '../../department/department.service';
import { NotificationService } from '../../notification/notification.service';
import { UserService } from '../../user/user.service';
import { TaskService } from '../task.service';

@Component({
  selector:    'app-task-editor',
  templateUrl: './task-editor.component.html',
  styles:      [],
})
export class TaskEditorComponent implements OnInit, OnDestroy {
  @Input() editorType: 'create' | 'edit';
  public task: Task;
  public case: Case;
  public taskTemplate: TaskTemplate;
  public quickTask: boolean;
  public form: UntypedFormGroup;
  public users: Array<User>;
  public departmentCategories: Array<DepartmentCategory>;
  public notificationChannels: Array<NotificationChannel>;
  public delayOptions: Array<{ label: any; value: string }>;
  public notifyOnOptions: Array<{ label: any; value: string }>;
  public isLoading                           = 0;
  public isSubmitting: boolean;
  public formSubmitted: boolean;
  public quillModules                        = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };
  private parseTaskContents                  = true;
  private subscriptions: Array<Subscription> = [];
  private authUser: User;

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              public translateService: TranslateService,
              @Optional() public dialogRef: MatDialogRef<TaskEditorComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private globalEventsService: MainGlobalEventService,
              private uploadService: UploadService,
              private taskService: TaskService,
              private statusService: StatusService,
              private paymentStatusService: PaymentStatusService,
              private userService: UserService,
              private caseService: CaseService,
              private departmentService: DepartmentService,
              private notificationService: NotificationService) {
  }

  get templateNotificationsArray() {
    return this.form.get('template_notifications') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.data      = this.data ? this.data : null;
    this.quickTask = !!(this.data && this.data.quickTask);
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (this.data) {
        this.case         = this.data.case;
        this.taskTemplate = this.data.taskTemplate;
        this.editorType   = this.data.editorType;
        if (this.editorType === 'edit') {
          this.task = this.data.task;
          this.loadMissingRelations(this.task, ['template_notifications']);
        } else {
          this.buildDefaultTask(new Task(), this.case, this.taskTemplate, this.data.prefill, this.data.quickTask);
        }
      } else {
        this.editorType = this.route.snapshot.data.editorType;
        this.route.paramMap.subscribe(params => this.fetchTask(+params.get('id')));
      }
    });

    this.buildDelayOptions();
    this.buildNotifyOnOptions();
    this.fetchAssignables();
    this.fetchNotificationChannels();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public addTemplateNotification($event) {
    $event.preventDefault();
    const formNotifications = this.form.get('template_notifications') as UntypedFormArray;
    formNotifications.push(this.initTemplateNotification(new TemplateNotification()));
  }

  public removeTemplateNotification(index: number) {
    this.templateNotificationsArray.removeAt(index);
  }

  public notifyOnChanged(index: number) {
    if (this.templateNotificationsArray.at(index).get('notify_on').value === 'assignment') {
      this.templateNotificationsArray.at(index).get('delay').setValue(0);
      this.templateNotificationsArray.at(index).get('delay_unit').setValue('second');
    }
    this.templateNotificationsArray.at(index).get('delay').updateValueAndValidity();
    this.templateNotificationsArray.at(index).get('delay_unit').updateValueAndValidity();
  }

  private buildForm(task: Task) {
    const authorId                = task.authorable_type === 'user' ? task.authorable_id : task.task_template.author_id;
    const noteRequirementEditable = this.editorType === 'create' ||
        (this.authUser.id === authorId || this.authUser.role_id === 5);
    this.form                     = this.fb.group({
      task_template_id:         [task.task_template_id],
      assigned_users_ids:       [
        task.assigned_users ? task.assigned_users.map(user => user.id) : null,
        !task.assigned_departments ? [Validators.required] : [],
      ],
      assigned_departments_ids: [
        task.assigned_departments ? task.assigned_departments.map(department => department.id) : null,
        !task.assigned_users ? [Validators.required] : [],
      ],
      name:                     [task.name, [Validators.required]],
      notes:                    [task.notes],
      require_completion_note:  [{value: task.require_completion_note, disabled: !noteRequirementEditable}],
      notify_on_completion_via: [task.notify_on_completion_via, []],
      due_date:                 [task.due_date ? new Date(task.due_date) : null, [Validators.required]],
      template_notifications:   this.fb.array([]),
    });

    const formNotifications = this.form.get('template_notifications') as UntypedFormArray;
    if (task.template_notifications && task.template_notifications.length) {
      task.template_notifications.forEach(templateNotification => {
        formNotifications.push(this.initTemplateNotification(templateNotification));
      });
    } else {
      const initialNotification      = new TemplateNotification();
      initialNotification.delay      = 10;
      initialNotification.delay_unit = 'minute';
      formNotifications.push(this.initTemplateNotification(initialNotification));
    }
  }

  private initTemplateNotification(templateNotification: TemplateNotification) {
    return this.fb.group({
      id:         [templateNotification.id ? templateNotification.id : null],
      channel:    [templateNotification.channel ? templateNotification.channel : 'toast', Validators.required],
      notify_on:  [
        templateNotification.notify_on ? templateNotification.notify_on : 'before_due_date',
        Validators.required,
      ],
      delay:      [templateNotification.delay, Validators.required],
      delay_unit: [templateNotification.delay_unit ? templateNotification.delay_unit : 'minute', Validators.required],
    });
  }

  public assignableTypeChanged(newAssignableType: 'user' | 'department') {
    this.form.get('assignable_id').setValue(null);
    this.form.get('assignable_id').updateValueAndValidity();
  }

  private fetchTask(taskId: number) {
    this.isLoading++;
    this.taskService.show(taskId).pipe(finalize(() => this.isLoading--)).subscribe(res => {
      this.task = res.data;
      this.case = this.task.case;
      this.buildForm(this.task);
    });
  }

  private fetchAssignables() {
    this.isLoading++;
    this.subscriptions.push(
        this.userService.index({is_staff: 1, select_all: 1}).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.users = result.data),
    );
    this.isLoading++;
    this.subscriptions.push(
        this.departmentService.categoryIndex(['departments']).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.departmentCategories = result.data),
    );
  }

  private fetchNotificationChannels() {
    this.isLoading++;
    this.subscriptions.push(
        this.notificationService.indexChannels().pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.notificationChannels = result.data),
    );
  }

  clearDatePicker($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.patchValue({due_date: ''});
  }

  public submitForm() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return false;
    }
    const formValue                   = this.form.value;
    formValue.require_completion_note = this.form.get('require_completion_note').value;

    this.isSubmitting = true;
    if (this.editorType === 'create') {
      this.taskService.storeCaseTask(this.data.case.id, formValue)
          .pipe(finalize(() => this.isSubmitting = false))
          .subscribe(value => {
            this.toastr.success(this.translateService.instant('TASK.editor.response.create.success'));
            this.dialogRef.close('reFetch');
          });
    } else {
      this.taskService.updateCaseTask(this.data.case.id, this.data.task.id, formValue)
          .pipe(finalize(() => this.isSubmitting = false))
          .subscribe(value => {
            this.toastr.success(this.translateService.instant('TASK.editor.response.edit.success'));
            this.dialogRef.close('reFetch');
          });
    }
  }

  private loadMissingRelations(task: Task, relations) {
    if (task.hasOwnProperty('template_notifications')) {
      return this.buildForm(task);
    }
    this.isLoading++;
    this.taskService.loadMissing(task.case_id, task.id, relations).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.task = result.data;
          this.buildForm(this.task);
        });
  }

  private buildDefaultTask(
      task: Task, clientCase: Case = null, taskTemplate: TaskTemplate = null, prefill: boolean = false,
      quickTask: boolean                                                                       = false,
  ) {
    if (prefill && clientCase && taskTemplate) {
      task.task_template            = taskTemplate;
      task.task_template_id         = taskTemplate.id;
      task.require_completion_note  = taskTemplate.require_completion_note;
      task.notify_on_completion_via = taskTemplate.notify_on_completion_via;
      task.due_date                 = moment().add(taskTemplate.delay, taskTemplate.delay_unit).toDate();
      if (quickTask) {
        task.assigned_users = [this.authUser];
      } else {
        task.assigned_users       = taskTemplate.assigned_users;
        task.assigned_departments = taskTemplate.assigned_departments;
      }

      if (this.parseTaskContents) {
        const unparsedTask = {name: taskTemplate.name, notes: taskTemplate.notes};
        return this.subscriptions.push(
            this.taskService.parseTaskVariables(clientCase.id, unparsedTask).pipe(finalize(() => this.isLoading--))
                .subscribe(result => {
                      const parsedTask = result.data;
                      task.name        = parsedTask.name;
                      task.notes       = parsedTask.notes;
                      this.buildForm(task);
                    },
                ),
        );
      }
      task.name  = taskTemplate.name;
      task.notes = taskTemplate.notes;
      return this.buildForm(task);
    } else {
      task.require_completion_note = false;
      task.task_template           = new TaskTemplate();
      this.buildForm(task);
    }
  }

  private uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public addVariable($event) {
    const content = this.form.get('notes').value || '';
    this.form.get('notes').setValue(content + $event.target.innerText + ' ');
  }

  public assignableChanged(assignableType: 'user' | 'department') {
    if (!this.form.get('assigned_users_ids').value || !this.form.get('assigned_users_ids').value.length) {
      this.form.get('assigned_departments_ids').setValidators([Validators.required]);
    } else {
      this.form.get('assigned_departments_ids').setValidators([]);
    }
    if (!this.form.get('assigned_departments_ids').value || !this.form.get('assigned_departments_ids').value.length) {
      this.form.get('assigned_users_ids').setValidators([Validators.required]);
    } else {
      this.form.get('assigned_users_ids').setValidators([]);
    }
    this.form.get('assigned_users_ids').updateValueAndValidity();
    this.form.get('assigned_departments_ids').updateValueAndValidity();
  }

  private buildDelayOptions() {
    this.delayOptions = [
      {
        value: 'minute',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.minute'),
      },
      {
        value: 'hour',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.hour'),
      },
      {
        value: 'day',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.day'),
      },
      {
        value: 'week',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.week'),
      },
      {
        value: 'month',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.month'),
      },
    ]
    ;
  }

  private buildNotifyOnOptions() {
    this.notifyOnOptions = [
      {
        value: 'assignment',
        label: this.translateService.instant(
            'TASK-TEMPLATE.editor.task-template-notification.notify_on.options.assignment',
        ),
      },
      {
        value: 'before_due_date',
        label: this.translateService.instant(
            'TASK-TEMPLATE.editor.task-template-notification.notify_on.options.before_due_date',
        ),
      },
      {
        value: 'after_due_date',
        label: this.translateService.instant(
            'TASK-TEMPLATE.editor.task-template-notification.notify_on.options.after_due_date',
        ),
      },
    ];
  }

}
