import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse, LaravelResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Status } from '../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../_base-shared/models/Status/StatusCategory';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class StatusService extends MainBaseApiService {

  index(data = {}): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/statuses`, {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }

  get(statusId: number): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/statuses/${ statusId }`)
        .pipe(catchError(response => this.handleError(response)));
  }

  indexCategoriesWithStatuses() {
    return this.http.get<LaravelResponse<Array<StatusCategory>>>(
        this.apiUrl + '/statuses/by-parent', {params: {all: 1, select_all: 1} as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  store(status: Status) {
    return this.http.post<LaravelResourceResponse<Status>>(`${ this.apiUrl }/statuses`, status)
        .pipe(catchError(response => this.handleError(response)));
  }

  update(statusId: number, status: Status) {
    return this.http.put<LaravelResourceResponse<Status>>(`${ this.apiUrl }/statuses/${ statusId }`, status)
        .pipe(catchError(response => this.handleError(response)));
  }

  delete(statusId: number): Observable<LaravelResponse> {
    return this.http.delete<LaravelResponse>(`${ this.apiUrl }/statuses/${ statusId }`)
        .pipe(catchError(response => this.handleError(response)));
  }

}
