<div class="d-flex blue-box">
  <div class="img-wrapper">
    <img src="/assets/img/LogomarkArtboard 3.png" alt="">
  </div>
</div>
<div class="container" style="padding-top: 100px">
  <h2 *ngIf="responseSuccess && paymentInfo"
      [innerHTML]="'CLIENT.payment.payment-info.success.title' | translate: {
      firstName: paymentInfo.first_name ? paymentInfo.first_name : ''}">
  </h2>
  <h2 *ngIf="!responseSuccess && paymentInfo"
      [innerHTML]="'CLIENT.payment.payment-info.error.title' | translate: {
      firstName: paymentInfo.first_name ? paymentInfo.first_name : ''}">
  </h2>

  <div class="row" *ngIf="!responseSuccess && paymentInfo">
    <div class="col text-center">
      <a mat-raised-button color="primary" [routerLink]="['/client', 'payments', 'confirm']"
         [queryParams]="{payment_request_uuid: paymentInfo.payment_request_uuid, signature: paymentInfo.retry_signature}">
        Retry
      </a>
    </div>
  </div>

  <div class="row" *ngIf="paymentInfo">
    <div class="col-6">
      <p *ngIf="responseSuccess">{{ 'CLIENT.payment.payment-info.success.transaction_paid_amount' | translate }}:
        {{ paymentInfo.transaction_paid_amount | currency: 'EUR' }}
      </p>
      <p>{{ 'CLIENT.payment.payment-info.shared.total_paid' | translate }}:
        {{ paymentInfo.total_paid | currency: 'EUR' }}
      </p>
      <p>{{ 'CLIENT.payment.payment-info.shared.total_remaining' | translate }}:
        {{ paymentInfo.total_remaining | currency: 'EUR' }}
      </p>
    </div>
    <div class="col-6 text-right">
      <p *ngIf="responseSuccess">{{ 'CLIENT.payment.payment-info.success.transaction_payment_date' | translate }}:
        {{ paymentInfo.transaction_payment_date | date }}
      </p>
      <p>{{ 'CLIENT.payment.payment-info.shared.ref_number' | translate }}:
        {{ paymentInfo.ref_number }}
      </p>
    </div>
  </div>
</div>

<div class="text-center" style="padding-top: 100px">
  <img style="width: 150px;" class="img-fluid" src="assets/img/logos/deuda-logo.png" alt="Logo">
</div>
