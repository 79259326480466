<div class="container-fluid">
  <h2 *ngIf="userFirstName">{{ "SHARED.hello" | translate }} {{userFirstName}}
    , {{ "DOCUMENTS.upload-note" | translate }}:</h2>
  <h2 *ngIf="!userFirstName">{{ "SHARED.hello" | translate }}, {{ "DOCUMENTS.upload-note" | translate }}:</h2>
  <mat-accordion multi>
    <ng-container *ngFor="let file of requiredFilesArray; let i=index">
      <mat-expansion-panel class="{{file.hasClass}}">
        <mat-expansion-panel-header class="custom-upload-header">
          <mat-panel-title *ngIf="!file.custom">
            {{"CASES.single.document-types." + file.name + '-short' | translate}}
          </mat-panel-title>
          <mat-panel-title *ngIf="file.custom">
            {{file.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-upload-document-type
                [type]="file.name"
                [uploader]="uploader"
                [uuId]="uuId"
                [files]="allFiles"
        ></app-upload-document-type>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>

<div class="container-fluid creditor-documents pt-4"
     *ngIf="creditorsRequiredFilesArray.length && !dniNotApproved.length">
  <h2>{{ 'CASES.single.creditors.heading' | translate }} {{ 'CASES.single.documents.heading' | translate }}</h2>
  <mat-accordion multi>
    <ng-container *ngFor="let file of creditorsRequiredFilesArray; let i=index">
      <mat-expansion-panel class="{{file.hasClass}}">
        <mat-expansion-panel-header class="custom-upload-header">
          <mat-panel-title *ngIf="!file.public_organisation">
            {{formatFileType(file.name)}} Deuda
          </mat-panel-title>
          <mat-panel-title *ngIf="file.public_organisation">
            {{formatFileType(file.public_organisation)}} Deuda
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-upload-document-type
                [type]="file.name"
                [file]="file"
                [uploader]="uploader"
                [uuId]="uuId"
                [files]="allFiles"
                [creditors]="true"
        ></app-upload-document-type>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>

<div class="container-fluid creditor-documents pt-4" *ngIf="bankRequiredFilesArray.length && !dniNotApproved.length">
  <h2>{{ 'CASES.editor.assets.table.bank_accounts' | translate }} {{"CASES.single.documents.heading" | translate}}</h2>
  <mat-accordion multi>
    <ng-container *ngFor="let file of bankRequiredFilesArray; let i=index">
      <mat-expansion-panel class="{{file.hasClass}}">
        <mat-expansion-panel-header class="custom-upload-header">
          <mat-panel-title>
            {{file.entity.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-upload-document-type
                [type]="file.name"
                [file]="file"
                [uploader]="uploader"
                [uuId]="uuId"
                [files]="allFiles"
                [banks]="true"
        ></app-upload-document-type>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>

<div *ngIf="isLoadingUploaded" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<div *ngIf="!isLoadingUploaded" class="row pt-5 pb-5">
  <div class="col-12">
    <div class="card shadow">
      <div class="card-header">
        <h2>{{ "DOCUMENTS.uploaded-documents" | translate }}</h2>
      </div>
      <div class="card-body">
        <!--    Show uploaded personal files    -->
        <h2 class="pt-3">{{"CASES.single.personal-documents" | translate}}</h2>
        <mat-accordion multi>
          <mat-expansion-panel
                  class="{{getDocumentStatus(instance.value)}}"
                  style="transition: none"
                  *ngFor="let instance of personalFilesFiltered | keyvalue; let i = index"
          >
            <mat-expansion-panel-header>
              <mat-panel-title *ngIf="!instance.value[0]?.type?.custom">
                {{"CASES.single.document-types." + instance.key + '-short' | translate}}
              </mat-panel-title>
              <mat-panel-title *ngIf="instance.value[0]?.type?.custom">
                {{instance.key}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="pt-lg-4">
              <div class="row align-items-center" *ngFor="let document of instance.value" style="padding-bottom: 15px">
                <div class="col-md-12">
                  <ng-container>
                    <a target="_blank" href="{{storageUrl + document.location}}">{{document.name}}</a>
                    <label class="pl-1">({{"CASES.single." + document.status | translate}})</label>
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <!--    Show creditor uploaded files    -->
        <div *ngIf="!dniNotApproved.length">
          <h2 class="pt-5">{{ 'CASES.single.creditors.heading' | translate }} {{ 'CASES.single.documents.heading' | translate }}</h2>
          <h3 *ngIf="creditorFilesFiltered?.length === 0"
              class="text-center user-document-list">{{ "CASES.single.no-files-uploaded" | translate }}</h3>
          <mat-accordion multi>
            <mat-expansion-panel
                    class="{{getDocumentStatus(instance.value)}}"
                    style="transition: none"
                    *ngFor="let instance of creditorFilesFiltered | keyvalue"
            >
              <mat-expansion-panel-header>
                <mat-panel-title style="text-transform: capitalize">
                  {{formatFileType(instance.key)}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="pt-lg-4">
                <div class="row align-items-center" *ngFor="let document of instance.value"
                     style="padding-bottom: 15px">
                  <div class="col-md-12">
                    <ng-container>
                      <a target="_blank" href="{{storageUrl + document.location}}">{{document.name}}</a>
                      <label class="pl-1">({{"CASE_CREDITOR.model.public_organization.options." +
                      document.status | translate}})</label>
                    </ng-container>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="!dniNotApproved.length">
          <h2 class="pt-5">{{ 'CASES.editor.assets.table.bank_accounts' | translate }} {{"CASES.single.documents.heading" | translate}}</h2>
          <h3 *ngIf="bankFilesFiltered?.length === 0"
              class="text-center user-document-list">{{ "CASES.single.no-files-uploaded" | translate }}</h3>
          <mat-accordion multi>
            <mat-expansion-panel
                    class="{{getDocumentStatus(instance.value)}}"
                    style="transition: none"
                    *ngFor="let instance of bankFilesFiltered | keyvalue"
            >
              <mat-expansion-panel-header>
                <mat-panel-title style="text-transform: capitalize">
                  {{formatFileType(instance.key)}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="pt-lg-4">
                <div class="row align-items-center" *ngFor="let document of instance.value"
                     style="padding-bottom: 15px">
                  <div class="col-md-12">
                    <ng-container>
                      <a target="_blank" href="{{storageUrl + document.location}}">{{document.name}}</a>
                      <label class="pl-1">({{"CASES.single." + document.status | translate}})</label>
                    </ng-container>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>

