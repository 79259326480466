import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import { ClientService } from '../../../client.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector:    'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls:   ['./edit-modal.component.scss']
})
export class EditModalComponent implements OnInit {
  public form: UntypedFormControl;

  constructor(public dialogRef: MatDialogRef<EditModalComponent>,
              private clientService: ClientService,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormControl(this.data.control.value);
  }

  closeModal() {
    this.dialogRef.close();
  }

  submit() {
    const data = {
      [this.data.name]: this.form.value
    };

    this.clientService.updateUser(this.data.primaryClient.uuid, data).subscribe(
      result => {
        this.toastr.success('Updated');
        this.dialogRef.close(result.data);
      },
    );
  }

}
