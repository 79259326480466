import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../_base-shared/contracts/common.interface';
import { AffiliateCaseListFilter } from '../../../../../_base-shared/models/Case/AffiliateCaseListFilter';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import {Affiliate} from '../../../../../_base-shared/models/Affiliate';

@Component({
  selector:    'app-affiliate-case-list-filters',
  templateUrl: './affiliate-case-list-filters.component.html',
  styleUrls:   ['./affiliate-case-list-filters.component.scss'],
})
export class AffiliateCaseListFiltersComponent implements OnInit, OnDestroy {
  @Output() filtersReady  = new EventEmitter<boolean>();
  @Output() submitFilters = new EventEmitter<AffiliateCaseListFilter>();
  @Input() affiliates: Array<Affiliate>;
  @Input() affiliateId: Array<number>;

  public isLoading                     = 0;
  public form: UntypedFormGroup;
  public dateRadioControl: UntypedFormControl = new UntypedFormControl();

  public affiliateCaseListFilter: AffiliateCaseListFilter;
  public conversionStatusOptions: Array<AppSelectOption> = [];

  private subscriptions: Array<Subscription> = [];
  private formChangeSubscriber: Subscription;
  private localStorageName                   = 'affiliate-case-list-filters';

  constructor(private fb: UntypedFormBuilder,
              private globalEventService: MainGlobalEventService,
  ) {
  }

  ngOnInit(): void {
    this.affiliateCaseListFilter = this.getFiltersFromStorage();
    this.buildForm(this.affiliateCaseListFilter);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.formChangeSubscriber.unsubscribe();
  }

  public dateChanged($event: MatDatepickerInputEvent<unknown>, formControlName, endDay = false) {
    if (endDay) {
      const date = moment($event.value).endOf('day');
      this.form.get(formControlName).patchValue(date.toDate());
    }
  }

  public clearDates() {
    this.form.patchValue({
      start_date: null,
      end_date:   null,
    });
  }

  private getFiltersFromStorage(): AffiliateCaseListFilter {
    const filter = new AffiliateCaseListFilter();
    let data     = JSON.parse(localStorage.getItem(this.localStorageName));
    data         = data ? data : {};

    filter.search            = data.search ? data.search : null;
    filter.start_date        = data.start_date ? new Date(data.start_date) : null;
    filter.end_date          = data.end_date ? new Date(data.end_date) : null;
    filter.status_date_type  = data.status_date_type ? data.status_date_type : 'sign_up';
    filter.conversion_status = data.conversion_status ? data.conversion_status : 'all';
    filter.affiliate_ids     = data.affiliate_ids ? data.affiliate_ids : this.affiliateId;
    return filter;
  }

  private buildForm(affiliateCaseListFilter: AffiliateCaseListFilter): void {
    this.form = this.fb.group({
      search:            [affiliateCaseListFilter.search],
      start_date:        [affiliateCaseListFilter.start_date],
      end_date:          [affiliateCaseListFilter.end_date],
      status_date_type:  [affiliateCaseListFilter.status_date_type],
      conversion_status: [affiliateCaseListFilter.conversion_status],
      affiliate_ids:     [affiliateCaseListFilter.affiliate_ids ? affiliateCaseListFilter.affiliate_ids : this.affiliateId],
    });
    this.buildConversionStatusOptions(affiliateCaseListFilter.status_date_type);
    this.filtersReady.emit(true);
    this.submitFilters.emit(this.affiliateCaseListFilter);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    this.formChangeSubscriber = this.form.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
    ).subscribe(res => {
      if (this.form.invalid) {
        return;
      }

      this.affiliateCaseListFilter = this.form.value;
      this.submitFilters.emit(this.affiliateCaseListFilter);
    });
  }

  public clearFormControl($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }

  private buildConversionStatusOptions(dateType: 'sign_up' | 'conversion_date') {
    const selectOptions = [];

    if (dateType === 'sign_up') {
      selectOptions.push({label: 'All', value: 'all'});
    }

    selectOptions.push({label: 'Converted - Active', value: 'converted_active'});
    selectOptions.push({label: 'Converted - Inactive', value: 'converted_inactive'});
    selectOptions.push({label: 'Converted - All', value: 'converted_all'});

    if (dateType === 'sign_up') {
      selectOptions.push({label: 'Unconverted', value: 'unconverted'});
    }

    this.conversionStatusOptions = selectOptions;
  }

  public datePickTypeUpdated($event: any) {
    this.buildConversionStatusOptions($event);
  }
}
