import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import {
  BasePaymentHandlerComponent
} from '../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ScriptLoaderService } from '../../../../../../_base-shared/services/script-loader.service';
import { ClientService } from '../../client.service';
import { User } from '../../../../../../_base-shared/models/User/User';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { environment } from '../../../../environments/environment';
import { PaymentCard } from '../../../../../../_base-shared/models/Payment/PaymentCard';

@Component({
  selector:    'app-client-payment-handler',
  templateUrl: '../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component.html',
  styleUrls:   [
    '../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component.scss',
    './client-payment-handler.component.scss'
  ]
})
export class ClientPaymentHandlerComponent extends BasePaymentHandlerComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected fb: UntypedFormBuilder,
              protected dialog: MatDialog,
              protected breakpointObserver: BreakpointObserver,
              protected translate: TranslateService,
              protected toastr: ToastrService,
              protected scriptLoader: ScriptLoaderService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              protected globalEventsService: MainGlobalEventService,
              protected clientAppService: ClientService,
  ) {
    super(route, router, fb, dialog, breakpointObserver, translate, toastr, scriptLoader, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.editableAmount    = false;
    this.redirectNewTab    = false;
    this.autoRedirect      = true;
    this.redirectSuccess   = '/client/payments/outcome/success';
    this.redirectError     = '/client/payments/outcome/error';
    this.redirectCancelled = '/client/payments/outcome/error';

    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser  = user;
      const caseUuid = this.route.snapshot.paramMap.get('caseUuid');
      this.fetchCase(this.authUser, caseUuid);
    });
  }

  public submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.submitFormToApi(this.authUser.uuid, this.case.uuid, this.form.value);
  }

  protected submitFormToApi(userUuid: string, caseUuid: string, formValue): void {
    super.makePayment(this.clientAppService.makePayment(userUuid, caseUuid, formValue));
  }

  protected fetchCase(client: User, caseUuid: string): void {
    const loadRelations = [
      'client.address',
      'partner.address',
    ];
    this.isLoading++;
    this.clientAppService.showUserCase(client.uuid, caseUuid, loadRelations)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.case         = result.data;
        this.clientRole   = this.case.partner_user_id === this.authUser.id ? 'partner' : 'client';
        this.targetClient = this.case.partner_user_id === this.authUser.id ? this.case.partner : this.case.client;
        this.globalEventsService.setClientSelectedCase(this.case);
        this.clientAppService.paymentAmount(client.uuid, this.case.uuid).subscribe(
          res => {
            this.paymentPlanType = res.data.plan_type;
            this.installment     = res.data.installment;
            let chargeAmount     = 0;
            if (this.installment) {
              chargeAmount = this.installment.amount - this.installment.amount_paid;
              this.dueDate = new Date(this.installment.term_date);
            }
            this.amountToCharge = chargeAmount;
            this.fetchPaymentCards(client, this.case, this.amountToCharge, this.amountToCharge, false);
          });
      });
  }

  protected fetchPaymentCards(client: User, clientCase: Case, amount: number, maxPayableAmount: number, isMotoPayment: boolean): void {
    this.isLoading++;
    this.clientAppService.indexPaymentCards(client.uuid, clientCase.uuid).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.paymentCards           = this.mapPaymentCards(result.data);
        const redirectSuccessFull   = environment.APP_URL + this.redirectSuccess;
        const redirectErrorFull     = environment.APP_URL + this.redirectError;
        const redirectCancelledFull = environment.APP_URL + this.redirectCancelled;

        this.buildForm(client, amount, maxPayableAmount, isMotoPayment, redirectSuccessFull, redirectErrorFull, redirectCancelledFull);
      });
  }

  public makeDefault(paymentCard: PaymentCard): void {
    super.baseMakeDefault(paymentCard, this.clientAppService.setDefaultPaymentCard(this.case.uuid, paymentCard.uuid));
  }

  public deleteCard(paymentCard: PaymentCard): void {
    super.baseDeleteCard(paymentCard, this.clientAppService.deletePaymentCardPublic(this.case.uuid, paymentCard.uuid));
  }
}
