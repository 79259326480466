import { Injectable } from '@angular/core';
import { MainBaseApiService } from '../_shared/services/main-base-api.service';
import {LaravelResponse} from '../../../../_base-shared/contracts/laravel-response.interface';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AffiliateUserService extends MainBaseApiService {
  public storeLead(data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${this.apiUrl}/affiliate-cases`, data)
        .pipe(catchError(response => this.handleError(response)));
  }
}
