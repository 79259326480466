import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ClientService } from '../../client.service';
import { finalize } from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-expense-step',
  templateUrl: './expense-step.component.html',
  styleUrls: ['./expense-step.component.scss']
})
export class ExpenseStepComponent implements OnInit {
  @Input() caseUuid: string;
  @Input() userUuid: string;
  @Input() isReadOnly: boolean;
  @Input() isDesktop: boolean;
  public form: UntypedFormGroup;
  public isLoading = 0;

  public essentialArrayFilter = ['essential_rent', 'essential_local_tax', 'essential_mortgage', 'life_insurance',
    'pension_plan', 'ibi', 'home_insurance', 'school_expenses', 'essential_other'];
  public essentialArray = [];
  public essentialTotal = 0;

  public livingExpensesFilter = ['essential_gas', 'essential_electricity', 'essential_water'];
  public livingExpensesArray = [];
  public livingExpensesTotal = 0;

  public phoneExpensesFilter = ['phone_home', 'phone_mobile'];
  public phoneExpensesArray = [];
  public phoneExpensesTotal = 0;

  public travelExpensesFilter = ['essential_hire_purchase', 'travel_public_transport', 'travel_car_insurance', 'travel_vehicle_tax',
    'travel_fuel', 'itv'];
  public travelExpensesArray = [];
  public travelExpensesTotal = 0;

  public housekeepingFilter = ['housekeeping_food_and_groceries', 'housekeeping_cleaning', 'housekeeping_newspapers',
    'housekeeping_alcohol', 'housekeeping_clothing_footwear', 'housekeeping_laundry'];
  public housekeepingArray = [];
  public housekeepingTotal = 0;

  public otherFilter = ['other_hobbies_leisure_sport', 'other_other', 'medications', 'medical_expenses', 'pet_expenses',
    'cosmetics_and_beauty', 'legal_fees'];
  public otherArray = [];
  public otherTotal = 0;

  public specialExpenses = [];
  public specialExpensesTotal = 0;

  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.isLoading++;
    this.clientService.indexCaseRelation(this.caseUuid, this.userUuid, 'expense')
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        result.data.forEach(expense => {
          //  Filter essential expenses
          if (this.essentialArrayFilter.indexOf(expense.name) !== -1) {
            this.essentialArray.push({name: expense.name, value: expense.value ? expense.value : 0});
            this.essentialTotal += expense.value || 0;
          }
          //  Filter living expenses
          if (this.livingExpensesFilter.indexOf(expense.name) !== -1) {
            this.livingExpensesArray.push({name: expense.name, value: expense.value ? expense.value : 0});
            this.livingExpensesTotal += expense.value || 0;
          }
          //  Filter phone and internet expenses
          if (this.phoneExpensesFilter.indexOf(expense.name) !== -1) {
            this.phoneExpensesArray.push({name: expense.name, value: expense.value ? expense.value : 0});
            this.phoneExpensesTotal += expense.value || 0;
          }
          //  Filter travel expenses
          if (this.travelExpensesFilter.indexOf(expense.name) !== -1) {
            this.travelExpensesArray.push({name: expense.name, value: expense.value ? expense.value : 0});
            this.travelExpensesTotal += expense.value || 0;
          }
          //  Filter housekeeping expenses
          if (this.housekeepingFilter.indexOf(expense.name) !== -1) {
            this.housekeepingArray.push({name: expense.name, value: expense.value ? expense.value : 0});
            this.housekeepingTotal += expense.value || 0;
          }
          //  Filter other expenses
          if (this.otherFilter.indexOf(expense.name) !== -1) {
            this.otherArray.push({name: expense.name, value: expense.value ? expense.value : 0});
            this.otherTotal += expense.value || 0;
          }
        });
        this.specialExpenses = result.data.filter(res => res.name === 'special_expenses')[0].value;

        if (this.specialExpenses) {
          this.specialExpenses.forEach(specialExpense => {
            this.specialExpensesTotal += +specialExpense.value;
          });
        }

        this.isLoading++;
        this.clientService.indexCaseExpenseValidation(this.caseUuid, this.userUuid)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(expenseResult => {
            this.buildForm(expenseResult.data);
          });
      });
  }

  private buildForm(validatableExpense) {
    const expenses = this.essentialArray.concat(this.livingExpensesArray, this.phoneExpensesArray, this.travelExpensesArray,
      this.housekeepingArray, this.otherArray
    );
    const group = {};

    expenses.forEach(expense => {
      group[expense.name] = new UntypedFormControl(validatableExpense ? validatableExpense[expense.name] : true);
    });
    // @ts-ignore
    group.other_expenses = new UntypedFormControl(true);
    // @ts-ignore
    group.special_expenses = new UntypedFormControl(true);

    this.form = new UntypedFormGroup(group);
  }

  public getData() {
    const expenses = this.form.value;
    const otherExpenses = expenses.other_expenses;
    delete expenses.other_expenses;

    return {
      expense: expenses,
      other_expenses: otherExpenses,
      relation: 'expense'
    };
  }

}
