import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailListComponent } from './email-list/email-list.component';
import { EmailEditorComponent } from './email-editor/email-editor.component';
import { PermissionGuard } from '../../../../../../_base-shared/guards/permission.guard';


const routes: Routes = [
  {
    path: 'email',
    children: [
      {path: '', canActivate: [PermissionGuard], component: EmailListComponent},
      {path: 'create', canActivate: [PermissionGuard], component: EmailEditorComponent, data: {editor: 'create'}},
      {path: ':id/edit', canActivate: [PermissionGuard], component: EmailEditorComponent, data: {editor: 'edit'}},
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class EmailRoutingModule {
}
