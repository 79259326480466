import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  LaravelResourceResponse,
  LaravelResponse
} from '../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { DebtCancellationModel } from '../../../../../_base-shared/models/Document/DebtCancellationModel';

@Injectable({
  providedIn: 'root'
})
export class CaseDocumentService extends MainBaseApiService {
  public indexCaseDocuments(caseId: number) {
    return this.http.get<LaravelResponse>(this.apiUrl + '/cases/' + caseId + '/case-documents')
      .pipe(catchError(response => this.handleError(response)));
  }

  public index(caseId: number, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResponse>(this.apiUrl + '/cases/' + caseId + '/documents', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public showPropuestoModelo(caseId: number) {
    return this.http.get<LaravelResponse>(this.apiUrl + '/cases/' + caseId + '/propuesto-modelo')
      .pipe(catchError(response => this.handleError(response)));
  }

  changeStatus(data): Observable<LaravelResponse> {
    return this.http.patch<LaravelResponse>(`${ this.apiUrl }/cases/${ data.caseId }/documents/${ data.fileId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public setDocAsCompleted(data, caseId): Observable<LaravelResponse> {
    return this.http.patch<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/document-requests`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  // changeType(data): Observable<LaravelResponse> {
  //   return this.http.patch<LaravelResponse>(`${this.apiUrl}/cases/${data.caseId}/documents/${data.fileId}`, data);
  // }

  request(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/document-requests`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  resendRequest(data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(
      `${ this.apiUrl }/cases/${ data.caseId }/document-requests/${ data.fileId }/resend-request`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFiles(uuId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ uuId }/documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFilesLegal(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFilesCourt(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/court-documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFilesBank(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  changeStatusLegal(caseId, fileId, data): Observable<LaravelResponse> {
    return this.http.patch<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents-upload/${ fileId }`,
      data).pipe(catchError(response => this.handleError(response)));
  }

  changeStatusCourt(caseId, fileId, data): Observable<LaravelResponse> {
    return this.http.patch<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/court-documents-upload/${ fileId }`,
      data).pipe(catchError(response => this.handleError(response)));
  }

  removeUploadedFileLegal(caseId, fileId): Observable<LaravelResponse> {
    return this.http.delete<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents-upload/${ fileId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  removeUploadedFileCourt(caseId, fileId): Observable<LaravelResponse> {
    return this.http.delete<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/court-documents-upload/${ fileId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  removeUploadedFile(uuId, fileId): Observable<LaravelResponse> {
    return this.http.delete<LaravelResponse>(`${ this.apiUrl }/cases/${ uuId }/documents-upload/${ fileId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getRequiredFiles(uuId): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${ this.apiUrl }/cases/${ uuId }/document-requests`)
      .pipe(catchError(response => this.handleError(response)));
  }

  adminUploadFiles(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/documents`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  //  todo: add route here
  adminUploadContract(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/contract-mandates-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  //  todo: add route here
  regenerateContract(caseId, contractId) {
    return this.http.get<LaravelResourceResponse>(
      `${ this.apiUrl }/cases/${ caseId }/contracts/${ contractId }/regenerate`)
      .pipe(catchError(response => this.handleError(response)));
  }

  resignContract(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/send-contract/resign`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  mergeDocuments(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/documents/merge`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  mergeLegalDocuments(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents/merge`, data);
  }

  mergeCourtDocuments(caseId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseId }/court-documents/merge`, data);
  }

  generateDocument(caseId: number, data) {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-documents/generate', data, {
      observe: 'response', responseType: 'blob'
    }).pipe(catchError(response => this.handleError(response)));
  }

  setDocumentVisibility(caseId: number, data) {
    return this.http.patch<LaravelResponse>(this.apiUrl + '/cases/' + caseId + '/case-documents/documents-visibility',
      data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateRightOfAccessLetter(userRole: string, caseCreditorId: number) {
    return this.http.post(this.apiUrl + '/case-creditors/' + caseCreditorId + '/download-right-of-access',
      {client_role: userRole}, {
        observe: 'response', responseType: 'blob'
      });
  }

  sendAutoDeConcurso(caseUuid, clientRole: 'client' | 'partner') {
    return this.http.post<LaravelResponse>(`${ this.apiUrl }/cases/${ caseUuid }/legal-documents/auto-de-concurso`,
      {client_role: clientRole})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendSignedContract(caseId: number) {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/send-last-signed-contract`, {})
      .pipe(catchError(response => this.handleError(response)));
  }

  resendAdvicePack(caseId, regenerate: boolean) {
    return this.http.post<LaravelResponse>(
      `${ this.apiUrl }/cases/${ caseId }/regenerate-advice-pack`,
      {regenerate})
      .pipe(catchError(response => this.handleError(response)));
  }

  requestDebtCancellationRequestSignature(caseId: number) {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/debt-cancellation-request/request-signature', {})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendExperianEmail(caseId) {
    return this.http.post<LaravelResourceResponse>(
      `${ this.apiUrl }/cases/${ caseId }/send-experian-cancellation-request`, {})
      .pipe(catchError(response => this.handleError(response)));
  }

  public documentsCount(caseUuid: string, userUuid: string) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/uploaded-documents-count')
      .pipe(catchError(response => this.handleError(response)));
  }

  public getIsExperianReady(caseId: number) {
    return this.http.get<LaravelResourceResponse<DebtCancellationModel>>(
      this.apiUrl + '/cases/' + caseId + '/debt-cancellation-request-status',
    ).pipe(catchError(response => this.handleError(response)));
  }
}
