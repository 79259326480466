import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as cardValidation from 'creditcards';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { PaymentCard } from '../../../../../../../_base-shared/models/Payment/PaymentCard';
import { PaymentTerm } from '../../../../../../../_base-shared/models/Payment/PaymentTerm';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { ClientService } from '../../../client.service';
import { PaymentRequest } from '../../../../../../../_base-shared/models/Payment/PaymentRequest';
import { CustomValidators } from '../../../../../../../_base-shared/validators/custom.validators';

@Component({
  selector:    'app-public-payment-handler-wrapper',
  templateUrl: './public-payment-handler-wrapper.component.html',
  styleUrls:   ['./public-payment-handler-wrapper.component.scss']
})
export class PublicPaymentHandlerWrapperComponent implements OnInit {
  public isLoading = 0;
  public paymentRequest: PaymentRequest;
  public case: Case;
  public targetClient: User;
  public clientRole: 'client' | 'partner';
  public chargeAmount: number;
  public paymentCards: Array<PaymentCard>;
  public paymentPlanType: 'debt_plan' | 'additional_plans';
  public installment: PaymentTerm;

  public idCardForm: UntypedFormGroup;
  public submittingIdCard: boolean;
  public resolvingIdCardForm = 0;

  public paymentFormActive = false;

  public isSuccessful: boolean;
  public paymentResponse: string;

  private requestSignature: string;

  public desktopVersion: boolean;
  public errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private breakpointObserver: BreakpointObserver,
    private globalEventService: MainGlobalEventService,
    private clientAppService: ClientService,
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });

    const paymentRequestUuid = this.route.snapshot.queryParamMap?.get('payment_request');
    this.requestSignature    = decodeURI(this.route.snapshot.queryParamMap?.get('signature'));

    const caseUuid             = this.route.snapshot.queryParamMap?.get('uuid');
    const requestChargeAmounts = this.route.snapshot.queryParamMap?.get('amount');
    this.paymentPlanType       = this.route.snapshot.queryParamMap?.get('plan_type') === 'additional_plans' ?
      'additional_plans' :
      'debt_plan';

    if ( ! paymentRequestUuid && ! requestChargeAmounts) {
      this.errorMessage = 'Invalid form';

      return;
    }

    if (paymentRequestUuid) {
      this.isLoading++;
      this.clientAppService.getPaymentRequest(paymentRequestUuid, this.requestSignature, ['case.product', 'case.client'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.paymentRequest  = result.data;
          this.case            = this.paymentRequest.case;
          this.targetClient    = this.case.client;
          this.clientRole      = 'client';
          this.chargeAmount    = +this.paymentRequest.amount;
          this.paymentPlanType = this.paymentRequest.charge_additional_plan ? 'additional_plans' : 'debt_plan';
          this.fetchPaymentCards();
        });
    } else if (caseUuid) {
      this.chargeAmount    = +requestChargeAmounts;
      this.paymentPlanType = this.route.snapshot.queryParamMap?.get('plan_type') === 'additional_plans' ?
        'additional_plans' :
        'debt_plan';
      this.buildIdCardForm(); // Note: will not be able to see existing cards if commented out
    } else {
      this.chargeAmount    = +requestChargeAmounts;
      this.paymentPlanType = this.route.snapshot.queryParamMap?.get('plan_type') === 'additional_plans' ?
        'additional_plans' :
        'debt_plan';
      this.buildIdCardForm();
    }
  }

  private buildIdCardForm(): void {
    this.idCardForm = this.fb.group({
      id_card: [null, [Validators.required, Validators.maxLength(13), CustomValidators.idCardPattern()]],
    });
  }

  public submitIdCardForm(form: FormGroup): void {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    if (this.case) {
      this.fetchPaymentCards();
    } else {
      this.submittingIdCard = true;
      this.clientAppService.getUserByIdCard(this.idCardForm.get('id_card')?.value)
        .pipe(finalize(() => this.submittingIdCard = false))
        .subscribe(result => {
            this.targetClient = result.data;
            this.clientRole   = this.targetClient.id === this.case.partner_user_id ? 'partner' : 'client';
            this.resolvingIdCardForm++;
            this.clientAppService.getLatestUserCase(this.targetClient.uuid, ['client.address'])
              .pipe(finalize(() => this.resolvingIdCardForm--))
              .subscribe(
                res => {
                  this.case       = res.data;
                  this.idCardForm = null;
                  this.clientAppService.paymentAmount(this.case.client.uuid, this.case.uuid).subscribe(
                    r => {
                      this.paymentPlanType = r.data.plan_type;
                      this.installment     = r.data.installment;
                      this.fetchPaymentCards();
                      let amountToCharge = 0;
                      if (this.installment) {
                        amountToCharge = this.installment.amount - this.installment.amount_paid;
                      }
                      this.chargeAmount = Math.min(amountToCharge, this.chargeAmount);
                      this.idCardForm   = null;
                      this.buildNewCardForm(this.targetClient, this.chargeAmount);
                    });
                },
              );
          },
          err => this.errorMessage = 'Invalid DNI'
        );
    }
  }

  private fetchPaymentCards(): void {
    const requestData = this.paymentRequest ?
      {signature: this.requestSignature, id_card: this.targetClient.id_card} :
      null;

    const observable = this.paymentRequest ?
      this.clientAppService.authorizePaymentRequest(this.paymentRequest.uuid, requestData) :
      this.clientAppService.getPaymentCardsPublic(this.case.uuid);

    this.submittingIdCard = true;
    observable.pipe(finalize(() => this.submittingIdCard = false))
      .subscribe(result => {
        this.paymentCards      = this.mapPaymentCards(result.data);
        this.idCardForm        = null;
        this.paymentFormActive = true;
      }, error => {
        this.idCardForm   = null;
        this.buildNewCardForm(this.targetClient, this.chargeAmount);
      });
  }

  public dismissError(): void {
    this.errorMessage = null;
  }

  private buildNewCardForm(targetClient: User, chargeAmount: number): void {
    this.paymentFormActive = true;
  }

  private mapPaymentCards(paymentCards: Array<PaymentCard>): Array<PaymentCard> {
    return paymentCards.map(card => {
      const expDateObj         = DateTime.fromFormat(
        `${ card.card_exp_month }${ card.card_exp_year }`,
        'MMyyyy'
      ).startOf('month');
      card.expires_at          = expDateObj.endOf('month').toISO();
      const expirationDateDiff = expDateObj.diffNow('months').months;

      if (expirationDateDiff <= 4) {
        card.expire = 'soon';
      }

      if (expirationDateDiff < 0) {
        card.expire = 'expired';
      }

      card.card_brand = card.card_brand ? card.card_brand : cardValidation.card.type(card.card_bin);

      return card;
    });
  }

}
