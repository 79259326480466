import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseAsset } from '../../../../../../../../_base-shared/models/Case/CaseAsset';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseExpense } from '../../../../../../../../_base-shared/models/Case/CaseExpense';
import { CaseIncome } from '../../../../../../../../_base-shared/models/Case/CaseIncome';
import { Proposal } from '../../../../../../../../_base-shared/models/CaseDocument/Proposal';
import { EntityAdministrator } from '../../../../../../../../_base-shared/models/Entity/EntityAdministrator';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-proposal-modal',
  templateUrl: './proposal-modal.component.html',
  styleUrls:   ['./proposal-modal.component.scss'],
  animations:  [
    trigger(
        'inOutAnimation',
        [
          transition(
              ':enter',
              [
                style({height: 0, opacity: 0}),
                animate('0.3s ease-out',
                    style({height: '*', opacity: 1})),
              ],
          ),
          transition(
              ':leave',
              [
                animate('0.3s ease-in',
                    style({height: 0, opacity: 0})),
              ],
          ),
        ],
    ),
  ],
})
export class ProposalModalComponent implements OnInit {
  public case: Case;
  public caseAdministrator: EntityAdministrator;
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading  = 0;
  public storageUrl = environment.STORAGE_URL + '/';

  public totalIncome     = 0;
  public totalExpenses   = 0;
  public totalAssets     = 0;
  public debtLevel       = 0;
  public monthsRemaining = 0;
  public totalMonths     = 0;
  public showStepPayment = false;

  constructor(private fb: UntypedFormBuilder,
              private caseService: CaseService,
              private translateService: TranslateService,
              private toast: ToastrService,
              private dialogRef: MatDialogRef<ProposalModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.case               = this.data.case;
    const requiredRelations = [
      'product',
      'case_entities.administrator',
      'unsecured_creditors',
      'terms',
      'income',
      'expense',
      'assets',
      'proposal',
    ];
    const loadRelations     = [];
    requiredRelations.forEach(relation => {
      if (relation === 'case_entities.administrator') {
        if (!this.case?.case_entities?.administrator) {
          loadRelations.push('case_entities.administrator');
        }
      } else {
        if (!this.case[relation]) {
          loadRelations.push(relation);
        }
      }
    });

    this.isLoading++;
    this.caseService.get(this.case.id, loadRelations).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      // this.case = result.data;
      loadRelations.forEach(relation => {
        this.case[relation] = result.data[relation];
      });
      this.caseAdministrator = this.data.administrator ? this.data.administrator : this.case.case_entities?.administrator;
      console.log(this.data);
      this.calculateValues(this.case);
      this.buildForm();
      this.calculateDividend();

      this.form.get('offer_to_creditors').valueChanges
          .subscribe(value => this.calculateDividend('offer_to_creditors'));
      this.form.get('debt_level').valueChanges.subscribe(value => this.calculateDividend('debt_level'));
      this.form.get('term').valueChanges.subscribe(value => this.calculateDividend('term'));
      this.form.get('step_offer').valueChanges.subscribe(value => this.calculateDividend('step_offer'));
      this.form.get('step_period').valueChanges.subscribe(value => this.calculateDividend('step_period'));

      this.form.get('dividend').valueChanges.subscribe(value => this.calculateOfferToCreditors(value));
    });
  }

  public closeModal(proposal: Proposal | null, dismissed: boolean) {
    this.dialogRef.close({dismissed, data: proposal});
  }

  private buildForm(): void {
    const proposal        = this.case.proposal;
    const administratorId      = this.caseAdministrator?.id;
    const administratorName    = this.caseAdministrator?.name ? this.caseAdministrator.name : environment.COMPANY_NAME;
    const administratorAddress = this.caseAdministrator?.address ? this.caseAdministrator.address : environment.COMPANY_ADDRESS;

    this.form = this.fb.group({
      total_income:        [this.totalIncome.toFixed(2), [Validators.required]],
      debt_level:          [this.debtLevel.toFixed(2), [Validators.required]],
      total_expenses:      [this.totalExpenses.toFixed(2), [Validators.required]],
      total_assets:        [this.totalAssets.toFixed(2), [Validators.required]],
      disposable_income:   [(this.totalIncome - this.totalExpenses).toFixed(2), [Validators.required]],
      legal_fees:          [
        {value: (this.case.expense?.legal_fees || 0).toFixed(2), disabled: true},
        [Validators.required],
      ],
      months_remaining:    [this.monthsRemaining, [Validators.required]],
      step_period:         [proposal?.step_period ? proposal.step_period : null],
      step_offer:          [proposal?.step_offer ? proposal.step_offer : null],
      offer_to_creditors:  [
        proposal?.offer_to_creditors ? proposal.offer_to_creditors : '',
        [Validators.required],
      ],
      term:                [proposal?.term ? proposal.term : '60', [Validators.required]],
      dividend:            [
        proposal?.dividend ? proposal.dividend : null,
        [Validators.required],
      ],
      payment_date:        [
        proposal?.payment_date ? new Date(proposal.payment_date) : null,
        [Validators.required],
      ],
      administrator_id:         [administratorId],
      administrator_name:       [
        {value: administratorName, disabled: this.case.product.group_slug === 'lso'},
        [Validators.required],
      ],
      administrator_address:    [
        {value: administratorAddress, disabled: this.case.product.group_slug === 'lso'},
        [Validators.required],
      ],
      cuenta_de_pago:      [proposal?.proposal?.cuenta_de_pago ? proposal.proposal.cuenta_de_pago : null], // TODO: move to table
      referencia_del_pago: [proposal?.proposal?.referencia_del_pago ? proposal.proposal.referencia_del_pago : null], // TODO: move to table
    });

    this.formActive      = true;
    this.showStepPayment = !!proposal?.step_period;
  }

  private calculateOfferToCreditors(dividend: number): void {
    const {debt_level, term, step_offer, step_period} = this.form.value;
    let offerToCreditors: number;

    if (this.showStepPayment) {
      offerToCreditors = +((
          ((+dividend / 100) * +debt_level - (+step_offer * +step_period)) / (term - step_period)
      ).toFixed(2));
    } else {
      offerToCreditors = +((((+dividend / 100) * +debt_level) / +term).toFixed(2));
    }

    // Prevent infinite loop by disabling value change event
    this.form.patchValue({offer_to_creditors: offerToCreditors}, {emitEvent: false, onlySelf: true});
  }

  private calculateDividend(changedField: string = null): void {
    const {offer_to_creditors, debt_level, term, step_offer, step_period} = this.form.value;
    let dividend: number;

    if (this.showStepPayment) {
      const val = ((+offer_to_creditors * (+term - step_period) + (step_offer * step_period)) / +debt_level).toFixed(4);
      dividend  = +(+val * 100).toFixed(2);
    } else {
      const val = ((+offer_to_creditors * +term) / +debt_level).toFixed(4);
      dividend  = +(+val * 100).toFixed(2);
    }

    // Prevent infinite loop by disabling value change event
    this.form.get('dividend').patchValue(dividend, {emitEvent: false, onlySelf: true});
  }

  private calculateValues(clientCase: Case) {
    clientCase.terms.forEach(term => {
      if (term.amount_paid < term.amount) {
        this.monthsRemaining++;
      }
    });
    this.totalMonths = clientCase.terms.length;
    this.calculateTotalIncome(clientCase.income);
    this.calculateTotalExpenses(clientCase.expense);
    this.calculateTotalAssets(clientCase.assets);
    this.calculateDebtLevel(clientCase.unsecured_creditors);
  }

  private calculateDebtLevel(unsecuredCreditors: Array<CaseCreditor>) {
    this.debtLevel = 0;
    unsecuredCreditors.forEach(creditor => {
      this.debtLevel += (+creditor.pivot.current_balance || 0);
    });
  }

  private calculateTotalIncome(caseIncome: CaseIncome) {
    const salary      = (+caseIncome?.pension_state || 0) + (+caseIncome?.salary_client || 0) +
        (+caseIncome?.salary_partner || 0)
        + (+caseIncome?.subsidy_benefit || 0) + (+caseIncome?.unemployment_benefit || 0);
    const pension     = (+caseIncome?.pension_private || 0) + (+caseIncome?.pension_credit || 0) +
        (+caseIncome?.pension_other || 0);
    const otherIncome = (+caseIncome?.other_boarders_or_lodgers || 0) +
        (+caseIncome?.other_non_dependent_contributions || 0)
        + (+caseIncome?.other_student_loans_and_grants || 0);
    const benefits    = (+caseIncome?.compensatory_pension || 0) + (+caseIncome?.alimony || 0);
    this.totalIncome  = salary + pension + otherIncome + benefits;
  }

  private calculateTotalExpenses(caseExpense: CaseExpense) {
    const essential = (+caseExpense?.essential_rent || 0) + (+caseExpense?.essential_mortgage || 0)
        + (+caseExpense?.life_insurance || 0) + (+caseExpense?.pension_plan || 0) + (+caseExpense?.ibi || 0)
        + (+caseExpense?.essential_gas || 0) + (+caseExpense?.home_insurance || 0) +
        (+caseExpense?.school_expenses || 0)
        + (+caseExpense?.essential_electricity || 0) + (+caseExpense?.essential_water || 0)
        + (+caseExpense?.essential_other || 0) + (+caseExpense?.essential_local_tax || 0);

    const otherExpenditure = (+caseExpense?.other_hobbies_leisure_sport || 0) + (+caseExpense?.other_other || 0)
        + (+caseExpense?.pet_expenses || 0) + (+caseExpense?.medical_expenses || 0) + (+caseExpense?.medications || 0) +
        (+caseExpense?.cosmetics_and_beauty || 0)
        + (+caseExpense?.legal_fees || 0);

    const travel = (+caseExpense?.travel_public_transport || 0) + (+caseExpense?.travel_car_insurance || 0)
        + (+caseExpense?.travel_vehicle_tax || 0) + (+caseExpense?.travel_fuel || 0) +
        (+caseExpense?.essential_hire_purchase || 0) +
        (+caseExpense?.itv || 0);

    const housekeeping = (+caseExpense?.housekeeping_food_and_groceries || 0) +
        (+caseExpense?.housekeeping_cleaning || 0)
        + (+caseExpense?.housekeeping_newspapers || 0) + (+caseExpense?.housekeeping_alcohol || 0)
        + (+caseExpense?.housekeeping_laundry || 0) + (+caseExpense?.housekeeping_clothing_footwear || 0);

    const phone = (+caseExpense?.phone_home || 0) + (+caseExpense?.phone_mobile || 0);

    let specialExpenses = 0;
    if (caseExpense.special_expenses && caseExpense.special_expenses.length) {
      specialExpenses = 0;

      caseExpense.special_expenses.forEach(spec => specialExpenses += +spec.value);
    }

    this.totalExpenses = essential + otherExpenditure + travel + housekeeping + phone + specialExpenses;
  }

  private calculateTotalAssets(caseAssets: Array<CaseAsset>) {
    this.totalAssets = 0;
    caseAssets.forEach(caseAsset => {
      if (caseAsset.type === 'bank_accounts') {
        this.totalAssets += caseAsset.balance - caseAsset.exposed;
      }

      if (caseAsset.type === 'vehicles') {
        this.totalAssets += caseAsset.value - caseAsset.outstanding_finance;
      }

      if (caseAsset.type === 'properties') {
        this.totalAssets += caseAsset.value - +caseAsset.mortgage;
      }

      if (caseAsset.type === 'other') {
        this.totalAssets += caseAsset.estimated_value - caseAsset.outstanding_finance;
      }
    });
  }

  public submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const requestData = {...this.form.getRawValue(), payment_date: this.form.get('payment_date').value.toISOString()};

    this.isLoading++;
    this.caseService.generateProposal(this.case.id, requestData).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.case.proposal = result.data;
          this.toast.success(this.translateService.instant('CASES.details.generate-proposal-success'));
          this.closeModal(this.case.proposal, false);
        },
        error => {
          console.error(error);
          this.toast.error(this.translateService.instant('CASES.details.generate-proposal-error'));
        });
  }

  public stepPayment() {
    this.showStepPayment = !this.showStepPayment;
    if (!this.showStepPayment) { // remove value in form
      this.form.get('step_period').patchValue(null);
      this.form.get('step_offer').patchValue(null);
    }
    //  Update form values to recalculate `dividend` field
    this.form.updateValueAndValidity();
  }
}
