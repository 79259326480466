<button mat-stroked-button type="button" color="primary" class="btn mt-2 ml-2"
        (click)="clearFilters()">{{ "SHARED.reset-filters" | translate }}</button>
<div class="filters pb-5 pt-3" *ngIf="form" [formGroup]="form">
  <div class="advanced-filters">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "PAYMENTS.advanced-filters" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-4">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</mat-label>
              <mat-select formControlName="ownership" multiple>
                <mat-option
                  value="applicant">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</mat-option>
                <mat-option
                  value="partner">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</mat-option>
                <mat-option value="joint">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</mat-option>
              </mat-select>
              <button *ngIf="form.value.ownership.length" mat-button matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearMultiSelect($event, 'ownership')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-lg col-xl">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ 'CASE_CREDITOR.model.verified.label' | translate }}</mat-label>
              <mat-select formControlName="verified" multiple>
                <mat-option [value]="1">{{"CASE_CREDITOR.model.verified.options.true" | translate}}</mat-option>
                <mat-option [value]="0">{{"CASE_CREDITOR.model.verified.options.false" | translate}}</mat-option>
              </mat-select>
              <button *ngIf="form.value.verified.length" mat-button matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearMultiSelect($event, 'verified')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-lg col-xl">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</mat-label>
              <mat-select formControlName="response_received" multiple>
                <mat-option value="mandate_sent">
                  {{"CASE_CREDITOR.model.response_received.options.mandate_sent" | translate}}
                </mat-option>
                <mat-option value="chase_required">
                  {{"CASE_CREDITOR.model.response_received.options.chase_required" | translate}}
                </mat-option>
                <mat-option value="mandate_rejected">
                  {{"CASE_CREDITOR.model.response_received.options.mandate_rejected" | translate}}
                </mat-option>
                <mat-option value="balance_received">
                  {{"CASE_CREDITOR.model.response_received.options.balance_received" | translate}}
                </mat-option>
                <mat-option value="balance_outstanding">
                  {{"CASE_CREDITOR.model.response_received.options.balance_outstanding" | translate}}
                </mat-option>
                <mat-option value="proposal_sent">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_sent" | translate}}
                </mat-option>
                <mat-option value="proposal_rejected">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_rejected" | translate}}
                </mat-option>
                <mat-option value="modification_required">
                  {{"CASE_CREDITOR.model.response_received.options.modification_required" | translate}}
                </mat-option>
                <mat-option value="proposal_accepted">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_accepted" | translate}}
                </mat-option>
                <mat-option value="extrajudicial_claim_sent">
                  {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_sent' | translate }}
                </mat-option>
                <mat-option value="extrajudicial_claim_accepted">
                  {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_accepted' | translate }}
                </mat-option>
                <mat-option value="extrajudicial_claim_rejected">
                  {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_rejected' | translate }}
                </mat-option>
                <mat-option value="extrajudicial_claim_unanswered">
                  {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_unanswered' | translate }}
                </mat-option>
                <mat-option value="claim_filed">
                  {{ 'CASE_CREDITOR.model.response_received.options.claim_filed' | translate }}
                </mat-option>
                <mat-option value="claim_approved">
                  {{ 'CASE_CREDITOR.model.response_received.options.claim_approved' | translate }}
                </mat-option>
                <mat-option value="claim_rejected">
                  {{ 'CASE_CREDITOR.model.response_received.options.claim_rejected' | translate }}
                </mat-option>
              </mat-select>
              <button *ngIf="form.value.response_received.length" mat-button matSuffix mat-icon-button
                      aria-label="Clear"
                      (click)="clearMultiSelect($event, 'response_received')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<!--&lt;!&ndash; Secured Creditors &ndash;&gt;-->
<!--<h3>{{ "CASE_CREDITOR.model.type.options.secured" | translate }}</h3>-->
<!--<button mat-raised-button color="primary"-->
<!--        [matMenuTriggerFor]="menuSecured">{{ "SHARED.bulk-actions" | translate }}</button>-->
<!--<mat-menu #menuSecured="matMenu">-->
<!--  <div [title]="securedVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="securedVerifyDisabled"-->
<!--       (click)="verifyCreditor($event, selectionSecured, 'secured_creditors')">-->
<!--      {{ "CASES.details.verify-send-mandates" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div [title]="securedResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="securedResendDisabled"-->
<!--       (click)="verifyCreditor($event, selectionSecured, 'secured_creditors')">-->
<!--      {{ "CASES.details.resend-mandates" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div [title]="securedRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="securedRecoveryDisabled"-->
<!--       (click)="sendMandatesRecoveryAgent($event, selectionSecured)">-->
<!--      {{ "CASES.details.send-mandates-recovery" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="securedSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="securedSendAepDisabled"-->
<!--       (click)="sendAep($event, selectionSecured, 'secured_creditors')">-->
<!--      {{ "CASES.details.send-aep" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="securedResendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="securedResendAepDisabled"-->
<!--       (click)="sendAep($event, selectionSecured, 'secured_creditors')">-->
<!--      {{ "CASES.details.resend-aep" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="securedProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="securedProposalAccordDisabled"-->
<!--       (click)="sendProposalAndAccord($event, selectionSecured)">-->
<!--      {{ "CASES.details.send-proposal-accord" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="!selectionSecured.selected.length"-->
<!--       (click)="changeStatus($event, selectionSecured, 'secured_creditors')">-->
<!--      {{ "CASES.details.change-status" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="!selectionSecured.selected.length"-->
<!--       (click)="sendLastChance($event, selectionSecured)">-->
<!--      {{ "CASES.details.send-last-chance" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Right of Access &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionSecured.selected.length"-->
<!--       (click)="sendRightOfAccess(selectionSecured)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash;Right of Access  PPI&ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionSecured.selected.length"-->
<!--       (click)="sendRightOfAccessPpi(selectionSecured)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Send PPI Claim &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionSecured.selected.length ? 'One or more selected creditors does not have this action available' : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionSecured.selected.length"-->
<!--       (click)="sendPpiClaim(selectionSecured)">-->
<!--      {{ 'SHARED.send' | translate }} PPI Claim-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Azcarate Loan &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionSecured.selected.length"-->
<!--       (click)="sendAzcarateLoanLetter(selectionSecured)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Azcarate Revolving&ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionSecured.selected.length"-->
<!--       (click)="sendAzcarateRevolvingLetter(selectionSecured)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--</mat-menu>-->
<!--<div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">-->
<!--  <table style="width: 100%" mat-table matSort [dataSource]="securedCreditors">-->
<!--    <ng-container matColumnDef="select">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--        <mat-checkbox (change)="$event ? masterToggle(selectionSecured, securedCreditors) : null" color="primary"-->
<!--                      [checked]="selectionSecured.hasValue() && isAllSelected(selectionSecured, securedCreditors)"-->
<!--                      [indeterminate]="selectionSecured.hasValue() && !isAllSelected(selectionSecured, securedCreditors)">-->
<!--        </mat-checkbox>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let row">-->
<!--        <mat-checkbox (click)="$event.stopPropagation()" color="primary"-->
<!--                      (change)="$event ? selectionSecured.toggle(row) : null"-->
<!--                      [checked]="selectionSecured.isSelected(row)">-->
<!--        </mat-checkbox>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="name">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.name}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="ownership">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <ng-container *ngIf="element.pivot.ownership === 'applicant'">-->
<!--          {{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="element.pivot.ownership === 'partner'">-->
<!--          {{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="element.pivot.ownership === 'joint'">-->
<!--          {{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="debt_type">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        {{ "CASE_CREDITOR.model.debt_type.options." + element.pivot.debt_type | translate }}-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="initial_balance">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--        <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        {{ element.pivot.initial_balance | number: '1.2-2' }}€-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="current_balance">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.pivot.current_balance | number: '1.2-2'}}€</td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="monthly_payments">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.pivot.monthly_payments | number: '1.2-2'}}€</td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="reference_number">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.pivot.reference_number}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="response_received">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <ng-container>-->
<!--          {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.response_received) | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="additional_partner">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <mat-icon *ngIf="element.pivot.additional_partner" class="mat-icon-size" color="primary">check_circle-->
<!--        </mat-icon>-->
<!--        <mat-icon *ngIf="!element.pivot.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="making_payments">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <mat-icon *ngIf="element.pivot.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>-->
<!--        <mat-icon *ngIf="!element.pivot.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="azcarate">-->
<!--      <th mat-header-cell *matHeaderCellDef>Azcarate</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <mat-icon *ngIf="element.pivot.total_repaid > element.pivot.initial_balance" class="mat-icon-size"-->
<!--                  color="primary">check_circle-->
<!--        </mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="actions">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>-->
<!--        <mat-menu #menu="matMenu">-->
<!--          <a *ngIf="!element.pivot.verified" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="verifyCreditor($event, [element.pivot.id], 'secured_creditors')">-->
<!--            {{ "CASES.details.verify-send-mandates" | translate }}-->
<!--          </a>-->
<!--          <a *ngIf="element.pivot.verified" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="verifyCreditor($event, [element.pivot.id], 'secured_creditors')">-->
<!--            {{ "CASES.details.resend-mandates" | translate }}-->
<!--          </a>-->
<!--          <a *ngIf="element.pivot.recovery_creditor_id" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-mandates-recovery" | translate }}-->
<!--          </a>-->
<!--          <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">-->
<!--            <a *ngIf="!element.pivot.aep_sent" mat-menu-item class="dropdown-item" href="#"-->
<!--               [disabled]="missingAepFiles"-->
<!--               (click)="sendAep($event, [element.pivot.id], 'secured_creditors')">-->
<!--              {{ "CASES.details.send-aep" | translate }}-->
<!--            </a>-->
<!--          </div>-->
<!--          <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">-->
<!--            <a *ngIf="element.pivot.aep_sent" mat-menu-item class="dropdown-item" href="#"-->
<!--               [disabled]="missingAepFiles"-->
<!--               (click)="sendAep($event, [element.pivot.id], 'secured_creditors')">-->
<!--              {{ "CASES.details.resend-aep" | translate }}-->
<!--            </a>-->
<!--          </div>-->

<!--          <a [disabled]="!isSigned || !element.pivot.proposal_location"-->
<!--             mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendProposalAndAccord($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-proposal-accord" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             [disabled]="!(element.pivot.total_repaid > element.pivot.initial_balance)"-->
<!--             (click)="sendEmail($event, element.pivot.id)">-->
<!--            {{ "CASES.details.send-azcarate" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="changeStatus($event, [element.pivot.id], 'secured_creditors')">-->
<!--            {{ "CASES.details.change-status" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendLastChance($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-last-chance" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendDeleteNumber($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-delete-number" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendAntiHarassment($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-azkarate-acoso" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendLoanCancellationEmail($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-azkarate-cancelar" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             *ngIf="element.pivot.ownership === 'applicant' || element.pivot.ownership === 'joint' "-->
<!--             (click)="getRightOfAccess('client', element.pivot.creditor_id, $event)">-->
<!--            {{'CASES.details.get-right-of-access' | translate}} 1-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             *ngIf="element.pivot.ownership === 'partner' || element.pivot.ownership === 'joint' "-->
<!--             (click)="getRightOfAccess('partner', element.pivot.creditor_id, $event)">-->
<!--            {{'CASES.details.get-right-of-access' | translate}} 2-->
<!--          </a>-->
<!--        </mat-menu>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="loader">-->
<!--      <th mat-header-cell *matHeaderCellDef></th>-->
<!--      <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px">-->
<!--        <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"-->
<!--                     diameter="20" color="accent"></mat-spinner>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
<!--  </table>-->
<!--  <div *ngIf="isFetching" class="text-center" style="padding: 50px">-->
<!--    <div class="spinner-border text-primary" role="status">-->
<!--      <span class="sr-only">Loading...</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="!isFetching && securedCreditors && !securedCreditors.data.length" style="padding: 50px">-->
<!--    <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>-->
<!--  </div>-->
<!--</div>-->
<!--&lt;!&ndash; Unsecured Creditors &ndash;&gt;-->
<!--<h3>{{ "CASE_CREDITOR.model.type.options.unsecured" | translate }}</h3>-->
<!--<button mat-raised-button color="primary" [matMenuTriggerFor]="menuUnsecured">-->
<!--  {{ "SHARED.bulk-actions" | translate }}-->
<!--</button>-->
<!--<mat-menu #menuUnsecured="matMenu">-->
<!--  <div [title]="unsecuredVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="unsecuredVerifyDisabled"-->
<!--       (click)="verifyCreditor($event, selectionUnsecured, 'unsecured_creditors')">-->
<!--      {{ "CASES.details.verify-send-mandates" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div [title]="unsecuredResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="unsecuredResendDisabled"-->
<!--       (click)="verifyCreditor($event, selectionUnsecured, 'unsecured_creditors')">-->
<!--      {{ "CASES.details.resend-mandates" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div [title]="unsecuredRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="unsecuredRecoveryDisabled"-->
<!--       (click)="sendMandatesRecoveryAgent($event, selectionUnsecured)">-->
<!--      {{ "CASES.details.send-mandates-recovery" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="unsecuredSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="unsecuredSendAepDisabled"-->
<!--       (click)="sendAep($event, selectionUnsecured, 'unsecured_creditors')">-->
<!--      {{ "CASES.details.send-aep" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="unsecuredResendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="unsecuredResendAepDisabled"-->
<!--       (click)="sendAep($event, selectionUnsecured, 'unsecured_creditors')">-->
<!--      {{ "CASES.details.resend-aep" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="unsecuredProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="unsecuredProposalAccordDisabled"-->
<!--       (click)="sendProposalAndAccord($event, selectionUnsecured)">-->
<!--      {{ "CASES.details.send-proposal-accord" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="!selectionUnsecured.selected.length"-->
<!--       (click)="changeStatus($event, selectionUnsecured, 'unsecured_creditors')">-->
<!--      {{ "CASES.details.change-status" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="!selectionUnsecured.selected.length"-->
<!--       (click)="sendLastChance($event, selectionUnsecured)">-->
<!--      {{ "CASES.details.send-last-chance" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Right of Access &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionUnsecured.selected.length"-->
<!--       (click)="sendRightOfAccess(selectionUnsecured)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash;Right of Access  PPI&ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionUnsecured.selected.length"-->
<!--       (click)="sendRightOfAccessPpi(selectionUnsecured)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Send PPI Claim &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionUnsecured.selected.length ? 'One or more selected creditors does not have this action available' : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionUnsecured.selected.length"-->
<!--       (click)="sendPpiClaim(selectionUnsecured)">-->
<!--      {{ 'SHARED.send' | translate }} PPI Claim-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Azcarate Loan &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionUnsecured.selected.length"-->
<!--       (click)="sendAzcarateLoanLetter(selectionUnsecured)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Azcarate Revolving&ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionUnsecured.selected.length"-->
<!--       (click)="sendAzcarateRevolvingLetter(selectionUnsecured)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--</mat-menu>-->
<!--<div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">-->
<!--  <table style="width: 100%" mat-table matSort [dataSource]="unsecuredCreditors">-->
<!--    <ng-container matColumnDef="select">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--        <mat-checkbox (change)="$event ? masterToggle(selectionUnsecured, unsecuredCreditors) : null" color="primary"-->
<!--                      [checked]="selectionUnsecured.hasValue() && isAllSelected(selectionUnsecured, unsecuredCreditors)"-->
<!--                      [indeterminate]="selectionUnsecured.hasValue() && !isAllSelected(selectionUnsecured, unsecuredCreditors)">-->
<!--        </mat-checkbox>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let row">-->
<!--        <mat-checkbox (click)="$event.stopPropagation()" color="primary"-->
<!--                      (change)="$event ? selectionUnsecured.toggle(row) : null"-->
<!--                      [checked]="selectionUnsecured.isSelected(row)">-->
<!--        </mat-checkbox>-->
<!--      </td>-->
<!--    </ng-container>-->
<!--    <ng-container matColumnDef="name">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.name}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="ownership">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <ng-container-->
<!--          *ngIf="element.pivot.ownership === 'applicant'">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</ng-container>-->
<!--        <ng-container-->
<!--          *ngIf="element.pivot.ownership === 'partner'">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</ng-container>-->
<!--        <ng-container-->
<!--          *ngIf="element.pivot.ownership === 'joint'">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="debt_type">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_type.label' | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <ng-container>-->
<!--          {{ 'CASE_CREDITOR.model.debt_type.options.' + element.pivot.debt_type | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="initial_balance">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--        <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        {{ element.pivot.initial_balance | number: '1.2-2' }}€-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="current_balance">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.current_balance | number: '1.2-2'}}-->
<!--        €-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="monthly_payments">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.monthly_payments | number: '1.2-2'}}-->
<!--        €-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="reference_number">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.reference_number}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="response_received">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <ng-container>-->
<!--          {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.response_received) | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="additional_partner">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <mat-icon *ngIf="element.pivot.additional_partner" class="mat-icon-size" color="primary">check_circle</mat-icon>-->
<!--        <mat-icon *ngIf="!element.pivot.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="making_payments">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <mat-icon *ngIf="element.pivot.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>-->
<!--        <mat-icon *ngIf="!element.pivot.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="azcarate">-->
<!--      <th mat-header-cell *matHeaderCellDef>Azcarate</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <mat-icon *ngIf="element.pivot.total_repaid > element.pivot.initial_balance" class="mat-icon-size"-->
<!--                  color="primary">check_circle-->
<!--        </mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="actions">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>-->
<!--        <mat-menu #menu="matMenu">-->
<!--          <a *ngIf="!element.pivot.verified" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors')">-->
<!--            {{ "CASES.details.verify-send-mandates" | translate }}-->
<!--          </a>-->
<!--          <a *ngIf="element.pivot.verified" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors')">-->
<!--            {{ "CASES.details.resend-mandates" | translate }}-->
<!--          </a>-->
<!--          <a *ngIf="element.pivot.recovery_creditor_id" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-mandates-recovery" | translate }}-->
<!--          </a>-->

<!--          <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">-->
<!--            <a *ngIf="!element.pivot.aep_sent" mat-menu-item class="dropdown-item" href="#" [disabled]="missingAepFiles"-->
<!--               (click)="sendAep($event, [element.pivot.id], 'unsecured_creditors')">-->
<!--              {{ "CASES.details.send-aep" | translate }}-->
<!--            </a>-->
<!--          </div>-->
<!--          <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">-->
<!--            <a *ngIf="element.pivot.aep_sent" mat-menu-item class="dropdown-item" href="#" [disabled]="missingAepFiles"-->
<!--               (click)="sendAep($event, [element.pivot.id], 'unsecured_creditors')">-->
<!--              {{ "CASES.details.resend-aep" | translate }}-->
<!--            </a>-->
<!--          </div>-->

<!--          <a [disabled]="!isSigned || !element.pivot.proposal_location"-->
<!--             mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendProposalAndAccord($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-proposal-accord" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             [disabled]="!(element.pivot.total_repaid > element.pivot.initial_balance)"-->
<!--             (click)="sendEmail($event, element.pivot.id)">-->
<!--            {{ "CASES.details.send-azcarate" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="changeStatus($event, [element.pivot.id], 'unsecured_creditors')">-->
<!--            {{ "CASES.details.change-status" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendLastChance($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-last-chance" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendDeleteNumber($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-delete-number" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendAntiHarassment($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-azkarate-acoso" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendLoanCancellationEmail($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-azkarate-cancelar" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             *ngIf="element.pivot.ownership === 'applicant' || element.pivot.ownership === 'joint' "-->
<!--             (click)="getRightOfAccess('client', element.pivot.creditor_id, $event)">-->
<!--            {{'CASES.details.get-right-of-access' | translate}} 1-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             *ngIf="element.pivot.ownership === 'partner' || element.pivot.ownership === 'joint' "-->
<!--             (click)="getRightOfAccess('partner', element.pivot.creditor_id, $event)">-->
<!--            {{'CASES.details.get-right-of-access' | translate}} 2-->
<!--          </a>-->
<!--        </mat-menu>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="loader">-->
<!--      <th mat-header-cell *matHeaderCellDef></th>-->
<!--      <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"-->
<!--                     diameter="20" color="accent"></mat-spinner>-->
<!--      </td>-->
<!--      git-->
<!--    </ng-container>-->


<!--    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
<!--  </table>-->
<!--  <div *ngIf="isFetching" class="text-center" style="padding: 50px">-->
<!--    <div class="spinner-border text-primary" role="status">-->
<!--      <span class="sr-only">Loading...</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="!isFetching && unsecuredCreditors && !unsecuredCreditors.data.length" style="padding: 50px">-->
<!--    <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>-->
<!--  </div>-->
<!--</div>-->
<!--&lt;!&ndash; Claim Creditors &ndash;&gt;-->
<!--<h3>{{ "CASE_CREDITOR.model.type.options.claim" | translate }}</h3>-->
<!--<button mat-raised-button color="primary" [matMenuTriggerFor]="menuClaim">-->
<!--  {{ "SHARED.bulk-actions" | translate }}-->
<!--</button>-->
<!--<mat-menu #menuClaim="matMenu" >-->
<!--  <div [title]="claimVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="claimVerifyDisabled"-->
<!--       (click)="verifyCreditor($event, selectionClaim, 'claim_creditors')">-->
<!--      {{ "CASES.details.verify-send-mandates" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div [title]="claimResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="claimResendDisabled"-->
<!--       (click)="verifyCreditor($event, selectionClaim, 'claim_creditors')">-->
<!--      {{ "CASES.details.resend-mandates" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div [title]="claimRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="claimRecoveryDisabled"-->
<!--       (click)="sendMandatesRecoveryAgent($event, selectionClaim)">-->
<!--      {{ "CASES.details.send-mandates-recovery" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="claimSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="claimSendAepDisabled"-->
<!--       (click)="sendAep($event, selectionClaim, 'claim_creditors')">-->
<!--      {{ "CASES.details.send-aep" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="claimResendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="claimResendAepDisabled"-->
<!--       (click)="sendAep($event, selectionClaim, 'claim_creditors')">-->
<!--      {{ "CASES.details.resend-aep" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="claimProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="claimProposalAccordDisabled"-->
<!--       (click)="sendProposalAndAccord($event, selectionClaim)">-->
<!--      {{ "CASES.details.send-proposal-accord" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="!selectionClaim.selected.length"-->
<!--       (click)="changeStatus($event, selectionClaim, 'claim_creditors')">-->
<!--      {{ "CASES.details.change-status" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  <div-->
<!--    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" href="#" [disabled]="!selectionClaim.selected.length"-->
<!--       (click)="sendLastChance($event, selectionClaim)">-->
<!--      {{ "CASES.details.send-last-chance" | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Right of Access &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionClaim.selected.length"-->
<!--       (click)="sendRightOfAccess(selectionClaim)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash;Right of Access  PPI&ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionClaim.selected.length"-->
<!--       (click)="sendRightOfAccessPpi(selectionClaim)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Send PPI Claim &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionClaim.selected.length ? 'One or more selected creditors does not have this action available' : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionClaim.selected.length"-->
<!--       (click)="sendPpiClaim(selectionClaim)">-->
<!--      {{ 'SHARED.send' | translate }} {{'CASES.single.creditors.actions.ppi_claim' | translate}}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Azcarate Loan &ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionClaim.selected.length"-->
<!--       (click)="sendAzcarateLoanLetter(selectionClaim)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--  &lt;!&ndash; Azcarate Revolving&ndash;&gt;-->
<!--  <div-->
<!--    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">-->
<!--    <a mat-menu-item class="dropdown-item" [disabled]="!selectionClaim.selected.length"-->
<!--       (click)="sendAzcarateRevolvingLetter(selectionClaim)">-->
<!--      {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}-->
<!--    </a>-->
<!--  </div>-->
<!--</mat-menu>-->
<!--<div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">-->
<!--  <table style="width: 100%" mat-table matSort [dataSource]="claimCreditors">-->
<!--    <ng-container matColumnDef="select">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--        <mat-checkbox (change)="$event ? masterToggle(selectionClaim, claimCreditors) : null" color="primary"-->
<!--                      [checked]="selectionClaim.hasValue() && isAllSelected(selectionClaim, claimCreditors)"-->
<!--                      [indeterminate]="selectionClaim.hasValue() && !isAllSelected(selectionClaim, claimCreditors)">-->
<!--        </mat-checkbox>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let row">-->
<!--        <mat-checkbox (click)="$event.stopPropagation()" color="primary"-->
<!--                      (change)="$event ? selectionClaim.toggle(row) : null"-->
<!--                      [checked]="selectionClaim.isSelected(row)">-->
<!--        </mat-checkbox>-->
<!--      </td>-->
<!--    </ng-container>-->
<!--    <ng-container matColumnDef="name">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.name}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="ownership">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <ng-container-->
<!--          *ngIf="element.pivot.ownership === 'applicant'">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</ng-container>-->
<!--        <ng-container-->
<!--          *ngIf="element.pivot.ownership === 'partner'">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</ng-container>-->
<!--        <ng-container-->
<!--          *ngIf="element.pivot.ownership === 'joint'">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="debt_type">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_type.label' | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <ng-container>-->
<!--          {{ 'CASE_CREDITOR.model.debt_type.options.' + element.pivot.debt_type | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="initial_balance">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--        <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        {{ element.pivot.initial_balance | number: '1.2-2' }}€-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="current_balance">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.current_balance | number: '1.2-2'}}-->
<!--        €-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="monthly_payments">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.monthly_payments | number: '1.2-2'}}-->
<!--        €-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="reference_number">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.reference_number}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="response_received">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <ng-container>-->
<!--          {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.response_received) | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="additional_partner">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <mat-icon *ngIf="element.pivot.additional_partner" class="mat-icon-size" color="primary">check_circle</mat-icon>-->
<!--        <mat-icon *ngIf="!element.pivot.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="making_payments">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <mat-icon *ngIf="element.pivot.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>-->
<!--        <mat-icon *ngIf="!element.pivot.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="azcarate">-->
<!--      <th mat-header-cell *matHeaderCellDef>Azcarate</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <mat-icon *ngIf="element.pivot.total_repaid > element.pivot.initial_balance" class="mat-icon-size"-->
<!--                  color="primary">check_circle-->
<!--        </mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="actions">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>-->
<!--        <mat-menu #menu="matMenu" yPosition="above">-->
<!--          <a *ngIf="!element.pivot.verified" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="verifyCreditor($event, [element.pivot.id], 'claim_creditors')">-->
<!--            {{ "CASES.details.verify-send-mandates" | translate }}-->
<!--          </a>-->
<!--          <a *ngIf="element.pivot.verified" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="verifyCreditor($event, [element.pivot.id], 'claim_creditors')">-->
<!--            {{ "CASES.details.resend-mandates" | translate }}-->
<!--          </a>-->
<!--          <a *ngIf="element.pivot.recovery_creditor_id" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-mandates-recovery" | translate }}-->
<!--          </a>-->

<!--          <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">-->
<!--            <a *ngIf="!element.pivot.aep_sent" mat-menu-item class="dropdown-item" href="#" [disabled]="missingAepFiles"-->
<!--               (click)="sendAep($event, [element.pivot.id], 'claim_creditors')">-->
<!--              {{ "CASES.details.send-aep" | translate }}-->
<!--            </a>-->
<!--          </div>-->
<!--          <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">-->
<!--            <a *ngIf="element.pivot.aep_sent" mat-menu-item class="dropdown-item" href="#" [disabled]="missingAepFiles"-->
<!--               (click)="sendAep($event, [element.pivot.id], 'claim_creditors')">-->
<!--              {{ "CASES.details.resend-aep" | translate }}-->
<!--            </a>-->
<!--          </div>-->

<!--          <a [disabled]="!isSigned || !element.pivot.proposal_location"-->
<!--             mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendProposalAndAccord($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-proposal-accord" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             [disabled]="!(element.pivot.total_repaid > element.pivot.initial_balance)"-->
<!--             (click)="sendEmail($event, element.pivot.id)">-->
<!--            {{ "CASES.details.send-azcarate" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="changeStatus($event, [element.pivot.id], 'claim_creditors')">-->
<!--            {{ "CASES.details.change-status" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendLastChance($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-last-chance" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendDeleteNumber($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-delete-number" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendAntiHarassment($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-azkarate-acoso" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="sendLoanCancellationEmail($event, [element.pivot.id])">-->
<!--            {{ "CASES.details.send-azkarate-cancelar" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             *ngIf="element.pivot.ownership === 'applicant' || element.pivot.ownership === 'joint' "-->
<!--             (click)="getRightOfAccess('client', element.pivot.creditor_id, $event)">-->
<!--            {{'CASES.details.get-right-of-access' | translate}} 1-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             *ngIf="element.pivot.ownership === 'partner' || element.pivot.ownership === 'joint' "-->
<!--             (click)="getRightOfAccess('partner', element.pivot.creditor_id, $event)">-->
<!--            {{'CASES.details.get-right-of-access' | translate}} 2-->
<!--          </a>-->
<!--        </mat-menu>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="loader">-->
<!--      <th mat-header-cell *matHeaderCellDef></th>-->
<!--      <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"-->
<!--          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">-->
<!--        <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"-->
<!--                     diameter="20" color="accent"></mat-spinner>-->
<!--      </td>-->
<!--      git-->
<!--    </ng-container>-->


<!--    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
<!--  </table>-->
<!--  <div *ngIf="isFetching" class="text-center" style="padding: 50px">-->
<!--    <div class="spinner-border text-primary" role="status">-->
<!--      <span class="sr-only">Loading...</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="!isFetching && claimCreditors && !claimCreditors.data.length" style="padding: 50px">-->
<!--    <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>-->
<!--  </div>-->
<!--</div>-->
<!--&lt;!&ndash; Public Debts &ndash;&gt;-->
<!--<h3>{{ "CASE_CREDITOR.model.type.options.public" | translate }}</h3>-->
<!--<button mat-raised-button color="primary" [matMenuTriggerFor]="menuPublic">-->
<!--  {{ "SHARED.bulk-actions" | translate }}-->
<!--</button>-->
<!--<mat-menu #menuPublic="matMenu">-->
<!--  <a mat-menu-item class="dropdown-item" href="#"-->
<!--     [disabled]="publicDebtsVerifyDisabled"-->
<!--     (click)="verifyCreditor($event, selectionPublicDebts, 'public_debts')">-->
<!--    {{ "CASES.details.verify-send-mandates" | translate }}-->
<!--  </a>-->
<!--  <a mat-menu-item class="dropdown-item" href="#" [disabled]="publicDebtsResendDisabled"-->
<!--     (click)="verifyCreditor($event, selectionPublicDebts, 'public_debts')">-->
<!--    {{ "CASES.details.resend-mandates" | translate }}-->
<!--  </a>-->
<!--  <a mat-menu-item class="dropdown-item" href="#"-->
<!--     (click)="changeStatus($event, selectionPublicDebts, 'public_debts')">-->
<!--    {{ "CASES.details.change-status" | translate }}-->
<!--  </a>-->
<!--</mat-menu>-->
<!--<div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; bottom: 50px; margin-bottom: 2rem">-->
<!--  <table style="width: 100%" mat-table matSort [dataSource]="publicDebts">-->
<!--    <ng-container matColumnDef="select">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--        <mat-checkbox (change)="$event ? masterToggle(selectionPublicDebts, publicDebts) : null" color="primary"-->
<!--                      [checked]="selectionPublicDebts.hasValue() && isAllSelected(selectionPublicDebts, publicDebts)"-->
<!--                      [indeterminate]="selectionPublicDebts.hasValue() && !isAllSelected(selectionPublicDebts, publicDebts)">-->
<!--        </mat-checkbox>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let row">-->
<!--        <mat-checkbox (click)="$event.stopPropagation()" color="primary"-->
<!--                      (change)="$event ? selectionPublicDebts.toggle(row) : null"-->
<!--                      [checked]="selectionPublicDebts.isSelected(row)">-->
<!--        </mat-checkbox>-->
<!--      </td>-->
<!--    </ng-container>-->
<!--    <ng-container matColumnDef="name">-->
<!--      <th mat-header-cell-->
<!--          *matHeaderCellDef>{{ "CASE_CREDITOR.model.public_organization.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <ng-container *ngIf="element.public_organisation === 'social-security'">-->
<!--          {{ "CASE_CREDITOR.model.public_organization.options.social-security" | translate }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="element.public_organisation === 'estate'">-->
<!--          {{ "CASE_CREDITOR.model.public_organization.options.estate" | translate }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="element.public_organisation === 'town-hall'">-->
<!--          {{ "CASE_CREDITOR.model.public_organization.options.town-hall" | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="ownership">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <ng-container *ngIf="element.ownership === 'applicant'">-->
<!--          {{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="element.ownership === 'partner'">-->
<!--          {{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="element.ownership === 'joint'">-->
<!--          {{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="debt_type">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <ng-container *ngIf="element.debt_type === 'tax'">-->
<!--          {{ "CASE_CREDITOR.model.debt_type.options.tax" | translate }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="element.debt_type === 'capital-gain'">-->
<!--          {{ "CASE_CREDITOR.model.debt_type.options.capital-gain" | translate }}-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="element.debt_type === 'penalty-fee'">-->
<!--          {{ "CASE_CREDITOR.model.debt_type.options.penalty-fee" | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="initial_balance">-->
<!--      <th mat-header-cell *matHeaderCellDef>-->
<!--        <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        {{element.initial_balance | number: '1.2-2'}}€-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="current_balance">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.current_balance | number: '1.2-2'}}€</td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="monthly_payments">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.monthly_payments | number: '1.2-2'}}€</td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="reference_number">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.reference_number}} </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="response_received">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <ng-container>-->
<!--          {{ ("CASE_CREDITOR.model.response_received.options." + element.response_received) | translate }}-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="additional_partner">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.interested_third_party" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <mat-icon *ngIf="element.interested_third_party" class="mat-icon-size" color="primary">check_circle</mat-icon>-->
<!--        <mat-icon *ngIf="!element.interested_third_party" class="mat-icon-size" color="warn">cancel</mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="making_payments">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <mat-icon *ngIf="element.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>-->
<!--        <mat-icon *ngIf="!element.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="actions">-->
<!--      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>-->
<!--        <mat-menu #menu="matMenu" yPosition="above">-->
<!--          <a *ngIf="!element.verified" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="verifyCreditor($event, [element.id], 'public_debts')">-->
<!--            {{ "CASES.details.verify-send-mandates" | translate }}-->
<!--          </a>-->
<!--          <a *ngIf="element.verified" mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="verifyCreditor($event, [element.id], 'public_debts')">-->
<!--            {{ "CASES.details.resend-mandates" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             (click)="changeStatus($event, [element.id], 'public_debts')">-->
<!--            {{ "CASES.details.change-status" | translate }}-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             *ngIf="element.ownership === 'applicant' || element.ownership === 'joint' "-->
<!--             (click)="getRightOfAccess('client', element.creditor_id, $event)">-->
<!--            {{'CASES.details.get-right-of-access' | translate}} 1-->
<!--          </a>-->
<!--          <a mat-menu-item class="dropdown-item" href="#"-->
<!--             *ngIf="element.ownership === 'partner' || element.ownership === 'joint' "-->
<!--             (click)="getRightOfAccess('partner', element.creditor_id, $event)">-->
<!--            {{'CASES.details.get-right-of-access' | translate}} 2-->
<!--          </a>-->
<!--        </mat-menu>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="loader">-->
<!--      <th mat-header-cell *matHeaderCellDef></th>-->
<!--      <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px">-->
<!--        <mat-spinner-->
<!--          *ngIf="isPublicLoading && isPublicLoadingId.length && isPublicLoadingId.indexOf(element.id) !== -1"-->
<!--          diameter="20" color="accent"></mat-spinner>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <tr mat-header-row *matHeaderRowDef="displayedColumnsPD"></tr>-->
<!--    <tr mat-row *matRowDef="let row; columns: displayedColumnsPD;"></tr>-->
<!--  </table>-->
<!--  <div *ngIf="isFetchingPublicDebt" class="text-center" style="padding: 50px">-->
<!--    <div class="spinner-border text-primary" role="status">-->
<!--      <span class="sr-only">Loading...</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="!isFetchingPublicDebt && publicDebts && !publicDebts.data.length" style="padding: 50px">-->
<!--    <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>-->
<!--  </div>-->
<!--</div>-->

<div class="loan-creditors-section">
  <h3>{{ "CASES.editor.creditors.heading" | translate }}</h3>
  <div *ngIf="case" class="mat-elevation-z8"
       style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
    <table style="width: 100%; display: table;overflow-x: auto;" mat-table matSort [dataSource]="loanCreditors"
           [ngClass]="'table-responsive'">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="p-r-10">
          <mat-checkbox (change)="$event ? masterToggle(selectionLoanCreditors, loanCreditors) : null" color="primary"
                        [checked]="selectionLoanCreditors.hasValue() && isAllSelected(selectionLoanCreditors, loanCreditors)"
                        [indeterminate]="selectionLoanCreditors.hasValue() && !isAllSelected(selectionLoanCreditors, loanCreditors)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selectionLoanCreditors.toggle(row) : null"
                        [checked]="selectionLoanCreditors.isSelected(row)">
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="ownership">
        <th mat-header-cell *matHeaderCellDef
            class="p-r-10">{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container
            *ngIf="element.pivot.ownership === 'applicant'">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</ng-container>
          <ng-container
            *ngIf="element.pivot.ownership === 'partner'">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</ng-container>
          <ng-container
            *ngIf="element.pivot.ownership === 'joint'">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ "CASES.single.total" | translate}}
        </td>
      </ng-container>
      <form *ngIf="loanCreditorsForm" [formGroup]="loanCreditorsForm">
        <ng-container matColumnDef="debt_amount">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_amount" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <app-input *ngIf="isEditing && currentRow === i" type="text" formControlName="debt_amount"
                       appearance="standard" fullWidth="false"
                       [label]="'Debt Amount'">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{element.pivot.debt_amount | number: '1.2-2'}}€</p>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ totalDebtAmount | number: '1.2-2' }}€
          </td>
        </ng-container>

        <ng-container matColumnDef="negotiated_amount">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.negotiated_amount" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <app-input *ngIf="isEditing && currentRow === i" type="text" formControlName="negotiated_amount"
                       appearance="standard" fullWidth="false"
                       [label]="'Negotiated Amount'">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{element.pivot.negotiated_amount | number: '1.2-2'}} <span *ngIf="element.pivot.negotiated_amount">€</span></p>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ totalNegotiatedAmount | number: '1.2-2' }}€
          </td>
        </ng-container>

        <ng-container matColumnDef="total_reduction">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.total_reduction" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <p *ngIf="element.pivot.debt_amount && element.pivot.negotiated_amount">{{(element.pivot.debt_amount - element.pivot.negotiated_amount) | number: '1.2-2'}}€</p>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ totalReductionAmount | number: '1.2-2' }}€
          </td>
        </ng-container>

        <ng-container matColumnDef="percent_reduction">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.percent_reduction" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <p *ngIf="element.pivot.debt_amount && element.pivot.negotiated_amount">{{+(+(+(element.pivot.debt_amount - element.pivot.negotiated_amount) / element.pivot.debt_amount) *
              100) | number: '1.2-2'}} <span *ngIf="element.pivot.debt_amount && element.pivot.negotiated_amount">%</span></p>
          </td>
          <td mat-footer-cell *matFooterCellDef [ngClass]="percentReduction >= 70 ? 'text-success' : 'text-danger'">
            {{ percentReduction | number: '1.2-2' }}%
          </td>
        </ng-container>
      </form>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
          <mat-menu #menu="matMenu">
            <a *ngIf="!element.pivot.verified" mat-menu-item class="dropdown-item" href="#"
               (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors')">
              {{ "CASES.details.verify-send-mandates" | translate }}
            </a>
            <a *ngIf="element.pivot.verified" mat-menu-item class="dropdown-item" href="#"
               (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors')">
              {{ "CASES.details.resend-mandates" | translate }}
            </a>
            <a *ngIf="element.pivot.recovery_creditor_id" mat-menu-item class="dropdown-item" href="#"
               (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">
              {{ "CASES.details.send-mandates-recovery" | translate }}
            </a>

            <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <a *ngIf="!element.pivot.aep_sent" mat-menu-item class="dropdown-item" href="#" [disabled]="missingAepFiles"
                 (click)="sendAep($event, [element.pivot.id], 'unsecured_creditors')">
                {{ "CASES.details.send-aep" | translate }}
              </a>
            </div>
            <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <a *ngIf="element.pivot.aep_sent" mat-menu-item class="dropdown-item" href="#" [disabled]="missingAepFiles"
                 (click)="sendAep($event, [element.pivot.id], 'unsecured_creditors')">
                {{ "CASES.details.resend-aep" | translate }}
              </a>
            </div>

            <a [disabled]="!isSigned || !element.pivot.proposal_location"
               mat-menu-item class="dropdown-item" href="#"
               (click)="sendProposalAndAccord($event, [element.pivot.id])">
              {{ "CASES.details.send-proposal-accord" | translate }}
            </a>
            <a mat-menu-item class="dropdown-item" href="#"
               [disabled]="!(element.pivot.total_repaid > element.pivot.initial_balance)"
               (click)="sendEmail($event, element.pivot.id)">
              {{ "CASES.details.send-azcarate" | translate }}
            </a>
            <a mat-menu-item class="dropdown-item" href="#"
               (click)="changeStatus($event, [element.pivot.id], 'unsecured_creditors')">
              {{ "CASES.details.change-status" | translate }}
            </a>
            <a mat-menu-item class="dropdown-item" href="#"
               (click)="sendLastChance($event, [element.pivot.id])">
              {{ "CASES.details.send-last-chance" | translate }}
            </a>
            <a mat-menu-item class="dropdown-item" href="#"
               (click)="sendDeleteNumber($event, [element.pivot.id])">
              {{ "CASES.details.send-delete-number" | translate }}
            </a>
            <a mat-menu-item class="dropdown-item" href="#"
               (click)="sendAntiHarassment($event, [element.pivot.id])">
              {{ "CASES.details.send-azkarate-acoso" | translate }}
            </a>
            <a mat-menu-item class="dropdown-item" href="#"
               (click)="sendLoanCancellationEmail($event, [element.pivot.id])">
              {{ "CASES.details.send-azkarate-cancelar" | translate }}
            </a>
            <a mat-menu-item class="dropdown-item" href="#"
               *ngIf="element.pivot.ownership === 'applicant' || element.pivot.ownership === 'joint' "
               (click)="getRightOfAccess('client', element.pivot.creditor_id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 1
            </a>
            <a mat-menu-item class="dropdown-item" href="#"
               *ngIf="element.pivot.ownership === 'partner' || element.pivot.ownership === 'joint' "
               (click)="getRightOfAccess('partner', element.pivot.creditor_id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 2
            </a>
          </mat-menu>
          <button mat-stroked-button class="ml-2" (click)="toggleLoanEditMode(i, element)">
            {{ !isEditing || currentRow != i ? 'Edit' : 'Save' }}
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"
                       diameter="20" color="accent"></mat-spinner>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsLoanCreditors"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsLoanCreditors;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumnsLoanCreditors"></tr>
     </table>
    <div *ngIf="isFetching" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isFetching && unsecuredCreditors && !unsecuredCreditors.data.length" style="padding: 50px">
      <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
    </div>
  </div>
</div>
