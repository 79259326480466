import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';

@Component({
  selector:    'app-mi-dashboard',
  templateUrl: './mi-dashboard.component.html',
  styleUrls:   ['./mi-dashboard.component.scss'],
})
export class MiDashboardComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('lsoSort') lsoSort: MatSort;
  @ViewChild('dmSort') dmSort: MatSort;
  @ViewChild('bdmSort') bdmSort: MatSort;
  @ViewChild('dgsSort') dgsSort: MatSort;
  public isLoading                     = 0;
  public form: UntypedFormGroup;
  public typeForm                      = new UntypedFormControl('lso');
  public status_ids                    = [];
  public dataSource: MatTableDataSource<any>;
  public dataSourceDmLso: MatTableDataSource<any>;
  public dataSourceDgs: MatTableDataSource<any>;
  public tableDataSource               = [];
  public displayedColumns: string[]    = [
    'name', 'type', 'case_count', 'enviar_contrato', 'enviar_contrato_percent',
    'contrato_firmado', 'contrato_firmado_percent', 'pago', 'pago_percent', 'caso_cancelado', 'caso_cancelado_percent',
    'amount_period',
  ];
  public displayedColumnsLso: string[] = [
    'name',
    'type',
    'case_count_lso',
    'enviar_contrato_lso',
    'enviar_contrato_percent_lso',
    'contrato_firmado_lso',
    'contrato_firmado_percent_lso',
    'pago_lso',
    'pago_percent_lso',
    'caso_cancelado_lso',
    'caso_cancelado_percent_lso',
    'amount_period_lso',
  ];
  public displayedColumnsDm: string[]  = [
    'name', 'type', 'case_count_dm', 'enviar_contrato_dm', 'enviar_contrato_percent_dm',
    'contrato_firmado_dm', 'contrato_firmado_percent_dm', 'pago_dm', 'pago_percent_dm', 'caso_cancelado_dm',
    'caso_cancelado_percent_dm', 'amount_period_dm',
  ];
  public displayedColumnsBDm: string[] = [
    'name', 'type', 'case_count_bdm', 'enviar_contrato_bdm', 'enviar_contrato_percent_bdm',
    'contrato_firmado_bdm', 'contrato_firmado_percent_bdm', 'pago_bdm', 'pago_percent_bdm', 'caso_cancelado_bdm',
    'caso_cancelado_percent_bdm', 'amount_period_bdm',
  ];
  public displayedColumnsDGs: string[] = [
    'name', 'type', 'case_count_dgs', 'enviar_contrato_dgs', 'enviar_contrato_percent_dgs',
    'contrato_firmado_dgs', 'contrato_firmado_percent_dgs', 'pago_dgs', 'pago_percent_dgs', 'caso_cancelado_dgs',
    'caso_cancelado_percent_dgs', 'amount_period_dgs',
  ];
  public tableData                     = {};
  public sortBy                        = 'id';
  public sortOrder                     = 'desc';
  public version: 1 | 2 | number;

  constructor(private fb: UntypedFormBuilder,
              private dashboardService: DashboardService,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.version = +data.version;
      this.buildForm();
      this.getData();
      this.form.valueChanges
          .pipe(
              debounceTime(200),
              distinctUntilChanged(),
          )
          .subscribe(res => {
            this.getData();
          });
    });
  }

  buildForm() {
    this.form = this.fb.group({
      from:      [new Date()],
      to:        [new Date()],
      dateRadio: ['today'],
    });
  }

  clearDatePicker($event, name) {
    this.form.patchValue({[name]: ''});
  }

  onDateChange($event, type) {
    if (type === 'from') {
      this.form.patchValue({
        from: new Date($event.value),
      });
    }
    if (type === 'to') {
      this.form.patchValue({
        to: new Date($event.value),
      });
    }
  }

  onRadioChange($event) {
    if ($event.value === 'today') {
      this.form.patchValue({
        from: new Date(),
        to:   new Date(),
      });
    }
    if ($event.value === 'this-week') {
      const startOfWeek = moment().startOf('isoWeek').format();
      const endOfWeek   = moment().endOf('isoWeek').format();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to:   new Date(endOfWeek),
      });
    }
    if ($event.value === 'this-month') {
      const startOfWeek = moment().startOf('month').format();
      const endOfWeek   = moment().endOf('month').format();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to:   new Date(endOfWeek),
      });
    }
  }

  onRadioChangeType($event) {
    this.typeForm.setValue($event.value);
    if ($event.value === 'lso') {
      this.dataSourceDmLso.sort = this.lsoSort;
    }
    if ($event.value === 'dm') {
      this.dataSourceDmLso.sort = this.dmSort;
    }
    if ($event.value === 'bdm') {
      this.dataSourceDmLso.sort = this.bdmSort;
    }
    if ($event.value === 'dgs') {
      this.dataSourceDgs.sort = this.dgsSort;
    }
  }

  getData() {
    this.isLoading++;
    const dateRange = {
      start_date: this.form.value.from ? this.form.value.from.toDateString() : '',
      end_date:   this.form.value.to ? this.form.value.to.toDateString() : '',
    };

    this.tableData       = {};
    this.tableDataSource = [];
    const observable     = this.version === 1 ?
        this.dashboardService.index(dateRange) :
        this.dashboardService.indexV2(dateRange);
    observable
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            res => {
              this.status_ids = res.data.status_ids;
              res.data.status.forEach(elem => {
                this.tableData[elem.agent_id]                          = this.tableData[elem.agent_id] || {
                  name:     elem.agent,
                  id:       elem.agent_id,
                  statuses: {},
                  fees:     0,
                };
                this.tableData[elem.agent_id].statuses[elem.status_id] = {
                  cases:                    elem.cases,
                  cases_dm:                 elem.case_count_dm,
                  cases_bdm:                elem.case_count_bdm,
                  cases_dgs:                elem.case_count_dgs,
                  cases_lso:                elem.case_count_lso,
                  still_in_same_status:     elem.still_in_same_status,
                  still_in_same_status_dm:  elem.still_in_same_status_dm,
                  still_in_same_status_lso: elem.still_in_same_status_lso,
                  still_in_same_status_dgs: elem.still_in_same_status_dgs,
                };
              });
              for (const property in this.tableData) {
                if (this.tableData.hasOwnProperty(property)) {
                  //  This remapping is needed for the table sorting to work
                  //  (names need to be the same in 'matColumnDef' and in 'mat-cell')
                  //  And to split table for DM and LSO cases
                  console.log(res.data);
                  this.tableData[property].case_count     = res.data.case_count.find(
                      el => el.agent_id === +property).count;
                  this.tableData[property].case_count_dm  = res.data.case_count.find(
                      el => el.agent_id === +property).case_count_dm;
                  this.tableData[property].case_count_bdm = res.data.case_count.find(
                      el => el.agent_id === +property).case_count_bdm;
                  this.tableData[property].case_count_lso = res.data.case_count.find(
                      el => el.agent_id === +property).case_count_lso;
                  this.tableData[property].case_count_dgs = res.data.case_count.find(
                    el => el.agent_id === +property).case_count_dgs;

                  this.tableData[property].amount_period     = res.data.case_count.find(
                      el => el.agent_id === +property).amount_period;
                  this.tableData[property].amount_period_dm  = res.data.case_count.find(
                      el => el.agent_id === +property).amount_period_dm;
                  this.tableData[property].amount_period_bdm = res.data.case_count.find(
                      el => el.agent_id === +property).amount_period_bdm;
                  this.tableData[property].amount_period_lso = res.data.case_count.find(
                      el => el.agent_id === +property).amount_period_lso;
                  this.tableData[property].amount_period_dgs = res.data.case_count.find(
                    el => el.agent_id === +property).amount_period_dgs;

                  this.tableData[property].enviar_contrato             = this.tableData[property].statuses[this.status_ids[0]]?.cases ||
                      0;
                  this.tableData[property].enviar_contrato_dm          = this.tableData[property].statuses[this.status_ids[0]]?.cases_dm ||
                      0;
                  this.tableData[property].enviar_contrato_bdm         = this.tableData[property].statuses[this.status_ids[0]]?.cases_bdm ||
                      0;
                  this.tableData[property].enviar_contrato_lso         = this.tableData[property].statuses[this.status_ids[0]]?.cases_lso ||
                      0;
                  this.tableData[property].enviar_contrato_dgs         = this.tableData[property].statuses[this.status_ids[0]]?.cases_lso ||
                    0;
                  this.tableData[property].enviar_contrato_percent     =
                      (this.tableData[property].statuses[this.status_ids[0]]?.cases || 0) /
                      this.tableData[property].case_count * 100 || 0;
                  this.tableData[property].enviar_contrato_percent_dm  =
                      (this.tableData[property].statuses[this.status_ids[0]]?.cases_dm || 0) /
                      this.tableData[property].case_count_dm * 100 || 0;
                  this.tableData[property].enviar_contrato_percent_bdm =
                      (this.tableData[property].statuses[this.status_ids[0]]?.cases_bdm || 0) /
                      this.tableData[property].case_count_bdm * 100 || 0;
                  this.tableData[property].enviar_contrato_percent_lso =
                      (this.tableData[property].statuses[this.status_ids[0]]?.cases_lso || 0) /
                      this.tableData[property].case_count_lso * 100 || 0;
                  this.tableData[property].enviar_contrato_percent_dgs =
                    (this.tableData[property].statuses[this.status_ids[0]]?.cases_dgs || 0) /
                    this.tableData[property].case_count_dgs * 100 || 0;

                  this.tableData[property].contrato_firmado     = this.tableData[property].statuses[this.status_ids[1]]?.cases ||
                      0;
                  this.tableData[property].contrato_firmado_dm  = this.tableData[property].statuses[this.status_ids[1]]?.cases_dm ||
                      0;
                  this.tableData[property].contrato_firmado_bdm = this.tableData[property].statuses[this.status_ids[1]]?.cases_bdm ||
                      0;
                  this.tableData[property].contrato_firmado_lso = this.tableData[property].statuses[this.status_ids[1]]?.cases_lso ||
                      0;
                  this.tableData[property].contrato_firmado_dgs = this.tableData[property].statuses[this.status_ids[1]]?.cases_dgs ||
                    0;
                  this.tableData[property].contrato_firmado_percent     =
                      (this.tableData[property].statuses[this.status_ids[1]]?.cases || 0) /
                      this.tableData[property].enviar_contrato * 100 || 0;
                  this.tableData[property].contrato_firmado_percent_dm  =
                      (this.tableData[property].statuses[this.status_ids[1]]?.cases_dm || 0) /
                      this.tableData[property].enviar_contrato_dm * 100 || 0;
                  this.tableData[property].contrato_firmado_percent_bdm =
                      (this.tableData[property].statuses[this.status_ids[1]]?.cases_bdm || 0) /
                      this.tableData[property].enviar_contrato_bdm * 100 || 0;
                  this.tableData[property].contrato_firmado_percent_lso =
                      (this.tableData[property].statuses[this.status_ids[1]]?.cases_lso || 0) /
                      this.tableData[property].enviar_contrato_lso * 100 || 0;
                  this.tableData[property].contrato_firmado_percent_dgs =
                    (this.tableData[property].statuses[this.status_ids[1]]?.cases_dgs || 0) /
                    this.tableData[property].enviar_contrato_dgs * 100 || 0;

                  this.tableData[property].pago             = this.tableData[property].statuses[this.status_ids[2]]?.cases ||
                      0;
                  this.tableData[property].pago_dm          = this.tableData[property].statuses[this.status_ids[2]]?.cases_dm ||
                      0;
                  this.tableData[property].pago_bdm         = this.tableData[property].statuses[this.status_ids[2]]?.cases_bdm ||
                      0;
                  this.tableData[property].pago_lso         = this.tableData[property].statuses[this.status_ids[2]]?.cases_lso ||
                      0;
                  this.tableData[property].pago_dgs         = this.tableData[property].statuses[this.status_ids[2]]?.cases_dgs ||
                    0;
                  this.tableData[property].pago_percent     =
                      (this.tableData[property].statuses[this.status_ids[2]]?.cases || 0) /
                      this.tableData[property].contrato_firmado * 100 || 0;
                  this.tableData[property].pago_percent_dm  =
                      (this.tableData[property].statuses[this.status_ids[2]]?.cases_dm || 0) /
                      this.tableData[property].contrato_firmado_dm * 100 || 0;
                  this.tableData[property].pago_percent_bdm =
                      (this.tableData[property].statuses[this.status_ids[2]]?.cases_bdm || 0) /
                      this.tableData[property].contrato_firmado_bdm * 100 || 0;
                  this.tableData[property].pago_percent_lso =
                      (this.tableData[property].statuses[this.status_ids[2]]?.cases_lso || 0) /
                      this.tableData[property].contrato_firmado_lso * 100 || 0;
                  this.tableData[property].pago_percent_dgs =
                    (this.tableData[property].statuses[this.status_ids[2]]?.cases_dgs || 0) /
                    this.tableData[property].contrato_firmado_dgs * 100 || 0;
                  this.tableData[property].caso_cancelado             =
                      this.tableData[property].statuses[this.status_ids[4]]?.still_in_same_status || 0;
                  this.tableData[property].caso_cancelado_dm          =
                      this.tableData[property].statuses[this.status_ids[4]]?.still_in_same_status_dm || 0;
                  this.tableData[property].caso_cancelado_bdm         =
                      this.tableData[property].statuses[this.status_ids[4]]?.cases_bdm || 0;
                  this.tableData[property].caso_cancelado_lso         =
                      this.tableData[property].statuses[this.status_ids[4]]?.still_in_same_status_lso || 0;
                  this.tableData[property].caso_cancelado_dgs         =
                    this.tableData[property].statuses[this.status_ids[4]]?.still_in_same_status_dgs || 0;
                  this.tableData[property].caso_cancelado_percent     =
                      (
                          this.tableData[property].statuses[this.status_ids[4]]?.still_in_same_status || 0
                      ) / this.tableData[property].pago * 100 || 0;
                  this.tableData[property].caso_cancelado_percent_dm  =
                      (
                          this.tableData[property].statuses[this.status_ids[4]]?.still_in_same_status_dm || 0
                      ) / this.tableData[property].pago_dm * 100 || 0;
                  this.tableData[property].caso_cancelado_percent_bdm =
                      (
                          this.tableData[property].statuses[this.status_ids[4]]?.cases_bdm || 0
                      ) / this.tableData[property].pago_bdm * 100 || 0;
                  this.tableData[property].caso_cancelado_percent_lso =
                      (
                          this.tableData[property].statuses[this.status_ids[4]]?.still_in_same_status_lso || 0
                      ) / this.tableData[property].pago_lso * 100 || 0;
                  this.tableData[property].caso_cancelado_percent_dgs =
                    ( this.tableData[property].statuses[this.status_ids[4]]?.still_in_same_status_dgs || 0 )
                  / this.tableData[property].pago_dgs * 100 || 0;

                  this.tableDataSource.push(this.tableData[property]);
                }
              }

              const newFormat = [];
              this.tableDataSource.forEach(obj => {
                //  Create 2 objects from 1
                //  Used for creating 2 table rows for one agent
                const dm  = {...obj};
                dm.type   = 'DM';
                const bdm = {...obj};
                bdm.type  = 'BDM';
                const lso = {...obj};
                lso.type  = 'LSO';

                const dgs = {...obj};
                dgs.type  = 'DGS';

                newFormat.push(lso);
                newFormat.push(dm);
                newFormat.push(bdm);
                newFormat.push(dgs);
              });
              this.dataSource      = new MatTableDataSource(newFormat);
              this.dataSource.sort = this.sort;

              this.dataSourceDmLso      = new MatTableDataSource(this.tableDataSource);
              this.dataSourceDmLso.sort = this.lsoSort;

              this.dataSourceDgs       = new MatTableDataSource(this.tableDataSource);

              this.dataSourceDgs.sort  = this.dgsSort;
            }, err => console.log(err),
        );
  }

  public getTotal(statusId) {
    let total = 0;
    if (this.dataSource) {
      if (statusId === 'all') {
        // Used to count only data from 1st row (since we have 2 data objects for each agent)
        //  This way we ignore data from 2nd row
        this.dataSource.data.forEach(data => {
          if (data.type === 'DM') {
            total += +(data.amount_period || 0);
          }
        });
        return total.toFixed(2);
      } else if (statusId === 'cases') {
        this.dataSource.data.forEach(data => {
          if (data.type === 'DM') {
            total += +(data.case_count || 0);
          }
        });
        return total;
      } else {
        this.dataSource.data.forEach(data => {
          if (data.type === 'DM') {
            total += +(data.statuses[statusId]?.cases || 0);
          }
        });
        return total;
      }
    }
  }

  getTotalDmLso(statusId) {
    let total = 0;
    if (this.dataSourceDmLso) {
      if (statusId === 'cases') {
        this.dataSourceDmLso.data.forEach(data => {
          if (this.typeForm.value === 'lso') {
            total += +(data.case_count_lso || 0);
          }
          if (this.typeForm.value === 'dm') {
            total += +(data.case_count_dm || 0);
          }
          if (this.typeForm.value === 'bdm') {
            total += +(data.case_count_bdm || 0);
          }
          if (this.typeForm.value === 'dgs') {
            total += +(data.case_count_dgs || 0);
          }
        });
        return total;
      } else if (statusId === 'all') {
        this.dataSourceDmLso.data.forEach(data => {
          if (this.typeForm.value === 'lso') {
            total += +(data.amount_period_lso || 0);
          }
          if (this.typeForm.value === 'dm') {
            total += +(data.amount_period_dm || 0);
          }
          if (this.typeForm.value === 'bdm') {
            total += +(data.amount_period_bdm || 0);
          }
          if (this.typeForm.value === 'dgs') {
            total += +(data.amount_period_dgs || 0);
          }
        });
        return total.toFixed(2);
      } else {
        this.dataSourceDmLso.data.forEach(data => {
          if (this.typeForm.value === 'lso') {
            total += +(data.statuses[statusId]?.cases_lso || 0);
          }
          if (this.typeForm.value === 'dm') {
            total += +(data.statuses[statusId]?.cases_dm || 0);
          }
          if (this.typeForm.value === 'bdm') {
            total += +(data.statuses[statusId]?.cases_bdm || 0);
          }
          if (this.typeForm.value === 'dgs') {
            total += +(data.statuses[statusId]?.cases_dgs || 0);
          }
        });
        return total;
      }
    }
  }
}
