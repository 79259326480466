import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';

@Component({
  selector:    'app-case-relation-validation',
  templateUrl: './case-relation-validation.component.html',
  styleUrls:   ['./case-relation-validation.component.scss'],
  providers:   [
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false},
    }],
})
export class CaseRelationValidationComponent implements OnInit {
  public desktopVersion: boolean;


  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
  }

}
