<div class="div justify-content-center">
  <div class="d-flex">
    <h1 class="pr-3">{{'CLIENT-PORTAL.validator-form.payment' | translate}}</h1>
  </div>
</div>

<div class="secured">
  <table class="table table-borderless table-striped client-table">
    <tbody>
    <tr>
      <td>{{'CLIENT-PORTAL.validator-form.payment-date' | translate}}</td>
      <td>{{ debtPaymentPlan?.payment_day }}</td>
    </tr>
    <tr>
      <td>Primera Fase</td>
      <td></td>
    </tr>
    <tr>
      <td>Importe mensual</td>
      <td>{{ debtPaymentPlan?.phase_one_monthly_fee  | number: '1.2-2' }}€</td>
    </tr>
    <tr>
      <td>Duración</td>
      <td>{{ debtPaymentPlan?.phase_one_duration }}</td>
    </tr>
    <tr>
      <td>Segunda Fase</td>
      <td></td>
    </tr>
    <tr>
      <td>Importe mensual</td>
      <td>{{ debtPaymentPlan?.phase_two_monthly_fee  | number: '1.2-2' }}€</td>
    </tr>
    <tr>
      <td>Duración</td>
      <td>{{ debtPaymentPlan?.phase_two_duration }}</td>
    </tr>
    <tr>
      <td>Método de pago</td>
      <td>{{ casePaymentMethod?.name }}</td>
    </tr>
    </tbody>
  </table>
</div>

