import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { SignaturePadModule } from 'ngx-signaturepad';
import { CaseModule } from '../admin/case/case.module';
import { AuthModule } from '../auth/auth.module';
import { SharedClientModule } from './_shared/shared-client.module';
import { AssetsStepComponent } from './case-relation-validation/assets-step/assets-step.component';
import {
  CaseRelationValidationDesktopComponent,
} from './case-relation-validation/case-relation-validation-desktop/case-relation-validation-desktop.component';
import {
  CaseRelationValidationMobileComponent,
} from './case-relation-validation/case-relation-validation-mobile/case-relation-validation-mobile.component';
import { CaseRelationValidationComponent } from './case-relation-validation/case-relation-validation.component';
import { CreditorsStepComponent } from './case-relation-validation/creditors-step/creditors-step.component';
import { DetailsStepComponent } from './case-relation-validation/details-step/details-step.component';
import { EditModalComponent } from './case-relation-validation/details-step/edit-modal/edit-modal.component';
import { ExpenseStepComponent } from './case-relation-validation/expense-step/expense-step.component';
import { IncomeStepComponent } from './case-relation-validation/income-step/income-step.component';
import { NextStepModalComponent } from './case-relation-validation/next-step-modal/next-step-modal.component';
import { OutcomeStepComponent } from './case-relation-validation/outcome-step/outcome-step.component';
import { PaymentStepComponent } from './case-relation-validation/payment-step/payment-step.component';
import { SignatureStepComponent } from './case-relation-validation/signature-step/signature-step.component';
import { SuccessPageComponent } from './case-relation-validation/success-page/success-page.component';
import { VerifyDetailsComponent } from './case-relation-validation/verify-details/verify-details.component';
import { ClientRoutingModule } from './client-routing.module';
import { ContractsSignComponent } from './contracts/contracts-sign/contracts-sign.component';
import { ContractsComponent } from './contracts/contracts.component';
import { ClientDashboardComponent } from './dashboard/client-dashboard.component';
import { DocumentsPreviewComponent } from './dashboard/documents-preview/documents-preview.component';
import { OnboardingPreviewComponent } from './dashboard/onboarding-preview/onboarding-preview.component';
import { PaymentPreviewComponent } from './dashboard/payment-preview/payment-preview.component';
import { ProfilePreviewComponent } from './dashboard/profile-preview/profile-preview.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import {
  SignAssetAndCreditorListComponent,
} from './documents/sign-asset-and-creditor-list/sign-asset-and-creditor-list.component';
import { ESignatureComponent } from './e-signature/e-signature.component';
import { IntegrationModule } from './integration/integration.module';
import { NotaryDownloadComponent } from './notary-download/notary-download.component';
import { PaymentModule } from './payment/payment.module';
import { SignAssetListComponent } from './sign-asset-list/sign-asset-list.component';
import {
  SignDebtCancellationRequestComponent,
} from './sign-debt-cancellation-request/sign-debt-cancellation-request.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import {
  ClientProfileDesktopComponent,
} from './user/client-profile-settings/client-profile-desktop/client-profile-desktop.component';
import {
  ClientProfileMobileComponent,
} from './user/client-profile-settings/client-profile-mobile/client-profile-mobile.component';
import { ClientProfileSettingsComponent } from './user/client-profile-settings/client-profile-settings.component';
import {
  PublicPaymentHandlerWrapperComponent
} from './public-client/payment/public-payment-handler-wrapper/public-payment-handler-wrapper.component';
import {
  PublicPaymentHandlerComponent
} from './public-client/payment/public-payment-handler-wrapper/payment-handler/public-payment-handler.component';
import {
  ClientPaymentOutcomeComponent
} from './public-client/payment/client-payment-outcome/client-payment-outcome.component';
import { ClientPaymentFooterComponent } from './public-client/client-payment-footer/client-payment-footer.component';
import { PublicClientModule } from './public-client/public-client.module';

@NgModule({
  imports:      [
    SharedClientModule,
    SignaturePadModule,
    CaseModule,
    PaymentModule,
    IntegrationModule,
    MatStepperModule,
    MatSidenavModule,
    AuthModule,
    PublicClientModule,
    ClientRoutingModule,
  ],
  exports:      [
    ClientDashboardComponent,
  ],
  declarations: [
    ClientDashboardComponent,
    ClientProfileSettingsComponent,
    OnboardingPreviewComponent,
    DocumentsPreviewComponent,
    PaymentPreviewComponent,
    CaseRelationValidationComponent,
    UploadFilesComponent,
    ContractsComponent,
    ContractsSignComponent,
    ESignatureComponent,
    UnsubscribeComponent,
    NotaryDownloadComponent,
    DetailsStepComponent,
    CreditorsStepComponent,
    AssetsStepComponent,
    IncomeStepComponent,
    ExpenseStepComponent,
    OutcomeStepComponent,
    PaymentStepComponent,
    SignatureStepComponent,
    EditModalComponent,
    NextStepModalComponent,
    SuccessPageComponent,
    SignAssetAndCreditorListComponent,
    SignAssetListComponent,
    VerifyDetailsComponent,
    DocumentUploadComponent,
    CaseRelationValidationDesktopComponent,
    CaseRelationValidationMobileComponent,
    ProfilePreviewComponent,
    ClientProfileDesktopComponent,
    ClientProfileMobileComponent,
    SignDebtCancellationRequestComponent,
  ],
  providers:    [{provide: Window, useValue: window}],
})
export class ClientModule {
}
