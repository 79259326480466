<div class="payment-wrap client-typography" [ngClass]="{'mat-card-fixed-width mt-lg': desktopVersion}" >
  <mat-card [ngClass]="{'payment-is-overdue-warning': isOverdue}" [ngStyle]="{'height': desktopVersion ? '240px' : ''}">
    <div style="justify-content: left">
      <p class="d-flex flex-row align-items-center"
                         [ngStyle]="{'color': isOverdue ? '#b60000' : '#868e96' }">
        <mat-icon [ngStyle]="{'color': isOverdue ? '#b60000' : '' }" aria-hidden="false" fontIcon="work"
                  style="margin-right: 9px">credit_card
        </mat-icon>
        {{'CLIENT.client-dashboard.payments' | translate}}
      </p>
    </div>
    <mat-card-content>
      <div class="row mt-3">
        <div class="col">
          <h4 [ngStyle]="{'color': isOverdue ? '#b60000' : '' }">{{'CLIENT.client-dashboard.payments_due' | translate}}</h4>
          <div  [ngStyle]="{'max-width': !desktopVersion ? '352px' : '','margin': !desktopVersion ? 'auto' : ''}" class="d-flex flex-row">
            <div class="d-flex flex-column mr-4">
              <p>{{'CLIENT.client-dashboard.amount' | translate}}</p>
              <h3>{{amount | number: '1.2-2' }}&euro;</h3>
            </div>
            <div class="d-flex flex-column">
              <p>{{'CLIENT.client-dashboard.due' | translate}}</p>
              <h3>{{dueDate | date: 'd/M/y'}}</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- Temp action -->
      <mat-card-actions>
        <button  class="ml-2 onboarding-payment-btn"
                [ngStyle]="{'color': isOverdue ? '#b60000' : '','border': isOverdue ? '1px solid #b60000' : ' 1px solid #2764AE',
                'background-color': isOverdue ? '#f8e6e6' : '#FFFFFF' ,'margin-top': desktopVersion ? '21px' : ''}"
                [routerLink]="['/client', 'cases', case.uuid, 'payments']">
          {{'CLIENT.client-dashboard.make-a-payment' | translate}}
        </button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</div>
