import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AffiliateCaseListFilter } from '../../../../../_base-shared/models/Case/AffiliateCaseListFilter';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { CaseService } from '../../admin/case/case.service';
import {
  AffiliateCaseListFiltersComponent,
} from '../affiliate-case-list-filters/affiliate-case-list-filters.component';
import { AffiliateService } from '../../admin/affiliate/affiliate.service';

@Component({
  selector:    'app-affiliate-case-list',
  templateUrl: './affiliate-case-list.component.html',
  styles:      [],
})
export class AffiliateCaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AffiliateCaseListFiltersComponent) affiliateCaseListFiltersComponent: AffiliateCaseListFiltersComponent;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public componentType: 'admin' | 'affiliate';
  public affiliateIds: Array<number>;
  public authUser: User;
  public isLoadingCases                      = 0;
  public pageSelected                        = false;
  public displayedColumns: Array<string>     = [
    'df_ref',
    'first_name',
    'last_name',
    'email',
    'product',
    'status',
    'conversion_status',
    'created_date',
    'affiliate_name',
    'conversion_date',
    'earnings'
  ];
  public initFilters                         = false;
  public filtersReady                        = false;
  public affiliateCaseListFilters: AffiliateCaseListFilter;
  public totalResults: number;
  public totalPages: number;
  public dataSource: MatTableDataSource<Case>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_date' };
  private subscriptions: Array<Subscription> = [];
  private totalCases: number;

  constructor(private route: ActivatedRoute,
              private translate: TranslateService,
              public globalEventService: MainGlobalEventService,
              private caseService: CaseService,
              private affiliateService: AffiliateService,
              private changeDetector: ChangeDetectorRef,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globalEventService.authUser$.subscribe(user => {
        this.authUser      = user;
        this.componentType = this.authUser.role.slug === 'affiliate' ? 'affiliate' : 'admin';
        if (this.componentType === 'admin' && this.authUser.role.slug === 'super-admin') {
          this.route.paramMap.subscribe(params => {
            this.affiliateIds = [+params.get('id')];
            this.initFilters  = true;
          });
        } else {
          this.affiliateIds = this.authUser.affiliates ? this.authUser.affiliates.map(a => a.id) : [];
          this.initFilters  = true;
        }
      }),
    );
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.paginatorConfig        = this.defaultPaginatorConfig;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public changeComponentType(type: 'affiliate' | 'admin') {
    this.componentType = type;
  }

  private fetchCases(): void {
    this.dataSource = new MatTableDataSource<Case>([]);
    this.isLoadingCases++;

    this.affiliateCaseListFilters.start_date = this.affiliateCaseListFilters.start_date
    && this.affiliateCaseListFilters.start_date instanceof Date ?
      this.affiliateCaseListFilters.start_date.toISOString() :
      this.affiliateCaseListFilters.start_date;

    this.affiliateCaseListFilters.end_date = this.affiliateCaseListFilters.end_date
    && this.affiliateCaseListFilters.end_date instanceof Date ?
      this.affiliateCaseListFilters.end_date.toISOString() :
      this.affiliateCaseListFilters.end_date;
    const requestData                      = MainBaseApiService.convertFiltersForRequest(this.affiliateCaseListFilters, 'get');

    this.subscriptions.push(
      this.caseService.getAffiliateCases(requestData).pipe(finalize(() => this.isLoadingCases--)).subscribe(
        result => {
          this.dataSource             = new MatTableDataSource<Case>(result.data);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
      ),
    );
  }

  getConversionStatus(affiliateCase: Case): string {
    if (affiliateCase.lead.conversion_date) {
      return 'Converted';
    }

    return 'Unconverted';
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.affiliateCaseListFilters.page     = this.paginatorConfig.pageIndex + 1;
    this.affiliateCaseListFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchCases();
  }

  public handleFiltersState($event: boolean): void {
    this.filtersReady = $event;
  }

  public handleFilters($event: AffiliateCaseListFilter): void {
    this.affiliateCaseListFilters = $event;
    this.resetPagination();
    this.fetchCases();
  }

  private resetPagination(): void {
    this.paginatorConfig                   = this.defaultPaginatorConfig;
    this.affiliateCaseListFilters.per_page = this.paginatorConfig.pageSize;
    this.affiliateCaseListFilters.page     = this.paginatorConfig.pageIndex;
  }

  exportRecords(affiliateCaseListFilters: AffiliateCaseListFilter): void {
    affiliateCaseListFilters.expected_count = this.totalResults;
    affiliateCaseListFilters.select_all     = true;
    const requestFilter                     = MainBaseApiService.convertFiltersForRequest(affiliateCaseListFilters, 'get');

    this.affiliateService.getExport(requestFilter)
      .subscribe(
        result => {
          this.toastr.success(this.translate.instant('AFFILIATES.affiliate_export_success'));
        },
        error => console.log(error),
      );
  }
}
