import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { UserCaseDataValidation } from '../../../../../_base-shared/models/Case/UserCaseDataValidation';
import { UserCaseRelationValidation } from '../../../../../_base-shared/models/Case/UserCaseRelationValidation';
import { User } from '../../../../../_base-shared/models/User/User';
import { AnalyticsService } from '../../../../../_base-shared/services/analytics.service';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { ScriptLoaderService } from '../../../../../_base-shared/services/script-loader.service';
import { ClientService } from '../client.service';

@Component({
  selector:    'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls:   ['./client-dashboard.component.scss'],
})
export class ClientDashboardComponent implements OnInit {
  public isLoadingOnboarding  = 0;
  public authUser: User;
  public clientCases: Array<Case>;
  public case: Case;
  public caseRelationValidation: UserCaseRelationValidation;
  public onboardingEnabled: boolean;
  public companyName          = environment.COMPANY_NAME;
  public isPaymentPlanPaid: boolean;
  public caseDataValidation?: UserCaseDataValidation;
  public isOnboardingFinished = false;
  public desktopVersion: boolean;
  public bannerImage: string;

  constructor(private globalEventsService: MainGlobalEventService,
              private clientService: ClientService,
              private breakpointObserver: BreakpointObserver,
              private analyticsService: AnalyticsService,
              private scriptLoaderService: ScriptLoaderService) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
    this.setBannerImage();
    this.onboardingEnabled = true;
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.isLoadingOnboarding++;
      this.clientService.indexCases(this.authUser.uuid).pipe(finalize(() => this.isLoadingOnboarding--)).subscribe(
        result => {
          this.clientCases = result.data;
          // Note: if client only has 1 case, select it automatically
          if (this.clientCases.length === 1) {
            this.selectCase(this.authUser.uuid, this.clientCases[0].uuid);
          }
          // TODO: If client has more than 1 case, show a list of cases in view and let the client set selected case from list
        },
      );
    });

    this.analyticsService.init();
  }

  public selectCase(userUuid: string, caseUuid: string) {
    const loadRelations = ['relation_validations', 'client.address', 'partner.address'];
    this.isLoadingOnboarding++;
    this.clientService.showUserCase(userUuid, caseUuid, loadRelations).pipe(finalize(() => this.isLoadingOnboarding--))
      .subscribe(
        result => {
          this.case                   = result.data;
          this.caseRelationValidation = this.case.relation_validations.find(
            relationValidation => relationValidation.user_id === this.authUser.id,
          );
          this.isLoadingOnboarding++;
          this.clientService.indexCaseDataValidation(caseUuid, userUuid)
            .pipe(finalize(() => this.isLoadingOnboarding--))
            .subscribe(res => {
              this.caseDataValidation = res.data;
              this.globalEventsService.setClientSelectedCase(this.case);
            });
        },
      );
  }

  public hidePaymentPlan($event) {
    this.isPaymentPlanPaid = $event;
  }

  public setCaseStatus($event) {
    this.isOnboardingFinished = $event;
  }

  setBannerImage() {
    const bannerImages = [
      'https://www.deudafix.es/portal-img/img1.jpeg',
      'https://www.deudafix.es/portal-img/img2.jpeg',
      'https://www.deudafix.es/portal-img/img3.jpeg',
    ];

    this.bannerImage = bannerImages[Math.floor(Math.random() * bannerImages.length)];
  }
}
