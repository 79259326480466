import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../../../../../_base-shared/guards/permission.guard';
import { TaskTemplateEditorComponent } from './task-template-editor/task-template-editor.component';
import { TaskTemplateListComponent } from './task-template-list/task-template-list.component';

const routes: Routes = [
  {
    path:     'task-templates',
    children: [
      {path: '', canActivate: [PermissionGuard], component: TaskTemplateListComponent},
      {path: 'create', canActivate: [PermissionGuard], component: TaskTemplateEditorComponent, data: {type: 'create'}},
      {path: ':id/edit', canActivate: [PermissionGuard], component: TaskTemplateEditorComponent, data: {type: 'edit'}},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaskTemplateRoutingModule {
}
