import { Component, OnInit } from '@angular/core';
import { StatusService } from '../../../status/status.service';
import { Status } from '../../../../../../../_base-shared/models/Status/Status';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SystemEventService } from '../system-event.service';
import { SystemEventConfiguration } from '../../../../../../../_base-shared/models/SystemEventConfiguration';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector   : 'app-system-event-editor',
  templateUrl: './system-event-editor.component.html',
  styleUrls  : ['./system-event-editor.component.scss']
})
export class SystemEventEditorComponent implements OnInit {
  public statuses         = [];
  public isLoading        = 0;
  public isSubmitting        = 0;
  public configuration: SystemEventConfiguration;
  public form: UntypedFormGroup;

  constructor(
    private statusService: StatusService,
    private systemEventService: SystemEventService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.getStatuses();
    this.getConfiguration();
  }

  getStatuses() {
    this.isLoading++;
    const data = {
      all: 1,
    };
    this.statusService.index(data)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.statuses = res.data.map(status => (
            {
              id      : status.id,
              name    : status.name,
              itemName: status.name
            }
          ));
        },
        err => {
        }
      );
  }

  getConfiguration() {
    this.isLoading++;
    this.systemEventService.get()
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.configuration = {
            default_status: res.data.default_status?.status,
            send_login_details: res.data.send_login_details.status,
            sign_contract: res.data.sign_contract?.status,
            send_contract: res.data.send_contract?.status
          };
          this.buildForm();
        },
        err => {
        }
      );
  }

  buildForm() {
    this.isLoading++;
    this.form = this.fb.group({
      default_status      : [[
        {
          id      : this.configuration.default_status?.id,
          name    : this.configuration.default_status?.name,
          itemName: this.configuration.default_status?.name
        }], null],
      send_login_details: [[
        {
          id      : this.configuration.send_login_details.id,
          name    : this.configuration.send_login_details.name,
          itemName: this.configuration.send_login_details.name
        }], null],
      sign_contract     : [[
        {
          id      : this.configuration.sign_contract.id,
          name    : this.configuration.sign_contract.name,
          itemName: this.configuration.sign_contract.name
        }], null],
      send_contract     : [[
        {
          id      : this.configuration.send_contract?.id,
          name    : this.configuration.send_contract?.name,
          itemName: this.configuration.send_contract?.name
        }], null],
    });
    this.isLoading--;
  }

  submit() {
    this.isSubmitting++;
    console.log(this.form.value.default_status);
    const data = {
      default_status: this.form.value.default_status,
      // send_login_details: this.form.value.send_login_details[0].id,
      sign_contract: this.form.value.sign_contract,
      send_contract: this.form.value.send_contract,
    };
    this.systemEventService.store(data)
      .pipe(finalize(() => this.isSubmitting--))
      .subscribe(
        res => {
          this.toastr.success(this.translateService.instant('CONFIG.system-event.config-saved'));
          // this.router.navigateByUrl('/cases');
        },
        err => {
          this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
        }
      );
  }
}
