<div *ngIf="isLoading" class="row">
    <div class="col-12 pt-5 text-center">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
</div>
<form *ngIf="form" [formGroup]="form" [hidden]="isLoading" (ngSubmit)="submit(form)" class="form">
    <div class="card shadow mb-5">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <!-- First Name -->
                    <app-input formControlName="first_name" [label]="'USERS.editor.first_name' | translate"
                        [extraLabel]="true" [submitted]="formSubmitted"
                    >
                    </app-input>
                    <!-- Email -->
                    <app-input formControlName="email" [label]="'USERS.model.email' | translate"
                               [extraLabel]="true" [submitted]="formSubmitted">
                    </app-input>
                    <!-- Notes -->
                    <app-input formControlName="note" [label]="'CASES.single.notes' | translate"
                               [extraLabel]="true" [submitted]="formSubmitted">
                    </app-input>
                </div>
                <div class="col-md-6">
                    <!-- Last Name -->
                    <app-input formControlName="last_name" [label]="'USERS.editor.last_name' | translate"
                        [extraLabel]="true" [submitted]="formSubmitted">
                    </app-input>
                    <!-- Mobile -->
                    <app-input type="text" formControlName="mobile" [label]="'USERS.model.phone' | translate"
                               [extraLabel]="true" [submitted]="formSubmitted">
                    </app-input>
                </div>
                <div class="col-md-12">
                    <div class="d-flex mt-3 justify-content-start">
                        <app-spinner-btn [loading]="submitting" [name]="'SHARED.submit' | translate" type="submit"></app-spinner-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
