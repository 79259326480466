import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { PaymentStatus } from '../../../../../../_base-shared/models/Status/PaymentStatus';
import { UploadService } from '../../../../../../_base-shared/services/upload.service';
import { NotificationMessageComponent } from '../../notification/notification-message/notification-message.component';
import { StatusService } from '../../status/status.service';
import { TaskEditorComponent } from '../../task/task-editor/task-editor.component';
import { CaseService } from '../case.service';
import { QuickNoteComponent } from './quick-note/quick-note.component';

@Component({
  selector:      'app-case-detail',
  templateUrl:   './case-detail.component.html',
  styleUrls:     ['./case-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CaseDetailComponent implements OnInit, OnDestroy {
  public case: Case;
  public isLoading              = 0;
  public quickNotesDialogOpened = false;
  public unapprovedCreditors    = false;
  public showNewDocumentsTab: boolean;
  public quillModules           = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };

  private subscriptions: Array<Subscription> = [];

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private statusService: StatusService,
              private uploadService: UploadService) {
    const appEnv             = environment.APP_ENV;
    this.showNewDocumentsTab = appEnv === 'local' || appEnv === 'staging';
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => this.fetchCase(+params.get('id')));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'q') { //  Open dialog on 'ctrl' + 'q'
      if (!this.quickNotesDialogOpened) { //  Check if dialog is not already opened
        const dialog = this.dialog.open(QuickNoteComponent, {
          width: '50%',
          data:  {case: this.case},
        });
        dialog.afterOpened().subscribe(result => {
          this.quickNotesDialogOpened = true;
        });
        dialog.afterClosed().subscribe(result => {
          this.quickNotesDialogOpened = false;
        });
      }
    }
  }

  private fetchCase(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.get(caseId, ['client', 'payment_status', 'secured_creditors', 'unsecured_creditors'])
            .pipe(finalize(() => this.isLoading--))
            .subscribe(result => {
              this.case = result.data;
              this.checkForCaseAlerts(this.case);
              // this.checkForDNI();
              // this.checkSignatures();
              this.checkCreditors();
            }),
    );
  }

  private checkForCaseAlerts(clientCase: Case) {
    if (clientCase.client.vulnerability) {
      console.log('case has vulneratiblity');
      const vulnerabilityReason = this.translate.instant(
          'USERS.model.vulnerability.options.' + clientCase.client.vulnerability,
      );

      Swal.fire({
        text:               this.translate.instant('CASES.single.alerts.vulnerability',
            {reason: vulnerabilityReason}),
        icon:               'warning',
        showCancelButton:   false,
        confirmButtonText:  'OK',
        confirmButtonColor: '#4267b2',
      }).then(res => {
        if (res.isConfirmed) {
          this.checkForPaymentStatusAlerts(clientCase.payment_status);
        }
      });
    } else {
      this.checkForPaymentStatusAlerts(clientCase.payment_status);
    }
  }

  private checkForPaymentStatusAlerts(paymentStatus: PaymentStatus) {
    if (['Impago 1', 'Impago 2', 'Reclamar Pago'].includes(paymentStatus?.name)) {
      Swal.fire({
        text:               this.translate.instant('CASES.single.alerts.impago',
            {status: paymentStatus.name}),
        icon:               'warning',
        showCancelButton:   false,
        confirmButtonText:  'OK',
        confirmButtonColor: '#4267b2',
      }).then(res => {
        // if (res.isConfirmed) {
        // }
      });
    }
  }

  private checkCreditors() {
    this.case.unsecured_creditors.forEach(creditor => {
      if (!creditor.approved) {
        this.unapprovedCreditors = true;
      }

    });
    this.case.secured_creditors.forEach(creditor => {
      if (!creditor.approved) {
        this.unapprovedCreditors = true;
      }
    });
  }

  public openNotificationModal($event, caseId: number, channel: string) {
    $event.preventDefault();
    const channels = [];
    if (channel === 'all' || channel === 'email') {
      channels.push('email');
    }
    if (channel === 'all' || channel === 'sms') {
      channels.push('sms');
    }

    const data      = {
      templatableType: 'case',
      channels,
      filters:         {cases: [caseId]},
    };
    const dialogRef = this.dialog.open(NotificationMessageComponent, {
      width:       '50%',
      minHeight:   '230px',
      hasBackdrop: false,
      data,
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public addNewTask(taskCase: Case) {
    const dialogRef = this.dialog.open(TaskEditorComponent, {
      width:     '40%',
      autoFocus: false,
      data:      {
        case:       taskCase,
        editorType: 'create',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      // if needed
    });
  }

}
