import * as moment from 'moment';
import { environment } from '../../main/src/environments/environment';
import { CaseDistribution } from '../models/Distribution/CaseDistribution';

export class DistributionAmountCalculatorService {
  public vatPercentage = environment.VAT_PERCENTAGE;

  public calculateContractAmount(caseDistribution: CaseDistribution): number {
    let totalContractAmount = 0;
    caseDistribution.case.terms.map(term => {
      totalContractAmount += this.reduceVat(term.amount);
    });

    return totalContractAmount;
  }

  public calculateAmountPaid(caseDistribution: CaseDistribution,
                             submittedAt: Date | string,
                             beforeSubmit: boolean): number {
    let amountPaid = 0;

    if (caseDistribution.case.payments?.length && submittedAt) {
      caseDistribution.case.payments.forEach(payment => {
        if (beforeSubmit && moment(payment.created_at).isBefore(moment(submittedAt), 'seconds')) {
          amountPaid += this.reduceVat(payment.amount);
        }
        if (!beforeSubmit && moment(payment.created_at).isAfter(moment(submittedAt), 'seconds')) {
          amountPaid += this.reduceVat(payment.amount);
        }
      });
    }

    return amountPaid;
  }

  public reduceVat(amount: number): number {
    return +((amount / ((100 + this.vatPercentage) / 100)).toFixed(2));
  }
}
