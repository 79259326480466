import { Component, OnInit} from '@angular/core';

@Component({
  selector:    'app-verify-details',
  templateUrl: './verify-details.component.html',
  styleUrls:   ['./verify-details.component.scss'],
})
export class VerifyDetailsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
