import { Injectable } from '@angular/core';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { Observable } from 'rxjs';
import { LaravelResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SystemEventService extends MainBaseApiService{

  get(): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(`${this.apiUrl}/system-events`)
            .pipe(catchError(response => this.handleError(response)));
  }

  store(data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(`${this.apiUrl}/system-events`, data)
            .pipe(catchError(response => this.handleError(response)));
  }
}
