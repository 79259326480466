import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../contracts/laravel-response.interface';
import { PaymentMethod } from '../models/Payment/PaymentMethod';
import { MainBaseApiService } from '../../main/src/app/_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService extends MainBaseApiService {
  public index(data: any = {}, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<Array<PaymentMethod>>>(
        this.apiUrl + '/payment-methods', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateCasePaymentMethod(caseId: number, data = {}) {
    return this.http.patch<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/payment-method', data)
        .pipe(catchError(response => this.handleError(response)));
  }
}
