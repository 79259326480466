<nav *ngIf="showNav && !showPaymentsNav" class="navbar navbar-expand-lg navigation-bar navbar-dark"
     [class.client-nav]="!authUser || authUser?.role_id === 4"
     [class.desktop]="desktopVersion" [class.mobile]="!desktopVersion">
  <div
    [ngClass]="{'d-flex flex-row w-100 justify-content-between align-items-end': !authUser || ( authUser?.role_id === 4) && !desktopVersion}">
    <a class="navbar-brand" href="#" style="width: 125px" *ngIf="!navV2 || desktopVersion">
      <a href='http://www.epifinance.es'>
        <img *ngIf="!authUser || (authUser && authUser.role_id === 4)" class="img-fluid"
             src="assets/img/logos/deuda-logo.png" alt="Deudafix Logo"
             style="width: 150px;margin-left: 5rem;margin-top: 1rem;"
             [ngStyle]="{'margin-left': desktopVersion ? '150px' : '-5px'}">
      </a>
      <img *ngIf="authUser && authUser.role_id !== 4" class="img-fluid"
           src="assets/img/logos/novosys_white_transparent.png" alt="Deudafix Logo" style="width: 150px">
    </a>
    <div *ngIf="authUser?.role_id === 4 && !desktopVersion && showLiveChatButton">
      <a type="button" class="btn btn-link mr-2" style="color:#2764AE;font-size: 18px;border:1px solid #2764AE" href="https://wa.me/34960136099"><i
        class="far fa-comment-dots" style="color:#2764AE"></i>&nbsp;&nbsp;&nbsp;Whatsapp</a>
    </div>
    <!--    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"-->
    <!--            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">-->
    <!--      <span class="navbar-toggler-icon"></span>-->
    <!--    </button>-->
  </div>
  <app-staff-navigation [authUser]="authUser"></app-staff-navigation>
  <app-non-staff-navigation [authUser]="authUser" [selectedCase]="selectedCase" [desktopNav]=desktopVersion>
  </app-non-staff-navigation>
  <div class="full-width">
    <div class="form-inline d-flex justify-content-end">
      <app-header *ngIf="authUser && authUser.role_id !== 4" [notifications]="notifications"></app-header>
    </div>
  </div>
</nav>
<!--Nav for client verify-->
<div class="dashboard-client d-flex flex-row justify-content-start align-items-center ml-2 flex-row mt-3"
     *ngIf="navV2 && !desktopVersion">
  <a (click)="changeNav()" class="d-flex flex-row justify-content-start align-items-center"
     routerLink="/client/dashboard" style="color:#2764AE;font-size: 18px; font-family: 'Inter'">
    <mat-icon>keyboard_arrow_left</mat-icon>
    {{'SHARED.home' | translate}}
  </a>
  <img class="img-fluid" src="assets/img/logos/deuda-logo.png" alt="Deudafix Logo"
       style="width: 94px;margin-left: 18%">
</div>

<!--Client Lead Nav-->
<nav
  class="nav-container col-12 navbar navbar-expand-lg navbar-light display-flex flex-row justify-content-lg-center justify-content-between align-items-stretch"
  *ngIf="showPaymentsNav">
  <div class="col-7 d-flex justify-content-center">
    <div class="navbar-brand" style="width: 654px">
      <a href="https://novomoney.es/">
        <img src="assets/img/logos/novo1.png" alt="Novo Money Logo"
             style="width: 200px; padding-bottom: 5px ; padding-right: 0px; padding-top: 10px">
      </a>
    </div>
  </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation"
          (click)="openMobileNav()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="navbar-collapse navbarToggler" *ngIf="isMobileNav">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 d-flex flex-row justify-content-start w-100 mobile-nav-public">
      <a class="nav-item nav-label nav-item-padding" href="https://www.novomoney.es/">Inicio</a>
      <a class="nav-item nav-label nav-item-padding" href="https://www.novomoney.es/servicios">Por Qué Nosotros</a>
      <a class="nav-item nav-label nav-item-padding nav-active" href="https://www.novomoney.es/unirse">Solicitar</a>
      <a class="nav-item nav-label nav-item-padding" href="https://www.novomoney.es/contactanos">Contacto</a>
    </ul>
  </div>
</nav>
