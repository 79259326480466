import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CaseService } from '../../../admin/case/case.service';
import { CreditorService } from '../../../admin/creditor/creditor.service';

@Component({
  selector:    'app-change-creditor-status',
  templateUrl: './change-creditor-status.component.html',
  styleUrls:   ['./change-creditor-status.component.scss'],
})
export class ChangeCreditorStatusComponent implements OnInit {
  public type: 'public_debts' | 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors';
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading  = 0;

  constructor(public dialogRef: MatDialogRef<ChangeCreditorStatusComponent>,
              private fb: UntypedFormBuilder,
              private toast: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private creditorService: CreditorService,
              public translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.type = this.data.type;
    console.log(this.type);
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      status: ['mandate_sent', null],
    });

    this.formActive = true;
  }

  closeDialog(value = null) {
    this.dialogRef.close(value);
  }

  submit() {
    if (this.form.valid) {
      this.isLoading++;
      const data = {};
      if (!this.data.change_all) { // Change all is used when changing status in drafting tab
        // @ts-ignore
        data.response_received = this.form.value.status;
        // @ts-ignore
        data.selected_ids      = this.data.ids;
      }

      if (this.type === 'public_debts') {
        this.creditorService.updateCasePublicDebt(this.data.case.id, this.data.ids[0], data)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.change-status-success'));
              this.closeDialog(this.form.value.status);
            },
            error => {
              if (error.error?.data?.message) {
                this.toast.error(error.error.data.message);
              } else {
                this.toast.error(this.translate.instant('CASES.details.change-status-error'));
              }
            });
      } else {
        if (this.data.change_all) {
          // @ts-ignore
          data.change_all_creditors = 1;
          // @ts-ignore
          data.drafting_status      = this.form.value.status;
        }
        this.creditorService.updateCaseCreditor(this.data.ids[0], data)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.change-status-success'));
              this.closeDialog(this.form.value.status);
            },
            error => {
              if (error.error?.data?.message) {
                this.toast.error(error.error.data.message);
              } else {
                this.toast.error(this.translate.instant('CASES.details.change-status-error'));
              }
            });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
}
