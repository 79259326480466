import { Component, Inject, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from '../../../admin/case/case.service';
import { MainBaseApiService } from '../../services/main-base-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {VerifySignatureComponent} from '../../../_shared/components/verify-signature/verify-signature.component';
import {LaravelResponse} from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseContract } from '../../../../../../_base-shared/models/CaseDocument/CaseContract';
import { DocumentType } from '../../../../../../_base-shared/models/DocumentType';
import { User } from '../../../../../../_base-shared/models/User/User';
import { SafePipe } from '../../../../../../_base-shared/pipes/safe.pipe';
import { DocumentTypeService } from '../../../../../../_base-shared/services/document-type.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseDocumentService } from '../../../admin/case/case-document.service';
import { DebtCancellationModel } from "../../../../../../_base-shared/models/Document/DebtCancellationModel";
import { saveAs } from 'file-saver';

@Component({
  selector:    'show-payment-creditor-documents',
  templateUrl: './show-payment-creditor-documents.component.html',
  styleUrls:   ['./show-payment-creditor-documents.component.scss'],
})
export class showPaymentCreditorDocumentsModalComponent implements OnInit {

  @Output() selectedFileEvent                         = new EventEmitter<object>();

  public form: UntypedFormGroup;
  public formActive = false;
  public lang                            = 'es';
  public documentType: DocumentType[];
  public authUser: User;
  public case: Case;
  public legalDocumentType      = [];
  public courtDocumentType      = [];
  public userDocuments                   = [];
  public creditor: number = 0;
  public storageUrl                 = environment.STORAGE_URL + '/';

  public fileUrl: string;
  public fileType: string;
  public isSticky = false;

  constructor(private fb: UntypedFormBuilder,
              public translate: TranslateService,
              private caseService: CaseService,
              public dialogRef: MatDialogRef<showPaymentCreditorDocumentsModalComponent>,
              private route: ActivatedRoute,
              private documentTypeService: DocumentTypeService,
              private toastr: ToastrService,
              private cookieService: CookieService,
              private globalEventsService: MainGlobalEventService,
              private sanitized: DomSanitizer,
              private caseDocumentService: CaseDocumentService,
              private safePipe: SafePipe,
              public translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.lang = this.cookieService.get('lang');
    this.translate.onLangChange.subscribe(next => {
      this.documentTypeService.get().subscribe(result => {
        this.documentType = result;
        
        const customFiles = this.case.file_requests.filter(file => file.custom);
        this.documentType = this.documentType.concat(customFiles);
      });
    });

    const caseId = this.data.caseId;
    this.creditor = this.data.creditor;
    this.caseService.get(caseId, ['product'])
      //.pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
      //this.isLoading++;
      this.caseDocumentService.indexCaseDocuments(caseId)
        //.pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
          this.case = res.data;

          this.buildForm();
          this.userDocuments               = this.case.client_files;
          
          this.documentTypeService.get().subscribe(r => {
            this.documentType = r;
            //  Add custom documents to docTypes
            const customFiles = this.case.file_requests.filter(file => file.custom);
            this.documentType = this.documentType.concat(customFiles);
          });

          this.documentTypeService.getLegalDocumentTypes().subscribe(r => {
            this.legalDocumentType = r;
          });

          this.documentTypeService.getCourtDocumentTypes().subscribe(r => {
            this.courtDocumentType = r;
          });
        });
    });
  }

  private buildForm() {
   
    this.formActive        = true;
  }

  downloadImage(location: string, name: string) {
    const link = document.createElement('a');
    link.href = location;
    link.download = name;
    link.click();
  }

  triggerDownload(filename: string, location: string) {
    const anchor = document.createElement('a');
    anchor.href = `http://api.epifinance.local:8000/storage/${location}`;
    anchor.download = filename;
    anchor.click();
  }

  getFileType(extension: string): string {
    switch (extension.toLowerCase()) {
      case 'pdf':
        return 'application/pdf';
      case 'txt':
        return 'text/plain';
      // Add more cases for other file types if needed
      default:
        return 'application/octet-stream';
    }
  }
  
  isImage(extension: string): boolean {
    return ['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase());
  }
  
  isPdf(extension: string): boolean {
    return extension.toLowerCase() === 'pdf';
  }
  
  isText(extension: string): boolean {
    return extension.toLowerCase() === 'txt';
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  hasMatchingDocuments(): boolean {
    return this.userDocuments.length > 0 && this.userDocuments.some(doc => doc.creditor_id === this.creditor);
  }

  isMatchingDocument(file: any): boolean {
    return (file.creditor_id === this.creditor);
  }

  downloadFile($event: MouseEvent, document: any) {
    saveAs(this.storageUrl + document.location, document.name + '.' + document.extension);
  }

  downloadPdfFile($event: MouseEvent, document: any) {
    saveAs(this.storageUrl + document.pdf_location, document.name + '.pdf');
  }

  selectFile(e: Event, url: string, type: string) {
    e.preventDefault();
    this.fileUrl  = this.safePipe.transform(url + '?output=embed', 'resourceUrl') as string;
    this.fileType = type?.includes('image') ? 'image' : 'object';
  }

}
