import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Case} from '../../../../../../_base-shared/models/Case/Case';
import {User} from '../../../../../../_base-shared/models/User/User';
import {ClientService} from '../../client.service';

@Component({
  selector: 'app-payment-preview',
  templateUrl: './payment-preview.component.html',
  styleUrls: ['./payment-preview.component.scss']
})
export class PaymentPreviewComponent implements OnInit {
  @Input() case: Case;
  @Input() user: User;
  @Output() paymentPlanPaid = new EventEmitter<boolean>(false);

  public amount: number;
  public due: number;
  public isOverdue: boolean;
  public desktopVersion: boolean;
  public dueDate: Date;

  constructor(private clientService: ClientService, private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([, Breakpoints.XLarge, Breakpoints.Large]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
    this.clientService.paymentAmount(this.user.uuid, this.case.uuid)
      .subscribe(res => {
        /*Data will be null if payment plan is paid*/
        if (res.data === null){
          this.paymentPlanPaid.emit(true);
          return;
        }
        this.amount = res.data.installment.amount - res.data.installment.amount_paid;
        const termDate = new Date(res.data.installment.term_date);
        const currentDate = new Date();
        this.dueDate = termDate;
        this.isOverdue = termDate.getTime() < currentDate.getTime();
        this.due = Math.abs(Math.floor((termDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)));
      });
  }
}
