<div>
  <div class="card-body">
    <h1 style="color: #355270 !important;font-size: 24px;">Gracias por verificar tus datos.</h1>
    <p>En caso de que algún dato no fuera correcto, te llamaremos lo antes posible para actualizarlo.<br>Alternativamente,
      si lo prefieres, puedes contactarnos por el live chat.</p>
    <div class="d-flex">
      <div style="min-width: 125px" class="d-flex justify-content-center align-items-center">
        <a type="button" class="btn btn-link mr-2" style="color:#2764AE;font-size: 18px;border:1px solid #2764AE" href="https://wa.me/34960136099"><i
          class="far fa-comment-dots" style="color:#2764AE"></i>&nbsp;&nbsp;&nbsp;Whatsapp</a>
      </div>
      <div style="min-width: 125px" class="d-flex align-items-center">
        <button class="btn client-button-correct" routerLink="/">
          Volver al inicio
        </button>
      </div>
    </div>
  </div>
</div>
