<div class="flex-lg mb-2-lg">
  <div class="flex-column-lg upload-doc-heading">
    <span class="text-muted d-sm-none d-block-lg">Docs</span>
    <h2 class="mx-3 margin-4-lg">{{'CLIENT.documents.upload_documents' | translate}}</h2>
  </div>
  <div class="document-uploaded-container d-flex-center-lg d-sm-none" style="margin-right: 99px;">
    <span>{{uploadedDocs}}/{{requestedDocs}} {{'CLIENT.documents.uploaded' | translate}}</span>
  </div>
</div>
<div class="d-flex document-uploaded-container mb-5 align-items-center py-1">
  <p class="text-muted mx-3"
     style="margin-bottom: 0px !important;">{{'CLIENT.documents.documents_uploaded' | translate}}:</p>
  <span><b>{{uploadedDocs}}/{{requestedDocs}}</b></span>
</div>
<div class="container-fluid padding-lg document-upload">
  <div class="row">
    <div class="col-md-8">
      <div>
        <div>
          <mat-accordion multi>
            <mat-expansion-panel class="bg-light m-1 mb-3 {{file.documentClass}}"
                                 *ngFor="let file of requestedFilesArray">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon *ngIf="file.documentClass === 'accepted-document'" style="color: #479D84" class="mr-2">
                    done
                  </mat-icon>
                  <p class="m-0" style="white-space: pre;">
                    {{file.label ? file.label : file.name}}
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <p class="text-muted">{{'CLIENT.documents.please_upload' | translate}}</p>
                <div class="uploaded-files">
                  <div class="uploaded-file d-flex mb-2 justify-content-between"
                       *ngFor="let uploadedFile of this.uploadedFiles[file.name]">
                    <a href="{{storageUrl + uploadedFile.location}}" target="_blank" style="color: #0a6ebd">
                      {{uploadedFile.name}}
                    </a>
                    <div class="d-flex flex-row justify-content-center align-items-baseline">
                      <p style="color: red !important;"
                         *ngIf="uploadedFile.status === 'declined'">{{'CASES.single.documents.declined_document' | translate}}
                      </p>
                      <i style="color: #0a6ebd" (click)="onRemove($event, uploadedFile)" class="fas fa-trash-alt ml-1"
                         *ngIf="uploadedFile.status !== 'accepted'"></i>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                <!--  <button *ngIf="file.name !== 'dni'" type="button" class="btn btn-outlined-blue mr-2"
                          (click)="fileInput.click()">
                    <span>{{'CLIENT.documents.more_files' | translate}}</span>
                    <input #fileInput type="file" multiple="true" (change)="onFileInput($event, file, 'user', 'front')"
                           style="display:none;"/>
                  </button>-->
                  <button type="button" class="btn btn-outlined-blue mr-2" (click)="fileInput.click()"
                          *ngIf="file.name !== 'dni'">
                    <span>{{'CLIENT.documents.upload_files' | translate}}</span>
                    <input #fileInput type="file" (change)="onFileInput($event, file, 'user', 'front')"
                           style="display:none;"/>
                  </button>
                  <button type="button" class="btn btn-outlined-blue mr-2" (click)="fileInputFront.click()"
                          *ngIf="file.name === 'dni' && showDniFront">
                    <span>{{'CLIENT.documents.upload_front' | translate}}</span>
                    <input #fileInputFront type="file" (change)="onFileInput($event, file, 'user', 'front')"
                           style="display:none;"/>
                  </button>
                  <button type="button" class="btn btn-outlined-blue mr-2" (click)="fileInputBack.click()"
                          *ngIf="file.name === 'dni' && showDniBack">
                    <span>{{'CLIENT.documents.upload_back' | translate}}</span>
                    <input #fileInputBack type="file" multiple="true"
                           (change)="onFileInput($event, file, 'user', 'back')"
                           style="display:none;"/>
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div *ngIf="creditorDocsVisible">
        <div>
          <mat-accordion multi>
            <mat-expansion-panel class="bg-light m-1 mb-3" *ngFor="let file of creditorsRequiredFilesArray">
              <mat-expansion-panel-header>
                <mat-panel-title *ngIf="!file.public_organisation">
                  <p style="margin-bottom: 0px !important;">{{formatFileType(file.name)}} Deuda</p>
                </mat-panel-title>
                <mat-panel-title *ngIf="file.public_organisation">
                  <p style="margin-bottom: 0px !important;">{{formatFileType(file.public_organisation)}} Deuda</p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <p class="text-muted">{{'CLIENT.documents.please_upload' | translate}}</p>
                <div class="uploaded-files">
                  <div class="uploaded-file d-flex mb-2 justify-content-between"
                       *ngFor="let uploadedFile of file.public_organisation ? this.publicDebtFiles[file.public_organisation] : this.creditorFiles[file.name]">
                    <a href="{{storageUrl + uploadedFile.location}}" target="_blank" style="color: #0a6ebd">
                      {{uploadedFile.name}}
                    </a>
                    <div class="d-flex flex-row justify-content-center align-items-baseline">
                      <p style="color: red !important;"
                         *ngIf="uploadedFile.status === 'declined'">{{'CASES.single.documents.declined_document' | translate}}
                        </p>
                      <i style="color: #0a6ebd" (click)="onRemove($event, uploadedFile)" class="fas fa-trash-alt ml-1"
                         *ngIf="uploadedFile.status !== 'accepted'"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <!--<button type="button" class="btn btn-outlined-blue mr-2" (click)="fileInput.click()">
                  <span>{{'CLIENT.documents.more_files' | translate}}</span>
                  <input #fileInput type="file" multiple="true"
                         (change)="onFileInput($event, file, 'creditors', 'front')"
                         style="display:none;"/>
                </button>-->
                <button type="button" class="btn btn-outlined-blue" (click)="fileInput.click()">
                  <span>{{'CLIENT.documents.upload_files' | translate}}</span>
                  <input #fileInput type="file" (change)="onFileInput($event, file, 'creditors', 'front')"
                         style="display:none;"/>
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div *ngIf="bankDocsVisible">
        <div>
          <mat-accordion multi>
            <mat-expansion-panel class="bg-light m-1 mb-3" *ngFor="let file of bankRequiredFilesArray">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p class="m-0">{{file.entity.name}}</p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <p class="text-muted">{{'CLIENT.documents.please_upload' | translate}}</p>
                <div class="uploaded-files">
                  <div class="uploaded-file d-flex mb-2 justify-content-between"
                       *ngFor="let uploadedFile of this.bankFiles[file.entity.name]">
                    <a href="{{storageUrl + uploadedFile.location}}" target="_blank" style="color: #0a6ebd">
                      {{uploadedFile.name}}
                    </a>
                    <div class="d-flex flex-row justify-content-center align-items-baseline">
                      <p style="color: red !important;"
                         *ngIf="uploadedFile.status === 'declined'">{{'CASES.single.documents.declined_document' | translate}}
                        </p>
                      <i style="color: #0a6ebd" (click)="onRemove($event, uploadedFile)" class="fas fa-trash-alt ml-1"
                         *ngIf="uploadedFile.status !== 'accepted'"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <!--<button type="button" class="btn btn-outlined-blue mr-2" (click)="fileInput.click()">
                  <span>{{'CLIENT.documents.more_files' | translate}}</span>
                  <input #fileInput type="file" multiple="true" (change)="onFileInput($event, file, 'banks', 'front')"
                         style="display:none;"/>
                </button>-->
                <button type="button" class="btn btn-outlined-blue" (click)="fileInput.click()">
                  <span>{{'CLIENT.documents.upload_files' | translate}}</span>
                  <input #fileInput type="file" (change)="onFileInput($event, file, 'banks', 'front')"
                         style="display:none;"/>
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="col-md-4 document-upload-banner">
      <div style="" type="button" (click)="playVideo()"
           class="play-btn" *ngIf="!isVideoPlaying">
        <mat-icon>play_arrow</mat-icon>
      </div>
      <video controls name="document_upload" style="width: 100%" #videoPlayer id="videoOne" (play)="play('1')"
             (pause)="play('0')">
        <source src="https://www.deudafix.es/video/clave-PIN.mp4#t=0.1" type="video/mp4">
      </video>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div>
        <h2 class="mx-3 mb-5 mt-5">{{'CASES.single.documents.completed' | translate}}</h2>
        <div class="mt-5 completed-docs-container">
          <mat-accordion multi>
            <mat-expansion-panel class="bg-light m-1 mb-3 {{file.documentClass}}"
                                 *ngFor="let file of completedFilesArray">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon *ngIf="file.documentClass === 'accepted-document'" style="color: #479D84" class="mr-2">
                    done
                  </mat-icon>
                  <p class="m-0" style="white-space: pre;">
                    {{file.label ? file.label : file.name}}
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <p class="text-muted">{{'CLIENT.documents.please_upload' | translate}}</p>
                <div class="uploaded-files">
                  <div class="uploaded-file d-flex mb-2 justify-content-between"
                       *ngFor="let uploadedFile of this.uploadedFiles[file.name]">
                    <a href="{{storageUrl + uploadedFile.location}}" target="_blank" style="color: #0a6ebd">
                      {{uploadedFile.name}}
                    </a>
                    <div class="d-flex flex-row justify-content-center align-items-baseline">
                      <p style="color: red !important;"
                         *ngIf="uploadedFile.status === 'declined'">{{'CASES.single.documents.declined_document' | translate}}</p>
                      <i style="color: #0a6ebd" (click)="onRemove($event, uploadedFile)" class="fas fa-trash-alt ml-1"
                         *ngIf="uploadedFile.status !== 'accepted'"></i>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <!--<button *ngIf="file.name !== 'dni'" type="button" class="btn btn-outlined-blue mr-2"
                          (click)="fileInput.click()">
                    <span>{{'CLIENT.documents.more_files' | translate}}</span>
                    <input #fileInput type="file" multiple="true" (change)="onFileInput($event, file, 'user', 'front')"
                           style="display:none;"/>
                  </button>-->
                  <button type="button" class="btn btn-outlined-blue mr-2" (click)="fileInput.click()"
                          *ngIf="file.name !== 'dni'">
                    <span>{{'CLIENT.documents.upload_files' | translate}}</span>
                    <input #fileInput type="file" (change)="onFileInput($event, file, 'user', 'front')"
                           style="display:none;"/>
                  </button>
                  <button type="button" class="btn btn-outlined-blue mr-2" (click)="fileInputFront.click()"
                          *ngIf="file.name === 'dni' && showDniFront">
                    <span>{{'CLIENT.documents.upload_front' | translate}}</span>
                    <input #fileInputFront type="file" (change)="onFileInput($event, file, 'user', 'front')"
                           style="display:none;"/>
                  </button>
                  <button type="button" class="btn btn-outlined-blue mr-2" (click)="fileInputBack.click()"
                          *ngIf="file.name === 'dni' && showDniBack">
                    <span>{{'CLIENT.documents.upload_back' | translate}}</span>
                    <input #fileInputBack type="file" multiple="true"
                           (change)="onFileInput($event, file, 'user', 'back')"
                           style="display:none;"/>
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
