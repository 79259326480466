<div class="footer mt-5 col-12 row d-flex flex-column justify-content-center align-items-start">
  <div class="col-lg-7 col-11 d-flex justify-content-center mt-5" #colSeven>
    <div class="d-flex flex-column" style="width: 654px">
      <div class="logo-wrapper d-none flex-column align-items-start mb-3 mt-3">
        <a href=" https://novomoney.es/">
          <img src="../../../assets/img/logos/novo1.png" alt="Novo Money Logo"
               style="width: 200px;">
        </a>
      </div>
    </div>
  </div>
</div>
<div class="col-12 linksDivWrapper pb-4 d-flex flex-column flex-lg-row mb-5" [ngStyle]="{'margin-left': styleObj + 'px'}">
  <div class="logo-wrapper d-flex flex-column align-items-start mb-3 mt-3">
    <a href=" https://novomoney.es/">
      <img src="../../../assets/img/logos/novo1.png" alt="Novo Money Logo"
           style="width: 200px;">
    </a>
    <a href="">
      <img src="../../../assets/img/logos/ecommerceeu-confianza-fondo-claro-100.png" alt="Novo Money Logo"
           style="width: 130px;">
    </a>
  </div>
  <div class="d-flex flex-column flex-lg-row col-12 col-xl-6 justify-content-between links mt-3">
    <div class="d-flex flex-column footer-link mt-3">
      <h4>Información</h4>
      <a href="https://www.novomoney.es/servicios" class="poppins-14 padding-6">Por qué nosotros</a>
    </div>
    <div class="d-flex flex-column footer-link mt-3">
      <h4>Legal</h4>
      <a href="https://www.novomoney.es/aviso-legal" class="poppins-14 padding-6">Aviso legal</a>
      <a href="https://www.novomoney.es/condiciones" class="poppins-14 padding-6">Condiciones generales</a>
    </div>
    <div class="d-flex flex-column footer-link mt-3">
      <h4>Privacidad</h4>
      <a href="https://www.novomoney.es/privacidad" class="poppins-14 padding-6">Privacidad</a>
      <a href="https://www.novomoney.es/cookies" class="poppins-14 padding-6">Cookies</a>
    </div>
    <div class="d-flex flex-column footer-link mt-3">
      <h4>Contáctenos</h4>
      <a href="https://www.novomoney.es/contactanos" class="poppins-14 padding-6">Por qué Contacto</a>
      <a href="mailto:info@novomoney.es" style="color: #1E2022" class="poppins-14 padding-6">info@novomoney.es</a>
      <a href="#" style="color: #1E2022" class="poppins-14 padding-6">Avenida de Brasil 6, 28020,
        Madrid</a>
    </div>
  </div>
</div>
