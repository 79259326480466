import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse, LaravelResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseDepartmentAssignment } from '../../../../../_base-shared/models/Department/CaseDepartmentAssignment';
import { Department } from '../../../../../_base-shared/models/Department/Department';
import { DepartmentCategory } from '../../../../../_base-shared/models/Department/DepartmentCategory';
import { RoundRobinPool } from '../../../../../_base-shared/models/User/RoundRobinPool';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService extends MainBaseApiService {

  categoryIndex(relations: Array<'departments' | 'departments.users'> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<DepartmentCategory>>>(
        this.apiUrl + '/department-categories', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  index(relations: Array<'users'> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<Array<Department>>>(this.apiUrl + '/departments', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  indexDepartmentUsers(departmentId: number) {
    return this.http.get<LaravelResourceResponse<Array<User>>>(this.apiUrl + '/departments/' + departmentId + '/users')
        .pipe(catchError(response => this.handleError(response)));
  }

  indexDepartmentUsersByType(departmentType: string) {
    const params = MainBaseApiService.convertFiltersForRequest({type: departmentType}, 'get');

    return this.http.get<LaravelResourceResponse<Array<User>>>(this.apiUrl + '/departments/type/users', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  indexUserDepartments(userId: number) {
    return this.http.get<LaravelResourceResponse<Array<Department>>>(this.apiUrl + '/users/' + userId + '/departments')
        .pipe(catchError(response => this.handleError(response)));
  }

  getDepartmentAssignments(relations: Array<'department' | 'user'> = []) {
    return this.http.get<LaravelResponse>(this.apiUrl + '/department-assignments')
        .pipe(catchError(response => this.handleError(response)));
  }

  getCaseDepartmentAssignments(caseId: number) {
    return this.http.get<LaravelResourceResponse<Array<CaseDepartmentAssignment>>>(
        this.apiUrl + '/cases/' + caseId + '/department-assignments',
    ).pipe(catchError(response => this.handleError(response)));
  }

  updateCaseDepartmentAssignments(caseId: number, requestData) {
    return this.http.patch<LaravelResourceResponse<Array<CaseDepartmentAssignment>>>(
        this.apiUrl + '/cases/' + caseId + '/department-assignments', requestData,
    ).pipe(catchError(response => this.handleError(response)));
  }

  getUserRoundRobinPools(userId: number) {
    return this.http.get<LaravelResourceResponse<Array<RoundRobinPool>>>(
        this.apiUrl + '/users/' + userId + '/round-robin-pools')
        .pipe(catchError(response => this.handleError(response)));
  }
}
