import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionGuard } from '../../../../../../_base-shared/guards/permission.guard';
import { SmsListComponent } from './sms-list/sms-list.component';
import { SmsEditorComponent } from './sms-editor/sms-editor.component';


const routes: Routes = [
  {
    path: 'sms',
    children: [
      {path: '', canActivate: [PermissionGuard], component: SmsListComponent},
      {path: 'create', canActivate: [PermissionGuard], component: SmsEditorComponent, data: {editor: 'create'}},
      {path: ':id/edit', canActivate: [PermissionGuard], component: SmsEditorComponent, data: {editor: 'edit'}},
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class SmsRoutingModule {
}
