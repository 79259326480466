<!--<pre *ngIf="form">-->
<!--  touched:{{ form.touched }}-->
<!--  dirty:{{ form.dirty }}-->
<!--  invalid:{{ form.invalid }}-->
<!--</pre>-->
<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
  <!-- Case Details -->
  <mat-card class="mb-3" formGroupName="case">
    <mat-card-header>
      <mat-card-title>{{ 'CASES.editor.general.heading' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row mb-3">
        <div class="col-6">
          <!-- Joint Application -->
          <div class="row align-items-center">
            <mat-label class="col-6 col-form-label">{{ "CASES.single.application-type" | translate }}</mat-label>
            <div class="col-6">
              <mat-radio-group formControlName="joint_application" class="input-full-width">
                <mat-radio-button [value]="false"
                                  style="margin-right: 20px;">{{ "CASES.single-type" | translate }}</mat-radio-button>
                <mat-radio-button [value]="true">{{ "CASES.joint" | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="col-6">
          <!-- Linked Cases-->
          <div class="row align-items-center">
            <mat-label class="col-6 col-form-label">Linked cases</mat-label>
            <div class="col-6">
              <mat-form-field class="input-full-width">
                <mat-select formControlName="linked_cases_ids" placeholder="Case" [multiple]="true"
                            (openedChange)="triggerEvent($event)" class="input-full-width">
                  <mat-option>
                    <!-- TODO: replace me with app-input -->
                    <ngx-mat-select-search noEntriesFoundLabel="" placeholderLabel="Search"
                                           [formControl]="caseMultiFilterCtrl">
                    </ngx-mat-select-search>
                  </mat-option>
                  <ng-container *ngIf="caseMultiFilterCtrl.value.length <= 5">
                    <div class="spinner-container p-2 d-flex justify-content-center">
                      <p class="m-0">Enter 6 or more characters</p>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isLoadingCases">
                    <div class="spinner-container p-2 d-flex justify-content-center">
                      <mat-spinner diameter="30"></mat-spinner>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!isLoadingCases">
                    <mat-option *ngFor="let caseObj of filteredCases | async" [value]="caseObj.id">
                      {{caseObj.ref_number}} - {{caseObj.client.first_name}} {{caseObj.client.first_name}}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- Linked by Cases -->
          <div class="row align-items-center">
            <mat-label class="col-6 col-form-label">Linked by cases</mat-label>
            <mat-list class="list-horizontal" dense style="padding-left: 12px">
              <a *ngFor="let linkedCase of case.linked_cases"
                 [routerLink]="['/cases', linkedCase.id, 'general']">
                {{ linkedCase.ref_number }}
              </a>
            </mat-list>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <mat-card-actions align="end">
        <app-spinner-btn type="submit" [loading]="isSubmitting" [name]="'SHARED.save' | translate"
                         style="margin-right: 20px">
        </app-spinner-btn>
      </mat-card-actions>
    </mat-card-footer>
  </mat-card>
  <!-- Clients -->
  <div class="row">
    <ng-container>
      <div formGroupName="client"
           [class]="form.get('case.joint_application').value ? 'col-6' : 'col-12'">
        <div class="row">
          <div [class]="form.get('case.joint_application').value ? 'col-12' : 'col-6'">
            <div class="card shadow">
              <div class="card-body">
                <h2 class="text-center" *ngIf="form.get('case.joint_application').value">
                  {{"CASES.single.client" | translate}}
                </h2>
                <!-- Criminal Record -->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.criminal_record' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="criminal_record" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('client.criminal_record')?.touched &&-->
                <!--                     form?.get('client.criminal_record')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; Spanish Resident &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.spanish_resident' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="spanish_resident" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('client.spanish_resident')?.touched &&-->
                <!--                     form?.get('client.spanish_resident')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash;LSO in 5 years &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.lso_in_five_years' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="lso_in_five_years" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('client.lso_in_five_years')?.touched &&-->
                <!--                     form?.get('client.lso_in_five_years')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; Derivation of Responsibility &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.deprivation_of_responsibility' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="derivation_of_responsibility" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('client.derivation_of_responsibility')?.touched &&-->
                <!--                     form?.get('client.derivation_of_responsibility')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; More Than 1 Creditor &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.more_than_one_creditor' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="more_than_one_creditor" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('client.more_than_one_creditor')?.touched &&-->
                <!--                     form?.get('client.more_than_one_creditor')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; Public Sanctions &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.public_sanctions' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="public_sanctions" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('client.public_sanctions')?.touched &&-->
                <!--                     form?.get('client.public_sanctions')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!-- First Name-->
                <app-input formControlName="first_name" [label]="'USERS.model.first_name' | translate"
                           [extraLabel]="true" [fullWidth]="true">
                </app-input>
                <!-- Last Name -->
                <app-input formControlName="last_name" [label]="'USERS.model.last_name' | translate"
                           [extraLabel]="true" [fullWidth]="true">
                </app-input>
                <!-- Date of Birth-->
                <app-input type="date" formControlName="date_of_birth" [label]="'USERS.model.date_of_birth' | translate"
                           [extraLabel]="true" [fullWidth]="true">
                </app-input>
                <!-- Client ID Card -->
                <!--<app-input formControlName="id_card" [label]="'CASES.single.id-card' | translate"-->
                <!--           [extraLabel]="true" [fullWidth]="true">-->
                <!--</app-input>-->
                <!-- Client Email -->
                <app-input [type]="'email'" formControlName="email" [label]="'USERS.model.email' | translate"
                           [extraLabel]="true" [fullWidth]="true">
                </app-input>
                <!-- Client Phone -->
                <app-input [type]="'number'" formControlName="phone" prefix="+34"
                           [label]="'USERS.model.phone' | translate" [extraLabel]="true" [fullWidth]="true"
                           [patternError]="'CASES.single.mobile-invalid' | translate">
                </app-input>
                <!-- Client Home Phone -->
                <!-- <app-input [type]="'number'" formControlName="home_phone" prefix="+34"
                           [label]="'USERS.model.home_phone' | translate" [extraLabel]="true" [fullWidth]="true"
                           [patternError]="'CASES.single.mobile-invalid' | translate">
                </app-input> -->
                <!-- Client Work Phone -->
                <!-- <app-input [type]="'number'" formControlName="work_phone" prefix="+34"
                           [label]="'USERS.model.work_phone' | translate" [extraLabel]="true" [fullWidth]="true"
                           [patternError]="'CASES.single.mobile-invalid' | translate">
                </app-input> -->
                <!-- &lt;!&ndash; Client Employment &ndash;&gt; -->
                <app-input type="select" formControlName="employment"
                          [label]="'USERS.model.employment.label' | translate" [fullWidth]="true"
                          [extraLabel]="true" [selectOptions]="employmentOptions">
                </app-input>
                <!--Client Home Owner-->
                <app-input type="select" formControlName="home_owner" [extraLabel]="true" [fullWidth]="true"
                           [label]="'USERS.model.home_owner.label' | translate" [selectOptions]="homeOwnerOptions">
                </app-input>
                               <!-- &lt;!&ndash; Nationality &ndash;&gt;-->
                <!--                <app-input type="text" formControlName="nationality" [extraLabel]="true"-->
                <!--                           [label]="'USERS.model.nationality' | translate" [fullWidth]="true">-->
                <!--                </app-input> -->
                <!--                &lt;!&ndash; Client Economic Regime &ndash;&gt;-->
                <!--                <app-input type="select" formControlName="economic_regime" [fullWidth]="true"-->
                <!--                           [label]="'USERS.model.economic_regime.label' | translate" [extraLabel]="true"-->
                <!--                           [selectOptions]="economicRegimeOptions">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Client Marital Status &ndash;&gt;-->
                               <app-input type="select" formControlName="marital_status" [extraLabel]="true" [fullWidth]="true"
                                          [label]="'USERS.model.marital_status.label' | translate"
                                          [selectOptions]="maritalStatusOptions">
                               </app-input>
                <!--                &lt;!&ndash; Client Number of Dependent&ndash;&gt;-->
                               <app-input [type]="'number'" formControlName="number_of_dependent" [extraLabel]="true"
                                          [label]="'USERS.model.number_of_dependent' | translate" [fullWidth]="true">
                               </app-input>
                <!--                &lt;!&ndash; Marriage Date &ndash;&gt;-->
                <!--                <app-input type="date" formControlName="married_at" [label]="'USERS.model.married_at' | translate"-->
                <!--                           [extraLabel]="true" [fullWidth]="true">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Marriage City &ndash;&gt;-->
                <!--                <app-input type="text" formControlName="marriage_city" [extraLabel]="true"-->
                <!--                           [label]="'USERS.model.marriage_city' | translate" [fullWidth]="true">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Client Home Owner &ndash;&gt;-->
                <!--                <app-input type="select" formControlName="home_owner" [extraLabel]="true" [fullWidth]="true"-->
                <!--                           [label]="'USERS.model.home_owner.label' | translate" [selectOptions]="homeOwnerOptions">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Client Debt Aware &ndash;&gt;-->
                <!--                <app-input type="select" formControlName="debt_aware" [extraLabel]="true" [fullWidth]="true"-->
                <!--                           [label]="'USERS.model.debt_aware' | translate" [selectOptions]="debtAwareOptions">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Client vulnerability &ndash;&gt;-->
                <!--                <app-input type="select" formControlName="vulnerability" [extraLabel]="true" [fullWidth]="true"-->
                <!--                           [label]="'USERS.model.vulnerability.label' | translate"-->
                <!--                           [selectOptions]="vulnerabilityOptions">-->
                <!--                </app-input>-->
              </div>
            </div>
          </div>

          <div [class]="form.get('case.joint_application').value ? 'col-12' : 'col-6'">
            <div class="card shadow">
              <div class="card-body pl-0 pr-0">
                <mat-expansion-panel formGroupName="address"
                                     [expanded]="!form.get('case.joint_application').value">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ 'CASES.editor.clients.address.label' | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <!-- Address 1 -->
                  <app-input formControlName="address_1" [label]="'ADDRESS.address_1' | translate" [extraLabel]="true"
                             [fullWidth]="true">
                  </app-input>
                  <!-- Address 2 -->
                  <app-input formControlName="address_2" [label]="'ADDRESS.address_2' | translate" [extraLabel]="true"
                             [fullWidth]="true">
                  </app-input>
                  <!-- Postcode -->
                  <div class="d-flex justify-content-between align-items-baseline">
                    <div style="width: 50%;">
                      <mat-label>{{'ADDRESS.postcode' | translate}}</mat-label>
                    </div>
                    <div style="width: 50%;">
                      <mat-form-field appearance="outline" style="width:100%">
                        <mat-label>{{'ADDRESS.postcode' | translate}}</mat-label>
                        <input type="number" aria-label="Number" matInput formControlName="postcode"
                               [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                          <ng-container *ngIf="filteredOptionsClient">
                            <mat-option *ngFor="let option of filteredOptionsClient | async" [value]="option?.postcode"
                                        (click)="selectedPostcodeClient(option)">
                              {{option.postcode}}
                            </mat-option>
                          </ng-container>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- Municipality -->
                  <app-input formControlName="municipality" [label]="'ADDRESS.municipality' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- City -->
                  <app-input formControlName="city" [label]="'ADDRESS.city' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- Region -->
                  <app-input formControlName="region" [label]="'ADDRESS.region' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- County -->
                  <app-input formControlName="county" [label]="'ADDRESS.county' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- Country -->
                  <app-input formControlName="country" [label]="'ADDRESS.country' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                </mat-expansion-panel>
                <mat-expansion-panel class="mt-2" [expanded]="!form.get('case.joint_application').value">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ 'CASES.editor.clients.notification-preferences.label' | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <!-- Time to Contact -->
                  <app-input formControlName="time_to_contact" [extraLabel]="true" [fullWidth]="true"
                             [label]="'CASES.single.time-to-contact' | translate">
                  </app-input>
                  <!-- Notification Preferences Checkbox List -->
                  <div style="padding-top: 20px">
                    <label>{{ "CASES.single.allow-contact-by" | translate }}</label>
                    <mat-selection-list>
                      <mat-list-option (click)="setGDPR('client.allow_sms', $event)"
                                       [selected]="form.get('client.allow_sms').value">
                        {{ "CASES.single.sms" | translate }}
                      </mat-list-option>
                      <mat-list-option (click)="setGDPR('client.allow_call', $event)"
                                       [selected]="form.get('client.allow_call').value">
                        {{ "CASES.single.call" | translate }}
                      </mat-list-option>
                      <mat-list-option (click)="setGDPR('client.allow_email', $event)"
                                       [selected]="form.get('client.allow_email').value">
                        {{ 'USERS.model.email' | translate }}
                      </mat-list-option>
                      <mat-list-option (click)="setGDPR('client.allow_post', $event)"
                                       [selected]="form.get('client.allow_post').value">
                        {{ 'CASES.single.post' | translate }}
                      </mat-list-option>
                      <mat-list-option (click)="setGDPR('client.allow_marketing', $event)"
                                       [selected]="form.get('client.allow_marketing').value">
                        {{ 'USERS.editor.allow_marketing' | translate }}
                      </mat-list-option>
                    </mat-selection-list>
                  </div>
                </mat-expansion-panel>
                <hr/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- Partner part -->
    <ng-container *ngIf="form.get('case.joint_application').value">
      <div class="col-6" formGroupName="partner">
        <div class="row">
          <div class="col-12">
            <div class="card shadow">
              <div class="card-body">
                <h2 class="text-center">{{"CASES.single.partner" | translate}}</h2>
                <!-- Criminal Record -->
                <div class="row align-items-center mb-1">
                  <mat-label
                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.criminal_record' | translate}}</mat-label>
                  <div class="col-6">
                    <mat-radio-group formControlName="criminal_record" class="input-full-width">
                      <mat-radio-button [value]="true"
                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>
                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="form?.get('partner.criminal_record')?.touched &&
                     form?.get('partner.criminal_record')?.hasError('required')">
                      {{"SHARED.field-required" | translate}}
                    </mat-error>
                  </div>
                </div>
                <!-- Spanish Resident -->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.spanish_resident' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="spanish_resident" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('partner.spanish_resident')?.touched &&-->
                <!--                     form?.get('partner.spanish_resident')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash;LSO in 5 years &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.deprivation_of_responsibility' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="derivation_of_responsibility" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('partner.derivation_of_responsibility')?.touched &&-->
                <!--                     form?.get('partner.derivation_of_responsibility')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; Derivation of Responsibility &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.lso_in_five_years' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="lso_in_five_years" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('partner.lso_in_five_years')?.touched &&-->
                <!--                     form?.get('partner.lso_in_five_years')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; More Than 1 Creditor &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.more_than_one_creditor' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="more_than_one_creditor" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('partner.more_than_one_creditor')?.touched &&-->
                <!--                     form?.get('partner.more_than_one_creditor')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; Public Sanctions &ndash;&gt;-->
                <!--                <div class="row align-items-center mb-1">-->
                <!--                  <mat-label-->
                <!--                    class="col-6 col-form-label">{{'CASES.model.lso_qualifying_questions.public_sanctions' | translate}}</mat-label>-->
                <!--                  <div class="col-6">-->
                <!--                    <mat-radio-group formControlName="public_sanctions" class="input-full-width">-->
                <!--                      <mat-radio-button [value]="true"-->
                <!--                                        style="margin-right: 20px;">{{ "SHARED.yes" | translate }}</mat-radio-button>-->
                <!--                      <mat-radio-button [value]="false">{{ "SHARED.no" | translate }}</mat-radio-button>-->
                <!--                    </mat-radio-group>-->
                <!--                    <mat-error *ngIf="form?.get('partner.public_sanctions')?.touched &&-->
                <!--                     form?.get('partner.public_sanctions')?.hasError('required')">-->
                <!--                      {{"SHARED.field-required" | translate}}-->
                <!--                    </mat-error>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!-- Client 2 First Name-->
                <app-input formControlName="first_name" [label]="'USERS.model.first_name' | translate"
                           [extraLabel]="true" [fullWidth]="true">
                </app-input>
                <!-- Client 2 Last Name -->
                <app-input formControlName="last_name" [label]="'USERS.model.last_name' | translate"
                           [extraLabel]="true" [fullWidth]="true">
                </app-input>
                <!-- Client 2 Date Of Birth-->
                <app-input type="date" formControlName="date_of_birth" [label]="'USERS.model.date_of_birth' | translate"
                           [extraLabel]="true" [fullWidth]="true">
                </app-input>
                <!-- Client 2 ID Card -->
                <!--<app-input formControlName="id_card" [label]="'CASES.single.id-card' | translate"-->
                <!--           [extraLabel]="true" [fullWidth]="true">-->
                <!--</app-input>-->
                <!-- Client 2 Email -->
                <app-input [type]="'email'" formControlName="email" [label]="'USERS.model.email' | translate"
                           [extraLabel]="true" [fullWidth]="true">
                </app-input>
                <!-- Client 2 Phone -->
                <app-input [type]="'number'" formControlName="phone" prefix="+34" [extraLabel]="true" [fullWidth]="true"
                           [label]="'USERS.model.phone' | translate"
                           [patternError]="'CASES.single.mobile-invalid' | translate">
                </app-input>
                <!-- Client 2 Home Phone -->
                <!-- <app-input [type]="'number'" formControlName="home_phone" prefix="+34"
                           [label]="'USERS.model.home_phone' | translate" [extraLabel]="true" [fullWidth]="true"
                           [patternError]="'CASES.single.mobile-invalid' | translate">
                </app-input> -->
                <!-- Client 2 Work Phone -->
                <!-- <app-input [type]="'number'" formControlName="work_phone" prefix="+34"
                           [label]="'USERS.model.work_phone' | translate" [extraLabel]="true" [fullWidth]="true"
                           [patternError]="'CASES.single.mobile-invalid' | translate">
                </app-input> -->
                <!--                &lt;!&ndash; Client 2 Nationality &ndash;&gt;-->
                <!--                <app-input type="text" formControlName="nationality" [extraLabel]="true"-->
                <!--                           [label]="'USERS.model.nationality' | translate" [fullWidth]="true">-->
                <!--                </app-input>-->
                <!-- &lt;!&ndash; Client 2 Employment &ndash;&gt; -->
                <app-input type="select" formControlName="employment" [extraLabel]="true" [fullWidth]="true"
                          [label]="'USERS.model.employment.label' | translate"
                          [selectOptions]="employmentOptions">
                </app-input>
                <!--                &lt;!&ndash; Client 2 Economic Regime &ndash;&gt;-->
                <!--                <app-input type="select" formControlName="economic_regime" [extraLabel]="true" [fullWidth]="true"-->
                <!--                           [label]="'USERS.model.economic_regime.label' | translate"-->
                <!--                           [selectOptions]="economicRegimeOptions">-->
                <!--                </app-input>-->
                <!-- Client 2 Relation to Applicant -->
                <app-input type="select" formControlName="relation_to_applicant" [extraLabel]="true" [fullWidth]="true"
                           [label]="'USERS.model.relation_to_applicant.label' | translate"
                           [selectOptions]="relationToApplicant">
                </app-input>
                <!--                &lt;!&ndash; Client 2 Marital Status &ndash;&gt;-->
                <app-input type="select" formControlName="marital_status" [extraLabel]="true" [fullWidth]="true"
                          [label]="'USERS.model.marital_status.label' | translate"
                          [selectOptions]="maritalStatusOptions">
                </app-input>
                <!--                &lt;!&ndash; Client 2 Marriage Date &ndash;&gt;-->
                <!--                <app-input type="date" formControlName="married_at" [label]="'USERS.model.married_at' | translate"-->
                <!--                           [extraLabel]="true" [fullWidth]="true">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Client 2 Marriage City &ndash;&gt;-->
                <!--                <app-input type="text" formControlName="marriage_city" [extraLabel]="true"-->
                <!--                           [label]="'USERS.model.marriage_city' | translate" [fullWidth]="true">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Client 2 Home Owner &ndash;&gt;-->
                <!--                <app-input type="select" formControlName="home_owner" [extraLabel]="true" [fullWidth]="true"-->
                <!--                           [label]="'USERS.model.home_owner.label' | translate" [selectOptions]="homeOwnerOptions">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Client 2 Debt Aware &ndash;&gt;-->
                <!--                <app-input type="select" formControlName="debt_aware" [extraLabel]="true" [fullWidth]="true"-->
                <!--                           [label]="'USERS.model.debt_aware' | translate" [selectOptions]="debtAwareOptions">-->
                <!--                </app-input>-->
                <!--                &lt;!&ndash; Client 2 Vulnerability &ndash;&gt;-->
                <!--                <app-input type="select" formControlName="vulnerability" [extraLabel]="true" [fullWidth]="true"-->
                <!--                           [label]="'USERS.model.vulnerability.label' | translate"-->
                <!--                           [selectOptions]="vulnerabilityOptions">-->
                <!--                </app-input>-->
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card shadow">
              <div class="card-body">
                <!-- Address Group -->
                <mat-expansion-panel formGroupName="address" [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ 'CASES.editor.clients.address.label' | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <!-- Address 1 -->
                  <app-input formControlName="address_1" [label]="'ADDRESS.address_1' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- Address 2 -->
                  <app-input formControlName="address_2" [label]="'ADDRESS.address_2' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- Postcode -->
                  <div class="d-flex justify-content-between align-items-baseline">
                    <div style="width: 50%;">
                      <mat-label>{{'ADDRESS.postcode' | translate}}</mat-label>
                    </div>
                    <div style="width: 50%;">
                      <mat-form-field appearance="outline" style="width:100%">
                        <mat-label>{{'ADDRESS.postcode' | translate}}</mat-label>
                        <input type="number" aria-label="Number" matInput formControlName="postcode"
                               [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                          <ng-container *ngIf="filteredOptionsPartner">
                            <mat-option *ngFor="let option of filteredOptionsPartner | async" [value]="option?.postcode"
                                        (click)="selectedPostcodePartner(option)">
                              {{option.postcode}}
                            </mat-option>
                          </ng-container>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- Municipality -->
                  <app-input formControlName="municipality" [label]="'ADDRESS.municipality' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- City -->
                  <app-input formControlName="city" [label]="'ADDRESS.city' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- Region -->
                  <app-input formControlName="region" [label]="'ADDRESS.region' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- County -->
                  <app-input formControlName="county" [label]="'ADDRESS.county' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                  <!-- Country -->
                  <app-input formControlName="country" [label]="'ADDRESS.country' | translate"
                             [extraLabel]="true" [fullWidth]="true">
                  </app-input>
                </mat-expansion-panel>
                <!-- Notification Preferences -->
                <mat-expansion-panel class="mt-2" [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ 'CASES.editor.clients.notification-preferences.label' | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <!-- Time to Contact -->
                  <app-input formControlName="time_to_contact" [extraLabel]="true" [fullWidth]="true"
                             [label]="'CASES.single.time-to-contact' | translate">
                  </app-input>
                  <!-- Notification Preferences Checkbox List -->
                  <div style="padding-top: 20px">
                    <label>{{ "CASES.single.allow-contact-by" | translate }}</label>
                    <mat-selection-list>
                      <mat-list-option (click)="setGDPR('partner.allow_sms', $event)"
                                       [selected]="form.get('partner.allow_sms').value">
                        {{ "CASES.single.sms" | translate }}
                      </mat-list-option>
                      <mat-list-option (click)="setGDPR('partner.allow_call', $event)"
                                       [selected]="form.get('partner.allow_call').value">
                        {{ "CASES.single.call" | translate }}
                      </mat-list-option>
                      <mat-list-option (click)="setGDPR('partner.allow_email', $event)"
                                       [selected]="form.get('partner.allow_email').value">
                        {{ 'USERS.model.email' | translate }}
                      </mat-list-option>
                      <mat-list-option (click)="setGDPR('partner.allow_post', $event)"
                                       [selected]="form.get('partner.allow_post').value">
                        {{ "CASES.single.post" | translate }}
                      </mat-list-option>
                    </mat-selection-list>
                  </div>
                </mat-expansion-panel>
                <hr/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>
