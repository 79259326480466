import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Team } from '../../../../../_base-shared/models/User/Team';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService extends MainBaseApiService {

  public index(requestData, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest(
        {...requestData, with: relations, with_count: relationsCount}, 'get'
    );
    return this.http.get<LaravelResourceResponse<Array<Team>>>(this.apiUrl + '/teams', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public show(modelId, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations, with_count: relationsCount}, 'get');

    return this.http.get<LaravelResourceResponse<Team>>(this.apiUrl + '/teams/' + modelId, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public store(data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.post<LaravelResourceResponse<Team>>(this.apiUrl + '/teams', data, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public update(modelId: number, data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.put<LaravelResourceResponse<Team>>(this.apiUrl + '/teams/' + modelId, data, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public delete(modelId: number) {
    return this.http.delete<LaravelResourceResponse<Team>>(this.apiUrl + '/teams/' + modelId)
        .pipe(catchError(response => this.handleError(response)));
  }
}
