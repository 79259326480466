import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { MiscConfigService } from './misc-config.service';

@Component({
  selector:    'app-misc-config',
  templateUrl: './misc-config.component.html',
  styleUrls:   ['./misc-config.component.scss'],
})
export class MiscConfigComponent implements OnInit {
  public form: UntypedFormGroup;
  public formReady    = false;
  public percentageForm: UntypedFormGroup;
  public percentageFormReady = false;
  public isSubmitting = 0;
  public isSubmittingFee = 0;
  public feePercentages: Array<any> = [];
  public feePercentageTableColumns = ['name', 'percentage', 'bywho', 'date', 'actions'];
  public isFds: boolean = false;

  constructor(private fb: UntypedFormBuilder,
              private configService: MiscConfigService,
              private toast: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getConfigData();
    this.getFeePercentages();
    console.log(this.feePercentages);
  }

  private getConfigData() {
    this.configService.getConfigData().subscribe(next => {
      console.log(next);
      this.buildForm(next.data);
    });
  }

  public submit() {
    this.isSubmitting++;
    this.configService.index(this.form.value)
        .pipe(finalize(() => this.isSubmitting--))
        .subscribe(
            res => {
              this.toast.success(this.translate.instant('CONFIG.system-event.config-saved'));
            },
            error => {
              this.toast.error(this.translate.instant('CONFIG.system-event.config-saved-error'));
            });
  }

  private buildForm(data) {
    const loanArrangementFee = data.find(obj => obj.key === 'loan_arrangement_fee');
    const latePaymentPenalty = data.find(obj => obj.key === 'late_payment_penalty');
    const debt_reduction_fee = data.find(obj => obj.key === 'debt_reduction_fee');
    this.form = this.fb.group({
      loan_arrangement_fee: loanArrangementFee ? loanArrangementFee.value : '',
      late_payment_penalty: latePaymentPenalty ? latePaymentPenalty.value : '',
      debt_reduction_fee: debt_reduction_fee ? debt_reduction_fee.value : ''
    });

    this.formReady = true;
  }

  private getFeePercentages(): void {
    this.configService.getVariousFeePercentages().subscribe(next => {
        this.feePercentages = next.data;
        this.buildPercentageForm();
      },
      error => console.log(error),
    );
  }

  // submit percentage form
  public submitPercentageForm() {
    this.isSubmittingFee++;
    this.configService.savePercentage(this.percentageForm.value)
      .pipe(finalize(() => this.isSubmittingFee--))
      .subscribe(
        res => {
          this.percentageForm.reset();
          this.getFeePercentages();
          this.toast.success('Fee percentage saved');
        },
        error => {
          [error.error.errors].map((element) => {
            for (const key in element) {
              if (element.hasOwnProperty(key)) {
                this.toast.error(element[key]);
              }
            }
          });
        });
  }

  // private build percentage form with optional data for editing
  public buildPercentageForm(data = null) {
    this.isFds = false;
    this.percentageForm = this.fb.group({
      id: data ? data.id : '',
      name: data ? data.name : '',
      percentage: data ? data.percentage : '',
      is_fds: data ? data.is_fds : false,
    });
    this.isFds = (data ? data.is_fds : false);
    this.percentageFormReady = true;
  }

  public editFeePercentage(id, event) {
    event.preventDefault();
    this.configService.getFeePercentage(id).subscribe(next => {
      this.buildPercentageForm(next.data);
    });
  }

  public removeFeePercentage(id, event) {
    event.preventDefault();
    this.configService.deleteFeePercentage(id).subscribe(next => {
      this.getFeePercentages();
    });
  }

  // disable the save button if the form doesn't have a name or percentage
  public disableButton() {
    return !this.percentageForm.get('name').value || !this.percentageForm.get('percentage').value;
  }
}
