<!-- AGENT NAV -->
<ul *ngIf="authUser && authUser.role.is_staff && authUser.role.id !== 6" class="navbar-nav mr-auto">
  <!-- Dashboard -->
  <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
      class="nav-item">
    <a class="nav-link" routerLink=''>
      {{ "NAVBAR.dashboard" | translate }}
    </a>
<!--    <div class="dropdown-menu">-->
<!--      <a routerLink="" title="Sales leaderboard" class="dropdown-item">-->
<!--        <span class="nav-link-text">{{"DASHBOARD.sales-leaderboard" | translate}}</span>-->
<!--      </a>-->
<!--      <a routerLink="mi-dash" title="Mi dashboard" class="dropdown-item">-->
<!--        <span class="nav-link-text">{{"NAVBAR.mi-dashboard" | translate}}</span>-->
<!--      </a>-->
<!--      <a routerLink="mi-dash-customer-care" title="Mi dashboard customer"-->
<!--         class="dropdown-item">-->
<!--        <span class="nav-link-text">{{"NAVBAR.mi-dashboard-customer" | translate}}</span>-->
<!--      </a>-->
<!--      <a routerLink="mi-dash-details" title="Mi dashboard customer"-->
<!--         class="dropdown-item">-->
<!--        <span class="nav-link-text">{{"NAVBAR.mi-dash-details" | translate}}</span>-->
<!--      </a>-->
<!--      <a routerLink="verifier-dashboard" title="Verifier Dashboard" class="dropdown-item">-->
<!--        <span class="nv-link-text">{{"NAVBAR.verifier-dash" | translate}}</span>-->
<!--      </a>-->
<!--      <a routerLink="tasks-dashboard" title="Tasks" class="dropdown-item">-->
<!--        <span class="nav-link-text">{{"NAVBAR.tasks" | translate}}</span>-->
<!--      </a>-->
<!--      <a routerLink="heads-up-dash" title="Heads Up Dashboard" class="dropdown-item">-->
<!--        <span class="nav-link-text">{{"NAVBAR.heads-up-dashboard" | translate}}</span>-->
<!--      </a>-->
<!--    </div>-->
  </li>

  <!-- Cases -->
  <li routerLinkActive="active" class="nav-item">
    <a [routerLink]="['case-dashboard']" title="Cases" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.cases" | translate }}</span>
    </a>
  </li>

  <!-- MANAGER & ADMIN NAV -->
  <ng-container *ngIf="authUser.role_id !== 3">
    <!-- Affiliate Cases-->
<!--    <li routerLinkActive="active" class="nav-item">-->
<!--      <a [routerLink]="['affiliate-cases']" title="Affiliate Cases" class="nav-link">-->
<!--        <span class="nav-link-text">{{ "NAVBAR.affiliate-cases" | translate }}</span>-->
<!--      </a>-->
<!--    </li>-->
<!--    &lt;!&ndash; Payments &ndash;&gt;-->
<!--    <li routerLinkActive="active" class="nav-item">-->
<!--      <a [routerLink]="['customer-contact']" title="Customer Contact" class="nav-link">-->
<!--        <span class="nav-link-text">{{ "NAVBAR.customer-contact" | translate }}</span>-->
<!--      </a>-->
<!--    </li>-->
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="['payment']" title="Payments" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.payments" | translate }}</span>
      </a>
    </li>
<!--    <li routerLinkActive="active" class="nav-item">-->
<!--      <a [routerLink]="['legal']" title="Legal" class="nav-link">-->
<!--        <span class="nav-link-text">{{ "NAVBAR.legal" | translate }}</span>-->
<!--      </a>-->
<!--    </li>-->

    <!-- Creditors -->
    <!--        <li routerLinkActive="active" *ngIf="authUser.role_id !==  3" class="nav-item">-->
    <!--          <a routerLink="creditor" title="Creditors" class="nav-link">-->
    <!--            <span class="nav-link-text">{{ "NAVBAR.creditors" | translate }}</span>-->
    <!--            <i *ngIf="unapproved" class="fal fa-exclamation-circle ml-1 text-danger"></i>-->
    <!--          </a>-->
    <!--        </li>-->
  </ng-container>

  <li routerLinkActive="active" class="nav-item">
    <a routerLink="affiliates" title="Affiliates" class="nav-link"
       *ngIf="authUser.role_id === 1 || authUser.role_id === 5">
      <span class="nav-link-text">{{ "NAVBAR.affiliates" | translate }}</span>
    </a>
  </li>
  <li routerLinkActive="active" class="nav-item dropdown dashboard-drop">
    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
       aria-expanded="false">
      {{ "NAVBAR.address-book" | translate }}
    </a>
    <div class="dropdown-menu">
      <a routerLink="creditor" title="Creditors" class="dropdown-item" *ngIf="authUser.role_id !==  3">
        <span class="nav-link-text">{{ "NAVBAR.creditors" | translate }}</span>
        <i *ngIf="unapproved" class="fal fa-exclamation-circle ml-1 text-danger"></i>
      </a>
<!--      <a routerLink="notaries" title="Notaries" class="dropdown-item">-->
<!--        <span class="nav-link-text">Notaries</span>-->
<!--      </a>-->
<!--      <a routerLink="administrators" title="Administrators" class="dropdown-item">-->
<!--        <span class="nav-link-text">{{'NAVBAR.administrators' | translate}}</span>-->
<!--      </a>-->
<!--      <a routerLink="court" title="Court" class="dropdown-item">-->
<!--        <span class="nav-link-text">Court</span>-->
<!--      </a>-->
<!--      <a routerLink="town-halls" title="Court" class="dropdown-item">-->
<!--        <span class="nav-link-text">{{'NAVBAR.town_halls' | translate}}</span>-->
<!--      </a>-->
<!--      <a routerLink="solicitors" title="Court" class="dropdown-item">-->
<!--        <span class="nav-link-text">{{'NAVBAR.solicitors' | translate}}</span>-->
<!--      </a>-->
    </div>
  </li>

  <!-- ADMIN ONLY NAV -->
  <ng-container *ngIf="authUser.role_id === 1 || authUser.role_id === 5">
    <!-- Users -->
    <li routerLinkActive="active" class="nav-item dropdown clickable">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{ "NAVBAR.users" | translate }}
      </a>
      <div class="dropdown-menu">
        <a [routerLink]="['users']" title="Users" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.users" | translate }}</span>
        </a>
<!--        <a [routerLink]="['teams']" title="Users" class="dropdown-item">-->
<!--          <span class="nav-link-text">{{ "TEAM.list.heading" | translate }}</span>-->
<!--        </a>-->
      </div>

    </li>
  </ng-container>
  <!-- Distribution -->
<!--  <li *ngIf="userIsAMami()" routerLinkActive="active" class="nav-item">-->
<!--    <a [routerLink]="['/distribution']" title="Distribution" class="nav-link">-->
<!--      <span class="nav-link-text">{{ "NAVBAR.distribution" | translate }}</span>-->
<!--    </a>-->
<!--  </li>-->
  <ng-container *ngIf="authUser.role_id === 1 || authUser.role_id === 5">
    <li routerLinkActive="active" class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
         aria-expanded="false">
        {{ "NAVBAR.reports" | translate }}
      </a>
      <div class="dropdown-menu">
<!--        <a *ngIf="authUser.role_id === 5" routerLink="reports/headline-reports" title="Headline Reports"-->
<!--           class="dropdown-item">-->
<!--          <span class="nav-link-text">{{"NAVBAR.headline-reports" | translate}}</span>-->
<!--        </a>-->
<!--        <a routerLink="reports/creditor-book-value" title="Creditor - Book Value" class="dropdown-item">-->
<!--          <span class="nav-link-text">{{"NAVBAR.creditor-book-value" | translate}}</span>-->
<!--        </a>-->
        <a *ngIf="authUser.role_id === 5" routerLink="reports/financial-reports" title="Financial Reports"
           class="dropdown-item">
          <span class="nav-link-text">{{"NAVBAR.financial-reports" | translate}}</span>
        </a>
        <a routerLink="reports/missing-documents" title="Missing documents"
           class="dropdown-item">
          <span class="nav-link-text">{{"NAVBAR.missing-documents" | translate}}</span>
        </a>
        <a routerLink="tasks" title="Tasks" class="dropdown-item">
          <span class="nav-link-text">{{"NAVBAR.tasks" | translate}}</span>
        </a>
      </div>
    </li>

    <!-- Config -->
    <li class="nav-item dropdown" routerLinkActive="active">
      <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
         aria-expanded="false">
        {{ "NAVBAR.configuration" | translate }}
      </a>
      <div class="dropdown-menu">
<!--        <a routerLink="notification" title="Notifications" class="dropdown-item">-->
<!--          <span class="nav-link-text">{{ "NAVBAR.notifications" | translate }}</span>-->
<!--        </a>-->
        <a routerLink="status" title="Statuses" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.statuses" | translate }}</span>
        </a>
        <a *ngIf="authUser?.role_id === 5" routerLink="call-statuses" title="Call statuses" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.call-statuses" | translate }}</span>
        </a>
        <a routerLink="task-templates" title="Task Templates" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.task-templates" | translate }}</span>
        </a>
        <a routerLink="sms" title="SMS Templates" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.sms-templates" | translate }}</span>
        </a>
        <a routerLink="email" title="Email Templates" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.email-templates" | translate }}</span>
        </a>
        <a routerLink="system-event" title="System Events" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.system-events" | translate }}</span>
        </a>
        <a routerLink="drip-campaigns" title="Drip Campaigns" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.drip-campaigns" | translate }}</span>
        </a>
        <a routerLink="config/dialer" title="Dialer Configuration" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.dialer-config" | translate }}</span>
        </a>
        <a routerLink="misc-config" title="Misc Config" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.misc-config" | translate }}</span>
        </a>
      </div>
    </li>
  </ng-container>
  <!--Employees-->
<!--  <li routerLinkActive="active" class="nav-item">-->
<!--    <a [routerLink]="['/employee-list']" title="Employees" class="nav-link">-->
<!--      <span class="nav-link-text">{{ "NAVBAR.employees" | translate }}</span>-->
<!--    </a>-->
<!--  </li>-->
</ul>

<ul *ngIf="authUser && authUser.role.is_staff && authUser.role.id === 6" class="navbar-nav mr-auto">
  <!-- Dashboard -->
  <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
      class="nav-item dropdown dashboard-drop">
    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
       aria-expanded="false">
      {{ "NAVBAR.dashboard" | translate }}
    </a>
    <div class="dropdown-menu">
      <a routerLink="" title="Sales leaderboard" class="dropdown-item">
        <span class="nav-link-text">{{"DASHBOARD.sales-leaderboard" | translate}}</span>
      </a>
    </div>
  </li>

  <li routerLinkActive="active" class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true"
       aria-expanded="false">
      {{ "NAVBAR.reports" | translate }}
    </a>
    <div class="dropdown-menu">
      <a routerLink="reports/headline-reports" title="Headline Reports"
         class="dropdown-item">
        <span class="nav-link-text">{{"NAVBAR.headline-reports" | translate}}</span>
      </a>
    </div>
  </li>
</ul>
