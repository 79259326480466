import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { DocumentType } from '../models/DocumentType';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeService {

  public documentTypes: DocumentType[] = [];

  constructor(public translate: TranslateService) {
  }

  get(): Observable<DocumentType[]> {
    const documentTypes = [
      {id: 1, name: 'dni', parentType: 'initial', type: 'personal', label: 'DNI/NIE', custom: 0},
      {id:          2,
        name:       'regulatory-agreement',
        parentType: 'initial',
        type:       'personal',
        label:      'Convenio Regulador o Sentencia',
        custom:     0,
      },
      {id:          3,
        name:       'child-birth-certificate',
        parentType: 'initial',
        type:       'personal',
        label:      'Certificado de nacimiento de los hijos',
        custom:     0,
      },
      {id:          4,
        name:       'notarial-deed',
        parentType: 'initial',
        type:       'personal',
        label:      'Escritura notarial - (en el supuesto de existir separación de bienes matrimoniales)',
        custom:     0,
      },
      {id:          28,
        name:       'work-life-record',
        parentType: 'initial',
        type:       'personal',
        label:      'Informe Vida Laboral',
        custom:     0,
      },
      {id:          5,
        name:       'pension-certificate',
        parentType: 'initial',
        type:       'income',
        label:      'Certificado Percepción De Pensión – (en el supuesto queelclientesea pensionista)',
        custom:     0,
      },
      {id:          6,
        name:       'employment-contract',
        parentType: 'initial',
        type:       'income',
        label:      'Contrato laboral (en el supuesto de trabajar por cuenta ajena)',
        custom:     0,
      },
      {id:          7,
        name:       'unemployment-benefit',
        parentType: 'initial',
        type:       'income',
        label:      'Prestación por desempleo o subsidio (en el supuesto de estar en situación de desempleo)',
        custom:     0,
      },
      {id:          8,
        name:       'deed-of-sale',
        parentType: 'initial',
        type:       'income',
        label:      'Escritura compraventa – (en el supuesto de ser titular de bienes inmuebles)',
        custom:     0,
      },
      {id:          9,
        name:       'mortgage-deed',
        parentType: 'initial',
        type:       'income',
        label:      'Escritura garantía hipotecaria – (en el supuesto de ser titular de bienes inmuebles)',
        custom:     0,
      },
      {id:          10,
        name:       'circulation-permit',
        parentType: 'initial',
        type:       'income',
        label:      'Permiso de circulación – (en el supuesto de ser titular de vehículos)',
        custom:     0,
      },
      {id:          11,
        name:       'vehicle-technical-card',
        parentType: 'initial',
        type:       'income',
        label:      'Tarjeta técnica vehículo - (en el supuesto de ser titular de vehículos)',
        custom:     0,
      },
      {id:          12,
        name:       'contracts-in-force',
        parentType: 'initial',
        type:       'income',
        label:      'Contratos en vigor – (luz, agua, gas, teléfono, seguros)',
        custom:     0,
      },
      {id:          13,
        name:       'certificate-of-ownership',
        parentType: 'initial',
        type:       'income',
        label:      'Certificado titularidad capital mobiliario (acciones, préstamos, depósitos)',
        custom:     0,
      },
      {id:          14,
        name:       'loan-card',
        parentType: 'initial',
        type:       'financial',
        label:      'Contratos de los préstamos/tarjetas',
        custom:     0,
      },
      {id:          15,
        name:       'proof-of-debts',
        parentType: 'initial',
        type:       'financial',
        label:      'Justificante de las deudas (fotografías banca online, cartas, cualquier documento que acredite la entidad y el importe a deber)',
        custom:     0,
      },
      {id:          16,
        name:       'certificate-of-registration',
        parentType: 'expiry-documents',
        type:       'personal',
        label:      'Certificado de empadronamiento',
        custom:     0,
      },
      {id:          17,
        name:       'birth-certificate',
        parentType: 'expiry-documents',
        type:       'personal',
        label:      'Certificado de nacimiento',
        custom:     0,
      },
      {id:          18,
        name:       'criminal-record',
        parentType: 'expiry-documents',
        type:       'personal',
        label:      'Certificado de antecedentes penales',
        custom:     0,
      },
      {id:          19,
        name:       'unemployment-certificate',
        parentType: 'expiry-documents',
        type:       'income',
        label:      'Certificado desempleo - (en el supuesto de estar en situación de desempleo)',
        custom:     0,
      },
      {id:          20,
        name:       'model',
        parentType: 'expiry-documents',
        type:       'income',
        label:      'Modelo 036/037 - (en el supuesto de ser autónomo)',
        custom:     0,
      },
      {id:          21,
        name:       'last-payrolls',
        parentType: 'expiry-documents',
        type:       'income',
        label:      '03 últimas nóminas - (en el supuesto de trabajar por cuenta ajena)',
        custom:     0,
      },
      {id:          22,
        name:       'last-model',
        parentType: 'expiry-documents',
        type:       'income',
        label:      '03 últimas declaraciones modelo 303 – (en el supuesto de ser autónomo)',
        custom:     0,
      },
      {id:          23,
        name:       'last-personal-income',
        parentType: 'expiry-documents',
        type:       'income',
        label:      '4 últimas declaraciones IRPF o Certificado de no obligación declarar',
        custom:     0,
      },
      {id:          24,
        name:       'property-registry',
        parentType: 'expiry-documents',
        type:       'income',
        label:      'Certificado Registro Propiedad - (en el supuesto de ser titular de bienes inmuebles)',
        custom:     0,
      },
      {id:          25,
        name:       'certificate',
        parentType: 'expiry-documents',
        type:       'financial',
        label:      'Certificado, o justificante actualizado de las deudas',
        custom:     0,
      },
      {id:          26,
        name:       'ownership-and-bank-balance',
        parentType: 'expiry-documents',
        type:       'financial',
        label:      'Certificado de titularidad y saldo de cuentas bancarias',
        custom:     0,
      },
      {id:          27,
        name:       'judicial-notifications',
        parentType: 'expiry-documents',
        type:       'financial',
        label:      'Notificaciones judiciales en el supuesto de que existan',
        custom:     0,
      },
      {id:          6602,
        name:       'cred-payment-proof',
        parentType: 'initial',
        type:       'financial',
        label:      'Prueba de pago a los acreededores',
        custom:     0,
      },
    ];

    return of(documentTypes);
  }

  getLegalDocumentTypes(): Observable<any[]> {
    const documentTypes = [
      {id: 1, name: 'aep', type: 'extrajudicial', label: 'AEP Notary'},
      {id: 24, name: 'aep-reg', type: 'extrajudicial', label: 'AEP Reg'},
      {id: 2, name: 'indice-documentos', type: 'extrajudicial', label: 'Índice Documentos'},
      {id: 3, name: 'acta-notarial-inicial', type: 'extrajudicial', label: 'Acta Notarial inicial'},
      {id: 4, name: 'propuesta', type: 'extrajudicial', label: 'Propuesta'},
      {id: 5, name: 'convocatoria-moc', type: 'extrajudicial', label: 'Convocatoria MOC'},
      {id: 6, name: 'acta-moc', type: 'extrajudicial', label: 'Acta MOC'},
      {id: 7, name: 'acta-notarial-final', type: 'extrajudicial', label: 'Acta Notarial Final'},
      {id: 8, name: 'demanda', type: 'judicial', label: 'DEMANDA'},
      {id: 9, name: 'memoria', type: 'judicial', label: 'MEMORIA'},
      {id: 10, name: 'inventario-de-bienes-y-derechos', type: 'judicial', label: 'INVENTARIO DE BIENES Y DERECHOS'},
      {id: 11, name: 'lista-de-acreedores', type: 'judicial', label: 'LISTA DE ACREEDORES'},
      {id: 12, name: 'plan-de-liquidacion', type: 'judicial', label: 'PLAN DE LIQUIDACIÓN'},
      {id:     13,
        name:  'justificante-de-presentación-de-la-demanda',
        type:  'judicial',
        label: 'Justificante de presentación de la demanda',
      },
      {id: 14, name: 'do-de-reparto', type: 'judicial', label: 'D.O. de reparto'},
      {id: 15, name: 'auto-de-concurso', type: 'judicial', label: 'Auto de concurso'},
      {id: 16, name: 'auto-de-inadmision', type: 'judicial', label: 'Auto de Inadmisión'},
      {id: 17, name: 'recurso-de-inadmision', type: 'judicial', label: 'Recurso de Inadmisión'},
      {id: 18, name: 'personaciones-acreedores', type: 'judicial', label: 'Personaciones Acreedores'},
      {id: 19, name: 'escrito–procurador', type: 'judicial', label: 'Escrito – Procurador'},
      {id: 20, name: 'Informes-ac', type: 'judicial', label: 'Informes AC'},
      {id: 21, name: 'auto-de-conclusión', type: 'judicial', label: 'Auto de Conclusión'},
      {id: 22, name: 'solicitud-bepi', type: 'judicial', label: 'Solicitud BEPI'},
      {id: 23, name: 'auto-exoneracion', type: 'judicial', label: 'Auto exoneración'},
    ];
    return of(documentTypes);
  }

  getCourtDocumentTypes(): Observable<any[]> {
    const documentTypes = [
      // {id: 1, name: 'court-documents', type: '', label: 'Court documents'},
    ];
    return of(documentTypes);
  }
}
